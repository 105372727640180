import {
  Box,
  Button,
  Grid,
  InputAdornment,
  OutlinedInput,
  Popover,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TalentSVGButton from "../../common/TalentSVGButton";
import chatComment from "../../../assets/Padding Excluded/Black_Chat_Green.svg";
import sort from "../../../assets/Padding Excluded/Black_Sort_Filter.svg";
import locale from "../../../i18n/locale";
import profile from "../../../assets/user_logo.svg";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import {
  getCandidateComments,
  postTalentComment,
} from "../../../redux/admin/jobsSlice";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, COMMENT_SORTING } from "../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { addJobComment, getAllComments } from "../../../redux/admin/jobsSlice";
import { dateConverterMonth } from "../../../utils/DateTime";
import SortDropDown from "../../common/SortDropDown";
const StyledTextField = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  margin: "8px 0",
  paddingRight: "8px",

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grayBorder,
    borderRadius: "20px",
    transition: "border-color 0.3s", // Add transition for a smoother effect
  },

  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.grayBorder, // Change to the desired hover color
  },
}));
export default function JobCommentBox({ jobContent }) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const [totalComment, setTotalComment] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [anchorElComment, setAnchorElComment] = useState(null);
  const [currentSort, setCurrentSort] = useState(1);
  const sendComment = async () => {
    try {
      setIsSubmitting(true);

      const comment = {
        job_id: jobContent?.job_id,
        comment: inputValue,
      };

      const { payload } = await dispatch(addJobComment(comment));

      if (payload?.status === "success") {
        setInputValue("");
        await getComments(jobContent?.job_id);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Comment Added Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePostButtonClick = () => {
    if (!isSubmitting && inputValue !== "") {
      sendComment();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !isSubmitting && inputValue !== "") {
      event.preventDefault();
      sendComment();
    }
  };

  const getComments = async (sort) => {
    try {
      const { payload } = await dispatch(
        getAllComments({ job_id: jobContent?.job_id, sort_type: sort })
      );
      if (payload?.status == "success") {
        setComments(payload?.data);
        setTotalComment(payload?.count);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleSort = async (sortID) => {
    setCurrentSort(sortID);
    setAnchorElComment(null);
    await getComments(sortID);
  };

  const handlePopoverCloseReferral = () => {
    setAnchorElComment(null);
  };

  useEffect(() => {
    if (jobContent?.job_id) {
      getComments("");
    }
  }, [jobContent]);

  return (
    <Grid sx={{ mt: 4, width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", width: "140px", alignItems: "center" }}>
          <TalentSVGButton
            color={"white"}
            source={chatComment}
            height={16}
            width={18}
            padding={"0 16px 0 0 !important"}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            {i18n["allTalent.comments"]} ({totalComment})
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={(event) => {
            setAnchorElComment(event.target);
          }}
        >
          <TalentSVGButton
            color={"white"}
            source={sort}
            outerHeight={22}
            height={18}
            width={15}
            padding={"0px !important"}
            startIconMargin={"0px !important"}
          />
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            {"Sort"}
          </Typography>
        </Box>

        <Popover
          id="dropdown"
          open={Boolean(anchorElComment)}
          anchorEl={anchorElComment}
          onClose={handlePopoverCloseReferral}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            "& .MuiPaper-root-MuiPopover-paper": {
              minWidth: "18% !important",
              borderRadius: "20px !important",
              mt: 1,
            },
          }}
        >
          <SortDropDown
            list={COMMENT_SORTING?.filter((item) => item?.id !== currentSort)}
            handleClick={handleSort}
          />
        </Popover>
      </Box>
      <Box id="talentList" sx={{ overflow: "hidden", height: "160px" }}>
        <InfiniteScroll
          style={{
            height: "100%",
            overflowX: "hidden",
            scrollbarWidth: "thin",
            paddingBottom: '35px !important'
          }}
          dataLength={comments !== undefined && comments?.length}
          hasMore={true}
          scrollableTarget="talentList"
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          {comments?.length > 0 ? (
            comments?.map((comment) => {
              return (
                <Box sx={{ display: "flex", mt: 2 }}>
                  <Box
                    component="img"
                    className="profileAvatar"
                    alt="crayon logo"
                    src={
                      comment?.profile_url !== "NO URL"
                        ? comment?.profile_url
                        : profile
                    }
                    sx={{
                      mr: 1,
                      width: 20,
                      height: 20,
                    }}
                  />
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.SemiBold,
                        mr: 1,
                      }}
                    >
                      {`${comment?.user?.first_name} ${
                        comment?.user?.last_name
                      }  (${
                        comment?.employer_profile
                          ? comment?.employer_profile?.company_name
                          : "-"
                      })`}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: theme.typography.Regular,
                        mr: 1,
                        lineBreak: "anywhere",
                      }}
                    >
                      {comment.comment}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 400,
                        mr: 1,
                        color: theme.palette.grayButton.main,
                        textAlign: "end",
                      }}
                    >
                      {dateConverterMonth(comment.created_at)}
                    </Typography>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                mt: 4,
                color: theme.palette.placeholder,
              }}
            >
              {i18n["pendingJobs.noData"]}
            </Box>
          )}
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                    width: 7px !important;
                    background-color: #F5F5F5; /* Set the background color of the scrollbar */
                  }

                  .infinite-scroll-component__outerdiv {
                    height:100%
                  }

                  .infinite-scroll-component::-webkit-scrollbar-thumb {
                    background-color: #888c; /* Set the color of the scrollbar thumb */
                  }`}
          </style>
        </InfiniteScroll>
      </Box>
      <Box sx={{ mt: 4 }}>
        <StyledTextField
          id="outlined-adornment-password"
          type="text"
          size="small"
          sx={{
            "&:hover": {
              border: "none !important",
            },
          }}
          placeholder="type your comment here..."
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          endAdornment={
            <InputAdornment position="end">
              <Button
                variant="contained"
                id="broad"
                sx={{
                  borderRadius: "20px",
                  fontSize: "12px",
                  height: "25px !important",
                }}
                color="lightGreenButton300"
                onClick={handlePostButtonClick}
              >
                Post
              </Button>
            </InputAdornment>
          }
        />
      </Box>
    </Grid>
  );
}
