import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import companyLogo from "../../../assets/user_logo.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import { dateConverterFullMonth } from "../../../utils/DateTime";

import flip from "../../../assets/NewFlipIcon.svg";
import Slider2 from "../Slider2";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import companyIcon from "../../../assets/Padding Excluded/Blue_Following_Company-.svg";
import useViewport from "../ViewPort";
import { truncateText } from "../../../utils/Common";
import LocationIcon from "../../../assets/Padding Included/Red_Location.svg";
import { Link } from "react-router-dom";
import CompanyLink from "../../../assets/Padding Excluded/teal_World_Website.svg";

export default function CompanyInfoFrontCard({
  companyDetail,
  setisHorizontalFlipped,
  setVideoLink,
  setOpenReviewVideoDialog,
}) {
  const theme = useTheme();
  const industries = companyDetail?.employer_industries?.map(
    (item) => item?.industry?.name
  );

  const { width } = useViewport();

  return (
    <>
      <Box
        sx={{
          height: { xs: "406px", tablet: "225px", lg: "280px", mlg: "225px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            height: "100%",
            flexDirection:{xs:"column",tablet:"row"},
            alignItems:{xs:"baseline",tablet:"center"},
            paddingTop:{xs:"45px",tablet:"0px"}
          }}
        >
          <Box
            component={"img"}
            src={
              companyDetail?.companyURL !== "NO URL"
                ? companyDetail?.companyURL
                : companyLogo
            }
            sx={{
              //   height: { xs: "96px", xl: "96px" },
              //   width: { xs: "96px", xl: "96px" },
              //   minWidth: { xs: "96px", xl: "96px" },
              height: { xs: "175px", tablet: "175px" },
              width: { xs: "175px", tablet: "175px" },
              borderRadius: companyDetail?.companyURL !== "NO URL"? "10px":"20px",
              margin: "24px 14px 25px 21px",
              marginTop:{xs:"15px",tablet:"24px"},
              marginBottom:{xs:"10px",tablet:"25px"},
              marginLeft:{xs:"10px",xs390:"21px"}
            }}
          />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "10px",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={companyIcon}
                sx={{
                  height: 16,
                  width: 15,
                  maxHeight: { xs: 16 },
                  maxWidth: { xs: 15 },
                  wordBreak: "break-all",
                }}
              />
              <Typography
                sx={{
                  fontSize: { xs: "14px", lg: "14px", xl: "20px" },
                  fontWeight: theme.typography.Bold,
                  wordBreak: "break-all",
                }}
              >
                {companyDetail?.name
                  ? width < 1024
                    ? truncateText(companyDetail?.name, 25)
                    : companyDetail?.name
                  : "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
                height: "14px",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 16,
                  width: 15,
                  maxHeight: { xs: 16 },
                  maxWidth: { xs: 15 },
                  wordBreak: "break-all",
                }}
                alt="location"
                src={LocationIcon}
              />
              <Typography
                sx={{
                  fontWeight: theme.typography.Bold,
                  fontSize: "14px",
                  letterSpacing: "0.25px",
                  lineHeight: 1,
                  // marginLeft: "4px",
                }}
              >
                {companyDetail?.town_name && companyDetail?.country_name
                  ? width < 1024
                    ? truncateText(
                        `${companyDetail?.town_name}, ${companyDetail?.country_name}`,
                        25
                      )
                    : `${companyDetail?.town_name}, ${companyDetail?.country_name}`
                  : "-"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: "10px",
                height: "14px",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 16,
                  width: 15,
                  maxHeight: { xs: 16 },
                  maxWidth: { xs: 15 },
                  wordBreak: "break-all",
                }}
                alt="location"
                src={CompanyLink}
              />
              <Typography
                sx={{
                  fontWeight: theme.typography.Bold,
                  fontSize: "14px",
                  letterSpacing: "0.25px",
                  lineHeight: 1,
                  // marginLeft: "2px",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  target="_blank"
                  to={companyDetail?.website}
                >
                  {companyDetail?.website
                    ? width < 1024
                      ? truncateText(companyDetail?.website, 25)
                      : companyDetail?.website
                    : "-"}
                </Link>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                gap: "10px",
              }}
            >
              <Box
                component={"img"}
                src={calendar}
                sx={{
                  height: 16,
                  width: 15,
                  maxHeight: { xs: 16 },
                  maxWidth: { xs: 15 },
                  wordBreak: "break-all",
                }}
              />
              <Typography
                sx={{
                  fontSize: { xs: "14px", lg: "14px", xl: "14px" },
                  fontWeight: theme.typography.Bold,
                  color: "black",
                  textWrap: "wrap",
                  // marginLeft: "4px",
                }}
              >
                {/* Date Joined:
                <br className="breakTag" style={{ display: "none" }} /> */}
                {companyDetail?.created_at
                  ? width <= 1024
                    ? truncateText(
                        dateConverterFullMonth(companyDetail?.created_at),
                        10
                      )
                    : dateConverterFullMonth(companyDetail?.created_at)
                  : "-"}
                <style>
                  {`@media (max-width: 1919px) {
                                .breakTag {
                                display: block !important;
                              }
                              }`}
                </style>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  // minWidth: `calc(100% - 260px)`,
                  // maxWidth: `calc(100% - 260px)`,
                  minWidth: { xs: `320px`, tablet: "375px", lg: "280px",md24:"500px",lgm:"500px" },
                  maxWidth: { xs: `320px`, tablet: "375px", lg: "280px",md24:"500px",lgm:"500px" },
                }}
              >
                <Slider2
                  items={industries || []}
                  color={"tymeColor"}
                  hideTagsAfter={2}
                  margin={"0 5px 0 0"}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            color="precium"
            variant="contained"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: "0 0 0 10px",
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            {companyDetail?.company_type ? companyDetail?.company_type : "-"}
          </Button>
          <Button
            color="peachPayment"
            variant="contained"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: 0,
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            {companyDetail?.company_size ? companyDetail?.company_size : "-"}
          </Button>
          <Button
            color="playButton"
            variant="contained"
            onClick={() => {
              if (companyDetail?.companyVideoURL !== "NO URL" || !companyDetail?.video_link ) {
                setVideoLink(companyDetail?.companyVideoURL || !companyDetail?.video_link);
                setOpenReviewVideoDialog(true);
              }
            }}
            sx={{
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              background:
                companyDetail?.companyVideoURL === "NO URL" &&  !companyDetail?.video_link &&
                theme.palette.grayButton600.main,
              borderRadius: 0,
              borderTopRightRadius: "25px",
              cursor:
                (companyDetail?.companyVideoURL === "NO URL" &&  !companyDetail?.video_link)
                  ? "default"
                  : "pointer",
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            <Box
              component={"img"}
              src={
                // companyDetail?.companyVideoURL !== "NO URL"
                //   ? playButtonIconWhite
                //   : playButtonIcon
                playButtonIconWhite
              }
              sx={{
                height: "16px",
                width: "20px",
              }}
            />
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        // color="redButton100"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(true)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </>
  );
}
