import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import ButtonPanel from "../../common/ButtonPanel";
import {
  HIRING_RIGHT_STAGES_BUTTON_GROUP,
  ALERT_TYPE,
  BASIC_SEARCH,
  JOBS_RIGHT_COMPANIES_STATUS,
  ERROR_MSG,
  ALL_SIZE,
  ALL_TYPE,
} from "../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllIndustries, setLoading } from "../../../redux/configSlice";
import { setAlert } from "../../../redux/configSlice";
import { getAllJobRoleType } from "../../../redux/jobRole";
import { getAllStages } from "../../../redux/stages";
import { getAllTypes } from "../../../redux/allTypes";
import CustomDialog from "../../common/CustomDialog";
import ApplyJobs from "../jobs/ApplyJobs";
import { Button, Paper, ThemeProvider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import CompanyInfoCard from "../../common/CompanyInfoCard";
import { getDecodedToken, getToken, useQuery } from "../../../utils/Common";
import NoRecord from "../../common/NoRecord";
import { whosHiringTheme } from "../../../utils/componentThemes/whosHiringTheme";
import HiringCard from "./HiringCard";
import {
  getHiringList,
  updateAllCompany,
} from "../../../redux/guest/whoshiring";
import {
  getCompanySize,
  getCompanyType,
} from "../../../redux/employer/empProfileSlice";
import useViewport from "../../common/ViewPort";
import CommonLoader from "../../common/CommonLoader";
import Loader from "../../common/Loader";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { setSideFilter } from "../../../redux/login/loginSlice";

export default function Hiring({ handleJobsListingDynamicTheme }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let decodedToken = getDecodedToken(getToken());
  const query = useQuery();
  const { width } = useViewport();
  const isLoading = useSelector((state) => state.config.loading);
  const allCompanyHiringList = useSelector(
    (state) => state.configWhosHiring.companylist
  );
  const { companySize, companyType } = useSelector((state) => state.myProfile);
  const currentPageJobsCount = useSelector(
    (state) => state.configWhosHiring.currentPageJobsCount
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const { isSideFilter } = useSelector((state) => state.login);
  const showBox = useSelector((state) => state.configWhosHiring.showBox);
  const allIndustries = useSelector((state) => state.config.industries);
  const allJobTypes = useSelector((state) => state.jobtype.jobRoleType);
  const allStages = useSelector((state) => state.configstages.stages);
  const allTypes = useSelector((state) => state.configAllTypes?.types);
  const selectedCompany = useRef([JOBS_RIGHT_COMPANIES_STATUS?.[1]?.id]);
  const selectedIndustries = useRef([
    allIndustries?.[0]?.id ? allIndustries?.[0]?.id : 1111,
  ]);
  const selectedAllPost = useRef([HIRING_RIGHT_STAGES_BUTTON_GROUP?.[0]?.id]);
  const selectedSize = useRef([ALL_SIZE?.id]);
  const selectedType = useRef([ALL_TYPE?.id]);
  const [filters, setFilters] = useState([allIndustries?.[0]?.id]);
  const [filtersJobType, setFiltersJobType] = useState([allJobTypes?.[0]?.id]);
  const [filtersJobStage, setFiltersJobStage] = useState([allStages?.[0]?.id]);
  const [filtersType, setFiltersType] = useState([allTypes?.[0]?.id]);
  const [filterCompany, setFilterCompany] = useState([
    JOBS_RIGHT_COMPANIES_STATUS?.[1]?.id,
  ]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [lastKey, setLastKey] = useState(0);
  const [searchedJobs, setSearchedJobs] = useState("");
  const [openApplyJobDialog, setopenApplyJobDialog] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [selectedCompSize, setSelectedCompSize] = useState([]);
  const [selectedCompType, setSelectedCompType] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [favouriteFilter, setFavouriteFilter] = useState({
    favourite: "",
    appliedJobs: "",
  });

  const [itemsFetched, setItemsFetched] = useState(false);
  const gridRef = useRef();
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true))
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false))
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);
  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }
  };
  useLayoutEffect(() => {
    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, isSideFilter]);

  const onHandleClose = () => {
    setopenApplyJobDialog(false);
  };

  const handleFilterSelection = (paramType, filterName) => {
    if (
      filterName[0] === "companies" ||
      filterName[0] === "all industries" ||
      filterName[0] === "all companies" ||
      filterName[0] === "all size" ||
      filterName[0] === "all type"
    ) {
      filterName[0] === "companies" && (selectedCompany.current = []);
      filterName[0] === "all industries" && (selectedIndustries.current = []);
      filterName[0] === "all companies" && (selectedAllPost.current = []);
      filterName[0] === "all size" && (selectedSize.current = []);
      filterName[0] === "all type" && (selectedType.current = []);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType); // Remove the parameter from the query string
      navigate(`/whoshiring?${queryParams?.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const getAllData = async () => {
    try {
      // dispatch(setLoading(true));
      dispatch(setLoading({ value: true, apiName: "getAllData" }));

      await Promise.all([
        dispatch(getAllTypes()),
        dispatch(getAllIndustries()),
        dispatch(getAllJobRoleType()),
        dispatch(getAllStages()),
        dispatch(getCompanySize()),
        dispatch(getCompanyType()),
      ]);
      // dispatch(setLoading(false));
      dispatch(setLoading({ value: false, apiName: "getAllData" }));
    } catch (error) {
      // dispatch(setLoading(false));
      dispatch(setLoading({ value: false, apiName: "getAllData" }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getCompanyHiringList = async (
    selectedFilters = [],
    lastkeyy = lastKey,
    filteralltype = favouriteFilter,
    companyfilter = filterCompany,
    companySizeFilter = selectedCompSize,
    companyTypeFilter = selectedCompType
  ) => {
    try {
      //  dispatch(setLoading(true))
      lastkeyy === "" && setIsDataLoading(true);
      // lastkeyy === "" && dispatch(setLoading({ value: true, apiName: "getCompanyHiringList" }));
      dispatch(setLoading({ value: true, apiName: "getCompanyHiringList" }));
      let data = {
        lastKey: lastkeyy,
        decodedToken: decodedToken,
        industryID: selectedFilters?.[0] !== 1111 ? selectedFilters : "",
        status: companyfilter?.[0] !== 1111 ? companyfilter : "",
        company_size:
          companySizeFilter?.[0] !== "all size" ? companySizeFilter : "",
        company_type:
          companyTypeFilter?.[0] !== "all type" ? companyTypeFilter : "",
        favourite: filteralltype?.favourite || "",
        appliedJobs: filteralltype?.appliedJobs || "",
        query: query.get("query") ? query.get("query") : "",
        job_title: query.get("job_title")
          ? query.get("job_title")
          : basicData?.job_title,
        town_id: query.get("location")
          ? query.get("location")
          : query.size < 1
          ? ""
          : basicData?.town_id,
        region_id: query.get("region_id")
          ? query.get("region_id")
          : query.size < 1
          ? ""
          : basicData?.region_id,
        tag_id: query.get("tag_id")
          ? query.get("tag_id")
          : query.size < 1
          ? ""
          : basicData?.tag_id,
        tool_id: query.get("tool_id")
          ? query.get("tool_id")
          : query.size < 1
          ? ""
          : basicData?.tool_id,
        company_id: query.get("company_id")
          ? query.get("company_id")
          : query.size < 1
          ? ""
          : basicData?.company_id,
        currency_id: query.get("currency_id")
          ? query.get("currency_id")
          : query.size < 1
          ? ""
          : basicData?.currency_id,
        highest_qualification_id: query.get("highest_qualification_id")
          ? query.get("highest_qualification_id")
          : query.size < 1
          ? ""
          : basicData?.highest_qualification_id,
        salary: query.get("salary")
          ? query.get("salary")
          : query.size < 1
          ? ""
          : basicData?.salary,
        experience: query.get("experience")
          ? query.get("experience")
          : query.size < 1
          ? ""
          : basicData?.experience,
      };
      const { payload } = await dispatch(getHiringList(data));
      if (payload?.status === "success") {
        setIsDataLoading(false);
        if (payload?.data?.length <= 0) {
          // dispatch(setLoading(false))
          // dispatch(
          //   setLoading({ value: false, apiName: "getCompanyHiringList" })
          // );
        } else {
          setLastKey(payload?.pageNumber + 1);
          setItemsFetched(true);
          // dispatch(setLoading(false))
          // dispatch(
          //   setLoading({ value: false, apiName: "getCompanyHiringList" })
          // );
        }
        setIsDataLoading(false);
      } else if (payload === undefined) {
        return;
      } else {
        // dispatch(setLoading(false))
        setIsDataLoading(false);
        // dispatch(setLoading({ value: false, apiName: "getCompanyHiringList" }));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      dispatch(setLoading({ value: false, apiName: "getCompanyHiringList" }));
    } catch (error) {
      // dispatch(setLoading(false))
      setIsDataLoading(false);
      dispatch(setLoading({ value: false, apiName: "getCompanyHiringList" }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const setNewWidthandPadding = () => {
    if (gridRef.current) {
    }
  };

  const onChangeFilter = (selectedFilter) => {
    let industry = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allIndustries?.find(
        (jobtype) => jobtype?.id === type
      );
      industry?.push(selectedJobType?.name);
    });
    dispatch(updateAllCompany());
    handleFilterSelection("industry", industry);
    setLastKey(0);
    setFilters(selectedFilter);
    setIsFilterApplied(true);
    getCompanyHiringList(
      selectedFilter,
      "",
      favouriteFilter,
      filterCompany,
      selectedCompSize,
      selectedCompType
    );
  };

  const onChangeFilterCompany = (selectedFilter) => {
    let company = [];
    let companyStatusName = [];
    selectedFilter.map((type) => {
      let selectedJobType = JOBS_RIGHT_COMPANIES_STATUS?.find(
        (jobtype) => jobtype?.id === type
      );
      company?.push(selectedJobType?.id);
    });
    selectedFilter.map((type) => {
      let selectedJobType = JOBS_RIGHT_COMPANIES_STATUS?.find(
        (jobtype) => jobtype?.id === type
      );
      companyStatusName?.push(selectedJobType.name);
    });
    selectedCompany.current = company;
    setIsFilterApplied(true);
    dispatch(updateAllCompany());
    setLastKey(0);
    setFilterCompany(company);
    handleFilterSelection("companies", companyStatusName);
    getCompanyHiringList(
      filters,
      "",
      favouriteFilter,
      company,
      selectedCompSize,
      selectedCompType
    );
  };

  const onChangefavourite = (selectedFilter) => {
    let withoutLoginFiter;
    setIsFilterApplied(true);
    if (
      !decodedToken &&
      selectedFilter?.some((item) => [2, 1111].includes(item))
    ) {
      withoutLoginFiter = selectedFilter.filter(
        (element) => element !== 3 && element !== 4
      );

      const posts = [];
      const selectedID = [];

      withoutLoginFiter?.forEach((types) => {
        const selectedJobType = HIRING_RIGHT_STAGES_BUTTON_GROUP?.find(
          (type) => type?.id === types
        );

        posts.push(selectedJobType?.name);
        selectedID.push(selectedJobType?.id);
      });

      selectedAllPost.current = selectedID;
      dispatch(updateAllCompany());
      handleFilterSelection("posts", posts);
      setLastKey(0);

      const allTypeFilter = {
        favourite: withoutLoginFiter.includes(3) ? true : "",
        appliedJobs: withoutLoginFiter.includes(4) ? true : "",
      };

      setFavouriteFilter(allTypeFilter);
      getCompanyHiringList(
        filters,
        "",
        allTypeFilter,
        filterCompany,
        selectedCompSize,
        selectedCompType
      );

      return;
    }

    if (decodedToken) {
      const posts = [];
      const selectedID = [];

      selectedFilter?.forEach((types) => {
        const selectedJobType = HIRING_RIGHT_STAGES_BUTTON_GROUP?.find(
          (type) => type?.id === types
        );

        posts.push(selectedJobType?.name);
        selectedID.push(selectedJobType?.id);
      });

      selectedAllPost.current = selectedID;
      dispatch(updateAllCompany());
      handleFilterSelection("posts", posts);
      setLastKey(0);

      const allTypeFilter = {
        favourite: selectedFilter.includes(3) ? true : "",
        appliedJobs: selectedFilter.includes(4) ? true : "",
      };

      setFavouriteFilter(allTypeFilter);
      getCompanyHiringList(
        filters,
        "",
        allTypeFilter,
        filterCompany,
        selectedCompSize,
        selectedCompType
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login first to apply this filter",
        })
      );
    }
  };

  const onChangeSize = (selectedFilter) => {
    let companySizeSelected = [];
    let selectSizeID = [];
    if (selectedFilter?.length === 1 && selectedFilter?.[0] === 1111) {
      companySizeSelected?.push("all size");
    } else {
      selectedFilter?.map((item) => {
        let selectedCompanySize = companySize?.find(
          (size) => size?.id === item
        );
        companySizeSelected?.push(selectedCompanySize?.name);
        selectSizeID?.push(selectedCompanySize?.id);
      });
      selectedSize.current = selectSizeID;
    }
    setIsFilterApplied(true);
    dispatch(updateAllCompany());
    setSelectedCompSize(companySizeSelected);
    handleFilterSelection("all size", companySizeSelected);
    setLastKey(0);
    getCompanyHiringList(
      filters,
      "",
      favouriteFilter,
      filterCompany,
      companySizeSelected,
      selectedCompType
    );
  };

  const onChangeType = (selectedFilter) => {
    let companyTypeSelected = [];
    let selectTypeID = [];
    if (selectedFilter?.length === 1 && selectedFilter?.[0] === 1111) {
      companyTypeSelected?.push("all type");
    } else {
      selectedFilter?.map((item) => {
        let selectedCompanyType = companyType?.find(
          (size) => size?.id === item
        );
        companyTypeSelected?.push(selectedCompanyType?.name);
        selectTypeID?.push(selectedCompanyType?.id);
      });
      selectedType.current = selectTypeID;
    }
    setIsFilterApplied(true);
    dispatch(updateAllCompany());
    setSelectedCompType(companyTypeSelected);
    handleFilterSelection("all type", companyTypeSelected);
    setLastKey(0);
    getCompanyHiringList(
      filters,
      "",
      favouriteFilter,
      filterCompany,
      selectedCompSize,
      companyTypeSelected
    );
  };

  const calculateDynamicHeight = () => {
    // Calculate the desired height based on screen resolution or any other logic
    // For example, you can use window.innerWidth or window.innerHeight to adjust the height dynamically.
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };

  useLayoutEffect(() => {
    let companyStatus = [];
    let industry = [];
    let posts = [];
    let favouriteposts = {};
    let allSize = [];
    let allSizeName = [];
    let allType = [];
    let allTypeName = [];
    dispatch(updateAllCompany());
    if (query.get("all type") !== null) {
      query
        .get("all type")
        ?.split(",")
        .map((type) => {
          let selectedCompanySize = [ALL_TYPE, ...companyType]?.find(
            (jobtype) => jobtype?.name === type
          );
          allType?.push(selectedCompanySize?.id);
          allTypeName?.push(selectedCompanySize?.name);
        });
      selectedType.current = allType;
      setSelectedCompType(allTypeName);
    }
    if (query.get("all size") !== null) {
      query
        .get("all size")
        ?.split(",")
        .map((type) => {
          let selectedCompanySize = [ALL_SIZE, ...companySize]?.find(
            (jobtype) => jobtype?.name === type
          );
          allSize?.push(selectedCompanySize?.id);
          allSizeName?.push(selectedCompanySize?.name);
        });
      selectedSize.current = allSize;
      setSelectedCompSize(allSizeName);
    }
    if (query.get("companies") !== null) {
      query
        .get("companies")
        ?.split(",")
        .map((type) => {
          let selectedJobType = JOBS_RIGHT_COMPANIES_STATUS?.find(
            (jobtype) => jobtype?.name === type
          );
          companyStatus?.push(selectedJobType?.id);
        });
      selectedCompany.current = companyStatus;
      setFilterCompany(companyStatus);
    }
    if (query.get("industry") !== null) {
      query
        .get("industry")
        ?.split(",")
        .map((type) => {
          let selectedJobType = allIndustries?.find(
            (jobtype) => jobtype?.name === type
          );
          industry?.push(selectedJobType?.id);
        });
      selectedIndustries.current = industry;
      setFilters(industry);
    }
    if (query.get("posts") !== null) {
      query
        .get("posts")
        ?.split(",")
        .map((type) => {
          let selectedJobType = HIRING_RIGHT_STAGES_BUTTON_GROUP?.find(
            (jobtype) => jobtype?.name === type
          );
          posts?.push(selectedJobType?.id);
        });
      selectedAllPost.current = posts;
      favouriteposts = {
        favourite: posts?.includes(3) ? true : false,
        appliedJobs: posts?.includes(4) ? true : false,
      };
      setFavouriteFilter({
        favourite: posts?.includes(3) ? true : false,
        appliedJobs: posts?.includes(4) ? true : false,
      });
    }
    if (
      allIndustries?.length > 0 &&
      companyType?.length > 0 &&
      companySize?.length > 0
    ) {
      getCompanyHiringList(
        industry,
        "",
        favouriteposts,
        companyStatus?.length === 0
          ? JOBS_RIGHT_COMPANIES_STATUS?.[1]?.id
          : companyStatus,
        allSizeName,
        allTypeName
      );
    }
  }, [allIndustries, companyType, companySize]);

  useEffect(() => {
    dispatch(updateAllCompany());
    setBasicData({
      job_title: query.get("job_title")
        ? query.get("job_title")
        : basicData?.job_title,
      town_id: query.get("location")
        ? query
            .get("location")
            .split(",")
            .map((item) => Number(item))
        : basicData?.town_id,
      region_id: query.get("region_id")
        ? query
            .get("region_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.region_id,
      tag_id: query.get("tag_id")
        ? query
            .get("tag_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.tag_id,
      tool_id: query.get("tool_id")
        ? query
            .get("tool_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.tool_id,
      company_id: query.get("company_id")
        ? query
            .get("company_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.company_id,
      currency_id: query.get("currency_id")
        ? query
            .get("currency_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.currency_id,
      highest_qualification_id: query.get("highest_qualification_id")
        ? query
            .get("highest_qualification_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.highest_qualification_id,
      salary: query.get("salary")
        ? query
            .get("salary")
            .split(",")
            .map((item) => Number(item))
        : basicData?.salary,
      experience: query.get("experience")
        ? query
            .get("experience")
            .split(",")
            .map((item) => Number(item))
        : basicData?.experience,
    });
  }, []);

  useEffect(() => {
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleJobsListingDynamicTheme(decodedToken?.data?.personality);
    }
  }, []);

  useEffect(() => {
    setNewWidthandPadding();
  }, []);

  useEffect(() => {
    getAllData();
  }, []);

  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        position:"relative",
        justifyContent: "space-between",
      }}
    >
        {width < 768 && width !==0 && isSideFilter && 
      <Box sx={{position:"absolute", top:"0", left:"0", height:"100%", width:"100%", background:"rgba(0,0,0, .2)"}}></Box>
      }
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
         <Box sx={{ minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight:"45px",
             }}></Box>
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <LeftArrow /> : <RightArrow />}
        </Button> */}
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Box
              sx={{
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedIndustries?.current}
                panelData={allIndustries}
                side="left"
                color="filterButtons"
                onChangeFilter={onChangeFilter}
                type="common"
              />
            </Box>
            {/* <Button
              variant="contained"
              sx={{
                position: "static",
                borderRadius: "0 10px 10px 0",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="filterButtons"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpand((prevState) => !prevState);
                dispatch(setFilterExpanded(!filterExpanded));
              }}
            >
              {isSideFilter ? <LeftArrow /> : <RightArrow />}
            </Button> */}
            <style>
              {`.filterSec::-webkit-scrollbar {
                        display: none; /* Set the background color of the scrollbar */
                      }`}
            </style>
          </Box>
        )}
      </Box>
      {/* {!isDataLoading &&  */}
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          // zIndex:isSideFilter && -1,
          zIndex:width < 768 && isSideFilter && -1,
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          height={calculateDynamicHeight()}
          key={`${filters} + ${filtersJobType} + ${filtersJobStage} + ${filtersType}+${searchedJobs} +${favouriteFilter}`}
          dataLength={allCompanyHiringList?.length} //This is important field to render the next data
          next={() =>
            getCompanyHiringList(
              filters,
              lastKey,
              favouriteFilter,
              filterCompany,
              selectedCompSize,
              selectedCompType
            )
          }
          scrollThreshold={"200px"}
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Box
            sx={{
              margin: "auto",
              width: {
                padding: width <= 768 ? "10px 10px" : `0 ${newPadding}px`,
                xs: `95%`,
                md24: "100%",
              },
            }}
          >
            {query.get("company_id") && (
              <CompanyInfoCard companyid={query.get("company_id")} />
            )}
          </Box>

          <Grid
            container
            gap={width <= 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width <= 768 && "center"}
            sx={{
              position: "relative",
              padding: width <= 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: query.get("company_id")
                ? "20px"
                : width <= 768
                ? "35px"
                : "55px",
                paddingBottom: '35px !important'
            }}
          >
            {allCompanyHiringList?.length > 0
              ? allCompanyHiringList?.map((job, index) => (
                  <Box
                    sx={{
                      width: newCardWidth,
                    }}
                  >
                    <ThemeProvider theme={whosHiringTheme}>
                      <HiringCard
                        job={job}
                        setJobId={setSelectedJobId}
                        onHandleClose={onHandleClose}
                        setopenApplyJobDialog={setopenApplyJobDialog}
                        getCompanyHiringList={getCompanyHiringList}
                        favouriteFilter={favouriteFilter}
                        filterCompany={filterCompany}
                        currentPageJobsCount={currentPageJobsCount}
                        jobslength={allCompanyHiringList?.length}
                        index={index}
                      />
                    </ThemeProvider>
                  </Box>
                ))
              : showBox && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NoRecord />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                  display:none;
          }
          
          .infinite-scroll-component__outerdiv{
            width:100%
          }
                 `}
          </style>
        </InfiniteScroll>
      </Box>
      {/* } */}

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <RightArrow /> : <LeftArrow />}
        </Button> */}
         <Box sx={{ minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight:"45px",
             }}></Box>
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                        display:none;
                      }
                      `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedCompany.current}
                topMargin={true}
                panelData={JOBS_RIGHT_COMPANIES_STATUS}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterCompany}
              />
              {/* <AllCompaniesFIlter
                panelData={JOBS_RIGHT_COMPANIES_BUTTON}
                setCompanySize={setCompanySize}
                setCompanyType={setCompanyType}
                companySize={companySize}
                companyType={companyType}
                onChangeFilter={handleDropDownFilters}
                side="right"
                color="filterButtons"
              /> */}
              <ButtonPanel
                selected={selectedSize.current}
                panelData={[ALL_SIZE, ...companySize]}
                onChangeFilter={onChangeSize}
                side="right"
                color="filterButtons"
              />
              <ButtonPanel
                selected={selectedType.current}
                panelData={[ALL_TYPE, ...companyType]}
                onChangeFilter={onChangeType}
                side="right"
                color="filterButtons"
              />
              <ButtonPanel
                selected={selectedAllPost.current}
                panelData={HIRING_RIGHT_STAGES_BUTTON_GROUP}
                onChangeFilter={onChangefavourite}
                side="right"
                color="filterButtons"
              />
            </Paper>
            {/* <Button
              variant="contained"
              sx={{
                position: "sticky",
                top: 0,
                borderRadius: "10px 0 0 10px",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="filterButtons"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpand((prevState) => !prevState);
                dispatch(setFilterExpanded(!filterExpanded));
              }}
            >
              {isSideFilter ? <RightArrow /> : <LeftArrow />}
            </Button> */}
          </Box>
        )}
      </Box>

      <CustomDialog
        show={openApplyJobDialog}
        hideButton={false}
        onDialogClose={onHandleClose}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
        isApplyJob
      >
        <ApplyJobs
          jobId={selectedJobId}
          setopenApplyJobDialog={setopenApplyJobDialog}
        />
      </CustomDialog>
    </Box>
  );
}
