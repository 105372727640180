import {
  Box,
  Button,
  Grid,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import hireTalent from "../assets/HomePage/crayon_hire_talent.svg";
import recruit from "../assets/HomePage/crayon_recruit.svg";
import findWork from "../assets/HomePage/crayon_find_work.svg";
import earnCash from "../assets/HomePage/crayon_earn_cash.svg";
import recruiterBanner from "../assets/HomePage/crayon_candidate_banner.gif";
import FindPageIcon from "../assets/HomePage/FindPageIcon.svg";
import searchIcon from "../assets/HomePage/Blue_SearchNew.svg";
import { Link, useNavigate } from "react-router-dom";
import Signup from "../components/login/signup";
// import HomeIcon from "@mui/icons-material/Home";
import flip from "../assets/NewFlipIcon.svg";
import employerBanner from "../assets/HomePage/crayon_candidate_banner.gif";
import VideoIcon from "../assets/HomePage/VideoIcon.svg";
import HomeIcon from "../assets/HomePage/HomeIcon.svg";
import FIndWorkBottomIcon from "../assets/HomePage/FIndWorkBottomIcon.svg";
import HomeTalentIcon from "../assets/HomePage/HomeTalentIcon.svg";
import jobLinkIcon from "../assets/HomePage/JobLinkIcon.svg";

export default function FindWork({
  setComponentToFlip,
  setIsRotate,
  isRotate,
  mobileView,
  setMobileView,
  setisHorizontalFlipped,
}) {
  const theme = useTheme();
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [calcPaddingX, setCalcPaddingX] = useState(
    (window.innerHeight * 150) / 1078
  );
  const [calcPaddingY, setCalcPaddingY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [buttonSizeX, setButtonSizeX] = useState(
    (window.innerHeight * 150) / 1078
  );
  const [buttonSizeY, setButtonSizeY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [buttonSizeWithoutTextX, setButtonSizeWithoutTextX] = useState(
    (window.innerHeight * 100) / 1078
  );

  const [buttonSizeWithoutTextY, setButtonSizeWithoutTextY] = useState(
    (window.innerWidth * 100) / 1920
  );

  const [middleButtonX, setMiddleButtonX] = useState(
    (window.innerHeight * 75) / 1078
  );

  const [middleButtonY, setMiddleButtonY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [paddingLeftSide, setPaddingLeftSide] = useState(
    (window.innerWidth * 150) / 1920
  );
  const [paddingRightSide, setPaddingRightSide] = useState(
    (window.innerWidth * 100) / 1920
  );

  const [homeButtonY, setHomeButtonY] = useState(
    (window.innerWidth * 50) / 1920
  );

  const [homeButtonX, setHomeButtonX] = useState(
    (window.innerHeight * 50) / 1078
  );

  const navigate = useNavigate();
  const [searchText, setSearchText] = useState();
  const [showSignup, setShowSignup] = useState(false);

  const getNewWidth = () => {
    setButtonSizeWithoutTextX((window.innerHeight * 100) / 1078);
    setButtonSizeWithoutTextY((window.innerWidth * 100) / 1920);
    setButtonSizeX((window.innerHeight * 150) / 1078);
    setButtonSizeY((window.innerWidth * 150) / 1920);
    setCalcPaddingX((window.innerHeight * 150) / 1078);
    setCalcPaddingY((window.innerWidth * 150) / 1920);
    setPaddingLeftSide((window.innerWidth * 150) / 1920);
    setPaddingRightSide((window.innerWidth * 100) / 1920);
    setMiddleButtonX((window.innerHeight * 75) / 1078);
    setMiddleButtonY((window.innerWidth * 150) / 1920);
    setHomeButtonY((window.innerWidth * 50) / 1920);
    setHomeButtonX((window.innerHeight * 50) / 1078);
  };

  const handleTalentSearch = () => {
    navigate(`/jobs?query=${encodeURIComponent(searchText)}`);
  };

  const handleCloseSignup = () => {
    setShowSignup(false);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  return mobileView ? (
    <Grid
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // padding: `${calcPaddingX}px ${calcPaddingY}px`,
        padding: "55px 30px",
      }}
      height={{
        xs: `calc(100vh - 60px)`,
        sm: `calc(100vh - 60px )`,
        md: `calc(100vh - 90px)`,
        mdl: `calc(100vh - 90px)`,
        lg: `calc(100vh - 80px)`,
        mlg: `calc(100vh - 80px)`,
        xl: `calc(100vh - 100px)`,
        xxl: "calc(100vh - 120px)",
      }}
    >
      {/* <Button
        onClick={() => {
          setComponentToFlip(3);
          setisHorizontalFlipped((prev) => !prev);
        }}
      >
        Flip
      </Button> */}

      <Box
        sx={{
          background: "white",
          width: "100%",
          height: "100%",
          position: "relative",
          borderRadius: "25px",
          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
        }}
      >
        {/* <Button
          variant="contained"
          color="deleteVideoButton"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            zIndex: "999",
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => {
            setComponentToFlip(4);
            setisHorizontalFlipped((prev) => !prev);
          }}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button> */}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.hireCandidateNew.main,
                },
                backgroundColor: theme.homepage.greyButton.main,
                height: `125px`,
                width: `125px`,
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                padding: { sm: "0px" },
                borderRadius: "25px 0px 25px 0px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // top: 0,
                // left: 0,
                zIndex: "999",
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={hireTalent}
                  // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  heihgt="50px"
                  width="50px"
                />
              }
              onClick={() => {
                setComponentToFlip(1);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            >
              hire talent
            </Button>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.findWorkNew.main,
                },
                backgroundColor: theme.homepage.findWorkNew.main,
                height: `45px`,
                width: `125px`,
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "0px 0px 10px 10px",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 10px",
                // position: "absolute",
                // top: "0%",
                // left: "50%",
                // transform: "translate(-50%, 0%)",
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={jobLinkIcon}
                  height="20px"
                  width="22px"
                />
                // <Box
                //   component={"img"}
                //   alt="talent"
                //   src={recruit}
                //   // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                //   // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                //   heihgt="50px"
                //   width="50px"
                // />
              }
              onClick={() => navigate("/jobs")}
            >
              view jobs
            </Button>
            {/* <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.homeIconNew.main,
                },
                backgroundColor: theme.homepage.homeIconNew.main,
                height: `${homeButtonX}px`,
                minWidth: `${homeButtonY}px`,
                borderRadius: "0px 0px 10px 10px ",
                padding: "0px !important",
                zIndex: "999",
                "& .MuiButton-startIcon": {
                  margin: "0px !important",
                },
              }}
              startIcon={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30px",
                    width: "30px",
                    "& .MuiSvgIcon-root": {
                      fontSize: {
                        sm: "16px",
                        md: "18px",
                        mddl: "20px",
                        lg: "25px",
                        mlg: "30px",
                      },
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    alt="talent"
                    src={HomeIcon}
                    height="25px"
                    width="20px"
                  />
                </Box>
              }
              onClick={() => {
                setComponentToFlip(0);
                setisHorizontalFlipped((prev) => !prev);

                setIsRotate(!isRotate);
              }}
            ></Button> */}
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.findWorkNew.main,
                },
                backgroundColor: theme.homepage.findWorkNew.main,
                height: `125px`,
                width: `125px`,
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                display: "flex",
                flexDirection: "column",
                borderRadius: "0px 25px 0px 25px",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // bottom: 0,
                // right: 0,
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={findWork}
                  // height={{ xs: 28, md: 40, mdl: 50, lg: 46, lgm: 60, mlg: 80 }}
                  // width={{ xs: 30, md: 42, mdl: 52, lg: 62, lgm: 62, mlg: 82 }}
                  heihgt="50px"
                  width="50px"
                />
              }
            >
              find work
            </Button>
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: { sm: "space-between" },
            }}
          >
            {/* <Box
              sx={{
                width: { md: "50%", mdl: "70%", lg: "40%", mlg: "55%" },
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingLeft: `40px`,
              }}
            >
              <Box
                component={"img"}
                alt="image"
                src={recruiterBanner}
                height={{
                  sm: "80%",
                  mdl: "80%",
                  mddl: "80%",
                  lg: "80%",
                  mlg: "77%",
                }}
                width={{
                  sm: "80%",
                  mdl: "80%",
                  mddl: "80%",
                  lg: "80%",
                  mlg: "85%",
                }}
              />
            </Box> */}
            <Box
              sx={{
                width: { md: "50%", mdl: "50%", lg: "50%", mlg: "50%" },
                height: "100%",
                display: "flex",
                alignItems: "center",
                // justifyContent: {
                //   sm: "flex-start",
                //   md: "flex-end",
                //   mdl: "flex-start",
                //   mddl: "flex-start",
                //   lg: "flex-end",
                //   mlg: "flex-end",
                // },
                justifyContent: "center",
                boxSizing: "border-box",
                // paddingLeft: `40px`,
                // paddingRight: `50px`,
              }}
            >
              <Box
                component={"img"}
                alt="image"
                src={employerBanner}
                height={{
                  sm: "60%",
                  mdl: "60%",
                  mddl: "65%",
                  lg: "70%",
                  mlg: "86%",
                }}
                width={{
                  sm: "95%",
                  mdl: "90%",
                  mddl: "85%",
                  lg: "89",
                  mlg: "89%",
                }}
                sx={{
                  aspectRatio: "16/9",
                }}
              />
            </Box>
            <Box
              sx={{
                width: { md: "50%", lg: "50%", mlg: "50%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                textAlign: "start",
                paddingLeft: `50px`,
                paddingRight: `50px`,
                boxSizing: "border-box",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box sx={{}}>
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "30px",
                        mdl: "32px",
                        mddl: "35px",
                        lg: "37px",
                        lgm: "40px",
                        mlg: "48px",
                        xl: "48px",
                      },
                      fontWeight: "bold",
                      textWrap: "nowrap",
                    }}
                  >
                    Job hunting is hard,
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "30px",
                        mdl: "32px",
                        mddl: "35px",
                        lg: "37px",
                        lgm: "40px",
                        mlg: "48px",
                        xl: "48px",
                      },
                      fontWeight: "bold",
                      textWrap: "nowrap",
                    }}
                  >
                    we get it.
                  </Typography>
                </Box>
                <Box
                  component={"img"}
                  src={FindPageIcon}
                  sx={{
                    height: "150px",
                    width: "118px",
                    marginLeft: { lg: "20px", lg1440: "60px" },
                  }}
                ></Box>
              </Box>

              <Typography
                sx={{
                  fontSize: "24px",
                  textWrap: { lg: "wrap", lg1440: "nowrap" },
                  fontWeight: "bold",
                }}
              >
                Let’s at least have some fun along the way.
              </Typography>
              <Paper
                elevation={0}
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                  height: { md: "30px", mdl: "35px", lg: "35px", mlg: "45px" },
                  width: {
                    sm: "200px",
                    md: "260px",
                    mdl: "270px",
                    mddl: "300px",
                    lg: "330px",
                    lgm: "350px",
                    mlg: "500px",
                  },
                  marginTop: "30px",
                  backgroundColor: theme.homepage.findWorkSearchBoxNew.main,
                }}
              >
                <InputBase
                  sx={{
                    fontWeight: 700,
                    color: theme.homepage.findWorkSearchBoxNewColor.main,
                    width: {
                      sm: "200px",
                      md: "260px",
                      mdl: "270px",
                      mddl: "300px",
                      lg: "300px",
                      mlg: "400px",
                    },
                    fontSize: "14px",
                  }}
                  placeholder={"Begin your search for work here…"}
                  inputProps={{ "aria-label": "search google maps" }}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault(); // Prevent the default Enter key behavior (page refresh)
                      handleTalentSearch(); // Call your function here
                    }
                  }}
                />
                <Box
                  component="img"
                  src={searchIcon}
                  sx={{
                    height: {
                      sm: "12px",
                      md: "18px",
                      mdl: "22px",
                      lg: "24px",
                      mlg: "24px",
                    },
                    width: {
                      sm: "16px",
                      md: "20px",
                      mdl: "23px",
                      lg: "25px",
                      mlg: "25px",
                    },
                    cursor: "pointer",
                  }}
                  onClick={handleTalentSearch}
                />
              </Paper>
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "35px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                If you’re here, you’re probably not loving your job. Or you’re
                just having a look-see at what else is out there. Either way,
                our aim is to leave you feeling inspired, hopeful, informed and
                most importantly, with a smile on your face thinking ‘flip, that
                was a cool experience!
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.bookDemo.main,
                },
                backgroundColor: theme.homepage.greyButton.main,
                height: `125px`,
                width: `125px`,
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                padding: { sm: "0px" },
                borderRadius: "0px 25px 0px 25px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // right: 0,
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={recruit}
                  // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  heihgt="50px"
                  width="50px"
                />
              }
              onClick={() => {
                setComponentToFlip(3);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            >
              recruit
            </Button>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                width: "100%",
                justifyContent: "center",
                position: "absolute",
                bottom: 0,
              }}
            >
              <Link
                to={`https://crayondemo.co.za/`}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
                target="_blank"
              >
                <Button
                  variant="contained"
                  sx={{
                    ":hover": {
                      backgroundColor: theme.homepage.findWorkNew.main,
                    },
                    backgroundColor: theme.homepage.findWorkNew.main,
                    height: `45px`,
                    width: `125px`,
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 10px 0px 0px",
                    display: "flex",
                    padding: "6px 10px",
                    // flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                    // position: "absolute",
                    // bottom: 0,
                    textWrap: "nowrap",
                    "& .MuiButton-startIcon": {
                      margin: "0px",
                    },
                  }}
                  startIcon={
                    <Box
                      component={"img"}
                      alt="talent"
                      src={VideoIcon}
                      height="20px"
                      width="22px"
                    />
                  }
                  onClick={() => {
                    // setComponentToFlip(4);
                    // setIsRotate(!isRotate);
                    // setisHorizontalFlipped((prev) => !prev);
                  }}
                >
                  learn more
                </Button>
                {/* <Box
                onClick={() => navigate("/jobs")}
                sx={{
                  backgroundColor: theme.palette.peachPayment.main,
                  height: "50px",
                  width: "50px",
                  borderRadius: "10px 10px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  alt="talent"
                  src={FIndWorkBottomIcon}
                  height="30px"
                  width="30px"
                />
              </Box> */}
                {/* <Button
                variant="contained"
                sx={{
                  paddingTop: "0px !important",
                  justifyContent: "flex-start !important",
                  ":hover": {
                    backgroundColor: theme.homepage.homeIconNew.main,
                  },
                  backgroundColor: theme.homepage.homeIconNew.main,
                  height: { xs: `${middleButtonX}px`, xl: "75px" },
                  width: { xs: `${middleButtonY}px`, xl: "150px" },
                  borderRadius: "25px 25px 0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: {
                    sm: "8px",
                    md: "11px",
                    mdl: "12px",
                    mddl: "13px",
                    lg: "14px",
                    mlg: "18px",
                  },
                  alignItems: "center",
                  textWrap: "nowrap",
                  lineHeight: "1",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={linkedUsers}
                    height={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 40,
                    }}
                    width={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 38,
                    }}
                  />
                }
              >
                view jobs
              </Button> */}
              </Link>
            </Box>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.earnCashNew.main,
                },
                backgroundColor: theme.homepage.greyButton.main,
                height: `125px`,
                width: `125px`,
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                padding: { sm: "0px" },
                borderRadius: "25px 0px 25px 0px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // bottom: 0,
                // left: 0,
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={earnCash}
                  // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  heihgt="50px"
                  width="50px"
                />
              }
              onClick={() => {
                setComponentToFlip(4);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            >
              earn cash
            </Button>
          </Box>
        </Box>
      </Box>

      <Signup
        openFunc={showSignup}
        closeFunc={handleCloseSignup}
        uploadedFileName={uploadedFileName}
      />
    </Grid>
  ) : (
    <Box sx={{ paddingTop: "55px" }}>
      <Box
        boxShadow={`0px 5px 10px ${theme.palette.boxShadow.main} !important`}
        sx={{
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          height: { xs: "633px", tablet: "85vh", mdl: "85vh" },
          minWidth: "340px",
          background: "white",
          margin: "0px 10px",
          // marginTop: "55px",
          position: "relative",
        }}
      >
        {/* <Button
          variant="contained"
          color="deleteVideoButton"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            zIndex: "999",
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => {
            setComponentToFlip(4);
            setisHorizontalFlipped((prev) => !prev);
          }}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.hireCandidateNew.main,
              },
              backgroundColor: theme.homepage.greyButton.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              borderRadius: "25px 0px 25px 0px",
              display: "flex",
              flexDirection: "column",
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            onClick={() => {
              setComponentToFlip(1);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={hireTalent}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
          >
            hire talent
          </Button>
          <Box
            sx={{
              position: "relative",
              // position: { tablet: "absolute", mdl: "relative" },
              // transform: { tablet: "translateY(-50%)", mdl: "" },
              // top: { tablet: "50%", mdl: "" },
              // left: { tablet: "0%", mdl: "" },
            }}
          >
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.findWorkNew.main,
                },
                backgroundColor: theme.homepage.findWorkNew.main,
                height: `45px`,
                width: `100px`,
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "0px 0px 10px 10px",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-around",
                padding: "6px 10px",
                // position: "absolute",
                // top: "0%",
                // left: "50%",
                // transform: "translate(-50%, 0%)",
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={jobLinkIcon}
                  height="20px"
                  width="22px"
                />
                // <Box
                //   component={"img"}
                //   alt="talent"
                //   src={recruit}
                //   // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                //   // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                //   heihgt="50px"
                //   width="50px"
                // />
              }
              onClick={() => navigate("/jobs")}
            >
              jobs
            </Button>
            {/* <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.homeIconNew.main,
                },
                backgroundColor: theme.homepage.homeIconNew.main,
                height: `50px`,
                minWidth: `50px`,
                borderRadius: "0px 0px 10px 10px",
                padding: "0px !important",
                zIndex: "999",
                "& .MuiButton-startIcon": {
                  margin: "0px !important",
                },
              }}
              startIcon={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30px",
                    width: "30px",
             
                  }}
                >
               
                  <Box
                    component={"img"}
                    alt="talent"
                    src={HomeIcon}
                    height="25px"
                    width="20px"
                  />
                </Box>
              }
              onClick={() => {
                setComponentToFlip(0);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            ></Button> */}
          </Box>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.findWorkNew.main,
              },
              backgroundColor: theme.homepage.findWorkNew.main,
              fontWeight: theme.typography.Bold,
              height: `100px`,
              minWidth: `100px`,
              padding: { xs: "0px" },
              borderRadius: "0px 25px 0px 25px",

              display: "flex",
              flexDirection: "column",
              fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={findWork}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
          >
            find work
          </Button>
        </Box>
        <Box
          sx={{
            // flex: "1",
            display: "flex",
            alignItems: { xs: "center", tablet: "center" },

            justifyContent: { xs: "center", tablet: "center" },
            minHeight: { xs: "273px", tablet: "auto" },
            // marginBottom: "11px",
            flex: { xs: "", tablet: "1", mdl: "" },
          }}
        >
          <Box>
            <Box
              component={"img"}
              sx={{
                objectFit: "contain",
                width: { xs: "310px", tablet: "400px", md24: "600px" },
              }}
              alt="image"
              src={recruiterBanner}
            ></Box>
          </Box>
        </Box>
        <Box sx={{ marginBottom: { tablet: "20px" } }}>
          <Box
            sx={{
              fontSize: { xs: "24px", tablet: "40px" },
              // lineHeight: { xs: "35px", tablet: "55.5px" },
              color: "#000000",
              fontWeight: 600,
              paddingLeft: "15px",
              textAlign: "right",
              paddingRight: "21px",
              marginBottom: "5px",
            }}
          >
            <Box>Job hunting is hard,</Box>
            <Box>we get it.</Box>
          </Box>
          <Box
            sx={{
              textAlign: "right",
              paddingRight: "21px",
              // display: "flex",
              justifyContent: "space-between",
              fontSize: "14px",
              lineHeight: "24px",
              color: "#000000",
              fontWeight: 600,
              // minHeight: "48px",
            }}
          >
            Let's at least have some fun along the way.
          </Box>
          <Paper
            elevation={0}
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 10px",
              height: "45px",
              width: "86%",
              margin: "auto",
              marginBottom: "17px",
              marginTop: "5px",
              backgroundColor: theme.homepage.findWorkSearchBoxNew.main,
            }}
          >
            <InputBase
              sx={{
                fontWeight: 700,
                color: theme.homepage.findWorkSearchBoxNewColor.main,
                fontSize: "14px",
                marginTop: "5px",
                width: "90%",
              }}
              placeholder={"Begin your search for work here..."}
              inputProps={{ "aria-label": "search google maps" }}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleTalentSearch();
                }
              }}
            />
            <Box
              component="img"
              src={searchIcon}
              sx={{
                height: "25px",
                width: "25px",
                cursor: "pointer",
              }}
              onClick={handleTalentSearch}
            />
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            position: "relative",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.bookDemo.main,
              },
              backgroundColor: theme.homepage.greyButton.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              padding: { sm: "0px" },
              fontWeight: theme.typography.Bold,
              borderRadius: "0px 25px 0px 25px",
              display: "flex",
              flexDirection: "column",
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={recruit}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(3);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            recruit
          </Button>

          {/* <Link
            to={`https://crayondemo.co.za/`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
            }}
            target="_blank"
          > */}
          <Link
            to={`https://crayondemo.co.za/`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
            }}
            target="_blank"
          >
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.findWorkNew.main,
                },
                backgroundColor: theme.homepage.findWorkNew.main,
                height: `45px`,
                width: `100px`,
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "10px 10px 0px 0px",
                display: "flex",
                padding: "6px 10px",
                // flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                // position: "absolute",
                // bottom: 0,
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={VideoIcon}
                  height="20px"
                  width="22px"
                />
              }
              onClick={() => {
                // setComponentToFlip(4);
                // setIsRotate(!isRotate);
                // setisHorizontalFlipped((prev) => !prev);
              }}
            >
              demo
            </Button>
          </Link>

          {/* <Box
            onClick={() => navigate("/jobs")}
            sx={{
              backgroundColor: theme.palette.peachPayment.main,
              height: "50px",
              width: "50px",
              borderRadius: "10px 10px 0 0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              component={"img"}
              alt="talent"
              src={FIndWorkBottomIcon}
              height="30px"
              width="30px"
            />
          </Box> */}
          {/* <Button
                variant="contained"
                sx={{
                  paddingTop: "0px !important",
                  justifyContent: "flex-start !important",
                  ":hover": {
                    backgroundColor: theme.homepage.homeIconNew.main,
                  },
                  backgroundColor: theme.homepage.homeIconNew.main,
                  height: { xs: `${middleButtonX}px`, xl: "75px" },
                  width: { xs: `${middleButtonY}px`, xl: "150px" },
                  borderRadius: "25px 25px 0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: {
                    sm: "8px",
                    md: "11px",
                    mdl: "12px",
                    mddl: "13px",
                    lg: "14px",
                    mlg: "18px",
                  },
                  alignItems: "center",
                  textWrap: "nowrap",
                  lineHeight: "1",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={linkedUsers}
                    height={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 40,
                    }}
                    width={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 38,
                    }}
                  />
                }
              >
                view jobs
              </Button> */}
          {/* </Link> */}
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.earnCashNew.main,
              },
              backgroundColor: theme.homepage.greyButton.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              padding: "0px",
              display: "flex",
              flexDirection: "column",
              borderRadius: "25px 0px 25px 0px",
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={earnCash}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(4);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            earn cash
          </Button>
        </Box>
        <Signup
          openFunc={showSignup}
          closeFunc={handleCloseSignup}
          uploadedFileName={uploadedFileName}
        />
      </Box>
    </Box>
  );
}
