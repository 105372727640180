import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ALERT_TYPE, CLAIMS } from "../../../../utils/Constants";
import { TableCells } from "../../../../utils/CommonComponent";
import companyLogo from "../../../../assets/Crayon_Favicon.svg";
import { getClaims } from "../../../../redux/admin/maintenance";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import { dateConverterMonth } from "../../../../utils/DateTime";

export default function Claims({
  openClaim,
  handleClose,
  setCompanyClaims,
  claimVoucherInfo,
  setSelectedCompany,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [claimedVoucherInfo, setClaimedVoucherInfo] = useState([]);
  const [lastKey, setLastKey] = useState(false);

  const getClaimsInfo = async (lastKeyy) => {
    try {
      const { payload } = await dispatch(
        getClaims({
          lastKey: lastKeyy,
          voucher_id: claimVoucherInfo?.voucher_id,
        })
      );
      if (payload?.status === "success") {
        if (payload?.pageNumber === 0) {
          setClaimedVoucherInfo(payload?.data);
          setLastKey(payload?.pageNumber + 1);
        } else if (payload?.data?.length > 0) {
          setClaimedVoucherInfo((prev) => [...prev, ...payload?.data]);
          setLastKey(payload?.pageNumber + 1);
        }
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    getClaimsInfo(0);
  }, []);

  return (
    <Dialog
      open={openClaim}
      fullWidth={true}
      //   maxWidth={"xs"}
      scroll="body"
      padding={0}
      zIndex='99999999999999999999'
      sx={{
        // zIndex: 100000,
        "& .MuiPaper-root": {
          borderRadius: "25px",
          maxWidth: "728px !important",
        },
      }}
    >
      <DialogTitle sx={{ padding: "24px 20px" }}>
        <Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
          >
            {claimVoucherInfo?.name}
          </Typography>

          <Box
            sx={{
              width: "105px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
            >
              Claims
            </Typography>
            <Button
              variant="contained"
              color="submitButton"
              sx={{
                minWidth: "fit-content",
                height: "26px",
                borderRadius: "7px",
                padding: "7px",
                color: "black",
              }}
            >
              {claimVoucherInfo?.claims}
            </Button>
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
      </DialogTitle>
      <Divider />
      <Box>
        <InfiniteScroll
          style={{
            overflow: "hidden",
            scrollbarWidth: "thin",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            // width: "97%",
          }}
          dataLength={claimedVoucherInfo?.length}
          next={() => getClaimsInfo(lastKey)}
          scrollThreshold={"100px"}
          scrollableTarget={"jobList"}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <TableContainer component={Paper} sx={{ height: "300px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCells
                    sx={{
                      padding: "0px 16px 8px 20px",
                    }}
                  ></TableCells>
                  <TableCells
                    sx={{
                      padding: "0px 16px 8px 20px",
                    }}
                  >
                    <Typography variant="subtitle1" fontWeight="bold">
                      Company
                    </Typography>
                  </TableCells>
                  <TableCells
                    sx={{
                      padding: "0px 16px 8px 20px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ textAlign: "center" }}
                    >
                      User
                    </Typography>
                  </TableCells>
                  <TableCells
                    sx={{
                      padding: "0px 16px 8px 20px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ textAlign: "center" }}
                    >
                      Date Claimed
                    </Typography>
                  </TableCells>
                  <TableCells
                    sx={{
                      padding: "0px 16px 8px 20px",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      fontWeight="bold"
                      sx={{ textAlign: "center" }}
                    >
                      Coin Expiry Date
                    </Typography>
                  </TableCells>
                </TableRow>
              </TableHead>

              <TableBody>
                {claimedVoucherInfo?.map((row) => (
                  <TableRow
                    key={row.voucher_claim_id}
                    style={{
                      "& .css-12zgwp-MuiTableCell-root": {
                        padding: "0 16px !important",
                      },
                    }}
                  >
                    <TableCells
                      sx={{
                        padding: "0px 16px 8px 20px",
                      }}
                    >
                      {
                        <Box
                          component={"img"}
                          src={row?.company?.profile_url==="No URL"? companyLogo : row?.company?.profile_url}
                          sx={{ width: 40, height: 40, borderRadius: "10px" }}
                        />
                      }
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 16px 8px 20px",
                        fontWeight: theme.typography.Bold,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedCompany(row);
                        handleClose();
                        setCompanyClaims(true);
                      }}
                    >
                      {row?.company?.name}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 16px 8px 20px",
                        textAlign: "center",
                      }}
                    >
                      {row?.user?.first_name} {row?.user?.last_name}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 16px 8px 20px",
                        textAlign: "center",
                      }}
                    >
                      {dateConverterMonth(row?.created_at)}
                    </TableCells>
                    <TableCells
                      sx={{
                        padding: "0px 16px 8px 20px",
                        textAlign: "center",
                      }}
                    >
                      {dateConverterMonth(row?.expiry_coin_date)}
                    </TableCells>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                      width: 7px !important;
                      background-color: #F5F5F5; /* Set the background color of the scrollbar */
                    }

                    .infinite-scroll-component__outerdiv {
                      height:100%;
                      width: 100%;
                    }

                    .infinite-scroll-component::-webkit-scrollbar-thumb {
                      background-color: #888c; /* Set the color of the scrollbar thumb */
                    }`}
          </style>
        </InfiniteScroll>
      </Box>
    </Dialog>
  );
}
