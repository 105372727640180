import { Box, Button, Dialog, Grid, Paper } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import {
  ALERT_TYPE,
  CANDIDATE_PROFILE,
  ERROR_MSG,
  PROMOTER_MY_PROFILE_LEFT,
} from "../../../utils/Constants";
import LeftSideProfilePanel from "../../candidate/myProfile/LeftSideProfilePanel";
import grey_profile_collaborator from "../../../assets/Personality Logos/crayon_personality_grey_collaborator.svg";
import grey_profile_contemplator from "../../../assets/Personality Logos/crayon_personality_grey_contemplator.svg";
import grey_profile_challenger from "../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import grey_profile_character from "../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import leftIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import rightIcon from "../../../assets/Black_Right_Next - Copy.svg";
import TheBasics from "../../candidate/myProfile/TheBasics";
import joinedGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_joined.svg";
import personalAssessmentGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_personal assesment.svg";
import bountyEarnedGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_bounty earned.svg";
import profileDoneGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_profile done.svg";
import refferalGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_refferal signed up.svg";
import sharedJobGray from "../../../assets/Badges/promoter/Gray badges/crayon_promoter_grey_shared a job.svg";
import joined from "../../../assets/Badges/promoter/Coloured/crayon_promoter_hired.svg";
import personalAssessment from "../../../assets/Badges/promoter/Coloured/crayon_promoter_personal_assesment.svg";
import bounty from "../../../assets/Badges/promoter/Coloured/crayon_promoter_bounty earned.svg";
import profileDone from "../../../assets/Badges/promoter/Coloured/crayon_promoter_profile done.svg";
import sharedJob from "../../../assets/Badges/promoter/Coloured/crayon_promoter_shared a job.svg";
import refferal from "../../../assets/Badges/promoter/Coloured/crayon_promoter_refferal signed up.svg";
import {
  changeToCandidate,
  getCandidateBadge,
  getProfile,
  setCurrentStep,
  setCurrentStepPromoter,
  uploadProfilePic,
} from "../../../redux/candidate/myProfileSlice";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { getCountries } from "../../../redux/employer/postJobSlice";
import {
  getLanguage,
  getNationality,
} from "../../../redux/candidate/myCvSlice";
import OpenCameraDialog from "../../common/ProfilePicCaptureDialog";
import SavaAndExit from "../../candidate/myProfile/dialog/SavaAndExit";
import { nanoid } from "@reduxjs/toolkit";
import SwitchToCandidate from "../SwitchToCandidate";
import SwitchConfirmationBox from "../SwitchConfirmationBox";
import CropModal from "../../employer/myProfile/cropModal";
import useViewport from "../../common/ViewPort";
import CandidateMyProfileCardRow from "../../candidate/myProfile/CandidateMyProfileCardRow";
import { setFilterExpanded } from "../../../redux/guest/jobsSlice";
import { useNavigate } from "react-router-dom";
import { setSideFilter } from "../../../redux/login/loginSlice";

export default function PromoterProfileCard() {
  const dispatch = useDispatch();
  const { width } = useViewport();
  const hiddenFileInput = useRef(null);
  const [anchorElCompanyDialog, setAnchorElCompanyDialog] = useState(null);
  const [candidateBadge, setCandidateBadge] = useState([]);
  const [followedCompanyCount, setFollowedCompanyCount] = useState(0);
  const [leftExpanded, setLeftExpand] = useState(true);
  const { isSideFilter } = useSelector((state) => state.login);
  const [expanded, setExpanded] = useState(false);
  const [selectedStep, setSelectedStep] = useState(1);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [imagePreview, setImagePreview] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [imageName, setImageName] = useState("My pic");
  const [openCameraDialog, setOpenCameraDialog] = useState(false);
  const [arrSlider2, setArrSlider2] = useState([
    grey_profile_contemplator,
    grey_profile_collaborator,
    grey_profile_character,
    grey_profile_challenger,
  ]);
  const currentStepPromoter = useSelector(
    (state) => state?.myProfileSli?.currentStepPromoter
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [badgesArr, setBadgesArr] = useState([
    {
      id: 1,
      name: "joined",
      status: false,
      colored: joined,
      gray: joinedGray,
    },
    {
      id: 2,
      name: "profile_pic",
      status: false,
      colored: profileDone,
      gray: profileDoneGray,
    },
    {
      id: 3,
      name: "personality_assesment",
      status: false,
      colored: personalAssessment,
      gray: personalAssessmentGray,
    },
    {
      id: 4,
      name: "shared_job",
      status: false,
      colored: sharedJob,
      gray: sharedJobGray,
    },
    {
      id: 5,
      name: "referral_signedup",
      status: false,
      colored: refferal,
      gray: refferalGray,
    },
    {
      id: 6,
      name: "bounty",
      status: false,
      colored: bounty,
      gray: bountyEarnedGray,
    },
  ]);

  const [arrSlider3, setArrSlider3] = useState([]);
  const [profileCompletion, setProfileCompletion] = useState({
    totalProfileCompletion: 0,
    profileCompletion: 0,
    cvBasics: 0,
    workLife: 0,
    studyLife: 0,
    refrences: 0,
  });
  const [windowWidth, setWindowWidth] = useState();
  const [image, setImage] = useState([]);
  const [rightExpanded, setRightExpanded] = useState(true);
  const [profile, setProfile] = useState(CANDIDATE_PROFILE);
  const [errors, setErrors] = useState([]);
  const [switchCandidate, setSwitchCandidate] = useState(false);
  const [switchCandidateConfirm, setSwitchCandidateConfirm] = useState(false);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [openSaveAndExitDialog, setOpenSaveAndExitDialog] = useState(false);
  const [imageBlob, setImageBlob] = useState();
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);
  const openAddCompanyDialog = Boolean(anchorElCompanyDialog);

  const handleOpenSaveAndExitDialog = () => {
    setOpenSaveAndExitDialog((prevState) => !prevState);
  };
  const handleImageClick = () => {
    setImagePreview(null);
    hiddenFileInput?.current?.click();
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (width > 1024) {
      // setLeftExpand(true);
      // setRightExpanded(true);
      dispatch(setSideFilter(true));
    } else {
      dispatch(setSideFilter(false));
      // setLeftExpand(false);
      // setRightExpanded(false);
    }
  }, [width]);

  useEffect(() => {
    if (currentStepPromoter) {
      setExpanded(true);
      setSelectedStep(2);
      dispatch(setCurrentStepPromoter(false));
    }
  }, [currentStepPromoter]);
  const cropInputFUnction = (e) => {
    const files = e?.target?.files;
    if (files && files?.length > 0) {
      const reader = new FileReader();
      reader.onload = () => setImage(reader?.result);
      reader?.readAsDataURL(files?.[0]);
    }
  };
  const handleImageChange = async (event, name) => {
    if (event?.target?.files?.[0] === undefined) {
      return;
    }
    cropInputFUnction(event);
    const render = new FileReader();
    setZoom(1);
    setImageName(event?.target?.files?.[0].name);
    const imageData = window.URL.createObjectURL(
      new Blob(event?.target?.files, { type: "application/*" })
    );
    setImagePreview(imageData);
    setOpenEditImage(true);
    event.target.value = "";
  };

  const handleSubscriptionOk = async () => {
    const formData = new FormData();
    const blobTofile = new File([imageBlob], imageName, {
      type: "image/jpeg",
    });
    formData.append("profile-pic", blobTofile);
    try {
      const { payload } = await dispatch(uploadProfilePic(formData));
      if (payload?.status === "success") {
        setProfile({ ...profile, profile_url: payload?.data });
        setImage(payload?.data);
        getBadgeData();
        setOpenEditImage(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Profile pic uploaded Successfully!",
          })
        );
        setImageBlob();
        setCroppedImageUrl(null);
      } else if (payload?.status === "error") {
        setImageName("My pic");
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Image format not supported",
          })
        );
      }
    } catch (error) {
      setImageName("My pic");
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "",
        })
      );
    }
  };

  const handlePhotoCapture = () => {
    setOpenCameraDialog((prevState) => !prevState);
  };

  const handleLeftClick = () => {
    setArrSlider2([
      ...arrSlider2?.slice(arrSlider2?.length - 1),
      ...arrSlider2?.slice(0, arrSlider2?.length - 1),
    ]);
  };

  const handleRightClick = () => {
    setArrSlider2([...arrSlider2?.slice(1), ...arrSlider2?.slice(0, 1)]);
  };

  const handleLeftClick3 = () => {
    setBadgesArr([
      ...badgesArr?.slice(badgesArr?.length - 1),
      ...badgesArr?.slice(0, badgesArr?.length - 1),
    ]);
  };

  const handleRightClick3 = () => {
    setBadgesArr([...badgesArr?.slice(1), ...badgesArr?.slice(0, 1)]);
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 600) {
      // xs
      return 100;
    } else if (screenWidth < 900) {
      // sm
      return 100;
    } else if (screenWidth < 1366) {
      // md
      return 100;
    } else if (screenWidth < 1650) {
      return 100;
    } else if (screenWidth < 1785) {
      return 100;
    } else if (screenWidth < 1920) {
      // lg
      return 100;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };

  const handleClickMyInfo = () => {
    setExpanded((prevState) => !prevState);
    expanded ? setSelectedStep(1) : setSelectedStep(2);
  };

  const handleStep = (step) => {
    if (step === 1) {
      setExpanded(false);
    }
    if (step === 2) {
      setExpanded(true);
    } else if (step === 3) {
      navigate("/assessment");

      // window.open(
      //   "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
      // );
    }
  };

  const getProfileData = (profileData) => {
    setProfile(profileData);
  };

  const getAllData = async () => {
    try {
      const { payload } = await dispatch(getProfile());
      if (payload?.status === "success") {
        if (typeof payload?.data == "string") {
          setProfile({});
        } else {
          setArrSlider3([
            {
              value: payload?.data?.totoalshare,
              color: "Applied",
              name: "shares",
            },
            {
              value: payload?.data?.totoalreferrals,
              color: "Shortlisted",
              name: "referrals",
            },
            {
              value: payload?.data?.totoalshortlisted,
              color: "Interviewd",
              name: "bounty earned",
            },
          ]);
          setProfile({
            ...payload?.data,
            reveal_gender: payload?.data?.reveal_gender,
            criminal_flag: payload?.data?.criminal_flag
              ? payload?.data?.criminal_flag
              : true,
            company_type: payload?.data?.company_type,
            company_size: payload?.data?.company_size,
            company_culture: payload?.data?.company_culture?.map((item) => ({
              value: item?.culture_id,
              label: item?.name,
            })),
          });
          setImage(payload?.data?.profile_url);
          const profileCompletionPercentage = {
            totalProfileCompletion: payload?.data?.profile_percent_complete,
            profileCompletion: payload?.data?.profile_completed ? 25 : 0,
            cvBasics: payload?.data?.cv_basic_completed ? 10 : 0,
            workLife: payload?.data?.work_life_completed ? 5 : 0,
            studyLife: payload?.data?.study_life_completed ? 5 : 0,
            refrences: payload?.data?.refrences_completed ? 5 : 0,
          };
          setProfileCompletion(profileCompletionPercentage);
          setFollowedCompanyCount(payload?.data?.followCompanyCount);
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleInfoSave = () => {
    setSelectedStep(1);
    dispatch(setCurrentStep(0));
    handleStep(1);
    getAllData();
  };

  const getBadgeData = async () => {
    try {
      const { payload } = await dispatch(getCandidateBadge());
      if (payload?.status === "success") {
        setCandidateBadge(payload?.data);
        setBadgesArr(
          badgesArr?.map((item) => {
            return payload?.data?.hasOwnProperty(item?.name)
              ? { ...item, status: payload?.data?.[item?.name] }
              : item;
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: error,
        })
      );
    }
  };

  const getAll = async () => {
    try {
      dispatch(setLoading(true));
      await Promise.all([
        dispatch(getCountries()),
        dispatch(getNationality()),
        dispatch(getLanguage()),
      ]);

      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const handleImageData = (data) => {
    const id = nanoid();
    const base64Image = data?.replace(/^data:image\/jpeg;base64,/, "");
    setImage(data);
    const binaryData = atob(base64Image);
    const blob = new Blob(
      [new Uint8Array([...binaryData]?.map((char) => char?.charCodeAt(0)))],
      { type: "application/*" }
    );
    setImageName(id + ".jpeg");
    const imageData = window.URL.createObjectURL(blob);
    setZoom(1);
    setImagePreview(imageData);
    setOpenEditImage(true);
  };

  const switchToCandidate = async () => {
    try {
      const { payload } = await dispatch(changeToCandidate());
      if (payload?.status === "success") {
        setSwitchCandidate(false);
        setSwitchCandidateConfirm(false);
        localStorage.clear();
        window.location.reload();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("resize", calculateHeight);
    return () => {
      window.removeEventListener("resize", calculateHeight);
    };
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    getAll();
    getAllData();
    getBadgeData();
  }, []);

  const renderFooter = <></>;

  const handleSubscriptionCancel = () => {
    setImageName("My pic");
    setOpenEditImage(false);
    setImageBlob();
    setCroppedImageUrl(null);
    setImage();
  };
  //   const profileProgressDataValues = useSelector(
  //     (state) => state.myProfileSli.profileProgressDataValues
  //   );
  //   let decodedToken = getDecodedToken(getToken());
  // console.log(profileProgressDataValues,"profileProgressDataValues")
  // useEffect(()=>{
  //   const role_id = decodedToken?.data?.role_id;
  //   if(profileProgressDataValues?.my_info === 0 && (role_id ===5)){
  //     // dispatch(setProfileSteps(1));
  //     setExpanded(true)
  //   }
  // },[])
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        {width < 768 && width !== 0 && isSideFilter && (
          <Box
          onClick={() => dispatch(setSideFilter(false))}  
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0, .2)",
            }}
          ></Box>
        )}
        <Box
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 1024 ? "absolute" : "relative"}`,
            // width: isSideFilter
            //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: isSideFilter ? "125px" : "30px",
            zIndex: "30",
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpanded((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {isSideFilter ? <LeftArrow /> : <RightArrow />}
          </Button> */}
          <Box
            sx={{
              minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight: "45px",
            }}
          ></Box>
          {isSideFilter && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: `${width < 768 && "135px"}`,
                paddingTop: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  // marginRight: "1px",
                  boxShadow: 0,
                  // maxWidth: `${width < 768 && "150px"}`,
                  maxWidth: "125px",
                }}
              >
                <LeftSideProfilePanel
                  panelData={PROMOTER_MY_PROFILE_LEFT}
                  side="left"
                  onChangeFilter={handleStep}
                  selectedButton={selectedStep}
                  color="filterButtons"
                />
              </Paper>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            // zIndex: isSideFilter && -1,
            zIndex:width < 768 && isSideFilter && -1,
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            flexDirection: "column",
            width: `calc(100vw - 210px)`,
            position: "sticky",
            overflowY: "scroll",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: { xs: "center", xl: "flex-start" },
              position: "relative",
              height: "auto",
              flexDirection: "column",
              // marginLeft: { xs: "10px", lg: "30px", xl: "35px" },
              // marginRight: { xs: "10px", lg: "10px", xl: "35px" },
              // width: { xs: "90%", md24: "96.5%" },
              gap: expanded ? "40px" : "0px",
              width: "100%",
              paddingLeft: "30px",
              paddingRight: "30px",
              marginTop: "55px",
            }}
          >
            <CandidateMyProfileCardRow
              profile={profile}
              handlePhotoCapture={handlePhotoCapture}
              hiddenFileInput={hiddenFileInput}
              handleImageChange={handleImageChange}
              handleImageClick={handleImageClick}
              calculateHeight={calculateHeight}
              handleLeftClick={handleLeftClick}
              arrSlider2={arrSlider2}
              handleRightClick={handleRightClick}
              expanded={expanded}
              handleClickMyInfo={handleClickMyInfo}
            />
            {!expanded ? (
              <Box
                sx={{
                  top: { xs: "30px", md24: "30px", mdl: "70px" },
                  padding: "0px !important",
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "0px !important",
                  position: "relative",
                  background: "#ffff",
                  display: "flex",
                  flexDirection: `${width > 1024 ? "row" : "column"}`,
                  gap: "10px",
                  marginX: { xs: "10px", lg: "0px" },
                }}
                width={"100%"}
              >
                <Button
                  variant="contained"
                  color="yoco"
                  sx={{
                    height: "45px",
                    width: "125px",
                    fontSize: {
                      xs: "14px",
                      xl: "14px",
                    },
                    borderRadius: "25px 0px 10px 0px",
                    cursor: "default",
                    ":hover": {
                      boxShadow: "none !important",
                    },
                  }}
                >
                  my badges
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    paddingY: "15px",
                    gap: "10px",
                    flexWrap: "wrap",
                    justifyContent: { xs: "center", md24: "flex-start" },
                  }}
                  // width={width < 1024 ? "100%" : `calc(100% - 369px)`}
                  width={width < 1024 ? "100%" : `100%`}
                >
                  {windowWidth < 1100 && (
                    <Box
                      component={"img"}
                      src={leftIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleLeftClick3}
                    />
                  )}
                  {badgesArr?.map((item) => {
                    return (
                      <Box
                        component="img"
                        height={{
                          xs: 90,
                          lg: 90,
                          lgm: 110,
                          mlg: 110,
                          xl: 125,
                          xxl: 150,
                        }}
                        alt="Joined"
                        src={item?.status ? item?.colored : item?.gray}
                      />
                    );
                  })}
                </Box>
                {windowWidth < 1100 && (
                  <Box
                    sx={{
                      height: "120px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={rightIcon}
                      sx={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                      onClick={handleRightClick3}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
            {expanded ? (
              <Box
                sx={{
                  marginTop: "30px",
                  padding:
                    width < 1024
                      ? "20px 10px 0px 10px !important"
                      : "30px 30px 0px 30px !important",
                  marginX: width < 1024 ? "10px" : "0px",
                  cursor: "auto !important",
                  boxShadow: "0px 5px 10px #00000029",
                  borderRadius: "25px",
                  marginBottom: "30px !important",
                  position: "relative",
                  background: "#ffff",
                  width: { xs: "90%", md24: "90.5%", mdl: "96.5%" },
                }}
              >
                <TheBasics
                  profile={profile}
                  handleProfileData={getProfileData}
                  errors={errors}
                  setErrors={setErrors}
                  handleInfoSave={handleInfoSave}
                />
              </Box>
            ) : null}
          </Grid>
        </Box>
        <SavaAndExit
          show={openSaveAndExitDialog}
          handleOpen={handleOpenSaveAndExitDialog}
        />
      </Box>
      <Dialog open={openCameraDialog} fullWidth={true} maxWidth={"sm"} zIndex='99999999999999999999'>
        <OpenCameraDialog
          onRecordingStop={setOpenCameraDialog}
          handleImage={handleImageData}
        />
      </Dialog>
      {openEditImage && (
        <CropModal
          handleOpen={openEditImage}
          handleClose={setOpenEditImage}
          handleSubscriptionOk={handleSubscriptionOk}
          handleSubscriptionCancel={handleSubscriptionCancel}
          imagePreview={imagePreview}
          image={image}
          setImage={setImage}
          croppedImageUrl={croppedImageUrl}
          setCroppedImageUrl={setCroppedImageUrl}
          imageBlob={imageBlob}
          setImageBlob={setImageBlob}
        />
      )}
      {switchCandidate && (
        <SwitchToCandidate
          handleClose={() => setSwitchCandidate(false)}
          openEdit={switchCandidate}
          setSwitchCandidate={setSwitchCandidate}
          setSwitchCandidateConfirm={setSwitchCandidateConfirm}
        />
      )}
      {switchCandidateConfirm && (
        <SwitchConfirmationBox
          handleClose={() => setSwitchCandidateConfirm(false)}
          openEdit={switchCandidateConfirm}
          handleSwitch={switchToCandidate}
        />
      )}
    </>
  );
}
