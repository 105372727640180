import { Box, Button, useTheme } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../components/common/CloseIcon";
import useViewport from "../components/common/ViewPort";
import { Link } from "react-router-dom";
import Facebookcon from "../assets/Footer/crayon_social_Facebook.svg";
import InstagramIcon from "../assets/Footer/crayon_social_Instagram.svg";
import TwitterIcon from "../assets/Footer/crayon_social_X.svg";
import LinkedinIcon from "../assets/Footer/crayon_social_LinkedIn.svg";
import LinkedinIconMobile from "../assets/Footer/crayon_social_LinkedIn_mobile.svg";
import YoutubeIcon from "../assets/Footer/crayon_social_Youtube.svg";

const FooterTabsNew = ({
  tabs,
  colors,
  activeTab,
  setActiveTab,
  tabComponent,
  setShowFooter,
}) => {
  const [footerTabs, setFooterTabs] = useState(tabs);
  const { width } = useViewport();
  const theme = useTheme();

  const handleTabChange = (item) => {
    setActiveTab(item);
    const remainingTabs = tabs?.filter((t) => t !== item);
    const newTabs = [...remainingTabs, item];
    setFooterTabs(newTabs);
  };

  return (
    <Box
      sx={{ position: "fixed", bottom: "0", width: "100vw", paddingBottom: 0,  background: "#F2F4FB"}}
    >
      <Box sx={{ position: "relative" }}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {footerTabs?.map((item, index) => {
            return (
              <Button
                className={`footer-btn ${item === activeTab ? "active" : ""}`}
                sx={{
                  background: `${colors[item]}!important`,
                  width: "100%",
                  color: "white",
                  fontSize: {
                    md: "12px",
                    lg: "14px",
                    mlg: "14px",
                    lgm: "16px",
                    xl: "16px",
                    xxl: "16px",
                  },
                  fontWeight: "700",
                  borderRadius: "25px 25px 0 0",
                  position: "relative",
                  zIndex: item === activeTab ? "1888888" : index,
                  marginTop:
                    item === activeTab
                      ? "-45px !important"
                      : `-45px !important`,
                  height: item === activeTab ? "45px" : "90px",
                  // overflow: "hidden",
                  padding: item !== activeTab ? "0 0 45px 0" : "0",
                  transition: "all 0.3s ease",
                }}
                onClick={() => {
                  handleTabChange(item);
                }}
              >
                {item}
                {width <= 600 && item === activeTab && (
                  <CloseIcon
                    color="white"
                    isWhite={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowFooter(false);
                      setActiveTab("how this page works");
                      setFooterTabs(tabs);
                    }}
                  />
                )}
              </Button>
            );
          })}
        </Box>
        <Box
          sx={{
            height: width <= 600 ? "40px" : "200px",
            maxHeight: width <= 600 ? "40px" : "200px",
            minHeight: width <= 600 ? "40px" : "200px",
            width: width <= 600 ? "200px" : "40px",
            position: "absolute",
            right: width <= 600 ? "50%" : 0,
            transform: width <= 600 ? "translateX(50%)" : "none",
            bottom: width <= 600 ? "0" : "unset",
            top: width <= 600 ? "unset" : "calc(50% + 20px)",
            borderRadius:
              width <= 600 ? "10px 10px 0px 0px" : "10px 0px 0px 10px",
            display: "flex",
            flexDirection: width <= 600 ? "row" : "column",
            gap: "0px",
            zIndex: "9999999999999999",
          }}
        >
          <Link
            to={`https://x.com/crayon_za`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
              height: "40px",
              maxHeight: "40px",
              maxWidth: "40px",
              minWidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            target="_blank"
          >
            <Box
              component="img"
              sx={{
                height: "40px",
                maxHeight: "40px",
                maxWidth: "40px",
                minWidth: "40px",
                minHeight: "40px",
                width: "40px",
              }}
              src={TwitterIcon}
            ></Box>
          </Link>
          <Link
            to={`https://facebook.com/crayonjobs`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWiidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            target="_blank"
          >
            <Box
              component="img"
              sx={{
                height: "40px",
                maxHeight: "40px",
                maxWidth: "40px",
                minWidth: "40px",
                minHeight: "40px",
                width: "40px",
              }}
              src={Facebookcon}
            ></Box>
          </Link>
          <Link
            to={`https://youtube.com/@crayonjobs`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
              height: "40px",
              maxHeight: "40px",
              maxWidth: "40px",
              minWidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            target="_blank"
          >
            <Box
              component="img"
              sx={{
                height: "40px",
                maxHeight: "40px",
                maxWidth: "40px",
                minWidth: "40px",
                minHeight: "40px",
                width: "40px",
              }}
              src={YoutubeIcon}
            ></Box>
          </Link>
          <Link
            to={`https://www.instagram.com/crayon_za/`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            target="_blank"
          >
            <Box
              component="img"
              sx={{
                height: "40px",
                maxHeight: "40px",
                maxWidth: "40px",
                minWidth: "40px",
                minHeight: "40px",
                width: "40px",
              }}
              src={InstagramIcon}
            ></Box>
          </Link>
          <Link
            to={`https://linkedin.com/company/crayon_za`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
              height: "40px",
              maxheight: "40px",
              maxWidth: "40px",
              minWidth: "40px",
              minHeight: "40px",
              width: "40px",
            }}
            target="_blank"
          >
            <Box
              component="img"
              sx={{
                height: "40px",
                maxheight: "40px",
                maxWidth: "40px",
                minWidth: "40px",
                minHeight: "40px",
                width: "40px",
              }}
              src={width <= 600 ? LinkedinIconMobile  : LinkedinIcon}
            ></Box>
          </Link>
        </Box>
        <Box
          sx={{
            height: {
              xs: `400px`,
              sm: `400px`,
              md: `400px`,
              mdl: `400px`,
              lg: `400px`,
              lg1440: '420px',
              lgmm: '430px',
              lgm: '450px',
              mlg: `550px`,
              xl: `550px`,
              xxl: "550px",
            },
            overflowY: "scroll",
          }}
        >
          {tabComponent[activeTab]}
        </Box>
      </Box>
    </Box>
  );
};

export default FooterTabsNew;
