import { createTheme } from "@mui/material/styles";

import { candidateMyCVDefaultTheme } from "../Theme";
const candidateMyJobsTheme = createTheme({
  ...candidateMyCVDefaultTheme,
  palette: {
    ...candidateMyCVDefaultTheme.palette,
    accessListButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    trackButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    recruitButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    directButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    announceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    workSetupButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    manageButton: {
      main: "#0077C2",
      contrastText: "#FFF",
    },
    talentButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    flipButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    newFlipColor:{
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    editJobButton: {
      main: "#DD3EB8",
      contrastText: "#FFF",
    },
    pauseJobButton: {
      main: "#FF9900",
      contrastText: "#FFF",
    },
    closeJobButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    duplicateJobButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    viewJobButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    reactiveJobButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    skillsButton: {
      main: "#00A9E0",
      contrastText: "#FFF",
    },
    toolsButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    traitsButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    addEditMemberButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },

    topUpCoinButton: {
      main: "#FF9900",
      contrastText: "#FFF",
    },
    extendJobButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    jobTypeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    chart: {
      ...candidateMyCVDefaultTheme.palette.chart,
      Interviewed: "#CCF381",
      Applied: "#FFCB00",
      Shortlisted: "#FFCB00",
      grit_score: "#482683",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      weakMatch: "#D1011B",
    },
  },
});

export { candidateMyJobsTheme };
