import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import profile from "../../../../assets/user_logo.svg";
import flip from "../../../../assets/NewFlipIcon.svg";
import job_exp from "../../../../assets/Padding Included/Green_Duration.svg";
import BlueCurrency from "../../../../assets/Blue_Salary.svg";
import redLocation from "../../../../assets/Red_Location.svg";
import calendar from "../../../../assets/Padding Included/Yellow_Calendar.svg";
import matchMeIcon from "../../../../assets/Padding Excluded/Black_Match_me.svg";
import viewCVIcon from "../../../../assets/Padding Excluded/Black_Lock.svg";
import applyIcon from "../../../../assets/Padding Excluded/Black_Follower.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../../i18n/locale";
import { ALERT_TYPE } from "../../../../utils/Constants";
import TextWrapper from "../../../common/TextWrapper";
import { convertDatetimeAgo } from "../../../../utils/DateTime";
import { useDispatch } from "react-redux";
import {
  favouriteJob,
  getTalentBackSideData,
  setTalentList,
} from "../../../../redux/guest/talentSlice";
import { Link } from "react-router-dom";
import { formatCurrencyWithCommas } from "../../../../utils/Currency";
import favouriteGrey from "../../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../../assets/Crayon Favourite Icon_Red.svg";
import profile_challenger from "../../../../assets/Personality Logos/personality_characters_challenger.svg";
// import profile_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import profile_grey_challenger from "../../../../assets/Personality Logos/crayon_personality_grey_challenger.svg";
import profile_grey_character from "../../../../assets/Personality Logos/crayon_personality_grey_character.svg";
import profile_character from "../../../../assets/Personality Logos/crayon_personality_character.svg";
import profile_collaborator from "../../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import profile_contemplator from "../../../../assets/Personality Logos/crayon_personality_contemplator.svg";
import Slider2 from "../../../common/Slider2";
import Slider from "../../../common/Slider";
import { setAlert } from "../../../../redux/configSlice";
import MUIRadialChart from "../../../common/MUIRadialChart";
import { Card, CircularProgress } from "@mui/material";
import TalentMatch from "../TalentMatch";
import RunMatch from "../RunMatch";
import ShortlistBox from "../ShortlistBox";
import {
  calculateMUIHeight,
  getDecodedToken,
  getToken,
  setCookie,
  splitString,
} from "../../../../utils/Common";
import playButtonIcon from "../../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../../assets/Padding Excluded/Black_reactive_job.svg";
import viewCVIconUnlock from "../../../../assets/Padding Excluded/Black_Unlock.svg";
import SubscriptionPopModal from "../SubscriptionPopModal";
import NewSlider from "../../../common/NewSlider";
import * as Flag from "country-flag-icons/react/3x2";
import TalentSVGButton from "../../../common/TalentSVGButton";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import JobsSlider from "../../jobs/JobsSlider";

export default function TalentCard({
  index,
  talent,
  selectedJobID,
  setSelectedJobID,
  setisFlipped,
  handleReviewVideoDialog,
  indexNumber,
  jobslength,
  isHorizontalFlipped,
  currentPageJobsCount,
  setUnlockCV,
  setUnlockVideo,
  setNoCoins,
  setSelected,
  setGetCoins,
  setOpenNotLoggedIn,
  setOpenNotLoggedInMatched,
  setSubHeadingModal,
  allTalent,
}) {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const theme = useTheme();
  const CountryFlag = Flag[talent?.country_code ? talent?.country_code : ""];

  const [isStar, setIsStarSelected] = useState(
    talent?.favourite_talent
      ? talent?.favourite_talent
      : document.cookie
          ?.match(/\d+/g)
          ?.map((item) => parseInt(item, 10))
          ?.includes(talent?.user_id)
  );

  const [job, setJob] = useState(talent);
  const [load, setLoad] = useState(true);
  const [matchMeButton, setMatchButton] = useState(false);
  const [runMatch, setRunMatch] = useState(false);
  const [shortlist, setShortlist] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  let decodedToken = getDecodedToken(getToken());

  const handleFavourite = (event, talentid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteTalent", talentid, 30);
  };

  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 4) {
      await dispatch(favouriteJob({ reqid: talent?.user_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as employer to add this talent as favourite",
        })
      );
    }
  };

  const handleViewCV = () => {
    window.open(`/candidate-cv/${talent?.user_id}`, { target: "_blank" });
  };

  const handleMatchMeButton = () => {
    if (decodedToken === undefined || decodedToken?.data?.role_id === 3) {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "Login as employer to match",
      //   })
      // );
      setSubHeadingModal(
        "talent can only be matched against your current active roles"
      );
      setOpenNotLoggedInMatched(true);
    } else {
      setMatchButton((prevState) => !prevState);
    }
  };

  const handleShortlistButton = () => {
    if (decodedToken === undefined || decodedToken?.data?.role_id === 3) {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "Login as employer to shortlist",
      //   })
      // );
      setSubHeadingModal(
        "shortlisting can only be done against your current active roles"
      );
      setOpenNotLoggedInMatched(true);
    } else {
      setShortlist(true);
    }
  };

  const handleRunMatchButton = () => {
    if (decodedToken === undefined) {
      // setOpenNotLoggedIn(true);
    } else {
      setRunMatch((prevState) => !prevState);
      setMatchButton((prevState) => !prevState);
    }
  };
  const handleShortlist = () => {
    if (decodedToken === undefined) {
      // setOpenNotLoggedIn(true);
    } else {
      setRunMatch(false);
      setMatchButton(false);
      setShortlist(false);
    }
  };

  const handleTitle = () => {
    if (decodedToken) {
      setSelected(talent);
      setGetCoins("unlockcv");
    } else if (!decodedToken) {
      setOpenNotLoggedIn(true);
    }
  };
  const hasFetched = useRef(false);

  const getBackSideData = useCallback(async () => {
    try {
      const { payload } = await dispatch(
        getTalentBackSideData({ candidate_id: talent?.user_id })
      );
      if (payload?.status === "success") {
        setJob({ ...talent, ...payload?.data?.[0] });
        setLoad(false);
      } else {
        setLoad(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      setLoad(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  }, [dispatch, talent, setJob, setLoad]);

  useEffect(() => {
    if (isHorizontalFlipped && !hasFetched.current) {
      getBackSideData();
      hasFetched.current = true;
    }
  }, [isHorizontalFlipped, getBackSideData]);

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
    >
      {!matchMeButton && !runMatch && !shortlist && (
        <>
          <Grid
            container
            // padding={1}
            justifyContent="space-between"
            alignItems="start"
            sx={{
              borderRadius: "25px 25px 0 0",
              // gap: 3,
            }}
          >
            {job?.profile_url !== "NO URL" ? (
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  ml: "15px",
                  mt: "15px",
                  borderRadius: "10px",
                }}
                alt="profile"
                src={job?.profile_url}
              />
            ) : (
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  ml: "15px",
                  mt: "15px",
                  borderRadius: "10px",
                }}
                alt="profile"
                src={profile}
              />
            )}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {job?.firstactivity && (
                  <Box>
                    <Button
                      color={
                        job?.firstactivity === "recent"
                          ? "recentButton"
                          : "activeButtonNew"
                      }
                      variant="contained"
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: "0 0 0 10px",
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,

                        cursor: "default",
                        ":hover": {
                          boxShadow: "none",
                        },
                      }}
                    >
                      {job?.firstactivity || "-"}
                    </Button>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  {/* <Button
                    variant="contained"
                    color="talentAnnounceButton"
                    sx={{
                      height: "45px",
                      width: "60px",
                      minWidth: "60px",
                      maxHeight: { xs: "60px" },
                      maxWidth: { xs: "60px" },
                      borderRadius: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "default",
                      ":hover": { boxShadow: 0 },
                    }}
                    // onClick={handleClick}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30,
                        maxHeight: { xs: 30 },
                        maxWidth: { xs: 30 },
                      }}
                      alt="job_volume"
                      src={job_volume}
                      // onClick={handleClick}
                    />
                  </Button> */}
                  <Button
                    color="playButton"
                    variant="contained"
                    // onClick={() =>
                    //   job?.cam_link === null
                    //     ? ""
                    //     : handleReviewVideoDialog(true)
                    // }
                    onClick={() => {
                      if (
                        (job?.is_videoUnlock && decodedToken) ||
                        (decodedToken?.data?.talent_plan &&
                          job?.cam_link !== null)
                      ) {
                        handleReviewVideoDialog(true);
                      } else if (job?.cam_link !== null && decodedToken) {
                        setSelected(job);
                        // setUnlockVideo(true);
                        setGetCoins("unlockvideo");
                      } else if (!decodedToken) {
                        setOpenNotLoggedIn(true);
                      }
                    }}
                    sx={{
                      // height: "auto",
                      minWidth: "60px",
                      width: "60px",
                      height: 45,
                      background:
                        job?.cam_link === null &&
                        theme.palette.grayButton600.main,
                      borderRadius: "0 25px 0 0",
                      padding: 0,
                      ":hover": {
                        boxShadow: "none",
                      },
                      cursor: job?.cam_link !== null ? "pointer" : "default",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={
                        // job?.cam_link === null
                        //   ? playButtonIcon
                        //   : playButtonIconWhite
                        playButtonIconWhite
                      }
                      sx={{
                        height: "14px",
                        width: "14px",
                      }}
                    />
                  </Button>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  fontWeight: theme.typography.Regular,
                  letterSpacing: "0.75px",
                  height: "16px",
                  paddingTop: "3px",
                  color: theme.palette.grayButton600.main,
                }}
              >
                joined {convertDatetimeAgo(job?.user_created_at)} ago
              </Typography>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              m: "15px",
            }}
          >
            {job?.is_cvUnlock || decodedToken?.data?.talent_plan ? (
              // <Link
              //   to={`/candidate-cv/${job?.user_id}`}
              //   target="_blank"
              //   style={{
              //     textDecoration: "none",
              //     color: theme.palette.black200.main,
              //     height: "20px",
              //     width: "100%",
              //   }}
              // >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  width: "100%",
                  cursor: "default",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                noWrap
              >
                {job?.pin_talent && (
                  <TalentSVGButton
                    color={"white"}
                    source={pinIcon}
                    outerHeight={20}
                    height={20}
                    width={20}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                )}
                <Link
                  to={`/candidate-cv/${job?.user_id}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.black200.main,
                    // height: "20px",
                    // width: "100%",
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    cursor: "pointer",
                    maxWidth: `calc(100% - 95px)`,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {job?.first_name}
                </Link>
                {/* <span
                    style={{
                      fontSize: "20px",
                      fontWeight: theme.typography.Bold,
                      cursor: "pointer",
                      maxWidth: `calc(100% - 95px)`,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    
                  </span> */}
                {job?.country_code && (
                  <CountryFlag
                    title="United States"
                    className="..."
                    style={{ width: 22, height: 20 }}
                  />
                )}
                <Box
                  component={"img"}
                  src={isStar ? favouriteRed : favouriteGrey}
                  sx={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    decodedToken === undefined
                      ? handleFavourite(event, job?.user_id)
                      : handleStar(job?.user_id)
                  }
                />
              </Typography>
            ) : (
              // </Link>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  width: "100%",
                  cursor: "default",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={handleTitle}
                noWrap
              >
                {job?.pin_talent && (
                  // <TalentSVGButton
                  //   color={"white"}
                  //   source={pinIcon}
                  //   outerHeight={20}
                  //   height={20}
                  //   width={20}
                  //   padding={"0px !important"}
                  //   startIconMargin={"0px !important"}
                  // />
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      maxHeight: { xs: 20 },
                      maxWidth: { xs: 20 },
                      cursor: "pointer",
                    }}
                    alt="pinIcon"
                    src={pinIcon}
                  />
                )}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    cursor: "pointer",
                    maxWidth: job?.pin_talent
                      ? `calc(100% - 95px)`
                      : `calc(100% - 65px)`,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {job?.first_name}
                </span>
                {job?.country_code && (
                  <CountryFlag
                    title="United States"
                    className="..."
                    style={{ width: 22, height: 20 }}
                  />
                )}
                <Box
                  component={"img"}
                  src={isStar ? favouriteRed : favouriteGrey}
                  sx={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    decodedToken === undefined
                      ? handleFavourite(event, job?.user_id)
                      : handleStar(job?.user_id)
                  }
                />
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                width: "100%",
              }}
              noWrap
            >
              {job?.jobTitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="currency"
                  src={BlueCurrency}
                />
                {/* </IconButton> */}
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.salaryMin === 0 ? (
                    <>
                      {job?.currencySymbol}
                      {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                      {job?.employment_type === "freelance"
                        ? "per hour"
                        : "per month"}
                    </>
                  ) : job?.currencySymbol ||
                    job?.salaryMin ||
                    job?.salaryMax ? (
                    <>
                      {job?.currencySymbol}
                      {formatCurrencyWithCommas(job?.salaryMin)} to{" "}
                      {job?.currencySymbol}
                      {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                      {job?.employment_type === "freelance"
                        ? "per hour"
                        : "per month"}
                    </>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="location"
                  src={redLocation}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.town_name || "-"}
                  {job?.country_name && `, ${job?.country_name} `}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="job_exp"
                  src={job_exp}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.experienceYear ? `${job?.experienceYear} years` : "-"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="calendar"
                  src={calendar}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.noticePeriod_description
                    ? job?.noticePeriod_description?.replace("calendar", "")
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
              paddingY={0}
              gap={"15px"}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: "134px",
                  padding: "0 20px 0 15px",
                }}
              >
                {/*<Slider2
                  items={splitString(job?.UserSkills) || []}
                  color={"skillsButton"}
                  hideTagsAfter={2}
                  height="25px"
                />
                <Slider2
                  items={splitString(job?.UserTools) || []}
                  color={"toolsButton"}
                  hideTagsAfter={2}
                  height="25px"
              />*/}
                {isHorizontalFlipped && !load ? (
                  <>
                    <JobsSlider
                      items={
                        splitString(job?.UserSkills)?.sort(
                          (a, b) => a?.length - b?.length
                        ) || []
                      }
                      color={"yoco"}
                      hideTagsAfter={2}
                      height="25px"
                      job={job}
                      index={indexNumber}
                      jobslength={jobslength}
                      isBackSide
                      id={job?.user_id}
                      currentPageJobsCount={currentPageJobsCount}
                    />

                    <JobsSlider
                      items={
                        splitString(job?.UserTools)?.sort(
                          (a, b) => a?.length - b?.length
                        ) || []
                      }
                      color={"filterButtons"}
                      hideTagsAfter={2}
                      height="25px"
                      job={job}
                      index={indexNumber}
                      jobslength={jobslength}
                      isBackSide
                      id={job?.user_id}
                      currentPageJobsCount={currentPageJobsCount}
                    />
                  </>
                ) : (
                  <Box
                    style={{
                      margin: "15px 0 0 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                )}
                <Slider
                  items={splitString(job?.UserTraits) || []}
                  theme={theme}
                  color={"precium"}
                />
              </Box>
            </Grid>
          </Box>

          <Grid
            container
            padding="15px 16px 15px 16px"
            justifyContent="space-around"
            alignItems="center"
            flexWrap={"nowrap"}
          >
            <Box
              component="img"
              height={{ xs: "100px", lg: "100px", xl: "100px" }}
              // sx={{ margin: "0 -22px 0 -22px" }}
              alt="Personality"
              src={
                (job?.primaryName === "collaborator" && profile_collaborator) ||
                (job?.primaryName === "challenger" && profile_challenger) ||
                (job?.primaryName === "character" && profile_character) ||
                (job?.primaryName === "contemplator" && profile_contemplator) ||
                profile_grey_challenger
              }
            />
            <Box
              component="img"
              height={{ xs: "100px", lg: "100px", xl: "100px" }}
              // sx={{ margin: "0 -22px 0 -22px" }}
              // alt="job_exp"
              alt="Personality"
              src={
                (job?.shadowName === "collaborator" && profile_collaborator) ||
                (job?.shadowName === "challenger" && profile_challenger) ||
                (job?.shadowName === "character" && profile_character) ||
                (job?.shadowName === "contemplator" && profile_contemplator) ||
                profile_grey_character
              }
            />
            <MUIRadialChart
              value={job?.grit_score}
              chartName={"grit score"}
              max={100}
              size={calculateMUIHeight()}
              countFontSize={16}
              labelFontSize={12}
              labelFontWeight={theme.typography.Regular}
              color={"grit_score_new"}
            />
          </Grid>
          <Grid
            container
            flexWrap={"nowrap"}
            alignItems="center"
            overflow={"hidden"}
            sx={{
              width: "100%",
              height: 60,
            }}
          >
            <Button
              variant="contained"
              onClick={handleMatchMeButton}
              sx={{
                borderRadius: 0,
                width: "32.5%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                boxShadow: "none !important",
                gap: 1,
              }}
              color="matchButton"
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "26px",
                  }}
                  src={matchMeIcon}
                />
              }
            >
              match
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                fontSize: "14px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                width: "35%",
                height: "100%",
                padding: "0px !important",
                boxShadow: "none !important",
              }}
              color="tymeColor"
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  src={
                    job?.is_cvUnlock || decodedToken?.data?.talent_plan
                      ? viewCVIconUnlock
                      : viewCVIcon
                  }
                />
              }
              onClick={() => {
                if (job?.is_cvUnlock || decodedToken?.data?.talent_plan) {
                  handleViewCV();
                } else if (decodedToken) {
                  setSelected(job);
                  setGetCoins("unlockcv");
                } else if (!decodedToken) {
                  setOpenNotLoggedIn(true);
                }
              }}
            >
              view CV
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                ":hover": {
                  boxShadow: 0,
                },
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                boxShadow: "none !important",
                gap: 1,
              }}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                  src={applyIcon}
                />
              }
              color="nandos"
              onClick={handleShortlistButton}
            >
              {i18n["talentCard.shortlist"]}
            </Button>
          </Grid>
          <Button
            variant="contained"
            // color="nandos"
            // color="#38A8AE"
            color="newFlipColor"
            sx={{
              width: "20px",
              height: "100px",
              padding: 0,
              position: "absolute",
              right: 0,
              top: `calc(50% - 50px)`,
              minWidth: "20px",
              fontSize: "20px",
              borderRadius: "10px 0 0 10px",
            }}
            onClick={() => setisFlipped(false)}
          >
            <Box
              component={"img"}
              src={flip}
              sx={{
                height: "20px",
                width: "15px",
              }}
            />
          </Button>
        </>
      )}
      {matchMeButton && !runMatch && !shortlist && (
        <TalentMatch
          job={job}
          closeFunc={handleMatchMeButton}
          setRunMatch={setRunMatch}
          setSelectedJob={setSelectedJob}
          setSelectedJobID={setSelectedJobID}
          selectedJobID={selectedJobID}
        />
      )}
      {runMatch && (
        <RunMatch
          job={job}
          closeFunc={handleRunMatchButton}
          setMatchButton={setMatchButton}
          setRunMatch={setRunMatch}
          setShortlist={setShortlist}
          selectedJobID={selectedJobID}
        />
      )}
      {shortlist && (
        <ShortlistBox
          job={job}
          closeFunc={handleShortlist}
          setMatchButton={setMatchButton}
          setRunMatch={setRunMatch}
          setShortlist={setShortlist}
          selectedJob={selectedJob}
          selectedJobID={selectedJobID}
          setSelectedJob={setSelectedJob}
          handleMatchMeButton={handleMatchMeButton}
        />
      )}
      {
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      }
    </Card>
  );
}
