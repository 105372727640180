import React, { useEffect, useState } from "react";
import { MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/system";
import { Padding } from "@mui/icons-material";
import Select from "react-select";
import CustomSelect from "../candidate/myCV/CustomSelect";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const SearchBarDropDown = ({
  selectedValue,
  setSelectedValue,
  handleJobSearch,
  filterSection,
  top=true,
}) => {
  // const [selectedValue, setSelectedValue] = useState("find work");
  const [isOpen, setIsOpen] = useState(false);
  const [filteredList, setFilteredList] = useState([]);
  const options = [
    { value: "", label: "find work", color: "#00A9E0" },
    { value: "talent", label: "find talent", color: "#FFC425" },
    { value: "whoshiring", label: "find a biz", color: "#00a6a6" },
  ];

  const handleChange = (event) => {
    setSelectedValue(event?.target?.value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setIsOpen(false);
  };

  const getFilterValue = (selectedValue) => {
    if (selectedValue === "") {
      return "find work";
    } else if (selectedValue === "talent") {
      return "find talent";
    } else if (selectedValue === "whoshiring") {
      return "find a biz";
    }
    return "";
  };

  useEffect(() => {
    let filteredOptions = options?.filter(
      (item) => item?.label !== getFilterValue(selectedValue)
    );
    setFilteredList([...filteredOptions]);
  }, [selectedValue]);

  return (
    <>
      {/* <div
        style={{
          width: "107px",
          minWidth: "107px",
          position: "relative",
          height: "45px",
          flex:`${!filterSection && "1"}` ,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor:
              selectedValue === "whoshiring"
                ? "#00a6a6"
                : selectedValue === "talent"
                ? "#f9d700"
                : selectedValue === ""
                ? "#00A9E0"
                : "",
            color: "white",
            // padding: "10px",
            fontSize: "12px",
            height: "45px",
            paddingLeft: "10px",
            paddingright: "5px",
            cursor: "pointer",
            zIndex: "999999999999",
            fontWeight:"700",
            borderRadius:`${filterSection?"0 25px 0 0":""}`,
          }}
        >
          <div
            onClick={()=>handleJobSearch(selectedValue)}
            style={{
              fontSize: "12px",
              fontWeight:"700"
            }}
          >
            {selectedValue === ""
              ? "find work"
              : selectedValue === "talent"
              ? "find talent"
              : selectedValue === "whoshiring"
              ? "find a biz"
              : ""}
          </div>
          <div
           onClick={toggleDropdown}
            style={{
              fontSize: "12px",
            }}
          >
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        </div>
        {isOpen && (
          <div
            style={{
              zIndex: "999999999999",
              position: "absolute",
              top: "100%",
              left: "0",
              width: "100%",
              borderRadius: "0 0 0 25px",
              overflow: "hidden",
              fontSize: "12px",
              fontWeight:"700"
            }}
          >
            {filteredList?.map((item) => (
              <div
                onClick={() => {handleOptionClick(item?.value);handleJobSearch(item?.label === "find work"
                ? ""
                : item?.label === "find talent"
                ? "talent"
                : item?.label === "find a biz"
                ? "whoshiring"
                : "")}}
                style={{
                  backgroundColor: item?.color,
                  color: "white",
                  height: "45px",
                  cursor: "pointer",
                  fontSize: "12px",
                  paddingLeft: "10px",
                  display:"flex",
                  alignItems:"center"
                }}
                   key={item?.value} 
              >
                {item?.label}
              </div>
            ))}
          </div>
        )}
      </div> */}
      <>
        <div
          style={{
            zIndex: "99999999999999 !important",
            width: "90px",
            minWidth: '90px',
            // minWidth: "107px",
            position: "relative",
            height: "45px",
            flex: `${!filterSection && "1"}`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor:
                selectedValue === "whoshiring"
                  ? "#00a6a6"
                  : selectedValue === "talent"
                  ? "#f9d700"
                  : selectedValue === ""
                  ? "#00A9E0"
                  : "",
              color: "white",
              fontSize: "12px",
              height: "45px",
              paddingLeft: "10px",
              paddingRight: "5px",
              cursor: "pointer",
              zIndex: "999999999999",
              fontWeight: "700",
              borderRadius:isOpen && !top?"0":"0 25px 0 0",
            }}
          >
            <div
              onClick={() => handleJobSearch(selectedValue)}
              style={{
                fontSize: "12px",
                fontWeight: "700",
              }}
            >
              {selectedValue === ""
                ? "find work"
                : selectedValue === "talent"
                ? "find talent"
                : selectedValue === "whoshiring"
                ? "find a biz"
                : ""}
            </div>
            <div
              onClick={toggleDropdown}
              style={{
                fontSize: "18px",
              }}
            >
              {isOpen ? <KeyboardArrowUpIcon /> :<KeyboardArrowDownIcon />}
            </div>
          </div>
          {isOpen &&
            (top ? (
              <div
                style={{
                  zIndex: "99999999999999 !important",
                  position: "absolute",
                  top: "100%", // Changed from top to bottom
                  left: "0",
                  width: "100%",
                  borderRadius: "0 0 0 25px", // Adjusted borderRadius for upward opening
                  overflow: "hidden",
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {filteredList?.map((item) => (
                  <div
                    onClick={() => {
                      handleOptionClick(item?.value);
                      handleJobSearch(
                        item?.label === "find work"
                          ? ""
                          : item?.label === "find talent"
                          ? "talent"
                          : item?.label === "find a biz"
                          ? "whoshiring"
                          : ""
                      );
                    }}
                    style={{
                      backgroundColor: item?.color,
                      zIndex: "99999999999999 !important",
                      color: "white",
                      height: "45px",
                      cursor: "pointer",
                      fontSize: "12px",
                      paddingLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={item?.value}
                  >
                    {item?.label}
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  zIndex: "999999999999",
                  position: "absolute",
                  bottom: "100%", // Changed from top to bottom
                  left: "0",
                  width: "100%",
                  borderRadius: "25px 25px 0 0", // Adjusted borderRadius for upward opening
                  overflow: "hidden",
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {filteredList?.map((item) => (
                  <div
                    onClick={() => {
                      handleOptionClick(item?.value);
                      handleJobSearch(
                        item?.label === "find work"
                          ? ""
                          : item?.label === "find talent"
                          ? "talent"
                          : item?.label === "find a biz"
                          ? "whoshiring"
                          : ""
                      );
                    }}
                    style={{
                      backgroundColor: item?.color,
                      color: "white",
                      height: "45px",
                      cursor: "pointer",
                      fontSize: "12px",
                      paddingLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    key={item?.value}
                  >
                    {item?.label}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </>
    </>
  );
};

export default SearchBarDropDown;
