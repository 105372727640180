import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import {
  ALERT_TYPE,
  EMPLOYER_JOB_POSTING_SPEC_LEFT,
  EMPLOYER_SPEC_LEFT,
  ERROR_MSG,
  POST_JOB_BASIC,
  POST_JOB_STEP,
  PROFILE_COMPLETION_NUMBER,
} from "../../../utils/Constants";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Paper, Step, StepLabel, Stepper, useTheme } from "@mui/material";
import TheBasicsNew from "../../common/postJobsSection/TheBasics";
import TheDetailsNew from "../../common/postJobsSection/TheDetails";
import CultureAddNew from "../../common/postJobsSection/CultureAdd";
import JobType from "../../common/postJobsSection/JobType";
import { useNavigate, useParams } from "react-router-dom";
import SavaAndExit from "../../common/postJobsSection/dialog/SavaAndExit";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import JobsDetailsPage from "../../common/JobsDetailsPage";
import { ThemeProvider } from "@emotion/react";
import { jobDetailsPageTheme } from "../../../utils/Theme";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { useDispatch } from "react-redux";
import {
  addBasicData,
  getBasicData,
  getEmployerCompanyList,
  getTitles,
  getSkills,
  getQualification,
  getRequiredQualification,
  getCurrency,
  getCountry,
  getRoleTypes,
  getWorkSetup,
  getTown,
  getTools,
  getContractDetails,
} from "../../../redux/employer/postJobSlice";
import jwt_decode from "jwt-decode";
import locale from "../../../i18n/locale";
import { validateSchema } from "../../../utils/Common";
import * as Yup from "yup";
import {
  getAssociation,
  getIndustries,
  getLanguage,
  getNationality,
} from "../../../redux/candidate/myCvSlice";
import CustomDialog from "../../common/CustomDialog";
import ProfileNotCompleted from "../../common/ProfileNotCompleted";
import Bounty from "../../common/postJobsSection/Bounty";
import { useSelector } from "react-redux";
import useViewport from "../../common/ViewPort";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import MySpec from "../../common/postJobsSection/MySpec";
import { setSideFilter } from "../../../redux/login/loginSlice";

export default function MyCV({ handleDynamicTheme }) {
  const i18n = locale.en;
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [specSelectBtn, setSpecSelectBtn] = useState(0);
  const [completed, setCompleted] = useState({});
  const [jobTypeDetails, setJobTypeDetails] = useState({
    jobType: "",
    is_video_require: false,
    is_interview_require: false,
    post_incognito: false,
  });
  const { isSideFilter } = useSelector((state) => state.login);
  const { jobId } = useParams();
  const gridRef = useRef();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [newWidth, setNewWidth] = useState();
  const profilePercentage = useSelector(
    (state) => state.employerjoblisting.profilePercentage
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const { width } = useViewport();
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  const [itemsFetched, setItemsFetched] = useState(false);
  const [openSaveAndExitDialog, setOpenSaveAndExitDialog] = useState(false);
  const [selectedCompanyRecruit, setSelectedCompanyRecruit] = useState();
  const [selectedCompanyDirect, setSelectedCompanyDirect] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [companyListLoading, setCompanyListLoading] = useState(false);
  const [basicData, setBasicData] = useState(POST_JOB_BASIC);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);
  const [showNoInfoBox, setShowNoInfoBox] = useState(false);
  const [openSpecBox, setOpenSpecBox] = useState(false);
  const [specName, setspecName] = useState("No file chosen");

  const [isEditPostjob, setIsEditPostjob] = useState(
    jobId === undefined ? false : true
  );
  const [errors, setErrors] = useState([]);

  const basicInfoSchema = Yup.object().shape({
    job_title_id: Yup.object()
      .shape({
        value: Yup.mixed().required("Job title value is Required."),
        label: Yup.string().required("Job title label is Required."),
      })
      .nullable(true)
      .required("Job title is Required."),
    job_role_type: Yup.string().required("Role type is Required."),
    work_setup: Yup.string().required("Work setup is Required."),
    country_id: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .test(
        "country_id value is Required.",
        "country_id value is Required.",
        (obj) => obj && obj.label && obj.value
      )
      .nullable(true)
      .required("country_id value is Required."),
    town_id: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .test(
        "town_id value is Required.",
        "town_id value is Required.",
        (obj) => obj && obj.label && obj.value
      )
      .nullable(true)
      .required("town_id value is Required."),
    industry_id: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Industry value is Required."),
          label: Yup.string().required("Industry label is Required."),
        })
      )
      .required("Industry is Required.")
      .min(1, "At least one industry is required."),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Skill value is Required."),
          label: Yup.string().required("Skill label is Required."),
        })
      )
      .required("Skills is Required.")
      .min(3, "At least three skills is required."),
    tools: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Tool value is Required."),
          label: Yup.string().required("Tool label is Required."),
        })
      )
      .required("Tools is Required.")
      .min(3, "At least three tools is required."),
    nationality_ids: Yup.array().when("nationality_flag", {
      is: false,
      then: (schema) =>
        schema
          .required("nationality_ids is Required.")
          .min(1, "At least one nationality is required."),
      otherwise: (schema) => schema.notRequired(),
    }),
    language_ids: Yup.array().when("language_flag", {
      is: false,
      then: (schema) =>
        schema
          .required("language_flag is Required.")
          .min(1, "At least one nationality is required."),
      otherwise: (schema) => schema.notRequired(),
    }),
    gender: Yup.string().when("gender_flag", {
      is: false,
      then: (schema) => schema.required("gender is Required."),
      otherwise: (schema) => schema.notRequired(),
    }),
    currency_id: Yup.number().required("Currency is Required."),
    job_start_date: Yup.string().required("Date is Required."),
    nationality_flag: Yup.bool(),
    language_flag: Yup.bool(),
    // gender_flag: Yup.bool(),
  });

  const scrollToTop = () => {
    gridRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const token = localStorage?.getItem("token");
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }

  const getCompanyList = async () => {
    setCompanyListLoading(true);
    const { payload } = await dispatch(getEmployerCompanyList());
    if (payload?.status === "success") {
      setCompanyListLoading(false);
      setCompanyList(payload?.data);
      if (basicData?.job_type === "Recruit") {
        setSelectedCompanyRecruit(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
        setSelectedCompanyDirect("");
      } else if (basicData?.job_type === "Direct") {
        setSelectedCompanyDirect(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
        setSelectedCompanyRecruit("");
      } else {
        setSelectedCompanyRecruit(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
        setSelectedCompanyDirect(
          payload?.data?.filter((item) => item?.default)?.[0]?.company_id
        );
      }
    }
  };

  const getAllTheBasics = async () => {
    try {
      dispatch(setLoading(true));
      const { payload } = await dispatch(getBasicData(jobId));
      // getCompanyList();
      if (payload?.status === "success") {
        const basic = { ...payload?.data };
        basic?.job_spec && setspecName(basic?.job_spec);
        if (basic?.association_ids?.length > 0) {
          basic.association_ids = basic?.association_ids?.map((item) => ({
            label: item?.association_name,
            value: item?.association_id,
          }));
        }
        if (basic?.industry_id?.length > 0) {
          basic.industry_id = basic?.industry_id?.map((item) => ({
            label: item?.industry_name,
            value: item?.industry_id,
          }));
        }
        if (basic?.preferred_qualification_ids?.length > 0) {
          basic.preferred_qualification_ids =
            basic?.preferred_qualification_ids?.map((item) => ({
              label: item?.qualification_name,
              value: item?.qualification_id,
            }));
        }
        if (basic?.skills?.length > 0) {
          basic.skills = basic?.skills?.map((item) => ({
            label: item?.skill_name,
            value: item?.tag_id,
          }));
        }
        if (basic?.tools?.length > 0) {
          basic.tools = basic?.tools?.map((item) => ({
            label: item?.tool_name,
            value: item?.tool_id,
          }));
        }
        if (basic?.job_title_id) {
          basic.job_title_id = {
            label: basic?.title,
            value: basic?.job_title_id,
          };
        }
        if (basic?.country_id) {
          basic.country_id = {
            label: basic?.country_name,
            value: basic?.country_id,
          };
        }
        if (basic?.town_id) {
          basic.town_id = {
            label: basic?.town_name,
            value: basic?.town_id,
          };
        }
        if (basic?.nationality_flag === null) {
          basic.nationality_flag = false;
        }
        if (basic?.language_flag === null) {
          basic.language_flag = false;
        }
        setBasicData(basic);
        // setspecName(basic)
        dispatch(setLoading(false));
        if (payload?.data?.job_type === "Recruit") {
          setSelectedCompanyRecruit(payload?.data?.company_id);
          setSelectedCompanyDirect("");
        } else {
          setSelectedCompanyDirect(payload?.data?.company_id);
          setSelectedCompanyRecruit("");
        }
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  useEffect(() => {
    if (activeStep === 0) {
      setCompleted({
        0: jobId !== undefined ? true : false,
        1: jobId !== undefined ? true : false,
        2: jobId !== undefined ? true : false,
        3: jobId !== undefined ? true : false,
        4: jobId !== undefined ? true : false,
        5: jobId !== undefined ? true : false,
      });
      jobId !== undefined ? getAllTheBasics() : setBasicData(POST_JOB_BASIC);
    }
  }, [activeStep, jobId]);

  useEffect(() => {
    handleDynamicTheme(
      selectedCompanyRecruit || basicData?.job_type === "Recruit"
        ? "Recruit"
        : selectedCompanyDirect || basicData?.job_type === "Direct"
        ? "Direct"
        : ""
    );
  }, [basicData]);

  const handleStep = (step) => {
    // step === 0 && setSelectedPage(1);
    step >= 0 && setSelectedPage(1);
    setActiveStep(step);
    scrollToTop();
  };
  const handleComplete = (step) => {
    const newCompleted = completed;
    newCompleted[step || activeStep] = true;
    setCompleted(newCompleted);
    scrollToTop();
  };
  const handleOpenSaveAndExitDialog = () => {
    setOpenSaveAndExitDialog((prevState) => !prevState);
  };
  const getAllDataNew = async (para, country_id) => {
    try {
      switch (para) {
        case "titles":
          await dispatch(getTitles());
          return;
        case "jobRole":
          await dispatch(getRoleTypes());
          return;
        case "contractDetails":
          await dispatch(getContractDetails());
          return;
        case "workSetup":
          await dispatch(getWorkSetup());
          return;
        case "country":
          await dispatch(getCountry());
          return;
        case "townsMain":
          await dispatch(getTown(country_id));
          return;
        case "industries":
          await dispatch(getIndustries());
          return;
        case "skills":
          await dispatch(getSkills());
          return;
        case "tools":
          await dispatch(getTools());
          return;
        case "nationality":
          await dispatch(getNationality());
          return;
        case "language":
          await dispatch(getLanguage());
          return;
        case "requiredQua":
          await dispatch(getRequiredQualification());
          return;
        case "qualifications":
          await dispatch(getQualification());
          return;
        case "associations":
          await dispatch(getAssociation());
          return;

        case "currency":
          await dispatch(getCurrency());
          return;

        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const getInitialData = async (basic) => {
    try {
      if (basic?.job_title_id !== "") {
        await getAllDataNew("titles");
      }
      if (basic?.job_role_type !== "") {
        getAllDataNew("jobRole");
      }
      if (basic?.contract_duration !== 0) {
        getAllDataNew("contractDetails");
      }
      if (basic?.work_setup !== "") {
        getAllDataNew("workSetup");
      }
      if (basic?.country_id !== "") {
        getAllDataNew("country");
      }
      if (basic?.town_id?.length !== 0) {
        getAllDataNew("townsMain", basic?.country_id);
      }
      if (basic?.industry_id?.length !== 0) {
        getAllDataNew("industries");
      }
      if (basic?.skills?.length !== 0) {
        getAllDataNew("skills");
      }
      if (basic?.tools?.length !== 0) {
        getAllDataNew("tools");
      }
      if (basic?.nationality_ids?.length !== 0) {
        getAllDataNew("nationality");
      }
      if (basic?.language_ids?.length !== 0) {
        getAllDataNew("language");
      }
      if (basic?.required_qualification_id !== "") {
        getAllDataNew("requiredQua");
      }
      if (basic?.preferred_qualification_ids?.length !== 0) {
        getAllDataNew("qualifications");
      }
      if (basic?.association_ids?.length !== 0) {
        getAllDataNew("associations");
      }
      if (basic?.currency_id !== "") {
        getAllDataNew("currency");
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const saveBasic = async (btnType, newData, skip) => {
    let {
      job_title_id,
      job_role_type,
      work_setup,
      country_id,
      town_id,
      industry_id,
      skills,
      tools,
      job_spec,
      nationality_ids,
      language_ids,
      required_qualification_id,
      // association_ids,
      job_start_date,
      currency_id,
      nationality_flag,
      language_flag,
      gender_flag,
      gender,
    } = basicData;
    let testErrorState = {
      job_title_id,
      job_role_type,
      work_setup,
      country_id,
      town_id,
      industry_id,
      skills,
      tools,
      nationality_ids,
      language_ids,
      required_qualification_id,
      job_start_date,
      currency_id,
      nationality_flag,
      language_flag,
      gender_flag,
      gender,
    };
    let data = newData ? newData : basicData;
    validateSchema(basicInfoSchema, testErrorState)
      .then(() => {
        try {
          const newBasicData = {
            ...data,
            job_id: jobId ? jobId : null,
            job_spec: data?.job_spec
              ? data?.job_spec
              : specName !== "No file chosen"
              ? specName
              : null,
            job_type: data?.job_type,
            association_ids:
              data?.association_ids && data?.association_ids?.length > 0
                ? data?.association_ids?.map((item) => item?.value)
                : "",
            industry_id: data?.industry_id?.map((item) => item?.value),
            job_title_id: data?.job_title_id?.value
              ? data?.job_title_id?.value
              : "",
            preferred_qualification_ids:
              data?.preferred_qualification_ids &&
              data?.preferred_qualification_ids?.length > 0
                ? data?.preferred_qualification_ids?.map((item) => item?.value)
                : "",
            skills: data?.skills?.map((item) => item?.value),
            tools: data?.tools?.map((item) => item?.value),
            country_id: data?.country_id?.value ? data?.country_id?.value : "",
            town_id: data?.town_id?.value ? data?.town_id?.value : "",
            alternative_title_id:
              data?.alternateTitle && data?.alternateTitle?.length > 0
                ? data?.alternateTitle
                    ?.filter((item) => item?.__isNew__ !== true)
                    ?.map((item) => item?.value)
                : "",
            alternative_title_value: data?.alternateTitle
              ?.filter((item) => item?.__isNew__ === true)
              ?.map((item) => item?.label),
          };
          delete newBasicData?.alternateTitle;
          dispatch(addBasicData(newBasicData)).then((payload) => {
            if (payload?.payload?.status == "success") {
              handleComplete(1);
              if (btnType === "saveAndExit") {
                setOpenSaveAndExitDialog(false);
                setSelectedPage(3);
                setActiveStep(6);
                // history(
                //   decodedToken?.data?.role_id === 6
                //     ? "/recruiter/my-jobs"
                //     : "/employer/my-jobs"
                // );
                // return;
              } else {
                if (btnType !== "basic") {
                  handleStep(2);
                }
                if (btnType === "jobtype") {
                  handleStep(0);
                }
              }
              setErrors([]);
              history(
                decodedToken?.data?.role_id === 6
                  ? `/recruiter/post-a-job/${payload?.payload?.data?.job_id}`
                  : `/employer/post-a-job/${payload?.payload?.data?.job_id}`
              );
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Basic data created successfully!",
                })
              );
            } else if (payload?.payload?.status === "error") {
              if (
                payload?.payload?.errors?.find(
                  (error) => error?.key === "job_type"
                )
              ) {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Please Select Job Type First",
                  })
                );
              } else {
                setErrors(payload?.payload?.errors);
              }
              scrollToTop();
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "error",
                })
              );
            }
          });
        } catch (error) {
          setOpenSaveAndExitDialog(false);
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      })
      .catch((error) => {
        setOpenSaveAndExitDialog(false);
        setErrors(
          Object?.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const getProfilePercentageData = () => {
    if (
      profilePercentage?.myInfo + profilePercentage?.companyInfo <
      PROFILE_COMPLETION_NUMBER
    ) {
      setShowNoInfoBox(true);
    } else {
      setShowNoInfoBox(false);
    }
    // setProfilePercentage(payload?.data);
  };

  useEffect(() => {
    Object?.keys(profilePercentage)?.length !== 0 && getProfilePercentageData();
  }, [profilePercentage]);

  useEffect(() => {
    getInitialData(basicData);
  }, []);

  useEffect(() => {
    if (jobId && jobId?.length > 0) {
      setIsEditPostjob(true);
    } else {
      setIsEditPostjob(false);
      setActiveStep(0);
      setBasicData(POST_JOB_BASIC);
    }
  }, [jobId]);

  const calculateCardWidthAndPadding = (totalWidth) => {
    setNewWidth(totalWidth - 300);
  };

  useLayoutEffect(() => {
    const getNewWidth = () => {
      if (gridRef.current) {
        calculateCardWidthAndPadding(gridRef.current.clientWidth);
      }
    };

    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }

    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [isSideFilter, activeStep]);
  function calculateCardWidthAndPaddingMySpec(totalWidth) {
    let cardWidth = 340;
    let padding = width < 390 ? 20 : 15;
    // let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));
    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }
    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));
    padding = padding + remainingSpace / (numCards + 1);
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));
    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef.current) {
      calculateCardWidthAndPaddingMySpec(gridRef.current.clientWidth);
    }
  };
  const handleModalSaveAndExit = () => {
    if (activeStep === 1) {
      saveBasic("saveAndExit");
    }
  };
  useLayoutEffect(() => {
    if (gridRef.current) {
      calculateCardWidthAndPaddingMySpec(gridRef.current.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, isSideFilter]);

  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true));
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false));
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);

  return (
    <>
      <Grid
        container
        spacing={0}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        flexWrap={"nowrap"}
        gap={activeStep === 6 ? "0px" : "30px"}
        sx={{
          position: "relative",
        }}
      >
        {width < 768 && width !== 0 && isSideFilter && (
          <Box
          onClick={() => dispatch(setSideFilter(false))}
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0, .2)",
            }}
          ></Box>
        )}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            position: `${width < 768 ? "absolute" : "relative"}`,
            // width: isSideFilter
            //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
            //   : "30px",
            width: isSideFilter ? "125px" : "30px",
            minWidth: isSideFilter ? "125px" : "30px",
            // width: isSideFilter && "150px",
            zIndex: "30",
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              boxShadow: "0px 5px 10px #00000029 !important",
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpand((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {isSideFilter ? <LeftArrow /> : <RightArrow />}
          </Button> */}
          <Box
            sx={{
              minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight: "45px",
            }}
          ></Box>
          {isSideFilter && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                // height: {
                //   xs: `calc(100vh - 95px)`,
                //   sm: `calc(100vh - 120px )`,
                //   md: `calc(100vh - 120px)`,
                //   lg: `calc(100vh - 135px)`,
                //   xl: `calc(100vh - 155px)`,
                //   xxl: "calc(100vh - 175px)",
                // },
                width: `${width < 768 && "135px"}`,
                minWidth: "125px",
                paddingTop: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                overflowX: "hidden",
                overflowY: isSideFilter ? "scroll" : "unset",
              }}
              className="filterSec"
            >
              {EMPLOYER_JOB_POSTING_SPEC_LEFT?.map((btn) => (
                <Button
                  variant="contained"
                  color={
                    selectedPage === btn?.id
                      ? "activePageButton"
                      : "pageButtons"
                  }
                  sx={{
                    height: "45px",
                    borderRadius: "0 10px 10px 0",
                    fontSize: theme.typography.fontSize.xs,
                    // maxWidth: `${width < 768 && "150px"}`,
                    maxWidth: "125px",
                    minWidth: "125px",
                    fontWeight:
                      selectedPage === btn?.id
                        ? theme?.typography?.Bold
                        : theme?.typography?.Regular,
                    // boxShadow: "0px 5px 10px #00000029",
                    "&:hover": {
                      background: theme?.palette?.activeStepButtons?.main,
                      color: theme?.palette?.activeStepButtons?.contrastText,
                      fontWeight: theme?.typography?.Bold,
                    },
                  }}
                  onClick={() => {
                    setSelectedPage(btn?.id);
                    // btn?.id === 0 && setActiveStep(0);
                    btn?.id === 1 && setActiveStep(0);
                    btn?.id === 3 && setActiveStep(6);
                  }}
                >
                  {btn.name}
                </Button>
              ))}
            </Box>
          )}
        </Grid>
        <Grid
          container
          width={{ xs: "100%", tb24: `calc(100vw - 300px)` }}
          justifyContent={"center"}
          gap={"10px"}
          alignContent={"flex-start"}
          ref={gridRef}
          className={"postAJobComponent"}
          flexGrow={1}
          sx={{
            // zIndex: isSideFilter && -1,
            zIndex: width < 768 && isSideFilter && -1,
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            overflowY: "scroll",
            overflowX: "hidden",
            paddingBottom: activeStep > 0 && 6,
          }}
        >
          {activeStep === 6 ? (
            <MySpec
              specSelectBtn={specSelectBtn}
              setItemsFetched={setItemsFetched}
              newPadding={newPadding}
              newCardWidth={newCardWidth}
              setActiveStep={setActiveStep}
              setSelectedPage={setSelectedPage}
            />
          ) : (
            <Box
              sx={{
                boxShadow: activeStep > 0 && "0px 3px 6px #00000029",
                p: 0,
                borderRadius: "25px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                background: activeStep > 0 && "#FFFFFF",
                paddingBottom: 0,
                marginTop: { xs: "55px", md24: "55px" },
                width: "100%",
              }}
            >
              <Box>
                {activeStep === 0 && (
                  <JobType
                    changeStep={handleStep}
                    handleComplete={handleComplete}
                    handleJobTypeDetails={setBasicData}
                    selectedJobTypeDetails={basicData}
                    jobId={jobId}
                    getCompanyList={getCompanyList}
                    companyListLoading={companyListLoading}
                    companyList={companyList}
                    setSelectedCompanyRecruit={setSelectedCompanyRecruit}
                    selectedCompanyRecruit={selectedCompanyRecruit}
                    setSelectedCompanyDirect={setSelectedCompanyDirect}
                    selectedCompanyDirect={selectedCompanyDirect}
                    handleDynamicTheme={handleDynamicTheme}
                    isEditPostjob={isEditPostjob}
                    basicData={basicData}
                    setBasicData={setBasicData}
                    saveBasicJobType={saveBasic}
                  />
                )}
                {activeStep === 1 && (
                  <TheBasicsNew
                    jobId={jobId}
                    setspecName={setspecName}
                    specName={specName}
                    changeStep={handleStep}
                    handleComplete={handleComplete}
                    handleJobTypeDetails={setJobTypeDetails}
                    selectedJobTypeDetails={jobTypeDetails}
                    basicData={basicData}
                    setBasicData={setBasicData}
                    handleOpenSaveAndExitDialog={handleOpenSaveAndExitDialog}
                    isEditPostjob={isEditPostjob}
                    errors={errors}
                    setErrors={setErrors}
                    saveBasic={saveBasic}
                    setOpenSpecBox={setOpenSpecBox}
                    openSpecBox={openSpecBox}
                    setSelectedPage={setSelectedPage}
                  />
                )}
                {activeStep === 2 && (
                  <TheDetailsNew
                    setspecName={setspecName}
                    specName={specName}
                    changeStep={handleStep}
                    handleComplete={handleComplete}
                    handleOpenSaveAndExitDialog={handleOpenSaveAndExitDialog}
                    isEditPostjob={isEditPostjob}
                    setActiveStep={setActiveStep}
                    setSelectedPage={setSelectedPage}
                  />
                )}
                {activeStep === 3 && (
                  <CultureAddNew
                    setspecName={setspecName}
                    specName={specName}
                    changeStep={handleStep}
                    handleComplete={handleComplete}
                    jobTypeDetails={jobTypeDetails}
                    handleOpenSaveAndExitDialog={handleOpenSaveAndExitDialog}
                    isEditPostjob={isEditPostjob}
                    setActiveStep={setActiveStep}
                    setSelectedPage={setSelectedPage}
                  />
                )}
                {activeStep === 4 && (
                  <Bounty
                    setspecName={setspecName}
                    specName={specName}
                    changeStep={handleStep}
                    handleComplete={handleComplete}
                    jobTypeDetails={jobTypeDetails}
                    handleOpenSaveAndExitDialog={handleOpenSaveAndExitDialog}
                    isEditPostjob={isEditPostjob}
                    setSelectedPage={setSelectedPage}
                    setActiveStep={setActiveStep}
                  />
                )}
                {activeStep === 5 && (
                  <ThemeProvider theme={jobDetailsPageTheme}>
                    <JobsDetailsPage
                      jobId={jobId}
                      theme={jobDetailsPageTheme}
                      isEditPostjob={isEditPostjob}
                      routedFrom={"inside"}
                    />
                  </ThemeProvider>
                )}
              </Box>
            </Box>
          )}
          {showNoInfoBox && (
            <CustomDialog
              show={true}
              hideButton={false}
              dialogWidth="xs"
              showFooter={false}
              padding={0}
            >
              <ProfileNotCompleted />
            </CustomDialog>
          )}
          <style>
            {`.postAJobComponent::-webkit-scrollbar {
                     display:none;
                   }
                   `}
          </style>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            direction: "rtl",
            position: `${width < 768 ? "absolute" : "relative"}`,
            zIndex: "30",
            width: isSideFilter ? "125px" : "30px",
            minWidth: isSideFilter ? "125px" : "30px",
            right: "0",
          }}
        >
          <Box
            sx={{
              minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight: "45px",
            }}
          ></Box>
          {/* <Button
            variant="contained"
            sx={{
              position: "sticky",
              top: 0,
              boxShadow: "0px 5px 10px #00000029 !important",
              borderRadius: "10px 0 0 10px",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              // backgroundColor: `${theme.palette.basicColor.main}`,
              // "&:hover": {
              //   backgroundColor: `${theme.palette.basicColor.main}`,
              // },
            }}
            color="filterButtons"
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpand((prevState) => !prevState);
              dispatch(setFilterExpanded(!filterExpanded));
            }}
          >
            {isSideFilter ? <RightArrow /> : <LeftArrow />}
          </Button> */}
          {isSideFilter &&
            (activeStep === 6 ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: `${width < 768 && "135px"}`,
                  paddingTop: `${width < 768 && "10px"}`,
                  background: `${width > 0 && width < 768 && "#EBECF3"}`,
                  boxShadow: `${
                    width > 0 && width < 768 && "0px 5px 10px #00000029"
                  }`,
                  opacity: `${width < 768 && "1"}`,
                  borderRadius: `${width < 768 && "10px"}`,
                  height: {
                    xs: `calc(100vh - 95px)`,
                    sm: `calc(100vh - 120px )`,
                    md: `calc(100vh - 120px)`,
                    lg: `calc(100vh - 135px)`,
                    xl: `calc(100vh - 155px)`,
                    xxl: "calc(100vh - 175px )",
                  },
                  overflowY: isSideFilter ? "scroll" : "unset",
                }}
                className="rightfilterSec"
              >
                <style>
                  {`.rightfilterSec::-webkit-scrollbar {
                            display:none;
                          }
                          `}
                </style>
                <Paper
                  sx={{
                    background: "transparent",
                    boxShadow: 0,
                    gap: "10px",
                    display: "flex",
                    flexDirection: "column",
                    // maxWidth: `${width < 768 && "150px"}`,
                    maxWidth: "125px",
                  }}
                >
                  {EMPLOYER_SPEC_LEFT?.map((btn) => (
                    <Button
                      variant="contained"
                      color={
                        specSelectBtn === btn?.id
                          ? "activePageButton"
                          : "pageButtons"
                      }
                      sx={{
                        height: "45px",
                        borderRadius: "10px 0 0 10px",
                        fontSize: theme.typography.fontSize.xs,
                        // maxWidth: `${width < 768 && "150px"}`,
                        maxWidth: "125px",
                        minWidth: "125px",
                        fontWeight:
                          specSelectBtn === btn?.id
                            ? theme?.typography?.Bold
                            : theme?.typography?.Regular,
                        // boxShadow: "0px 5px 10px #00000029",
                        "&:hover": {
                          background: theme?.palette?.activeStepButtons?.main,
                          color:
                            theme?.palette?.activeStepButtons?.contrastText,
                          fontWeight: theme?.typography?.Bold,
                        },
                      }}
                      onClick={() => {
                        setSpecSelectBtn(btn?.id);
                      }}
                    >
                      {btn?.name}
                    </Button>
                  ))}
                </Paper>
              </Box>
            ) : (
              <Box
                className="rightfilterSec"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // width: "141px",
                  width: `${width < 768 && "125px"}`,
                  minWidth: "125px",
                  paddingTop: `${width < 768 && "10px"}`,
                  paddingLeft: `${width < 768 && "10px"}`,
                  paddingBottom: `${width < 768 && "10px"}`,
                  background: `${width > 0 && width < 768 && "#EBECF3"}`,
                  boxShadow: `${
                    width > 0 && width < 768 && "0px 5px 10px #00000029"
                  }`,
                  opacity: `${width < 768 && "1"}`,
                  borderRadius: `${width < 768 && "10px"}`,
                }}
              >
                <Stepper
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                  sx={{
                    "& .MuiStepConnector-root": {
                      marginLeft: "20px",
                    },
                    "& .MuiStepConnector-line": {
                      borderColor: theme?.palette?.activeStepButtons?.main,
                      minHeight: "40px",
                      margin: "5px 0px 6px 0px",
                    },
                  }}
                >
                  {POST_JOB_STEP?.map((step, index) => (
                    <Step
                      key={step?.label}
                      completed={completed[index]}
                      sx={{
                        "& .Mui-active": {
                          color:
                            theme?.palette?.activeStepButtons?.contrastText,
                          fill: theme?.palette?.activeStepButtons
                            ?.contrastCircle,
                        },
                        "& .MuiStepIcon-root": {
                          color:
                            activeStep !== index &&
                            theme?.palette?.activeStepButtons?.circleColor,
                          width: "25px !important",
                          height: "25px !important",
                          fontSize: "12px",
                        },
                        "& .MuiStepIcon-text": {
                          fill:
                            activeStep === index
                              ? theme?.palette?.activeStepButtons?.iconText
                              : theme?.palette?.stepButtons?.iconText,
                          fontSize: "12px",
                        },
                        "& .MuiStepLabel-labelContainer": {
                          color: theme?.palette?.stepButtons?.contrastText,
                        },
                        "& .MuiStepLabel-label": {
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight:
                            activeStep === index
                              ? theme?.typography?.Bold
                              : theme.typography.Regular,
                          color: activeStep === index && "#FFF !important",
                        },
                        "& .MuiStepLabel-label.Mui-active": {
                          color:
                            theme?.palette?.activeStepButtons?.contrastText,
                        },
                        "& .MuiStepLabel-label.Mui-completed": {
                          color: theme?.palette?.activeStepButtons?.main,
                        },
                      }}
                    >
                      <StepLabel
                        onClick={() => handleStep(index)}
                        color="stepButtonColor"
                        style={{
                          border: `2px solid ${step?.borderColor}`,
                          borderRadius: "10px 0 0 10px",
                          cursor: "pointer",
                          height: "40px",
                          padding: "0 10px",
                          backgroundColor:
                            activeStep === index
                              ? theme?.palette?.stepButtonColor?.main
                              : theme?.palette?.stepButtons?.main,
                          // boxShadow: "0px 5px 10px #00000029",
                          flexDirection: "row-reverse",
                        }}
                      >
                        {step?.label}
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            ))}
        </Grid>
      </Grid>
      {openSaveAndExitDialog && (
        <SavaAndExit
          show={openSaveAndExitDialog}
          handleOpen={handleOpenSaveAndExitDialog}
          handleModalSaveAndExit={handleModalSaveAndExit}
        />
      )}
    </>
  );
}
