import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "../../assets/Padding Excluded/White_Search.svg";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import locale from "../../i18n/locale";
import PlaceIcon from "../../assets/Red_Location.svg";
import { useTheme } from "@mui/material/styles";
import { setAlert, setLoading } from "../../redux/configSlice";
import { useDispatch, useSelector } from "react-redux";
import { ALERT_TYPE, ERROR_MSG } from "../../utils/Constants";
import { debounce, useQuery } from "../../utils/Common";
import AutoCompleteLocation from "./AutoCompleteLocation";
import { getTowns } from "../../redux/candidate/myProfileSlice";
import { useLocation } from "react-router-dom";
import { getSearchedResult } from "../../redux/guest/jobsSlice";

export default function SearchBarBox({
  placeholder,
  color = "tealColor",
  padding = "0",
  homePage = false,
  showSearchBar,
  setShowSearchBar,
  openAdvanceSearch,
  setOpenAdvanceSearch,
  handleJobSearch,
  setSearchText,
  searchText,
  setLocationID,
  setBasicData,
  basicData,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const query = useQuery();
  const params = useLocation();
  const options = ["Option 1", "Option 2"];
  const [value, setValue] = useState(options[0]);
  const [inputValue, setInputValue] = useState("");
  const [searchBox, setSearchBox] = useState(false);
  const [locationInput, setLocationInput] = useState(false);
  const { town } = useSelector((state) => state?.myProfileSli);
  const [isLoading, setIsLoading] = useState(false);

  const [showArrow, setShowArrow] = useState(false);

  const getSearchedData = async () => {
    dispatch(setLoading({ value: true, apiName: "getSearchedData" }));
    try {
      let data = {
        job_title: [],
        town_id: query
          ?.get("location")
          ?.split(",")
          ?.map((str) => parseInt(str)),
        tag_id: [],
        tool_id: [],
        country_id: [],
        company_id: [],
        highest_qualification_id: [],
        currency_id: [],
      };
      const { payload } = await dispatch(getSearchedResult(data));
      if (payload?.status === "success") {
        const location = Array.from(
          payload?.data?.towns || [],
          (item) => item?.name
        );
        let data = {
          searchTitle: location[0],
          limit: 200,
        };
        await dispatch(getTowns(data));
      }
      dispatch(setLoading({ value: false, apiName: "getSearchedData" }));
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "getSearchedData" }));
    }
  };

  const getTownValue = () => {
    const location_id = query?.get("location");
    return town?.find((title) => title?.id == Number(location_id));
  };

  useEffect(() => {
    getSearchedData();
  }, []);

  const handleMultipleAutoComplete = (event, newValue, id) => {
    let newBasicData = {};

    if (typeof newValue === "string") {
      newBasicData = {
        ...basicData,
        [id]: [newValue],
      };
    } else if (newValue && newValue?.inputValue) {
      // Create a new value from the user input
      newBasicData = {
        ...basicData,
        [id]: [newValue?.inputValue],
      };
    } else {
      newBasicData = {
        ...basicData,
        [id]: [newValue?.id],
      };
    }
    setLocationID(newValue?.id);
    setBasicData(newBasicData);
    if (event?.code === "Enter") {
      handleJobSearch("", newValue?.id); // Call your function here
    }
  };

  const showLocationInput = () => {
    setLocationInput((prevState) => !prevState);
  };

  const getAllData = async (para) => {
    try {
      await dispatch(getTowns(""));
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleSearch = (event) => {
    setSearchText(event?.target?.value);
    const delayedJobTitleUpdate = setTimeout(() => {
      setBasicData({ ...basicData, job_title: event?.target?.value });
    }, 100); // Set your preferred delay time in milliseconds
    // Clear the previous timeout to avoid unnecessary state updates
    if (delayedJobTitleUpdate) clearTimeout(delayedJobTitleUpdate);
  };

  useEffect(() => {
    query?.get("location") && setLocationInput((prevState) => !prevState);
    query?.get("location") && getAllData();
  }, []);

  useEffect(() => {
    if (setSearchBox) {
      if (showSearchBar || openAdvanceSearch) {
        setSearchBox(true);
      } else {
        // setSearchBox(false);
      }
    }
  }, [openAdvanceSearch, showSearchBar]);

  const handleOnSelectInputChange = (event, newValue) => {
    debouncedHandleOnSelectInputChange(event, newValue);
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type) => {
        let params = {
          searchTitle: type,
          limit: 200,
        };
        if (type) {
          try {
            dispatch(getTowns(params));
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );

  return (
    <>
      {showSearchBar && !showArrow && (
        <Paper
          elevation={0}
          sx={{
            width: { mddl: "600px", lg: "800px", lgm: "1000px" },
            display: { xs: "none", sm: "flex", md: "flex" },
            m: { xs: 2, sm: 0, md: 0 },
            alignItems: "center",
            height: 45,
            position: "sticky",
            borderRadius: "10px 10px 0 0",
            overflow: "hidden",
            boxShadow: "0px 5px 10px #00000029",
            backgroundColor: theme?.palette?.lightGrey?.main,
            // border: `2px solid ${theme.palette[color].main}`,
          }}
        >
          <Button
            variant="contained"
            color="tymeColor"
            width="100px"
            sx={{
              minWidth: "100px",
              boxShadow: 0,
              borderRadius: "10px 0 0 0",
              height: "100%",
              fontSize: "16px",
              fontWeight: theme?.typography?.Bold,
            }}
            startIcon={
              <Box
                component={"img"}
                height={"20px"}
                width={"20px"}
                src={SearchIcon}
              />
            }
          >
            {i18n["searchBar.search"]}
          </Button>

          <Paper
            elevation={0}
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              width: 1,
              background: "transparent",
            }}
          >
            <InputBase
              sx={{
                ml: 1,
                width: 1,
                fontSize: "14px",
                fontWeight: 700,
                backgroundColor: theme?.palette?.topBar?.main,
              }}
              placeholder={i18n["searchBar.searchPlaceholder"]}
              inputProps={{ "aria-label": "search google maps" }}
              value={searchText}
              onChange={(event) => {
                handleSearch(event);
              }}
              onKeyDown={(event) => {
                if (event?.key === "Enter") {
                  event?.preventDefault(); // Prevent the default Enter key behavior (page refresh)
                  handleJobSearch(
                    params?.pathname?.includes("talent")
                      ? "talent"
                      : params?.pathname?.includes("whoshiring")
                      ? "whoshiring"
                      : ""
                  ); // Call your function here
                }
              }}
            />
          </Paper>
          <IconButton
            color="redButton"
            aria-label="search job"
            component="button"
            onClick={showLocationInput}
          >
            <Box
              component={"img"}
              height={"25px"}
              width={"25px"}
              src={PlaceIcon}
            />
          </IconButton>
          <Paper
            elevation={0}
            component="form"
            sx={{
              // p: "2px 4px",
              mx: 2,
              display: locationInput ? "flex" : "none",
              alignItems: "center",
              borderRadius: "25px",
              width: 0.5,
            }}
          >
            <AutoCompleteLocation
              multiple={false}
              background={theme?.palette?.chart?.gray}
              id="town_id"
              onInputChange={(event, newInputValue) => {
                handleOnSelectInputChange(event, newInputValue);
              }}
              onFocus={() => {
                if (town?.length === 0) {
                  getAllData();
                }
              }}
              value={getTownValue()}
              onChange={handleMultipleAutoComplete}
              placeholder={"Add location"}
              data={town}
              height={"90px"}
              fontSize={"14px"}
            />
          </Paper>

          <Button
            sx={{
              minWidth: "100px",
              padding: "0px !important",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme?.typography?.Bold,
              boxShadow: 0,
              borderRadius: 0,
              height: "100%",
            }}
            variant="contained"
            color="yoco"
            onClick={() => handleJobSearch("")}
          >
            {i18n["searchBar.findWork"]}
          </Button>
          <Button
            sx={{
              minWidth: "100px",
              padding: "0px !important",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme?.typography?.Bold,
              boxShadow: 0,
              borderRadius: 0,
              height: "100%",
            }}
            variant="contained"
            color="tymeColor"
            onClick={() => handleJobSearch("talent")}
          >
            {i18n["searchBar.findTalent"]}
          </Button>
          <Button
            sx={{
              minWidth: "100px",
              padding: "0px !important",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme?.typography?.Bold,
              boxShadow: 0,
              borderRadius: 0,
              height: "100%",
            }}
            variant="contained"
            color="filterButtons"
            onClick={() => handleJobSearch("whoshiring")}
          >
            {i18n["searchBar.findBiz"]}
          </Button>
        </Paper>
      )}
    </>
  );
}
