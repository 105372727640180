import {
  Box,
  Button,
  Divider,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  adminJobsFilter,
  updateJobDetail,
} from "../../../../redux/admin/jobsSlice";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  BASIC_RANGEMARKS_SLIDER,
  ERROR_MSG,
} from "../../../../utils/Constants";
import EditBasicInfo from "./EditBasicInfo";
import { getJobDetail } from "../../../../redux/guest/jobsSlice";
import { useSelector } from "react-redux";

import {
  getIndustries,
  getLanguage,
  getAssociation,
  getNationality,
} from "../../../../redux/candidate/myCvSlice";
import {
  getTitles,
  getSkills,
  getQualification,
  getRequiredQualification,
  getCurrency,
  getCountry,
  getRoleTypes,
  getWorkSetup,
  getTown,
  getTools,
  updateToInitialSkills,
  getContractDetails,
  getPersonalities,
  getTraits,
  uploadPostJobCultureVideo,
  uploadEmployerJobSpecData,
} from "../../../../redux/employer/postJobSlice";
import EditDetailInfo from "./EditDetailInfo";
import EditCultureDetail from "./EditCultureDetail";
import CommonLoader from "../../../common/CommonLoader";
import locale from "../../../../i18n/locale";
import * as Yup from "yup";
import {
  genarateLabelValue,
  genarateLabelValueForFreelance,
  validateSchema,
} from "../../../../utils/Common";
import EditJobType from "./EditJobType";
import Loader from "../../../common/Loader";
import specIcon from "../../../../assets/Padding Excluded/Black_Upload.svg";
import { useNavigate } from "react-router-dom";

export default function EditJobDetail({
  show,
  handleClose,
  editJobId,
  getAdminJobList,
  setAllJobs,
  setJobsLastKey,
  basicData,
  allJobs,
  pinJobFlag,
  isAdmin,
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    // borderRadius: "10px",
    boxShadow: "none !important",
    height: { sm: "480px", lgmm: "650px", lgm: "750px", xl: "800px" },
    "&:focus-visible": {
      outline: "none",
    },
  };

  const i18n = locale.en;
  const history = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const {
    titles,
    skills,
    tools,
    qualifications,
    requiredQua,
    currency,
    contractDetails,
    country,
    town,
    roleTypes,
    workSetup,
    initialSkills,
  } = useSelector((state) => state.postJobs);

  const BASIC = {
    lastKey: "",
    status_id: "",
    job_stage: "",
    job_title: "",
    job_type: "",
    team_member_user_id: "",
    company_id: "",
  };

  const { industries, language, association, nationality } = useSelector(
    (state) => state.myCv
  );

  const { personalities, traits } = useSelector((state) => state?.postJobs);
  const [rangeValue, setRangeValue] = useState([]);
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS_SLIDER);
  const [jobDetail, setJobDetail] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [specName, setSpecName] = useState(jobDetail?.job_spec ? jobDetail?.job_spec : "No file selected");

  const [sameJobAndBio, setSameJobAndBio] = useState(true);
  const [isRecruitVideoNeeded, setisRecruitVideoNeeded] = useState(
    jobDetail?.job_type === "Recruit" ? jobDetail?.is_video_require : false
  );
  const [isInterviewNeeded, setIsInterviewNeeded] = useState(
    jobDetail?.job_type === "Recruit" ? jobDetail?.is_interview_require : false
  );
  const [isRecruitPostPublicNeeded, setisRecruitPostPublicNeeded] = useState(
    jobDetail?.job_type === "Recruit" ? jobDetail?.post_incognito : false
  );
  const [isDirectVideoNeeded, setisDirectVideoNeeded] = useState(
    jobDetail?.job_type === "Direct" ? jobDetail?.is_video_require : false
  );
  const [isDirectPostPublicNeeded, setisDirectPostPublicNeeded] = useState(
    jobDetail?.job_type === "Direct" ? jobDetail?.post_incognito : false
  );

  const basicInfoSchema = Yup.object().shape({
    job_title_id: Yup.object()
      .shape({
        value: Yup.mixed().required("Job title value is Required."),
        label: Yup.string().required("Job title label is Required."),
      })
      .nullable(true)
      .required("Job title is Required."),
    job_role_type: Yup.string().required("Role type is Required."),
    work_setup: Yup.string().required("Work setup is Required."),
    country_id: Yup.object()
      .shape({
        value: Yup.number().required("country_id value is Required."),
        label: Yup.string().required("country_id label is Required."),
      })
      .nullable(true)
      .required("country_id is Required."),
    town_id: Yup.object()
      .shape({
        value: Yup.number().required("town_id value is Required."),
        label: Yup.string().required("town_id label is Required."),
      })
      .nullable(true)
      .required("town_id is Required."),
    industry_id: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Industry value is Required."),
          label: Yup.string().required("Industry label is Required."),
        })
      )
      .required("Industry is Required.")
      .min(1, "At least one industry is required."),
    skills: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Skill value is Required."),
          label: Yup.string().required("Skill label is Required."),
        })
      )
      .required("Skills is Required.")
      .min(3, "At least three skills is required."),
    tools: Yup.array()
      .of(
        Yup.object().shape({
          value: Yup.mixed().required("Tool value is Required."),
          label: Yup.string().required("Tool label is Required."),
        })
      )
      .required("Tools is Required.")
      .min(3, "At least three tools is required."),
    nationality_ids: Yup.array()
      .of(Yup.number())
      .required("Nationality is Required.")
      .min(1, "At least one nationality is required."),
    language_ids: Yup.array()
      .of(Yup.number())
      .required("Language is Required.")
      .min(1, "At least one language is required."),
    required_qualification_id: Yup.number().required(
      "Qualification is Required."
    ),
    currency_id: Yup.number().required("Currency is Required."),
    job_start_date: Yup.string().required("Date is Required."),
    nationality_flag: Yup.bool(),
    description: Yup.string().required("Role summary is Required."),
    role_responsibilty: Yup.string().required(
      "Role responsibility is Required."
    ),
    role_requirements: Yup.string().required("Role requirements is Required."),
    primary_personality: Yup.string()
      .nullable()
      .required("Primary Personality is Required."),
    shadow_personality: Yup.string()
      .nullable()
      .required("Shadow Personality is Required."),
    grit_score: Yup.number().nullable().required("Grit Score is Required."),
    traits: Yup.array()
      .min(5, "At least five trait is required.")
      .required("Traits are Required."),
    job_questions: Yup.lazy((value) => {
      if (Array.isArray(value) && value.length > 0) {
        return Yup.array()
          .of(
            Yup.object().shape({
              // job_id: Yup.string().required("Job ID is Required."),
              question: Yup.string().required("Question is Required."),
            })
          )
          .max(5, "At least five question is required.")
          .required("Screen Questions are Required.");
      }
      return Yup.array().required("At least one screen question is required.");
    }),
    gender: Yup.string().when("gender_flag", {
      is: false,
      then: (schema) => schema.required("gender is Required."),
      otherwise: (schema) => schema.notRequired(),
    }),
    skinz: Yup.string().when("skinz_flag", {
      is: false,
      then: (schema) => schema.required("skinz is Required."),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  const sanitizeHTML = (htmlContent) => {
    const tempElement = document?.createElement("div");
    tempElement.innerHTML = htmlContent;

    const isContentEmpty = tempElement?.textContent?.trim() === "";
    return isContentEmpty ? "" : htmlContent;
  };

  const getJobList = async () => {
    setIsLoading(true);
    try {
      const data = {
        job_id: editJobId,
        user_id: "",
      };
      const { payload } = await dispatch(getJobDetail(data));
      if (payload?.status === "success") {
        const basic = { ...payload?.data };
        getInitialData(payload?.data);
        if (basic?.industry_jobs?.length > 0) {
          basic.industry_id = basic?.industry_jobs?.map((item) => ({
            label: item?.industry?.name,
            value: item?.industry_id,
          }));
        }
        if (basic?.job_tags?.length > 0) {
          basic.skills = basic?.job_tags?.map((item) => ({
            label: item?.tag?.tag,
            value: item?.tag_id,
          }));
        }
        if (basic?.job_tools?.length > 0) {
          basic.tools = basic?.job_tools?.map((item) => ({
            label: item?.tool?.name,
            value: item?.tool_id,
          }));
        }
        if (basic?.job_languages?.length > 0) {
          basic.language_ids = basic?.job_languages?.map(
            (item) => item?.language_id
          );
        }
        if (basic?.job_traits?.length > 0) {
          basic.traits = basic?.job_traits?.map((item) => item?.trait_id);
        }
        if (basic?.association_jobs?.length > 0) {
          basic.association_ids = basic?.association_jobs?.map((item) => ({
            label: item.association?.name,
            value: item.association_id,
          }));
        }
        if (basic?.job_qualifications?.length > 0) {
          basic.preferred_qualification_ids = basic?.job_qualifications?.map(
            (item) => ({
              label: item?.qualification?.name,
              value: item?.qualification_id,
            })
          );
        }
        if (basic?.experience) {
          basic.experience = [
            basic?.experience?.year_start,
            basic?.experience?.year_end,
          ];
        }
        if (basic?.salary) {
          basic.currency_id = basic?.salary?.currency?.currency_id;
          basic.salary = [basic?.salary?.min, basic?.salary?.max];
        }
        if (basic?.type) {
          basic.job_role_type = basic?.type;
        }
        if (basic?.title && basic?.job_title_id) {
          basic.job_title_id = {
            value: basic?.job_title_id,
            label: basic?.title,
          };
        }
        if (basic?.town) {
          basic.town_id = {
            value: basic?.town?.town_id,
            label: basic?.town?.name,
          };
        }
        if (basic?.town) {
          basic.country_id = {
            value: basic?.town?.country?.country_id,
            label: basic?.town?.country?.name,
          };
        }
        if (basic?.primary) {
          basic.primary_personality = basic?.primary?.personality_id;
        }
        if (basic?.shadow) {
          basic.shadow_personality = basic?.shadow?.personality_id;
        }
        if (basic?.job_nationalities?.length > 0) {
          basic.nationality_ids = basic?.job_nationalities?.map((item) => {
            return item?.nationality_id;
          });
        }
        setSameJobAndBio(
          basic?.job_summary_flag !== null ? basic?.job_summary_flag : true
        );
        basic.gender_flag =
          basic?.gender_flag !== null || basic?.gender_flag !== undefined
            ? basic?.gender_flag
            : true;
        basic.skinz_flag =
          basic?.skinz_flag !== null || basic?.skinz_flag !== undefined
            ? basic?.skinz_flag
            : true;
        // getInitialSalary(basic);
        setSpecName(payload?.data?.job_spec)
        setJobDetail(basic);
      }
      setIsLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
      setIsLoading(false);
    }
  };

  const getAllDataNew = async (para, country_id) => {
    try {
      switch (para) {
        case "personality":
          await dispatch(getPersonalities());
          return;
        case "traits":
          await dispatch(getTraits());
          return;
        case "titles":
          await dispatch(getTitles());
          return;
        case "jobRole":
          await dispatch(getRoleTypes());
          return;
        case "contractDetails":
          await dispatch(getContractDetails());
          return;
        case "workSetup":
          await dispatch(getWorkSetup());
          return;
        case "country":
          await dispatch(getCountry());
          return;
        case "townsMain":
          await dispatch(getTown(country_id));
          return;
        case "industries":
          await dispatch(getIndustries());
          return;
        case "skills":
          await dispatch(getSkills());
          return;
        case "tools":
          await dispatch(getTools());
          return;
        case "nationality":
          await dispatch(getNationality());
          return;
        case "language":
          await dispatch(getLanguage());
          return;
        case "requiredQua":
          await dispatch(getRequiredQualification());
          return;
        case "qualifications":
          await dispatch(getQualification());
          return;
        case "associations":
          await dispatch(getAssociation());
          return;

        case "currency":
          await dispatch(getCurrency());
          return;

        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getInitialData = async (basic) => {
    try {
      // if (basic?.job_title_id !== "") {
      //   await getAllDataNew("titles");
      // }
      if (basic?.type !== "") {
        getAllDataNew("jobRole");
      }
      if (basic?.contract_duration !== 0) {
        getAllDataNew("contractDetails");
      }
      if (basic?.work_setup !== "") {
        getAllDataNew("workSetup");
      }
      // if (basic?.country_id !== 0) {
      //   getAllDataNew("country");
      // }
      // if (basic?.town?.town_id !== 0) {
      //   getAllDataNew("townsMain", basic?.town?.country?.country_id);
      // }
      // if (basic?.industry_id?.length !== 0) {
      //   getAllDataNew("industries");
      // }
      // if (basic?.skills?.length !== 0) {
      //   getAllDataNew("skills");
      // }
      // if (basic?.tools?.length !== 0) {
      //   getAllDataNew("tools");
      // }
      if (basic?.nationality_ids?.length !== 0) {
        getAllDataNew("nationality");
      }
      if (basic?.language_ids?.length !== 0) {
        getAllDataNew("language");
      }
      if (basic.required_qualification_id !== "") {
        getAllDataNew("requiredQua");
      }
      // if (basic?.preferred_qualification_ids?.length !== 0) {
      //   getAllDataNew("qualifications");
      // }
      // if (basic?.association_ids?.length !== 0) {
      //   getAllDataNew("associations");
      // }
      if (basic?.currency_id !== "") {
        getAllDataNew("currency");
      }
      if (basic?.primary !== "") {
        getAllDataNew("personality");
      }
      if (basic?.shadow !== "") {
        getAllDataNew("personality");
      }
      if (basic?.traits?.length !== 0) {
        getAllDataNew("traits");
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const editJobDetail = async () => {
    let {
      job_title_id,
      job_role_type,
      work_setup,
      country_id,
      town_id,
      industry_id,
      skills,
      tools,
      nationality_ids,
      language_ids,
      required_qualification_id,
      job_start_date,
      currency_id,
      role_requirements,
      role_responsibilty,
      description,
      primary,
      shadow,
      grit_score,
      traits,
      primary_personality,
      shadow_personality,
      job_questions,
      gender_flag,
      gender,
      skinz,
      skinz_flag,
    } = jobDetail;

    let testErrorState = {
      job_title_id,
      job_role_type,
      work_setup,
      country_id,
      town_id,
      industry_id,
      skills,
      tools,
      nationality_ids,
      language_ids,
      required_qualification_id,
      job_start_date,
      currency_id,
      role_requirements: sanitizeHTML(role_requirements),
      role_responsibilty: sanitizeHTML(role_responsibilty),
      description: sanitizeHTML(description),
      primary_personality: primary
        ? primary?.name
        : primary_personality
        ? primary_personality
        : "",
      shadow_personality: shadow
        ? shadow?.name
        : shadow_personality
        ? shadow_personality
        : "",
      grit_score,
      traits,
      job_questions,
      gender_flag,
      gender,
      skinz,
      skinz_flag,
    };
    validateSchema(basicInfoSchema, testErrorState)
      .then(() => {
        try {
          let data = {
            ...jobDetail,
            job_id: editJobId,
            job_title: jobDetail?.job_title_id?.label, // Title
            job_type: jobDetail?.job_type, // job_type
            // job_type: "Recruit", // job_type
            job_start_date: jobDetail?.job_start_date,
            job_role_type: jobDetail?.job_role_type, // Type
            contract_duration: jobDetail?.contract_duration,
            currency_id: jobDetail?.currency_id, //  SalaryTable - > Updated currency_id in that (salary_id)
            salary: jobDetail?.salary, // SalaryTable - > Updated min/max in that (salary_id)
            work_setup: jobDetail?.work_setup, // work_setup
            town_id: jobDetail?.town_id?.value, //town_id
            country_id: jobDetail?.country_id?.value,
            alternative_title_id:
              jobDetail?.alternateTitle && jobDetail?.alternateTitle?.length > 0
                ? jobDetail?.alternateTitle
                    ?.filter((item) => item?.__isNew__ !== true)
                    ?.map((item) => item?.value)
                : "",
            alternative_title_value: jobDetail?.alternateTitle
              ?.filter((item) => item?.__isNew__ === true)
              ?.map((item) => item?.label),
            skills: jobDetail?.skills?.map((item) => {
              return item?.value;
            }), //job_tag Table-> Change tag_id
            tools: jobDetail?.tools?.map((item) => {
              return item?.value;
            }), // job_tool Table -> Change tool_id (job_id)
            industry_id: jobDetail?.industry_id?.map((item) => {
              return item?.value;
            }),
            language_ids: jobDetail?.language_ids,
            nationality_ids: jobDetail?.nationality_ids,
            experience: jobDetail?.experience, // Experience-Table -> get experience_id : update year_start and year_end (experience_id)
            required_qualification_id: jobDetail?.required_qualification_id, // required_qualification_id
            preferred_qualification_ids:
              jobDetail?.preferred_qualification_ids?.length > 0
                ? [jobDetail?.preferred_qualification_ids?.[0]?.value]
                : [],
            association_ids:
              jobDetail?.association_ids?.length > 0
                ? [jobDetail?.association_ids?.[0]?.value]
                : [],
            role_summary: jobDetail?.description, //Description
            role_responsibilty: jobDetail?.role_responsibilty, // role_responsibility
            role_requirements: jobDetail?.role_requirements, // role requirements
            job_notes: jobDetail?.job_notes ? jobDetail?.job_notes : "",
            primary_personality: jobDetail?.primary_personality,
            shadow_personality: jobDetail?.shadow_personality, // shadow_personality
            traits: jobDetail?.traits, // job_traits Table -> Updated trait_id  (job_id)
            grit_score: jobDetail?.grit_score, // grit_score
            screen_questions: jobDetail?.job_questions,
            // job_type
            is_video_require:
              jobDetail?.job_type === "Recruit"
                ? isRecruitVideoNeeded
                : isDirectVideoNeeded,
            is_interview_require: isInterviewNeeded,
            post_incognito:
              jobDetail?.job_type === "Recruit"
                ? isRecruitPostPublicNeeded
                : isDirectPostPublicNeeded,
          };
          dispatch(updateJobDetail(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: i18n["alertMessage.Jobdataupdatedsuccessfully"],
                })
              );
              handleClose();
              setAllJobs([]);
              setJobsLastKey(0);
              getAdminJobList(0, basicData);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: ERROR_MSG,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.error"],
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };
  const [singleSalaryValue, setSingleSalaryValue] = useState(0);
  const getInitialSalary = (basicData) => {
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range;
    let salaryInterval;
    if (basicData?.job_role_type === "freelance") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      salaryInterval = interval;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
    }
    setSalaryRange(range);

    if (basicData?.job_role_type === "freelance") {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 100,
              basicData?.salary?.[1] / 100,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 100);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / salaryInterval,
              basicData?.salary?.[1] / salaryInterval,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / salaryInterval);
      }
    } else {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 10000,
              basicData?.salary?.[1] / 10000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 10000);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 1000,
              basicData?.salary?.[1] / 1000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 1000);
      }
    }
  };

  const fileAccept = "video/*";
  const uploadSpecFileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const hiddenFileInput = useRef(null);

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event?.target?.files?.[0];
    if (!selectedFile) {
      return;
    }
    if (isAdmin) {
      const formData = new FormData();
      const file = event?.target?.files?.[0];
      formData.append("jobspecemployer", file);
      editJobId !== undefined && formData.append("job_id", editJobId);
      try {
        const { payload } = await dispatch(uploadEmployerJobSpecData(formData));
        if (payload?.status === "success") {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.SUCCESS,
              msg: i18n["alertMessage.SpecuploadedSuccessfully"],
            })
          );
          setSpecName(payload?.data?.job_spec);
          !editJobId &&
            history(`/employer/post-a-job/${payload?.data?.job_id}`);
          // setOpenSpecBox(false);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: payload?.message,
            })
          );
        }
      } catch (error) {
        dispatch(setAlert({ show: true }));
      }
    } else {
      const link = URL.createObjectURL(selectedFile);
      await uploadVideo(selectedFile);
    }
  };

  const uploadVideo = async (file) => {
    const formData = new FormData();
    formData?.append("jobvideo", file);
    formData?.append("job_id", jobDetail?.job_id);
    formData?.append("user_id", jobDetail?.user_id);
    try {
      const { payload } = await dispatch(uploadPostJobCultureVideo(formData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.VideouploadedSuccessfully"],
          })
        );
        editJobDetail();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.FailedtouploadVideo"],
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  useEffect(() => {
    if (show) {
      getJobList();
    }
  }, [show]);

  useEffect(() => {
    getInitialSalary(jobDetail);
  }, [currency, jobDetail]);

  return (
    !isLoading && (
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} position="relative">
          <Box sx={{ position: "absolute", right: 0 }}>
            <Button
              variant="contained"
              color="uploadJobspec"
              sx={{
                color: "white",
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                borderRadius: "0 0 0 10px",
                padding: { xs: "6px", md24: "0px" },
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
              onClick={handleFileClick}
              startIcon={
                <Box
                  component={"img"}
                  src={specIcon}
                  sx={{
                    display: { xs: "none", tablet: "block" },
                    height: "20px",
                    width: "26px",
                  }}
                />
              }
            >
              {i18n["postAJob.uploadjobspec"]}
            </Button>
            <Typography>{specName}</Typography>
          </Box>

          <Box sx={{ height: "100%", overflow: "auto" }}>
            <EditJobType
              jobDetail={jobDetail}
              setJobDetail={setJobDetail}
              setIsInterviewNeeded={setIsInterviewNeeded}
              setisDirectPostPublicNeeded={setisDirectPostPublicNeeded}
              setisDirectVideoNeeded={setisDirectVideoNeeded}
              isInterviewNeeded={isInterviewNeeded}
              isRecruitVideoNeeded={isRecruitVideoNeeded}
              isRecruitPostPublicNeeded={isRecruitPostPublicNeeded}
              isDirectVideoNeeded={isDirectVideoNeeded}
              setisRecruitVideoNeeded={setisRecruitVideoNeeded}
              isDirectPostPublicNeeded={isDirectPostPublicNeeded}
              setisRecruitPostPublicNeeded={setisRecruitPostPublicNeeded}
              setAllJobs={setAllJobs}
              allJobs={allJobs}
              pinJobFlag={pinJobFlag}
            />
            <EditBasicInfo
              jobDetail={jobDetail}
              setJobDetail={setJobDetail}
              tools={tools}
              skills={skills}
              industries={industries}
              titles={titles}
              roleTypes={roleTypes}
              contractDetails={contractDetails}
              requiredQua={requiredQua}
              qualifications={qualifications}
              initialSkills={initialSkills}
              language={language}
              nationality={nationality}
              association={association}
              workSetup={workSetup}
              town={town}
              country={country}
              currency={currency}
              errors={errors}
              setErrors={setErrors}
              setSalaryRange={setSalaryRange}
              salaryRange={salaryRange}
              setRangeValue={setRangeValue}
              rangeValue={rangeValue}
              setSingleSalaryValue={setSingleSalaryValue}
              singleSalaryValue={singleSalaryValue}
            />
            <EditDetailInfo
              jobDetail={jobDetail}
              setJobDetail={setJobDetail}
              errors={errors}
              setErrors={setErrors}
              sameJobAndBio={sameJobAndBio}
              setSameJobAndBio={setSameJobAndBio}
            />
            <EditCultureDetail
              jobDetail={jobDetail}
              setJobDetail={setJobDetail}
              personalities={personalities}
              traits={traits}
              errors={errors}
              setErrors={setErrors}
              setisDirectVideoNeeded={setisDirectVideoNeeded}
              setisRecruitVideoNeeded={setisRecruitVideoNeeded}
              isDirectVideoNeeded={isDirectVideoNeeded}
              isRecruitVideoNeeded={isRecruitVideoNeeded}
            />
          </Box>
          <Box sx={{ background: "white" }}>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "333.33px",
                borderRadius: "0 0 0 10px",
                height: "60px",
                background: "lightgray",
                color: "black",
                padding: 3,
                ":hover": {
                  background: "lightgray",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              cancel
            </Button>
            <input
              accept={isAdmin ? uploadSpecFileAccept : fileAccept}
              ref={hiddenFileInput}
              type="file"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
            <Button
              variant="contained"
              color="recordVideoButton"
              sx={{
                width: "333.33px",
                height: "60px",
                fontSize: "15px",
                fontWeight: theme.typography.Bold,
                borderRadius: 0,
              }}
              onClick={() =>
                jobDetail?.briffing_video_flag && handleFileClick()
              }
              disabled={!jobDetail?.briffing_video_flag}
            >
              upload video
            </Button>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "333.34px",
                borderRadius: "0 0 10px 0",
                height: "60px",
                padding: 3,
              }}
              variant="contained"
              color="redButton100"
              onClick={editJobDetail}
            >
              edit
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  );
}
