import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../../common/CloseIcon";
import {
  ToggleCheckedSwitch,
  ToggleSwitch,
  ToggleSwitch2,
} from "../../../../utils/CommonComponent";
import { useDispatch } from "react-redux";
import { updateActiveStatus } from "../../../../redux/candidate/myProfileSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";

export default function GoDark({
  onClose,
  settingsRef,
  setSettingButton,
  profile,
  setProfile,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [status, setStatus] = useState(profile?.active);

  const handleStatusToggleChange = async () => {
    try {
      const data = {
        userstatus: !status,
      };
      const { payload } = await dispatch(updateActiveStatus(data));
      if (
        payload?.data?.status === "success" ||
        payload?.status === "success"
      ) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Status Changed",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong,please try again later!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  return (
    <Box sx={{ width: "300px", height: "auto", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="peachPayment"
          sx={{
            width: "150px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 10px 0",
            padding: "0px",
          }}
        >
          go dark
        </Button>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>

      <Box
        sx={{
          padding: "10px 10px 14px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
            }}
          >
            Profile status
          </Typography>

          <Box sx={{ display: "flex", gap: "13px", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Regular,
              }}
            >
              Not active
            </Typography>
            <ToggleSwitch2
              checkedColor={"greenSwitch"}
              notCheckedColor={"redButton"}
              checked={status}
              onChange={(event) => {
                setStatus(event?.target?.checked);
                setProfile({ ...profile, active: event?.target?.checked });
                handleStatusToggleChange();
              }}
            />
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Regular,
              }}
            >
              active
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="darkGrey"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
            color: "black",
          }}
          onClick={() => {
            setSettingButton(settingsRef?.current);
            onClose();
          }}
        >
          back to settings
        </Button>
      </Box>
    </Box>
  );
}
