import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import MyJobsCard from "../../common/myJobsSectionEmployerAndRecruiter/MyJobsCardNew";
// import ButtonPanel from "../../common/myJobsSectionEmployerAndRecruiter/ButtonPanel";
import {
  BASIC_SEARCH,
  PROFILE_COMPLETION_NUMBER,
} from "../../../utils/Constants";
import locale from "../../../i18n/locale";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { getAllEmployerJobs } from "../../../redux/employer/employerJobsConfigSlice";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  getFilteredJobsListing,
  getProfilePercentage,
} from "../../../redux/employer/empJobListing";
import { getAllStages } from "../../../redux/stages";
import { getJobStatus } from "../../../redux/status";
import { Button, Paper, ThemeProvider, useTheme } from "@mui/material";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import RightFiltersPanel from "../../common/myJobsSectionEmployerAndRecruiter/RightFiltersPanel";
import NoJobsBox from "../../common/myJobsSectionEmployerAndRecruiter/NoJobsBox";
import { employerMyJobsTheme } from "../../../utils/componentThemes/employerMyJobsTheme";
import MyClientFilter from "../../common/myJobsSectionEmployerAndRecruiter/MyClientFilter";
import CustomDialog from "../../common/CustomDialog";
import ProfileNotCompleted from "../../common/ProfileNotCompleted";
import ButtonPanel from "../../common/ButtonPanel";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../../../utils/Common";
import useViewport from "../../common/ViewPort";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { setSideFilter } from "../../../redux/login/loginSlice";

export default function RecruiterMyJobs() {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedAllStages = useRef([]);
  const selectedJobStatus = useRef([]);
  const navigate = useNavigate();
  const query = useQuery();
  const { width } = useViewport();
  const { isSideFilter } = useSelector((state) => state.login);

  const allStages = useSelector((state) => state?.configstages?.stages);
  const jobStatus = useSelector((state) => state?.configjobstatus?.status);
  const profilePercentage = useSelector(
    (state) => state.employerjoblisting.profilePercentage
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );

  const [currentPageJobsCount, setCurrentPageJobsCount] = useState(0);
  const [showNoInfoBox, setShowNoInfoBox] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [clientListFilter, setClientListFilter] = useState([]);
  const [jobListFilter, setJobListFilter] = useState([allStages?.[0]?.id]);
  const [jobStatusFilter, setJobStatusFilter] = useState([jobStatus?.[0]?.id]);
  const [assignedJobFilter, setAssignedJobFilter] = useState(
    query?.get("assignedjobs") ? true : false
  );
  const [talents, setTalents] = useState([]);
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [isJobUnlocked, setIsJobUnlocked] = useState(false);
  const [lastKey, setLastKey] = useState("");

  const [itemsFetched, setItemsFetched] = useState(false);
  const assignedjobClicked = useRef(false);

  const [selectedCompany, setSelectedCompany] = useState([]);
  const [showBox, setShowBox] = useState(false);
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [progressButton, setProgressButton] = useState(false);
  const boxRef = useRef();
  const handleClickOutside = (event) => {
    if (boxRef.current && !boxRef.current.contains(event.target)) {
      setProgressButton(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // responsive functions
  const gridRef = useRef(); // Create a ref to the grid card element
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    // let padding = 25;
    let padding = width < 390 ? 20 : 15;

    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }
  };
  useEffect(() => {
    if (gridRef.current) {
      calculateCardWidthAndPadding(gridRef.current.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, isSideFilter]);
  // responsive functions
  const allJobsFilter = async () => {
    await dispatch(getAllEmployerJobs());
  };

  const getStages = async () => {
    await dispatch(getAllStages());
  };
  const getStatus = async () => {
    await dispatch(getJobStatus());
  };

  const getProfilePercentageData = () => {
    if (
      profilePercentage?.myInfo + profilePercentage?.companyInfo <
      PROFILE_COMPLETION_NUMBER
    ) {
      setShowNoInfoBox(true);
    } else {
      setShowNoInfoBox(false);
    }
    // setProfilePercentage(payload?.data);
  };

  const handleFilterSelection = (paramType, filterName) => {
    if (
      filterName?.[0] === "all stages" ||
      filterName?.[0] === "job status" ||
      filterName === "assigned" ||
      filterName?.length === 0
    ) {
      if (filterName === "assigned") {
        setSelectedFilters([]);
      }
      filterName?.[0] === "all stages" && (selectedAllStages.current = []);
      filterName?.[0] === "job status" && (selectedJobStatus.current = []);
      const queryParams = new URLSearchParams(window?.location?.search);
      queryParams.delete(paramType);
      navigate(`/recruiter/my-jobs?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const getJobList = async (
    selectedFilters = jobListFilter,
    selectedStatusFilter = jobStatusFilter,
    lastkey = lastKey,
    assignedjob,
    clients
  ) => {
    const data = {
      selectedFilters:
        selectedFilters?.[0] !== 1111 ? selectedFilters?.toString() : "",
      selectedStatusFilter:
        selectedStatusFilter?.[0] !== 1111
          ? selectedStatusFilter?.toString()
          : "",
      lastKey: lastkey,
      assignedJobFlag: assignedjob,
      ...basicData,
      company_id: clients?.toString(),
    };
    const { payload } = await dispatch(getFilteredJobsListing(data));
    if (payload?.status === "success") {
      if (payload?.data?.length === 0 && payload?.pagenumber === 0) {
        setShowBox(true);
        setCurrentPageJobsCount(payload?.data?.length);
      } else {
        setCurrentPageJobsCount(payload?.data?.length);
        setShowBox(false);
        setProfileCompletion(25);
        setLastKey(payload?.pagenumber + 1);
        setAllJobs((prevState) => [...prevState, ...payload?.data]);
        setItemsFetched(true);
      }
    }
  };

  const assignedJobs = (name) => {
    handleFilterSelection(
      "assignedjobs",
      assignedjobClicked?.current ? name : "assigned"
    );
    setAssignedJobFilter((prev) => !prev);
    setAllJobs([]);
    getJobList(
      jobListFilter,
      jobStatusFilter,
      "",
      assignedjobClicked?.current ? true : "",
      clientListFilter
    );
  };
  const clientFilter = (selected) => {
    handleFilterSelection("clients", selected);
    setClientListFilter(selected);
    setAllJobs([]);
    getJobList(
      jobListFilter,
      jobStatusFilter,
      "",
      assignedJobFilter ? assignedJobFilter : "",
      selected
    );
  };

  const jobsFilter = (selectedFilter) => {
    let stages = [];
    selectedFilter?.map((item) => {
      let selcted = allStages?.find((filter) => filter?.id === item);
      stages?.push(selcted?.name);
    });

    selectedAllStages.current = selectedFilter;

    handleFilterSelection("allstages", stages);
    setAllJobs([]);
    setJobListFilter(selectedFilter);
    getJobList(
      selectedFilter,
      jobStatusFilter,
      "",
      assignedJobFilter ? assignedJobFilter : "",
      clientListFilter
    );
  };

  const jobsFilterLeft = (selectedFilter) => {
    let status = [];
    selectedFilter?.map((item) => {
      let selcted = jobStatus?.find((filter) => filter?.id === item);
      status?.push(selcted?.name);
    });

    selectedJobStatus.current = selectedFilter;
    handleFilterSelection("jobstatus", status);

    setAllJobs([]);
    setJobStatusFilter(selectedFilter);
    getJobList(
      jobListFilter,
      selectedFilter,
      "",
      assignedJobFilter ? assignedJobFilter : "",
      clientListFilter
    );
  };

  const calculatedHeight = () => {
    // Calculate the desired height based on screen resolution or any other logic
    // For example, you can use window.innerWidth or window.innerHeight to adjust the height dynamically.
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 90px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };

  useEffect(() => {
    Object?.keys(profilePercentage)?.length !== 0 && getProfilePercentageData();
  }, [profilePercentage]);

  useEffect(() => {
    allJobsFilter();
    getStages();
    getStatus();
  }, []);
  useEffect(() => {
    if (allStages?.length > 0) {
      setJobListFilter([allStages?.[0]?.id]);
      setJobListFilter([allStages?.[0]?.id]);
    }
    setAllJobs([]);
    // getJobList([allStages?.[0]?.id], [jobStatus?.[0]?.id], "");
    setTalents([]);
  }, []);

  useEffect(() => {
    let stagefilter = [];
    let statusfilter = [];
    let clientFilter = [];

    query
      .get("allstages")
      ?.split(",")
      ?.map((item) => {
        let stageID = allStages?.find((stage) => stage?.name === item);
        stagefilter?.push(stageID?.id);
      });
    selectedAllStages.current = stagefilter;
    setJobListFilter(stagefilter);

    query
      .get("jobstatus")
      ?.split(",")
      ?.map((item) => {
        let stageID = jobStatus?.find((stage) => stage?.name === item);
        statusfilter?.push(stageID?.id);
      });
    selectedJobStatus.current = statusfilter;
    setJobStatusFilter(statusfilter);

    query
      ?.get("clients")
      ?.split(",")
      ?.map((item) => {
        clientFilter?.push(parseInt(item));
        setSelectedCompany(clientFilter);
      });
    if (allStages?.length > 0 && jobStatus?.length > 0) {
      getJobList(
        stagefilter,
        statusfilter,
        "",
        assignedJobFilter ? assignedJobFilter : "",
        clientFilter
      );
    }
  }, [allStages, jobStatus]);

  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true));
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false));
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);
  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {width < 768 && width !== 0 && isSideFilter && (
        <Box
        onClick={() => dispatch(setSideFilter(false))}
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0, .2)",
          }}
        ></Box>
      )}
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: isSideFilter
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
          // width: isSideFilter && "150px",
          zIndex: "30",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
            boxShadow: "0px 5px 10px #00000029",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <LeftArrow /> : <RightArrow />}
        </Button> */}
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Paper
              sx={{
                background: "transparent",
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                boxShadow: 0,
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedAllStages?.current}
                panelData={allStages}
                side="left"
                color={"filterButtons"}
                onChangeFilter={jobsFilter}
              />

              <MyClientFilter
                side="left"
                setSelectedCompany={setSelectedCompany}
                selectedCompany={selectedCompany}
                color={"filterButtons"}
                onChangeFilter={clientFilter}
              />

              <Button
                sx={{
                  padding: "6px 7px",
                  lineHeight: "inherit",
                  // boxShadow: "0px 5px 10px #00000029",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  mr: { xs: 1, sm: 0 },
                  fontSize: theme.typography.fontSize.xs,
                  width: "125px",
                  fontWeight: theme.typography.Bold,
                  "&:hover": {
                    color: theme?.palette?.filterButtons?.contrastText,
                    backgroundColor: theme?.palette?.filterButtons?.main,
                    fontWeight: theme?.typography?.Bold,
                  },
                  height: "45px",
                }}
                disableElevation
                variant="contained"
                color={query?.get("assignedjobs") ? "filterButtons" : "base"}
                onClick={() => {
                  assignedjobClicked.current = !assignedjobClicked?.current;
                  assignedJobs("assignedjob");
                }}
              >
                {i18n["empMyJobs.assignedJobs"]}
              </Button>
            </Paper>
          </Box>
        )}
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          // zIndex:isSideFilter && -1,
          zIndex: width < 768 && isSideFilter && -1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          key={`${jobListFilter}, ${jobStatusFilter}`}
          height={calculatedHeight()}
          dataLength={allJobs?.length}
          scrollThreshold={"100px"}
          next={() =>
            getJobList(
              jobListFilter,
              jobStatusFilter,
              lastKey,
              assignedJobFilter ? assignedJobFilter : "",
              selectedCompany
            )
          }
          style={{
            overflowX: "hidden",
          }}
          hasMore={true}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            gap={width <= 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width <= 768 && "center"}
            sx={{
              position: "relative",
              padding: width <= 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: width <= 768 ? "35px" : "55px",
            }}
          >
            {allJobs?.length > 0 && !showNoInfoBox ? (
              allJobs?.map((job, index) => (
                <Box
                  sx={{
                    width: newCardWidth,
                    height: { xs: "556px", lg: "556px", xl: "582px" },
                  }}
                >
                  <ThemeProvider theme={employerMyJobsTheme}>
                    <MyJobsCard
                      isJobUnlocked={isJobUnlocked}
                      setIsJobUnlocked = {setIsJobUnlocked}
                      index={index}
                      job={job}
                      newCardWidth={newCardWidth}
                      getJobs={getJobList}
                      setAllJobs={setAllJobs}
                      allJobs={allJobs}
                      jobslength={allJobs?.length}
                      currentPageJobsCount={currentPageJobsCount}
                    />
                  </ThemeProvider>
                </Box>
              ))
            ) : showNoInfoBox ? (
              <CustomDialog
                show={true}
                hideButton={false}
                dialogWidth="xs"
                showFooter={false}
                padding={0}
              >
                <ProfileNotCompleted />
              </CustomDialog>
            ) : (
              showBox && (
                <Box
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    margin: "auto",
                  }}
                >
                  <NoJobsBox />
                </Box>
              )
            )}
            {allJobs?.length >= 1 && (
              <Box
                sx={{
                  width: newCardWidth,
                  height: { xs: "556px", lg: "556px", xl: "582px" },
                }}
              >
                <ThemeProvider theme={employerMyJobsTheme}>
                  <MyJobsCard index={-1} job={{}} newCardWidth={newCardWidth} />
                </ThemeProvider>
              </Box>
            )}
          </Grid>

          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                        display:none
                      }
                      .infinite-scroll-component__outerdiv {
                        width: 100%
                      }`}
          </style>
        </InfiniteScroll>
      </Box>
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: isSideFilter && "150px",
          right: "0",
          // width: isSideFilter
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button> */}
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              // paddingLeft: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                      width: 0px !important;
                      background-color: #EFEEEE; /* Set the background color of the scrollbar */
                   }
                   .rightfilterSec::-webkit-scrollbar-thumb {
                     background-color: white;
                     width: 0px;
                     box-shadow: 0px 3px 3px #00000029;
                     border-radius: 3px;
                   }`}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <RightFiltersPanel
                selected={selectedJobStatus?.current}
                panelData={jobStatus}
                color="filterButtons"
                side="right"
                onChangeFilter={jobsFilterLeft}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
