import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";
import constructionIcon from "../assets/PricingUnderConstructIcon.svg";

const HowTheSiteWork = ({ width }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflowY: "auto",
        columnGap: "30px",
        overflowX: "auto",
        display: "grid",
        direction: width <= 600 ? "column" : "row",
        gridTemplateColumns:
          width <= 600 ? "1fr" : "repeat(5, minmax(360px, 360px))",
        alignItems: width <= 600 ? "center" : "inherit",
        justifyContent: width <= 600 ? "center" : "inherit",
        paddingLeft: width <= 600 ? "30px" : "20px",
        paddingRight: width > 600 ? "40px" : "30px",
        height: "100%",
        paddingBottom: width <= 600 ? "42px" : "5px",
      }}
      // height={width <= 600 ? '71%' : "78%"}
    >
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Box
          sx={{
            height: "530px",
            width: "360px",
            borderRadius: "25px",
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px #00000029",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              marginBottom: "10px",
              paddingX: "30px",
              marginTop: "20px",
            }}
          >
            Under construction…{" "}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              marginBottom: "70px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
          >
            Here we’ll be adding an explainer how-to video about the design of
            the overall site and how everything fits together… stay tuned!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "183px",
            }}
          >
            <Box
              component={"img"}
              sx={{ width: "150px", height: "150px" }}
              src={constructionIcon}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowTheSiteWork;
