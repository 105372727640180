import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import TalentCard from "./TalentCardNew";
import ButtonPanel from "../../common/ButtonPanel";
import {
  ALERT_TYPE,
  BASIC_SEARCH,
  TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP,
} from "../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilteredTalent,
  updateAllTalent,
} from "../../../redux/guest/talentSlice";
import {
  getAllIndustries,
  setAlert,
  setLoading,
} from "../../../redux/configSlice";
import { getAllTypes } from "../../../redux/allTypes";
import { getAllTalentType } from "../../../redux/guest/talentTypes";
import { Button, Paper } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { getDecodedToken, getToken, useQuery } from "../../../utils/Common";
import NoRecord from "../../common/NoRecord";
import { useNavigate } from "react-router-dom";
import CommonLoader from "../../common/CommonLoader";
import useViewport from "../../common/ViewPort";
import Loader from "../../common/Loader";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { setSideFilter } from "../../../redux/login/loginSlice";

export default function Talent({ handleTalentListingDynamicTheme }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { width } = useViewport();
  const selectedIndustries = useRef([]);
  const selectedTalent = useRef([]);
  const selectedActivity = useRef([]);
  const selectedType = useRef([]);

  const currentPageJobsCount = useSelector(
    (state) => state.configtalentslice?.currentPageJobsCount
  );
  const showBox = useSelector((state) => state.configtalentslice?.showBox);
  const allTalent = useSelector((state) => state.configtalentslice?.talentList);
  const talentNextPageID = useSelector(
    (state) => state.configtalentslice?.talentNextPageID
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const { isSideFilter } = useSelector((state) => state.login);
  const allIndustries = useSelector((state) => state.config?.industries);
  const allTypes = useSelector((state) => state.configAllTypes?.types);
  const allTalentTypes = useSelector((state) => state.talenttype?.talentType);
  const isLoading = useSelector((state) => state.config.loading);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [filters, setFilters] = useState([allIndustries?.[0]?.id]);
  const [filtersType, setFiltersType] = useState([allTypes?.[0]?.id]);
  const [filtersJobType, setFiltersJobType] = useState([
    allTalentTypes?.[0]?.id,
  ]);
  const [activityFilter, setActivityFilter] = useState({
    recent: "",
    active: "",
    indemand: "",
    favourite: "",
    mytalent: "",
  });
  const [filtersWorkType, setFiltersWorkType] = useState([]);
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);

  const [lastKey, setLastKey] = useState(0);

  const token = localStorage?.getItem("token");
  let decodedToken = getDecodedToken(getToken());

  // responsive functions
  const [itemsFetched, setItemsFetched] = useState(false);
  const gridRef = useRef(); // Create a ref to the grid card element
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);

  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true));
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false));

      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }
  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };
  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, isSideFilter]);
  // responsive functions

  const getTalent = async (
    selectedFilters = filters,
    personalityType = filtersType,
    jobtype = filtersJobType,
    worktype = filtersWorkType,
    allActivityFilter = activityFilter,
    lastkeyy
  ) => {
    // const lastKey = all_talent?.[all_talent.length - 1]?.user_id || "";
    // lastkeyy === "" && setIsDataLoading(true);
    // dispatch(setLoading({value:true, apiName:"getTalent"}));
    dispatch(setLoading({ value: true, apiName: "getTalent" }));
    let data = {
      selectedFilters: selectedFilters?.toString(),
      nextPageId: lastkeyy,
      personalityType: personalityType?.toString(),
      jobtype: "",
      employment_type: jobtype?.[0] !== 1111 ? jobtype.toString() : "",
      work_setup: worktype?.[0] !== "all talent" ? worktype.toString() : "",
      recent: allActivityFilter?.recent || "",
      active: allActivityFilter?.active || "",
      indemand: allActivityFilter?.indemand || "",
      favourite: allActivityFilter?.favourite || "",
      mytalent: allActivityFilter?.mytalent || "",
      ...basicData,
      talent_title: query.get("job_title")
        ? query.get("job_title")
        : basicData?.job_title,
      query: query?.get("query") ? query?.get("query") : "",
      town_id: query.get("location")
        ? query.get("location")
        : query.size < 1
        ? ""
        : basicData?.town_id,
      region_id: query.get("region_id")
        ? query.get("region_id")
        : query.size < 1
        ? ""
        : basicData?.region_id,
      tag_id: query.get("tag_id")
        ? query.get("tag_id")
        : query.size < 1
        ? ""
        : basicData?.tag_id,
      tool_id: query.get("tool_id")
        ? query.get("tool_id")
        : query.size < 1
        ? ""
        : basicData?.tool_id,
      company_id: query.get("company_id")
        ? query.get("company_id")
        : query.size < 1
        ? ""
        : basicData?.company_id,
      currency_id: query.get("currency_id")
        ? query.get("currency_id")
        : query.size < 1
        ? ""
        : basicData?.currency_id,
      highest_qualification_id: query.get("highest_qualification_id")
        ? query.get("highest_qualification_id")
        : query.size < 1
        ? ""
        : basicData?.highest_qualification_id,
      salary: query.get("salary")
        ? query.get("salary")
        : query.size < 1
        ? ""
        : basicData?.salary,
      experience: query.get("experience")
        ? query.get("experience")
        : query.size < 1
        ? ""
        : basicData?.experience,
      decodedToken: decodedToken,
    };

    const { payload } = await dispatch(getFilteredTalent(data));
    setIsDataLoading(false);
    if (payload?.status === "success") {
      if (payload?.data?.length <= 0) {
      } else {
        setLastKey(payload?.nextPageId);
        setItemsFetched(true);
      }
      dispatch(setLoading({ value: false, apiName: "getTalent" }));
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message,
        })
      );
      dispatch(setLoading({ value: false, apiName: "getTalent" }));
    }
  };

  const getIndustries = async () => {
    await dispatch(getAllIndustries());
  };
  const getTypes = async () => {
    await dispatch(getAllTypes());
  };
  const getTalentTypes = async () => {
    await dispatch(getAllTalentType());
  };

  const handleFilterSelection = (paramType, filterName) => {
    if (
      filterName[0] === "all talent" ||
      filterName[0] === "all industries" ||
      filterName[0] === "all activity" ||
      filterName[0] === "all types"
    ) {
      filterName[0] === "all industries" && (selectedIndustries.current = []);
      filterName[0] === "all talent" && (selectedTalent.current = []);
      filterName[0] === "all activity" && (selectedActivity.current = []);
      filterName[0] === "all types" && (selectedType.current = []);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType);
      navigate(`/talent?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const onChangeFilter = (selectedFilter) => {
    let industry = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allIndustries?.find(
        (jobtype) => jobtype?.id === type
      );
      industry?.push(selectedJobType?.name);
    });
    setIsFilterApplied(true);
    dispatch(updateAllTalent());
    setLastKey("");
    setFilters(selectedFilter);
    handleFilterSelection("industries", industry);
    getTalent(
      selectedFilter,
      filtersType,
      filtersJobType,
      filtersWorkType,
      activityFilter,
      ""
    );
  };

  const onChangeFilterType = (selectedFilter) => {
    let allType = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allTypes?.find((jobtype) => jobtype?.id === type);
      allType?.push(selectedJobType?.name);
    });
    setIsFilterApplied(true);
    dispatch(updateAllTalent());
    setLastKey("");
    setFiltersType(selectedFilter);
    handleFilterSelection("all types", allType);
    getTalent(
      filters,
      selectedFilter,
      filtersJobType,
      filtersWorkType,
      activityFilter,
      ""
    );
  };

  const onChangeActivityFilter = (selectedFilter) => {
    if (
      (decodedToken && decodedToken?.data?.role_id !== 3) ||
      selectedFilter.includes(1) ||
      selectedFilter.includes(2) ||
      selectedFilter.includes(3) ||
      selectedFilter.includes(4) ||
      selectedFilter.includes(1111)
    ) {
      dispatch(updateAllTalent());
      setLastKey("");
      const allActivityFilter = {
        recent: selectedFilter?.includes(2) ? true : "",
        active: selectedFilter?.includes(3) ? true : "",
        indemand: selectedFilter?.includes(4) ? true : "",
        favourite: selectedFilter?.includes(5) ? true : "",
        mytalent: selectedFilter?.includes(6) ? true : "",
      };
      let selected = [];
      selectedFilter?.map((type) => {
        let selectedJobType = TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP?.find(
          (jobtype) => jobtype?.id === type
        );
        selected?.push(selectedJobType?.name);
      });
      setIsFilterApplied(true);
      setActivityFilter(allActivityFilter);
      handleFilterSelection(
        "all activity",
        selectedFilter?.includes(1111) ? ["all activity"] : selected
      );
      getTalent(
        filters,
        filtersType,
        filtersJobType,
        filtersWorkType,
        allActivityFilter,
        ""
      );
    } else {
      handleFilterSelection("all activity", ["all activity"]);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg:
            decodedToken?.data?.role_id !== 3
              ? "Login first to apply this filter"
              : "Login as employer to apply this filter",
        })
      );
    }
  };

  const onChangeFilterJobType = (selectedFilter) => {
    let jobstype = [];
    let worksetup = [];
    selectedFilter
      ?.filter((item) => item <= 5)
      ?.map((type) => {
        let selectedJobType = allTalentTypes?.find(
          (jobtype) => jobtype?.id === type
        );
        jobstype?.push(selectedJobType?.name);
      });

    selectedFilter
      ?.filter((item) => item > 5)
      ?.map((type) => {
        let selectedJobType = allTalentTypes?.find(
          (jobtype) => jobtype?.id === type
        );
        worksetup?.push(selectedJobType?.name);
      });
    setIsFilterApplied(true);
    dispatch(updateAllTalent());
    setLastKey("");
    setFiltersJobType(jobstype);
    setFiltersWorkType(worksetup);
    handleFilterSelection("jobstype", jobstype);
    handleFilterSelection("worksetup", worksetup);
    getTalent(filters, filtersType, jobstype, worksetup, activityFilter, "");
  };

  const calculateDynamicHeight = () => {
    // Calculate the desired height based on screen resolution or any other logic
    // For example, you can use window.innerWidth or window.innerHeight to adjust the height dynamically.
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      // sm
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      // md
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1920) {
      // lg
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      // xl
      return `calc(100vh - 100px)`;
    } else {
      // xxl or greater
      return `calc(100vh - 120px)`;
    }
  };
  useEffect(() => {
    let industry = [];
    let types = [];
    const allActivityFilter = {
      recent: query.get("all activity")?.includes("recent") ? true : "",
      active: query.get("all activity")?.includes("active") ? true : "",
      indemand: query.get("all activity")?.includes("in demand") ? true : "",
      favourite: query.get("all activity")?.includes("favourites") ? true : "",
      mytalent: query.get("all activity")?.includes("my talent") ? true : "",
    };
    dispatch(updateAllTalent());
    getIndustries();
    getTypes();
    getTalentTypes();
    getTalent(
      industry,
      types,
      query.get("jobstype") !== null
        ? query.get("jobstype")
        : [allTalentTypes?.[0]?.id],
      query.get("worksetup") !== null ? query.get("worksetup") : [],
      allActivityFilter,
      ""
    );
  }, []);

  useEffect(() => {
    let industry = [];
    let jobtype = [];
    let worksetup = [];
    let activity = [];
    let types = [];

    query
      .get("all activity")
      ?.split(",")
      .map((type) => {
        let selectedJobType = TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP?.find(
          (jobtype) => jobtype?.name === type
        );
        activity?.push(selectedJobType?.id);
      });
    selectedActivity.current = activity;

    query
      .get("all types")
      ?.split(",")
      .map((type) => {
        let selectedJobType = allTypes?.find(
          (jobtype) => jobtype?.name === type
        );
        types?.push(selectedJobType?.id);
      });
    selectedType.current = types;

    query
      .get("industries")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allIndustries?.find(
          (jobtype) => jobtype?.name === type
        );
        industry?.push(selectedJobType?.id);
      });
    selectedIndustries.current = industry;

    query
      .get("jobstype")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allTalentTypes?.find(
          (jobtypes) => jobtypes?.name === type
        );
        jobtype?.push(selectedJobType?.id);
      });

    query
      .get("worksetup")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allTalentTypes?.find(
          (jobtype) => jobtype?.name === type
        );
        worksetup?.push(selectedJobType?.id);
      });

    const mergedString = [...jobtype, ...worksetup];
    selectedTalent.current = mergedString;

    const allActivityFilter = {
      recent: query.get("all activity")?.includes("recent") ? true : "",
      active: query.get("all activity")?.includes("active") ? true : "",
      indemand: query.get("all activity")?.includes("in demand") ? true : "",
      favourite: query.get("all activity")?.includes("favourites") ? true : "",
      mytalent: query.get("all activity")?.includes("my talent") ? true : "",
    };

    setFilters(industry);
    setFiltersType(types);
    setFiltersJobType(jobtype);
    setFiltersWorkType(worksetup);
    setActivityFilter(allActivityFilter);
    // dispatch(updateAllTalent());
    if (
      allIndustries?.length > 0 &&
      allTalentTypes?.length > 0 &&
      allTypes?.length > 0
    ) {
      if (isFilterApplied) {
        getTalent(
          industry,
          types,
          query.get("jobstype") !== null
            ? query.get("jobstype")
            : [allTalentTypes?.[0]?.id],
          query.get("worksetup") !== null ? query.get("worksetup") : [],
          allActivityFilter,
          ""
        );
      }
    }
  }, [allIndustries, allTalentTypes, allTypes]);

  useEffect(() => {
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleTalentListingDynamicTheme(decodedToken?.data?.personality);
    }
  }, [decodedToken]);

  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position:"relative"

      }}
    >
           {width < 768 && width !==0 && isSideFilter && 
      <Box sx={{position:"absolute", top:"0", left:"0", height:"100%", width:"100%", background:"rgba(0,0,0, .2)"}}></Box>
      }
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          // width: leftExpanded && "150px",
          zIndex: "30",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <LeftArrow /> : <RightArrow />}
        </Button> */}
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px)`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Box
              sx={{
                // maxWidth: `${width < 768 && "125px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedIndustries.current}
                topMargin={true}
                panelData={allIndustries}
                side="left"
                color="filterButtons"
                onChangeFilter={onChangeFilter}
                type="common"
              />
            </Box>
            {/* <Button
              variant="contained"
              sx={{
                position: "static",
                borderRadius: "0 10px 10px 0",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="filterButtons"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpand((prevState) => !prevState);
                // dispatch(setFilterExpanded(!filterExpanded));
              }}
            >
              {isSideFilter ? <LeftArrow /> : <RightArrow />}
            </Button> */}
            <style>
              {`.filterSec::-webkit-scrollbar {
                      display: none; /* Set the background color of the scrollbar */
                    }`}
            </style>
          </Box>
        )}
      </Box>
      {/* {isDataLoading ? (
        <Loader />
      ) : ( */}
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          // zIndex:isSideFilter && -1,
          zIndex:width < 768 && isSideFilter && -1,
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          height={calculateDynamicHeight()}
          key={`${filters} + ${filtersType} + ${filtersJobType} + ${filtersWorkType} + ${activityFilter.active} + ${activityFilter.recent} + ${activityFilter.favourite} + ${activityFilter.indemand} + ${activityFilter.mytalent}`}
          dataLength={allTalent?.length}
          next={() =>
            getTalent(
              filters,
              filtersType,
              filtersJobType,
              filtersWorkType,
              activityFilter,
              talentNextPageID
            )
          }
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          scrollThreshold={"200px"}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            gap={width < 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width < 768 && "center"}
            sx={{
              position: "relative",
              padding: width < 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: query.get("company_id")
                ? "20px"
                : width <= 768
                ? "35px"
                : "55px",
                paddingBottom: '35px !important'
            }}
          >
            {allTalent?.length > 0
              ? allTalent?.map((talent, index) => {
                  return (
                    <Box
                      sx={{
                        width: newCardWidth,
                      }}
                    >
                      <TalentCard
                        job={talent}
                        allTalent={allTalent}
                        jobslength={allTalent?.length}
                        index={index}
                        currentPageJobsCount={currentPageJobsCount}
                      />
                    </Box>
                  );
                })
              : showBox && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <NoRecord />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                      display:none;
              }
              .infinite-scroll-component__outerdiv{
                width:100%
              }
              `}
          </style> 
        </InfiniteScroll>
      </Box>
      {/* )} */}
      <Grid
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          right: "0",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
        }}
      >
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {/* <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <RightArrow /> : <LeftArrow />}
        </Button> */}
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                  display:none;
                }
                `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedTalent?.current}
                topMargin={true}
                panelData={allTalentTypes}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobType}
              />
              <ButtonPanel
                selected={selectedActivity?.current}
                panelData={TALENT_RIGHT_JOB_ACTIVITY_BUTTON_GROUP}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeActivityFilter}
              />
              <ButtonPanel
                selected={selectedType.current}
                panelData={allTypes}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterType}
              />
            </Paper>
            <Box
              sx={{
                minWidth: "30px",
                width: "30px",
                height: "45px",
                minHeight: "45px",
              }}
            ></Box>
            {/* <Button
              variant="contained"
              sx={{
                position: "sticky",
                top: 0,
                borderRadius: "10px 0 0 10px",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="filterButtons"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpand((prevState) => !prevState);
                dispatch(setFilterExpanded(!filterExpanded));
              }}
            >
              {isSideFilter ? <RightArrow /> : <LeftArrow />}
            </Button> */}
          </Box>
        )}
      </Grid>
    </Box>
  );
}
