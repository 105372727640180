import Box from "@mui/material/Box";
import TopBar from "../components/common/TopBar";
import { useEffect, useRef, useState } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import Snackbar from "../components/common/SnackBar";
import Backdrop from "../components/common/Backdrop";
import upArrow from "../assets/Black_Up_Close.svg";
import downArrowWhite from "../assets/Black_Down_Open.svg";
import useViewport from "../components/common/ViewPort";
import whiteClose from "../assets/Padding Included/White_Close.svg";

import InfoIcon from "../assets/InfoIcon.svg";
import { ThemeProvider } from "@emotion/react";
import {
  topBarChallengerTheme,
  topBarCharacterTheme,
  topBarCollaboratorTheme,
  topBarContemplatorTheme,
  topBarTheme,
} from "../utils/Theme";
import jwt_decode from "jwt-decode";
import SmallButton from "../components/common/SmallButton";
import { useTheme } from "@mui/material";
import LabTabs from "./FooterTabs";
import { useSelector } from "react-redux";
import HowThisPageWork from "./HowThisPageWork";
import HowTheSiteWork from "./HowTheSiteWork";
import WhatTheFaqFooterComponent from "./WhatTheFaqFooterComponent";
import CultureFooterComponent from "./CultureFooterComponent";
import OurStoryFooterComponent from "./OurStoryFooterComponent";
import MoreCoolStuff from "./MoreCoolStuff";
import CloseIcon from "../components/common/CloseIcon";
import FooterTabsNew from "./FooterTabsNew";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function Main() {
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const theme = useTheme();
  const [showFooter, setShowFooter] = useState(false);
  const footerRef = useRef();
  const footerBtnRef = useRef();
  const token = localStorage?.getItem("token");
  let decodedToken;

  if (token) {
    decodedToken = jwt_decode(token);
  }

  const { leftMenuButton } = useSelector((state) => state.adminSlice);

  const initialTabs = [
    "more cool stuff",
    "our story",
    "culture add",
    "what da FAQ?!",
    "how the site works",
    "how this page works",
  ];

  const colors = {
    "how this page works": "#38A8AE",
    "how the site works": "#00A9E0",
    "what da FAQ?!": "#CE0A24",
    "culture add": "#FFC425",
    "our story": "#EC5228",
    "more cool stuff": "#5932D9",
  };

  const [topBarDynamicTheme, setTopBarDynamicTheme] = useState(topBarTheme);
  const { width } = useViewport();

  useEffect(() => {
    if (pathname === "/") navigate("/", { replace: true });
  }, []);

  const handleCandidateDynamicTheme = (value) => {
    if (value === 1) {
      setTopBarDynamicTheme(topBarChallengerTheme);
    } else if (value === 2) {
      setTopBarDynamicTheme(topBarCharacterTheme);
    } else if (value === 3) {
      setTopBarDynamicTheme(topBarContemplatorTheme);
    } else if (value === 4) {
      setTopBarDynamicTheme(topBarCollaboratorTheme);
    } else {
      setTopBarDynamicTheme(topBarTheme);
    }
  };

  useEffect(() => {
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
  }, []);

  const handleClickOutside = (event) => {
    if (
      footerRef.current &&
      !footerRef.current.contains(event.target) &&
      !footerBtnRef.current.contains(event.target)
    ) {
      setShowFooter(false);
    }
  };

  useEffect(() => {
    if (showFooter) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFooter]);
  useEffect(() => {
    if (leftMenuButton) {
      setShowFooter(false);
    }
  }, [leftMenuButton]);

  const [activeTab, setActiveTab] = useState(
    initialTabs[initialTabs.length - 1]
  );
  const [tabs, setTabs] = useState(initialTabs);

  const tabComponent = {
    "more cool stuff": (
      <Box
        sx={{
          background: "#F2F4FB",
          height: "100%",
          // position: "relative",
        }}
      >
        <MoreCoolStuff width={width} />
      </Box>
    ),
    "our story": (
      <Box
        sx={{
          background: "#F2F4FB",
          height: "100%",
        }}
      >
        <OurStoryFooterComponent width={width} />
      </Box>
    ),
    "culture add": (
      <Box
        sx={{
          background: "#FFF",
          height: "100%",
        }}
      >
        <CultureFooterComponent width={width} />
      </Box>
    ),
    "what da FAQ?!": (
      <Box
        sx={{
          background: "#F2F4FB",
          height: "100%",
        }}
      >
        <WhatTheFaqFooterComponent width={width} />
      </Box>
    ),
    "how the site works": (
      <Box
        sx={{
          background: "#F2F4FB",
          height: "100%",
        }}
      >
        <HowTheSiteWork width={width} />
      </Box>
    ),
    "how this page works": (
      <Box
        sx={{
          background: "#F2F4FB",
          height: "100%",
        }}
      >
        <HowThisPageWork width={width} />
      </Box>
    ),
  };

  useEffect(() => {
    if(showFooter === false){
      setActiveTab("how this page works");
    }
  },[showFooter]);

  return (
    <>
      {/* <Snackbar /> */}
      <Backdrop />
      <ThemeProvider theme={topBarDynamicTheme}>
        <TopBar handleCandidateDynamicTheme={handleCandidateDynamicTheme} />
      </ThemeProvider>
      <Box
        component="main"
        sx={{
          height: {
            xs: `calc(100vh - 60px)`,
            sm: `calc(100vh - 60px )`,
            md: `calc(100vh - 90px)`,
            mdl: `calc(100vh - 90px)`,
            lg: `calc(100vh - 80px)`,
            mlg: `calc(100vh - 80px)`,
            xl: `calc(100vh - 100px)`,
            xxl: "calc(100vh - 120px)",
          },
        }}
      >
        <Outlet />
        <>
          <Box
            ref={footerBtnRef}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              position: "fixed",
              bottom: "0",
              zIndex: leftMenuButton ? 999 : 1000,
            }}
          >
            {width > 600 && (
              <SmallButton
                backgroundColor={
                  showFooter
                    ? colors?.[activeTab]
                    : theme.homepage.footerButton.main
                }
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 15,
                      width: 15,
                      marginRight: "5px",
                    }}
                    alt="Down arrow"
                    // src={InfoIcon}
                    src={showFooter ? whiteClose : InfoIcon}
                  />
                }
                onClick={() => {
                  setShowFooter((prev) => !prev);
                  setActiveTab("how this page works");
                }}
                // label={"info"}
                display="flex"
                // justifyContent={'space-around'}
                alignItems={"center"}
                startIconMargin="0px !important"
                marginTop="0px !important"
                padding="0px !important"
                height={{ xs: 30 }}
                width={{ xs: 60, tablet: 100 }}
                fontWeight={700}
                fontSize={"14px"}
                borderRadius="10px 10px 0px 0px"
                label={width > 600 ? (showFooter ? "close" : "info") : ""}
                // zIndex='10000'
              />
            )}
            {width <= 600 && !showFooter && (
              <SmallButton
                backgroundColor={
                  showFooter
                    ? colors?.[activeTab]
                    : theme.homepage.footerButton.main
                }
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 15,
                      width: 15,
                      marginRight: "5px",
                    }}
                    alt="Down arrow"
                    src={showFooter ? downArrowWhite : InfoIcon}
                  />
                }
                onClick={() => {
                  setShowFooter((prev) => !prev);
                }}
                startIconMargin="0px !important"
                marginTop="0px !important"
                padding="0px !important"
                height={{ xs: 30 }}
                width={{ xs: 100, tablet: 100 }}
                fontWeight={700}
                fontSize={"14px"}
                borderRadius="10px 10px 0px 0px"
                label={width < 600 ? (showFooter ? "close" : "info") : ""}
              />
            )}
          </Box>
          {showFooter && (
            <Box
              ref={footerRef}
              sx={{
                position: "fixed",
                bottom: "0",
                zIndex: leftMenuButton ? 998 : 999,
                width: "100vw",
                // height: "100%",
                // background: "#F2F4FB",
                // maxHeight: {
                //   xs: `calc(100vh - 80px)`,
                //   sm: `calc(100vh - 80px )`,
                //   md: `calc(100vh - 110px)`,
                //   mdl: `calc(100vh - 110px)`,
                //   lg: `calc(100vh - 1000px)`,
                //   mlg: `calc(100vh - 100px)`,
                //   xl: `calc(100vh - 200px)`,
                //   xxl: "100%",
                // },
                // minHeight: '780px',
                // height:width <= 600 ? '680px' : "680px",
                // paddingBottom: width <= 600 ? "0" : "0",
                // minHeight: { xs360: "100px", lgm: "660px" },
                // minHeight:width <= 600 ? '680px' : "800px",
                // maxHeight: width <= 600 ? 'calc(100vh - 100px) !important' : 'calc(100vh - 140px) !important',
                // width: width <= 600 ? "101vw" : "100.1vw",
                // left: width <= 600 ? '-2px': "-2px",
                // zIndex: leftMenuButton ? 998 : 999,
                // border: "1px solid red",
                // background: "#F2F4FB",
              }}
            >
              <FooterTabsNew
                tabs={tabs}
                setTabs={setTabs}
                colors={colors}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabComponent={tabComponent}
                setShowFooter={setShowFooter}
              />
              {/* <LabTabs
                tabs={tabs}
                setTabs={setTabs}
                colors={colors}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                tabComponent={tabComponent}
                setShowFooter={setShowFooter}
              /> */}
            </Box>
          )}
        </>
      </Box>
    </>
  );
}
