import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import React from "react";

const WhatTheFaqFooterComponent = ({ width }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        overflowY: "auto",
        // gap: "30px",
        columnGap: "30px",
        overflowX: "auto",
        display: "grid",
        direction: width <= 600 ? "column" : "row",
        gridTemplateColumns:
          width <= 600 ? "1fr" : "repeat(5, minmax(360px, 360px))",
        alignItems: width <= 600 ? "center" : "inherit",
        justifyContent: width <= 600 ? "center" : "inherit",
        paddingLeft: width <= 600 ? "20px" : "20px",
        paddingRight: width > 600 ? "40px" : "20px",
         height:'100%',
         paddingBottom:width <= 600 ? '42px' :'0px'
      }}
      // height={width <= 600 ? "73%" : "78%"}
    >
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Who is Crayon for?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Crayon is built for employers and recruiters looking for a fresh
          alternative to traditional job marketplaces and ATS offerings. It’s a
          platform for candidates to showcase not only their skills, but their
          unique personalities too.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            letterSpacing: "0px",
            // color: "#fff",
            lineHeight: "19px",
          }}
        >
          What is a Crayon Direct job?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          You will be liaising directly with the employer or recruiter who
          posted the job and not via the Crayon team. They will manage the
          process and reach out to you.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What is a Crayon Recruit job?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          The Crayon team is running this one so you will be liaising via us on
          behalf of one of our clients. Please do not reach out to the company
          directly.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          How do I track my application?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Every job you apply to will be displayed on your dashboard post login.
          The stage of the job and your individual application stage will be
          shown on each job card on the ‘my jobs’ page. If there is an update to
          either, you will receive an email.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          How does the Crayon Bounty referral incentive work?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          All users can share jobs to social networks or via email and have
          referrals tracked via a sharing or affiliate code. Any referral is
          yours for life and if they get hired, for any job, you earn the Bounty
          offered by the employer or Crayon on that role.
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "40px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What is the personality quiz used for?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          The quick 100-question quiz takes 5-10 minutes and allows us to assign
          you a primary and secondary personality, top 5 positive traits and a
          Grit Score. This is more for your benefit but is also used by
          employers to get a feel for you as an individual beyond the data.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What is a Grit Score?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We combine 11 of your traits to generate a score that provides a
          measure of your conscientiousness, tenacity and perseverance. There is
          no good or bad score. Refer to the Culture Add section for more info.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          How is my data handled?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Crayon is POPI and GDPR compliant. We share with employers and
          recruiters only the data you permit and provide options to hide
          certain aspects of your profile. You can delete your account at any
          time, although we’ll be sad to see you go.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What do your terms say about reaching out to clients directly?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            lineHeight: "19px",
            letterSpacing: "0px",
          }}
        >
          Don’t do it. We are making an effort to bring transparency to the
          marketplace via disclosing who we are working with, either on their
          behalf via Crayon Recruit, or them using the platform, via Crayon
          Direct. And besides, it’s just plain irritating receiving unsolicited
          outreach. Trust the platform, trust the process.
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "40px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What do your terms say about reaching out to candidates directly or
          receiving applications via my company site as a result of Crayon’s
          marketing?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We partner with our clients to source immediate talent and facilitate
          the growth of your long-term talent pool(s). Circumventing and
          undermining this collaboration, especially if running a job via Crayon
          Recruit, will simply result in us placing features of the platform
          behind a paywall. You have the ability to unlock CVs, unlock videos,
          and are able to redirect applications to your site or ATS post them
          applying. Also, CrayBot tracks everything.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          How much does it cost to post and unlock a job?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Free! Post a job, wait a couple of hours, and when you check back in,
          you’ll be able to see the number of matched profiles, applicants, and
          candidates our Talent Partners have shortlisted into your funnel. To
          unlock a job will set you back 10 Crayon coins (R950). If you opted to
          receive video applications, it’s an extra 10 coins. If via Crayon
          Recruit, and you want to receive recorded screening interviews
          conducted by the Crayon team, it’s an extra 10 coins.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What are your success fees?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          If hiring via Crayon Recruit, our success fees start from 5%. Yes,
          that’s not a typo.
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "40px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          How do I become a Talent Partner?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Our network of Talent Partners work independently, but together with
          the Crayon team to either actively work assigned roles, or to submit
          candidates to earn the indicated Bounty. We will always have a job
          open under the Crayon company page where you can apply to become a
          Talent Partner. We don’t mind if you’re currently a recruiter
          elsewhere, just be good at it.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What is the difference between Crayon Lite and Crayon Talent?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "15px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We offer two ways to manage talent. If you are here for a good time,
          not a long time, you’ll start and stay on our free offering, Crayon
          Lite. If you’re here for a good time and a long time, and want to
          unlock the full power of the platform, Crayon Talent is for you.
          Crayon Talent gives you unlimited access to our backend (easy there
          CrayBot), our global candidate database and the ability to create
          talent pools and advanced searches, and share content with your
          following. You save up to 40% on hiring fees too!
        </Typography>
      </Box>
      <Box sx={{ boxSizing: "border-box", paddingTop: "10px", height: "100%" }}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Bold,
            marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Rarely Asked Questions
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          What is Crayon?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Crayon is a Smiles-as-a-Software (SaaS) talent platform built from the
          ground up to solve for the problem statement faced by all founders,
          managers, and HR specialists - “How do I hire effectively and
          affordably for my business while ensuring our brand is able to attract
          and retain top talent?”
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Why the name Crayon?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Everyone is their own unique persona in the box of crayons that is
          life. Combining big data with the human element, we like to colour
          outside the lines.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Who is CrayBot?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          I am CrayBot. You are currently in my house; the humans just work
          here. CrayBot is our feisty in-house data guy. He takes what he learns
          from candidates and employers and recruiters, and goes to work in the
          background trying his best to connect smiles. He’s basically a
          matchmaking jobs Cupid. He’s currently in beta (to make it better),
          but his dream is to one day to turn ‘ai’ into AI.
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            // marginBottom: "5px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          Who are your competitors?
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          We prefer to collaborate, but if pressed for an answer, let’s put it
          this way…
        </Typography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: theme.typography.Regular,
            marginBottom: "10px",
            // color: "#fff",
            letterSpacing: "0px",
            lineHeight: "19px",
          }}
        >
          If you’re currently posting jobs via LinkedIn, Pnet, Careers24,
          Indeed, Bamboo, Lever, Greenhouse, Workable, etc., as an employer or
          recruiter, Crayon Direct is for you. If you’re currently using
          third-party recruiters, and paying 15%+, Crayon Recruit is for you.
        </Typography>
      </Box>
    </Box>
  );
};

export default WhatTheFaqFooterComponent;
