import Button from "@mui/material/Button";
import locale from "../../../i18n/locale";
import { Box, InputLabel, TextField, Tooltip, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setAlert, setLoading } from "../../../redux/configSlice";
import {
  addDetailData,
  getDetailData,
  uploadEmployerJobSpecData,
} from "../../../redux/employer/postJobSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import Typography from "@mui/material/Typography";
import { POST_JOB_STEPS } from "../../../utils/Constants";
import TextEditor from "./TextEditor";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import scrapeSpecIcon from "../../../assets/Padding Excluded/White_scrapeSpec.svg";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { BlueSwitch, LightTooltip } from "../../../utils/CommonComponent";
import { DETAIL } from "../../../utils/Constants";
import {
  getDecodedToken,
  getToken,
  validateSchema,
} from "../../../utils/Common";
import specIcon from "../../../assets/Padding Excluded/White_scrapeSpec.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/Black_View.svg";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import previousIcon from "../../../assets/Black_Left_Previous.svg";
import SavaAndExit from "./dialog/SavaAndExit";
import { useRef } from "react";
dayjs.locale("en-gb");

export default function TheDetails({
  specName,
  setspecName,
  changeStep,
  handleComplete,
  // handleOpenSaveAndExitDialog,
  isEditPostjob,
  setActiveStep,
  setSelectedPage,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const history = useNavigate();
  const dispatch = useDispatch();
  const { jobId } = useParams();
  let decodedToken = getDecodedToken(getToken());
  const [detailData, setDetailData] = useState({ ...DETAIL, job_id: jobId });
  const [errors, setErrors] = useState([]);
  const [sameJobAndBio, setSameJobAndBio] = useState(true);
  const [openSaveAndExitDialog, setOpenSaveAndExitDialog] = useState(false);
  const detailInfoSchema = Yup.object().shape({
    job_bio: Yup.string().when("checkBio", {
      is: false,
      then: (schema) => schema.required("Job bio is Required."),
      otherwise: (schema) => schema.optional(),
    }),
    role_summary: Yup.string().required("Role summary is Required."),
    role_responsibilty: Yup.string().required(
      "Role responsibility is Required."
    ),
    role_requirements: Yup.string().required("Role requirements is Required."),
    checkBio: Yup.bool(),
  });
  const hiddenFileInput = useRef(null);

  const getAllTheDetails = async () => {
    // const { payload } = await dispatch();
    try {
      dispatch(setLoading(true));
      const { payload } = await dispatch(getDetailData(jobId));
      if (payload?.status === "success") {
        const detail = payload?.data;
        setSameJobAndBio(
          payload?.data?.job_summary_flag !== null
            ? payload?.data?.job_summary_flag
            : true
        );
        setDetailData({ ...detail, job_id: jobId });
        setErrors([]);
      } else if (payload?.status === "error") {
        payload?.message?.length > 0 && setErrors(payload?.message);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleFileClick = async (event) => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const file = event?.target?.files?.[0];
    formData.append("jobspecemployer", file);
    jobId !== undefined && formData.append("job_id", jobId );
    try {
      const { payload } = await dispatch(uploadEmployerJobSpecData(formData));
      if (payload?.status === "success") {
        // setspecName(file?.name);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.SpecuploadedSuccessfully"],
          })
        );
        setspecName(payload?.data?.job_spec);
        !jobId && history(`/employer/post-a-job/${payload?.data?.job_id}`);
        // setOpenSpecBox(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
    // setOpenSpecBox(false);
  };
  const sanitizeHTML = (htmlContent) => {
    const tempElement = document?.createElement("div");
    tempElement.innerHTML = htmlContent;

    const isContentEmpty = tempElement?.textContent?.trim() === "";
    return isContentEmpty ? "" : htmlContent;
  };

  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  const saveDetail = async (btnType) => {
    let { job_bio, role_summary, role_responsibilty, role_requirements } =
      detailData;

    let testErrorState = {
      job_bio: job_bio ? job_bio : "",
      role_summary: sanitizeHTML(role_summary),
      role_responsibilty: sanitizeHTML(role_responsibilty),
      role_requirements: sanitizeHTML(role_requirements),
      checkBio: sameJobAndBio,
    };
    let updateResponse = {
      job_bio: sameJobAndBio ? "" : detailData?.job_bio,
      job_id: jobId,
      job_spec: detailData?.job_spec
        ? detailData?.job_spec
        : specName !== "No file chosen"
        ? specName
        : null,
      job_summary_flag: detailData?.job_summary_flag,
      role_requirements: sanitizeHTML(detailData?.role_requirements),
      role_responsibilty: sanitizeHTML(detailData?.role_responsibilty),
      role_summary: sanitizeHTML(detailData?.role_summary),
      job_notes: sanitizeHTML(detailData?.job_notes),
    };
    setDetailData(updateResponse);
    validateSchema(detailInfoSchema, testErrorState)
      .then(() => {
        try {
          dispatch(addDetailData(updateResponse)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Details Data added successfully!",
                })
              );

              handleComplete(2);

              if (btnType === "saveDetail") {
                changeStep(1);
                // history(
                //   decodedToken?.data?.role_id === 6
                //     ? "/recruiter/my-jobs"
                //     : "/employer/my-jobs"
                // );
                // history("/employer/my-jobs");
                return;
              } else if (btnType === "saveToDraft") {
                setOpenSaveAndExitDialog(false);
                setSelectedPage(3);
                setActiveStep(6);
              } else {
                changeStep(3);
              }
              setErrors([]);
            } else if (payload?.payload?.status === "error") {
              setErrors(payload?.payload?.errors);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            }
          });
        } catch (error) {
          setOpenSaveAndExitDialog(false);
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      })
      .catch((error) => {
        setOpenSaveAndExitDialog(false);
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handleSwitch = (event) => {
    setSameJobAndBio(event?.target?.checked);
    const newDetailData = {
      ...detailData,
      [event?.target?.name]: event?.target?.checked,
    };
    setDetailData(newDetailData);
  };
  const handleInputChange = (inputText, type) => {
    const newDetailData = {
      ...detailData,
      [type]: inputText,
    };
    setErrors(errors?.filter((item) => item?.key !== type));
    setDetailData(newDetailData);
  };
  const handleJobBioChange = (event) => {
    const newDetailData = {
      ...detailData,
      job_bio: event?.target?.value,
    };
    setDetailData(newDetailData);
  };
  const handleOpenSaveAndExitDialog = () => {
    setOpenSaveAndExitDialog((prevState) => !prevState);
  };
  const handleModalSaveAndExit = () => {
    saveDetail("saveToDraft");
  };
  useEffect(() => {
    jobId !== undefined && getAllTheDetails();
  }, [jobId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="checkers"
            sx={{
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px 16px", md24: "6px 16px" },
              borderRadius: "25px 0 10px 0",
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              color: "white",
            }}
          >
            {i18n["postAJob.thedetails"]}
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", tablet: "none" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "6px 16px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            // onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            upload spec
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 10px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            parse spec
          </Button>
        </Box>
        <input
          accept={fileAccept}
          ref={hiddenFileInput}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          required
        />
        <Box sx={{ display: { xs: "none", tablet: "block" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "6px 16px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {i18n["postAJob.uploadjobspec"]}
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            {i18n["postAJob.parsejobspec"]}
          </Button>
          <Typography sx={{ fontSize: "12px" }}>{specName}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "19px",
          p: "0",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            padding: "24px 41px 0 41px",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          {!sameJobAndBio && (
            <Box>
              <InputLabel
                htmlFor="jobBio"
                sx={{
                  color: "black",
                  pb: 1,
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Job bio (this will show on the job card)*
              </InputLabel>
              <TextField
                name="job_bio"
                value={detailData?.job_bio}
                onChange={handleJobBioChange}
                multiline
                rows={2}
                sx={{
                  width: "100%",
                  height: "80px",
                  borderColor: theme.palette.borderColor,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "10px",
                      border: errors?.find((error) => error?.key === "job_bio")
                        ? "1px solid red"
                        : `1px solid ${theme.palette.borderColor}`,
                    },
                    "&:hover fieldset": {
                      border: errors?.find((error) => error?.key === "job_bio")
                        ? "1px solid red"
                        : `1px solid ${theme.palette.borderColor}`,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.borderColor,
                    },
                  },
                }}
              ></TextField>
            </Box>
          )}
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                height: "19px",
                pb: 1,
                alignItems: "center",
              }}
            >
              <InputLabel
                htmlFor="roleSummary"
                sx={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                How they’ll role (job summary)*
              </InputLabel>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                Use this for job bio
              </Typography>
              <Tooltip disableInteractive>
                <LightTooltip
                  title={"Role summary and bio will be same if turned on"}
                  placement="right"
                >
                  <Typography
                    sx={{
                      height: "17px",
                      width: "17px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      lineHeight: 0.1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: theme.typography.Bold,
                      boxShadow: "0px 2px 4px #00000029",
                      border: 1,
                    }}
                  >
                    i
                  </Typography>
                </LightTooltip>
              </Tooltip>
              <BlueSwitch
                name="job_summary_flag"
                checked={sameJobAndBio}
                onChange={(event) => handleSwitch(event)}
              />
            </Box>
            <TextEditor
              minHeight="126px"
              value={detailData?.role_summary}
              borderRadius="10px"
              border={
                // !detailData?.role_summary &&
                errors?.find((error) => error?.key === "role_summary")
                  ? "1px solid red"
                  : `1px solid ${theme.palette.borderColor}`
              }
              type="role_summary"
              onInputCHange={handleInputChange}
            />
          </Box>
          <Box>
            <InputLabel
              htmlFor="roleResponsibility"
              sx={{
                color: "black",
                paddingBottom: "8px",
                fontSize: "14px",
                height: "19px",
                fontWeight: theme.typography.Bold,
              }}
            >
              What they’ll do (role responsibilities)*
            </InputLabel>
            <TextEditor
              minHeight="126px"
              value={detailData?.role_responsibilty}
              borderRadius="10px"
              border={
                // !detailData?.role_responsibilty &&
                errors?.find((error) => error?.key === "role_responsibilty")
                  ? "1px solid red"
                  : `1px solid ${theme.palette.borderColor}`
              }
              type="role_responsibilty"
              onInputCHange={handleInputChange}
            />
          </Box>
          <Box>
            <InputLabel
              htmlFor="roleRequirement"
              sx={{
                color: "black",
                pb: 1,
                height: "19px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              What they’ll need (role requirements)*
            </InputLabel>
            <TextEditor
              minHeight="126px"
              value={detailData?.role_requirements}
              borderRadius="10px"
              border={
                // !detailData?.role_requirements &&
                errors?.find((error) => error?.key === "role_requirements")
                  ? "1px solid red"
                  : `1px solid ${theme.palette.borderColor}`
              }
              type="role_requirements"
              onInputCHange={handleInputChange}
            />
          </Box>
          <Box>
            <InputLabel
              htmlFor="jobNotes"
              sx={{
                color: "black",
                pb: 1,
                height: "19px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              Job Notes{" "}
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                (optional)
              </span>
            </InputLabel>
            <TextEditor
              minHeight="126px"
              value={detailData?.job_notes}
              borderRadius="10px"
              border={`1px solid ${theme.palette.borderColor}`}
              type="job_notes"
              onInputCHange={handleInputChange}
            />
          </Box>
        </Box>

        <Box
          sx={{
            mt: "9px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="tymeColor"
            sx={{
              width: "150px",
              height: "60px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              borderRadius: "25px 0 0 0",
              color: "white",
            }}
            onClick={() => {
              saveDetail("saveDetail");
            }}
            startIcon={
              <Box
                component={"img"}
                src={previousIcon}
                sx={{
                  height: "25px",
                  width: "25px",
                  color: "white",
                }}
              />
            }
          >
            the basics
          </Button>
          <Button
            variant="contained"
            color="saveAndExitButton"
            sx={{
              width: "150px",
              height: "60px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              borderRadius: "0",
            }}
            onClick={handleOpenSaveAndExitDialog}
          >
            save to drafts
          </Button>
          <Button
            variant="contained"
            color="detailButton"
            sx={{
              width: "150px",
              height: "60px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              borderRadius: "0 25px 0 0",
            }}
            endIcon={
              <Box
                component={"img"}
                src={nextIcon}
                sx={{
                  height: "25px",
                  width: "25px",
                }}
              />
            }
            onClick={() => {
              saveDetail("cultureAdd");
            }}
          >
            culture add
          </Button>
        </Box>
      </Box>
      {openSaveAndExitDialog && (
        <SavaAndExit
          show={openSaveAndExitDialog}
          handleOpen={handleOpenSaveAndExitDialog}
          handleModalSaveAndExit={handleModalSaveAndExit}
        />
      )}
    </>
  );
}
