import { useState, useEffect, Fragment, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled, useTheme } from "@mui/material/styles";
import crayonLogo from "../../assets/Crayon_Logo.svg";
import Red_Crayon_Icon from "../../assets/Padding Included/Red_Crayon_Icon.svg";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  PUBLIC_TAB_ITEMS,
  DRAWER_WIDTH,
  AUTHORIZED_TAB_ITEMS_EMPLOYER,
  AUTHORIZED_TAB_ITEMS_CANDIDATE,
  ALERT_TYPE,
  TAB_ITEMS_EMPLOYER,
  TAB_ITEMS_CANDIDATE,
  TAB_ITEMS_ADMIN,
  AUTHORIZED_TAB_ITEMS_RECRUITER,
  TAB_ITEMS_RECRUITER,
  USER_ROLES,
  BASIC_SEARCH,
  AUTHORIZED_TAB_ITEMS_PROMOTER,
  TAB_ITEMS_PROMOTER,
  AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER,
  TAB_ITEMS_CANDIDATE_PROMOTER,
  TAB_ITEMS_RECRUITER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER,
  TAB_ITEMS_EMPLOYER_PROMOTER,
  ERROR_MSG,
  currentTabs1,
} from "../../utils/Constants";
import { useLocation, Link, useNavigate } from "react-router-dom";
import locale from "../../i18n/locale";
import Signup from "../login/signup";
import Login from "../login/login";
import { setAlert, setLoading } from "../../redux/configSlice";
import {
  getDecodedToken,
  getLocalStorage,
  getToken,
  removeCookie,
  useQuery,
} from "../../utils/Common";
import {
  logout,
  resendMail,
  resendMailPost,
  setActiveTab,
  setInitialPopup,
  setIsAdmin,
  topBarCurrentTab,
} from "../../redux/login/loginSlice";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
  Zoom,
} from "@mui/material";
import candidateProfile from "../../assets/Padding Excluded/Black_Man_Happy - Copy.svg";
import candidateLite from "../../assets/Crayon_User_Lite.svg";
import candidateTalent from "../../assets/TopBarIcons/CrayonTalentIcon.svg";
import candidateTalentBlack from "../../assets/TopBarIcons/CrayonTalentIconBlack.svg";
import JobTabIcon from "../../assets/TopBarIcons/crayon_jobs.svg";
import TalentTabIcon from "../../assets/TopBarIcons/crayon_talent.svg";
import WhosHiringTabIcon from "../../assets/TopBarIcons/crayon_whos_hiring.svg";
import Crayon_quickLink from "../../assets/TopBarIcons/Crayon_quickLink.svg";
import SolutionsTabIcon from "../../assets/TopBarIcons/crayon_solutions.svg";
import PricingTabIcon from "../../assets/TopBarIcons/crayon_pricing.svg";
import ContactTabIcon from "../../assets/TopBarIcons/crayon_contact.svg";
import MyCamTabIcon from "../../assets/TopBarIcons/crayon_my_cam.svg";
import MyCvTabIcon from "../../assets/TopBarIcons/crayon_my_CV.svg";
import PostAJobTabIcon from "../../assets/TopBarIcons/crayon_post_jobs.svg";
import MyTeamTabIcon from "../../assets/TopBarIcons/crayon_my_team.svg";
import SharedJobIcon from "../../assets/TopBarIcons/crayon_shared_jobs.svg";
import ReferralsJobIcon from "../../assets/TopBarIcons/crayon_referrals.svg";
import MyJobsIcon from "../../assets/TopBarIcons/crayon_my jobs.svg";
import CrayonLogin from "../../assets/TopBarIcons/crayon_login.svg";
import CrayonJoin from "../../assets/TopBarIcons/crayon_join.svg";
import viewCVIcon from "../../assets/Padding Excluded/Black_Lock.svg";
import UnlockIcon from "../../assets/Padding Excluded/UnlockIcon.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoutIcon from "../../assets/Icons/crayon_logout_icon.svg";

import LockBlackIcon from "../../assets/Padding Excluded/LockBlackIcon.svg";
import notification from "../../assets/TalentMenu/Crayon_alertNew.svg";
import topBarIcon from "../../assets/TalentMenu/Crayon_Talent_Menu_Home.svg";
import upArrow from "../../assets/UpArrow.svg";
import downArrow from "../../assets/DownArrow.svg";
// import upArrowOld from "../../assets/UpArrowOld.svg";
// import downArrowOld from "../../assets/DownArrowOld.svg";
import upArrowOld from "../../assets/Black_Up_Close - Copy.svg";
import downArrowOld from "../../assets/Black_Down_Open - Copy.svg";
import SearchBarBox from "./SearchBarBox";
import AdvanceSection from "./AdvanceSection";
import {
  getFilteredTalent,
  updateAllTalent,
} from "../../redux/guest/talentSlice";
import { getFilteredJobsListing } from "../../redux/employer/empJobListing";
import { getCandidateFilteredJob } from "../../redux/candidate/candidateJobs";
import {
  appliedFlagUpdate,
  getFilteredJobs,
  setCardsPadding,
  setFilterExpanded,
  setLoggedIn,
  updateAllJobs,
} from "../../redux/guest/jobsSlice";
import {
  companyLogoClicked,
  setCurrentStep,
  setCurrentStepPromoter,
  setFooterCompanyData,
} from "../../redux/candidate/myProfileSlice";
import { setProfileSteps } from "../../redux/employer/empProfileSlice";
import { getHiringList, updateAllCompany } from "../../redux/guest/whoshiring";
// import promoterlogo from "../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import promoterlogo from "../../assets/HomePage/crayon_earn_cash.svg";
import recruit from "../../assets/HomePage/crayon_recruit.svg";
import employer from "../../assets/HomePage/crayon_hire_talent.svg";

import useViewport from "./ViewPort";
import ResendMailBox from "../login/ResendMailBox";
import Alerts from "../candidate/alert/Alerts";
import {
  getNotificationCount,
  setCoinsCount,
  setNotificationCount,
  setOpenCoinBox,
} from "../../redux/candidate/alerts";
import Settings from "../candidate/alert/Settings";
import SpoilerAlert from "../candidate/alert/SpoilerAlert";
import { clearClientPool, clearTalentPool } from "../../redux/admin/jobsSlice";
import TopBarMenuList from "./TopBarMenuList";
import { setCVName } from "../../redux/candidate/myCvSlice";
import coinIcon from "../../assets/Crayon Talent/Crayon Coin.svg";
import RedInfoIcon from "../../assets/Crayon Talent/RedInfoIcon.svg";
import VerificationBot from "../../assets/Crayon Talent/VerificationBot.svg";
import CrayonCoins from "./CrayonCoins";
import GetCoins from "./GetCoins";
import MobileSearchBarBox from "./MobileSearchBarBox";
import CoinSpendBox from "./CoinSpendBox";
import EmployerLeftSideBox from "./employerTopBoxes/EmployerLeftSideBox";
import EmployerPercentageBox from "./employerTopBoxes/EmployerPercentageBox";
import CandidateLeftSideBox from "./candidateTopBoxes/CandidateLeftSideBox";
import CandidatePercentageBox from "./candidateTopBoxes/CandidatePercentageBox";
import PreLoginLeftSideBox from "./preLoginPagesBox/PreLoginLeftSideBox";
import InitialApprovalModal from "./InitialApprovalModal";
import CloseIcon from "./CloseIcon";
import ForgotPasswordConfirmationModal from "../login/ForgotPasswordConfirmationModal";

const StyledTab = styled(Tabs)(({ theme }) => ({
  display: "flex !important",
  alignItems: "center !important",
  "& .MuiTab-root": {
    textTransform: "none",
    color: theme.palette.black,
    fontWeight: 700,
    opacity: 1,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  "& .MuiTab-iconWrapper": {
    marginBottom: "0px !important",
  },
  "& .Mui-selected": {},
}));

export default function TopBar({ handleCandidateDynamicTheme }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  let { pathname } = useLocation();

  const navigate = useNavigate();
  const params = useLocation();
  const query = useQuery();
  const boxRef = useRef(null);
  const openInfoRef = useRef(null);
  const loggedin = useSelector((state) => state?.configjobslice?.loggedin);
  const [openInitialApporovalModal, setOpenInitialApporovalModal] =
    useState(false);
  const cardsPadding = useSelector(
    (state) => state?.configjobslice?.cardsPadding
  );
  const { notificationCount, coinsCount, openCoinBox } = useSelector(
    (state) => state?.configalert
  );
  const { initialPopup, currentTabs, isAdmin, activeTab } = useSelector(
    (state) => state?.login
  );
  const topBarCompanyLogoClicked = useSelector(
    (state) => state?.myProfileSli?.topBarCompanyLogoClicked
  );
  const [confirm, setConfirm] = useState(false);
  // const countRef = useRef(0);
  const currentStep = useSelector((state) => state?.myProfileSli?.currentStep);
  const footerCompanyData = useSelector(
    (state) => state?.myProfileSli?.footerCompanyData
  );
  const cvSteps = useSelector((state) => state?.myProfileSli?.cvSteps);
  const profileSteps = useSelector((state) => state?.myProfile?.profileSteps);
  const [searchText, setSearchText] = useState(
    query.get("query") !== null ? query.get("query") : ""
  );
  const { width } = useViewport();

  let decodedToken = getDecodedToken(getToken());
  const [isForgotPassword, setIsForgotPassowrd] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [company_id, setCompany_id] = useState(null);

  const showCoin = decodedToken?.data?.usersRoleTypeId;
  const userType = parseInt(decodedToken?.data?.role_id);
  const planType = decodedToken?.data?.talent_plan;
  const isPromoter = decodedToken?.data?.promoter;
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("token"))
  );

  const [intervalID, setIntervalID] = useState(null);
  const [locationID, setLocationID] = useState("");
  const [basicData, setBasicData] = useState({
    job_title: [],
    region_id: [],
    tag_id: [],
    town_id: [],
    tool_id: [],
    salary: [0, 100000],
    experience: [],
    company_id: [],
    currency_id: [{ label: "ZAR", value: "1" }],
    highest_qualification_id: [],
  });
  const [showBox, setShowBox] = useState(false);
  const [lastKey, setLastKey] = useState("");
  const [allJobs, setAllJobs] = useState([]);
  const [searchBox, setSearchBox] = useState(false);

  const [resendMailBox, setResendMailBox] = useState(false);
  const [email, setEmail] = useState("");
  const [showArrow, setShowArrow] = useState(false);
  const [openAdvanceSearch, setOpenAdvanceSearch] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [isAdmin, setIsAdmin] = useState(false);
  // const [currentTabs, setcurrentTabs] = useState(PUBLIC_TAB_ITEMS);
  // const [activeTab, setActiveTab] = useState();
  // const [activeTab, setActiveTab] = useState(pathname?.slice(1));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [publicTabs, setPublicTabs] = useState(PUBLIC_TAB_ITEMS);
  const [hoveredTab, setHoveredTab] = useState("");
  const [openNotification, setOpenNotication] = useState(false);
  const quickLinksButtonRef = useRef(null);
  const candidateButtonRef = useRef(null);
  const promoterButtonRef = useRef(null);

  const [openCoins, setOpenCoins] = useState(null);
  const [openGetCoins, setOpenGetCoins] = useState(null);
  const coinsRef = useRef(null);
  const openCoinsBox = Boolean(openCoins);
  const [anchorElTopBar, setAnchorElTopBar] = useState(null);
  const openTopBar = Boolean(anchorElTopBar);
  const topBarRef = useRef(null);
  const [buttonClick, setButtonClick] = useState(false);
  const [rightBoxY, setRightBoxY] = useState((window.innerWidth * 400) / 1920);
  const [employerRightBoxY, setEmployerRightBoxY] = useState(
    (window.innerWidth * 456) / 1920
  );
  const [openInfo, setOpenInfo] = useState(false);
  const anchorElInfo = Boolean(openInfo);
  const sign = useSelector((state) => state?.sign);
  const alertRef = useRef();
  const settingRef = useRef();
  const spoilerRef = useRef();
  const [openSetting, setOpenSetting] = useState(false);
  const [openSpoiler, setOpenSpoiler] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState();
  const [candidatePageHeading, setCandidatePageHeading] = useState(false);

  let firstTypographyText = "";
  let secondTypographyText = "";

  switch (true) {
    case params.pathname.includes("/candidate/my-jobs"):
      firstTypographyText = "";
      secondTypographyText =
        "You had one job (application). Now you have two, then three, and then four. How do you keep track of them all? This page!";
      break;
    case params.pathname.includes("/candidate/my-cv"):
      switch (cvSteps) {
        case 0:
          // firstTypographyText = "Crayon vitae - the basics";
          secondTypographyText =
            "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";
          break;
        case 1:
          // firstTypographyText = "Crayon vitae - work life";
          secondTypographyText =
            "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";
          break;
        case 2:
          // firstTypographyText = "Crayon vitae - study life";
          secondTypographyText =
            "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";
          break;
        case 3:
          // firstTypographyText = "Crayon vitae - references";
          secondTypographyText =
            "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";
          break;
        case 4:
          // firstTypographyText = "Crayon vitae - view my CV";
          secondTypographyText =
            "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";
          break;
        default:
          // firstTypographyText = "Crayon vitae - the basics";
          secondTypographyText =
            "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";
          break;
      }
      secondTypographyText =
        "The more you complete, the stronger you can compete! We use this info to match you to your dream job and to highlight your profile to potential employers, so make it count!";

      break;
    case params?.pathname?.includes("/candidate/my-cam"):
      firstTypographyText = "";
      secondTypographyText =
        "Lights, camera, action! This is where you get to showcase your personality and what makes you tick. The market has shifted, and we’re providing the tools to ‘sell’ yourself and let employers know why you’re the right guy or gal for the job!";
      break;
    case params?.pathname?.includes("/candidate/my-profile"):
      firstTypographyText = "";
      secondTypographyText =
        "Take me home, country road! This is where the magic begins and you get to build and manage your profile.";
      break;
    case params?.pathname?.includes("/candidate/my-referrals"):
      firstTypographyText = "";
      secondTypographyText =
        "These are your money makers! The talent below followed you to Crayon and are yours on the platform for life. If they get hired, you get the Bounty on offer for that job!";
      break;
    case params?.pathname?.includes("/candidate/shared-jobs"):
      firstTypographyText = "";
      secondTypographyText =
        "These are the lines you have in the water. A history of the jobs you’ve shared allows you to track who has applied and/or joined Crayon as a result of your efforts.";
      break;
    case params?.pathname?.includes("/promoter/my-profile"):
      firstTypographyText = "";
      secondTypographyText =
        "Take me home, country road! This is where the magic begins and you get to build and manage your profile.";
      break;
    case params?.pathname?.includes("/candidate/assessment"):
      firstTypographyText = "";
      secondTypographyText =
        "It’s quiz time! Grab a coffee, and settle in. Answer the questions as you see yourself in the work environment, not out on the jol at 2am. CrayBot will run the results and update your profile with your personalities (yes, there are multiple), traits and Grit Score.";
      break;

    default:
      break;
  }

  switch (true) {
    case params?.pathname?.includes("/employer/my-jobs") ||
      params.pathname?.includes("/recruiter/my-jobs"):
      // firstTypographyText = i18n["empMyJobs.hirelikeaboss"];
      secondTypographyText =
        "Hire like a boss! This is where you get to manage and track the status of your posted jobs and unlock a world of talent!";
      break;
    case params?.pathname?.includes("/employer/post-a-job") ||
      params?.pathname?.includes("/recruiter/post-a-job"):
      // firstTypographyText = i18n["postAJob.title"];
      secondTypographyText =
        "Design your next employee! Take control and go it alone via Crayon Direct, or save hours and outsource via Crayon Recruit. Job posts are unfree; to unlock a job will cost you some coins.";
      break;
    case params?.pathname?.includes("/employer/my-team") ||
      params?.pathname?.includes("/recruiter/my-team"):
      // firstTypographyText = i18n["empMyJobs.hirelikeaboss"];
      secondTypographyText =
        "…makes the dream work! (Someone fire the copywriter please.) This is where you get to add to and manage your team, user access rights and approve/reject requests to partner with your company. Super Admin and Admin are all-seeing and can do a lot, so allocate wisely!";
      break;
    case params?.pathname?.includes("/employer/my-cam") ||
      params?.pathname?.includes("/recruiter/my-cam"):
      // firstTypographyText = i18n["empMyCam.leftBoxText"];
      secondTypographyText =
        "Lights, camera, action! This is where you get to showcase your biz, company culture, office parties, and info per job. The market has shifted, and we’re providing the tools to ‘sell’ your company and job to the right talent!";
      break;
    case params?.pathname?.includes("/employer/shared-jobs") ||
      params?.pathname?.includes("/recruiter/shared-jobs"):
      // firstTypographyText = "take me home, country road";
      secondTypographyText =
        "These are the lines you have in the water. A history of the jobs you’ve shared allows you to track who has applied and/or joined Crayon as a result of your efforts.";
      break;
    case params?.pathname?.includes("/employer/my-referrals") ||
      params?.pathname?.includes("/recruiter/my-referrals"):
      // firstTypographyText = "take me home, country road";
      secondTypographyText =
        "These are your money makers! The talent below followed you to Crayon and are yours on the platform for life. If they get hired, you get the Bounty on offer for that job!";
      break;
    case params?.pathname?.includes("/employer/manage-talent/") ||
      params?.pathname?.includes("/recruiter/manage-talent/"):
      secondTypographyText =
        "Drag ’n drop it like it’s hot! This is your chance to be Tom Cruise in Minority Report. We prefer can-do over Kanban; it’s like Monday.com, but for all days of the week.";
      break;

    case params?.pathname?.includes("/employer/my-profile") ||
      params?.pathname?.includes("/recruiter/my-profile"):
      switch (profileSteps) {
        case 0:
          secondTypographyText =
            "Take me home, country road! This is where the magic begins and you get to build and manage your profile.";

          break;
        case 1:
          secondTypographyText =
            "Take me home, country road! This is where the magic begins and you get to build and manage your profile.";

          break;
        case 2:
          secondTypographyText =
            "Either additional group companies or your clients, these are other businesses that you can post jobs on behalf of. “With great power, comes great responsibility.” ~ Spider-Man";
          break;
        case 3:
          secondTypographyText =
            "Either additional group companies or your clients, these are other businesses that you can post jobs on behalf of. “With great power, comes great responsibility.” ~ Spider-Man";

          break;
        case 4:
          secondTypographyText =
            "Let’s make a plan! You’ll start on Crayon Lite but the real magic of the platform lives behind door number 2, Crayon Talent!";
          break;
        case 5:
          secondTypographyText =
            "We added this page because the accountants wanted it. Sorry. Please add your deets and a card or two. Finance will be happy.";
          break;
        default:
          secondTypographyText =
            "Take me home, country road! This is where the magic begins and you get to build and manage your profile.";
          break;
      }
      break;
    default:
      // firstTypographyText = i18n["empMyJobs.hirelikeaboss"];
      // secondTypographyText = i18n["empMyJobs.hirelikeaboss2"];
      break;
  }

  switch (true) {
    case params.pathname.includes("/jobs"):
      firstTypographyText = "";
      secondTypographyText =
        "This is our jobs bored. So when you’re bored, you look for jobs. You can just keep scrolling and scrolling, or take a targeted approach via the search function.";
      break;
    case params.pathname.includes("/talent"):
      firstTypographyText = "";
      secondTypographyText =
        "Crayon’s got talent! Here we give you a teaser of who’s out there and what you can expect to see in your job pipelines. View full profiles and unlocked videos with Crayon Talent.";
      break;
    case params?.pathname?.includes("/whoshiring"):
      firstTypographyText = "";
      secondTypographyText =
        "Recognise a few names? Well it’s the place to be for startups, scale-ups and SMEs; loved too by innovative corporates. Find your dream next biz and check out the jobs they currently have on offer.";
      break;
    case params?.pathname?.includes("/solutions"):
      firstTypographyText = "";
      secondTypographyText =
        "We’re all about solving industry challenges. Here you’ll find a couple of our solutions and features of the Crayon platform.";
      break;
    case params?.pathname?.includes("/contact"):
      firstTypographyText = "";
      secondTypographyText =
        "Make contact, ET. No, we don’t mean add us to your Rolodex. Reach out, let’s have a chat. We’ll do our best to address your query, complaint or suggestion as soon as possible (which might not always be that soon… like just now, maybe now now).";
      break;
    case params?.pathname === "/":
      firstTypographyText = "";
      secondTypographyText =
        "Hello, hi, howzit! Welcome to Crayon! Pick your fighter by selecting one of the four platform activities or browse the available companies out there looking for talent!";
      break;
    case params?.pathname?.includes("/payment"):
      firstTypographyText = "";
      secondTypographyText = "";
      break;
    case params?.pathname?.includes("/assessment"):
      firstTypographyText = "";
      secondTypographyText =
        "It’s quiz time! Grab a coffee, and settle in. Answer the questions as you see yourself in the work environment, not out on the jol at 2am. CrayBot will run the results and update your profile with your personalities (yes, there are multiple), traits and Grit Score.";
      break;
    case params?.pathname?.includes("/pricing"):
      firstTypographyText = "";
      secondTypographyText =
        "You can get started and post a job for free! To start unlocking talent, it will cost a couple of coins. Here we aim to detail what is what when it comes to pricing and the differences between Crayon Lite vs. Crayon Talent, and Crayon Direct vs. Crayon Recruit. Candidates and Promoters, you play for free.";
      break;
    case params?.pathname?.includes("/forbidden"):
      firstTypographyText = "";
      secondTypographyText = "";
      break;
    case params?.pathname?.includes("/pageNotFound"):
      firstTypographyText = "";
      secondTypographyText = "";
      break;
    case params?.pathname?.includes("/termsOfService"):
      firstTypographyText = "";
      secondTypographyText = "";
      break;
    case params?.pathname?.includes("/privacyPolicy"):
      firstTypographyText = "";
      secondTypographyText = "";
      break;
    default:
      break;
  }

  const handleConfirmButtonClick = () => {
    setConfirm(false);
  };

  const getNewWidth = () => {
    setRightBoxY((window.innerWidth * 400) / 1920);
    setEmployerRightBoxY((window.innerWidth * 456) / 1920);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  const handleShowSearchBtn = () => {
    setShowSearchBar(!showSearchBar);
  };

  const handleShowSearchBar = () => {
    setShowSearchBar(true);
    setOpenAdvanceSearch(!openAdvanceSearch);
  };

  const handleJobSearch = async (searchFor, newValueId) => {
    dispatch(setLoading({ value: true, apiName: "getTalent" }));
    dispatch(updateAllJobs());
    dispatch(updateAllTalent());
    dispatch(updateAllCompany());
    let data = {};
    let updatedBasicData = {
      job_title: basicData?.job_title?.map((item) => item?.label),
      region_id: basicData?.region_id?.map((item) => item?.value),
      tag_id: basicData?.tag_id?.map((item) => item?.value),
      town_id: basicData?.town_id?.map((item) => item?.value),
      tool_id: basicData?.tool_id?.map((item) => item?.value),
      salary: basicData?.salary,
      experience: basicData?.experience,
      company_id: basicData?.company_id?.map((item) =>
        window?.location?.pathname
          ?.split("/")
          ?.filter((part) => part !== "")?.[0] === "talent"
          ? item?.label
          : item?.value
      ),
      currency_id: basicData?.currency_id?.map((item) => item?.value),
      highest_qualification_id: basicData?.highest_qualification_id?.map(
        (item) => item?.value
      ),
    };
    if (searchFor === "talent") {
      updatedBasicData["talent_title"] = basicData?.job_title?.map(
        (item) => item?.label
      );
      delete updatedBasicData["job_title"];
    } else {
      updatedBasicData["job_title"] = basicData?.job_title?.map(
        (item) => item?.label
      );
      delete updatedBasicData["talent_title"];
    }
    searchFor === "talent"
      ? (data = {
          selectedFilters: "",
          nextPageId: "",
          personalityType: "",
          jobtype: "",
          ...updatedBasicData,
          talent_title: updatedBasicData?.talent_title,
          query: searchText,
        })
      : searchFor === "whoshiring"
      ? (data = {
          query: searchText,
          ...updatedBasicData,
        })
      : params.pathname.includes("/employer/my-jobs")
      ? (data = {
          selectedFilters: "",
          selectedStatusFilter: "",
          lastKey: "",
          ...updatedBasicData,
          job_title: updatedBasicData?.job_title,
          query: searchText,
        })
      : params.pathname.includes("/candidate/my-jobs")
      ? (data = {
          filterMyStatus: "",
          filterJobStatus: "",
          lastKey: "",
          ...updatedBasicData,
          job_title: updatedBasicData?.job_title,
          query: searchText,
        })
      : (data = {
          selectedFilters: "",
          lastKey: "",
          jobtype: "",
          jobstage: "",
          personalityType: "",
          favourites: "",
          recentjob: "",
          appliedjob: "",
          ...updatedBasicData,
          job_title: updatedBasicData?.job_title,
          query: searchText,
          town_id: locationID
            ? locationID
            : newValueId
            ? newValueId
            : updatedBasicData?.town_id,
        });
    const { payload } = await dispatch(
      searchFor === "talent"
        ? getFilteredTalent(data)
        : searchFor === "whoshiring"
        ? getHiringList(data)
        : params?.pathname.includes("/employer/my-jobs")
        ? getFilteredJobsListing(data)
        : params?.pathname.includes("/candidate/my-jobs")
        ? getCandidateFilteredJob(data)
        : getFilteredJobs(data)
    );
    if (payload?.status === "success") {
      if (payload?.data?.length <= 0) {
        setShowBox(true);
      }
      if (
        !params?.pathname?.includes("/employer/my-jobs") ||
        !params?.pathname?.includes("/candidate/my-jobs")
      ) {
        navigate(
          `/${
            searchFor === "talent"
              ? "talent"
              : searchFor === "whoshiring"
              ? "whoshiring"
              : "jobs"
          }?query=${searchText ? searchText : ""}&job_title=${
            updatedBasicData?.job_title
              ? updatedBasicData?.job_title
              : updatedBasicData?.talent_title
          }&location=${
            locationID
              ? locationID
              : newValueId
              ? newValueId
              : updatedBasicData?.town_id
          }&region_id=${updatedBasicData?.region_id}&tag_id=${
            updatedBasicData?.tag_id
          }&tool_id=${updatedBasicData?.tool_id}&company_id=${
            updatedBasicData?.company_id
          }&currency_id=${
            updatedBasicData?.currency_id
          }&highest_qualification_id=${
            updatedBasicData?.highest_qualification_id
          }&salary=${updatedBasicData?.salary}&experience=${
            updatedBasicData?.experience
          }
            `
        );
      }
      setLastKey(payload?.pagenumber + 1 || payload?.pageNumber + 1);
      setAllJobs([]);
      setAllJobs((prevState) => [...prevState, ...payload?.data]);
      setOpenAdvanceSearch(false);
      setBasicData(basicData);
      dispatch(setLoading({ value: false, apiName: "getTalent" }));
    } else {
      dispatch(setLoading({ value: false, apiName: "getTalent" }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const resendVerificationMail = async () => {
    const load = {
      type: "signup",
    };
    try {
      const { payload } = await dispatch(resendMailPost(load));
      if (payload?.status === "success") {
        handleInfoClose();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.emailsent"],
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const onHandleClose = () => {
    setOpenLoginDialog(false);
    setShowSignup(false);
  };

  useEffect(() => {
    if (isLoggedIn) {
      removeCookie("favoriteJobs");
      removeCookie("favoriteTalent");
      removeCookie("favoriteCompany");
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (pathname.slice(1) != activeTab) {
      dispatch(setActiveTab(pathname.slice(1)));
      // setActiveTab(pathname.slice(1));
    }
    if (decodedToken) {
      dispatch(setNotificationCount(notificationCount));
      dispatch(setCoinsCount(coinsCount));
      getCount();
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(setFilterExpanded(width > 900));
    dispatch(setCardsPadding(30));
  }, [width, pathname]);

  useEffect(() => {
    setShowSearchBar(false);
    setOpenAdvanceSearch(false);
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
    dispatch(setIsAdmin(false));
    const adminRoles = [
      USER_ROLES?.at(0)?.role_id,
      USER_ROLES?.at(1)?.role_id,
      USER_ROLES?.at(6)?.role_id,
      USER_ROLES?.at(3)?.role_id,
      USER_ROLES?.at(4)?.role_id,
    ];
    const isAdminRole = adminRoles.includes(userType);

    if (isAdminRole) {
      if (pathname.slice(1).includes("admin")) {
        dispatch(setIsAdmin(true));
        dispatch(topBarCurrentTab([]));
      } else if (
        userType === USER_ROLES?.at(4)?.role_id &&
        pathname.slice(1).includes("recruiter")
      ) {
        setIsLoggedIn(true);
        dispatch(
          topBarCurrentTab(
            isPromoter
              ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
              : AUTHORIZED_TAB_ITEMS_RECRUITER
          )
        );
      } else if (
        userType === USER_ROLES?.at(3)?.role_id &&
        pathname.slice(1).includes("employer")
      ) {
        setIsLoggedIn(true);
        dispatch(
          topBarCurrentTab(
            isPromoter
              ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
              : AUTHORIZED_TAB_ITEMS_EMPLOYER
          )
        );
      } else if (
        [USER_ROLES?.at(0)?.role_id, USER_ROLES?.at(1)?.role_id].includes(
          userType
        ) &&
        pathname?.includes("manage-talent")
      ) {
        dispatch(setIsAdmin(true));
        dispatch(topBarCurrentTab([]));
      } else {
        dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));
      }
    } else if (userType === USER_ROLES?.at(2)?.role_id) {
      if (pathname.slice(1).includes("candidate")) {
        setIsLoggedIn(true);
        dispatch(
          topBarCurrentTab(
            isPromoter
              ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
              : AUTHORIZED_TAB_ITEMS_CANDIDATE
          )
        );
      } else {
        dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));
      }
    } else if (userType === USER_ROLES?.at(5)?.role_id) {
      if (pathname.slice(1).includes("promoter")) {
        setIsLoggedIn(true);
        dispatch(topBarCurrentTab(AUTHORIZED_TAB_ITEMS_PROMOTER));
      } else {
        dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));
      }
    } else {
      dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));
    }
  }, [activeTab, userType, isLoggedIn, loggedin, pathname]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
    dispatch(setActiveTab(newTab));

    if (
      newTab === "employer/quick_links" ||
      newTab === "candidate/quick_links"
    ) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleTabClick = (event, newTab) => {
    setAnchorEl(quickLinksButtonRef?.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleInfoClose = () => {
    setOpenInfo(null);
  };

  const handleClick = () => {
    setAnchorElTopBar(topBarRef?.current);
  };

  const handlePopoverCloseTopBar = (label) => {
    setAnchorElTopBar(null);
    if (label === "my crayon") {
      currentStep === 2
        ? dispatch(setCurrentStep(2))
        : dispatch(setCurrentStep(1));
    } else if (label === "logout") {
      handleLogout();
      // return;
    }
    if (width < 889) {
      handleDrawerToggle();
    }
  };

  const signUpHandle = () => {
    const role_id = decodedToken?.data?.role_id;
    if (Boolean(getLocalStorage("token")) && localStorage.getItem("temp")) {
      let tabs;
      if (role_id === 4) {
        tabs = AUTHORIZED_TAB_ITEMS_EMPLOYER;
        navigate("employer/my-profile", { replace: true });
        // setActiveTab("employer/my-profile");
        dispatch(setActiveTab("employer/my-profile"));

        dispatch(setProfileSteps(1));
      } else if (role_id === 5) {
        tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
        navigate("promoter/my-profile", { replace: true });
        dispatch(setActiveTab("promoter/my-profile"));

        // setActiveTab("promoter/my-profile");
        dispatch(setCurrentStepPromoter(true));
      } else if (role_id === 6) {
        tabs = AUTHORIZED_TAB_ITEMS_RECRUITER;
        navigate("recruiter/my-profile", { replace: true });
        dispatch(setActiveTab("recruiter/my-profile"));

        // setActiveTab("recruiter/my-profile");
        dispatch(setProfileSteps(1));
      } else {
        tabs = AUTHORIZED_TAB_ITEMS_CANDIDATE;
        navigate("candidate/my-profile", { replace: true });
        dispatch(setActiveTab("candidate/my-profile"));

        // setActiveTab("candidate/my-profile");
        dispatch(setCurrentStep(2));
      }
      dispatch(topBarCurrentTab(tabs));

      // setcurrentTabs(tabs);
      setIsLoggedIn(true);
      localStorage.removeItem("temp");
    }
  };
  useEffect(() => {
    signUpHandle();
  }, [sign]);

  const onHandleLogin = async (payload) => {
    setShowLogin(false);
    const token = payload?.token;
    const decodedToken = getDecodedToken(token);
    const user = parseInt(decodedToken?.data?.role_id);
    const isPromoter = parseInt(decodedToken?.data?.promoter);
    const profileCompletion = decodedToken?.data?.profile_percent_complete;
    const isNavigate = decodedToken?.data?.employerProfileFlag;

    localStorage.setItem("token", token);
    onHandleClose();

    let tabs;
    if ([1, 2, 8].includes(user)) {
      dispatch(setIsAdmin(true));
      navigate("admin/adminJobs/active-jobs", { replace: true });
    } else if (user === 4) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_EMPLOYER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/employer/my-jobs", { replace: true });
        dispatch(setActiveTab("/employer/my-jobs"));
      } else {
        navigate("/employer/my-profile", { replace: true });
        dispatch(setActiveTab("/employer/my-profile"));
      }
    } else if (user === 5) {
      tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
      navigate("/promoter/shared-jobs", { replace: true });
      dispatch(setActiveTab("/promoter/shared-jobs"));
    } else if (user === 6) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_RECRUITER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/recruiter/my-jobs", { replace: true });
        dispatch(setActiveTab("/recruiter/my-jobs"));
      } else {
        navigate("/recruiter/my-profile", { replace: true });
        dispatch(setActiveTab("/recruiter/my-profile"));
      }
    } else {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
        : AUTHORIZED_TAB_ITEMS_CANDIDATE;
      navigate("/candidate/my-jobs", { replace: true });
      dispatch(setActiveTab("/candidate/my-jobs"));
    }

    dispatch(topBarCurrentTab(tabs));
    setIsLoggedIn(true);
  };

  const handlequicklinks = () => {
    dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));
    // setcurrentTabs(PUBLIC_TAB_ITEMS);
  };

  const handleLogout = async () => {
    try {
      const { payload } = await dispatch(logout());
      if (payload?.status === "success") {
        if (intervalID) {
          clearInterval(intervalID);
        }
        navigate("/", { replace: true });
        localStorage.removeItem("token");
        setSelectedLabel();
        dispatch(clearClientPool());
        dispatch(clearTalentPool());
        dispatch(setProfileSteps(0));
        dispatch(setLoggedIn(false));
        dispatch(setCVName("No file chosen"));
        dispatch(appliedFlagUpdate({ jobId: "", applidflag: false }));
        removeCookie("favoriteJobs");
        setIsLoggedIn(false);
        dispatch(setIsAdmin(false));
        dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));
        // setIsAdmin(false);
        // navigate("jobs", { replace: true });
        // setcurrentTabs(PUBLIC_TAB_ITEMS);
        dispatch(setActiveTab(""));
        // setActiveTab("jobs");
      }
    } catch (error) {}
  };

  const handleHomeLogoClick = () => {
    dispatch(setIsAdmin(false));

    // setIsAdmin(false);
    navigate("/", { replace: true });
    dispatch(topBarCurrentTab(PUBLIC_TAB_ITEMS));

    // setcurrentTabs(PUBLIC_TAB_ITEMS);
    // setActiveTab("");
    dispatch(setActiveTab(""));

    dispatch(companyLogoClicked(!topBarCompanyLogoClicked));
  };

  const handleMyCrayon = () => {
    dispatch(setIsAdmin(false));

    // setIsAdmin(false);
    if (userType === USER_ROLES?.at(3)?.role_id) {
      navigate("employer/my-profile", { replace: true });
      dispatch(
        topBarCurrentTab(
          isPromoter
            ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
            : AUTHORIZED_TAB_ITEMS_EMPLOYER
        )
      );

      // setcurrentTabs(
      //   isPromoter
      //     ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
      //     : AUTHORIZED_TAB_ITEMS_EMPLOYER
      // );
      // setActiveTab("employer/my-profile");
      dispatch(setActiveTab("employer/my-profile"));
    } else if (
      userType === USER_ROLES?.at(0)?.role_id ||
      userType === USER_ROLES?.at(1)?.role_id ||
      userType === USER_ROLES?.at(6)?.role_id
    ) {
      navigate("admin/adminJobs/active-jobs", { replace: true });
    } else if (userType === USER_ROLES?.at(4)?.role_id) {
      navigate("recruiter/my-profile", { replace: true });
      dispatch(
        topBarCurrentTab(
          isPromoter
            ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
            : AUTHORIZED_TAB_ITEMS_RECRUITER
        )
      );
      // setcurrentTabs(
      //   isPromoter
      //     ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
      //     : AUTHORIZED_TAB_ITEMS_RECRUITER
      // );
      // setActiveTab("recruiter/my-profile");
      dispatch(setActiveTab("recruiter/my-profile"));
    } else if (userType === USER_ROLES?.at(5)?.role_id) {
      navigate("promoter/my-profile", { replace: true });
      dispatch(topBarCurrentTab(AUTHORIZED_TAB_ITEMS_PROMOTER));
      // setcurrentTabs(AUTHORIZED_TAB_ITEMS_PROMOTER);
      // setActiveTab("promoter/my-profile");
      dispatch(setActiveTab("promoter/my-profile"));
    } else {
      currentStep === 2
        ? dispatch(setCurrentStep(2))
        : dispatch(setCurrentStep(1));
      navigate("candidate/my-profile", { replace: true });
      dispatch(
        topBarCurrentTab(
          isPromoter
            ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
            : AUTHORIZED_TAB_ITEMS_CANDIDATE
        )
      );

      // setcurrentTabs(
      //   isPromoter
      //     ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
      //     : AUTHORIZED_TAB_ITEMS_CANDIDATE
      // );
      // setActiveTab("candidate/my-profile");
      dispatch(setActiveTab("candidate/my-profile"));
    }
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };
  const handleCloseSignup = () => {
    setShowSignup(false);
  };
  const toggleForm = () => {
    setShowLogin((prevState) => !prevState);
    setShowSignup((prevState) => !prevState);
  };

  const closeForgotPassword = () => {
    setShowSignup(true);
  };

  const getTabIcon = (selectedTab) => {
    switch (selectedTab) {
      case "jobs":
        return JobTabIcon;
      case "talent":
        return TalentTabIcon;
      case "whoshiring":
        return WhosHiringTabIcon;
      case "who's-hiring":
        return WhosHiringTabIcon;
      case "solutions":
        return SolutionsTabIcon;
      case "pricing":
        return PricingTabIcon;
      case "contact":
        return ContactTabIcon;
      case "my-cam":
        return MyCamTabIcon;
      case "my-cv":
        return MyCvTabIcon;
      case "post-a-job":
        return PostAJobTabIcon;
      case "my-team":
        return MyTeamTabIcon;
      case "shared-jobs":
        return SharedJobIcon;
      case "my-referrals":
        return ReferralsJobIcon;
      case "my-jobs":
        return MyJobsIcon;
      case "qucik-links":
        return Crayon_quickLink;
      case "support":
        return ContactTabIcon;
      case "get-coins":
        return coinIcon;
      case "logout":
        return logoutIcon;
      default:
        return JobTabIcon;
    }
  };

  const handleNavigate = (path) => {
    navigate(path);
    handleDrawerToggle();
  };

  const newDrawer = (
    <>
      {!isLoggedIn ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderRadius: "20px",
            backgroundColor: "#f2f4fb",
            padding: `${width < 900 ? "5px,0px" : "10px 0px"}`,
          }}
        >
          <Button
            variant="contained"
            sx={{
              boxShadow: "none !important",
              width: "200px",
              borderRadius: "20px 20px 0 0",
              height: "60px",
              display: "flex",
              flexDirection: "row",
              fontSize: "12px",
            }}
            color="redButton100"
            onClick={() => {
              setShowSignup(true);
              handleDrawerToggle();
            }}
          >
            <Box
              component="img"
              sx={{
                height: "25px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              alt="crayon logo"
              src={CrayonJoin}
            />
            {i18n["topBar.join"]}
          </Button>
          <Button
            variant="contained"
            sx={{
              boxShadow: "0px 5px 10px #00000029",
              width: "200px",
              height: "45px",
              borderRadius: "0 0 10px 10px",
              position: "static",
              flexDirection: "row",
              fontSize: {
                sx: "12px",
              },
              mb: 1,
            }}
            color="grayButton200"
            onClick={() => {
              handleDrawerToggle();
              setShowLogin(true);
            }}
          >
            <Box
              component="img"
              sx={{
                height: "25px",
                cursor: "pointer",
                marginRight: "10px",
              }}
              alt="crayon logo"
              src={CrayonLogin}
            />
            {i18n["topBar.login"]}
          </Button>
          {!decodedToken &&
            currentTabs1?.map(({ label, path, img }) => {
              return (
                <Tab
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                    minHeight: "45px",
                    padding: "3px 7px 5px 8px!important",
                    textTransform: "none",
                    color: "black",
                    fontSize: "14px",
                    fontWeight: 700,
                    opacity: 1,
                    borderRadius: "0 0 10px 10px",
                  }}
                  key={path}
                  value={path}
                  to={path}
                  component={Link}
                  onClick={() => {
                    handleNavigate(path);
                    setSelectedLabel(label);
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        // flexDirection: "row-reverse",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={getTabIcon(
                          label.split(" ").join("-").toLowerCase()
                        )}
                        sx={{
                          height: "25px",
                          width: "25px",
                          mr: "5px",
                        }}
                        alt="Profile"
                      />
                      {label}
                    </Box>
                  }
                />
              );
            })}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f2f4fb",
              padding: `${width < 900 ? "5px,0px" : "10px 0px"}`,
              width: "200px",
              borderRadius: "25px 25px 0 0",
              justifyContent: "center",
            }}
          >
            {isLoggedIn && (
              <Box
                component="img"
                sx={{
                  height: "55px",
                  width: "66px",
                  cursor: "pointer",
                }}
                alt="crayon logo"
                src={topBarIcon}
                onClick={() => {
                  handleMyCrayon();
                  setMobileOpen(false);
                }}
              />
            )}
          </Box>

          {userType === USER_ROLES?.at(2)?.role_id && (
            <Box
              sx={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "-999",
              }}
              // width={`calc(100vw - 80px)`}
            >
              <Button
                variant="contained"
                ref={candidateButtonRef}
                startIcon={
                  <Box
                    component={"img"}
                    src={candidateProfile}
                    sx={{
                      height: "20px",
                      width: "23px",
                      ml: "25%",
                      // mr: 1,
                    }}
                  />
                }
                sx={{
                  // flexDirection: "row-reverse",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "0 0 10px 10px",
                  height: "45px",
                  width: "200px",
                  // boxShadow: "0px 5px 10px #00000029 !important",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  backgroundColor: theme.palette.yoco.main,
                  ":hover": {
                    backgroundColor: theme.palette.yoco.main,
                    boxShadow: "none",
                  },
                  padding: "10px 0px 10px 10px !important",
                  fontSize: {
                    md: "12px",
                    lg: "14px",
                    mlg: "14px",
                    xl: "14px",
                    xxl: "16px",
                  },
                }}
              >
                Candidate
              </Button>
            </Box>
          )}
          {userType === USER_ROLES?.at(5)?.role_id && (
            <Box
              sx={{
                width: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: "-999",
              }}
              width={`calc(100vw - 80px)`}
            >
              <Button
                variant="contained"
                color="DirectToggleButton"
                ref={promoterButtonRef}
                startIcon={
                  <Box
                    component={"img"}
                    src={promoterlogo}
                    sx={{
                      height: "25px",
                      width: "25px",
                      // ml: 1,
                      mb: "6px",
                    }}
                  />
                }
                sx={{
                  display: "flex",
                  // flexDirection: "row-reverse",
                  alignItems: "center",
                  borderRadius: "0 0 10px 10px",
                  height: "45px",
                  width: "200px",
                  // boxShadow: "0px 5px 10px #00000029 !important",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  ":hover": {
                    boxShadow: "none",
                  },
                  padding: "10px 0px 10px 0px !important",
                  fontSize: {
                    md: "12px",
                    lg: "14px",
                    mlg: "14px",
                    xl: "14px",
                    xxl: "16px",
                  },
                }}
              >
                Promoter
              </Button>
            </Box>
          )}

          {userType === USER_ROLES?.at(3)?.role_id ||
          userType === USER_ROLES?.at(4)?.role_id ? (
            <Box
              sx={{
                width: "200px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                zIndex: "-999",
              }}
            >
              <Button
                variant="contained"
                onClick={() => setButtonClick((prev) => !prev)}
                startIcon={
                  <Box
                    component={"img"}
                    src={
                      activeTab.includes("admin")
                        ? candidateTalent
                        : userType === USER_ROLES?.at(4)?.role_id
                        ? recruit
                        : userType === USER_ROLES?.at(3)?.role_id
                        ? employer
                        : candidateLite
                    }
                    sx={{
                      height: activeTab.includes("admin") ? "25px" : "25px",
                      width: activeTab.includes("admin") ? "28px" : "25px",
                    }}
                  />
                }
                sx={{
                  // flexDirection: "row-reverse",
                  alignItems: "center",
                  borderRadius: "0 0 10px 10px",
                  height: "45px",
                  width: "200px",
                  // boxShadow: "0px 5px 10px #00000029 !important",
                  boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                  backgroundColor: activeTab.includes("admin")
                    ? theme.palette.redButton100.main
                    : userType === USER_ROLES?.at(4)?.role_id
                    ? theme.palette.redButton100.main
                    : theme.palette.tymeColor.main,
                  ":hover": {
                    backgroundColor: activeTab.includes("admin")
                      ? theme.palette.redButton100.main
                      : userType === USER_ROLES?.at(4)?.role_id
                      ? theme.palette.redButton100.main
                      : theme.palette.tymeColor.main,
                    boxShadow: "none",
                  },
                  padding: "10px 0px 10px 0px !important",
                  fontSize: {
                    md: "12px",
                    lg: "14px",
                    mlg: "14px",
                    xl: "14px",
                    xxl: "16px",
                  },
                }}
              >
                {activeTab.includes("admin")
                  ? "Talent"
                  : userType === USER_ROLES?.at(4)?.role_id
                  ? "Recruiter"
                  : "Employer"}
              </Button>
            </Box>
          ) : null}
          {(userType === USER_ROLES?.at(0)?.role_id ||
            userType === USER_ROLES?.at(1)?.role_id ||
            userType === USER_ROLES?.at(6)?.role_id) && (
            <Box
              sx={{
                width: "200px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "center",
                zIndex: "-999",
              }}
            >
              <Link
                to={`/admin/adminJobs/all-jobs`}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Button
                  variant="contained"
                  // endIcon={
                  //   <Box
                  //     component={"img"}
                  //     src={candidateTalent}
                  //     sx={{
                  //       height: "25px",
                  //       width: "28px",
                  //     }}
                  //   />
                  // }
                  sx={{
                    // flexDirection: "row-reverse",
                    alignItems: "flex-start",
                    borderRadius: "0 0 10px 10px",
                    height: "45px",
                    width: "200px",
                    // boxShadow: "0px 5px 10px #00000029 !important",
                    boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                    backgroundColor: theme.palette.redButton100.main,
                    ":hover": {
                      backgroundColor: theme.palette.redButton100.main,
                      boxShadow: "none",
                    },
                    padding: "10px 0px 10px 0px !important",
                    fontSize: {
                      md: "12px",
                      lg: "14px",
                      mlg: "14px",
                      xl: "14px",
                      xxl: "16px",
                    },
                  }}
                >
                  {"Admin"}
                </Button>
              </Link>
            </Box>
          )}

          <Tabs orientation="vertical">
            {userType === USER_ROLES?.at(3)?.role_id && !isPromoter
              ? TAB_ITEMS_EMPLOYER_PROMOTER.filter((item) =>
                  pathname.includes("admin")
                    ? item.label !== "Talent"
                    : item.label !== "Lite"
                )?.map(({ label, path }) =>
                  label === "Talent" && !planType ? (
                    <Tab
                      disabled
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" && theme.palette.darkGrey.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        "&.Mui-disabled": {
                          color: "black !important",
                        },
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalentBlack}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}

                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={LockBlackIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  ) : label === "get coins" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      // key={path}
                      onClick={() => {
                        dispatch(setOpenCoinBox(true));
                        setAnchorElTopBar(null);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={getTabIcon(
                            label.split(" ").join("-").toLowerCase()
                          )}
                          sx={{
                            height: "25px",
                            width: "28px",
                            mr: "5px",
                            mb: "0px !important",
                          }}
                        />
                        {label === "get coins" && (
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : label === "logout" && decodedToken ? (
                    <>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                          "& .MuiAccordionSummary-root": {
                            // padding: "0px !important",
                            // display:"flex !important",
                            // justifyContent:"center !important"
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                          "&::before": {
                            visibility: "hidden !important",
                          },
                          "& .MuiTypography-body1": {
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "& .MuiAccordionSummary-content": {
                            flexGrow: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          ".MuiPaper-root-MuiAccordion-root": {
                            backgroundColor: "#E5E5E5 !important",
                          },
                          "& .MuiPaper-root-MuiAccordion-root::before": {
                            display: "none !important", // This will remove the :before pseudo-element
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={getTabIcon("qucik-links")}
                              sx={{
                                height: "25px",
                                width: "25px",
                                mr: "5px",
                              }}
                              alt="Profile"
                            />

                            <Typography
                              sx={{
                                fontWeight: "800 !important",
                                fontSize: "20px",
                              }}
                            >
                              quick links
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0px", boxShadow: "none" }}
                        >
                          <List sx={{ padding: "0px" }}>
                            {currentTabs1?.map(({ label, path }) => (
                              <ListItem key={label} disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedLabel(label);
                                    handleNavigate(path);
                                  }}
                                  sx={{
                                    textAlign: "center",
                                    fontWeight:
                                      label === selectedLabel ? 700 : 500,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    "& .MuiListItemText-multiline": {
                                      fontWeight:
                                        label === selectedLabel
                                          ? "700 !important"
                                          : "500 !important",
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={getTabIcon(
                                      label.split(" ").join("-").toLowerCase()
                                    )}
                                    sx={{
                                      height: "25px",
                                      width: "25px",
                                      mr: 1,
                                    }}
                                    alt="Profile"
                                  />
                                  <Typography
                                    sx={{
                                      width: "auto !important",
                                      fontSize: "14px",
                                      fontWeight: "800 !important",
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "center",
                          backgroundColor:
                            (label === "Lite" &&
                              theme.palette.tymeColor.main) ||
                            (label === "Talent" &&
                              theme.palette.redButton100.main),
                          width: "200px",
                          minHeight: "45px",
                          padding: "3px 7px 5px 8px!important",
                          textTransform: "none",
                          color:
                            (label === "Lite" && "white") ||
                            (label === "Talent" && "white") ||
                            "black",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            lgm: "16px",
                            xl: "16px",
                            xxl: "16px",
                          },
                          fontWeight: 700,
                          opacity: 1,
                          borderRadius: "0 0 10px 10px",
                        }}
                        key={path}
                        value={path}
                        to={label !== "logout" ? path : "/jobs"}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={candidateTalent}
                                sx={{
                                  height: "25px",
                                  width: "28px",
                                  mr: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label === "Lite" && (
                              <Box
                                component={"img"}
                                src={candidateLite}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: 1,
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label !== "my crayon" && (
                              <Box
                                component={"img"}
                                src={getTabIcon(
                                  label.split(" ").join("-").toLowerCase()
                                )}
                                sx={{
                                  height: "25px",
                                  width: "28px",
                                  mr: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Bold,
                              }}
                            >
                              {label}
                            </Box>
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={UnlockIcon}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                          </Box>
                        }
                        component={Link}
                        onClick={() => handlePopoverCloseTopBar(label)}
                      />
                    </>
                  ) : (
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalent}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          {label === "Lite" && (
                            <Box
                              component={"img"}
                              src={candidateLite}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: 1,
                                mb: "0px !important",
                              }}
                            />
                          )}
                          {label !== "my crayon" && label !== "logout" && (
                            <Box
                              component={"img"}
                              src={getTabIcon(
                                label.split(" ").join("-").toLowerCase()
                              )}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={UnlockIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  )
                )
              : userType === USER_ROLES?.at(3)?.role_id &&
                TAB_ITEMS_EMPLOYER.filter((item) =>
                  pathname.includes("admin")
                    ? item.label !== "Talent"
                    : item.label !== "Lite"
                )?.map(({ label, path }) =>
                  label === "Talent" && !planType ? (
                    <Tab
                      disabled
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" && theme.palette.darkGrey.main),
                        justifyContent: "center",
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        "&.Mui-disabled": {
                          color: "black !important",
                        },
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalentBlack}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={LockBlackIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  ) : label === "get coins" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        boxSizing: "border-box",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        justifyContent: "center",
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      onClick={() => {
                        dispatch(setOpenCoinBox(true));
                        setAnchorElTopBar(null);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={getTabIcon(
                            label.split(" ").join("-").toLowerCase()
                          )}
                          sx={{
                            height: "25px",
                            width: "28px",
                            mr: "5px",
                            mb: "0px !important",
                          }}
                        />
                        {label === "get coins" && (
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  ) : label === "logout" && decodedToken ? (
                    <>
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            // padding: "0px !important",
                            // display:"flex !important",
                            // justifyContent:"center !important"
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                          "&::before": {
                            visibility: "hidden !important",
                          },
                          "& .MuiTypography-body1": {
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "& .MuiAccordionSummary-content": {
                            flexGrow: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          ".MuiPaper-root-MuiAccordion-root": {
                            backgroundColor: "#E5E5E5 !important",
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={getTabIcon("qucik-links")}
                              sx={{
                                height: "25px",
                                width: "25px",
                                mr: "5px",
                              }}
                              alt="Profile"
                            />

                            <Typography
                              sx={{
                                fontWeight: "800 !important",
                                fontSize: "20px",
                              }}
                            >
                              quick links
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0px", boxShadow: "none" }}
                        >
                          <List sx={{ padding: "0px" }}>
                            {currentTabs1?.map(({ label, path }) => (
                              <ListItem key={label} disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedLabel(label);
                                    handleNavigate(path);
                                  }}
                                  sx={{
                                    textAlign: "center",
                                    fontWeight:
                                      label === selectedLabel ? 700 : 500,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    "& .MuiListItemText-multiline": {
                                      fontWeight:
                                        label === selectedLabel
                                          ? "700 !important"
                                          : "500 !important",
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={getTabIcon(
                                      label.split(" ").join("-").toLowerCase()
                                    )}
                                    sx={{
                                      height: "25px",
                                      width: "25px",
                                      mr: 1,
                                    }}
                                    alt="Profile"
                                  />
                                  <Typography
                                    sx={{
                                      width: "auto !important",
                                      fontSize: "14px",
                                      fontWeight: "800 !important",
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          justifyContent: "center",
                          backgroundColor:
                            (label === "Lite" &&
                              theme.palette.tymeColor.main) ||
                            (label === "Talent" &&
                              theme.palette.redButton100.main),
                          width: "200px",
                          minHeight: "45px",
                          padding: "3px 7px 5px 8px!important",
                          textTransform: "none",
                          color:
                            (label === "Lite" && "white") ||
                            (label === "Talent" && "white") ||
                            "black",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            lgm: "16px",
                            xl: "16px",
                            xxl: "16px",
                          },
                          fontWeight: 700,
                          opacity: 1,
                          borderRadius: "0 0 10px 10px",
                        }}
                        key={path}
                        value={path}
                        to={label !== "logout" ? path : "/jobs"}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={candidateTalent}
                                sx={{
                                  height: "25px",
                                  width: "28px",
                                  mr: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label === "Lite" && (
                              <Box
                                component={"img"}
                                src={candidateLite}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: 1,
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label !== "my crayon" && (
                              <Box
                                component={"img"}
                                src={getTabIcon(
                                  label.split(" ").join("-").toLowerCase()
                                )}
                                sx={{
                                  height: "25px",
                                  width: "28px",
                                  mr: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Bold,
                              }}
                            >
                              {label}
                            </Box>
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={UnlockIcon}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                          </Box>
                        }
                        component={Link}
                        onClick={() => handlePopoverCloseTopBar(label)}
                      />
                    </>
                  ) : (
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        justifyContent: "center",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalent}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          {label === "Lite" && (
                            <Box
                              component={"img"}
                              src={candidateLite}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: 1,
                                mb: "0px !important",
                              }}
                            />
                          )}
                          {label !== "my crayon" && label !== "logout" && (
                            <Box
                              component={"img"}
                              src={getTabIcon(
                                label.split(" ").join("-").toLowerCase()
                              )}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={UnlockIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  )
                )}
            {userType === USER_ROLES?.at(4)?.role_id && isPromoter
              ? TAB_ITEMS_RECRUITER_PROMOTER?.filter((item) =>
                  pathname.includes("admin")
                    ? item.label !== "Talent"
                    : item.label !== "Lite"
                )?.map(({ label, path }) =>
                  label === "Talent" && !planType ? (
                    <Tab
                      disabled
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" && theme.palette.darkGrey.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        "&.Mui-disabled": {
                          color: "black !important",
                        },
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalentBlack}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={LockBlackIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  ) : label === "get coins" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        boxSizing: "border-box",

                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      onClick={() => {
                        dispatch(setOpenCoinBox(true));
                        setAnchorElTopBar(null);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={getTabIcon(
                            label.split(" ").join("-").toLowerCase()
                          )}
                          sx={{
                            height: "25px",
                            width: "28px",
                            mr: "5px",
                            mb: "0px !important",
                          }}
                        />
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          {label}
                        </Box>
                      </Box>
                    </Box>
                  ) : label === "logout" && decodedToken ? (
                    <>
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            // padding: "0px !important",
                            // display:"flex !important",
                            // justifyContent:"center !important"
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                          "&::before": {
                            visibility: "hidden !important",
                          },
                          "& .MuiTypography-body1": {
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "& .MuiAccordionSummary-content": {
                            flexGrow: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          ".MuiPaper-root-MuiAccordion-root": {
                            backgroundColor: "#E5E5E5 !important",
                          },
                          "& .MuiPaper-root-MuiAccordion-root::before": {
                            visibility: "hidden !important",
                            display: "none !important", // This will remove the :before pseudo-element
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={getTabIcon("qucik-links")}
                              sx={{
                                height: "25px",
                                width: "25px",
                                mr: "5px",
                              }}
                              alt="Profile"
                            />

                            <Typography
                              sx={{
                                fontWeight: "800 !important",
                                fontSize: "20px",
                              }}
                            >
                              quick links
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0px", boxShadow: "none" }}
                        >
                          <List sx={{ padding: "0px" }}>
                            {currentTabs1?.map(({ label, path }) => (
                              <ListItem key={label} disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedLabel(label);
                                    handleNavigate(path);
                                  }}
                                  sx={{
                                    textAlign: "center",
                                    fontWeight:
                                      label === selectedLabel ? 700 : 500,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    "& .MuiListItemText-multiline": {
                                      fontWeight:
                                        label === selectedLabel
                                          ? "700 !important"
                                          : "500 !important",
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={getTabIcon(
                                      label.split(" ").join("-").toLowerCase()
                                    )}
                                    sx={{
                                      height: "25px",
                                      width: "25px",
                                      mr: 1,
                                    }}
                                    alt="Profile"
                                  />
                                  <Typography
                                    sx={{
                                      width: "auto !important",
                                      fontSize: "14px",
                                      fontWeight: "800 !important",
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          backgroundColor:
                            (label === "Lite" &&
                              theme.palette.tymeColor.main) ||
                            (label === "Talent" &&
                              theme.palette.redButton100.main),
                          width: "200px",
                          justifyContent: "center",
                          minHeight: "45px",
                          padding: "3px 7px 5px 8px!important",
                          textTransform: "none",
                          color:
                            (label === "Lite" && "white") ||
                            (label === "Talent" && "white") ||
                            "black",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            lgm: "16px",
                            xl: "16px",
                            xxl: "16px",
                          },
                          fontWeight: 700,
                          opacity: 1,
                          borderRadius: "0 0 10px 10px",
                        }}
                        key={path}
                        value={path}
                        to={label !== "logout" ? path : "/jobs"}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={candidateTalent}
                                sx={{
                                  height: "25px",
                                  width: "28px",
                                  mr: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label === "Lite" && (
                              <Box
                                component={"img"}
                                src={candidateLite}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: 1,
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label === "logout" && (
                              <Box
                                component="img"
                                src={getTabIcon(
                                  label.split(" ").join("-").toLowerCase()
                                )}
                                sx={{
                                  height: "25px",
                                  width: "25px",
                                  mr: 1,
                                }}
                                alt="Profile"
                              />
                            )}

                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Bold,
                              }}
                            >
                              {label}
                            </Box>
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={UnlockIcon}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                          </Box>
                        }
                        component={Link}
                        onClick={() => handlePopoverCloseTopBar(label)}
                      />
                    </>
                  ) : (
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        justifyContent: "center",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalent}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          {label === "Lite" && (
                            <Box
                              component={"img"}
                              src={candidateLite}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: 1,
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={UnlockIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  )
                )
              : userType === USER_ROLES?.at(4)?.role_id &&
                TAB_ITEMS_RECRUITER?.filter((item) =>
                  pathname.includes("admin")
                    ? item.label !== "Talent"
                    : item.label !== "Lite"
                )?.map(({ label, path }) =>
                  label === "Talent" && !planType ? (
                    <Tab
                      disabled
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" && theme.palette.darkGrey.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        "&.Mui-disabled": {
                          color: "black !important",
                        },
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalentBlack}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={LockBlackIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  ) : label === "get coins" ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        justifyContent: "center",
                        boxSizing: "border-box",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      onClick={() => {
                        dispatch(setOpenCoinBox(true));
                        setAnchorElTopBar(null);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={getTabIcon(
                            label.split(" ").join("-").toLowerCase()
                          )}
                          sx={{
                            height: "25px",
                            width: "28px",
                            mr: "5px",
                            mb: "0px !important",
                          }}
                        />
                        <Box
                          sx={{
                            fontSize: "14px",
                            fontWeight: theme.typography.Bold,
                          }}
                        >
                          {label}
                        </Box>
                      </Box>
                    </Box>
                  ) : label === "logout" && decodedToken ? (
                    <>
                      <Accordion
                        sx={{
                          boxShadow: "none !important",
                          "& .MuiAccordionSummary-root": {
                            // padding: "0px !important",
                            // display:"flex !important",
                            // justifyContent:"center !important"
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                          "&::before": {
                            visibility: "hidden !important",
                          },
                          "& .MuiTypography-body1": {
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "& .MuiAccordionSummary-content": {
                            flexGrow: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          ".MuiPaper-root-MuiAccordion-root": {
                            backgroundColor: "#E5E5E5 !important",
                          },
                          "& .MuiPaper-root-MuiAccordion-root::before": {
                            visibility: "hidden !important",
                            display: "none !important", // This will remove the :before pseudo-element
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={getTabIcon("qucik-links")}
                              sx={{
                                height: "25px",
                                width: "25px",
                                mr: "5px",
                              }}
                              alt="Profile"
                            />

                            <Typography
                              sx={{
                                fontWeight: "800 !important",
                                fontSize: "20px",
                              }}
                            >
                              quick links
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0px", boxShadow: "none" }}
                        >
                          <List sx={{ padding: "0px" }}>
                            {currentTabs1?.map(({ label, path }) => (
                              <ListItem key={label} disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedLabel(label);
                                    handleNavigate(path);
                                  }}
                                  sx={{
                                    textAlign: "center",
                                    fontWeight:
                                      label === selectedLabel ? 700 : 500,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    "& .MuiListItemText-multiline": {
                                      fontWeight:
                                        label === selectedLabel
                                          ? "700 !important"
                                          : "500 !important",
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={getTabIcon(
                                      label.split(" ").join("-").toLowerCase()
                                    )}
                                    sx={{
                                      height: "25px",
                                      width: "25px",
                                      mr: 1,
                                    }}
                                    alt="Profile"
                                  />
                                  <Typography
                                    sx={{
                                      width: "auto !important",
                                      fontSize: "14px",
                                      fontWeight: "800 !important",
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row-reverse",
                          backgroundColor:
                            (label === "Lite" &&
                              theme.palette.tymeColor.main) ||
                            (label === "Talent" &&
                              theme.palette.redButton100.main),
                          width: "200px",
                          minHeight: "45px",
                          padding: "3px 7px 5px 8px!important",
                          textTransform: "none",
                          color:
                            (label === "Lite" && "white") ||
                            (label === "Talent" && "white") ||
                            "black",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            lgm: "16px",
                            xl: "16px",
                            xxl: "16px",
                          },
                          justifyContent: "center",
                          fontWeight: 700,
                          opacity: 1,
                          borderRadius: "0 0 10px 10px",
                        }}
                        key={path}
                        value={path}
                        to={label !== "logout" ? path : "/jobs"}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={candidateTalent}
                                sx={{
                                  height: "25px",
                                  width: "28px",
                                  mr: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label === "Lite" && (
                              <Box
                                component={"img"}
                                src={candidateLite}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: 1,
                                  mb: "0px !important",
                                }}
                              />
                            )}
                            {label === "logout" && (
                              <Box
                                component="img"
                                src={getTabIcon(
                                  label.split(" ").join("-").toLowerCase()
                                )}
                                sx={{
                                  height: "25px",
                                  width: "25px",
                                  mr: 1,
                                }}
                                alt="Profile"
                              />
                            )}
                            <Box
                              sx={{
                                fontSize: "14px",
                                fontWeight: theme.typography.Bold,
                              }}
                            >
                              {label}
                            </Box>
                            {label === "Talent" && (
                              <Box
                                component={"img"}
                                src={UnlockIcon}
                                sx={{
                                  height: "20px",
                                  width: "20px",
                                  ml: "5px",
                                  mb: "0px !important",
                                }}
                              />
                            )}
                          </Box>
                        }
                        component={Link}
                        onClick={() => handlePopoverCloseTopBar(label)}
                      />
                    </>
                  ) : (
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        backgroundColor:
                          (label === "Lite" && theme.palette.tymeColor.main) ||
                          (label === "Talent" &&
                            theme.palette.redButton100.main),
                        width: "200px",
                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          (label === "Lite" && "white") ||
                          (label === "Talent" && "white") ||
                          "black",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        justifyContent: "center",
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={candidateTalent}
                              sx={{
                                height: "25px",
                                width: "28px",
                                mr: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                          {label === "Lite" && (
                            <Box
                              component={"img"}
                              src={candidateLite}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: 1,
                                mb: "0px !important",
                              }}
                            />
                          )}
                          <Box
                            sx={{
                              fontSize: "14px",
                              fontWeight: theme.typography.Bold,
                            }}
                          >
                            {label}
                          </Box>
                          {label === "Talent" && (
                            <Box
                              component={"img"}
                              src={UnlockIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                                ml: "5px",
                                mb: "0px !important",
                              }}
                            />
                          )}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  )
                )}
            {userType === USER_ROLES?.at(2)?.role_id && isPromoter
              ? TAB_ITEMS_CANDIDATE_PROMOTER?.slice(0, -1)?.map(
                  ({ label, path }) => {
                    return label === "logout" && decodedToken ? (
                      <>
                        <Accordion
                          sx={{
                            boxShadow: "none",
                            "& .MuiAccordionSummary-root": {
                              // padding: "0px !important",
                              // display:"flex !important",
                              // justifyContent:"center !important"
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "&::before": {
                              visibility: "hidden !important",
                            },
                            "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                            "& .MuiTypography-body1": {
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            "& .MuiAccordionSummary-content": {
                              flexGrow: 0,
                              fontSize: "14px",
                              fontWeight: "500",
                            },
                            ".MuiPaper-root-MuiAccordion-root": {
                              backgroundColor: "#E5E5E5 !important",
                            },
                            "& .MuiPaper-root-MuiAccordion-root::before": {
                              display: "none !important", // This will remove the :before pseudo-element
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                // flexDirection: "row-reverse",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                component="img"
                                src={getTabIcon("qucik-links")}
                                sx={{
                                  height: "25px",
                                  width: "25px",
                                  mr: "5px",
                                }}
                                alt="Profile"
                              />

                              <Typography
                                sx={{
                                  fontWeight: "800 !important",
                                  fontSize: "20px",
                                }}
                              >
                                quick links
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{ padding: "0px", boxShadow: "none" }}
                          >
                            <List sx={{ padding: "0px" }}>
                              {currentTabs1?.map(({ label, path }) => (
                                <ListItem key={label} disablePadding>
                                  <ListItemButton
                                    onClick={() => {
                                      setSelectedLabel(label);
                                      handleNavigate(path);
                                    }}
                                    sx={{
                                      textAlign: "center",
                                      fontWeight:
                                        label === selectedLabel ? 700 : 500,
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      "& .MuiListItemText-multiline": {
                                        fontWeight:
                                          label === selectedLabel
                                            ? "700 !important"
                                            : "500 !important",
                                        fontSize: "14px",
                                        color: "black",
                                      },
                                      "& .MuiListItemText-primary": {
                                        fontWeight: 700,
                                        fontSize: "14px",
                                        color: "black",
                                      },
                                    }}
                                  >
                                    <Box
                                      component="img"
                                      src={getTabIcon(
                                        label.split(" ").join("-").toLowerCase()
                                      )}
                                      sx={{
                                        height: "25px",
                                        width: "25px",
                                        mr: 1,
                                      }}
                                      alt="Profile"
                                    />
                                    <Typography
                                      sx={{
                                        width: "auto !important",
                                        fontSize: "14px",
                                        fontWeight: "800 !important",
                                      }}
                                    >
                                      {label}
                                    </Typography>
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                        <Tab
                          sx={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                            flexDirection: "row",
                            backgroundColor:
                              label === "Candidate" && theme.palette.yoco.main,
                            width: "200px",

                            minHeight: "45px",
                            padding: "3px 7px 5px 8px!important",
                            textTransform: "none",
                            color:
                              label === "Candidate"
                                ? "white"
                                : `${theme.palette.grayButton100.light} !important`,
                            fontSize: {
                              md: "12px",
                              lg: "14px",
                              mlg: "14px",
                              lgm: "16px",
                              xl: "16px",
                              xxl: "16px",
                            },
                            fontWeight: 700,
                            opacity: 1,
                            borderRadius: "0 0 10px 10px",
                          }}
                          key={path}
                          value={path}
                          to={label !== "logout" ? path : "/jobs"}
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {label !== "my crayon" && (
                                <Box
                                  component="img"
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "25px",
                                    mr: "5px",
                                  }}
                                  alt="Profile"
                                />
                              )}
                              {label}
                            </Box>
                          }
                          component={Link}
                          onClick={() => handlePopoverCloseTopBar(label)}
                        />
                      </>
                    ) : (
                      <Tab
                        sx={{
                          display: "flex",
                          // flexDirection: "row-reverse",
                          flexDirection: "row",
                          backgroundColor:
                            label === "Candidate" && theme.palette.yoco.main,
                          width: "200px",

                          minHeight: "45px",
                          padding: "3px 7px 5px 8px!important",
                          textTransform: "none",
                          color:
                            label === "Candidate"
                              ? "white"
                              : `${theme.palette.grayButton100.light} !important`,
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            lgm: "16px",
                            xl: "16px",
                            xxl: "16px",
                          },
                          fontWeight: 700,
                          opacity: 1,
                          borderRadius: "0 0 10px 10px",
                        }}
                        key={path}
                        value={path}
                        to={label !== "logout" ? path : "/jobs"}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {label !== "my crayon" && label !== "logout" && (
                              <Box
                                component="img"
                                src={getTabIcon(
                                  label.split(" ").join("-").toLowerCase()
                                )}
                                sx={{
                                  height: "25px",
                                  width: "25px",
                                  mr: "5px",
                                }}
                                alt="Profile"
                              />
                            )}
                            {label}
                          </Box>
                        }
                        component={Link}
                        onClick={() => handlePopoverCloseTopBar(label)}
                      />
                    );
                  }
                )
              : userType === USER_ROLES?.at(2)?.role_id &&
                TAB_ITEMS_CANDIDATE?.slice(0, -1)?.map(({ label, path }) => {
                  return label === "logout" && decodedToken ? (
                    <>
                      <Accordion
                        sx={{
                          boxShadow: "none",
                          "& .MuiAccordionSummary-root": {
                            // padding: "0px !important",
                            // display:"flex !important",
                            // justifyContent:"center !important"
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "& .MuiTypography-body1": {
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          "&::before": {
                            visibility: "hidden !important",
                          },
                          "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                          "& .MuiAccordionSummary-content": {
                            flexGrow: 0,
                            fontSize: "14px",
                            fontWeight: "500",
                          },
                          ".MuiPaper-root-MuiAccordion-root": {
                            backgroundColor: "#E5E5E5 !important",
                          },
                          "& .MuiPaper-root-MuiAccordion-root::before": {
                            display: "none !important", // This will remove the :before pseudo-element
                          },
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // flexDirection: "row-reverse",
                              alignItems: "center",
                            }}
                          >
                            <Box
                              component="img"
                              src={getTabIcon("qucik-links")}
                              sx={{
                                height: "25px",
                                width: "25px",
                                mr: "5px",
                              }}
                              alt="Profile"
                            />

                            <Typography
                              sx={{
                                fontWeight: "800 !important",
                                fontSize: "20px",
                              }}
                            >
                              quick links
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{ padding: "0px", boxShadow: "none" }}
                        >
                          <List sx={{ padding: "0px" }}>
                            {currentTabs1?.map(({ label, path }) => (
                              <ListItem key={label} disablePadding>
                                <ListItemButton
                                  onClick={() => {
                                    setSelectedLabel(label);
                                    handleNavigate(path);
                                  }}
                                  sx={{
                                    textAlign: "center",
                                    fontWeight:
                                      label === selectedLabel ? 700 : 500,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    "& .MuiListItemText-multiline": {
                                      fontWeight:
                                        label === selectedLabel
                                          ? "700 !important"
                                          : "500 !important",
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                    "& .MuiListItemText-primary": {
                                      fontWeight: 700,
                                      fontSize: "14px",
                                      color: "black",
                                    },
                                  }}
                                >
                                  <Box
                                    component="img"
                                    src={getTabIcon(
                                      label.split(" ").join("-").toLowerCase()
                                    )}
                                    sx={{
                                      height: "25px",
                                      width: "25px",
                                      mr: 1,
                                    }}
                                    alt="Profile"
                                  />
                                  <Typography
                                    sx={{
                                      width: "auto !important",
                                      fontSize: "14px",
                                      fontWeight: "800 !important",
                                    }}
                                  >
                                    {label}
                                  </Typography>
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          backgroundColor:
                            label === "Candidate" && theme.palette.yoco.main,
                          width: "200px",

                          minHeight: "45px",
                          padding: "3px 7px 5px 8px!important",
                          textTransform: "none",
                          color:
                            label === "Candidate"
                              ? "white"
                              : `${theme.palette.grayButton100.light} !important`,
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            lgm: "16px",
                            xl: "16px",
                            xxl: "16px",
                          },
                          fontWeight: 700,
                          opacity: 1,
                          borderRadius: "0 0 10px 10px",
                        }}
                        key={path}
                        value={path}
                        to={label !== "logout" && path}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {label !== "my crayon" && (
                              <Box
                                component="img"
                                src={getTabIcon(
                                  label.split(" ").join("-").toLowerCase()
                                )}
                                sx={{
                                  height: "25px",
                                  width: "25px",
                                  mr: "5px",
                                }}
                                alt="Profile"
                              />
                            )}
                            {label}
                          </Box>
                        }
                        component={Link}
                        onClick={() => handlePopoverCloseTopBar(label)}
                      />
                    </>
                  ) : (
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor:
                          label === "Candidate" && theme.palette.yoco.main,
                        width: "200px",

                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          label === "Candidate"
                            ? "white"
                            : `${theme.palette.grayButton100.light} !important`,
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" && path}
                      label={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {label !== "my crayon" && label !== "logout" && (
                            <Box
                              component="img"
                              src={getTabIcon(
                                label.split(" ").join("-").toLowerCase()
                              )}
                              sx={{
                                height: "25px",
                                width: "25px",
                                mr: "5px",
                              }}
                              alt="Profile"
                            />
                          )}
                          {label}
                        </Box>
                      }
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  );
                })}
            {userType === USER_ROLES?.at(5)?.role_id &&
              TAB_ITEMS_PROMOTER?.map(({ label, path }) => {
                return label === "logout" && decodedToken ? (
                  <>
                    <Accordion
                      sx={{
                        boxShadow: "none",
                        "& .MuiAccordionSummary-root": {
                          // padding: "0px !important",
                          // display:"flex !important",
                          // justifyContent:"center !important"
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        "& .MuiTypography-body1": {
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        "&::before": {
                          visibility: "hidden !important",
                        },
                        "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                        "& .MuiAccordionSummary-content": {
                          flexGrow: 0,
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        ".MuiPaper-root-MuiAccordion-root": {
                          backgroundColor: "#E5E5E5 !important",
                        },
                        "& .MuiPaper-root-MuiAccordion-root::before": {
                          display: "none !important", // This will remove the :before pseudo-element
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={getTabIcon("qucik-links")}
                            sx={{
                              height: "25px",
                              width: "25px",
                              mr: "5px",
                            }}
                            alt="Profile"
                          />

                          <Typography
                            sx={{
                              fontWeight: "800 !important",
                              fontSize: "20px",
                            }}
                          >
                            quick links
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0px", boxShadow: "none" }}
                      >
                        <List sx={{ padding: "0px" }}>
                          {currentTabs1?.map(({ label, path }) => (
                            <ListItem key={label} disablePadding>
                              <ListItemButton
                                onClick={() => {
                                  setSelectedLabel(label);
                                  handleNavigate(path);
                                }}
                                sx={{
                                  textAlign: "center",
                                  fontWeight:
                                    label === selectedLabel ? 700 : 500,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  "& .MuiListItemText-multiline": {
                                    fontWeight:
                                      label === selectedLabel
                                        ? "700 !important"
                                        : "500 !important",
                                    fontSize: "14px",
                                    color: "black",
                                  },
                                  "& .MuiListItemText-primary": {
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "black",
                                  },
                                }}
                              >
                                <Box
                                  component="img"
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "25px",
                                    mr: 1,
                                  }}
                                  alt="Profile"
                                />
                                <Typography
                                  sx={{
                                    width: "auto !important",
                                    fontSize: "14px",
                                    fontWeight: "800 !important",
                                  }}
                                >
                                  {label}
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Tab
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        backgroundColor:
                          label === "Promoter" &&
                          theme.palette.DirectToggleButton.main,
                        width: "200px",
                        minHeight: "45px",
                        // padding: "3px 7px 5px 8px!important",
                        padding:
                          label === "Promoter"
                            ? "0px"
                            : "3px 7px 5px 8px !important",
                        textTransform: "none",
                        color:
                          label === "Promoter"
                            ? "white"
                            : `${theme.palette.grayButton100.light} !important`,
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={label}
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                      icon={
                        label === "Promoter" && (
                          <Box
                            component={"img"}
                            src={promoterlogo}
                            sx={{
                              height: "25px",
                              width: "25px",
                              mr: "4px",
                            }}
                          />
                        )
                      }
                    />
                  </>
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      backgroundColor:
                        label === "Promoter" &&
                        theme.palette.DirectToggleButton.main,
                      width: "200px",
                      minHeight: "45px",
                      // padding: "3px 7px 5px 8px!important",
                      padding:
                        label === "Promoter"
                          ? "0px"
                          : "3px 7px 5px 8px !important",
                      textTransform: "none",
                      color:
                        label === "Promoter"
                          ? "white"
                          : `${theme.palette.grayButton100.light} !important`,
                      fontSize: {
                        md: "12px",
                        lg: "14px",
                        mlg: "14px",
                        lgm: "16px",
                        xl: "16px",
                        xxl: "16px",
                      },
                      fontWeight: 700,
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => handlePopoverCloseTopBar(label)}
                    icon={
                      label === "Promoter" && (
                        <Box
                          component={"img"}
                          src={promoterlogo}
                          sx={{
                            height: "25px",
                            width: "25px",
                            mr: "4px",
                          }}
                        />
                      )
                    }
                  />
                );
              })}
            {(userType === USER_ROLES?.at(0)?.role_id ||
              userType === USER_ROLES?.at(1)?.role_id ||
              userType === USER_ROLES?.at(6)?.role_id) &&
              TAB_ITEMS_ADMIN?.map(({ label, path }) => {
                return label === "logout" && decodedToken ? (
                  <>
                    <Accordion
                      sx={{
                        boxShadow: "none",
                        "& .MuiAccordionSummary-root": {
                          // padding: "0px !important",
                          // display:"flex !important",
                          // justifyContent:"center !important"
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        "& .MuiTypography-body1": {
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        "&::before": {
                          visibility: "hidden !important",
                        },
                        "&.Mui-expanded": {
                            marginTop: "0 !important",
                          },
                        "& .MuiAccordionSummary-content": {
                          flexGrow: 0,
                          fontSize: "14px",
                          fontWeight: "500",
                        },
                        ".MuiPaper-root-MuiAccordion-root": {
                          backgroundColor: "#E5E5E5 !important",
                        },
                        "& .MuiPaper-root-MuiAccordion-root::before": {
                          display: "none !important", // This will remove the :before pseudo-element
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            // flexDirection: "row-reverse",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={getTabIcon("qucik-links")}
                            sx={{
                              height: "25px",
                              width: "25px",
                              mr: "5px",
                            }}
                            alt="Profile"
                          />

                          <Typography
                            sx={{
                              fontWeight: "800 !important",
                              fontSize: "20px",
                            }}
                          >
                            quick links
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{ padding: "0px", boxShadow: "none" }}
                      >
                        <List sx={{ padding: "0px" }}>
                          {currentTabs1?.map(({ label, path }) => (
                            <ListItem key={label} disablePadding>
                              <ListItemButton
                                onClick={() => {
                                  setSelectedLabel(label);
                                  handleNavigate(path);
                                }}
                                sx={{
                                  textAlign: "center",
                                  fontWeight:
                                    label === selectedLabel ? 700 : 500,
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  "& .MuiListItemText-multiline": {
                                    fontWeight:
                                      label === selectedLabel
                                        ? "700 !important"
                                        : "500 !important",
                                    fontSize: "14px",
                                    color: "black",
                                  },
                                  "& .MuiListItemText-primary": {
                                    fontWeight: 700,
                                    fontSize: "14px",
                                    color: "black",
                                  },
                                }}
                              >
                                <Box
                                  component="img"
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "25px",
                                    mr: 1,
                                  }}
                                  alt="Profile"
                                />
                                <Typography
                                  sx={{
                                    width: "auto !important",
                                    fontSize: "14px",
                                    fontWeight: "800 !important",
                                  }}
                                >
                                  {label}
                                </Typography>
                              </ListItemButton>
                            </ListItem>
                          ))}
                        </List>
                      </AccordionDetails>
                    </Accordion>
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row-reverse",
                        width: "200px",

                        minHeight: "45px",
                        padding: "3px 7px 5px 8px!important",
                        textTransform: "none",
                        color:
                          label === "Candidate"
                            ? "white"
                            : `${theme.palette.grayButton100.light} !important`,
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                        fontWeight: 700,
                        opacity: 1,
                        borderRadius: "0 0 10px 10px",
                      }}
                      key={path}
                      value={path}
                      to={label !== "logout" ? path : "/jobs"}
                      label={label}
                      component={Link}
                      onClick={() => handlePopoverCloseTopBar(label)}
                    />
                  </>
                ) : (
                  <Tab
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      width: "200px",

                      minHeight: "45px",
                      padding: "3px 7px 5px 8px!important",
                      textTransform: "none",
                      color:
                        label === "Candidate"
                          ? "white"
                          : `${theme.palette.grayButton100.light} !important`,
                      fontSize: {
                        md: "12px",
                        lg: "14px",
                        mlg: "14px",
                        lgm: "16px",
                        xl: "16px",
                        xxl: "16px",
                      },
                      fontWeight: 700,
                      opacity: 1,
                      borderRadius: "0 0 10px 10px",
                    }}
                    key={path}
                    value={path}
                    to={label !== "logout" ? path : "/jobs"}
                    label={label}
                    component={Link}
                    onClick={() => handlePopoverCloseTopBar(label)}
                  />
                );
              })}
          </Tabs>
        </>
      )}
    </>
  );

  const handleNotification = () => {
    if (openSetting || openSpoiler) {
      setOpenSetting(false);
      setOpenSpoiler(false);
      setOpenNotication(false);
    } else {
      setOpenNotication((prev) => !prev);
    }
  };

  const handleCoin = () => {
    if (openGetCoins) {
      setOpenGetCoins(false);
      setOpenCoins(false);
    } else {
      setOpenCoins((prev) => !prev);
    }
  };

  const handleClickOutside = (event) => {
    if (
      boxRef?.current &&
      !boxRef?.current?.contains(event?.target) &&
      event?.target?.classList?.length > 0 &&
      event.target.id !== "customSelectcloseBtn" &&
      (event?.target?.role !== "option" || event?.target?.tagName !== "LI")
    ) {
      setOpenAdvanceSearch(false);
      setShowSearchBar(false);
    }
  };

  const handleClickOutsideCoins = (event) => {
    if (
      coinsRef?.current &&
      !coinsRef?.current?.contains(event?.target) &&
      !event?.target?.className?.includes("coins-class")
    ) {
      setOpenCoins(false);
      setOpenGetCoins(false);
      dispatch(setOpenCoinBox(false));
    }
  };

  const handleClickOutsideAlert = (event) => {
    if (
      alertRef?.current &&
      !alertRef?.current?.contains(event?.target) &&
      !event?.target?.className?.includes("notification-class")
    ) {
      setOpenNotication(false);
    }
    if (
      settingRef?.current &&
      !settingRef?.current?.contains(event?.target) &&
      !event?.target?.className?.includes("notification-class")
    ) {
      setOpenSetting(false);
    }
    if (
      spoilerRef?.current &&
      !spoilerRef?.current?.contains(event?.target) &&
      !event?.target?.className?.includes("notification-class")
    ) {
      setOpenSpoiler(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCoins);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCoins);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideAlert);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideAlert);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDecrement = () => {
    // countRef.current -= 1;
  };
  const candidateBoxRef = useRef();

  const handleCandOutClick = (event) => {
    if (
      candidateBoxRef.current &&
      !candidateBoxRef.current.contains(event.target)
    ) {
      setCandidatePageHeading(false);
    }
  };
  const getCompanyHiringList = async () => {
    try {
      dispatch(setLoading({ value: true, apiName: "getCompanyHiringList" }));
      let data = {
        lastKey: 0,
        decodedToken: decodedToken,
        industryID: "",
        status: 2,
        company_size: "",
        company_type: "",
        favourite: "",
        appliedJobs: "",
        query: "",
        job_title: "",
        town_id: "",
        region_id: "",
        tag_id: "",
        tool_id: "",
        company_id: 32,
        currency_id: "",
        highest_qualification_id: "",
        salary: "",
        experience: "",
      };
      const { payload } = await dispatch(getHiringList(data));
      if (payload?.status === "success") {
        if (payload?.data?.length > 0) {
          dispatch(setFooterCompanyData(payload?.data?.[0]));
        }
      } else {
      }
      dispatch(setLoading({ value: false, apiName: "getCompanyHiringList" }));
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "getCompanyHiringList" }));
    }
  };
  useEffect(() => {
    if (footerCompanyData?.length === 0) {
      getCompanyHiringList();
    }
  }, [footerCompanyData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleCandOutClick);
    return () => {
      document.removeEventListener("mousedown", handleCandOutClick);
    };
  }, [currentStep]);
  const getCount = async () => {
    if (params?.pathname?.includes("admin")) {
      return;
    }
    try {
      const { payload } = await dispatch(getNotificationCount());
      if (payload?.status === "success") {
        dispatch(setNotificationCount(payload?.count));
        dispatch(setCoinsCount(payload?.coinsCounter));
        payload?.company_id && setCompany_id(payload?.company_id);
        // countRef.current = payload?.count;
      }
    } catch (error) {}
  };

  return (
    <Box ref={boxRef}>
      <Box
        sx={{
          background: "#FFFFFF",
          padding: { xs: "0", md: "0 0 0 27px" },
          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
        }}
      >
        <Box
          // height= {`${showSearchBar? "145px" : "100px"} `}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            background: "#FFFFFF",
            top: 0,
            zIndex: 10,
            height: {
              xs: showSearchBar ? "auto" : "auto",
              md: showSearchBar ? "125px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              lgm: showSearchBar ? "125px" : "90px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            minHeight: {
              xs: "60px",
            },
            // boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
          }}
        >
          {/* {!decodedToken &&  */}
          {/* {
          width<768 && decodedToken ?"": ( */}
          <Box
            component="img"
            sx={{
              height: { xs: "45px", md: "60px", xl: "60px", xxl: "90px" },
              width: { xs: "125px", md: "200px", xl: "200px", xxl: "200px" },
              cursor: "pointer",
              marginLeft: { xs: "20px", tablet: "0px" },
              // display:{xs:"none",tablet:"block"}
            }}
            alt="crayon logo"
            src={crayonLogo}
            // onClick={handleHomeLogoClick}
            onClick={() => {
              handleHomeLogoClick();
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                gap: { md: "20px", lg: "40px", xl: "60px" },
              }}
            >
              <div>
                {!isAdmin && (
                  <>
                    <StyledTab
                      value={activeTab}
                      onChange={handleTabChange}
                      textColor="inherit"
                      aria-label="secondary tabs example"
                      sx={{
                        display: { xs: "none", sm: "block" },
                        "& .MuiTabs-flexContainer": {
                          gap: {
                            md24: "12px !important",
                            mddl: "40px !important",
                          },
                        },
                      }}
                    >
                      {currentTabs?.map(({ label, path }) => {
                        const lastElement = path?.split("/");
                        return (
                          <Tab
                            ref={
                              label === "quick links"
                                ? quickLinksButtonRef
                                : null
                            }
                            onClick={() => {
                              label == "quick links" && handleTabClick();
                              setSearchText("");
                            }}
                            key={path}
                            value={path}
                            to={label != "quick links" ? path : null}
                            label={
                              label != "quick links"
                                ? activeTab === path ||
                                  hoveredTab ===
                                    lastElement[lastElement?.length - 1]
                                  ? ""
                                  : label
                                : hoveredTab ===
                                  lastElement[lastElement?.length - 1]
                                ? ""
                                : open
                                ? ""
                                : label
                              // activeTab === path ||
                              //     hoveredTab ===
                              //       lastElement[lastElement?.length - 1]
                              //     ? ""
                              //     : label
                            }
                            component={Link}
                            sx={{
                              display: "flex",
                              flexDirection:
                                label == "quick links"
                                  ? "row-reverse"
                                  : "column-reverse",
                              justifyContent: "center",
                              minHeight: "40px !important",
                              minWidth: "50px",
                              width:
                                // label === "quick links"
                                //   ? "fit-content"
                                //   : `${label?.length}ch`,
                                `${label?.length}ch`,
                              textWrap: "noWrap",
                              padding: "0px !important",
                              fontSize: {
                                md: "12px",
                                lg: "14px",
                                mlg: "14px",
                                lgm: "16px",
                                xl: "16px",
                                xxl: "16px",
                              },
                            }}
                            onMouseEnter={() => {
                              setHoveredTab(
                                lastElement[lastElement?.length - 1]
                              );
                            }}
                            onMouseLeave={() => {
                              setHoveredTab("");
                            }}
                            icon={
                              label == "quick links" ? (
                                <>
                                  {open ? (
                                    <>
                                      {/* {(hoveredTab ===
                                        lastElement[
                                          lastElement?.length - 1
                                        ] || open)&& (
                                        <Zoom in={hoveredTab} timeout={600}>
                                          <img
                                            src={getTabIcon("qucik-links")} // Use your SVG image here
                                            alt={label}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </Zoom>
                                      )} */}
                                      {open && (
                                        <Zoom
                                          in={open ? "qucik-links" : hoveredTab}
                                          timeout={600}
                                        >
                                          <img
                                            src={getTabIcon("qucik-links")} // Use your SVG image here
                                            alt={label}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </Zoom>
                                      )}
                                      {hoveredTab ===
                                        lastElement[lastElement?.length - 1] ||
                                      open ? (
                                        ""
                                      ) : (
                                        <KeyboardArrowUpIcon />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {(hoveredTab ===
                                        lastElement[lastElement?.length - 1] ||
                                        open) && (
                                        <Zoom in={hoveredTab} timeout={600}>
                                          <img
                                            src={getTabIcon("qucik-links")} // Use your SVG image here
                                            alt={label}
                                            style={{
                                              width: "50px",
                                              height: "50px",
                                            }}
                                          />
                                        </Zoom>
                                      )}
                                      {hoveredTab ===
                                        lastElement[lastElement?.length - 1] ||
                                      open ? (
                                        ""
                                      ) : (
                                        <KeyboardArrowDownIcon />
                                      )}
                                    </>
                                  )}
                                </>
                              ) : (
                                (activeTab === path && (
                                  <img
                                    src={getTabIcon(
                                      lastElement[lastElement?.length - 1]
                                    )} // Use your SVG image here
                                    alt={label}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                )) ||
                                (hoveredTab ===
                                  lastElement[lastElement?.length - 1] && (
                                  <Zoom in={hoveredTab} timeout={600}>
                                    <img
                                      src={getTabIcon(hoveredTab)} // Use your SVG image here
                                      alt={label}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </Zoom>
                                ))
                              )
                            }
                          />
                        );
                      })}
                    </StyledTab>
                    <Popover
                      id="dropdown-menu"
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      style={{
                        zIndex: 1000000,
                      }}
                    >
                      <Tabs orientation="vertical" onChange={handlequicklinks}>
                        {publicTabs?.map(({ label, path }) => {
                          const lastElement = path?.split("/");
                          return (
                            <Tab
                              sx={{
                                width: "130px",
                                height: "45px",
                                textTransform: "none",
                                color: `${theme?.palette?.grayButton100?.light}`,
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                              }}
                              key={path}
                              value={path}
                              to={path}
                              label={
                                label != "quick links"
                                  ? activeTab ===
                                      lastElement[lastElement?.length - 1] ||
                                    hoveredTab ===
                                      lastElement[lastElement?.length - 1]
                                    ? ""
                                    : label
                                  : label
                              }
                              component={Link}
                              onClick={handlePopoverClose}
                              onMouseEnter={() => {
                                setHoveredTab(
                                  lastElement[lastElement?.length - 1]
                                );
                              }}
                              onMouseLeave={() => {
                                setHoveredTab("");
                              }}
                              icon={
                                (activeTab ===
                                  lastElement[lastElement?.length - 1] && (
                                  <img
                                    src={getTabIcon(activeTab)} // Use your SVG image here
                                    alt={label}
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                    }}
                                  />
                                )) ||
                                (hoveredTab ===
                                  lastElement[lastElement?.length - 1] && (
                                  <Zoom in={hoveredTab} timeout={600}>
                                    <img
                                      src={getTabIcon(hoveredTab)} // Use your SVG image here
                                      alt={label}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                      }}
                                    />
                                  </Zoom>
                                ))
                              }
                            />
                          );
                        })}
                      </Tabs>
                    </Popover>
                  </>
                )}
              </div>
            </Box>

            <Box
              sx={{
                position: "relative",
                // top: "30px",
                display: { xs: "none", md: "flex" },
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <SearchBarBox
                setBasicData={setBasicData}
                basicData={basicData}
                setLocationID={setLocationID}
                setSearchText={setSearchText}
                searchText={searchText}
                openAdvanceSearch={openAdvanceSearch}
                setOpenAdvanceSearch={setOpenAdvanceSearch}
                showSearchBar={showSearchBar}
                setShowSearchBar={setShowSearchBar}
                handleJobSearch={handleJobSearch}
              />
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              height: "100%",
              display: "flex",
              // width: { md: "200px", xl: "240px" },
              justifyContent: "end",
            }}
          >
            {decodedToken && width > 889 ? (
              <>
                {userType !== USER_ROLES?.at(0)?.role_id &&
                  userType !== USER_ROLES?.at(1)?.role_id &&
                  userType !== USER_ROLES?.at(6)?.role_id && (
                    <Box
                      sx={{
                        display: "flex",
                        cursor: "pointer",
                        marginTop: "20px",
                        width: "60px",
                        boxSizing: "border-box",
                      }}
                      onClick={() => {
                        handleNotification();
                      }}
                    >
                      <Box
                        component={"img"}
                        className="notification-class"
                        src={notification}
                        sx={{
                          height: "30px !important",
                          width: "32px",
                        }}
                      />

                      <Button
                        className="notification-class"
                        variant="contained"
                        color="redButton100"
                        sx={{
                          borderRadius: "5px",
                          boxShadow: "none",
                          height: "20px !important",
                          // width: "20px!important",
                          minWidth: "20px !important",
                          lineHeight: 0,
                          padding: "6px",
                          fontSize: "10px",
                          top: "-17px",
                          left: "-13px",
                          visibility:
                            notificationCount > 0 ? "visible" : "hidden",
                        }}
                      >
                        {notificationCount}
                      </Button>
                    </Box>
                  )}
                {showCoin === 1 || showCoin === 2 ? (
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      marginTop: "13px",
                      width: "70.5px",
                      boxSizing: "border-box",
                    }}
                    onClick={company_id ? () => handleCoin() : () => {}}
                  >
                    <Box
                      component={"img"}
                      className="coins-class"
                      src={coinIcon}
                      sx={{ height: "40px", width: "40px" }}
                    />
                    <Button
                      className="coins-class"
                      variant="contained"
                      color="getCoins"
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        height: "24px !important",
                        // width: "20px!important",
                        minWidth: "24px !important",
                        lineHeight: 0,
                        padding: "6px",
                        fontSize: "10px",
                        top: "-13px",
                        left: "-10px",
                        // visibility: coinsCount > 0 ? "visible" : "hidden",
                      }}
                    >
                      {coinsCount}
                    </Button>
                  </Box>
                ) : (
                  <>
                    {/* <Box sx={{ height: "40px", width: "70.5px" }}></Box> */}
                  </>
                )}
                <Popover
                  id="dropdown-menu"
                  open={openTopBar}
                  anchorEl={anchorElTopBar}
                  onClose={handlePopoverCloseTopBar}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    "& .MuiPaper-root": {
                      // borderRadius:
                      //   userType === USER_ROLES?.at(2)?.role_id ||
                      //   userType === USER_ROLES?.at(0)?.role_id ||
                      //   userType === USER_ROLES?.at(1)?.role_id ||
                      //   userType === USER_ROLES?.at(6)?.role_id
                      //     ? "0px 0px 10px 10px !important"
                      //     : "0 0 25px 25px !important",
                      borderRadius: "0 0 10px 10px !important",
                      backgroundColor: "#f2f4fb",
                      // boxShadow: "none",
                      right: 0,
                      left: "initial !important",
                      cursor: "pointer",
                      boxShadow: "0px 5px 10px #00000029",
                    },
                  }}
                >
                  <Tabs orientation="vertical">
                    {userType === USER_ROLES?.at(3)?.role_id && !isPromoter
                      ? TAB_ITEMS_EMPLOYER_PROMOTER.filter((item) =>
                          pathname.includes("admin")
                            ? item.label !== "Talent"
                            : item.label !== "Lite"
                        )?.map(({ label, path }) =>
                          label === "Talent" && !planType ? (
                            <Tab
                              disabled
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.darkGrey.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                  color: "black !important",
                                },
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalentBlack}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={LockBlackIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          ) : label === "get coins" ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                boxSizing: "border-box",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              // key={path}
                              onClick={() => {
                                dispatch(setOpenCoinBox(true));
                                setAnchorElTopBar(null);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "28px",
                                    mr: "5px",
                                    mb: "0px !important",
                                  }}
                                />
                                {label === "get coins" && (
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Tab
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              to={label !== "logout" ? path : "/jobs"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalent}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  {label === "Lite" && (
                                    <Box
                                      component={"img"}
                                      src={candidateLite}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: 1,
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={UnlockIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          )
                        )
                      : userType === USER_ROLES?.at(3)?.role_id &&
                        TAB_ITEMS_EMPLOYER.filter((item) =>
                          pathname.includes("admin")
                            ? item.label !== "Talent"
                            : item.label !== "Lite"
                        )?.map(({ label, path }) =>
                          label === "Talent" && !planType ? (
                            <Tab
                              disabled
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.darkGrey.main),
                                justifyContent: "center",
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                  color: "black !important",
                                },
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalentBlack}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={LockBlackIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          ) : label === "get coins" ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                boxSizing: "border-box",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                justifyContent: "center",
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              onClick={() => {
                                dispatch(setOpenCoinBox(true));
                                setAnchorElTopBar(null);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "28px",
                                    mr: "5px",
                                    mb: "0px !important",
                                  }}
                                />
                                {label === "get coins" && (
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Tab
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                justifyContent: "center",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              to={label !== "logout" ? path : "/jobs"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalent}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  {label === "Lite" && (
                                    <Box
                                      component={"img"}
                                      src={candidateLite}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: 1,
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={UnlockIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          )
                        )}
                    {userType === USER_ROLES?.at(4)?.role_id && isPromoter
                      ? TAB_ITEMS_RECRUITER_PROMOTER?.filter((item) =>
                          pathname.includes("admin")
                            ? item.label !== "Talent"
                            : item.label !== "Lite"
                        )?.map(({ label, path }) =>
                          label === "Talent" && !planType ? (
                            <Tab
                              disabled
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.darkGrey.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                  color: "black !important",
                                },
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              to={label !== "logout" ? path : "/jobs"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalentBlack}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={LockBlackIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          ) : label === "get coins" ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                boxSizing: "border-box",

                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              onClick={() => {
                                dispatch(setOpenCoinBox(true));
                                setAnchorElTopBar(null);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "28px",
                                    mr: "5px",
                                    mb: "0px !important",
                                  }}
                                />
                                <Box
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: theme.typography.Bold,
                                  }}
                                >
                                  {label}
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Tab
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                justifyContent: "center",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              to={label !== "logout" ? path : "/jobs"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalent}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  {label === "Lite" && (
                                    <Box
                                      component={"img"}
                                      src={candidateLite}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: 1,
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={UnlockIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          )
                        )
                      : userType === USER_ROLES?.at(4)?.role_id &&
                        TAB_ITEMS_RECRUITER?.filter((item) =>
                          pathname.includes("admin")
                            ? item.label !== "Talent"
                            : item.label !== "Lite"
                        )?.map(({ label, path }) =>
                          label === "Talent" && !planType ? (
                            <Tab
                              disabled
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.darkGrey.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                  color: "black !important",
                                },
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              to={label !== "logout" ? path : "/jobs"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalentBlack}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={LockBlackIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          ) : label === "get coins" ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                justifyContent: "center",
                                boxSizing: "border-box",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              onClick={() => {
                                dispatch(setOpenCoinBox(true));
                                setAnchorElTopBar(null);
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                              >
                                <Box
                                  component={"img"}
                                  src={getTabIcon(
                                    label.split(" ").join("-").toLowerCase()
                                  )}
                                  sx={{
                                    height: "25px",
                                    width: "28px",
                                    mr: "5px",
                                    mb: "0px !important",
                                  }}
                                />
                                <Box
                                  sx={{
                                    fontSize: "14px",
                                    fontWeight: theme.typography.Bold,
                                  }}
                                >
                                  {label}
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Tab
                              sx={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                backgroundColor:
                                  (label === "Lite" &&
                                    theme.palette.tymeColor.main) ||
                                  (label === "Talent" &&
                                    theme.palette.redButton100.main),
                                width: "125px",
                                minHeight: "45px",
                                padding: "3px 7px 5px 8px!important",
                                textTransform: "none",
                                color:
                                  (label === "Lite" && "white") ||
                                  (label === "Talent" && "white") ||
                                  "black",
                                fontSize: {
                                  md: "12px",
                                  lg: "14px",
                                  mlg: "14px",
                                  lgm: "16px",
                                  xl: "16px",
                                  xxl: "16px",
                                },
                                justifyContent: "center",
                                fontWeight: 700,
                                opacity: 1,
                                borderRadius: "0 0 10px 10px",
                              }}
                              key={path}
                              value={path}
                              to={label !== "logout" ? path : "/jobs"}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: "100%",
                                  }}
                                >
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={candidateTalent}
                                      sx={{
                                        height: "25px",
                                        width: "28px",
                                        mr: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  {label === "Lite" && (
                                    <Box
                                      component={"img"}
                                      src={candidateLite}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: 1,
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                  <Box
                                    sx={{
                                      fontSize: "14px",
                                      fontWeight: theme.typography.Bold,
                                    }}
                                  >
                                    {label}
                                  </Box>
                                  {label === "Talent" && (
                                    <Box
                                      component={"img"}
                                      src={UnlockIcon}
                                      sx={{
                                        height: "20px",
                                        width: "20px",
                                        ml: "5px",
                                        mb: "0px !important",
                                      }}
                                    />
                                  )}
                                </Box>
                              }
                              component={Link}
                              onClick={() => handlePopoverCloseTopBar(label)}
                            />
                          )
                        )}
                    {userType === USER_ROLES?.at(2)?.role_id && isPromoter
                      ? TAB_ITEMS_CANDIDATE_PROMOTER?.map(({ label, path }) => (
                          <Tab
                            sx={{
                              display: "flex",
                              // flexDirection: "row-reverse",
                              flexDirection: "row",
                              backgroundColor:
                                label === "Candidate" &&
                                theme.palette.yoco.main,
                              width: "125px",

                              minHeight: "45px",
                              padding: "3px 7px 5px 8px!important",
                              textTransform: "none",
                              color:
                                label === "Candidate"
                                  ? "white"
                                  : `${theme.palette.grayButton100.light} !important`,
                              fontSize: {
                                md: "12px",
                                lg: "14px",
                                mlg: "14px",
                                lgm: "16px",
                                xl: "16px",
                                xxl: "16px",
                              },
                              fontWeight: 700,
                              opacity: 1,
                              borderRadius: "0 0 10px 10px",
                            }}
                            key={path}
                            value={path}
                            to={label !== "logout" ? path : "/jobs"}
                            label={label}
                            component={Link}
                            onClick={() => handlePopoverCloseTopBar(label)}
                            icon={
                              label === "Candidate" && (
                                <Box
                                  component={"img"}
                                  src={candidateProfile}
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 1,
                                  }}
                                />
                              )
                            }
                          />
                        ))
                      : userType === USER_ROLES?.at(2)?.role_id &&
                        TAB_ITEMS_CANDIDATE?.map(({ label, path }) => (
                          <Tab
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              backgroundColor:
                                label === "Candidate" &&
                                theme.palette.yoco.main,
                              width: "125px",

                              minHeight: "45px",
                              padding: "3px 7px 5px 8px!important",
                              textTransform: "none",
                              color:
                                label === "Candidate"
                                  ? "white"
                                  : `${theme.palette.grayButton100.light} !important`,
                              fontSize: {
                                md: "12px",
                                lg: "14px",
                                mlg: "14px",
                                lgm: "16px",
                                xl: "16px",
                                xxl: "16px",
                              },
                              fontWeight: 700,
                              opacity: 1,
                              borderRadius: "0 0 10px 10px",
                            }}
                            key={path}
                            value={path}
                            to={label !== "logout" && path}
                            label={label}
                            component={Link}
                            onClick={() => handlePopoverCloseTopBar(label)}
                            icon={
                              label === "Candidate" && (
                                <Box
                                  component={"img"}
                                  src={candidateProfile}
                                  sx={{
                                    height: "20px",
                                    width: "20px",
                                    mr: 1,
                                  }}
                                />
                              )
                            }
                          />
                        ))}
                    {userType === USER_ROLES?.at(5)?.role_id &&
                      TAB_ITEMS_PROMOTER?.map(({ label, path }) => (
                        <Tab
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            backgroundColor:
                              label === "Promoter" &&
                              theme.palette.DirectToggleButton.main,
                            width: "125px",

                            minHeight: "45px",
                            // padding: "3px 7px 5px 8px!important",
                            padding:
                              label === "Promoter"
                                ? "0px"
                                : "3px 7px 5px 8px !important",
                            textTransform: "none",
                            color:
                              label === "Promoter"
                                ? "white"
                                : `${theme.palette.grayButton100.light} !important`,
                            fontSize: {
                              md: "12px",
                              lg: "14px",
                              mlg: "14px",
                              lgm: "16px",
                              xl: "16px",
                              xxl: "16px",
                            },
                            fontWeight: 700,
                            opacity: 1,
                            borderRadius: "0 0 10px 10px",
                          }}
                          key={path}
                          value={path}
                          to={label !== "logout" ? path : "/jobs"}
                          label={label}
                          component={Link}
                          onClick={() => handlePopoverCloseTopBar(label)}
                          icon={
                            label === "Promoter" && (
                              <Box
                                component={"img"}
                                src={promoterlogo}
                                sx={{
                                  height: "25px",
                                  width: "25px",
                                  mr: "4px",
                                }}
                              />
                            )
                          }
                        />
                      ))}
                    {(userType === USER_ROLES?.at(0)?.role_id ||
                      userType === USER_ROLES?.at(1)?.role_id ||
                      userType === USER_ROLES?.at(6)?.role_id) &&
                      TAB_ITEMS_ADMIN?.map(({ label, path }) => (
                        <Tab
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            width: "125px",

                            minHeight: "45px",
                            padding: "3px 7px 5px 8px!important",
                            textTransform: "none",
                            color:
                              label === "Candidate"
                                ? "white"
                                : `${theme.palette.grayButton100.light} !important`,
                            fontSize: {
                              md: "12px",
                              lg: "14px",
                              mlg: "14px",
                              lgm: "16px",
                              xl: "16px",
                              xxl: "16px",
                            },
                            fontWeight: 700,
                            opacity: 1,
                            borderRadius: "0 0 10px 10px",
                          }}
                          key={path}
                          value={path}
                          to={label !== "logout" ? path : "/jobs"}
                          label={label}
                          component={Link}
                          onClick={() => handlePopoverCloseTopBar(label)}
                        />
                      ))}
                  </Tabs>
                </Popover>
                <Box
                  sx={{
                    // position: !openTopBar && "relative",
                    // top: !openTopBar ? "27px" : "0px",
                    marginTop: {
                      xs: showSearchBar ? "48px" : "48px",
                      // tablet: showSearchBar ?"50px" : "15px",
                      tablet: showSearchBar ? "89px" : "54px",
                      md24: showSearchBar ? "50px" : "15px",
                      lg: showSearchBar ? "50px" : "15px",
                      xl: showSearchBar ? "50px" : "15px",
                      xll: showSearchBar ? "50px" : "16px",
                      xxl: showSearchBar ? "50px" : "16px",
                    },
                    // marginRight: `${width <= 1024 ? "20px" : "155px"}`,
                    marginRight: `${width <= 900 ? "20px" : "0px"}`,
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "column",
                    height: {
                      md: "75px",
                      lg: "75px",
                      xl: "75px",
                      xxl: "75px",
                    },
                    width: "125px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f2f4fb",
                      padding: `${width < 900 ? "5px,0px" : "10px 0px"}`,
                      width: "125px",
                      borderRadius: "25px 25px 0 0",
                      justifyContent: "center",
                    }}
                    ref={topBarRef}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: "55px",
                        width: "66px",
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={topBarIcon}
                      onClick={handleMyCrayon}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "20px",
                      }}
                    >
                      {decodedToken?.data?.is_verified === 0 &&
                        decodedToken !== undefined &&
                        !params?.pathname?.includes("admin") && (
                          <Box
                            sx={{
                              height: 20,
                              width: 20,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {/* <Button
                            ref={openInfoRef}
                            variant="contained"
                            color="employerButton"
                            sx={{
                              borderRadius: "5px",
                              boxShadow: "none",
                              height: "20px !important",
                              minWidth: "20px",
                              lineHeight: 0,
                              padding: "6px",
                              fontSize: "10px",
                            }}
                            start={
                              RedInfoIcon
                            }
                            onClick={() => setOpenInfo(openInfoRef?.current)}
                          >
                            i
                          </Button> */}
                            <Box
                              ref={openInfoRef}
                              component="img"
                              sx={{
                                height: 16,
                                width: 15,
                                maxHeight: { xs: 16 },
                                maxWidth: { xs: 15 },
                                cursor: "pointer",
                                // marginBottom: "-3px",
                              }}
                              alt="currency"
                              onClick={() => setOpenInfo(openInfoRef?.current)}
                              src={RedInfoIcon}
                            />
                            <Popover
                              open={anchorElInfo}
                              anchorEl={openInfo}
                              onClose={handleInfoClose}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              sx={{
                                "& .MuiPaper-root": {
                                  width: { xs: "340px", tablet: "360px" },
                                  borderRadius: "25px !important",
                                  // padding: "16px !important",
                                  // mt: "-10px",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  background: "#FFF",
                                  borderRadius: "25px",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="nandos"
                                    sx={{
                                      height: "45px",
                                      minWidth: "150px",
                                      maxWidth: "150px",
                                      fontSize: {
                                        xs: "14px",
                                        xl: "14px",
                                      },
                                      borderRadius: "25px 0px 10px 0px",
                                      cursor: "default",
                                      ":hover": {
                                        boxShadow: "none !important",
                                      },
                                    }}
                                  >
                                    verification
                                  </Button>
                                  <Box
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleInfoClose();
                                    }}
                                  >
                                    <CloseIcon />
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flex: "1",
                                    flexDirection: "column",
                                    padding: "15px",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Box
                                      component={"img"}
                                      src={VerificationBot}
                                      sx={{
                                        height: "100px",
                                        width: "67px",
                                        marginX: "40px",
                                      }}
                                    ></Box>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: theme.typography.Regular,
                                          marginBottom: "15px",
                                        }}
                                      >
                                        “Verifications over vacations!” CrayBot
                                        always says.
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "12px",
                                          fontWeight: theme.typography.Regular,
                                        }}
                                      >
                                        You’ve got mail! Please check your inbox
                                        for an email from Crayon and verify your
                                        account via the link provided.
                                      </Typography>
                                    </Box>
                                  </Box>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: theme.typography.Bold,
                                      marginTop: "15px",
                                    }}
                                  >
                                    Your account will be locked if not verified
                                    within 24 hours.
                                  </Typography>
                                </Box>
                                {/* <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: theme.typography.Bold,
                                }}
                              >
                                {i18n["alertMessage.verify"]}
                                <Button
                                  variant="contained"
                                  color="applyButton"
                                  sx={{
                                    borderRadius: "5px",
                                    boxShadow: "none",
                                    height: "20px !important",
                                    minWidth: "fit-content !important",
                                    lineHeight: 0,
                                    padding: "6px",
                                    fontSize: "10px",
                                    top: "0px",
                                    left: "10px",
                                  }}
                                  onClick={() => resendVerificationMail()}
                                >
                                  resend
                                </Button>
                              </Typography> */}
                                <Box sx={{ width: "100%" }}>
                                  <Button
                                    variant="contained"
                                    color="newSubmit"
                                    sx={{
                                      width: "50%",
                                      height: "60px",
                                      borderRadius: "0 0 0 25px",
                                      fontWeight: theme.typography.Bold,
                                      fontSize: "14px",
                                    }}
                                    onClick={handleInfoClose}
                                  >
                                    I’ll do it later
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="nandos"
                                    sx={{
                                      width: "50%",
                                      height: "60px",
                                      borderRadius: "0 0 25px 0",
                                      fontWeight: theme.typography.Bold,
                                      fontSize: "14px",
                                    }}
                                    onClick={() => resendVerificationMail()}
                                  >
                                    resend link
                                  </Button>
                                </Box>
                              </Box>
                            </Popover>
                          </Box>
                        )}
                      <Box
                        sx={{
                          height: 15,
                          width: 20,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "end",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height:
                              decodedToken?.data?.is_verified === 0 &&
                              decodedToken !== undefined &&
                              !params?.pathname?.includes("admin")
                                ? "10px"
                                : 30,
                            width:
                              decodedToken?.data?.is_verified === 0 &&
                              decodedToken !== undefined &&
                              !params?.pathname?.includes("admin")
                                ? "10px"
                                : 30,
                            cursor: "pointer",
                          }}
                          alt="crayon logo"
                          src={
                            decodedToken?.data?.is_verified === 0 &&
                            decodedToken !== undefined &&
                            !params?.pathname?.includes("admin")
                              ? openTopBar
                                ? upArrow
                                : downArrow
                              : openTopBar
                              ? upArrowOld
                              : downArrowOld
                          }
                          onClick={handleClick}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {userType === USER_ROLES?.at(2)?.role_id && !openTopBar && (
                    <Box
                      sx={{
                        width: "125px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        zIndex: "-999",
                      }}
                      // width={`calc(100vw - 80px)`}
                    >
                      <Button
                        variant="contained"
                        ref={candidateButtonRef}
                        startIcon={
                          <Box
                            component={"img"}
                            src={candidateProfile}
                            sx={{
                              height: "20px",
                              width: "23px",
                              // mr: 1,
                            }}
                          />
                        }
                        sx={{
                          // flexDirection: "row-reverse",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          borderRadius: "0 0 10px 10px",
                          height: "45px",
                          width: "125px",
                          // boxShadow: "0px 5px 10px #00000029 !important",
                          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                          backgroundColor: theme.palette.yoco.main,
                          ":hover": {
                            backgroundColor: theme.palette.yoco.main,
                            boxShadow: "none",
                          },
                          padding: "10px 0px 10px 10px !important",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            xl: "14px",
                            xxl: "16px",
                          },
                        }}
                      >
                        Candidate
                      </Button>
                    </Box>
                  )}
                  {userType === USER_ROLES?.at(5)?.role_id && !openTopBar && (
                    <Box
                      sx={{
                        width: "125px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        zIndex: "-999",
                      }}
                      width={`calc(100vw - 80px)`}
                    >
                      <Button
                        variant="contained"
                        color="DirectToggleButton"
                        ref={promoterButtonRef}
                        startIcon={
                          <Box
                            component={"img"}
                            src={promoterlogo}
                            sx={{
                              height: "25px",
                              width: "25px",
                              // ml: 1,
                              mb: "6px",
                            }}
                          />
                        }
                        sx={{
                          display: "flex",
                          // flexDirection: "row-reverse",
                          alignItems: "center",
                          borderRadius: "0 0 10px 10px",
                          height: "45px",
                          width: "125px",
                          // boxShadow: "0px 5px 10px #00000029 !important",
                          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                          ":hover": {
                            boxShadow: "none",
                          },
                          padding: "10px 0px 10px 0px !important",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            xl: "14px",
                            xxl: "16px",
                          },
                        }}
                      >
                        Promoter
                      </Button>
                    </Box>
                  )}

                  {(userType === USER_ROLES?.at(3)?.role_id && !openTopBar) ||
                  (userType === USER_ROLES?.at(4)?.role_id && !openTopBar) ? (
                    <Box
                      sx={{
                        width: "125px",
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        zIndex: "-999",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => setButtonClick((prev) => !prev)}
                        startIcon={
                          <Box
                            component={"img"}
                            src={
                              activeTab.includes("admin")
                                ? candidateTalent
                                : userType === USER_ROLES?.at(4)?.role_id &&
                                  !openTopBar
                                ? recruit
                                : userType === USER_ROLES?.at(3)?.role_id &&
                                  !openTopBar
                                ? employer
                                : candidateLite
                            }
                            sx={{
                              height: activeTab.includes("admin")
                                ? "25px"
                                : "25px",
                              width: activeTab.includes("admin")
                                ? "28px"
                                : "25px",
                            }}
                          />
                        }
                        sx={{
                          // flexDirection: "row-reverse",
                          alignItems: "center",
                          borderRadius: "0 0 10px 10px",
                          height: "45px",
                          width: "125px",
                          // boxShadow: "0px 5px 10px #00000029 !important",
                          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                          backgroundColor: activeTab.includes("admin")
                            ? theme.palette.redButton100.main
                            : userType === USER_ROLES?.at(4)?.role_id &&
                              !openTopBar
                            ? theme.palette.redButton100.main
                            : theme.palette.tymeColor.main,
                          ":hover": {
                            backgroundColor: activeTab.includes("admin")
                              ? theme.palette.redButton100.main
                              : userType === USER_ROLES?.at(4)?.role_id &&
                                !openTopBar
                              ? theme.palette.redButton100.main
                              : theme.palette.tymeColor.main,
                            boxShadow: "none",
                          },
                          padding: "10px 0px 10px 0px !important",
                          fontSize: {
                            md: "12px",
                            lg: "14px",
                            mlg: "14px",
                            xl: "14px",
                            xxl: "16px",
                          },
                        }}
                      >
                        {activeTab.includes("admin")
                          ? "Talent"
                          : userType === USER_ROLES?.at(4)?.role_id &&
                            !openTopBar
                          ? "Recruiter"
                          : "Employer"}
                      </Button>
                    </Box>
                  ) : null}
                  {(userType === USER_ROLES?.at(0)?.role_id ||
                    userType === USER_ROLES?.at(1)?.role_id ||
                    userType === USER_ROLES?.at(6)?.role_id) &&
                    !openTopBar && (
                      <Box
                        sx={{
                          width: "125px",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          zIndex: "-999",
                        }}
                      >
                        <Link
                          to={`/admin/adminJobs/all-jobs`}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Button
                            variant="contained"
                            // endIcon={
                            //   <Box
                            //     component={"img"}
                            //     src={candidateTalent}
                            //     sx={{
                            //       height: "25px",
                            //       width: "28px",
                            //     }}
                            //   />
                            // }
                            sx={{
                              // flexDirection: "row-reverse",
                              alignItems: "flex-start",
                              borderRadius: "0 0 10px 10px",
                              height: "45px",
                              width: "125px",
                              // boxShadow: "0px 5px 10px #00000029 !important",
                              boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
                              backgroundColor: theme.palette.redButton100.main,
                              ":hover": {
                                backgroundColor:
                                  theme.palette.redButton100.main,
                                boxShadow: "none",
                              },
                              padding: "10px 0px 10px 0px !important",
                              fontSize: {
                                md: "12px",
                                lg: "14px",
                                mlg: "14px",
                                xl: "14px",
                                xxl: "16px",
                              },
                            }}
                          >
                            {"Admin"}
                          </Button>
                        </Link>
                      </Box>
                    )}
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  borderRadius: 0,
                  overflow: "hidden",
                  // width: { md: "200px", xl: "240px" },
                  height: "100%",
                  alignItems: "flex-end",
                  marginRight: "30px",
                }}
              >
                {width >= 900 || width === 0 ? (
                  <>
                    <Button
                      variant="contained"
                      sx={{
                        boxShadow: "none !important",
                        width: "125px",
                        padding: "30px 0",
                        borderRadius: "20px 0 0 0",
                        height: {
                          md: "50px",
                          lg: "65px",
                          lgm: "75px",
                          xl: "75px",
                          xxl: "75px",
                        },
                        // height: "75px",
                        flexDirection: "column",
                        // fontSize: "18px",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                      }}
                      color="redButton100"
                      // startIcon={}
                      onClick={() => setShowSignup(true)}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: {
                            sx: "20px",
                            md: "20px",
                            lgm: "30px",
                            xl: "30px",
                            xxl: "30px",
                          },
                          // marginRight: 1,
                          cursor: "pointer",
                        }}
                        alt="crayon logo"
                        src={CrayonJoin}
                      />
                      {i18n["topBar.join"]}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        boxShadow: "none !important",
                        width: "125px",
                        color: "black",
                        height: {
                          md: "50px",
                          lg: "65px",
                          lgm: "75px",
                          xl: "75px",
                          xxl: "75px",
                        },
                        // height: "75px",
                        padding: "30px 0",
                        borderRadius: "0 20px 0 0",
                        bottom: 0,
                        position: "static",
                        flexDirection: "column",
                        // fontSize: "18px",
                        fontSize: {
                          md: "12px",
                          lg: "14px",
                          mlg: "14px",
                          lgm: "16px",
                          xl: "16px",
                          xxl: "16px",
                        },
                      }}
                      color="lightGrey"
                      onClick={() => setShowLogin(true)}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: {
                            sx: "20px",
                            md: "20px",
                            lgm: "30px",
                            xl: "30px",
                            xxl: "30px",
                          },
                          cursor: "pointer",
                        }}
                        alt="crayon logo"
                        src={CrayonLogin}
                      />
                      {i18n["topBar.login"]}
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: `${width > 889 || width === 0 ? "none" : "flex"}`,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {decodedToken && (
              <>
                {(showCoin === 1 || showCoin === 2) && (
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      width: "45px !important",
                    }}
                    onClick={company_id ? () => handleCoin() : () => {}}
                  >
                    <Box
                      component={"img"}
                      className="coins-class"
                      src={coinIcon}
                      sx={{ height: "32px", width: "27px" }}
                    />
                    <Button
                      className="coins-class"
                      variant="contained"
                      color="getCoins"
                      sx={{
                        borderRadius: "8px",
                        boxShadow: "none",
                        height: "24px !important",
                        // width: "20px!important",
                        minWidth: "24px !important",
                        lineHeight: 0,
                        padding: "6px",
                        fontSize: "10px",
                        top: "-13px",
                        left: "-10px",
                        // visibility: coinsCount > 0 ? "visible" : "hidden",
                      }}
                    >
                      {coinsCount}
                    </Button>
                  </Box>
                )}
                {userType !== USER_ROLES?.at(0)?.role_id &&
                  userType !== USER_ROLES?.at(1)?.role_id &&
                  userType !== USER_ROLES?.at(6)?.role_id && (
                    <Box
                      sx={{ display: "flex", width: "40px !important" }}
                      onClick={() => handleNotification()}
                    >
                      <Box
                        component={"img"}
                        className="notification-class"
                        src={notification}
                        sx={{
                          height: {
                            xs: "25px",
                            sm: "35px",
                            xl: "40px",
                            xxl: "40px",
                          },
                          width: { xs: "25px !important", sm: "32px" },
                        }}
                      />

                      <Button
                        className="notification-class"
                        variant="contained"
                        color="redButton100"
                        sx={{
                          borderRadius: "5px",
                          boxShadow: "none",
                          height: "20px !important",
                          // width: "20px!important",
                          minWidth: "20px !important",
                          lineHeight: 0,
                          padding: "6px",
                          fontSize: "10px",
                          top: "-17px",
                          left: "-13px",
                          visibility:
                            notificationCount > 0 ? "visible" : "hidden",
                        }}
                      >
                        {notificationCount}
                      </Button>
                    </Box>
                  )}
              </>
            )}

            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ mr: 0, p: 0, pr: "8px" }}
            >
              <MenuIcon sx={{ width: "25px", height: "25px" }} />
            </IconButton>
          </Box>
          <Drawer
            anchor={"right"}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: `${(width > 1023 || width === 0) && "none"}`,
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                height: "auto",
                borderRadius: "25px",
              },
              "&.MuiDrawer-root": {
                zIndex: "999999999",
              },
            }}
          >
            {newDrawer}
          </Drawer>
        </Box>
        {showSearchBar && (
          <Box
            sx={{
              width: "100%",
              display: { xs: "block", md: "none" },
              height: "45px",
            }}
          >
            <MobileSearchBarBox
              setBasicData={setBasicData}
              basicData={basicData}
              setLocationID={setLocationID}
              setSearchText={setSearchText}
              searchText={searchText}
              openAdvanceSearch={openAdvanceSearch}
              setOpenAdvanceSearch={setOpenAdvanceSearch}
              showSearchBar={showSearchBar}
              setShowSearchBar={setShowSearchBar}
              handleJobSearch={handleJobSearch}
            />
          </Box>
        )}
        {/* </Box > */}
        {showLogin && (
          <Login
            handleLogin={onHandleLogin}
            openFunc={showLogin}
            toggleForm={toggleForm}
            closeFunc={handleCloseLogin}
            handleOpenLogin={setShowLogin}
            handleOpenSignUp={closeForgotPassword}
            handleForgotPassword={closeForgotPassword}
            setResendMailBox={setResendMailBox}
            setEmail={setEmail}
            uploadedFileName={uploadedFileName}
            handleDrawerToggle={handleDrawerToggle}
            setSelectedLabel={setSelectedLabel}
            setOpenInitialApporovalModal={setOpenInitialApporovalModal}
            handleConfirmPasswordChange={() => setIsForgotPassowrd(true)}
          />
        )}
        <ResendMailBox
          setResendMailBox={setResendMailBox}
          email={email}
          open={resendMailBox}
          handleClose={() => setResendMailBox(false)}
        />
        {showSignup && (
          <Signup
            onDialogClose={onHandleClose}
            toggleForm={toggleForm}
            openFunc={showSignup}
            closeFunc={handleCloseSignup}
            handleOpenSignUp={setShowSignup}
            setSelectedLabel={setSelectedLabel}
            handleDrawerToggle={handleDrawerToggle}
            uploadedFileName={uploadedFileName}
          />
        )}
        {isForgotPassword && (
          <ForgotPasswordConfirmationModal
            openFunc={isForgotPassword}
            closeFunc={() => setIsForgotPassowrd(false)}
          />
        )}
      </Box>
      {/* {(params.pathname.includes("/jobs") ||
        params.pathname.includes("/talent") ||
        params.pathname.includes("/whoshiring") ||
        params.pathname.includes("/solutions") ||
        params?.pathname === "/contact" ||
        params.pathname.includes("/payment") ||
        params?.pathname?.includes("/assessment") ||
        params.pathname.includes("/pricing") ||
        params?.pathname?.includes("/forbidden") ||
        params?.pathname?.includes("/pageNotFound") ||
        params?.pathname?.includes("/privacyPolicy") ||
        params?.pathname?.includes("/termsOfService") ||
        params.pathname === "/") && (
        <PreLoginLeftSideBox
          firstTypographyText={firstTypographyText}
          secondTypographyText={secondTypographyText}
          showSearchBar={showSearchBar}
        />
      )} */}
      {/* {params.pathname.includes("/candidate/") && (
        <CandidateLeftSideBox
          firstTypographyText={firstTypographyText}
          secondTypographyText={secondTypographyText}
          showSearchBar={showSearchBar}
        />
      )} */}
      {/* {params.pathname.includes("/promoter/my-profile") && (
        <CandidateLeftSideBox
          firstTypographyText={firstTypographyText}
          secondTypographyText={secondTypographyText}
          showSearchBar={showSearchBar}
        />
      )} */}
      {/* {(params.pathname.includes("/employer/") ||
        params.pathname.includes("/recruiter/")) && (
        <EmployerLeftSideBox
          employerRightBoxY={employerRightBoxY}
          showSearchBar={showSearchBar}
          firstTypographyText={firstTypographyText}
          secondTypographyText={secondTypographyText}
          profileSteps={profileSteps}
        />
      )} */}
      {!params.pathname.includes("/admin/") && (
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            margin: "0 auto",
            zIndex: openAdvanceSearch ? 999 : 999,
            // width: `calc(100vw - 360px)`,
            // width: openAdvanceSearch ? "80%" : "20%",
            // width: openAdvanceSearch
            //   ? `calc(100% - ${cardsPadding * 2 + 250}px)`
            //   : "20%",
            width: {
              xs: "100%",
              lg: `calc(100% - ${cardsPadding * 2 + 250}px)`,
            },
          }}
        >
          <AdvanceSection
            setBasicData={setBasicData}
            basicData={basicData}
            setOpenAdvanceSearch={setOpenAdvanceSearch}
            openAdvanceSearch={openAdvanceSearch}
            handleJobSearch={handleJobSearch}
            setShowArrow={setShowArrow}
            showArrow={showArrow}
            setSearchBox={setSearchBox}
            // color={color}
            showSearchBar={showSearchBar}
            setShowSearchBar={setShowSearchBar}
            handleShowSearchBtn={handleShowSearchBtn}
            handleShowSearchBar={handleShowSearchBar}
            setSearchText={setSearchText}
            // homePage={homePage}
          />
        </Box>
      )}

      {openCoins && (
        <Box
          sx={{
            position: "absolute",
            top: {
              display: { xs: "none", md24: "block" },
              xs: showSearchBar ? "85px" : "60px",
              md: showSearchBar ? "115px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            // right: { xs: "0px", mddl: "180px" },
            right: { xs: "0px", mddl: "0px" },
            zIndex: "10000",
          }}
        >
          <CrayonCoins
            coinsRef={coinsRef}
            // width={rightBoxY}
            width={{ xs: "340px", tablet: "360px" }}
            // width={"360"}
            rightExpand={true}
            handleClose={() => setOpenCoins(false)}
            setOpenNotication={setOpenCoins}
            setOpenGetCoins={setOpenGetCoins}
          />
        </Box>
      )}
      {(openGetCoins || openCoinBox) && (
        <Box
          sx={{
            position: "absolute",
            top: {
              display: { xs: "none", md24: "block" },
              xs: showSearchBar ? "85px" : "60px",
              md: showSearchBar ? "115px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            // right: { xs: "0px", mddl: "180px" },
            right: { xs: "0px", mddl: "0px" },
            zIndex: "10000",
          }}
        >
          <GetCoins
            coinsRef={coinsRef}
            // width={rightBoxY}
            // width={rightBoxY}
            width={{ xs: "340px", tablet: "360px" }}
            rightExpand={true}
            handleClose={() => {
              setOpenGetCoins(null);
              dispatch(setOpenCoinBox(false));
            }}
            setOpenNotication={setOpenGetCoins}
            setConfirm={setConfirm}
          />
        </Box>
      )}
      {confirm && (
        <CoinSpendBox
          text1={i18n["myplanPopup.nowyourecoiningit"]}
          text1Width={"208px"}
          text3={i18n["myplanPopup.crayonadminwillreview"]}
          text3Width={"252px"}
          text3Height={"72px"}
          buttonText={"got it, thanks"}
          handleClose={() => setConfirm(false)}
          show={confirm}
          buttonClick={handleConfirmButtonClick}
          gap={"20px"}
        />
      )}
      {openNotification && (
        <Box
          sx={{
            position: "absolute",
            top: {
              display: { xs: "none", md24: "block" },
              xs: showSearchBar ? "85px" : "40px",
              md: showSearchBar ? "125px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            // right: "180px",
            right: "0px",
            zIndex: "10000",
          }}
        >
          <Alerts
            // width={rightBoxY}
            width={{ xs: "340px", tablet: "450px" }}
            rightExpand={true}
            handleClose={() => setOpenNotication(false)}
            handleDecrement={handleDecrement}
            setOpenNotication={setOpenNotication}
            setOpenSetting={setOpenSetting}
            alertRef={alertRef}
            getCount={getCount}
          />
        </Box>
      )}

      {openSetting && (
        <Box
          sx={{
            position: "absolute",
            top: {
              xs: showSearchBar ? "85px" : "40px",
              md: showSearchBar ? "125px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            // right: "180px",
            right: "0px",
          }}
        >
          <Settings
            // width={rightBoxY}
            width={{ xs: "340px", tablet: "450px" }}
            rightExpand={true}
            handleClose={() => setOpenSetting(false)}
            setOpenSetting={setOpenSetting}
            settingRef={settingRef}
            setOpenSpoiler={setOpenSpoiler}
          />
        </Box>
      )}
      {initialPopup && (
        <InitialApprovalModal
          open={initialPopup}
          setOpen={() => {
            dispatch(setInitialPopup(false));
          }}
          handleReviewLater={() => {
            dispatch(setInitialPopup(false));
          }}
        />
      )}
      {openSpoiler && (
        <Box
          sx={{
            position: "absolute",
            top: {
              xs: showSearchBar ? "85px" : "40px",
              md: showSearchBar ? "125px" : "90px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            // right: "180px",
            right: "0px",
          }}
        >
          <SpoilerAlert
            // width={rightBoxY}
            width={{ xs: "340px", tablet: "450px" }}
            rightExpand={true}
            handleClose={() => setOpenSpoiler(false)}
            setOpenSetting={setOpenSetting}
            spoilerRef={spoilerRef}
          />
        </Box>
      )}
      {params.pathname.includes("/candidate/") && !openNotification && (
        <CandidatePercentageBox showSearchBar={showSearchBar} />
      )}
      {params.pathname.includes("/promoter/my-profile") && (
        <CandidatePercentageBox showSearchBar={showSearchBar} />
      )}
      {((params.pathname.includes("/employer/") &&
        !params.pathname.includes("/manage-talent/")) ||
        (params.pathname.includes("/recruiter/") &&
          !params.pathname.includes("/manage-talent/"))) && (
        <EmployerPercentageBox showSearchBar={showSearchBar} />
      )}
    </Box>
  );
}
