import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import companyLogo from "../../../assets/user_logo.svg";
import companyLogo1 from "../../../assets/company_logo_1.svg";
import profileIcon from "../../../assets/EmployerProfile/ProfileIcons.svg";
import calendarIcon from "../../../assets/Padding Included/Yellow_Calendar.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";
import companyIcon from "../../../assets/EmployerProfile/EmpCompanyIcon.svg";
import { dateConverterFullMonth } from "../../../utils/DateTime";
import locale from "../../../i18n/locale";
import ReactCardFlip from "react-card-flip";
import EmployerPersonalityFrontCard from "./EmployerPersonalityFrontCard";
import CompanyStats from "./CompanyStats";
import redLocation from "../../../assets/Red_Location.svg";
import uploadIcon from "../../../assets/Cam/UploadVideoIcon.svg";
import takePhotoIcon from "../../../assets/EmployerProfile/CameraPhotoIcon.svg";
import CompanyCultureFrontCard from "./CompanyCultureFrontCard";
import CompanyCultureBackCard from "./CompanyCultureBackCard";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { setProfileSteps } from "../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import useViewport from "../../common/ViewPort";
import flip from "../../../assets/NewFlipIcon.svg";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import Slider2 from "../../common/Slider2";
import { truncateText } from "../../../utils/Common";
import { useNavigate } from "react-router-dom";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";

export default function EmployerProfileCardRow({
  profileSteps,
  compInfo,
  employerData,
  companies,
  handlePhotoCapture,
  hiddenFileInput,
  setImagePreview,
  handleImageChange,
  editable,
  handleImageClick,
  chartDetails,
  arrSlider2,
  handleLeftClick,
  handleRightClick,
  companyDetail,
  calculateChartHeight,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [isHorizontalFlippedFirst, setIsHorizontalFlippedFirst] =
    useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);
  const typographyRef = useRef(null);
  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");

  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };
  const locationLabel = compInfo?.town_id
    ? `${compInfo?.town_id?.label}, ${compInfo?.country_id?.label}`
    : "-";

  const companyName =
    companies?.find((title) => title?.name === employerData?.company_name)
      ?.name ||
    employerData?.company_name ||
    "My Company";
  const fullName = `${employerData?.user?.first_name} ${employerData?.user?.surname}`;
  useEffect(() => {
    const typographyElement = typographyRef?.current;
    if (typographyElement) {
      const fullHeight = typographyElement?.scrollHeight;
      const lineHeight = 20;
      const clampedHeight = lineHeight * 4;
      if (fullHeight > clampedHeight) {
        setIsOverflowing(true);
      }
    }
  }, [compInfo?.notes]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        alignItems: "center",
        width: { xs: "100%", md: "100%" },
        gap: "30px",
      }}
    >
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
          allVideoData={companyDetail}
        />
      )}
      {profileSteps === 2 ? (
        <Box
          sx={{
            //   minWidth: {
            //   xs: "340px",
            //   xs390: "360px",
            //   sm: "90%",
            //   tablet: "100%",
            //   md24: "96.5%",
            //   lg: "50%",
            //   mlg: `calc(50% - 15px)`,
            // },
            width: {
              xs: "340px",
              xs390: "360px",
              sm: "90%",
              tablet: "100%",
              md24: "96.5%",
              lg: "50%",
              mlg: `calc(50% - 15px)`,
            },
          }}
        >
          <ReactCardFlip
            isFlipped={isHorizontalFlippedFirst}
            flipDirection={"horizontal"}
            flipSpeedBackToFront="0.5"
            flipSpeedFrontToBack="0.5"
            // key={job?.job_id}
          >
            <Box
              sx={{
                // minWidth: `calc(100% - 15px)`,
                // width: `calc(100% - 15px)`,
                width: "100%",
                minWidth: "100%",
                background: "#ffff",
                boxShadow: "0px 5px 10px #00000029",
                borderRadius: "25px 25px 25px 0px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: "30px", lg: "0px" },
                minHeight: "225px",
                height: {
                  xs: "440px",
                  tablet: "225px",
                  lg: "225px",
                  lg1440: "225px",
                  mlg: "225px",
                },
                // order:"1"
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Button
                  color="precium"
                  variant="contained"
                  sx={{
                    fontSize: theme.typography.fontSize.xs,
                    fontWeight: theme.typography.Bold,
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: "0 0 0 10px",
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {compInfo?.company_type}
                </Button>
                <Button
                  color="peachPayment"
                  variant="contained"
                  sx={{
                    fontSize: theme.typography.fontSize.xs,
                    fontWeight: theme.typography.Bold,
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: 0,
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {compInfo?.company_size}
                </Button>
                <Button
                  color="playButton"
                  variant="contained"
                  onClick={() => {
                    if (
                      companyDetail?.companyVideoURL === "NO URL" ||
                      !companyDetail?.companyVideoURL
                    ) {
                      navigate("/employer/my-cam", { state: 2 });
                    } else {
                      setOpenReviewVideoDialog(true);
                      setVideoLink(
                        companyDetail?.companyVideoURL ||
                          !companyDetail?.companyVideoURL
                      );
                    }
                  }}
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    background:
                      (companyDetail?.companyVideoURL === "NO URL" ||
                        !companyDetail?.companyVideoURL) &&
                      theme.palette.grayButton600.main,
                    borderRadius: 0,
                    borderTopRightRadius: "25px",
                    cursor:
                      companyDetail?.companyVideoURL === "NO URL" ||
                      !companyDetail?.companyVideoURL
                        ? "default"
                        : "pointer",
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      companyDetail?.companyVideoURL === "NO URL" ||
                      !companyDetail?.companyVideoURL
                        ? playButtonIconWhite
                        : playButtonIcon
                    }
                    sx={{
                      height: "16px",
                      width: "20px",
                    }}
                  />
                </Button>
              </Box>
              <Button
                variant="contained"
                color="newFlipColor"
                sx={{
                  width: "20px",
                  height: "100px",
                  padding: 0,
                  position: "absolute",
                  right: 0,
                  top: `calc(50% - 50px)`,
                  minWidth: "20px",
                  fontSize: "20px",
                  borderRadius: "10px 0 0 10px",
                  zIndex: "9999",
                }}
                onClick={() => setIsHorizontalFlippedFirst((prev) => !prev)}
              >
                <Box
                  component={"img"}
                  src={flip}
                  sx={{
                    height: "20px",
                    width: "15px",
                  }}
                />
              </Button>
              <Box
                sx={{
                  // display: "flex",
                  // alignItems: "center",
                  // flex: "1",
                  // position: "relative",
                  display: "flex",
                  flex: "1",
                  height: "100%",
                  flexDirection: { xs: "column", tablet: "row" },
                  alignItems: { xs: "baseline", tablet: "center" },
                  paddingTop: { xs: "45px", tablet: "0px" },
                }}
              >
                <Box
                  component={"img"}
                  className="companyLogo"
                  alt=""
                  src={
                    compInfo?.companyURL?.length > 0
                      ? compInfo?.companyURL
                      : companyLogo1
                  }
                  sx={{
                    // height: { xs: "130px", tablet: "175px" },
                    // width: { xs: "130px", tablet: "175px" },
                    // minWidth: { xs: "130px", tablet: "175px" },
                    // margin: { xs: "10px", tablet: "20px" },
                    // marginBottom: { xs: "0px", tablet: "0" },
                    // borderRadius: "10px",
                    height: { xs: "175px", tablet: "175px" },
                    width: { xs: "175px", tablet: "175px" },
                    borderRadius:
                      compInfo?.companyURL?.length > 0 ? "10px" : "20px",
                    margin: "24px 14px 25px 21px",
                    marginTop: { xs: "15px", tablet: "24px" },
                    marginBottom: { xs: "10px", tablet: "25px" },
                    marginLeft: { xs: "10px", xs390: "21px" },
                  }}
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    marginTop: { xs: "5px", tablet: "15px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={profileIcon}
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                        wordBreak: "break-all",
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: { xs: "14px", lg: "14px", xl: "20px" },
                        fontWeight: theme.typography.Bold,
                        wordBreak: "break-all",
                      }}
                    >
                      {fullName
                        ? width < 1024
                          ? truncateText(fullName, 25)
                          : fullName
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={companyIcon}
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                        lineHeight: 1,
                      }}
                    >
                      {width < 1024
                        ? truncateText(companyName, 25)
                        : companyName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={redLocation}
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                        lineHeight: 1,
                      }}
                    >
                      {/* {compInfo?.town_id
                            ? `${compInfo?.town_id?.label}, ${compInfo?.country_id?.label}`
                            : "-"} */}
                      {width < 1024
                        ? truncateText(locationLabel, 25)
                        : locationLabel}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={calendarIcon}
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                        lineHeight: 1,
                      }}
                    >
                      {profileSteps === 2
                        ? compInfo?.created_at
                          ? dateConverterFullMonth(compInfo?.created_at)
                          : "-"
                        : employerData?.created_at
                        ? dateConverterFullMonth(employerData?.created_at)
                        : "-"}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        width: {
                          xs: "320px",
                          xs390: "340px",
                          md24: "350px",
                          lg: "240px",
                          lgm: "330px",
                        },
                      }}
                    >
                      <Slider2
                        items={
                          compInfo?.industry_ids?.map((item) => item?.label) ||
                          []
                        }
                        color={"tymeColor"}
                        hideTagsAfter={2}
                        margin={"0 5px 0 0"}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ position: "absolute", right: "0", bottom: "0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {profileSteps === 2 && (
                      <>
                        <input
                          ref={hiddenFileInput}
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            setImagePreview([]);
                            handleImageChange(event, "company");
                          }}
                          style={{ display: "none" }}
                        />
                        <Button
                          disabled={!editable}
                          variant="contained"
                          color="uploadPhotoButton"
                          sx={{
                            height: "45px",
                            width: "150px",
                            fontSize: {
                              xs: "14px",
                              md24: "14px",
                              xl: "14px",
                            },
                            zIndex: "9999",
                            padding: "6px 6px",
                            borderRadius: "10px 0px 25px 0px",
                            ":hover": {
                              boxShadow: "none !important",
                            },
                          }}
                          startIcon={
                            <Box
                              component={"img"}
                              src={uploadIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          }
                          onClick={handleImageClick}
                        >
                          upload logo
                        </Button>
                        {/* <Button
                    disabled={!editable}
                    variant="contained"
                    color="takePhotoButton"
                    sx={{
                      height: "45px",
                      width: "150px",
                      fontSize: {
                        xs: "14px",
                        md24: "14px",
                        xl: "14px",
                      },
                      borderRadius: "0px 0px 25px 0px",
                      ":hover": {
                        boxShadow: "none !important",
                      },
                    }}
                    startIcon={
                      <Box
                        component={"img"}
                        src={takePhotoIcon}
                        sx={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    }
                    onClick={handlePhotoCapture}
                  >
                    {i18n["empMyProfile.takePhoto"]}
                  </Button> */}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              {(profileSteps !== 2 || profileSteps === 2) && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "flex-end",
                    marginTop: "45px",
                    position: "absolute",
                    zIndex: "22",
                  }}
                >
                  <Button
                    sx={{
                      height: "45px",
                      width: "125px",
                      borderRadius: "0 0 10px 10px",
                      boxShadow: "0px 5px 10px #00000029",
                      fontSize: "14px",
                      padding: "4px 0px",
                      fontWeight: theme.typography.Bold,
                    }}
                    size="small"
                    variant="contained"
                    color="myInfoButton"
                    endIcon={
                      profileSteps === 1 || profileSteps === 2 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    }
                    onClick={() => {
                      if (profileSteps === 1 || profileSteps === 2) {
                        // setActiveButton(0);
                        dispatch(setProfileSteps(0));
                      } else {
                        // setActiveButton(1);
                        dispatch(setProfileSteps(1));
                      }
                    }}
                  >
                    {profileSteps === 2 ? "company info" : "my info"}
                  </Button>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                // minWidth: {xs:"100%",tablet:`calc(100% - 15px)`},
                // width: {xs:"100%",tablet:`calc(100% - 15px)`},
                width: "100%",
                minWidth: "100%",
                background: "#ffff",
                boxShadow: "0px 5px 10px #00000029",
                borderRadius: "25px 25px 25px 0px",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                marginBottom: { xs: "30px", lg: "0px" },
                minHeight: "225px",
                height: {
                  xs: "440px",
                  tablet: "225px",
                  lg: "225px",
                  mlg: "225px",
                },
                // order:"1"
              }}
            >
              <Button
                variant="contained"
                color="newFlipColor"
                sx={{
                  width: "20px",
                  height: "100px",
                  padding: 0,
                  position: "absolute",
                  right: 0,
                  top: `calc(50% - 50px)`,
                  minWidth: "20px",
                  fontSize: "20px",
                  borderRadius: "10px 0 0 10px",
                  zIndex: "9999",
                }}
                onClick={() => setIsHorizontalFlippedFirst((prev) => !prev)}
              >
                <Box
                  component={"img"}
                  src={flip}
                  sx={{
                    height: "20px",
                    width: "15px",
                  }}
                />
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
              >
                <Button
                  color="precium"
                  variant="contained"
                  sx={{
                    fontSize: theme.typography.fontSize.xs,
                    fontWeight: theme.typography.Bold,
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: "0 0 0 10px",
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {compInfo?.company_type}
                </Button>
                <Button
                  color="peachPayment"
                  variant="contained"
                  sx={{
                    fontSize: theme.typography.fontSize.xs,
                    fontWeight: theme.typography.Bold,
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: 0,
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  {compInfo?.company_size}
                </Button>
                <Button
                  color="playButton"
                  variant="contained"
                  onClick={() => {
                    if (
                      companyDetail?.companyVideoURL === "NO URL" ||
                      !companyDetail?.companyVideoURL
                    ) {
                      navigate("/employer/my-cam", { state: 2 });
                    } else {
                      setOpenReviewVideoDialog(true);
                      setVideoLink(
                        companyDetail?.companyVideoURL ||
                          !companyDetail?.companyVideoURL
                      );
                    }
                  }}
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    background:
                      (companyDetail?.companyVideoURL === "NO URL" ||
                        !companyDetail?.companyVideoURL) &&
                      theme.palette.grayButton600.main,
                    borderRadius: 0,
                    borderTopRightRadius: "25px",
                    cursor:
                      companyDetail?.companyVideoURL === "NO URL" ||
                      !companyDetail?.companyVideoURL
                        ? "default"
                        : "pointer",
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      compInfo?.companyVideoURL === "NO URL"
                        ? playButtonIconWhite
                        : playButtonIcon
                    }
                    sx={{
                      height: "16px",
                      width: "20px",
                    }}
                  />
                </Button>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  // alignItems: "center",
                  // flex: "1",
                  position: "relative",
                  display: "flex",
                  flex: "1",
                  height: "100%",
                  flexDirection: { xs: "column", tablet: "row" },
                  alignItems: { xs: "baseline", tablet: "center" },
                  paddingTop: { xs: "45px", tablet: "0px" },
                }}
              >
                <Box
                  component={"img"}
                  className="companyLogo"
                  alt=""
                  src={
                    compInfo?.companyURL?.length > 0
                      ? compInfo?.companyURL
                      : companyLogo1
                  }
                  sx={{
                    // display: { xs: "none", tablet: "block" },
                    // height: { xs: "130px", tablet: "175px" },
                    // width: { xs: "130px", tablet: "175px" },
                    // minWidth: { xs: "130px", tablet: "175px" },
                    // margin: { xs: "10px", tablet: "20px" },
                    // marginBottom: { xs: "0px", tablet: "0" },
                    // borderRadius: "10px",
                    height: { xs: "175px", tablet: "175px" },
                    width: { xs: "175px", tablet: "175px" },
                    borderRadius: "10px",
                    margin: "24px 14px 25px 21px",
                    marginTop: { xs: "15px", tablet: "24px" },
                    marginBottom: { xs: "0px", tablet: "25px", mlg: "25px" },
                    marginLeft: { xs: "10px", xs390: "21px" },
                  }}
                />
                <Box
                  sx={{
                    width: {
                      xs: `90%`,
                      lg: "56%",
                      lg1440: `calc(100% - 44px)`,
                    },
                    boxSizing: "border-box",
                    marginTop: { xs: "5px", tablet: "15px" },
                  }}
                >
                  <Box sx={{ paddingLeft: "5px" }}>
                    <Typography
                      sx={{
                        fontSize: theme.typography.fontSize.sm,
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Company bio
                    </Typography>
                    <Typography
                      ref={typographyRef}
                      sx={{
                        fontSize: theme.typography.fontSize.xs,
                        fontWeight: theme.typography.Regular,
                        overflow: viewMoreExpanded ? "scroll" : "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 4,
                        WebkitBoxOrient: "vertical",
                        width: `calc(100% - 44px)`,
                        height: "auto",
                        lineHeight: "20px",
                      }}
                    >
                      {compInfo?.notes}
                    </Typography>
                    {isOverflowing && (
                      <div style={{ width: `100%` }}>
                        <Button
                          sx={{
                            color: "black",
                            padding: 0,
                            fontSize: "12px !important",
                            fontWeight: theme.typography.Bold,
                            ".MuiButton-startIcon": {
                              marginRight: "0px !important",
                              marginLeft: "-18px !important",
                            },
                            height: { xs: "15px", tablet: "30px" },
                          }}
                          startIcon={
                            <Box
                              component="img"
                              sx={{
                                height: { xs: "15px", tablet: "30px" },
                                width: { xs: "15px", tablet: "30px" },
                                cursor: "pointer",
                              }}
                              alt="crayon logo"
                              src={viewMoreExpanded ? upArrow : downArrow}
                            />
                          }
                          onClick={handleViewMore}
                        >
                          {viewMoreExpanded ? "less" : "more"}
                        </Button>
                      </div>
                    )}
                  </Box>
                  <Box sx={{ width: "100%", gap: "5px", display: "flex" }}>
                    {compInfo?.employer_cultures?.length > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          // marginBottom: "20px",
                          // marginTop: { xs: "0px", lg: "10px" },
                          width: {
                            xs: "320px",
                            xs390: "340px",
                            md24: "420px",
                            lg: "240px",
                            lgm: "400px",
                          },
                        }}
                      >
                        <Slider2
                          items={compInfo?.employer_cultures || []}
                          color={"precium"}
                          hideTagsAfter={2}
                          type="culture"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "9px",
                    flexDirection: "column",
                  }}
                >
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", xl: "row" },
                      marginTop: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "flex" },
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        width: {
                          xs360: "90%",
                          xs390: "87%",
                          sm: "100%",
                          lg: "100%",
                        },
                   
                        boxSizing: "border-box",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: theme.typography.Bold,
                          // width: `calc(100% - 44px)`,
                          paddingRight: "30px",
                          boxSizing: "border-box",
                          width: `100%`,
                        }}
                      >
                        Company bio
                      </Typography>
                      <Typography
                        ref={typographyRef}
                        sx={{
                          fontSize: "12px",
                          fontWeight: theme.typography.Regular,
                          overflow: viewMoreExpanded ? "scroll" : "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: { lg: 2, lg1440: 5 },
                          WebkitBoxOrient: "vertical",
                          // width: `calc(100% - 44px)`,
                          width: `100%`,
                          paddingRight: "30px",
                          boxSizing: "border-box",
                          height: "auto",
                          lineHeight: "20px",
                        }}
                      >
                        {compInfo?.notes}
                      </Typography>
                      {isOverflowing && (
                        <div style={{ width: `100%` }}>
                          <Button
                            sx={{
                              color: "black",
                              padding: 0,
                              fontSize: "12px !important",
                              fontWeight: theme.typography.Bold,
                              ".MuiButton-startIcon": {
                                marginRight: "0px !important",
                                marginLeft: "-18px !important",
                              },
                              height: { xs: "15px", lg1440: "30px" },
                            }}
                            startIcon={
                              <Box
                                component="img"
                                sx={{
                                  height: { xs: "15px", lg1440: "30px" },
                                  width: { xs: "15px", lg1440: "30px" },
                                  cursor: "pointer",
                                }}
                                alt="crayon logo"
                                src={viewMoreExpanded ? upArrow : downArrow}
                              />
                            }
                            onClick={handleViewMore}
                          >
                            {viewMoreExpanded ? "less" : "more"}
                          </Button>
                        </div>
                        // <Box
                        //   sx={{
                        //     // width: `calc(100% - 44px)`
                        //     width: `100%`,
                        //     paddingRight: "30px",
                        //     boxSizing: "border-box",
                        //   }}
                        // >

                        // </Box>
                      )}
                    </Box>
                  </Box> */}
                  {/* {compInfo?.employer_cultures?.length > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                        minWidth: { xs: `200px`, tablet: "225px", lg: "350px" },
                        maxWidth: { xs: `200px`, tablet: "225px", lg: "350px" },
                      }}
                    >
                      <Slider2
                        items={compInfo?.employer_cultures || []}
                        color={"industriesButton"}
                        hideTagsAfter={2}
                        margin={"0 5px 0 0"}
                        type="culture"
                      />
                    </Box>
                  )} */}
                </Box>
                <Box sx={{ position: "absolute", right: "0", bottom: "0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {profileSteps === 2 && (
                      <>
                        <input
                          ref={hiddenFileInput}
                          type="file"
                          accept="image/*"
                          onChange={(event) => {
                            setImagePreview([]);
                            handleImageChange(event, "company");
                          }}
                          style={{ display: "none" }}
                        />
                        <Button
                          disabled={!editable}
                          variant="contained"
                          color="uploadPhotoButton"
                          sx={{
                            height: "45px",
                            width: "150px",
                            zIndex: "9999",
                            fontSize: {
                              xs: "14px",
                              md24: "14px",
                              xl: "14px",
                            },
                            padding: "6px 6px",
                            borderRadius: "10px 0px 25px 0px",
                            ":hover": {
                              boxShadow: "none !important",
                            },
                          }}
                          startIcon={
                            <Box
                              component={"img"}
                              src={uploadIcon}
                              sx={{
                                height: "20px",
                                width: "20px",
                              }}
                            />
                          }
                          onClick={handleImageClick}
                        >
                          upload logo
                        </Button>
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
              {(profileSteps !== 2 || profileSteps === 2) && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "flex-end",
                    marginTop: "45px",
                    position: "absolute",
                    zIndex: "22",
                  }}
                >
                  <Button
                    sx={{
                      height: "45px",
                      width: "125px",
                      borderRadius: "0 0 10px 10px",
                      boxShadow: "0px 5px 10px #00000029",
                      fontSize: "14px",
                      padding: "4px 0px",
                      fontWeight: theme.typography.Bold,
                    }}
                    size="small"
                    variant="contained"
                    color="myInfoButton"
                    endIcon={
                      profileSteps === 1 || profileSteps === 2 ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )
                    }
                    onClick={() => {
                      if (profileSteps === 1 || profileSteps === 2) {
                        // setActiveButton(0);
                        dispatch(setProfileSteps(0));
                      } else {
                        // setActiveButton(1);
                        dispatch(setProfileSteps(1));
                      }
                    }}
                  >
                    {profileSteps === 2 ? "company info" : "my info"}
                  </Button>
                </Box>
              )}
            </Box>
          </ReactCardFlip>
        </Box>
      ) : (
        <Box
          sx={{
            width: {
              xs: "340px",
              xs390: "360px",
              sm: "90%",
              tablet: "100%",
              md24: "96.5%",
              lg: "50%",
              mlg: `calc(50% - 15px)`,
            },
            // width: {
            //   xs: "340px",
            //   xs390: "360px",
            //   sm: "90%",
            //   tablet: "100%",
            //   md24: "96.5%",
            //   lg: "50%",
            //   mlg: `calc(50% - 15px)`,
            // },
            // background: "#ffff",
            // boxShadow: "0px 5px 10px #00000029",
            // borderRadius: "25px 25px 25px 0px",
            // position: "relative",
            // display: "flex",
            // flexDirection: "column",
            // marginBottom: { xs: "30px", lg: "0px" },
            // minHeight: "225px",
            // order:"1"
          }}
        >
          <Box
            sx={{
              width: "100%",
              minWidth: "100%",
              background: "#ffff",
              boxShadow: "0px 5px 10px #00000029",
              borderRadius: "25px 25px 25px 0px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
              marginBottom: { xs: "30px", lg: "0px" },
              minHeight: "225px",
              height: {
                xs: "440px",
                tablet: "225px",
                lg: "225px",
                lg1440: "225px",
                mlg: "225px",
              },
            }}
          >
            <Box
              sx={{
                // display: "flex",
                // alignItems: "center",
                // flex: "1",
                // position: "relative",
                display: "flex",
                flex: "1",
                height: "100%",
                flexDirection: { xs: "column", tablet: "row" },
                alignItems: { xs: "baseline", tablet: "center" },
                paddingTop: { xs: "45px", tablet: "0px" },
              }}
            >
              {profileSteps !== 2 && (
                <Box
                  component={"img"}
                  className="companyLogo"
                  alt=""
                  src={
                    employerData?.profileURL !== "NO URL"
                      ? employerData?.profileURL
                      : companyLogo
                  }
                  sx={{
                    // height: { xs: "130px", tablet: "175px" },
                    // width: { xs: "130px", tablet: "175px" },
                    // minWidth: { xs: "130px", tablet: "175px" },
                    // margin: { xs: "10px", tablet: "20px" },
                    // marginBottom: { xs: "22px", tablet: "0" },
                    // borderRadius: "10px",
                    height: { xs: "175px", tablet: "175px" },
                    width: { xs: "175px", tablet: "175px" },
                    borderRadius: "10px",
                    margin: "24px 14px 25px 21px",
                    marginTop: { xs: "15px", tablet: "24px" },
                    marginBottom: { xs: "10px", tablet: "25px" },
                    marginLeft: { xs: "10px", xs390: "21px" },
                  }}
                />
              )}

              <Box
                sx={{
                  // width: "100%",
                  // display: "flex",
                  // gap: "9px",
                  // flexDirection: "column",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={profileIcon}
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                      wordBreak: "break-all",
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "14px", lg: "14px", xl: "20px" },
                      fontWeight: theme.typography.Bold,
                      wordBreak: "break-all",
                    }}
                  >
                    {`${employerData?.user?.first_name} ${employerData?.user?.surname}`}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={companyIcon}
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,
                      fontSize: "14px",
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {companies?.find(
                      (title) => title?.name === employerData?.company_name
                    )?.name ||
                      employerData?.company_name ||
                      "My Company"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={redLocation}
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,
                      fontSize: "14px",
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {employerData?.town_name}, {employerData?.country_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={calendarIcon}
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                  />
                  <Typography
                    sx={{
                      fontWeight: theme.typography.Bold,
                      fontSize: "14px",
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {/* Date joined:{" "} */}
                    {profileSteps === 2
                      ? compInfo?.created_at
                        ? dateConverterFullMonth(compInfo?.created_at)
                        : "-"
                      : employerData?.created_at
                      ? dateConverterFullMonth(employerData?.created_at)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  zIndex: "99",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    // marginBottom: "20px",
                  }}
                >
                  {profileSteps === 2 && (
                    <>
                      <input
                        ref={hiddenFileInput}
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          setImagePreview([]);
                          handleImageChange(event, "company");
                        }}
                        style={{ display: "none" }}
                      />
                      <Button
                        disabled={!editable}
                        variant="contained"
                        color="uploadPhotoButton"
                        sx={{
                          height: "45px",
                          width: "150px",
                          fontSize: {
                            xs: "14px",
                            md24: "14px",
                            xl: "14px",
                          },
                          padding: "6px 6px",
                          borderRadius: "10px 0px 25px 0px",
                          ":hover": {
                            boxShadow: "none !important",
                          },
                        }}
                        startIcon={
                          <Box
                            component={"img"}
                            src={uploadIcon}
                            sx={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        }
                        onClick={handleImageClick}
                      >
                        upload logo
                      </Button>
                    </>
                  )}
                  {profileSteps !== 2 && (
                    <>
                      <input
                        ref={hiddenFileInput}
                        type="file"
                        accept="image/*"
                        onChange={(event) => {
                          setImagePreview([]);
                          handleImageChange(event, "info");
                        }}
                        style={{ display: "none" }}
                      />
                      <Button
                        variant="contained"
                        color="uploadPhotoButton"
                        sx={{
                          height: "45px",
                          width: "150px",
                          fontSize: {
                            xs: "14px",
                            md24: "14px",
                            xl: "14px",
                          },
                          padding: "6px 6px",
                          borderRadius: "10px 0px 0px 0px",
                          ":hover": {
                            boxShadow: "none !important",
                          },
                        }}
                        startIcon={
                          <Box
                            component={"img"}
                            src={uploadIcon}
                            sx={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        }
                        onClick={handleImageClick}
                      >
                        {i18n["empMyProfile.uploadAlogo"]}
                      </Button>
                      <Button
                        variant="contained"
                        color="takePhotoButton"
                        sx={{
                          height: "45px",
                          width: "150px",
                          fontSize: {
                            xs: "14px",
                            md24: "14px",
                            xl: "14px",
                          },
                          borderRadius: "0px 0px 25px 0px",
                          ":hover": {
                            boxShadow: "none !important",
                          },
                        }}
                        startIcon={
                          <Box
                            component={"img"}
                            src={takePhotoIcon}
                            sx={{
                              height: "20px",
                              width: "20px",
                            }}
                          />
                        }
                        onClick={handlePhotoCapture}
                      >
                        {i18n["empMyProfile.takePhoto"]}
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </Box>
            {(profileSteps !== 2 || (profileSteps === 2 && width > 1365)) && (
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                  height: "100%",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "flex-end",
                  marginTop: "45px",
                  position: "absolute",
                  zIndex: "22",
                }}
              >
                <Button
                  sx={{
                    height: "45px",
                    width: "125px",
                    borderRadius: "0 0 10px 10px",
                    boxShadow: "0px 5px 10px #00000029",
                    fontSize: "14px",
                    padding: "4px 0px",
                    fontWeight: theme.typography.Bold,
                  }}
                  size="small"
                  variant="contained"
                  color="myInfoButton"
                  endIcon={
                    profileSteps === 1 || profileSteps === 2 ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )
                  }
                  onClick={() => {
                    if (profileSteps === 1 || profileSteps === 2) {
                      // setActiveButton(0);
                      dispatch(setProfileSteps(0));
                    } else {
                      // setActiveButton(1);
                      dispatch(setProfileSteps(1));
                    }
                  }}
                >
                  {profileSteps === 2 ? "company info" : "my info"}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          // minWidth: {
          //   xs: "340px",
          //   xs390: "360px",
          //   sm: "90%",
          //   tablet: "100%",
          //   md24: "96.5%",
          //   lg: "50%",
          //   mlg: `calc(50% - 15px)`,
          // },
          width: {
            xs: "340px",
            xs390: "360px",
            sm: "90%",
            tablet: "100%",
            md24: "96.5%",
            lg: "50%",
            mlg: `calc(50% - 15px)`,
          },
          marginBottom: {
            xs: profileSteps === 2 ? "30px" : "0px",
            // sm: "70px",
            lg: "0px",
          },
          minHeight: "225px",
          display: "flex",
          boxSizing: "border-box",
          flex: { xl1920: "1" },
          flexDirection: "column",
        }}
      >
        {profileSteps !== 2 ? (
          <Box sx={{ width: "100%" }}>
            <EmployerPersonalityFrontCard
              profileSteps={profileSteps}
              employerData={employerData}
              chartDetails={chartDetails}
              setisHorizontalFlipped={setisHorizontalFlipped}
              handleLeftClick={handleLeftClick}
              arrSlider2={arrSlider2}
              handleRightClick={handleRightClick}
              calculateHeight={calculateChartHeight}
            />
            {/* <ReactCardFlip
              isFlipped={isHorizontalFlipped}
              flipDirection={"horizontal"}
              flipSpeedBackToFront="0.5"
              flipSpeedFrontToBack="0.5"
              // key={job?.job_id}
            >
              <EmployerPersonalityFrontCard
                profileSteps={profileSteps}
                employerData={employerData}
                chartDetails={chartDetails}
                setisHorizontalFlipped={setisHorizontalFlipped}
                handleLeftClick={handleLeftClick}
                arrSlider2={arrSlider2}
                handleRightClick={handleRightClick}
                calculateHeight={calculateChartHeight}
              />
              <EmployerPersonalityBackCard
                employerData={employerData}
                setisHorizontalFlipped={setisHorizontalFlipped}
              />
            </ReactCardFlip> */}
          </Box>
        ) : (
          <Box
          //  sx={{ width: "100%" }}
          >
            <CompanyStats
              companyDetail={companyDetail}
              calculateHeight={calculateChartHeight}
            />
          </Box>
        )}
      </Box>

      {profileSteps === 3 && (
        <Box
          sx={{
            position: "relative",
            // order:{xs:"2",md24:"3",lg:"2",xl:"2"}
            width: {
              xs: "340px",
              xs390: "360px",
              sm: "97%",
              lg: "350px",
              mlg: "450px",
            },
            marginLeft: { lg: "10px", mlg: "35px" },
            marginBottom: { xs: "0px", lg: "0px" },
            minHeight: "225px",
            display: "flex",
            boxSizing: "border-box",
            flex: { xl1920: "1" },
          }}
        >
          {profileSteps === 2 && (
            <Box sx={{ width: "100%" }}>
              <ReactCardFlip
                isFlipped={isHorizontalFlipped}
                flipDirection={"horizontal"}
                flipSpeedBackToFront="0.5"
                flipSpeedFrontToBack="0.5"
                // key={job?.job_id}
              >
                <CompanyCultureFrontCard
                  compInfo={compInfo}
                  setisHorizontalFlipped={setisHorizontalFlipped}
                />
                <CompanyCultureBackCard
                  compInfo={compInfo}
                  setisHorizontalFlipped={setisHorizontalFlipped}
                />
              </ReactCardFlip>
            </Box>
          )}
          {profileSteps === 2 && width < 1365 && (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                marginTop: "45px",
                position: "absolute",
                zIndex: "22",
              }}
            >
              <Button
                sx={{
                  height: "45px",
                  width: "125px",
                  borderRadius: "0 0 10px 10px",
                  boxShadow: "0px 5px 10px #00000029",
                  fontSize: "14px",
                  padding: "4px 0px",
                  fontWeight: theme.typography.Bold,
                }}
                size="small"
                variant="contained"
                color="myInfoButton"
                endIcon={
                  profileSteps === 1 || profileSteps === 2 ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                }
                onClick={() => {
                  if (profileSteps === 1 || profileSteps === 2) {
                    // setActiveButton(0);
                    dispatch(setProfileSteps(0));
                  } else {
                    // setActiveButton(1);
                    dispatch(setProfileSteps(1));
                  }
                }}
              >
                {profileSteps === 2 ? "company info" : "my info"}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
