import React from "react";
import { Link } from "react-router-dom";
import { truncate } from "lodash";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import SmallButton from "./SmallButton";
import Slider2 from "./Slider2";
import job_logo from "../../assets/Crayon_Favicon.svg";
import post_incognito from "../../assets/Incognito.svg";
import upArrow from "../../assets/Black_Up_Close - Copy.svg";
import downArrow from "../../assets/Black_Down_Open - Copy.svg";
import CompanyLogo from "../../assets/Padding Excluded/Blue_Following_Company-.svg";
import CalendarIcon from "../../assets/Padding Included/Yellow_Calendar.svg";
import viewjobsIcon from "../../assets/Padding Excluded/Black_Linked_User_Databases.svg";
import CompanyLink from "../../assets/Padding Excluded/Black_World_Website.svg";
import { dateConverterMonth } from "../../utils/DateTime";
import locale from "../../i18n/locale";
import redLocation from "../../assets/Red_Location.svg";
import FollowIcon from "../../assets/Padding Excluded/Black_Company Followers_new.svg";
import playButtonIconWhite from "../../assets/Padding Excluded/Black_reactive_job.svg";
import { LightTooltip } from "../../utils/CommonComponent";
import ReactCardFlip from "react-card-flip";
import flip from "../../assets/NewFlipIcon.svg";

const JobDetailCompanyComponent = ({
  isHorizontalFlipped,
  job,
  theme,
  setVideoLink,
  setOpenReviewVideoDialog,
  descriptionRef,
  viewMoreExpanded,
  exportAsCSV,
  showMoreButton,
  handleViewMore,
  handleViewJobs,
  followComp,
  companyFollowed,
  setisFlipped,
}) => {
  const i18n = locale.en;

  return (
    <ReactCardFlip
      isFlipped={isHorizontalFlipped}
      flipDirection={"horizontal"}
      flipSpeedBackToFront="0.5"
      flipSpeedFrontToBack="0.5"
      key={job?.job_id}
    >
      <Grid
        sx={{
          // background: theme.palette.boxBackground,
          background: theme.palette.base.main,
          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
          paddingBottom: 0,
          borderRadius: "25px",
          position: "relative",
          minHeight: "380px",
          maxHeight: "380px",
          maxWidth: "360px",
          width: "360px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          // color="nandos"
          // color="#38A8AE"
          color="newFlipColor"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => setisFlipped(true)}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button>
        <Box
          width={"100%"}
          textAlign={"end"}
          sx={{
            display: "flex",
            justifyContent: "end",
            // marginBottom: "6px",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              height: "45px",
              color: "white",
              minWidth: "60px",
              display: "flex",
              fontSize: "12px",
              maxWidth: "60px",
              justifyContent: "center",
              borderRadius: "0 0 0 10px",
              paddingX: "0px",
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              background: theme.palette.precium.main,
            }}
          >
            {job?.company?.company_type ? job?.company?.company_type : "-"}
          </Box>
          <Box
            sx={{
              height: "45px",
              color: "white",
              minWidth: "60px",
              display: "flex",
              paddingX: "0px",
              fontSize: "12px",
              maxWidth: "60px",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: theme.typography.Bold,
              background: theme.palette.peachPayment.main,
            }}
          >
            {job?.company?.company_size ? job?.company?.company_size : "-"}
          </Box>
          <Button
            color="playButton"
            variant="contained"
            sx={{
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: "0 25px 0 0px",
              padding: 0,
              background:
                job?.company_video_url === "No URL" &&
                theme.palette.grayButton600.main,
              ":hover": {
                boxShadow: "none",
              },
            }}
            // disabled={job?.company_video_url === "No URL"}
            onClick={() => {
              if (job?.company_video_url !== "No URL") {
                setVideoLink(job?.company_video_url);
                setOpenReviewVideoDialog(true);
              }
            }}
          >
            <Box
              component={"img"}
              src={
                // job?.company_video_url !== "No URL"
                //   ? playButtonIconWhite
                //   : playButtonIcon
                playButtonIconWhite
              }
              sx={{
                height: "14px",
                width: "14px",
              }}
            />
          </Button>
        </Box>

        <Box
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            flex: "1",
            paddingBottom: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {job?.post_incognito && !job?.shortlistedflag ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "5px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "125px",
                    width: "125px",
                    borderRadius: "10px",
                  }}
                  //   alt="job_logo"
                  src={post_incognito}
                />
                <Tooltip disableInteractive>
                  <LightTooltip
                    title={i18n["popupBoxText.postIncognito"]}
                    placement="right"
                  >
                    <Typography
                      sx={{
                        mt: "2px",
                        cursor: "pointer",
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        /* text-align: center; */
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                      }}
                    >
                      i
                    </Typography>
                  </LightTooltip>
                </Tooltip>
              </Box>
            ) : (
              <Link
                to={`/jobs/company/${job?.company_id}`}
                target={"_blank"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "125px",
                    width: "125px",
                    borderRadius: "10px",
                  }}
                  //   alt="job_logo"
                  src={
                    job?.profile_url !== "No URL" ? job?.profile_url : job_logo
                  }
                />
              </Link>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "12px",
                  gap: 1,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 18,
                    width: 18,
                    maxHeight: { xs: 28 },
                    maxWidth: { xs: 28 },
                  }}
                  alt="company"
                  src={CompanyLogo}
                />
                {job?.post_incognito && !job?.shortlistedflag ? (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "20px",
                      letterSpacing: "0.36px",
                      mt: 0.5,
                      // width: { xs: "60%", lg: "70%", xl: "80%" },
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    noWrap={true}
                  >
                    {"incognito"}
                  </Typography>
                ) : (
                  <Link
                    to={`/jobs/company/${job?.company_id}`}
                    target={"_blank"}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.black,
                      width: "90%",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: theme?.typography?.Bold,
                        fontSize: "20px",
                        letterSpacing: "0.36px",
                        mt: 0.5,
                        // width: { xs: "60%", lg: "70%", xl: "80%" },
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      noWrap={true}
                    >
                      {job?.company?.name ? job?.company?.name : "-"}
                    </Typography>
                  </Link>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "12px",
                  gap: "10px",
                }}
              >
                <Box
                  component={"img"}
                  src={redLocation}
                  sx={{
                    height: 16,
                    width: 16,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 16 },
                    marginLeft: "-2px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: theme?.typography?.Bold,
                    fontSize: "14px",
                    letterSpacing: "0px",
                    //   opacity: 0.8,
                    mt: 0.5,
                    width: "90%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  noWrap
                >
                  {job?.company?.town?.name || "-"}
                  {job?.company?.town?.name &&
                    `, ${job?.company?.town?.country?.name} `}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "12px",
                  gap: "10px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 18,
                    width: 18,
                    maxHeight: { xs: 28 },
                    maxWidth: { xs: 28 },
                  }}
                  alt="company link"
                  src={CompanyLink}
                />

                <Typography
                  sx={{
                    fontWeight: theme?.typography?.Bold,
                    fontSize: "14px",
                    letterSpacing: "0px",
                    mt: 0.5,
                    width: "90%",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                  noWrap
                >
                  {job?.company?.website !== "anonymous" ? (
                    <Tooltip
                      disableInteractive
                      title={job?.company?.website}
                      placement="top"
                    >
                      <Link
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                        target="_blank"
                        to={job?.company?.website} // Removed the optional chaining operator (?.)
                      >
                        {job?.company?.website}
                      </Link>
                    </Tooltip>
                  ) : job?.post_incognito ? (
                    <span
                      style={{
                        textDecoration: "none",
                        color: "black",
                      }}
                    >
                      {"incognito"}
                    </span>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  height: "12px",
                  gap: 1,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 18,
                    width: 18,
                    maxHeight: { xs: 28 },
                    maxWidth: { xs: 28 },
                  }}
                  alt="calendar"
                  src={CalendarIcon}
                />
                <Tooltip
                  disableInteractive
                  title={
                    job?.post_incognito && !job?.shortlistedflag
                      ? "incognito"
                      : dateConverterMonth(job?.company?.created_at)
                  }
                  placement="top"
                >
                  <Typography
                    sx={{
                      fontWeight: theme?.typography?.Bold,
                      fontSize: "14px",
                      letterSpacing: "0px",
                      mt: 0.5,
                      width: "90%",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    noWrap
                  >
                    {/* Date joined: */}
                    {job?.post_incognito && !job?.shortlistedflag
                      ? "incognito"
                      : job?.company?.created_at
                      ? dateConverterMonth(job?.company?.created_at)
                      : "-"}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          {/* <Box
            sx={{
              display: "flex",
              gap: "5px",
            }}
          >
            {job?.company?.company_type && (
              <SmallButton
                color={"companyTypeButton"}
                height={25}
                value={job?.company?.company_type}
                label={truncate(job?.company?.company_type, {
                  length: 12,
                })}
              />
            )}
            {job?.company?.company_size && (
              <SmallButton
                color={"companySizeButton"}
                height={25}
                value={job?.company?.company_size}
                label={truncate(job?.company?.company_size, {
                  length: 12,
                })}
              />
            )}
          </Box> */}
          <Slider2
            type={"listArray"}
            items={job?.company?.employer_industries}
            color={"tymeColor"}
            hideTagsAfter={2}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            height: "60px",
            margin: "auto",
          }}
        >
          <Button
            disabled={job?.post_incognito && !job?.shortlistedflag}
            variant="contained"
            color="tymeColor"
            sx={{
              borderRadius: "0 0 0 25px",
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              // fontWeight: "bold",
              height: "100%",
              width: "50%",
              ":hover": {
                boxShadow: 0,
              },
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 25,
                  width: 25,
                }}
                src={viewjobsIcon}
              />
            }
            onClick={() => handleViewJobs(job?.company?.company_id)}
          >
            view biz jobs
          </Button>
          <Button
            disabled={job?.post_incognito && !job?.shortlistedflag}
            variant="contained"
            color="nandos"
            sx={{
              borderRadius: "0 0 25px 0 ",
              fontWeight: theme.typography.Bold,
              fontSize: "14px",
              height: "100%",
              width: "50%",
              ":hover": {
                boxShadow: 0,
              },
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 20,
                  width: 20,
                }}
                src={FollowIcon}
              />
            }
            onClick={() => followComp(job?.company?.company_id)}
          >
            {companyFollowed ? "following" : "follow"}
          </Button>
        </Box>
      </Grid>

      <Grid
        sx={{
          // background: theme.palette.boxBackground,
          background: theme.palette.base.main,
          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
          paddingBottom: 0,
          borderRadius: "25px",
          position: "relative",
          minHeight: "380px",
          // maxHeight: "380px",
          maxWidth: "360px",
          width: "360px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          variant="contained"
          // color="nandos"
          // color="#38A8AE"
          color="newFlipColor"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => setisFlipped(false)}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button>
        <Box
          width={"100%"}
          textAlign={"end"}
          sx={{
            display: "flex",
            justifyContent: "end",
            // marginBottom: "6px",
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <Box
            sx={{
              height: "45px",
              color: "white",
              minWidth: "60px",
              display: "flex",
              fontSize: "12px",
              maxWidth: "60px",
              justifyContent: "center",
              borderRadius: "0 0 0 10px",
              paddingX: "0px",
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              background: theme.palette.precium.main,
            }}
          >
            {job?.company?.company_type ? job?.company?.company_type : "-"}
          </Box>
          <Box
            sx={{
              height: "45px",
              color: "white",
              minWidth: "60px",
              display: "flex",
              paddingX: "0px",
              fontSize: "12px",
              maxWidth: "60px",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: theme.typography.Bold,
              background: theme.palette.peachPayment.main,
            }}
          >
            {job?.company?.company_size ? job?.company?.company_size : "-"}
          </Box>
          <Button
            color="playButton"
            variant="contained"
            sx={{
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: "0 25px 0 0px",
              padding: 0,
              background:
                job?.company_video_url === "No URL" &&
                theme.palette.grayButton600.main,
              ":hover": {
                boxShadow: "none",
              },
            }}
            // disabled={job?.company_video_url === "No URL"}
            onClick={() => {
              if (job?.company_video_url !== "No URL") {
                setVideoLink(job?.company_video_url);
                setOpenReviewVideoDialog(true);
              }
            }}
          >
            <Box
              component={"img"}
              src={
                // job?.company_video_url !== "No URL"
                //   ? playButtonIconWhite
                //   : playButtonIcon
                playButtonIconWhite
              }
              sx={{
                height: "14px",
                width: "14px",
              }}
            />
          </Button>
        </Box>

        <Box
          sx={{
            padding: 3,
            display: "flex",
            flexDirection: "column",
            // gap: "10px",
            flex: "1",
            paddingBottom: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              //   gap: 2,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {job?.post_incognito && !job?.shortlistedflag ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "5px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "125px",
                    width: "125px",
                    borderRadius: "10px",
                  }}
                  //   alt="job_logo"
                  src={post_incognito}
                />
                <Tooltip disableInteractive>
                  <LightTooltip
                    title={i18n["popupBoxText.postIncognito"]}
                    placement="right"
                  >
                    <Typography
                      sx={{
                        mt: "2px",
                        cursor: "pointer",
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        /* text-align: center; */
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                      }}
                    >
                      i
                    </Typography>
                  </LightTooltip>
                </Tooltip>
              </Box>
            ) : (
              <Link
                to={`/jobs/company/${job?.company_id}`}
                target={"_blank"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "125px",
                    width: "125px",
                    borderRadius: "10px",
                  }}
                  //   alt="job_logo"
                  src={
                    job?.profile_url !== "No URL" ? job?.profile_url : job_logo
                  }
                />
              </Link>
            )}
          </Box>

          <Grid sx={{}}>
            <Typography
              sx={{
                fontWeight: "Bold",
                fontSize: 12,
                letterSpacing: "0.75px",
              }}
            >
              Company bio
            </Typography>
            <Typography
              ref={descriptionRef}
              sx={{
                fontWeight: "Medium",
                fontSize: 12,
                letterSpacing: "0px",
                lineHeight: "1.6",
                overflow: viewMoreExpanded ? "scroll" : "hidden",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: viewMoreExpanded || exportAsCSV ? "none" : 4,
                wordBreak: "break-word",
                maxHeight:
                  viewMoreExpanded || exportAsCSV ? "none" : "calc(4 * 1.6em)", // 4 lines times line height
              }}
            >
              {job?.company?.description}
            </Typography>
            {showMoreButton && !exportAsCSV && (
              <Button
                sx={{
                  color: "black",
                  padding: 0,
                  height: "30px",
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      cursor: "pointer",
                    }}
                    alt="crayon logo"
                    src={viewMoreExpanded ? upArrow : downArrow}
                  />
                }
                onClick={handleViewMore}
              >
                {viewMoreExpanded ? "less" : "more"}
              </Button>
            )}
          </Grid>
        </Box>
        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={{ml:"10px"}}>
          <Slider2
            type={"culture"}
            items={job?.company?.employer_cultures}
            color={"precium"}
            hideTagsAfter={2}
          />
          </Box>
        
          <Box
            sx={{
              width: "100%",
              height: "60px",
              margin: "auto",
            }}
          >
            <Button
              disabled={job?.post_incognito && !job?.shortlistedflag}
              variant="contained"
              color="tymeColor"
              sx={{
                borderRadius: "0 0 0 25px",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
                height: "100%",
                width: "50%",
                ":hover": {
                  boxShadow: 0,
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                  src={viewjobsIcon}
                />
              }
              onClick={() => handleViewJobs(job?.company?.company_id)}
            >
              view biz jobs
            </Button>
            <Button
              disabled={job?.post_incognito && !job?.shortlistedflag}
              variant="contained"
              color="nandos"
              sx={{
                borderRadius: "0 0 25px 0 ",
                fontWeight: theme.typography.Bold,
                fontSize: "14px",
                height: "100%",
                width: "50%",
                ":hover": {
                  boxShadow: 0,
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  src={FollowIcon}
                />
              }
              onClick={() => followComp(job?.company?.company_id)}
            >
              {companyFollowed ? "following" : "follow"}
            </Button>
          </Box>
        </Box>
      </Grid>
    </ReactCardFlip>
  );
};

export default JobDetailCompanyComponent;
