import { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import profileURL from "../../../assets/Crayon_Favicon.svg";
import job_volume from "../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import profileIcon from "../../../assets/Padding Excluded/Black_Talent copy.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import ButtonMenu from "./ButtonMenu";
import {
  convertDatetimeAgo,
  dateConverterMonth,
  trackLayoutButtonDurationCalculator,
} from "../../../utils/DateTime";
import { Card, Tooltip } from "@mui/material";
import ManageButtonMenu from "./ManageButtonMenu";
import { Link } from "react-router-dom";
import { formatCurrencyWithCommas } from "../../../utils/Currency";

import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";

import flip from "../../../assets/NewFlipIcon.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";

import TrackButton from "./TrackButton";
import TalentIcon from "../../../assets/Padding Included/White_Talent.svg";
import TalentIconBlack from "../../../assets/Padding Included/White_Talent_black.svg";
import submitCandidate from "../../../assets/Padding Excluded/SubmitCandidate.svg";
import MUIRadialChart from "../MUIRadialChart";
import TrackButtonLayout from "./TrackButtonLayout";
import { daysRemainingToComplete30Days } from "../../../utils/DateTime";
import { Circle } from "@mui/icons-material";
import TextWrapper from "../TextWrapper";
import {
  getDecodedToken,
  getToken,
  renderColor,
  splitString,
  useQuery,
} from "../../../utils/Common";
import jwt_decode from "jwt-decode";
import SmallButton from "../SmallButton";
import { truncate } from "lodash";
import {
  ALERT_TYPE,
  APPLICANT,
  APPLIED,
  INTERVIEW,
  MATCHED,
  SHORTLIST,
} from "../../../utils/Constants";
import SubmitCandidate from "./SubmitCandidate";
import NewSlider from "../NewSlider";
import JobPromoteCard from "../../promoter/JobPromoteCard";
import post_incognito from "../../../assets/Incognito.svg";
import { LightTooltip } from "../../../utils/CommonComponent";
import { setAlert } from "../../../redux/configSlice";
import { useDispatch } from "react-redux";
import lockIcon from "../../../assets/Padding Excluded/Black_Lock.svg";
import externalLink from "../../../assets/Padding Excluded/External_Link.svg";
import greyExternalLink from "../../../assets/WhiteIcons/Grey_External_Link.svg";
import unlockIcon from "../../../assets/Padding Excluded/White_Unlock.svg";
import unlockIconBlack from "../../../assets/Padding Excluded/White_Unlock_black.svg";
import JobsSlider from "../../guest/jobs/JobsSlider";

export default function MyJobsCard({
  index,
  job,
  setisFlipped,
  handleReviewVideoDialog,
  newCardWidth,
  hasJobVideo,
  setOpenRecordDialog,
  jobslength,
  indexNumber,
  currentPageJobsCount,
  setShowLogin,
  allJobs,
  setAllJobs,
  setCoinSpend,
  setJobExtend,
  setSelectedJob,
  setGetCoins,
  setNoCoins,
  setOpenExternal,
}) {
  const i18n = locale.en;
  const dispatch = useDispatch();

  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  const planType = decodedToken?.data?.plan_type;
  const [buttonMenu, setButtonMenu] = useState(false);
  const [trackButton, setTrackButton] = useState(false);
  const [isSubmitCandidate, setIsSubmitCandidate] = useState(false);
  const [cardWidth, setCardWidth] = useState();
  const [jobPromote, setJobPromote] = useState(false);
  // const industries = job?.industry.map((industry) => industry.industry_name);
  const industries = splitString(job?.JobIndustries);
  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 90;
    } else if (screenWidth < 900) {
      // sm
      return 90;
    } else if (screenWidth < 1366) {
      // md
      return 90;
    } else if (screenWidth < 1920) {
      // lg
      return 90;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };
  const getDescriptionText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv?.textContent || tempDiv?.innerText;
  };

  const handleVideoButton = (video) => {
    !video && handleReviewVideoDialog(true);
  };

  const getCardWidth = () => {
    const cardElement = document.querySelector(".sliderClass");
    if (cardElement) {
      const cardWidth = cardElement?.clientWidth;
      setCardWidth(cardWidth);
    }
    return null; // Return null if the card element is not found
  };

  const handleSubmitCandidate = () => {
    if (job?.jobStatusName === "active") {
      setIsSubmitCandidate(true);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Job is not active",
        })
      );
    }
  };

  const unlockManageTalent = () => {
    if (job?.job_unlock || decodedToken?.data?.talent_plan) {
      if (decodedToken?.data?.role_id === 6) {
        window.open(`/recruiter/manage-talent/${job?.job_id}`, "_blank");
      } else {
        window.open(`/employer/manage-talent/${job?.job_id}`, "_blank");
      }
    } else {
      setSelectedJob(job);
      setGetCoins(true);
    }
  };

  useEffect(() => {
    getCardWidth();
    window.addEventListener("resize", getCardWidth);
    return () => {
      window.removeEventListener("resize", getCardWidth);
    };
  }, [cardWidth]);

  return (
    <Card
      sx={{
        borderRadius: "25px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        boxShadow: "0px 5px 10px #00000029",
      }}
      className="sliderClass"
    >
      {!isSubmitCandidate && !jobPromote && (
        <>
          <Grid
            container
            // padding={1}
            justifyContent="space-between"
            // alignItems="center"
            overflow={"hidden"}
            sx={{
              borderRadius: "25px 25px 0 0",
              width: "100%",
              flexWrap: "nowrap",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "20%" }}>
                  <Button
                    color="accessListButton"
                    sx={{
                      borderRadius: "25px 0 10px 0",
                      padding: "7px 12px 10px 12px",
                      height: "45px",
                      minWidth: "45px !important",
                      border: "none",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      setTrackButton(false);
                      setButtonMenu((prevState) => !prevState);
                    }}
                    variant="contained"
                  >
                    <Box
                      component={"img"}
                      src={profileIcon}
                      sx={{
                        height: 16,
                        width: 20,
                      }}
                    />
                    <Box
                      component={"img"}
                      src={buttonMenu ? upArrow : downArrow}
                      sx={{
                        height: 25,
                        width: 25,
                        position: "absolute",
                        top: "17px",
                      }}
                    />
                  </Button>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "240px",
                  }}
                >
                  <>
                    <Box sx={{ display: "flex", paddingBottom: "7px" }}>
                      <Button
                        color={`${job?.job_type}Button`}
                        variant="contained"
                        sx={{
                          height: "auto",
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: "0 0 0 10px",
                          padding: 0,
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          cursor: "default",
                          ":hover": {
                            boxShadow: 0,
                          },
                        }}
                      >
                      {job?.job_type}
                      </Button>
                      <Button
                        sx={{
                          height: "auto",
                          // minWidth: { xs: "40xp", lg: "50px", xl: "60px" },
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: 0,
                          padding: 0,
                          // fontSize: { xs: "8px", lg: "10px", xl: "12px" },
                          fontSize: theme.typography.fontSize.xs,
                          fontWeight: theme.typography.Bold,
                          background:
                            theme.manageTalent?.[renderColor(job?.stageName)]
                              ?.main,
                          color: "white",
                          cursor: "default",
                          ":hover": {
                            background:
                              theme.manageTalent?.[renderColor(job?.stageName)]
                                ?.main,
                          },
                        }}
                      >
                        {job?.stageName || "-"}
                      </Button>
                      <Button
                        variant="contained"
                        color="announceButton"
                        sx={{
                          height: "45px",
                          // minWidth: { xs: "40px", lg: "50px", xl: "60px" },
                          minWidth: "60px",
                          maxHeight: { xs: "60px" },
                          maxWidth: { xs: "60px" },
                          borderRadius: 0,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          ":hover": { boxShadow: 0 },
                        }}
                        onClick={() => setJobPromote(true)}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: 30,
                            width: 30,
                            maxHeight: { xs: 30 },
                            maxWidth: { xs: 30 },
                          }}
                          alt="job_volume"
                          src={job_volume}
                        />
                      </Button>
                      <Button
                        color="playButton"
                        variant="contained"
                        sx={{
                          // position: "absolute",
                          // right: 0,
                          // top: "72px",
                          zIndex: 10,
                          height: "auto",
                          minWidth: "60px",
                          minHeight: "45px",
                          borderRadius: 0,
                          // borderBottomLeftRadius: "10px",
                          background:
                            job?.job_video === null &&
                            job?.job_video_link === null &&
                            theme.palette.grayButton600.main,
                          padding: 0,
                          ":hover": {
                            boxShadow: "none",
                          },
                        }}
                        // disabled={!job?.job_video}
                        onClick={() =>
                          !(job?.job_video || job?.job_video_link)
                            ? setOpenRecordDialog(true)
                            : handleVideoButton(
                                !(job?.job_video || job?.job_video_link)
                              )
                        }
                      >
                        <Box
                          component={"img"}
                          src={
                            // job?.job_video !== null
                            //   ? playButtonIconWhite
                            //   : playButtonIcon
                            playButtonIconWhite
                          }
                          sx={{
                            height: "16px",
                            width: "20px",
                          }}
                        />
                      </Button>
                    </Box>
                    {!trackButton && !buttonMenu && (
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Regular,
                          fontSize: theme.typography.fontSize.xs,
                          width: "100%",
                          whiteSpace: "nowrap", // Prevents text from wrapping
                          overflow: "hidden", // Hides any overflowing content
                          textOverflow: "ellipsis",
                          color: theme.palette.grayButton600.main,
                          lineHeight: "16px",
                        }}
                      >
                        posted {convertDatetimeAgo(job?.created_at)} ago
                      </Typography>
                    )}
                  </>
                </Box>
              </Box>

              {trackButton && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    paddingLeft: "19px",
                    paddingTop: "10px",
                    lineHeight: "19px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                      lineHeight: "19px",
                    }}
                  >
                    {i18n["empMyCam.whatsJobStatus"]}
                  </Typography>
                </Box>
              )}
              {buttonMenu && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    paddingLeft: "19px",
                    paddingTop: "10px",
                    lineHeight: "19px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Bold,
                      lineHeight: "19px",
                    }}
                  >
                    {i18n["empMyCam.whoCanAccess"]}
                  </Typography>
                </Box>
              )}
            </Box>

            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                width: "45px",
                height: "71px",
                flexDirection: "column",
                // border: !trackButton
                //   ? `1px solid ${theme.palette.chart.gray} `
                //   : "none",
                borderTop: 0,
                borderRight: 0,
                borderRadius: "0 0px 0px 10px",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  padding: 0,
                  boxShadow: "none !important",
                  height: "45px !important",
                  borderRadius: 0,
                  minWidth: "100% !important",
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setButtonMenu(false);
                  setTrackButton((prevState) => !prevState);
                }}
                color="trackButton"
              >
                <TrackButtonLayout
                  daysReamining={trackLayoutButtonDurationCalculator(job?.job_expire_date)}
                  job={job}
                />
                <Box
                  component={"img"}
                  src={trackButton ? upArrow : downArrow}
                  sx={{
                    height: 25,
                    width: 25,
                  }}
                />
              </Button>
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  fontWeight: theme.typography.Bold,
                  fontSize: theme.typography.fontSize.sm,
                  margin: "auto",
                  zIndex: 9,
                  position: "absolute",
                  right: "17px",
                  bottom: "0px",
                }}
              >
                {job?.jobStatusName || "Status"}{" "}
                <Circle
                  fontSize="string"
                  color={`${job?.jobStatusName}Status`}
                />
              </Typography>
            </Box>
          </Grid>

          {buttonMenu && (
            <ButtonMenu
              jobId={job?.job_id}
              closeFunc={setButtonMenu}
              newCardWidth={newCardWidth}
            />
          )}
          {trackButton && (
            <TrackButton
              theme={theme}
              closeFunc={setTrackButton}
              jobCreationDate={job?.job_expire_date}
              newCardWidth={newCardWidth}
              setJobExtend={setJobExtend}
              setGetCoins={setGetCoins}
              setSelectedJob={() => setSelectedJob(job)}
              status={job?.jobStatusName}
            />
          )}
          {!buttonMenu && !trackButton && (
            <>
              <Box
                sx={{
                  // display: "flex",
                  width: "100%",
                  // height: "294px",
                  // flexGrow: 1,
                }}
              >
                <Grid
                  sx={{
                    // flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    padding: "0 20px 0 15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      minHeight: "67px",
                      // maxWidth:
                      //   (job?.profile_url !== "No URL" &&
                      //     job?.post_incognito) ||
                      //   job?.profile_url === "DEFAULT URL"
                      //     ? `calc(${newCardWidth - 80}px)`
                      //     : `calc(${newCardWidth - 80}px)`,
                      maxWidth: "100%",
                    }}
                  >
                    {job?.profile_url !== "No URL" && job?.post_incognito ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "5px",
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "60px",
                            width: "60px",
                            maxHeight: { xs: "60px" },
                            maxWidth: { xs: "60px" },
                            borderRadius: "10px",
                          }}
                          alt="job_logo"
                          src={post_incognito}
                        />
                        <Tooltip disableInteractive>
                          <LightTooltip
                            title={i18n["popupBoxText.postIncognito"]}
                            placement="right"
                          >
                            <Typography
                              sx={{
                                mt: "4px",
                                cursor: "pointer",
                                height: "17px",
                                width: "17px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                lineHeight: 0.1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontWeight: "bold", // Corrected here
                                boxShadow: "0px 2px 4px #00000029",
                                border: 1,
                              }}
                            >
                              i
                            </Typography>
                          </LightTooltip>
                        </Tooltip>
                      </Box>
                    ) : job?.profile_url === "DEFAULT URL" ? (
                      <Link
                        to={`/jobs/company/${job?.company_id}`}
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Box
                          component="img"
                          sx={{
                            height: "60px",
                            width: "60px",
                            maxHeight: { xs: "60px" },
                            maxWidth: { xs: "60px" },
                            borderRadius: "10px",
                          }}
                          alt="job_logo"
                          src={profileURL}
                        />
                      </Link>
                    ) : (
                      job?.profile_url !== "No URL" && (
                        <Link
                          to={`/jobs/company/${job?.company_id}`}
                          target={"_blank"}
                          style={{
                            textDecoration: "none",
                            color: theme.palette.black,
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: "60px",
                              width: "60px",
                              maxHeight: { xs: "60px" },
                              maxWidth: { xs: "60px" },
                              borderRadius: "10px",
                            }}
                            alt="job_logo"
                            src={job?.profile_url}
                          />
                        </Link>
                      )
                    )}

                    <Tooltip
                      disableInteractive
                      arrow
                      // TransitionComponent={"Fade"}
                      // TransitionProps={{ timeout: 600 }}
                      title={job?.title}
                      placement="top"
                    >
                      <Link
                        to={
                          job?.post_incognito
                            ? `/jobs/${job?.country_name?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.townName?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.title?.replace(/\s|\//g, "")}/${
                                job?.job_id
                              }`
                            : `/jobs/${job?.country_name?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.townName?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.CompanyName?.replace(
                                /\s|\//g,
                                ""
                              )}/${job?.title?.replace(/\s|\//g, "")}/${
                                job?.job_id
                              }`
                        }
                        target={"_blank"}
                        style={{
                          textDecoration: "none",
                          color: theme.palette.black,
                        }}
                      >
                        <Typography
                          sx={{
                            // minHeight: "60px",
                            wordBreak: "break-all",
                            fontWeight: theme.typography.Bold,
                            fontSize: theme.typography.fontSize.xl,
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            WebkitLineClamp: 1,
                            zIndex: -999,
                          }}
                        >
                          {job?.title}
                        </Typography>
                      </Link>
                    </Tooltip>
                    <Box
                      component={"img"}
                      src={job?.external_link ? externalLink : greyExternalLink}
                      sx={{
                        height: "30px",
                        width: "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenExternal(true);
                        setSelectedJob(job);
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="currency"
                        src={BlueCurrency}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: theme.typography.fontSize.sm,
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.salaryMin === 0 ? (
                          <>
                            {job?.currencySymbol}
                            {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                            {job?.type === "freelance"
                              ? "per hour"
                              : "per month"}
                          </>
                        ) : job?.currencySymbol ||
                          job?.salaryMin ||
                          job?.salaryMax ? (
                          <>
                            {job?.currencySymbol}
                            {formatCurrencyWithCommas(job?.salaryMin)} to{" "}
                            {job?.currencySymbol}
                            {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                            {job?.type === "freelance"
                              ? "per hour"
                              : "per month"}
                          </>
                        ) : (
                          "-"
                        )}
                        {/* {job?.currencySymbol}
                    {formatCurrencyWithCommas(job?.salaryMin)} to{" "}
                    {job?.currencySymbol}
                    {formatCurrencyWithCommas(job?.salaryMax)} per month */}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="location"
                        src={redLocation}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: theme.typography.fontSize.sm,
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.townName}, {job?.country_name}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="job_exp"
                        src={job_exp}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: "14px",
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.experienceYearStart} to {job?.experienceYearEnd}{" "}
                        years
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        height: "19px",
                      }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: 16,
                          width: 15,
                          maxHeight: { xs: 16 },
                          maxWidth: { xs: 15 },
                        }}
                        alt="calendar"
                        src={calendar}
                      />
                      <Typography
                        sx={{
                          fontWeight: theme.typography.Bold,
                          fontSize: "14px",
                          letterSpacing: "0.25px",
                        }}
                      >
                        {job?.job_start_date
                          ? dateConverterMonth(job?.job_start_date)
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      height: "60px",
                      padding: "15px 0 7px 0px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "5px",
                      }}
                    >
                      {job?.type && (
                        <SmallButton
                          color={"jobTypeButton"}
                          fontSize={12}
                          height={25}
                          value={job?.type}
                          label={truncate(job?.type, { length: 12 })}
                        />
                      )}
                      {job?.work_setup && (
                        <SmallButton
                          color={"workSetupButton"}
                          fontSize={12}
                          height={25}
                          value={job?.work_setup}
                          label={truncate(job?.work_setup, { length: 12 })}
                        />
                      )}
                    </Box>
                    <JobsSlider
                      items={
                        industries?.sort((a, b) => a.length - b.length) || []
                      }
                      color={"industriesButton"}
                      hideTagsAfter={3}
                      height="25px"
                      job={job}
                      index={indexNumber}
                      jobslength={jobslength}
                      id={job?.job_id}
                      currentPageJobsCount={currentPageJobsCount}
                    />
                  </Box>
                  {/* <JobDescripiton description={job?.description} height={"60px"} /> */}
                  <TextWrapper
                    height={"66px"}
                    size={12}
                    color={theme.palette.black100}
                    letterSpacing="0.25px"
                    lineHeight={"21px"}
                    margin="0px !important"
                  >
                    {job?.job_bio
                      ? getDescriptionText(job?.job_bio)
                      : getDescriptionText(job?.description)}
                  </TextWrapper>
                </Grid>
              </Box>
              <Grid
                container
                width={"100%"}
                justifyContent="space-between"
                alignItems={"center"}
                // marginBottom={"15px"}
                padding={"0px 12px 14px 14px"}
              >
                <MUIRadialChart
                  chartName={MATCHED}
                  value={job?.Matched_Column_Count}
                  max={500}
                  size={calculateHeight()}
                  color={"matched"}
                  countFontSize={16}
                  labelFontSize={14}
                />
                <MUIRadialChart
                  chartName={APPLIED}
                  value={job?.TotalUserCount}
                  max={500}
                  size={calculateHeight()}
                  color={"application"}
                  countFontSize={16}
                  labelFontSize={14}
                />
                <MUIRadialChart
                  chartName={SHORTLIST}
                  value={job?.TotalUserShorlisted}
                  size={calculateHeight()}
                  max={500}
                  color={"employerShortListed"}
                  countFontSize={16}
                  labelFontSize={14}
                />
              </Grid>
              <Grid
                container
                // padding="0 8px 8px 8px"
                alignItems="center"
                overflow={"hidden"}
                sx={{
                  width: "101%",
                  borderRadius: "0 0 25px 25px",
                  height: "60px",
                }}
              >
                {job?.assignedFlag && decodedToken?.data?.role_id === 6 ? (
                  <>
                    <Box sx={{ width: "33.33%" }}>
                      <Button
                        sx={{
                          boxShadow: 0,
                          fontSize: "14px",
                          width: "100%",
                          height: "60px",
                          borderRadius: "0 0 0 0",
                          color: "black",
                        }}
                        variant="contained"
                        color="submitButton"
                        onClick={() => handleSubmitCandidate()}
                        startIcon={
                          <Box
                            component={"img"}
                            src={submitCandidate}
                            sx={{
                              height: "25px",
                              width: "25px",
                            }}
                          />
                        }
                      >
                        submit
                      </Button>
                    </Box>
                    <Box sx={{ width: "66.67%" }}>
                      <Link
                        to={
                          decodedToken?.data?.role_id === 6
                            ? `/recruiter/manage-talent/${job?.job_id}`
                            : `/employer/manage-talent/${job?.job_id}`
                        }
                        target="_blank"
                        style={{
                          textDecoration: "none",
                        }}
                      >
                        <Button
                          sx={{
                            boxShadow: 0,
                            fontSize: "14px",
                            width: "100%",
                            height: "60px",
                            borderRadius: "0 0 25px 0",
                          }}
                          variant="contained"
                          color="talentButton"
                          // onClick={() => showManageJob()}

                          startIcon={
                            <Box
                              component={"img"}
                              src={TalentIcon}
                              sx={{
                                height: "25px",
                                width: "25px",
                              }}
                            />
                          }
                        >
                          {i18n["manageJob.trackTalent"]}
                        </Button>
                      </Link>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box sx={{ width: "50%" }}>
                      <ManageButtonMenu job={job} cardWidth={cardWidth} />
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      {job?.pendingReq ? (
                        <Button
                          sx={{
                            boxShadow: 0,
                            fontSize: theme.typography.fontSize.sm,
                            width: "100%",
                            height: "60px",
                            cursor:"default",
                            borderRadius: "0 0 25px 0",
                            padding: "21px 5px 17px 5px",
                            "& .MuiButton-startIcon": {
                              paddingRight: "5px",
                              margin: "0px",
                            },
                            "& .MuiButton-endIcon": {
                              paddingLeft: "5px",
                              margin: "0px",
                            },
                          }}
                          variant="contained"
                          color="newSubmit"
                          onClick={() => {}}
                          startIcon={
                            <Box
                              component={"img"}
                              src={TalentIconBlack}
                              sx={{
                                height: "21px",
                                width: "20px",
                              }}
                            />
                          }
                          endIcon={
                            <Box
                              component={"img"}
                              src={unlockIconBlack}
                              sx={{
                                height: "20px",
                                width: "13px",
                              }}
                            />
                          }
                        >
                          unlock pending
                        </Button>
                      ) : (
                        <Button
                          sx={{
                            boxShadow: 0,
                            fontSize: theme.typography.fontSize.sm,
                            width: "100%",
                            height: "60px",
                            borderRadius: "0 0 25px 0",
                            padding: "21px 20px 17px 29px",
                            "& .MuiButton-startIcon": {
                              paddingRight: "15px",
                              margin: "0px",
                            },
                            "& .MuiButton-endIcon": {
                              paddingLeft: "15px",
                              margin: "0px",
                            },
                          }}
                          variant="contained"
                          color="talentButton"
                          onClick={() => unlockManageTalent()}
                          startIcon={
                            <Box
                              component={"img"}
                              src={TalentIcon}
                              sx={{
                                height: "21px",
                                width: "20px",
                              }}
                            />
                          }
                          endIcon={
                            <Box
                              component={"img"}
                              src={
                               ( job?.job_unlock ||
                                decodedToken?.data?.talent_plan)
                                  ? unlockIcon
                                  : lockIcon
                              }
                              sx={{
                                height: "20px",
                                width: "13px",
                              }}
                            />
                          }
                        >
                          {i18n["manageJob.talentBtn"]}
                        </Button>
                      )}
                    </Box>
                  </>
                )}
              </Grid>
              <Button
                variant="contained"
                // color="flipButton"
                // color="#38A8AE"
                color="newFlipColor"
                sx={{
                  width: "20px",
                  height: "100px",
                  padding: 0,
                  position: "absolute",
                  right: 0,
                  top: `calc(50% - 50px)`,
                  minWidth: "20px",
                  fontSize: "20px",
                  borderRadius: "10px 0 0 10px",
                }}
                onClick={() => setisFlipped(true)}
              >
                <Box
                  component={"img"}
                  src={flip}
                  sx={{
                    height: "20px",
                    width: "15px",
                  }}
                />
              </Button>
            </>
          )}
        </>
      )}

      {isSubmitCandidate && (
        <SubmitCandidate
          setIsSubmitCandidate={setIsSubmitCandidate}
          job={job}
          allJobs={allJobs}
          setAllJobs={setAllJobs}
        />
      )}
      {jobPromote && (
        <JobPromoteCard
          setJobPromote={setJobPromote}
          jobID={job?.job_id}
          job={job}
          setShowLogin={setShowLogin}
          height="100%"
        />
      )}
    </Card>
  );
}
