import {
  Box,
  Button,
  Grid,
  InputBase,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import hireTalent from "../assets/HomePage/crayon_hire_talent.svg";
import recruit from "../assets/HomePage/crayon_recruit.svg";
import findWork from "../assets/HomePage/crayon_find_work.svg";
import earnCash from "../assets/HomePage/crayon_earn_cash.svg";
import linkedUsers from "../assets/HomePage/Black_Linked_User_Databases.svg";
import candidateBanner from "../assets/HomePage/crayon_recruiter_banner.gif";
import RecruitPageIcon from "../assets/HomePage/RecruitPageIcon.svg";
import demoIcon from "../assets/Black_Contact.svg";
import searchIcon from "../assets/HomePage/Red_Search.svg";
import { Link, useNavigate } from "react-router-dom";
// import HomeIcon from "@mui/icons-material/Home";
import Signup from "../components/login/signup";
import flip from "../assets/NewFlipIcon.svg";
import employerBanner from "../assets/HomePage/crayon_recruiter_banner.gif";
import VideoIcon from "../assets/HomePage/VideoIcon.svg";
import HomeIcon from "../assets/HomePage/HomeIcon.svg";
import HomeTalentIcon from "../assets/HomePage/HomeTalentIcon.svg";

export default function Recruit({
  setComponentToFlip,
  setIsRotate,
  isRotate,
  mobileView,
  setMobileView,
  setisHorizontalFlipped,
}) {
  const theme = useTheme();
  const [showSignup, setShowSignup] = useState(false);
  const handleCloseSignup = () => {
    setShowSignup(false);
  };
  const [uploadedFileName, setUploadedFileName] = useState(null);

  const [calcPaddingX, setCalcPaddingX] = useState(
    (window.innerHeight * 150) / 1078
  );
  const [calcPaddingY, setCalcPaddingY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [buttonSizeX, setButtonSizeX] = useState(
    (window.innerHeight * 150) / 1078
  );
  const [buttonSizeY, setButtonSizeY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [buttonSizeWithoutTextX, setButtonSizeWithoutTextX] = useState(
    (window.innerHeight * 100) / 1078
  );

  const [buttonSizeWithoutTextY, setButtonSizeWithoutTextY] = useState(
    (window.innerWidth * 100) / 1920
  );

  const [middleButtonX, setMiddleButtonX] = useState(
    (window.innerHeight * 75) / 1078
  );

  const [middleButtonY, setMiddleButtonY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [paddingLeftSide, setPaddingLeftSide] = useState(
    (window.innerWidth * 100) / 1920
  );

  const [homeButtonY, setHomeButtonY] = useState(
    (window.innerWidth * 50) / 1920
  );

  const [homeButtonX, setHomeButtonX] = useState(
    (window.innerHeight * 50) / 1078
  );

  const [searchText, setSearchText] = useState();
  const navigate = useNavigate();

  const getNewWidth = () => {
    setButtonSizeWithoutTextX((window.innerHeight * 100) / 1078);
    setButtonSizeWithoutTextY((window.innerWidth * 100) / 1920);
    setButtonSizeX((window.innerHeight * 150) / 1078);
    setButtonSizeY((window.innerWidth * 150) / 1920);
    setCalcPaddingX((window.innerHeight * 150) / 1078);
    setCalcPaddingY((window.innerWidth * 150) / 1920);
    setPaddingLeftSide((window.innerWidth * 100) / 1920);
    setMiddleButtonX((window.innerHeight * 75) / 1078);
    setMiddleButtonY((window.innerWidth * 150) / 1920);
    setHomeButtonY((window.innerWidth * 50) / 1920);
    setHomeButtonX((window.innerHeight * 50) / 1078);
  };

  const handleTalentSearch = () => {
    navigate(`/talent?query=${encodeURIComponent(searchText)}`);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  return mobileView ? (
    <Grid
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // padding: `${calcPaddingX}px ${calcPaddingY}px`,
        padding: "55px 30px",
      }}
      height={{
        xs: `calc(100vh - 60px)`,
        sm: `calc(100vh - 60px )`,
        md: `calc(100vh - 90px)`,
        mdl: `calc(100vh - 90px)`,
        lg: `calc(100vh - 80px)`,
        mlg: `calc(100vh - 80px)`,
        xl: `calc(100vh - 100px)`,
        xxl: "calc(100vh - 120px)",
      }}
    >
      {/* <Button
        onClick={() => {
          setComponentToFlip(4);
          setisHorizontalFlipped((prev) => !prev);
        }}
      >
        Flip
      </Button> */}

      <Box
        sx={{
          background: "white",
          width: "100%",
          position: "relative",
          height: "100%",
          borderRadius: "25px",
          boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
        }}
      >
        {/* <Button
          variant="contained"
          color="deleteVideoButton"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            zIndex: "999",
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => {
            setComponentToFlip(2);
            setisHorizontalFlipped((prev) => !prev);
          }}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button> */}
        <Box
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.hireCandidateNew.main,
                },
                backgroundColor: theme.homepage.greyButton.main,
                height: `125px`,
                width: `125px`,
                minWidth: `125px`,
                padding: { sm: "0px" },
                borderRadius: "25px 0px 25px 0px",
                display: "flex",
                flexDirection: "column",
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // top: 0,
                // left: 0,
                zIndex: "999",
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px !important",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={hireTalent}
                  // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  heihgt="50px"
                  width="50px"
                />
              }
              onClick={() => {
                setComponentToFlip(1);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            >
              hire talent
            </Button>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.nandos.main,
                },
                backgroundColor: theme.palette.nandos.main,
                height: `45px`,
                minWidth: `125px`,
                borderRadius: "0px 0 10px 10px",
                // position: "absolute",
                fontWeight: "14px",
                padding: "0px !important",
                // top: `calc(50% - 25px)`,
                zIndex: "999",
                "& .MuiButton-startIcon": {
                  margin: "0px !important",
                },
              }}
              startIcon={
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "30px",
                    width: "30px",
                    "& .MuiSvgIcon-root": {
                      fontSize: {
                        sm: "16px",
                        md: "18px",
                        mddl: "20px",
                        lg: "25px",
                        mlg: "30px",
                      },
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    alt="talent"
                    src={HomeTalentIcon}
                    height="25px"
                    width="20px"
                  />
                  {/* <HomeIcon /> */}
                </Box>
              }
              onClick={() => {
                // setComponentToFlip(0);
                // setIsRotate(!isRotate);
                // setisHorizontalFlipped((prev) => !prev);
                navigate("/talent");
              }}
            >
              view talent
            </Button>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.findWorkNew.main,
                },
                backgroundColor: theme.homepage.greyButton.main,
                height: `125px`,
                width: `125px`,
                minWidth: `125px`,
                padding: { sm: "0px" },
                borderRadius: "0px 25px 0px 25px",

                display: "flex",
                flexDirection: "column",
                fontSize: "20px",
                alignItems: "center",
                fontWeight: theme.typography.Bold,
                justifyContent: "center",
                // position: "absolute",
                // bottom: 0,
                // right: 0,
                zIndex: "999",
                "& .MuiButton-startIcon": {
                  margin: "0px !important",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={findWork}
                  // height={{ xs: 28, md: 40, mdl: 50, lg: 46, lgm: 60, mlg: 80 }}
                  // width={{ xs: 30, md: 42, mdl: 52, lg: 62, lgm: 62, mlg: 82 }}
                  heihgt="50px"
                  width="50px"
                />
              }
              onClick={() => {
                setComponentToFlip(2);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            >
              find work
            </Button>
          </Box>

          <Box
            sx={{
              height: "100%",
              width: "100%",
              display: "flex",
              justifyContent: { sm: "space-between" },
              flex: "1",
            }}
          >
            <Box
              sx={{
                width: { md: "50%", mdl: "50%", lg: "50%", mlg: "50%" },
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // justifyContent: {
                //   sm: "flex-start",
                //   md: "flex-end",
                //   mdl: "flex-start",
                //   mddl: "flex-start",
                //   lg: "flex-end",
                //   mlg: "flex-end",
                // },
                // marginLeft: `40px`,
                // paddingRight: `50px`,
                boxSizing: "border-box",
              }}
            >
              <Box
                component={"img"}
                alt="image"
                src={employerBanner}
                height={{
                  sm: "60%",
                  mdl: "60%",
                  mddl: "65%",
                  lg: "70%",
                  mlg: "90%",
                }}
                // height={"auto"}
                // width={{
                //   sm: "95%",
                //   mdl: "90%",
                //   mddl: "85%",
                //   lg: "79%",
                //   mlg: "79%",
                // }}
                sx={{
                  aspectRatio: "16/9",
                }}
                width={{ md24: "75%", lg1440: "78%", xl: "77%" }}
              />
            </Box>

            {/* <Box
              sx={{
                width: { sm: "50%", mdl: "50%", lg: "55%", mlg: "55%" },
                height: "100%",
                position: "relative",
                // perspective: "1000px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "auto",
              }}
            >
              <Box
                component={"img"}
                alt="image"
                src={candidateBanner}
                height={{
                  sm: "80%",
                  md: "80%",
                  mdl: "85%",
                  lg: "90%",
                  xl: "90%",
                }}
                sx={{
                  rotate: "345deg",
                }}
              />
            </Box> */}
            <Box
              sx={{
                // width: "38%",
                width: { md: "50%", lg: "50%", mlg: "50%" },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "start",
                // paddingLeft: `40px`,
                boxSizing: "border-box",
                paddingRight: "40px",
                paddingLeft: "50px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "30px",
                        mdl: "32px",
                        mddl: "35px",
                        lg: "37px",
                        lgm: "40px",
                        mlg: "48px",
                        xl: "48px",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    Built by recruiters,
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "30px",
                        mdl: "32px",
                        mddl: "35px",
                        lg: "37px",
                        lgm: "40px",
                        mlg: "48px",
                        xl: "48px",
                      },
                      fontWeight: "bold",
                      textWrap: "nowrap",
                    }}
                  >
                    for recruiters.
                  </Typography>
                </Box>
                <Box
                  component={"img"}
                  src={RecruitPageIcon}
                  sx={{ height: "150px", width: "118px", marginLeft: "60px" }}
                ></Box>
              </Box>

              <Typography
                sx={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textWrap: { lg: "wrap", lg1440: "nowrap" },
                }}
              >
                10,000+ hours later, we know what you want.
              </Typography>
              <Paper
                elevation={0}
                component="form"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0px 10px",
                  height: { md: "30px", mdl: "35px", lg: "35px", mlg: "45px" },
                  width: {
                    sm: "200px",
                    md: "260px",
                    mdl: "270px",
                    lg: "330px",
                    lgm: "350px",
                    mlg: "500px",
                  },
                  marginTop: "30px",
                  backgroundColor: theme.homepage.searchBoxRecruitNew.main,
                }}
              >
                <InputBase
                  sx={{
                    fontWeight: 700,
                    color: theme.homepage.searchBoxRecruitNewInput.main,
                    width: {
                      sm: "200px",
                      md: "260px",
                      mdl: "270px",
                      lg: "300px",
                      mlg: "400px",
                    },
                    fontSize: "14px",
                  }}
                  placeholder={"Begin your search for talent here..."}
                  inputProps={{ "aria-label": "search google maps" }}
                  value={searchText}
                  onChange={(event) => {
                    setSearchText(event.target.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault(); // Prevent the default Enter key behavior (page refresh)
                      handleTalentSearch(); // Call your function here
                    }
                  }}
                />
                <Box
                  component="img"
                  src={searchIcon}
                  sx={{
                    height: {
                      sm: "12px",
                      md: "18px",
                      mdl: "22px",
                      lg: "24px",
                      mlg: "24px",
                    },
                    width: {
                      sm: "16px",
                      md: "20px",
                      mdl: "23px",
                      lg: "25px",
                      mlg: "25px",
                    },
                    cursor: "pointer",
                  }}
                  onClick={handleTalentSearch}
                />
              </Paper>
              <Typography
                sx={{
                  fontSize: "14px",
                  marginTop: "35px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                We’ve been at this for a while now. After 5+ years of refining
                our internal product, we thought ‘let’s play nice and build this
                for everyone’. No more need for recruitment swearwords like
                P***, *******24, D****, ******In.
              </Typography>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                }}
              >
                Is this a recruitment revolution? Indeed.
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.bookDemo.main,
                },
                backgroundColor: theme.homepage.bookDemo.main,
                height: `125px`,
                width: `125px`,
                minWidth: `125px`,
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                display: "flex",
                flexDirection: "column",
                borderRadius: "0px 25px 0px 25px",
                alignItems: "center",
                justifyContent: "center",
                // position: "absolute",
                // right: 0,
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={recruit}
                  // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  heihgt="50px"
                  width="50px"
                />
              }
            >
              recruit
            </Button>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                width: "100%",
                justifyContent: "center",
                // position: "absolute",
                // bottom: 0,
              }}
            >
              <Link
                to={`https://crayondemo.co.za/`}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
                target="_blank"
              >
                <Button
                  variant="contained"
                  sx={{
                    ":hover": {
                      backgroundColor: theme.palette.nandos.main,
                    },
                    backgroundColor: theme.palette.nandos.main,
                    height: `45px`,
                    width: `125px`,
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    borderRadius: "10px 10px 0px 0px",
                    display: "flex",
                    padding: "6px 10px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    textWrap: "nowrap",
                    "& .MuiButton-startIcon": {
                      margin: "0px",
                    },
                  }}
                  startIcon={
                    <Box
                      component={"img"}
                      alt="talent"
                      src={VideoIcon}
                      height="20px"
                      width="22px"
                    />
                  }
                  onClick={() => {}}
                >
                  learn more
                </Button>
                {/* <Button
                variant="contained"
                sx={{
                  paddingTop: "0px !important",
                  justifyContent: "flex-start !important",
                  ":hover": {
                    backgroundColor: theme.homepage.homeIconNew.main,
                  },
                  backgroundColor: theme.homepage.homeIconNew.main,
                  height: { xs: `${middleButtonX}px`, xl: "75px" },
                  width: { xs: `${middleButtonY}px`, xl: "150px" },
                  borderRadius: "25px 25px 0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: {
                    sm: "8px",
                    md: "11px",
                    mdl: "12px",
                    mddl: "13px",
                    lg: "14px",
                    mlg: "18px",
                  },
                  fontWeight: theme.typography.Bold,
                  alignItems: "center",
                  textWrap: "nowrap",
                  lineHeight: "1",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={demoIcon}
                    height={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 40,
                    }}
                    width={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 35,
                    }}
                  />
                }
              >
                book a demo
              </Button> */}
              </Link>
            </Box>
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.homepage.earnCashNew.main,
                },
                backgroundColor: theme.homepage.greyButton.main,
                height: `125px`,
                width: `125px`,
                minWidth: `125px`,
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                padding: { sm: "0px" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "25px 0px 25px 0px",
                justifyContent: "center",
                // position: "absolute",
                // bottom: 0,
                // left: 0,
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={earnCash}
                  // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  heihgt="50px"
                  width="50px"
                />
              }
              onClick={() => {
                setComponentToFlip(4);
                setIsRotate(!isRotate);
                setisHorizontalFlipped((prev) => !prev);
              }}
            >
              earn cash
            </Button>
          </Box>
        </Box>
      </Box>
    </Grid>
  ) : (
    <Box sx={{ paddingTop: "55px" }}>
      <Box
        boxShadow={`0px 5px 10px ${theme.palette.boxShadow.main} !important`}
        sx={{
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          height: { xs: "633px", tablet: "85vh", mdl: "85vh" },
          minWidth: "340px",
          background: "white",
          margin: "0px 10px",
          // marginTop: "55px",
          position: "relative",
        }}
      >
        {/* <Button
          variant="contained"
          color="deleteVideoButton"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            zIndex: "999",
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => {
            setComponentToFlip(2);
            setisHorizontalFlipped((prev) => !prev);
          }}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "100px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.hireCandidateNew.main,
              },
              backgroundColor: theme.homepage.greyButton.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              borderRadius: "25px 0px 25px 0px",
              display: "flex",
              flexDirection: "column",
              fontWeight: theme.typography.Bold,
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            onClick={() => {
              setComponentToFlip(1);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={hireTalent}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
          >
            hire talent
          </Button>
          {/* <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.homeIconNew.main,
              },
              backgroundColor: theme.homepage.homeIconNew.main,
              height: `50px`,
              minWidth: `50px`,
              borderRadius: "0px 0px 10px 10px",
       
              padding: "0px !important",
            
              zIndex: "999",
              "& .MuiButton-startIcon": {
                margin: "0px !important",
              },
            }}
            startIcon={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30px",
                  width: "30px",
                  "& .MuiSvgIcon-root": {
                    fontSize: {
                      sm: "16px",
                      md: "18px",
                      mddl: "20px",
                      lg: "25px",
                      mlg: "30px",
                    },
                  },
                }}
              >
             
                <Box
                  component={"img"}
                  alt="talent"
                  src={HomeIcon}
                  height="25px"
                  width="20px"
                />
              </Box>
            }
            onClick={() => {
              setComponentToFlip(0);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          ></Button> */}
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.palette.nandos.main,
              },
              backgroundColor: theme.palette.nandos.main,
              height: `45px`,
              minWidth: `100px`,
              borderRadius: "0px 0 10px 10px",
              fontWeight: "14px",
              padding: "0px !important",
              zIndex: "999",
              "& .MuiButton-startIcon": {
                margin: "0px !important",
              },
            }}
            startIcon={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "30px",
                  width: "30px",
                  "& .MuiSvgIcon-root": {
                    fontSize: {
                      sm: "16px",
                      md: "18px",
                      mddl: "20px",
                      lg: "25px",
                      mlg: "30px",
                    },
                  },
                }}
              >
                <Box
                  component={"img"}
                  alt="talent"
                  src={HomeTalentIcon}
                  height="25px"
                  width="20px"
                />
              </Box>
            }
            onClick={() => {
              navigate("/talent");
            }}
          >
            talent
          </Button>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.findWorkNew.main,
              },
              backgroundColor: theme.homepage.greyButton.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              padding: { sm: "0px" },
              borderRadius: "0px 25px 0px 25px",
              display: "flex",
              flexDirection: "column",
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={findWork}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(2);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            find work
          </Button>
        </Box>
        <Box
          sx={{
            // flex: "1",
            display: "flex",
            alignItems: { xs: "end", tablet: "center" },
            //  marginTop: "25px"
            //  minHeight:"243px",
            marginBottom: "4px",
            justifyContent: { xs: "center", tablet: "center" },
            minHeight: { xs: "256px", tablet: "auto" },
            flex: { xs: "", tablet: "1", mdl: "" },
          }}
        >
          <Box>
            <Box
              component={"img"}
              // sx={{ objectFit: "contain", width: "230px" }}
              sx={{
                objectFit: "contain",
                width: { xs: "290px", tablet: "400px", md24: "600px" },
              }}
              alt="image"
              src={candidateBanner}
            ></Box>
          </Box>
        </Box>
        <Box sx={{ marginBottom: { tablet: "20px" } }}>
          <Box
            sx={{
              fontSize: { xs: "24px", tablet: "40px" },
              // lineHeight: { xs: "35px", tablet: "55.5px" },
              color: "#000000",
              fontWeight: 600,
              paddingLeft: "15px",
              textAlign: "right",
              paddingRight: "21px",
              marginBottom: "5px",
            }}
          >
            <Box>Built by recruiters,</Box>
            <Box> for recruiters.</Box>
          </Box>
          <Box
            sx={{
              textAlign: "right",
              paddingRight: "21px",
              // display: "flex",
              justifyContent: "space-between",
              fontSize: "14px",
              lineHeight: "24px",
              color: "#000000",
              fontWeight: 600,
            }}
          >
            10,000+ hours later, we know what you want.
          </Box>
          <Paper
            elevation={0}
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0px 10px",
              height: "45px",
              width: "86%",
              margin: "auto",
              marginBottom: "20px",
              marginTop: "15px",
              backgroundColor: theme.homepage.searchBoxRecruitNew.main,
            }}
          >
            <InputBase
              sx={{
                fontWeight: 700,
                color: theme.homepage.searchBoxRecruitNewInput.main,

                fontSize: "14px",
                marginTop: "5px",
                width: "90%",
              }}
              placeholder={"Begin your search for talent here..."}
              inputProps={{ "aria-label": "search google maps" }}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  handleTalentSearch();
                }
              }}
            />
            <Box
              component="img"
              src={searchIcon}
              sx={{
                height: "25px",
                width: "25px",
                cursor: "pointer",
              }}
              onClick={handleTalentSearch}
            />
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            position: "relative",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.bookDemo.main,
              },
              backgroundColor: theme.homepage.bookDemo.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              padding: { sm: "0px" },
              borderRadius: "0px 25px 0px 25px",
              display: "flex",
              flexDirection: "column",
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={recruit}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            // onClick={() => {
            //     setComponentToFlip(3);
            //     setIsRotate(!isRotate);
            // }}
          >
            recruit
          </Button>
          {/* <Link
          to={`https://calendly.com/crayon/discovery`}
          style={{
            textDecoration: "none",
            color: theme.palette.black,
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
          target="_blank"
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: "#009AE0",
              },
              backgroundColor: "#009AE0",
              height: `50px`,
              width: `100px`,
              borderRadius: "10px 10px 0px 0px",
              display: "flex",
              flexDirection: "column",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              justifyContent: "center",
              textWrap: "nowrap",
              lineHeight: "1",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={demoIcon}
                height={"25px"}
                width={"25px"}
              />
            }
          >
            book a demo
          </Button>
        </Link> */}
          <Link
            to={`https://crayondemo.co.za/`}
            style={{
              textDecoration: "none",
              color: theme.palette.black,
            }}
            target="_blank"
          >
            <Button
              variant="contained"
              sx={{
                ":hover": {
                  backgroundColor: theme.palette.nandos.main,
                },
                backgroundColor: theme.palette.nandos.main,
                height: `45px`,
                width: `100px`,
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "10px 10px 0px 0px",
                display: "flex",
                padding: "6px 10px",
                alignItems: "center",
                justifyContent: "space-around",
                textWrap: "nowrap",
                "& .MuiButton-startIcon": {
                  margin: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  alt="talent"
                  src={VideoIcon}
                  height="20px"
                  width="22px"
                />
              }
              onClick={() => {}}
            >
              demo
            </Button>
            {/* <Box
              sx={{
                backgroundColor: theme.palette.peachPayment.main,
                height: "50px",
                width: "50px",
                borderRadius: "10px 10px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={"img"}
                alt="talent"
                src={VideoIcon}
                height="30px"
                width="30px"
              />
            </Box> */}
            {/* <Button
                variant="contained"
                sx={{
                  paddingTop: "0px !important",
                  justifyContent: "flex-start !important",
                  ":hover": {
                    backgroundColor: theme.homepage.homeIconNew.main,
                  },
                  backgroundColor: theme.homepage.homeIconNew.main,
                  height: { xs: `${middleButtonX}px`, xl: "75px" },
                  width: { xs: `${middleButtonY}px`, xl: "150px" },
                  borderRadius: "25px 25px 0px 0px",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: {
                    sm: "8px",
                    md: "11px",
                    mdl: "12px",
                    mddl: "13px",
                    lg: "14px",
                    mlg: "18px",
                  },
                  fontWeight: theme.typography.Bold,
                  alignItems: "center",
                  textWrap: "nowrap",
                  lineHeight: "1",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={demoIcon}
                    height={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 40,
                    }}
                    width={{
                      sm: 18,
                      md: 20,
                      mdl: 22,
                      mddl: 24,
                      lg: 25,
                      mlg: 35,
                    }}
                  />
                }
              >
                book a demo
              </Button> */}
          </Link>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.earnCashNew.main,
              },
              backgroundColor: theme.homepage.greyButton.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              padding: "0px",
              borderRadius: "25px 0px 25px 0px",
              display: "flex",
              flexDirection: "column",
              // fontSize: "18px",
              alignItems: "center",
              justifyContent: "center",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={earnCash}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(4);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            earn cash
          </Button>
        </Box>
        <Signup
          openFunc={showSignup}
          closeFunc={handleCloseSignup}
          uploadedFileName={uploadedFileName}
        />
      </Box>
    </Box>
  );
}
