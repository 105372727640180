import { useState, memo } from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Draggable } from "react-beautiful-dnd";
import { Box, Button, Grid, Tooltip } from "@mui/material";
import profile from "../../../assets/user_logo.svg";
import drag_dots from "../../../assets/Crayon_Dashboard_Grip.svg";
import eye from "../../../assets/Black_View.svg";
import leftArrow from "../../../assets/Crayon_User_Lite.svg";
import rightArrow from "../../../assets/Crayon_User_Lite.svg";
// import loveThis from "../../../assets/Black_I_Love_This.svg";
import likethis from "../../../assets/Black_Like.svg";
import JMDHireIcon from "../../../assets/JMDHireIcon.svg";
import loveThis from "../../../assets/JMDLovthisIcon.svg";
import pending from "../../../assets/Black_Pending.svg";
import reject from "../../../assets/Black_Reject.svg";
import newCross from "../../../assets/JMDCross.svg";
import hiredWhite from "../../../assets/Padding Excluded/Green_Like_White.svg";
import downArrow from "../../../assets/Black_Down_Open.svg";
import rejectCharacter from "../../../assets/Characters/Red_Triangle_Blank.svg";
import upArrow from "../../../assets/Black_Up_Close.svg";
import close from "../../../assets/Black_Close.svg";
import QA from "../../../assets/Black_Q_A.svg";
import quicklinks from "../../../assets/Black_Quicklinks.svg";
import maleIcon from "../../../assets/Blue_Male.svg";
import salary from "../../../assets/Blue_Salary.svg";
import experience from "../../../assets/Yellow_Experience_Title_Job.svg";
import companyDetail from "../../../assets/Padding Excluded/Blue_Following_Company_Green.svg";
import femaleIcon from "../../../assets/Blue_Female.svg";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import SmallButton from "../../common/SmallButton";
import { convertManageDOB } from "../../../utils/DateTime";
import { setAlert } from "../../../redux/configSlice";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  candidateViewed,
  getQandA,
  getTalentDetails,
  runIndividualMatch,
} from "../../../redux/employer/myJobsSlice";
import ExpandBottomButtons from "./DraggableCardComponents/ExpandBottomButtons";
import CandidateFlipInfo from "./DraggableCardComponents/CandidateFlipInfo";
import BasicInfo from "./DraggableCardComponents/BasicInfo";
import { formatCurrencyWithCommas } from "../../../utils/Currency";
import { CheckCircle } from "@mui/icons-material";
import CandidateFlipCircles from "./DraggableCardComponents/CandidateFlipCircles";
import MUIRadialChart from "../../common/MUIRadialChart";
import {
  StyledAccordionDraggableCard,
  StyledBoxDraggableCard,
} from "../../../utils/CommonComponent";
import { getDecodedToken, getToken } from "../../../utils/Common";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal";
import locale from "../../../i18n/locale";
import CommonLoader from "../../common/CommonLoader";
import ViewProfilePic from "./ViewProfilePic";
import { getMatchData } from "../../../redux/guest/jobsSlice";

export default function DraggableCardNew({
  userID,
  itemData,
  index,
  droppableId,
  onDragEnd,
  jobId,
  talentStatus,
  getTalentMyJobStatusCount,
  jobDetail,
  setJobDetail,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  let decodedToken = getDecodedToken(getToken());
  const planType = decodedToken?.data?.plan_type;
  const [anchorElQandA, setAnchorElQandA] = useState(null);
  const [openQandADialog, setOpenQandADialog] = useState(false);
  const [questionAnswer, setQuestionAnswer] = useState([]);
  const [item, setItem] = useState(itemData);
  const [talentInfo, setTalentInfo] = useState();
  const [talentInfoLoading, setTalentInfoLoading] = useState(false);
  const [expand, setExpand] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showDetailInfo, setShowDetailInfo] = useState(false);
  const [rejectCandidate, setRejectCandidate] = useState(false);
  const [rejectMatchedCandidate, setRejectMatchedCandidate] = useState(false);
  const [showSummaryInfo, setShowSummaryInfo] = useState(true);
  const [showQuickLink, setShowQuickLink] = useState(false);
  const [flip, setFlip] = useState(false);
  const [viewed, setViewed] = useState(item?.viewed);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [eyeviewClicked, setEyeViewClicked] = useState(false);

  const getTalentDetailInfo = async () => {
    setTalentInfoLoading(true);
    try {
      let data = {
        talent_id: userID,
        job_id: jobId,
      };
      const { payload } = await dispatch(getTalentDetails(data));
      if (payload?.status === "success") {
        setTalentInfo(payload?.data?.[0]);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
      setTalentInfoLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const getColor = (value) => {
    if (value <= 24) {
      return "weakMatch";
    } else if (value <= 74) {
      return "shortlisting";
    } else {
      return "strongMatch";
    }
  };

  const handleCandidateView = async (status) => {
    setEyeViewClicked(true);
    try {
      const { payload } = await dispatch(
        candidateViewed({
          job_user_id: userID,
          job_id: jobId,
          viewFlag: !status,
          job_status_id: droppableId,
        })
      );
      if (payload?.status === "success") {
        setViewed((prev) => !prev);
        setEyeViewClicked(false);
        // await getTalentMyJobStatusCount(jobId);
      } else {
        setEyeViewClicked(false);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      setEyeViewClicked(false);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleQandADialog = async (event) => {
    try {
      const data = {
        job_id: jobId,
        user_id: item?.user_id,
      };
      setShowQuestion((prev) => !prev);
      setShowSummaryInfo((prev) => !prev);

      if (expand) {
        setExpand(false);
        setShowInfo(false);
        setShowDetailInfo(false);
      }
      if (rejectCandidate || showQuickLink) {
        setShowSummaryInfo(false);
        setRejectCandidate(false);
        setShowQuickLink(false);
        setRejectMatchedCandidate(false);
      }

      const { payload } = await dispatch(getQandA(data));
      if (payload?.status === "success") {
        setQuestionAnswer(payload?.data);
      } else if (payload === undefined) {
        return;
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.error,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }

    setOpenQandADialog(true);
    !openQandADialog && setAnchorElQandA(event?.target);
  };

  const handleReject = () => {
    setShowSummaryInfo((prev) => !prev);
    setRejectCandidate((prev) => !prev);
    setRejectMatchedCandidate((prev) => !prev);
    if (droppableId === 1) {
      return;
    }
    const result = {
      destination: {
        droppableId: 9,
      },
      draggableId: item?.user_id,
      source: {
        droppableId: droppableId,
      },
    };
    onDragEnd(result);
  };

  const handleMoveApplicant = (moveColumnID) => {
    const result = {
      destination: {
        droppableId: `${moveColumnID}`,
      },
      draggableId: item?.user_id,
      source: {
        droppableId: `${droppableId}`,
      },
    };
    setShowSummaryInfo(true);
    setShowInfo(false);
    setShowQuickLink(false);
    onDragEnd(result);
  };

  const toggleAcordion = async () => {
    !expand && talentInfo === undefined && (await getTalentDetailInfo());
    if (showQuestion || showQuickLink) {
      setShowSummaryInfo(true);
      setShowQuestion(false);
      setShowQuickLink(false);
    } else {
      setExpand((prev) => !prev);
      setShowQuestion(false);
      setShowDetailInfo(true);
      setShowInfo(false);
    }
  };

  const handleFlip = async () => {
    !flip && talentInfo === undefined && (await getTalentDetailInfo());
    setFlip((prev) => !prev);
    if (showQuestion || showQuickLink) {
      setShowInfo(true);
      setShowSummaryInfo(true);
      setExpand(true);
      setShowQuestion(false);
      setShowQuickLink(false);
      setShowDetailInfo(false);
    } else {
      setShowInfo((prev) => !prev);
      setShowDetailInfo((prev) => !prev);
    }
  };

  const handleRejectDialog = (value) => {
    if (value === "candidate") {
      setRejectMatchedCandidate((prev) => !prev);
    } else {
      setRejectCandidate((prev) => !prev);
    }
    if (showQuestion || showQuickLink) {
      setShowSummaryInfo(false);
      setShowQuestion(false);
      setShowQuickLink(false);
    } else {
      setShowDetailInfo(false);
      setShowInfo(false);
      setShowQuestion(false);
      setShowSummaryInfo((prev) => !prev);
      setExpand(false);
    }
  };

  const handleQuickLink = () => {
    setShowQuickLink((prev) => !prev);
    setShowSummaryInfo((prev) => !prev);
    if (expand) {
      setExpand(false);
      setShowInfo(false);
      setShowDetailInfo(false);
    }
    if (rejectCandidate || showQuestion) {
      setShowSummaryInfo(false);
      setRejectCandidate(false);
      setShowQuestion(false);
      setRejectMatchedCandidate(false);
    }
  };
  const handleTitle = () => {
    setIsSubscriptionModal(true);
  };

  const runMatch = async () => {
    try {
      const { payload } = await dispatch(
        getMatchData({ job_id: jobId, user_id: userID })
      );
      if (payload?.status === "success") {
        setItem({
          ...item,
          matchingPercentage: payload?.data?.matchingPercentage,
        });
      }
    } catch (error) {}
  };
  return (
    <>
      <Draggable
        key={item?.user_id}
        draggableId={item?.user_id?.toString()}
        index={index}
      >
        {(provided) => (
          <StyledAccordionDraggableCard
            ref={provided?.innerRef}
            {...provided?.draggableProps}
            {...provided?.dragHandleProps}
            disableGutters
            expanded={expand}
            // sx={{
            //   height: expand ? "400px" : "150px",
            // }}
          >
            <AccordionSummary aria-controls="panel1a-content">
              <Grid
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#f2f4fb",
                      borderRadius: "10px 0 0 10px",
                    }}
                  >
                    <Box
                      component="img"
                      className="dragDots"
                      alt="drag dots"
                      src={drag_dots}
                      sx={{
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      borderRight: `solid 0.5px ${theme.palette.grayBorder}`,
                      opacity: "0.3",
                    }}
                  ></Box>
                </Box>
                {rejectCandidate && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: "37px",
                      }}
                    >
                      <StyledBoxDraggableCard
                        className="homeImages"
                        component="img"
                        alt="Reject"
                        src={rejectCharacter}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          width: "80%",
                          alignItems: "center",
                          // marginBottom: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: theme?.typography?.Bold,
                          }}
                        >
                          Are you sure you want to reject the candidate?
                        </Typography>
                        {/* <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        
                      </Typography> */}
                      </Box>
                    </Box>

                    <Box>
                      <Button
                        sx={{
                          boxShadow: 0,
                          padding: "0px",
                          fontSize: "12px",
                          width: "100px",
                          height: "30px",
                          fontWeight: theme?.typography?.Bold,
                          borderRadius: "10px 0px 0px 0px",
                          // background: "lightGray",
                          color: "black",
                        }}
                        variant="contained"
                        color="newSubmit"
                        onClick={handleRejectDialog}
                      >
                        whoops, no
                      </Button>
                      <Button
                        sx={{
                          padding: "0px",
                          boxShadow: 0,
                          fontSize: "12px",
                          width: "100px",
                          fontWeight: theme?.typography?.Bold,
                          borderRadius: "0px 10px 0px 0px",
                          height: "30px",
                        }}
                        variant="contained"
                        color="nandos"
                        onClick={handleReject}
                      >
                        yes
                      </Button>
                    </Box>
                  </Box>
                )}
                {rejectMatchedCandidate && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        marginBottom: "37px",
                      }}
                    >
                      <StyledBoxDraggableCard
                        className="homeImages"
                        component="img"
                        alt="Reject"
                        src={rejectCharacter}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          width: "80%",
                          alignItems: "center",
                          // marginBottom: "16px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: theme?.typography?.Bold,
                          }}
                        >
                          You can’t reject a matched candidate.
                        </Typography>
                        {/* <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 700,
                        }}
                      >
                        
                      </Typography> */}
                      </Box>
                    </Box>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <Button
                        sx={{
                          boxShadow: 0,
                          padding: "0px",
                          fontSize: "12px",
                          width: "100px",
                          height: "30px",
                          fontWeight: theme?.typography?.Bold,
                          borderRadius: "10px 10px 0px 0px",
                          // background: "lightGray",
                          // color: "black",
                        }}
                        variant="contained"
                        color="nandos"
                        onClick={() => handleRejectDialog("candidate")}
                      >
                        ok
                      </Button>
                      {/* <Button
                        sx={{
                          padding: "0px",
                          boxShadow: 0,
                          fontSize: "12px",
                          width: "100px",
                          fontWeight: theme?.typography?.Bold,
                          borderRadius: "0px 10px 0px 0px",
                          height: "30px",
                        }}
                        variant="contained"
                        color="nandos"
                        onClick={handleReject}
                      >
                        yes
                      </Button> */}
                    </Box>
                  </Box>
                )}
                {showQuestion && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent:
                        droppableId !== 1 ? "space-between" : "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      {droppableId !== 1 ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 700,
                              marginLeft: "13px",
                              mt: 1,
                            }}
                          >
                            Question & Answers:
                          </Typography>
                          <Box sx={{ width: "90%", margin: "auto" }}>
                            {questionAnswer?.map((questions, index) => {
                              return (
                                <>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: 700,
                                      mr: 1,
                                      mt: 1,
                                    }}
                                  >
                                    Q{index + 1}: {questions?.question}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      mr: 1,
                                    }}
                                  >
                                    A{index + 1}: {questions?.answer}
                                  </Typography>
                                </>
                              );
                            })}
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexDirection: "column",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              marginBottom: "37px",
                            }}
                          >
                            <StyledBoxDraggableCard
                              className="homeImages"
                              component="img"
                              alt="Reject"
                              src={rejectCharacter}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                flexDirection: "column",
                                width: "80%",
                                alignItems: "center",
                                // marginBottom: "16px",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  fontWeight: theme?.typography?.Bold,
                                }}
                              >
                                {i18n["draggableCard.matchColumnQA"]}
                              </Typography>
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              width: "100%",
                            }}
                          >
                            <Button
                              sx={{
                                padding: "0px",
                                boxShadow: 0,
                                fontSize: "12px",
                                width: "100px",
                                fontWeight: theme?.typography?.Bold,
                                borderRadius: "10px 10px 0px 0px",
                                height: "30px",
                              }}
                              variant="contained"
                              color="nandos"
                              onClick={toggleAcordion}
                            >
                              ok
                            </Button>
                          </Box>
                        </Box>
                        // <Typography
                        //   sx={{
                        //     fontSize: "14px",
                        //     fontWeight: 700,
                        //     mr: 1,
                        //     mt: 1,
                        //   }}
                        // >
                        //   {i18n["draggableCard.matchColumnQA"]}
                        // </Typography>
                      )}
                    </Box>
                    {droppableId !== 1 ? (
                      <Box sx={{ margin: "auto", mb: 0 }}>
                        <SmallButton
                          color="nandos"
                          startIcon={
                            <Box
                              component="img"
                              sx={{
                                height: 30,
                                width: 30,
                              }}
                              alt="Down arrow"
                              src={upArrow}
                            />
                          }
                          onClick={toggleAcordion}
                          startIconMargin="4px"
                          marginTop="5px"
                          height={19}
                          width={60}
                          fontWeight={700}
                          borderRadius="7px 7px 0px 0px"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                )}
                {showQuickLink && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          marginLeft: "13px",
                          mt: 1,
                        }}
                      >
                        Quick-move this applicant to
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 700,
                          marginLeft: "13px",
                        }}
                      >
                        one of the stage:
                      </Typography>
                      <Box sx={{ width: "90%", margin: "auto" }}>
                        {talentStatus?.map((talent, index) => {
                          return (
                            <>
                              <Typography
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "12px",
                                  fontWeight: theme.typography.Bold,
                                  mr: 1,
                                  mt: 1,
                                  gap: "10px",
                                }}
                                onClick={() => handleMoveApplicant(talent?.id)}
                              >
                                {talent?.status}
                                {talent?.id === droppableId && (
                                  <CheckCircle
                                    color="eyeview"
                                    sx={{ height: "18px", width: "18px" }}
                                  />
                                )}
                              </Typography>
                            </>
                          );
                        })}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center !important",
                      }}
                    >
                      <SmallButton
                        color="redButton"
                        startIcon={
                          <Box
                            component="img"
                            sx={{
                              height: 30,
                              width: 30,
                            }}
                            alt="Down arrow"
                            src={upArrow}
                          />
                        }
                        onClick={toggleAcordion}
                        startIconMargin="4px"
                        marginTop="5px"
                        height={19}
                        width={60}
                        fontWeight={700}
                        borderRadius="7px 7px 0px 0px"
                        boxShadow={"none"}
                      />
                    </Box>
                  </Box>
                )}
                {showSummaryInfo && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        // flexDirection: "column",
                        justifyContent: "space-between",
                        marginTop: "5px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "65px",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component="img"
                          alt="crayon logo"
                          src={
                            item?.profile_url !== "No URL"
                              ? item?.profile_url
                              : profile
                          }
                          sx={{
                            borderRadius: "10px !important",
                            // border: `1px solid ${theme.manageTalent.profileBorder.main}`,
                            height: "48px !important",
                            width: "48px !important",
                          }}
                          onClick={() => setOpenProfile(true)}
                        />

                        <Box
                          sx={{
                            margin: "0 -8px 0 -8px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <MUIRadialChart
                            value={
                              item?.matchingPercentage === null
                                ? 0
                                : item?.matchingPercentage
                            }
                            chartName={"match"}
                            max={100}
                            size={55}
                            countFontSize={14}
                            labelFontSize={8}
                            labelFontWeight={theme.typography.Bold}
                            countFontWeight={theme.typography.Bold}
                            color={getColor(item?.matchingPercentage)}
                            formattedValue={`${
                              item?.matchingPercentage === null
                                ? 0
                                : item?.matchingPercentage
                            } %`}
                            onClick={() => {
                              runMatch();
                            }}
                          />
                        </Box>
                        {showInfo && expand && (
                          <CandidateFlipCircles
                            shadow={talentInfo?.secondary_personality_name}
                            gritScore={
                              talentInfo?.grit_score
                                ? talentInfo?.grit_score
                                : null
                            }
                            primary={talentInfo?.primary_personality_name}
                            index={item?.user_id}
                          />
                        )}
                      </Box>

                      <Box sx={{ width: "195px" }}>
                        <Box
                          sx={{
                            width: `calc(100% - 12px)`,
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            {item?.gender ? (
                              <Box
                                component="img"
                                sx={{
                                  width: 25,
                                }}
                                alt="maleIcon"
                                src={
                                  item?.gender === "male"
                                    ? maleIcon
                                    : femaleIcon
                                }
                              />
                            ) : (
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: 25,
                                }}
                              >
                                -
                              </Box>
                            )}
                            <Tooltip
                              disableInteractive
                              title={item?.first_name + " " + item?.last_name}
                              placement="top"
                            >
                              {decodedToken?.data?.talent_plan ? (
                                <Link
                                  to={`/admin/admin-talent/all-talent/candidate-cv/${item?.user_id}`}
                                  target="_blank"
                                  style={{
                                    textDecoration: "none",
                                    color: theme.palette.black,
                                    width: `calc(100% - 30px)`,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: theme.typography.Bold,
                                      fontSize: "14px",
                                      width: "100%",
                                      minHeight: "21px",
                                      whiteSpace: "nowrap", // Prevents text from wrapping
                                      overflow: "hidden", // Hides any overflowing content
                                      textOverflow: "ellipsis", // Adds dots at the end of overflowing text
                                    }}
                                  >
                                    {item?.first_name + " " + item?.last_name}
                                  </Typography>
                                </Link>
                              ) : (
                                <Typography
                                  onClick={handleTitle}
                                  sx={{
                                    fontWeight: theme.typography.Bold,
                                    fontSize: "14px",
                                    width: `calc(100% - 30px)`,
                                    minHeight: "21px",
                                    whiteSpace: "nowrap", // Prevents text from wrapping
                                    overflow: "hidden", // Hides any overflowing content
                                    textOverflow: "ellipsis", // Adds dots at the end of overflowing text
                                  }}
                                >
                                  {item?.first_name + " " + item?.last_name}
                                </Typography>
                              )}
                            </Tooltip>
                          </Box>
                          {item?.dob && (
                            <Button
                              color="greyPlayButton"
                              variant="contained"
                              sx={{
                                borderRadius: "5px",
                                boxShadow: "none",
                                height: "16px !important",
                                minWidth: "18px !important",
                                lineHeight: 0,
                                padding: "0 1px !important",
                                fontSize: "12px",
                                fontWeight: theme.typography.Bold,
                                // left: "13px",
                                color: "#C9CAD8",
                              }}
                            >
                              {convertManageDOB(item?.dob)}
                            </Button>
                          )}
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              width: 25,
                            }}
                            alt="experience"
                            src={experience}
                          />
                          <Tooltip
                            disableInteractive
                            title={item?.job_title}
                            placement="top"
                          >
                            <Typography
                              sx={{
                                fontWeight: theme.typography.Bold,
                                fontSize: "12px",
                                marginTop: "5px",
                                width: "170px",
                                minHeight: "21px",
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitBoxOrient: "vertical",
                                WebkitLineClamp: 2,
                                wordBreak: "break-word",
                              }}
                            >
                              {item?.job_title ? item?.job_title : "-"}
                            </Typography>
                          </Tooltip>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "baseline",
                            marginLeft: "6px",
                            gap: "5px",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              width: 14,
                              height: 16,
                            }}
                            alt="companyDetail"
                            src={companyDetail}
                          />
                          <Typography
                            sx={{
                              fontWeight: theme.typography.Regular,
                              fontSize: "12px",
                              letterSpacing: "0.25px",
                              width: "150px",
                              minHeight: "21px",
                              whiteSpace: "nowrap", // Prevents text from wrapping
                              overflow: "hidden", // Hides any overflowing content
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.employerhistory
                              ? item?.employerhistory?.name
                              : "-"}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "fit-content",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "flex-start",
                            marginLeft: "6px",
                            gap: "5px",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              height: 16,
                              width: 15,
                              maxHeight: { xs: 16 },
                              maxWidth: { xs: 15 },
                            }}
                            alt="salary"
                            src={salary}
                          />
                          <Typography
                            sx={{
                              fontWeight: theme.typography.Regular,
                              fontSize: "12px",
                              letterSpacing: "0.25px",
                              width: "150px",
                              minHeight: "21px",
                              whiteSpace: "nowrap", // Prevents text from wrapping
                              overflow: "hidden", // Hides any overflowing content
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item?.SalarySymbol}
                            {formatCurrencyWithCommas(
                              item?.CandidateInfoSalaryMax
                            )}{" "}
                            {item?.employment_type === "freelance"
                              ? "per hour"
                              : "per month"}
                          </Typography>
                        </Box>
                        {showDetailInfo && expand && (
                          <BasicInfo
                            town={talentInfo?.town_name}
                            region={talentInfo?.country_name}
                            experience={talentInfo?.experience_year}
                            educationInfo={talentInfo?.highestQualificationName}
                            notice={talentInfo?.noticePeriod}
                            talentInfoLoading={talentInfoLoading}
                          />
                        )}
                        {showInfo && expand && (
                          <CandidateFlipInfo
                            skills={talentInfo?.tags}
                            tools={talentInfo?.tool}
                            traits={talentInfo?.traits}
                            talentInfoLoading={talentInfoLoading}
                          />
                        )}
                      </Box>
                    </Box>
                    {expand && (
                      <ExpandBottomButtons
                        crayonCam={
                          talentInfo?.cam_link !== null
                            ? talentInfo?.cam_link
                            : "No URL"
                        }
                        videoLink={talentInfo?.job_cam_url}
                        cvLink={talentInfo?.cv_url}
                        userID={talentInfo?.user_id}
                        phoneNo={talentInfo?.contact_no}
                        emailAddress={talentInfo?.email}
                        linkedinAddress={talentInfo?.linkedin_profile_link}
                        portfolio_url={talentInfo?.portfolio_url}
                        jobDetail={jobDetail}
                        calendar_link={jobDetail?.calendar_link}
                        job_id={jobDetail?.job_id}
                        setJobDetail={setJobDetail}
                        talentInfo={talentInfo}
                      />
                    )}
                    <Box sx={{ margin: "auto", mb: 0 }}>
                      <SmallButton
                        color="redButton"
                        startIcon={
                          <Box
                            component="img"
                            sx={{
                              height: 30,
                              width: 30,
                            }}
                            alt="Down arrow"
                            src={expand ? upArrow : downArrow}
                          />
                        }
                        onClick={toggleAcordion}
                        startIconMargin="0px !important"
                        marginTop="0px !important"
                        padding="0px !important"
                        height={19}
                        width={60}
                        fontWeight={700}
                        borderRadius="7px 7px 0px 0px"
                      />
                    </Box>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    // marginLeft: "auto",
                  }}
                >
                  <SmallButton
                    color={
                      item?.candidateJobStatusName === "pending"
                        ? "tymeColor"
                        : item?.candidateJobStatusName === "not for me"
                        ? "nandos"
                        : item?.candidateJobStatusName === "i like this"
                        ? "yoco"
                        : item?.candidateJobStatusName === "i love this"
                        ? "safair"
                        : item?.candidateJobStatusName === "rejected"
                        ? "nandos"
                        : item?.candidateJobStatusName === "hired"
                        ? "springBoks"
                        : "tymeColor"
                    }
                    startIcon={
                      <Box
                        component="img"
                        className="eye"
                        alt="eye logo"
                        src={
                          item?.candidateJobStatusName === "pending"
                            ? pending
                            : item?.candidateJobStatusName === "not for me"
                            ? reject
                            : item?.candidateJobStatusName === "i like this"
                            ? likethis
                            : item?.candidateJobStatusName === "i love this"
                            ? loveThis
                            : item?.candidateJobStatusName === "rejected"
                            ? newCross
                            : item?.candidateJobStatusName === "hired"
                            ? JMDHireIcon
                            : pending
                        }
                        sx={{
                          height:
                            item?.candidateJobStatusName === "rejected" ||
                            item?.candidateJobStatusName === "hired" ||
                            item?.candidateJobStatusName === "i love this"
                              ? "18px"
                              : "26px",
                          width:
                            item?.candidateJobStatusName === "rejected" ||
                            item?.candidateJobStatusName === "hired" ||
                            item?.candidateJobStatusName === "i love this"
                              ? "18px"
                              : "25px",
                        }}
                      />
                    }
                    padding={0}
                    startIconMargin={
                      item?.candidateJobStatusName === "rejected" ||
                      item?.candidateJobStatusName === "i love this"
                        ? "0px"
                        : "4px"
                    }
                    // margin="auto"
                    height={30}
                    width={30}
                    fontWeight={700}
                    borderRadius="0px 10px 0px 0px"
                    boxShadow={"none"}
                  />
                  <SmallButton
                    color="precium"
                    startIcon={
                      <Box
                        component="img"
                        className="eye"
                        alt="eye logo"
                        src={QA}
                        sx={{
                          height: 30,
                          width: 30,
                        }}
                      />
                    }
                    padding={0}
                    startIconMargin="0px"
                    height={30}
                    width={30}
                    fontWeight={700}
                    borderRadius="0"
                    onClick={(event) => handleQandADialog(event)}
                    boxShadow={"none"}
                  />
                  <SmallButton
                    color="redButton"
                    startIcon={
                      <Box
                        component="img"
                        className="eye"
                        alt="eye logo"
                        src={close}
                        sx={{
                          height: 32,
                          width: 30,
                        }}
                      />
                    }
                    padding={0}
                    startIconMargin="0px"
                    height={30}
                    width={30}
                    fontWeight={700}
                    borderRadius="0"
                    // cursor={droppableId === 1 ? "default" : "pointer"}
                    cursor={"pointer"}
                    onClick={() => {
                      if (droppableId !== 1) {
                        handleRejectDialog();
                      } else {
                        handleRejectDialog("candidate");
                      }
                    }}
                    boxShadow={"none"}
                  />
                  <SmallButton
                    color="tymeColor"
                    startIcon={
                      <Box
                        component="img"
                        className="eye"
                        alt="eye logo"
                        src={quicklinks}
                        sx={{
                          height: 23,
                          width: 30,
                        }}
                      />
                    }
                    padding={0}
                    startIconMargin="0px"
                    height={30}
                    width={30}
                    fontWeight={700}
                    borderRadius="0"
                    onClick={handleQuickLink}
                    boxShadow={"none"}
                  />
                  <SmallButton
                    color={viewed ? "eyeview" : "newSubmit"}
                    // color={changeViewColor ? "eyeview" : "eyeview100"}
                    startIcon={
                      <Box
                        component="img"
                        className="eye"
                        alt="eye logo"
                        src={eye}
                        sx={{
                          height: 26,
                          width: 26,
                        }}
                      />
                    }
                    onClick={() => {
                      !eyeviewClicked && handleCandidateView(item?.viewed);
                      // setChangeViewColor(true);
                    }}
                    padding={0}
                    startIconMargin="0px"
                    height={30}
                    width={30}
                    fontWeight={700}
                    borderRadius={
                      expand || showQuestion || showQuickLink
                        ? "0"
                        : "0px 0px 10px 0px"
                    }
                    boxShadow={"none"}
                  />
                  {(expand || showQuestion || showQuickLink) && (
                    <SmallButton
                      color="redButton"
                      startIcon={
                        <Box
                          component="img"
                          className="eye"
                          alt="eye logo"
                          src={flip ? leftArrow : rightArrow}
                          sx={{
                            height: 22,
                            width: 22,
                          }}
                        />
                      }
                      padding={0}
                      startIconMargin="0px !important"
                      height={30}
                      width={30}
                      fontWeight={700}
                      borderRadius="0px 0px 0px 10px"
                      onClick={handleFlip}
                      boxShadow={"none"}
                    />
                  )}
                </Box>
              </Grid>
            </AccordionSummary>
          </StyledAccordionDraggableCard>
        )}
      </Draggable>
      {isSubscriptionModal && (
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      )}
      {openProfile && (
        <ViewProfilePic
          open={openProfile}
          handleClose={() => setOpenProfile(false)}
          profile={item?.profile_url}
        />
      )}
    </>
  );
}

export const MemoizedComponent = memo(DraggableCardNew);
