import { useTheme } from "@emotion/react";
import { Box, Button, Card, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BlueCurrency from "../../../assets/Blue_Salary.svg";
import redLocation from "../../../assets/Red_Location.svg";
import calendar from "../../../assets/Padding Included/Yellow_Calendar.svg";
import job_exp from "../../../assets/Padding Included/Green_Duration.svg";
import {
  convertDatetimeAgo,
  dateConverterMonth,
} from "../../../utils/DateTime";
import TrackButtonLayout from "../../common/TrackButtonLayout";
import { Circle, ExpandLess, ExpandMore } from "@mui/icons-material";

import { Link, useLocation } from "react-router-dom";
import job_logo from "../../../assets/Crayon_Favicon.svg";
import videoPlaceholder from "../../../assets/VideoPlaceholder.png";
import deleteIcon from "../../../assets/Cam/DeleteIcon.svg";
import { formatCurrencyWithCommas } from "../../../utils/Currency";

import whiteRecord from "../../../assets/Cam/VideoRecordingIcon.svg";
import JobVideoUpload from "./JobVideUpload";
import TrackButton from "./TrackButton";
import PlayButton from "../../common/PlayButton";
import { useDispatch } from "react-redux";
import { deleteCandidateJobVideo } from "../../../redux/candidate/candidateJobs";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import Delete from "./dialog/Delete";
import { renderColor } from "../../../utils/Common";
import { truncate } from "lodash";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";

const VideoCard = ({
  job,
  allVideoJobs,
  setAllJobList,
  setActiveStep,
  allJobsList,

}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();


  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [trackButton, setTrackButton] = useState(false);
  const [videoLink, setVideoLink] = useState(job?.job_cam_url);
  const VideoRef = useRef(null);
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [openRecordDialog, setOpenRecordDialog] = useState(false);
  const [steps, setSteps] = useState();
 
  const handleCloseDialog = () => {
    setOpenReviewVideoDialog(false);
  };

  const handleOpenRecordDialog = () => {
    setSteps(1);
    setOpenRecordDialog((prevState) => !prevState);
  };
  const handleVideoUploaded = (uploadedVideoLink) => {
    setVideoLink(uploadedVideoLink);
    VideoRef?.current?.load();
  };

  const handleOpenDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };
  const handleCancelDelete = () => {
    setOpenDelete((prevState) => !prevState);
    setConfirmDelete(false);
  };

  const handleDeleteVideo = async () => {
    try {
      const { payload } = await dispatch(
        deleteCandidateJobVideo({ job_id: job?.job_id })
      );
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "Job video removed successfully!",
          })
        );
        setAllJobList((prev) =>
          prev?.map((item) =>
            item?.job_id === job?.job_id
              ? { ...item, job_cam_url: "No URL", JobCamVideo: false }
              : item
          )
        );
        setVideoLink("No URL");
        handleCancelDelete();
      }
    } catch (error) {}
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: "25px",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          boxShadow: "0px 5px 10px #00000029",
        }}
        className="sliderClass"
      >
        <Grid
          container
          justifyContent="space-between"
          overflow={"hidden"}
          sx={{
            borderRadius: "25px 25px 0 0",
            width: "100%",
            flexWrap: "nowrap",
          }}
        >
          {!trackButton ? (
            <>
              <Link
                to={`/jobs/company/${job?.company_id}`}
                target={"_blank"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "60px",
                    width: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    margin: "15px 0 0 15px",
                    borderRadius: "10px",
                  }}
                  alt="job_logo"
                  src={
                    job?.profile_url !== "No URL" ? job?.profile_url : job_logo
                  }
                />
              </Link>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    maxWidth: "130px",
                    width: "120px",
                    height: "64px",
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        maxWidth: "60px",
                        minHeight: "45px",
                        borderRadius: "0 0 0 10px",
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        background:
                          job?.job_type !== null &&
                          job?.job_type?.includes("Recruit")
                            ? theme.jobCard.jobType.recruit.main
                            : theme.jobCard.jobType.direct.main,
                        color: theme.jobCard.jobType.recruit.contrastText,
                        cursor: "default",
                        ":hover": {
                          boxShadow: 0,
                          background:
                            job?.job_type !== null &&
                            job?.job_type?.includes("Recruit")
                              ? theme.jobCard.jobType.recruit.main
                              : theme.jobCard.jobType.direct.main,
                        },
                      }}
                    >
                      {job?.job_type || "-"}
                    </Button>

                    {job?.stage?.name && (
                      <Tooltip
                        disableInteractive
                        arrow
                        title={`${job?.stage?.name}`}
                        placement="top"
                      >
                        <Button
                          sx={{
                            height: "auto",
                            maxWidth: "70px",
                            minHeight: "45px",
                            width: "60px",
                            borderRadius: 0,
                            padding: 0,
                            fontSize: 12,
                            fontWeight: theme.typography.Bold,
                            background:
                              theme.manageTalent[renderColor(job?.stage?.name)]
                                ?.main,
                            cursor: "default",
                            color: "white",
                            ":hover": {
                              background:
                                theme.manageTalent[
                                  renderColor(job?.stage?.name)
                                ]?.main,
                            },
                          }}
                        >
                          {job?.stage?.name
                            ? truncate(job?.stage?.name, { length: 9 })
                            : "-"}
                        </Button>
                      </Tooltip>
                    )}
                  </Box>
                  <Tooltip
                    disableInteractive
                    title={`posted ${convertDatetimeAgo(job?.created_at)} ago`}
                    placement="top"
                  >
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Regular,
                        fontSize: 12,
                        width: "100%",
                        whiteSpace: "nowrap", // Prevents text from wrapping
                        overflow: "hidden", // Hides any overflowing content
                        textOverflow: "ellipsis",
                        color: "#C9CAD8",
                        // marginRight:"35px"
                        marginLeft: "-15px",
                      }}
                    >
                      posted {convertDatetimeAgo(job?.created_at)} ago
                    </Typography>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    // width: { xs: "80px", lg: "90px", xl: "120px" },
                    width: "60px",
                    flexDirection: "column",
                    // border: !trackButton
                    //   ? `1px solid ${theme.palette.chart.gray} `
                    //   : "none",
                    borderTop: 0,
                    borderRight: 0,
                    borderRadius: "0 0px 0px 10px",
                    height: "70px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      boxShadow: "none !important",
                      height: "45px !important",
                      borderRadius: 0,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      setTrackButton((prevState) => !prevState);
                    }}
                    color="greyPlayButton"
                  >
                    <TrackButtonLayout
                      job={job}
                      colorPattern={[
                        job?.profileCompleted,
                        job?.cvCompleted,
                        true,
                        job?.JobCamVideo,
                      ]}
                    />
                    {trackButton ? (
                      <ExpandLess sx={{ color: "#000", fontSize: "16px" }} />
                    ) : (
                      <ExpandMore sx={{ color: "#000", fontSize: "16px" }} />
                    )}
                  </Button>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      fontWeight: theme.typography.Bold,
                      fontSize: "0.9rem",
                      marginRight: "20px",
                    }}
                  >
                    {job?.job_status?.name || "Status"}{" "}
                    <Circle
                      fontSize="string"
                      // color={
                      //   job?.job_status?.name === "active" ? "success" : "error"
                      // }
                      color={
                        job?.job_status?.name === "paused"
                          ? "pausedStatus"
                          : job?.job_status?.name === "closed"
                          ? "closedStatus"
                          : job?.job_status?.name === "rejected"
                          ? "rejectedStatus"
                          : job?.job_status?.name === "pending"
                          ? "pendingStatus"
                          : job?.job_status?.name === "active"
                          ? "activeStatus"
                          : job?.job_status?.name === "expired" ? "peachPayment" :""
                      }
                    />
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <Box />
          )}
        </Grid>
        {trackButton && (
          <TrackButton
            job={job}
            closeFunc={setTrackButton}
            setActiveStep={setActiveStep}
            handleOpenRecordDialog={handleOpenRecordDialog}
          />
        )}
        {!trackButton && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Grid
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  padding: "10px 20px 0 15px",
                }}
              >
                <Tooltip
                  disableInteractive
                  arrow
                  // TransitionComponent={"Fade"}
                  // TransitionProps={{ timeout: 600 }}
                  title={job?.title}
                  placement="top"
                >
                  <Link
                    to={
                      job?.post_incognito
                        ? `/jobs/${job?.town?.country?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                        : `/jobs/${job?.town?.country?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.company?.name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                    }
                    target={"_blank"}
                    style={{
                      textDecoration: "none",
                      height: "20px",
                      overflow: "visible",
                      color: theme.palette.black,
                    }}
                  >
                    <Typography
                      sx={{
                        wordBreak: "break-all",
                        fontWeight: theme.typography.Bold,
                        fontSize: "20px",
                        height: "25px",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: 1,
                      }}
                    >
                      {job?.title}
                    </Typography>
                  </Link>
                </Tooltip>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                      alt="currency"
                      src={BlueCurrency}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {/* {job?.salary?.currency?.symbol}{" "}
                      {formatCurrencyWithCommas(job?.salary?.min)} to{" "}
                      {formatCurrencyWithCommas(job?.salary?.max)} per month */}
                      {job?.salary?.min === 0 ? (
                        <>
                          {job?.salary?.currency?.symbol}
                          {formatCurrencyWithCommas(job?.salary?.max)}{" "}
                          {job?.type === "freelance" ? "per hour" : "per month"}
                        </>
                      ) : job?.salary?.currency?.symbol ||
                        job?.salary?.min ||
                        job?.salary?.max ? (
                        <>
                          {job?.salary?.currency?.symbol}
                          {formatCurrencyWithCommas(job?.salary?.min)} to{" "}
                          {job?.salary?.currency?.symbol}
                          {formatCurrencyWithCommas(job?.salary?.max)}{" "}
                          {job?.type === "freelance" ? "per hour" : "per month"}
                        </>
                      ) : (
                        "-"
                      )}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                      alt="location"
                      src={redLocation}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.town?.name}, {job?.town?.country?.name}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 15,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 15 },
                      }}
                      alt="job_exp"
                      src={job_exp}
                    />

                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.experience?.year_start} to{" "}
                      {job?.experience?.year_end} years
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      height: "14px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        height: 16,
                        width: 14,
                        maxHeight: { xs: 16 },
                        maxWidth: { xs: 14 },
                      }}
                      alt="calendar"
                      src={calendar}
                    />
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "14px",
                        letterSpacing: "0.25px",
                      }}
                    >
                      {job?.job_start_date
                        ? dateConverterMonth(job?.job_start_date)
                        : "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {job?.job_cam_url !== "No URL" || videoLink !== "No URL" ? (
                <Box
                  sx={{
                    flexGrow: 1,
                    padding: "12px 12px 3px 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "240px",
                  }}
                >
                  {(job?.job_cam_url !== "No URL" && !isPlaying) ||
                  (videoLink !== "No URL" && !isPlaying) ? (
                    <PlayButton />
                  ) : null}
                  {job?.job_cam_url !== "No URL" || videoLink !== "No URL" ? (
                    <video
                      autoPlay={false}
                      loops
                      preload="auto"
                      style={{
                        width: "320px",
                        height: "240px",
                        borderRadius: "25px",
                        objectFit: "cover",
                        cursor: disabledButton ? "wait" : "pointer",
                      }}
                      controls={false}
                      ref={VideoRef}
                      onClick={() => {
                        // if (!disabledButton) {
                        //   if (VideoRef?.current?.paused) {
                        //     VideoRef?.current?.play();
                        //     setIsPlaying(true);
                        //   } else {
                        //     VideoRef?.current?.pause();
                        //     setIsPlaying(false);
                        //   }
                        // }
                        setOpenReviewVideoDialog(true);
                      }}
                      onLoadedData={() => {
                        setDisabledButton(false);
                      }}
                      onEnded={() => setIsPlaying(false)}
                    >
                      <source
                        src={videoLink || job?.job_cam_url}
                        type="video/mp4"
                      />
                    </video>
                  ) : (
                    <Box
                      component={"img"}
                      src={videoPlaceholder}
                      sx={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  )}
                </Box>
              ) : (
                <Box
                  sx={{
                    flexGrow: 1,
                    margin: "12px 12px 3px 12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    height: "240px",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "25px",
                      width: "320px",
                      backgroundColor: "#F2F4FB",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={videoPlaceholder}
                      sx={{
                        height: "70px",
                        width: "70px",
                      }}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Grid
              container
              alignItems="center"
              overflow={"hidden"}
              sx={{
                width: "100%",
                borderRadius: "0 0 25px 25px",
                height: "60px",
              }}
            >
              {job?.job_cam_url === "No URL" && videoLink === "No URL" ? (
                <Button
                  variant="contained"
                  // sx={{
                  //   borderRadius: 0,
                  //   width: "100%",
                  //   height: "60px",
                  // }}
                  sx={{
                    fontSize: "14px",
                    width: "100%",
                    height: "60px",
                    paddingY: "25px",
                    borderRadius: "0 0 25px 25px",
                  }}
                  color="videoButton"
                  startIcon={
                    <Box
                      component={"img"}
                      src={whiteRecord}
                      sx={{
                        height: "20px",
                        width: "24px",
                      }}
                    />
                  }
                  onClick={handleOpenRecordDialog}
                >
                  complete video
                </Button>
              ) : (
                <>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 0,
                      width: "50%",
                      height: "100%",
                      fontSize: "14px",
                    }}
                    color="deleteVideoButton"
                    startIcon={
                      <Box
                        component={"img"}
                        src={deleteIcon}
                        sx={{ height: 18 }}
                      />
                    }
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      borderRadius: 0,
                      width: "50%",
                      height: "100%",
                      fontSize: "14px",
                    }}
                    color="videoButton"
                    startIcon={
                      <Box
                        component={"img"}
                        src={whiteRecord}
                        sx={{
                          height: "20px",
                          width: "24px",
                        }}
                      />
                    }
                    onClick={handleOpenRecordDialog}
                  >
                    update video
                  </Button>
                </>
              )}
            </Grid>
          </>
        )}
      </Card>
      {openRecordDialog && (
        <JobVideoUpload
          show={openRecordDialog}
          closeFunc={handleOpenRecordDialog}
          setOpenRecordDialog={setOpenRecordDialog}
          reloadVideo={handleVideoUploaded}
          job_id={location?.state ? location?.state : job?.job_id}
          allVideoJobs={allVideoJobs}
          setAllJobList={setAllJobList}
          job={job}
          setSteps={setSteps}
          steps={steps}
          setActiveStep={setActiveStep}
        />
      )}

      <Delete
        show={openDelete}
        handleOpen={handleOpenDelete}
        handleCancel={handleCancelDelete}
        handleDelete={handleDeleteVideo}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
      />
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
    </>
  );
};

export default VideoCard;
