import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import EmployerProfileCompletion from "../EmployerProfileCompletion";
import ProfileProgressButtonLayout from "../ProfileProgressButtonLayout";
import { useSelector } from "react-redux";
import downArrow from "../../../assets/Black_Down_Open - Copy.svg";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";
import CandidateProfileCompletion from "../CandidateProfileCompletion";
import { getProfileProgressData } from "../../../redux/candidate/myProfileSlice";
import { useDispatch } from "react-redux";
export default function CandidatePercentageBox({ showSearchBar }) {
  const params = useLocation();
  const rightBoxRef = useRef();
  const dispatch = useDispatch();
  const profileProgressDataValues = useSelector(
    (state) => state.myProfileSli.profileProgressDataValues
  );
  const [progressButton, setProgressButton] = useState(false);
  const handleCandOutClick = (event) => {
    if (
      rightBoxRef?.current &&
      !rightBoxRef?.current?.contains(event?.target)
    ) {
      setProgressButton(false);
    }
  };
  const getProgressData = async () => {
    const { payload } = await dispatch(getProfileProgressData());
  };
  useEffect(() => {
    getProgressData();
    document?.addEventListener("mousedown", handleCandOutClick);
    return () => {
      document?.removeEventListener("mousedown", handleCandOutClick);
    };
  }, [params]);
  return (
    <>
      {!progressButton ? (
        <Button
          ref={rightBoxRef}
          variant="contained"
          color="lightGreyColor"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "11px 24px 15px 24px",
            gap: "7px",
            width: { xs: "60px", tb: "65px" },
            height: "45px",
            borderRadius: "0 0 10px 10px",
            boxShadow: "0px 8px 10px -2px #00000029",
            position: "absolute",
            // right: { xs: "40px", tb: "50px", md24: "267px" },
            right: { xs: "0px", tb: "0", md24: "150px" },
            zIndex: "999",
          }}
          onClick={() => setProgressButton((prev) => !prev)}
        >
          <ProfileProgressButtonLayout
            profileCompletion={profileProgressDataValues?.my_info}
            cvBasics={profileProgressDataValues?.cv_basic_info}
            studyLife={profileProgressDataValues?.study_life}
            workLife={profileProgressDataValues?.cv_work_life}
            references={profileProgressDataValues?.cv_refernce}
            mycam={profileProgressDataValues?.cam}
            assesment={profileProgressDataValues?.personality}
          />
          <Box
            component={"img"}
            src={progressButton ? upArrow : downArrow}
            sx={{
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "13px",
            }}
          />
        </Button>
      ) : (
        <Box
          ref={rightBoxRef}
          sx={{
            position: "absolute",
            display: "block",
            top: {
              width: { xs: "31%", xl: "400px" },
              xs: showSearchBar ? "115px" : "60px",
              md: showSearchBar ? "115px" : "70px",
              lg: showSearchBar ? "125px" : "90px",
              // xl: "100px",
              xl: showSearchBar ? "125px" : "90px",
              xxl: showSearchBar ? "125px" : "90px",
            },
            // right: { xs: "40px", tb: "50px", md24: "267px" },
            right: {xs:'0', md:'60px'},
            zIndex: 1001,
          }}
        >
          <CandidateProfileCompletion
            progressButton={progressButton}
            setProgressButton={setProgressButton}
            rightBoxRef={rightBoxRef}
          />
        </Box>
      )}
    </>
  );
}