import { CheckCircle } from "@mui/icons-material";
import { Box, CircularProgress, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getSecondaryCompanyData } from "../../../redux/employer/empProfileSlice";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";
import InfiniteScroll from "react-infinite-scroll-component";
import logo from "../../../assets/Company Logos/Ogilvy Restructures.jpg";
import locale from "../../../i18n/locale";
import upArrow from "../../../assets/Black_Up_Close - Copy.svg";

export default function ClientsList({
  handleClose,
  onChangeFilter,
  setSelectedCompany,
  selectedCompany,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const i18n = locale.en;

  const [companiesList, setCompaniesList] = useState([]);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [pageNumber, setpageNumber] = useState(0);

  const getSecondaryCompanies = async (lastKey) => {
    const { payload } = await dispatch(getSecondaryCompanyData());
    if (payload?.status === "success") {
      setpageNumber(payload?.pageNumber + 1);
      setCompaniesList((prevState) => [...prevState, ...payload?.data]);
      payload?.data?.length === 0 && setHasMoreData(false);
    }
  };

  const handleClick = (item) => {
    setCompaniesList((prev) =>
      prev?.map((items) =>
        items?.company?.company_id === item?.company?.company_id
          ? {
              ...items,
              // selected: items?.selected ? false : true,
              selected: true,
            }
          : { ...items, selected: false }
      )
    );

    let updatedJobList = [...selectedCompany];
    if (!updatedJobList?.includes(item?.company?.company_id)) {
      updatedJobList?.push(item?.company?.company_id);
    } else {
      updatedJobList = updatedJobList?.filter(
        (items) => items !== item?.company?.company_id
      );
    }
    setSelectedCompany(updatedJobList);
    onChangeFilter(updatedJobList);
  };

  useEffect(() => {
    getSecondaryCompanies(0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "11px 15px 0 15px",
        }}
      >
        <Box
          sx={{
            width: "325px",
            height: "15px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: theme.typography.Bold,
              lineHeight: "15px",
            }}
          >
            {i18n["empMyJobs.myclient"]}
          </Typography>
          <Box
            component={"img"}
            src={upArrow}
            sx={{ height: "28px", width: "30px" }}
          />
        </Box>

        <Box sx={{ width: "325px", height: "15px", margin: "15px 0" }}>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              lineHeight: "15px",
            }}
          >
            {i18n["empMyJobs.selectcompanies"]}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",

            gap: "10px",
            paddingRight: "10px",
            marginBottom: "10px",
            "::-webkit-scrollbar": {
              height: "5px",
              background: "#DCDEEB",
              display: "unset !important",
            },
          }}
          height={{ sm: `calc(576px - 153px)`, xl: `calc(586px - 153px)` }}
          className="scroll-container"
          id="shortListTaleneList"
        >
          <InfiniteScroll
            style={{
              overflowX: "hidden",
              scrollbarWidth: "thin",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            dataLength={companiesList?.length || 0}
            next={() => getSecondaryCompanies({ lastKey: pageNumber })}
            hasMore={hasMoreData}
            loader={
              <Box
                style={{
                  margin: "15px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingBottom: '35px !important'
                }}
              >
                <CircularProgress color="inherit" size={20} />
              </Box>
            }
            scrollableTarget="shortListTaleneList"
            scrollThreshold={"50px"}
          >
            {hasMoreData ? (
              companiesList?.map((item) => (
                <Box
                  key={item?.company?.company_id}
                  sx={{ display: "flex", alignItems: "center", gap: "9px" }}
                >
                  <Box
                    component={"img"}
                    src={
                      item?.companyURL !== "No URL" ? item?.companyURL : logo
                    }
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "10px",
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title={item?.company?.name}
                    placement="top-start"
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: theme.typography.Bold,
                        wordBreak: "break-word",
                        cursor: "pointer",
                      }}
                      noWrap={true}
                      onClick={() => {
                        handleClick(item);
                      }}
                    >
                      {item?.company?.name}
                    </Typography>
                  </Tooltip>
                  {selectedCompany?.includes(item?.company?.company_id) && (
                    <CheckCircle
                      key={item?.company?.company_id}
                      sx={{
                        fontSize: 27,
                        cursor: "pointer",
                      }}
                      color="checkColor"
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography>No clients</Typography>
              </Box>
            )}
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
}
