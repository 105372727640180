import { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, InputLabel, Typography } from "@mui/material";
import locale from "../../../i18n/locale";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InputBox from "../../common/InputBox";
import { ALERT_TYPE, CV_STEPS, ERROR_MSG } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import { getCVWorkLife } from "../../../redux/candidate/myCVNew";
import { setAlert, setLoading } from "../../../redux/configSlice";
import {
  addReferenceData,
  getReferenceData,
  setCVName,
  setCVUrl,
  uploadCv,
} from "../../../redux/candidate/myCvSlice";
import { useSelector } from "react-redux";
import { getTitles } from "../../../redux/employer/postJobSlice";
import {
  getCandidateCompany,
  updateInitialCandidateCompanies,
} from "../../../redux/employer/empProfileSlice";
import { useTheme } from "@emotion/react";
import SaveAndExit from "./dialogBox/SaveAndExit";
import referenceAuto from "../../../assets/MyCV/Black_eferences.svg";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import previousIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { numberToWords } from "../../../utils/DateTime";
import { CheckCircle } from "@mui/icons-material";
import { isValidPhoneNumber } from "react-phone-number-input";

import CancelIcon from "@mui/icons-material/Cancel";
import CustomSelect from "./CustomSelect";
import {
  debounce,
  getContactDetail,
  getUserCountryFromGeolocation,
  validateSchema,
} from "../../../utils/Common";
import * as Yup from "yup";
import _ from "lodash";
import uploadCVIcon from "../../../assets/MyCV/UploadCv.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/GrabLinkedinIcon.svg";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import { Link } from "react-router-dom";

export default function References({
  changeStep,
  handleComplete,
  step,
  referenceData,
  setReferenceData,
  initialReferenceData,
  setInitialReferenceData,
}) {
  const i18n = locale.en;
  const REFERENCE = {
    reference_id: null,
    name: "",
    company_name: null,
    title: null,
    contact: "",
    email: "",
    country_code: "",
  };

  const dispatch = useDispatch();
  const theme = useTheme();
  const [noReferenceExp, setNoReferenceExp] = useState(false);
  const hiddenFileInput = useRef(null);
  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const [initialCountryCode, setInitialCountryCode] = useState("");
  // const [noReference, setNoReference] = useState(false);
  const [saveAndExit, setSaveAndExit] = useState(false);
  const [errors, setErrors] = useState([]);
  const [referenceID, setReferenceID] = useState([]);
  const [inValidContacts, setInvalidContacts] = useState([
    {
      referenceID: null,
      contactTouched: false,
      contactValid: false,
    },
  ]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [countryCode, setCountryCode] = useState();

  const [inputBoxWidth, setInputBoxWidth] = useState(
    (window.innerWidth * 672) / 1920
  );
  const [inputBoxHeight, setInputBoxHeight] = useState(
    (window.innerHeight * 40) / 1078
  );

  const [boxGap, setBoxGap] = useState((window.innerWidth * 120) / 1920);
  const countryCode = useRef();

  const { titles, candidateCompany } = useSelector((state) => state.myProfile);
  const { cVName, cVUrl } = useSelector((state) => state.myCv);
  const referenceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company_name: Yup.string().required("Company name is required"),
    title: Yup.string().required("Title is required"),
    contact: Yup.string().required("Contact is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
  });

  const finalSchema = Yup.object().shape({
    reference: Yup.array().when("noCheck", {
      is: true,
      then: (schema) => schema.of(referenceSchema),
      otherwise: (schema) => schema.optional(),
    }),
    noCheck: Yup.bool(),
  });
  const getAllData = async (para) => {
    try {
      switch (para) {
        case "titles":
          await dispatch(getTitles({ setState: setIsLoading, type: "title" }));
          return;
        case "companies":
          await dispatch(
            getCandidateCompany({
              setState: setIsLoading,
              type: "company_name",
            })
          );
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const saveReferenceInfo = async (step) => {
    try {
      const transformedData = referenceData?.map((item) => {
        let contactInfo;
        if (item?.contact?.startsWith("+")) {
          contactInfo = getContactDetail(formatPhoneNumberIntl(item?.contact));
        }
        let newItem = {
          ...item,
          contact: contactInfo ? contactInfo?.[1] : item?.contact,
          country_code: contactInfo ? contactInfo?.[0] : item?.country_code,
        };
        if (item?.company_name?.label) {
          newItem.company_name = item?.company_name?.label;
        }
        if (item?.title?.label) {
          newItem.title = item?.title?.label;
        }
        return newItem;
      });
      let checkedValue = referenceData?.some(
        (item) => item?.company_name !== "" && item?.company_name !== null
      );

      validateSchema(finalSchema, {
        reference: transformedData,
        noCheck: noReferenceExp === true ? false : true,
      })
        .then(() => {
          dispatch(
            addReferenceData(
              noReferenceExp === true
                ? { data: [], reference: true }
                : noReferenceExp === false
                ? {
                  data: transformedData?.filter(
                    (item) => item?.company_name !== ""
                  ),
                  reference: false
                }
                : {
                  data: transformedData?.filter(
                    (item) => item?.company_name !== ""
                  ),
                  reference: false
                }
            )
          ).then((payload) => {
            if (payload?.payload?.status == "success") {
              handleComplete(3);
              setErrors([]);
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: "Reference added successfully!",
                })
              );
              changeStep(step, "component");
            } else if (payload?.payload?.status === "error") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: "Something went wrong",
                })
              );
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: payload?.payload?.message,
                })
              );
            }
          });
        })
        .catch((error) => {
          setErrors(error);
        });
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getCVWorkLifeData = async () => {
    const transformData = (data) => {
      return data.map((item) => ({
        ...REFERENCE,
        company_name: {
          label: item?.company_name,
          value: item?.company_id || item?.company_name,
        },
        title: { label: item?.title, value: item?.title_id },
      }));
    };

    const updateReferenceData = (newData) => {
      setReferenceData(newData);
      setInitialReferenceData(newData);
    };
    const { payload } = await dispatch(getCVWorkLife());
    if (payload?.status === "success") {
      const newData =
        payload?.data?.length > 0 ? transformData(payload.data) : [REFERENCE];
      updateReferenceData(newData);
    } else if (payload?.status === "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Fill the work life details",
        })
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.data,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const getReferenceInfo = async () => {
    const { payload } = await dispatch(getReferenceData());
    if (payload?.status == "success") {
      setNoReferenceExp(payload?.reference);
      if (payload?.data?.length === 0) {
        await getCVWorkLifeData();
      } else {
        let updatedData = payload?.data;
        updatedData?.map((item) => {
          if (item.company_name) {
            if (item?.company_name && item?.company_id) {
              item.company_name = {
                value: item?.company_id,
                label: item?.company_name,
              };
            }
            if (item?.company_name && item?.company_id === null) {
              item.company_name = {
                value: item?.company_name,
                label: item?.company_name,
              };
            }
          }
          if (item.title) {
            if (item?.title && item?.title_id) {
              item.title = { value: item?.title_id, label: item?.title };
            }
            if (item?.title && item?.title_id === null) {
              item.title = { value: item?.title, label: item?.title };
            }
          }
        });
        setInitialReferenceData(updatedData);
        setReferenceData(updatedData);
      }
      setInvalidContacts(
        payload?.data?.map((data) => ({
          referenceID: data.reference_id,
          contactTouched: false,
          contactValid: false,
        }))
      );
      setReferenceID(payload?.data?.map((data) => data.reference_id));
    } else if (payload?.status == "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Fill the work life details",
        })
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.data,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const addReference = () => {
    setReferenceData((prevState) => [...prevState, { ...REFERENCE }]);
    setInvalidContacts((prevState) => [
      {
        referenceID: null,
        contactTouched: false,
        contactValid: false,
      },
      ...prevState,
    ]);
  };

  const removeReference = (event, index) => {
    if (referenceData?.length > 1) {
      const newWorkData = referenceData?.filter((data, i) => i + 1 != index);
      setReferenceData(newWorkData);
    }
  };

  const handleOpenNext = () => {
    setSaveAndExit((prevState) => !prevState);
  };

  const handleInputValue = (event, index, fieldName) => {
    const updatedReferenceData = [...referenceData];
    updatedReferenceData[index] = {
      ...updatedReferenceData[index],
      [event.target.id]:
        event.target.id === "contact"
          ? event.target.value
          : event.target.value || "",
    };
    setReferenceData(updatedReferenceData);
    let obj = { ...errors };
    let key = `reference[${index}].${fieldName}`;
    if (event?.target?.value) {
      delete obj[key];
    }
    setErrors({ ...obj });
  };

  const handleContactNumber = (name, value, index) => {
    const updatedReferenceData = [...referenceData];
    updatedReferenceData[index] = {
      ...updatedReferenceData[index],
      [name]: value,
      country_code: countryCode?.current,
    };
    setReferenceData(updatedReferenceData);
    let obj = { ...errors };
    let key = `reference[${index}].${name}`;
    if (value) {
      delete obj[key];
    }
    setErrors({ ...obj });
  };

  const getNewWidth = () => {
    setInputBoxWidth((window.innerWidth * 672) / 1920);
    setInputBoxHeight((window.innerHeight * 40) / 1078);
    setBoxGap((window.innerWidth * 120) / 1920);
  };

  const validateNumber = (index, phoneNumber) => {
    const updatedInvalidContactsData = [...inValidContacts];
    updatedInvalidContactsData[index] = {
      ...updatedInvalidContactsData[index],
      contactTouched: true,
      contactValid: isValidPhoneNumber(phoneNumber || ""),
    };
    setInvalidContacts(updatedInvalidContactsData);
  };

  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      try {
        if (type === "title") {
          dispatch(getTitles({ ...params, setState: setIsLoading, type }));
        }
        if (type === "company_name") {
          dispatch(
            getCandidateCompany({ ...params, setState: setIsLoading, type })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };

  const onCreateOption = (data, inputId, index) => {
    const updatedReferenceData = [...referenceData];
    if (data) {
      updatedReferenceData[index] = {
        ...updatedReferenceData[index],
        [inputId]: { label: data, value: data },
      };
    }
    setReferenceData(updatedReferenceData);
    let obj = { ...errors };
    if (data) {
      delete obj[`[${index}].${inputId}`];
    }
    setErrors({ ...obj });
  };

  const handleOnSelectChange = (ind, newValue, val, inputId) => {
    if (newValue === null) {
      dispatch(updateInitialCandidateCompanies());
    }
    const updatedReferenceData = [...referenceData];
    if (inputId === "title" || inputId === "company_name") {
      updatedReferenceData[ind] = {
        ...updatedReferenceData[ind],
        [inputId]: newValue,
      };
    }
    setReferenceData(updatedReferenceData);
    let obj = { ...errors };
    if (newValue?.label || newValue?.value) {
      delete obj[`[${ind}].${inputId}`];
    }
    setErrors({ ...obj });
  };

  const onMenuOpen = () => {
    if (titles.length === 0) {
      getAllData("titles");
    }
    setIsMenuOpen(true);
  };

  const onMenuClose = () => {
    setIsMenuOpen(false);
  };
  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append("cv", event?.target?.files?.[0]);
    try {
      const { payload } = await dispatch(uploadCv(formData));
      if (payload?.status === "success") {
        dispatch(setCVName(event?.target?.files?.[0]?.name));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "CV uploaded Successfully!",
          })
        );
        dispatch(setCVUrl(payload?.cv_url));
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
      const newWindow = window.open("", "_blank");
      newWindow.document.write(`
        <html>
        <head>
          <title>Crayon Jobs - ${cVName}</title>
        </head>
          <body style="margin: 0;">
            <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=${cVUrl}' width='100%' height='100%' frameborder='0'></iframe>
          </body>
        </html>
      `);
  };

  useEffect(() => {
    getUserCountryFromGeolocation()
      .then((countryCode) => {
        setInitialCountryCode(countryCode);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error fetching country code",
          })
        );
      });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  useEffect(() => {
    getAllData("titles");
  }, []);

  useEffect(() => {
    getReferenceInfo();
  }, []);
  const handleNoStudy = (event) => {
    const isChecked = event?.target?.checked;
    setErrors([]);
    setNoReferenceExp(isChecked);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box
          width={{
            xs: "150px",
            md24: "42%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="yoco"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                padding: { xs: "6px 16px", md24: "6px 16px" },
                borderRadius: "25px 0 0 0",
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
            >
              {i18n["myCV.reference"]}
            </Button>
            <Button
              variant="contained"
              color="oros"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                padding: { xs: "6px 16px", md24: "0px" },
                borderRadius: "0 0 10px 0",
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
              startIcon={
                <Box
                  component={"img"}
                  src={referenceAuto}
                  sx={{
                    display: { xs: "none", tablet: "block" },
                    height: "25px",
                    width: "26px",
                  }}
                />
              }
            >
              auto fill
              <Typography
                sx={{
                  padding: "5px",
                  height: "8px",
                  width: "8px",
                  borderRadius: "5px",
                  fontSize: "15px",
                  lineHeight: 0.1,
                  alignItems: "center",
                  justifyContent: "center",
                  display: { xs: "none", md24: "flex" },
                  fontWeight: theme.typography.Bold,
                  border: 1,
                  marginLeft: "13px",
                }}
              >
                i
              </Typography>
            </Button>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: 1,
              }}
            >
              <ToggleSwitch
                onChange={handleNoStudy}
                checked={noReferenceExp}
                color="greenSwitch"
              />
              <Typography
                sx={{
                  fontWeight: theme?.typography?.Regular,
                }}
              >
                do it later
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ textAlign: "right" }}>
          <input
            accept={fileAccept}
            ref={hiddenFileInput}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            required
          />
          <Button
            variant="contained"
            color={cVName !== "No file chosen" ? "checkColor" : "peachPayment"}
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 10px"},
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "0px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={uploadCVIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {cVName !== "No file chosen" ? "CV uploaded" : "upload my CV"}
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 25px"},
              padding: { xs: "5px", md24: "0px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            grab LinkedIn
          </Button>
          <Link
              to={cVName?.endsWith(".pdf") ? cVUrl : "#"}
              onClick={cVName?.endsWith(".pdf") ? () => {} : handleClick}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
              target="_blank"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Regular,
                  ml: 1,
                  mt: "4px",
                }}
              >
                {cVName}
              </Typography>
            </Link>
        </Box>
      </Box>
      <Box sx={{ padding: "0px 32px 0px 32px !important" }}>
        {referenceData?.length >= 0 &&
          referenceData?.map((work, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index != 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeReference(event, index + 1)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme.typography.Bold,
                      flex: 1,
                    }}
                  >
                    {numberToWords(index + 1) + ` Reference`}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md24: "row" },
                  justifyContent: "space-between",
                  mb: 3,
                  gap: { xs: `20px`, xl: "120px" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <InputLabel
                    htmlFor="company_name"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.referencePersonNameAndSurnameReq"]}
                  </InputLabel>

                  <InputBox
                    disabled={noReferenceExp}
                    sx={{
                      border: errors[`reference[${index}].name`]
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      borderRadius: "10px",
                      height: "40px",
                    }}
                    value={work?.name}
                    id="name"
                    placeholder={"Enter the name of your reference"}
                    onChange={(event) => handleInputValue(event, index, "name")}
                  />
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel
                    disabled={noReferenceExp}
                    htmlFor="company_name"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                      width: { xs: "94%", xl: `${inputBoxWidth}px` },
                    }}
                  >
                    {i18n["myCV.companyNameLabelReq"]}
                  </InputLabel>

                  <CustomSelect
                    isDisabled={noReferenceExp}
                    index={index}
                    variant="createSelect"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    onCreateOption={onCreateOption}
                    placeholder={"Select or add the relevant company"}
                    inputId="company_name"
                    name="company_name"
                    value={
                      work?.company_name?.label && work?.company_name?.value
                        ? {
                            label: work?.company_name?.label
                              ? work?.company_name?.label
                              : "",
                            value: work?.company_name?.value
                              ? work?.company_name?.value
                              : "",
                          }
                        : null
                    }
                    onMenuOpen={() => {
                      if (candidateCompany?.length === 0) {
                        getAllData("companies");
                      }
                      setIsMenuOpen(true);
                    }}
                    onMenuClose={onMenuClose}
                    option={candidateCompany?.map((item) => ({
                      value: item?.candidate_company_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading["company_name"]}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,
                        width: `100%`,
                        border: errors[
                          `reference[${index}].${["company_name"]}`
                        ]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors[
                          `reference[${index}].${["company_name"]}`
                        ]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                      }),
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md24: "row" },
                  mb: 3,
                  gap: { xs: `20px`, xl: "120px" },
                }}
              >
                <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
                  <InputLabel
                    htmlFor="title"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.jobTitleLableReq"]}
                  </InputLabel>

                  <CustomSelect
                    index={index}
                    isDisabled={noReferenceExp}
                    variant="createSelect"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    onCreateOption={onCreateOption}
                    placeholder={i18n["myCV.currentJobTitle"]}
                    inputId="title"
                    name="title"
                    value={
                      work?.title?.label && work?.title?.value
                        ? {
                            label: work?.title?.label ? work?.title?.label : "",
                            value: work?.title?.value ? work?.title?.value : "",
                          }
                        : null
                    }
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    option={titles?.map((item) => ({
                      value: item?.job_title_id,
                      label: item?.title,
                    }))}
                    isLoading={isLoading["title"]}
                    customStyle={{
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,

                        border: errors[`reference[${index}].${["title"]}`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors[`reference[${index}].${["title"]}`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", md24: "50%" },
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel
                    disabled={noReferenceExp}
                    htmlFor="contact_number"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                      width: { xs: "94%", xl: `${inputBoxWidth}px` },
                    }}
                  >
                    {i18n["myCV.contactNumber"]}
                  </InputLabel>
                  <Box
                    sx={{
                      background: "transparent",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      height: "38px",
                      borderRadius: "10px",
                      border: errors[`reference[${index}].contact`]
                        ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                        : "1px solid #E0E0E0",
                    }}
                  >
                    <PhoneInput
                      disabled={noReferenceExp}
                      international
                      id="contact"
                      placeholder="Enter phone number"
                      countryCallingCodeEditable={false}
                      value={
                        work?.contact?.startsWith("+")
                          ? work?.contact
                          : `${work?.country_code}${work?.contact}` || ""
                      }
                      onChange={(value) => {
                        handleContactNumber("contact", value, index);
                      }}
                      defaultCountry={initialCountryCode?.toUpperCase()}
                      focusInputOnCountrySelection={true}
                      onCountryChange={(value) => {
                        countryCode.current = value;
                      }}
                      style={{
                        font: "inherit",
                        padding: "10px",
                        fontSize: "14px",
                      }}
                      onBlur={() => validateNumber(index, work?.contact)}
                    />
                    {isValidPhoneNumber(work?.contact || "") && (
                      <Typography
                        sx={{
                          color: theme?.palette?.tealColor?.main,
                          fontSize: "14px",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                          marginRight: "5px",
                          justifyContent: "end",
                        }}
                      >
                        Correct <CheckCircle fontSize="small" />
                      </Typography>
                    )}
                    {inValidContacts?.find(
                      (item) => item?.referenceID === work?.reference_id
                    )?.contactTouched
                      ? !isValidPhoneNumber(work?.contact || "") && (
                          <Typography
                            sx={{
                              color: "red",
                              fontSize: "14px",
                              alignItems: "center",
                              display: "flex",
                              gap: "5px",
                              marginRight: "5px",
                              justifyContent: "end",
                            }}
                            noWrap
                          >
                            Invalid Contact <CancelIcon fontSize="small" />
                          </Typography>
                        )
                      : null}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md24: "row" },
                  mb: 3,
                  gap: { xs: `20px`, xl: "120px" },
                }}
              >
                <Box sx={{ width: { xs: "100%", md24: "50%" } }}>
                  <InputLabel
                    htmlFor="contact_number"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme?.typography?.Bold,
                    }}
                  >
                    {i18n["myProfile.emailLabelRequired"]}
                  </InputLabel>
                  <InputBox
                    disabled={noReferenceExp}
                    sx={{
                      border: errors[`reference[${index}].email`]
                        ? `1px solid ${theme?.jobCard?.jobStatus?.Rejected?.main}`
                        : "1px solid #E0E0E0",
                      borderRadius: "10px",
                      height: "40px",
                      width: "100%",
                    }}
                    value={work?.email}
                    id="email"
                    type="email"
                    placeholder={"Enter their email address"}
                    onChange={(event) =>
                      handleInputValue(event, index, "email")
                    }
                  />
                </Box>
                <Box sx={{ width: "50%" }}></Box>
              </Box>
            </Box>
          ))}
      </Box>

      <Button
        disabled={noReferenceExp}
        variant="contained"
        color="nandos"
        onClick={addReference}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "150px",
          height: "45px",
        }}
      >
        {"+ reference"}
      </Button>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          height: "90px",
          alignItems: "flex-end",
        }}
      >
        <Button
          sx={{
            boxShadow: 0,
            padding: "0px",
            fontSize: "14px",
            width: "150px",
            height: "60px",
            borderRadius: "10px 0px 0px 0px",
            color: "black",
          }}
          startIcon={
            <Box
              component={"img"}
              src={previousIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          variant="contained"
          color="lockItIn"
          onClick={() => {
            saveReferenceInfo(2);
          }}
        >
          {"study life"}
        </Button>
        <Button
          onClick={() => {
            saveReferenceInfo(4);
            handleComplete(3);
          }}
          sx={{
            boxShadow: 0,
            padding: "0px",
            fontSize: "14px",
            width: "150px",
            height: "60px",
            borderRadius: "0px 10px 0px 0px",
          }}
          endIcon={
            <Box
              component={"img"}
              src={nextIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          variant="contained"
          color="nandos"
        >
          {"finalise CV"}
        </Button>
      </Box>
      <SaveAndExit
        handleOpen={handleOpenNext}
        show={saveAndExit}
        changeStep={changeStep}
      />
    </>
  );
}
