import {
  Box,
  Button,
  Card,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PlayButton from "../../common/PlayButton";
import flip from "../../../assets/NewFlipIcon.svg";
import { getUserColor, splitString } from "../../../utils/Common";
import videoPlaceholder from "../../../assets/VideoPlaceholder.png";
import YouTubeEmbed from "../contact/YouTubeEmbed";

export default function SolutionCardFront({
  solution,
  setOpenReviewVideoDialog,
  setisFlipped,
  setVideoLink,
  index,
  solutionlength,
  currentPageJobsCount,
  mobileText,
}) {
  const theme = useTheme();
  const VideoRef = useRef(null);
  const videoIdRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const solutionType = splitString(solution?.solution_types);

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
      className="cardboxdetail"
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {solution?.user_types?.split(",")?.map((item, index) => {
          return (
            <Button
              variant="contained"
              color={getUserColor(item)}
              sx={{
                width: "75px",
                height: "45px",
                borderRadius:
                  (index === 0 && "0 0 0 10px") ||
                  (index === solution?.user_types?.split(",")?.length - 1 &&
                    "0 25px 0 0"),
                padding: "0 7px !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                boxShadow: "none !important",
                cursor: "default",
              }}
            >
              {item}
            </Button>
          );
        })}
      </Box>
      <Box sx={{ padding: "9px 27px 17px 13px" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            marginBottom: "4px",
          }}
        >
          Industry challenge…
        </Typography>
        <Tooltip
          disableInteractive
          title={solution?.industry_challenge}
          placement="top-end"
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              marginBottom: "8.5px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              height: "62px",
            }}
          >
            {solution?.industry_challenge}
          </Typography>
        </Tooltip>
        <Box sx={{ marginBottom: "10.5px", display: "flex", gap: "5px" }}>
          {/*<NewSlider
            items={solutionType?.sort((a, b) => a?.length - b?.length) || []}
            color={"recentButton"}
            hideTagsAfter={3}
            height="25px"
            job={solution}
            index={index}
            jobslength={solutionlength}
            id={solution?.solution_id}
            currentPageJobsCount={currentPageJobsCount}
            mobileText={mobileText}
          />*/}
          {solution?.solution_types?.split(",")?.map((item, index) => {
            return (
              <Button
                variant="contained"
                color={"recruitButton"}
                sx={{
                  minWidth: "fit-content",
                  height: "25px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontWeight: theme.typography.Bold,
                  padding: "0 7px !important",
                  boxShadow: "none !important",
                  cursor: "default",
                }}
              >
                {item}
              </Button>
            );
          })}
        </Box>
        <Tooltip
          disableInteractive
          title={solution?.industry_challenge_detail}
          placement="top-end"
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              marginBottom: "8px",
              height: "71px",
              width: "307px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 4,
              wordBreak: "break-word",
            }}
          >
            {solution?.industry_challenge_detail}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            height: "240px",
            width: "100%",
            // flexGrow: 1,
            // padding: "12px 12px 3px 12px",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // position: "relative",
            // height: "240px",
          }}
        >
          {solution?.challenge_video !== "No URL" &&
          !isPlaying &&
          solution?.challenge_link_flag === false ? (
            <PlayButton />
          ) : null}

          {/* {solution?.challenge_video !== "No URL"  ? (
            <video
              autoPlay={false}
              loops
              preload="auto"
              style={{
                width: `100%`,
                height: "240px",
                borderRadius: "25px",
                objectFit: "cover",
                cursor: disabledButton ? "wait" : "pointer",
              }}
              controls={false}
              ref={VideoRef}
              onClick={() => {
                // if (!disabledButton) {
                //   if (VideoRef?.current?.paused) {
                //     VideoRef?.current?.play();
                //     setIsPlaying(true);
                //   } else {
                //     VideoRef?.current?.pause();
                //     setIsPlaying(false);
                //   }
                // }
                setVideoLink(solution?.challenge_video);
                setOpenReviewVideoDialog(true);
              }}
              onLoadedData={() => {
                setDisabledButton(false);
              }}
              onEnded={() => setIsPlaying(false)}
            >
              <source src={solution?.challenge_video} type="video/mp4" />
            </video>
          ) : (
            <Box
              component={"img"}
              src={videoPlaceholder}
              sx={{
                height: "70px",
                width: "70px",
              }}
            />
          )} */}
          {solution?.challenge_video_flag === true &&
            solution?.challenge_video !== "No URL" && (
              <video
                autoPlay={false}
                loops
                preload="auto"
                style={{
                  width: `100%`,
                  height: "240px",
                  borderRadius: "25px",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                }}
                controls={false}
                ref={VideoRef}
                onClick={() => {
                  setVideoLink(solution?.challenge_video);
                  setOpenReviewVideoDialog(true);
                }}
                onLoadedData={() => {
                  setDisabledButton(false);
                }}
                onEnded={() => setIsPlaying(false)}
              >
                <source src={solution?.challenge_video} type="video/mp4" />
              </video>
            )}
          {solution?.challenge_link_flag === true &&
            solution?.challenge_video_link !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingX: "20px",
                  paddingTop: "0px",
                  borderRadius: "25px",
                }}
              >
                <YouTubeEmbed
                  videoId={
                    solution?.challenge_video_link?.match(videoIdRegex)?.[1] ||
                    "LuKFDQjq6NE"
                  }
                />
              </Box>
            )}
          {(solution?.challenge_link_flag === false ||
            solution?.challenge_link_flag === null) &&
            (solution?.challenge_video_flag === false ||
              solution?.challenge_video_flag === null) && (
              <Box
                component={"img"}
                src={videoPlaceholder}
                sx={{
                  height: "70px",
                  width: "70px",
                }}
              />
            )}
        </Box>
      </Box>
      <Button
        variant="contained"
        // color="flipButton"
        // color="#38A8AE"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisFlipped(true)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </Card>
  );
}
