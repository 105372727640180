import {
  Box,
  Button,
  Card,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useRef, useState } from "react";
import PlayButton from "../../common/PlayButton";
import flip from "../../../assets/NewFlipIcon.svg";
import likeThis from "../../../assets/Padding Excluded/Blue_Like.svg";
import likeThisWhite from "../../../assets/WhiteIcons/Grey_Like.svg";
import loveThis from "../../../assets/Padding Excluded/Red_I_Love_This.svg";
import loveThisWhite from "../../../assets/WhiteIcons/Grey_Love_This.svg";
import reject from "../../../assets/Padding Excluded/Red_Reject.svg";
import rejectWhite from "../../../assets/WhiteIcons/Grey_Rejected.svg";
import videoPlaceholder from "../../../assets/VideoPlaceholder.png";
import { useDispatch } from "react-redux";
import { updateReaction } from "../../../redux/admin/maintenance";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import YouTubeEmbed from "../contact/YouTubeEmbed";

export default function SolutionCardBack({
  solution,
  setOpenReviewVideoDialog,
  setisFlipped,
  setVideoLink,
  index,
  solutionlength,
  currentPageJobsCount,
  mobileText,
}) {
  const theme = useTheme();
  const VideoRef = useRef(null);
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  // const [liked, setLiked] = useState(false);
  // const [disLiked, setDisLiked] = useState(false);
  // const [lovedThis, setLovedThis] = useState(false);
  const videoIdRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const getUserColor = (value) => {
    if (value === "Employers") {
      return "tymeColor";
    } else if (value === "Recruiters") {
      return "nandos";
    } else if (value === "Candidates") {
      return "yoco";
    } else if (value === "Promoters") {
      return "filterButtons";
    }
  };

  const truncateString = (str) => {
    if (str?.length > 80) {
      return str?.substring(0, 80) + "...";
    }
    return str;
  };

  const updateSolutionReaction = async (text) => {
    try {
      let data = {
        solution_id: solution?.solution_id,
        like: text === "like" ? true : false,
        dislike: text === "dislike" ? true : false,
        loveit: text === "loveit" ? true : false,
      };
      const { payload } = await dispatch(updateReaction(data));
      if (payload?.status === "success") {
        if (text === "like") {
          setSelectedValue("liked");
        } else if (text === "dislike") {
          setSelectedValue("disLiked");
        } else if (text === "loveit") {
          setSelectedValue("lovedThis");
        }
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
      className="cardboxdetail"
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        {solution?.user_types?.split(",")?.map((item, index) => {
          return (
            <Button
              variant="contained"
              color={getUserColor(item)}
              sx={{
                width: "75px",
                height: "45px",
                borderRadius:
                  (index === 0 && "0 0 0 10px") ||
                  (index === solution?.user_types?.split(",")?.length - 1 &&
                    "0 25px 0 0"),
                padding: "0 7px !important",
                fontSize: "12px",
                fontWeight: theme.typography.Bold,
                boxShadow: "none !important",
                cursor: "default",
              }}
            >
              {item}
            </Button>
          );
        })}
      </Box>
      <Box sx={{ padding: "9px 27px 17px 13px" }}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            marginBottom: "4px",
          }}
        >
          …our solution
          <Box
            // key={btn?.id}
            component={"img"}
            src={selectedValue === "liked" ? likeThis : likeThisWhite}
            sx={{
              height: "15px",
              width: "11px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              updateSolutionReaction("like");
            }}
          />
          <Box
            // key={btn?.id}
            component={"img"}
            src={selectedValue === "disLiked" ? reject : rejectWhite}
            sx={{
              height: "15px",
              width: "11px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              updateSolutionReaction("dislike");
            }}
          />
          <Box
            // key={btn?.id}
            component={"img"}
            src={selectedValue === "lovedThis" ? loveThis : loveThisWhite}
            sx={{
              height: "15px",
              width: "17px",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              updateSolutionReaction("loveit");
            }}
          />
        </Typography>

        <Tooltip
          disableInteractive
          title={solution?.crayon_solution}
          placement="top-end"
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              marginBottom: "8.5px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              height: "62px",
            }}
          >
            {solution?.crayon_solution}
          </Typography>
        </Tooltip>

        <Box sx={{ marginBottom: "10.5px", display: "flex", gap: "5px" }}>
          {/*<NewSlider
          items={solutionType?.sort((a, b) => a?.length - b?.length) || []}
          color={"recentButton"}
          hideTagsAfter={3}
          height="25px"
          job={solution}
          index={index}
          jobslength={solutionlength}
          id={solution?.solution_id}
          currentPageJobsCount={currentPageJobsCount}
          mobileText={mobileText}
        />*/}
          {solution?.solution_types?.split(",")?.map((item, index) => {
            return (
              <Button
                variant="contained"
                color={"recruitButton"}
                sx={{
                  minWidth: "fit-content",
                  height: "25px",
                  borderRadius: "5px",
                  fontSize: "12px",
                  fontWeight: theme.typography.Bold,
                  padding: "0 7px !important",
                  boxShadow: "none !important",
                  cursor: "default",
                }}
              >
                {item}
              </Button>
            );
          })}
        </Box>
        <Tooltip
          disableInteractive
          title={solution?.crayon_solution_detail}
          placement="top-end"
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: theme.typography.Medium,
              marginBottom: "8px",
              height: "71px",
              width: "307px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 4,
              wordBreak: "break-word",
            }}
          >
            {solution?.crayon_solution_detail}
          </Typography>
        </Tooltip>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            height: "240px",
            width: "100%",
            // flexGrow: 1,
            // padding: "12px 12px 3px 12px",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // position: "relative",
            // height: "240px",
          }}
        >
          {solution?.solution_video !== "No URL" &&
          !isPlaying &&
          solution?.solution_link_flag === false ? (
            <PlayButton />
          ) : null}
          {solution?.solution_video_flag === true &&
            solution?.solution_video !== "No URL" && (
              <video
                autoPlay={false}
                loops
                preload="auto"
                style={{
                  width: `100%`,
                  height: "240px",
                  borderRadius: "25px",
                  objectFit: "cover",
                  cursor: disabledButton ? "wait" : "pointer",
                }}
                controls={false}
                ref={VideoRef}
                onClick={() => {
                  // if (!disabledButton) {
                  //   if (VideoRef?.current?.paused) {
                  //     VideoRef?.current?.play();
                  //     setIsPlaying(true);
                  //   } else {
                  //     VideoRef?.current?.pause();
                  //     setIsPlaying(false);
                  //   }
                  // }
                  setVideoLink(solution?.solution_video);
                  setOpenReviewVideoDialog(true);
                }}
                onLoadedData={() => {
                  setDisabledButton(false);
                }}
                onEnded={() => setIsPlaying(false)}
              >
                <source src={solution?.solution_video} type="video/mp4" />
              </video>
            )}
          {solution?.solution_link_flag === true &&
            solution?.solution_video_link !== "" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  paddingX: "20px",
                  paddingTop: "0px",
                  borderRadius: "25px",
                }}
              >
                <YouTubeEmbed
                  videoId={
                    solution?.solution_video_link?.match(videoIdRegex)?.[1] || "LuKFDQjq6NE"
                  }
                />
              </Box>
            )}
          {(solution?.solution_link_flag === false ||
            solution?.solution_link_flag === null) &&
            (solution?.solution_video_flag === false ||
              solution?.solution_video_flag === null) && (
              <Box
                component={"img"}
                src={videoPlaceholder}
                sx={{
                  height: "70px",
                  width: "70px",
                }}
              />
            )}
        </Box>
      </Box>
      <Button
        variant="contained"
        // color="flipButton"
        // color="#38A8AE"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </Card>
  );
}
