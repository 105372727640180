import { Box, Button, useTheme } from "@mui/material";
import React from "react";
import flip from "../../../assets/NewFlipIcon.svg";
import MUIRadialChart from "../../common/MUIRadialChart";
import FollowIcon from "../../../assets/Padding Excluded/Black_Company Followers.svg";

export default function CompanyStatsBackCard({
  companyDetail,
  setisHorizontalFlipped,
  calculateHeight,
}) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          height: { xs: "212px", tablet: "225px", lg: "280px", mlg: "225px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="tymeColor"
            sx={{
              height: "45px",
              minWidth: "125px",
              maxWidth: "125px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              borderRadius: "25px 0px 25px 0px",
              padding: "0px",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            the stats
          </Button>
        </Box>

        <Box
          sx={{
            display: { xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "18px",
            width: "100%",
            boxSizing: "border-box",
            marginTop: { xs: "10px", tablet: "0px", lg: "35px", lg1440: "0px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: {
                xs: "space-around",
                tablet: "flex-start",
                md: "center",
              },
              gap: {
                sm: "3px",
                tb: "30px",
                lgmm: "10px",
                mlg: "100px",
                xl: "47px",
                xll: "47px",
              },
              width: { xs: "90%", tablet: "71%", md: "100%" },
            }}
          >
            <MUIRadialChart
              size={calculateHeight()}
              max={500}
              countFontSize={20}
              labelFontSize={12}
              chartName={"applications"}
              value={companyDetail?.TotalUserCount}
              color={"activeJobNew"}
            />
            <MUIRadialChart
              size={calculateHeight()}
              max={100}
              countFontSize={20}
              labelFontSize={12}
              chartName={"shortlistings"}
              value={companyDetail?.TotalUserShortlisted}
              color={"averageMatch"}
            />
            <MUIRadialChart
              size={calculateHeight()}
              max={50}
              countFontSize={20}
              labelFontSize={12}
              chartName={"interviews"}
              value={companyDetail?.TotalUserInterviewed}
              color={"shortListings"}
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: 0,
            right: 0,
            alignItems: "flex-end",
            height: "100%",
            // marginBottom: "20px",
          }}
        >
          <Button
            variant="contained"
            color="checkers"
            sx={{
              height: "45px",
              width: "125px",
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              borderRadius: "10px 0px 25px 0px",
              padding: "0px",
              ":hover": {
                boxShadow: "none !important",
              },
              ".MuiButton-startIcon": {
                marginRight: "10px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 20,
                  width: 20,
                }}
                src={FollowIcon}
              />
            }
            // onClick={() => setOpenCameraDialog(prevState => !prevState)}
          >
            follow
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        // color="redButton100"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </>
  );
}
