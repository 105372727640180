import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import CloseIcon from "../../../common/CloseIcon";
import crayBot from "../../../../assets/CrayBotIcons/Crayon Craybot Notification-07.svg";
import lockIcon from "../../../../assets/Padding Excluded/Black_Lock.svg";
import darkIcon from "../../../../assets/Padding Excluded/Black_Trash_Delete_2.svg";
import eyeIcon from "../../../../assets/Black_View.svg";
import deleteIcon from "../../../../assets/Icons/CandidateProfile/Black_Trash_Delete_1.svg";
import locale from "../../../../i18n/locale";
import job_volume from "../../../../assets/Padding Excluded/Crayon Icon_Promote.svg";

export default function Settings({
  onClose,
  setResetPasswordButton,
  settingsRef,
  setHideItems,
  setGoDark,
  setDeleteProfile,
  profile
}) {
  const i18n = locale.en;
  const theme = useTheme();
  return (
    <Box sx={{ width: "300px", height: "340px", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="greyPlayButton"
          sx={{
            width: "125px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 0 0",
            color: "black",
            padding: "0px",
          }}
        >
          nuts and bolts
        </Button>
        <Button
          variant="contained"
          color={profile?.active ? "playButton" : "nandos" }
          sx={{
            width: "60px !important",
            minWidth: "60px !important",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "0 0 10px 0",
          }}
        >
          {profile?.active ? "active" : "not seeking"}
        </Button>

        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "11px",
          padding: "6px 0 23px 20px",
        }}
      >
        <Box sx={{ width: "143px" }}>
          <Box
            component={"img"}
            src={crayBot}
            sx={{ height: "100px", width: "143px" }}
          />
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Regular,
              width: "100%",
            }}
          >
            {i18n["myProfile.settingText"]}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "125px",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
          }}
        >
          <Button
            variant="contained"
            color="safair"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              // boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0 0 0 10px",
              gap: "10px",
              justifyContent: "flex-start",
              textAlign: "center",
            }}
            startIcon={
              <Box
                component={"img"}
                src={job_volume}
                sx={{ height: "20px", width: "18px" }}
              />
            }
            onClick={() => {
              // setGoDark(settingsRef.current);
              // onClose();
            }}
          >
            share code
          </Button>
          <Button
            variant="contained"
            color="yoco"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              // boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0 4px 0 0",
              justifyContent: "flex-end",
            }}
            startIcon={
              <Box
                component={"img"}
                src={lockIcon}
                sx={{ height: "20px", width: "13px" }}
              />
            }
            onClick={() => {
              setResetPasswordButton(settingsRef.current);
              onClose();
            }}
          >
            reset password
          </Button>
          <Button
            variant="contained"
            color="checkers"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              // boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0px",
              justifyContent: "flex-start",
            }}
            startIcon={
              <Box
                component={"img"}
                src={eyeIcon}
                sx={{ height: "40px", width: "40px" }}
              />
            }
            onClick={() => {
              setHideItems(settingsRef.current);
              onClose();
            }}
          >
            hide items
          </Button>
          <Button
            variant="contained"
            color="peachPayment"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              // boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0 0 0 10px",
              gap: "10px",
              justifyContent: "flex-start",
            }}
            startIcon={
              <Box
                component={"img"}
                src={darkIcon}
                sx={{ height: "20px", width: "20px" }}
              />
            }
            onClick={() => {
              setGoDark(settingsRef.current);
              onClose();
            }}
          >
            go dark
          </Button>
          <Button
            variant="contained"
            color="nandos"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              width: "125px",
              height: "45px",
              // boxShadow: `0px 5px 10px ${theme?.palette?.boxShadow?.main}`,
              borderRadius: "10px 0 0 10px",
              padding: "0px",
            }}
            startIcon={
              <Box
                component={"img"}
                src={deleteIcon}
                sx={{ height: "20px", width: "13px" }}
              />
            }
            onClick={() => {
              setDeleteProfile(settingsRef.current);
              onClose();
            }}
          >
            delete profile
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
