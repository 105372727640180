import { Box, Button, Paper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useViewport from "../../common/ViewPort";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import { useDispatch } from "react-redux";
import {
  StyledButtonLeft,
  StyledButtonRight,
} from "../../../utils/CommonComponent";
import { useTheme } from "@emotion/react";
import PriceIsRightModal from "./PriceIsRightModal";
import constructionIcon from "../../../assets/PricingUnderConstructIcon.svg";
import { setSideFilter } from "../../../redux/login/loginSlice";
import { useSelector } from "react-redux";
const Pricing = () => {
  const { width } = useViewport();
  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);
  const [openPriceRightModal, setOpenPriceRightModal] = useState(true);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const theme = useTheme();
  const { isSideFilter } = useSelector((state) => state.login);

  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true));
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false));
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);

  if (width === 0) {
    return null;
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        height: { xs: "87%", md24: "98%", lg: "98%" },
        position:"relative"

      }}
    >
            {width < 768 && width !==0 && isSideFilter && 
      <Box sx={{position:"absolute", top:"0", left:"0", height:"100%", width:"100%", background:"rgba(0,0,0, .2)"}}></Box>
      }
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
          zIndex: { xs: "99999", tablet: "30" },
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
            zIndex: "99999",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
          }}
        >
          {isSideFilter ? <LeftArrow /> : <RightArrow />}
        </Button> */}
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
          >
            <StyledButtonLeft
              onClick={() => setActiveStep(1)}
              variant={"contained"}
              color={activeStep === 1 ? "activeButton" : "displayCompanyButton"}
              sx={{
                width: isSideFilter ? "125px" : "30px",
                minWidth: isSideFilter ? "125px" : "30px",
                borderRadius: "0 10px 10px 0",
                border: 0,
                padding: "0",
                fontWeight:
                  activeStep === 1
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"I am most like..."}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(2)}
              variant={"contained"}
              color={activeStep === 2 ? "activeButton" : "displayCompanyButton"}
              sx={{
                borderRadius: "0 10px 10px 0",
                padding: "0",

                border: 0,
                // width: isSideFilter
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: isSideFilter ? "125px" : "30px",
                minWidth: isSideFilter ? "125px" : "30px",
                fontWeight:
                  activeStep === 2
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"Budget Bennie"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(3)}
              variant={"contained"}
              color={activeStep === 3 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                padding: "0",
                // width: isSideFilter
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: isSideFilter ? "125px" : "30px",
                minWidth: isSideFilter ? "125px" : "30px",
                fontWeight:
                  activeStep === 3
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                border: 0,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"No time Nigel"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(4)}
              variant={"contained"}
              color={activeStep === 4 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                padding: "0",
                // width: isSideFilter
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: isSideFilter ? "125px" : "30px",
                minWidth: isSideFilter ? "125px" : "30px",
                fontWeight:
                  activeStep === 4
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                border: 0,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"Talent Ted"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(5)}
              variant={"contained"}
              color={activeStep === 5 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                padding: "0",
                // width: isSideFilter
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: isSideFilter ? "125px" : "30px",
                minWidth: isSideFilter ? "125px" : "30px",
                fontWeight:
                  activeStep === 5
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                border: 0,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"Always hiring Annie"}
            </StyledButtonLeft>
            <StyledButtonLeft
              onClick={() => setActiveStep(6)}
              variant={"contained"}
              color={activeStep === 6 ? "activeButton" : "displayCompanyButton"}
              sx={{
                mb: 1,
                borderRadius: "0 10px 10px 0",
                padding: "0",
                // width: isSideFilter
                //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                //   : "30px",
                width: isSideFilter ? "125px" : "30px",
                minWidth: isSideFilter ? "125px" : "30px",
                fontWeight:
                  activeStep === 6
                    ? theme.typography.Bold
                    : theme.typography.Regular,
                border: 0,
                "&:hover": {
                  backgroundColor: theme?.palette?.activeButton?.main,
                  color: theme?.palette?.white,
                  fontWeight: theme.typography.Bold,
                },
              }}
            >
              {"Recruiter Reggie"}
            </StyledButtonLeft>
          </Box>
          //   <Box
          //     sx={{
          //       display: "flex",
          //       flexDirection: "column",
          //       gap: "10px",
          //       height: {
          //         xs: `calc(100vh - 95px)`,
          //         sm: `calc(100vh - 120px )`,
          //         md: `calc(100vh - 120px)`,
          //         lg: `calc(100vh - 135px)`,
          //         xl: `calc(100vh - 155px)`,
          //         xxl: "calc(100vh - 175px)",
          //       },
          //       width: `${width < 768 && "135px"}`,
          //       paddingTop: `${width < 768 && "10px"}`,
          //       background: `${width > 0 && width < 768 && "#EBECF3"}`,
          //       boxShadow: `${
          //         width > 0 && width < 768 && "0px 5px 10px #00000029"
          //       }`,
          //       opacity: `${width < 768 && "1"}`,
          //       borderRadius: `${width < 768 && "10px"}`,
          //       overflowX: "hidden",
          //       overflowY: isSideFilter ? "scroll" : "unset",
          //     }}
          //     className="filterSec"
          //   >
          //     <Box
          //       sx={{
          //         // maxWidth: `${width < 768 && "150px"}`,
          //         maxWidth: "125px",
          //       }}
          //     >
          //       <ButtonPanel
          //         selected={selectedUserType?.current}
          //         panelData={CONTACT_TYPE}
          //         side="left"
          //         color="filterButtons"
          //         onChangeFilter={onChangeFilter}
          //       />
          //     </Box>
          //     <style>
          //       {`.filterSec::-webkit-scrollbar {
          //           display: none; /* Set the background color of the scrollbar */
          //         }`}
          //     </style>
          //   </Box>
        )}
      </Box>
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          // zIndex:isSideFilter && -1,
          zIndex:width < 768 && isSideFilter && -1,
          justifyContent: "center",
          //   width: `calc(100vw - 255px)`,
          position: "sticky",
          background: "#fff",
          marginTop: "55px",
          marginX: "30px",
          borderRadius: "25px",
          marginBottom: "30px",
        }}
        className="grigBox"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{ width: "150px", height: "150px", marginBottom: "40px" }}
            src={constructionIcon}
          />
          <Box
            sx={{
              textAlign: "center",
              fontSize: "20px",
              fontWeight: theme.typography.Bold,
              marginBottom: "20px",
            }}
          >
            Under construction…
          </Box>
          <Box
            sx={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              maxWidth: "450px",
            }}
          >
            Our pricing page will drop in a couple of days (the page, not the
            price). Check in later for more info.
          </Box>
        </Box>

        {openPriceRightModal && (
          <PriceIsRightModal
            open={openPriceRightModal}
            setOpen={() => {
              setOpenPriceRightModal(false);
            }}
          />
        )}
      </Box>

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: { xs: "99999", tablet: "30" },
          // width: isSideFilter && "150px",
          right: "0",
          // width: isSideFilter
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
            zIndex: "99999",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
          }}
        >
          {isSideFilter ? <RightArrow /> : <LeftArrow />}
        </Button> */}
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                    display:none;
                  }
                  `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"activeButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: leftExpanded
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: isSideFilter ? "125px" : "30px",
                  minWidth: isSideFilter ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"...I am an"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: isSideFilter
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: isSideFilter ? "125px" : "30px",
                  minWidth: isSideFilter ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"Employer"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: isSideFilter
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: isSideFilter ? "125px" : "30px",
                  minWidth: isSideFilter ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"Recruiter"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: isSideFilter
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: isSideFilter ? "125px" : "30px",
                  minWidth: isSideFilter ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"Candidate"}
              </StyledButtonRight>
              <StyledButtonRight
                onClick={() => {}}
                variant={"contained"}
                color={"displayCompanyButton"}
                sx={{
                  borderRadius: "10px 0 0 10px",
                  border: 0,
                  // width: isSideFilter
                  //   ? { xs: "150px", lg: "150px", mlg: "150px", xl: "150px" }
                  //   : "30px",
                  width: isSideFilter ? "125px" : "30px",
                  minWidth: isSideFilter ? "125px" : "30px",
                  fontWeight:
                    activeStep === 2
                      ? theme.typography.Bold
                      : theme.typography.Regular,
                  "&:hover": {
                    backgroundColor: theme?.palette?.activeButton?.main,
                    color: theme?.palette?.white,
                    fontWeight: theme.typography.Bold,
                  },
                }}
              >
                {"Promoter"}
              </StyledButtonRight>
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Pricing;
