import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Button, Typography, useTheme } from "@mui/material";
import jobVideoicon from "../../../assets/CrayBotIcons/Crayon Job Video Character.svg";
import myVideoicon from "../../../assets/CrayBotIcons/Crayon Cam Video Charactersvg.svg";
// import jobVideoHeaderLogo from "../../../assets/Padding Excluded/White_jobVideo.svg";
// import myVideoHeaderLogo from "../../../assets/Padding Excluded/White_myVideo.svg";
import jobVideoHeaderLogo from "../../../assets/Cam/JobVideoIcon.svg";
import myVideoHeaderLogo from "../../../assets/Cam/MyVideoIcon.svg";
import PlayIcon from "../../../assets/Cam/PlayIcon.svg";
import RollCamera from "../../../assets/Cam/RollCamerIcon.svg";

import whiteRecord from "../../../assets/Padding Excluded/WhiteRecord.svg";
import { getEmployerMyCamVideo } from "../../../redux/employer/myJobsSlice";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import { getProfilePercentage } from "../../../redux/employer/empJobListing";
import CustomDialog from "../CustomDialog";
import ProfileNotCompleted from "../ProfileNotCompleted";
import locale from "../../../i18n/locale";
import useViewport from "../ViewPort";

export default function EmployerMyVideoList({
  handleChangeStep,
  isVideoUploaded,
  setIsVideoUploaded,
  setActiveStep,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const { width } = useViewport();

  // const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [profilePercentage, setProfilePercentage] = useState({
    myInfo: 0,
    companyInfo: 0,
    myPlan: 0,
    billing: 0,
  });
  // const getVideo = async () => {
  //   setLoading(true);
  //   const { payload } = await dispatch(getEmployerMyCamVideo());
  //   if (payload?.status === "success") {
  //     if (
  //       payload?.data?.length === 0 ||
  //       (payload?.data?.length > 0 &&
  //         payload?.data?.filter((item) => item?.default)?.[0]?.video_url ===
  //           "No URL")
  //     ) {
  //       setIsVideoUploaded(false);
  //     } else {
  //       setIsVideoUploaded(true);
  //     }
  //   } else {
  //     dispatch(
  //       setAlert({
  //         show: true,
  //         type: ALERT_TYPE?.ERROR,
  //         msg: "No video",
  //       })
  //     );
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   getVideo();
  // }, []);

  return (
    <Box
      flexDirection={{ xs: "column", lg: "row" }}
      sx={{
        width: "100%",
        display: "flex",
        gap: "25px",
        paddingBottom: "30px",
        height: { xs: "auto", md: "calc(100% - 10%)" },
        marginTop: width <= 768 || width !== 0 ? "55px" : "55px",
      }}
    >
      <Box
        width={{ xs: "100%", lg: "50%" }}
        sx={{
          background: "#FFF",
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="precium"
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",
            gap: "5px",
            padding: "6px 6px",
            fontWeight: theme.typography.Bold,
          }}
        >
          <Box
            component={"img"}
            src={myVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          {i18n["empMyCam.companyVideo"]}
        </Button>
        <Box
          sx={{
            padding: { xs: "10px 20px 20px", md: "30px 20px 57px" },
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
            gap: "25px",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", md: "70%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                textAlign: "center",
              }}
            >
              Showcase your biz!
            </Typography>
          </Box>

          <Box
            component={"img"}
            src={myVideoicon}
            sx={{
              height: {
                xs: "200px",
                xs360: "200px",
                xs390: "200px",
                md: "250px",
                md24: "250px",
                lgm: "250px",
              },
              maxHeight: "250px",
            }}
          />
          <Box
            sx={{
              width: { xs: "90%", md: "80%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Give us your company elevator pitch! Record or upload a short
              intro video, highlighting the nature of your business, what makes
              it great to work at, growth plans, company culture, etc.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Adding your company video increases your chances of attracting top
              talent and is often the first thing looked at by candidates!
            </Typography>
          </Box>
        </Box>
        {isVideoUploaded ? (
          <Box sx={{ width: "100%" }}>
            <Button
              variant="contained"
              color="yoco"
              sx={{
                width: "50%",
                height: "60px",
                borderRadius: "0 0 0px 25px",
                fontSize: "15px",
                gap: "10px",
                fontWeight: theme.typography.Bold,
              }}
              onClick={() => setActiveStep(2)}
            >
              <Box
                component={"img"}
                src={RollCamera}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              roll camera, again
            </Button>
            <Button
              variant="contained"
              color="nandos"
              sx={{
                width: "50%",
                height: "60px",
                borderRadius: "0 0 25px 0",
                fontSize: "15px",
                gap: "10px",
                fontWeight: theme.typography.Bold,
              }}
              onClick={() => handleChangeStep(2)}
            >
              <Box
                component={"img"}
                src={PlayIcon}
                sx={{
                  height: "20px",
                  width: "24px",
                }}
              />
              view video
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="nandos"
            sx={{
              width: "100%",
              height: "60px",
              borderRadius: "0 0 25px 25px",
              fontSize: "15px",
              gap: "10px",
              fontWeight: theme.typography.Bold,
            }}
            onClick={() => handleChangeStep(2)}
          >
            <Box
              component={"img"}
              src={RollCamera}
              sx={{
                height: "20px",
                width: "24px",
              }}
            />
            {isVideoUploaded ? "view video" : "roll camera"}
          </Button>
        )}
      </Box>

      <Box
        sx={{
          background: "#FFF",
          boxShadow: "0px 3px 6px #00000029",
          width: { xs: "100%", lg: "50%" },
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Button
          variant="contained"
          color="peachPayment"
          sx={{
            borderRadius: "25px 0 10px 0",
            width: "150px",
            height: "45px",
            fontSize: "14px",
            fontWeight: theme.typography.Bold,
            gap: "5px",
            padding: "6px 6px",
          }}
        >
          <Box
            component={"img"}
            src={jobVideoHeaderLogo}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          {i18n["empMyCam.jobvideos"]}
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: { xs: "10px 20px 20px", md: "10px 20px 57px" },
            gap: "25px",
          }}
        >
          <Box
            sx={{
              width: { xs: "90%", md: "70%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                textAlign: "center",
              }}
            >
              Let’s keep it reels!
            </Typography>
          </Box>

          <Box
            component={"img"}
            src={jobVideoicon}
            sx={{
              height: {
                // xs: "200px",
                // xs360: "200px",
                // xs390: "200px",
                // md: "300px",
                xs: "200px",
                xs360: "200px",
                xs390: "200px",
                md: "250px",
                md24: "250px",
                lgm: "250px"
              },
              width: "auto",
              maxHeight: "270px",
            }}
          />
          <Box
            sx={{
              width: { xs: "90%", md: "80%" },
              // height: "200px",
              flexDirection: "column",
              alignItems: "center",
              display: "flex",
              gap: "20px",
              marginBottom: { xs: "10px", md: "" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              You can include a video specific to each job. Here you will get to
              highlight the key requirements of the job, what sort of candidate
              should apply and any nuances not captured within the job spec.
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: theme.typography.Regular,
              }}
            >
              Make sure to keep it relevant to the particular job and rather
              highlight your overall business via the company video.
            </Typography>
          </Box>
        </Box>
        <Button
          variant="contained"
          color="videoButton"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
            fontSize: "15px",
            gap: "10px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => handleChangeStep(3)}
        >
          <Box
            component={"img"}
            src={PlayIcon}
            sx={{
              height: "20px",
              width: "24px",
            }}
          />
          view video(s)
        </Button>
      </Box>
    </Box>
  );
}
