import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { StyledBox } from "../../../../utils/CommonComponent";
import { setAlert } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  CULTURE_MARKS,
  ERROR_MSG,
} from "../../../../utils/Constants";
import {
  employerPersonality,
  talentPersonality,
} from "../../../../redux/admin/jobsSlice";
import { validateSchema } from "../../../../utils/Common";
import * as Yup from "yup";

import challanger from "../../../../assets/Personality Logos/crayon_personality_grey_challeneger.svg";
import character from "../../../../assets/Personality Logos/crayon_personality_character.svg";
import contemplator from "../../../../assets/Personality Logos/crayon_personality_collaborator.svg";
import contemplator1 from "../../../../assets/Personality Logos/crayon_personality_contemplator.svg";

export default function EditEmployerPersonlaity({
  show,
  talentContent,
  seteditPersonality,
  setPersonalitiesData,
  personalitiesData,
  setButtonArr,
  buttonArr,
  setEmployerData,
  allEmployerData,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [errors, setErrors] = useState([]);

  const textValue = (value) => {
    return value;
  };

  const personalitySchema = Yup.object().shape({
    primary_personality_id: Yup.string()
      .nullable()
      .required("Primary Personality is Required."),
    secondary_personality_id: Yup.string()
      .nullable()
      .required("Shadow Personality is Required."),
    grit_score: Yup.number().nullable().required("Grit Score is Required."),
  });

  const handleClose = () => {
    setErrors([]);
    seteditPersonality((prev) => !prev);
  };

  const addPersonality = async () => {
    let { primary_personality_id, secondary_personality_id, grit_score } =
      personalitiesData;

    let testErrorState = {
      primary_personality_id: primary_personality_id,
      secondary_personality_id: secondary_personality_id,
      grit_score: grit_score,
    };

    validateSchema(personalitySchema, testErrorState)
      .then(() => {
        try {
          const data = {
            ...personalitiesData,
            user_id: talentContent?.user_id,
          };
          dispatch(employerPersonality(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              setEmployerData(
                allEmployerData?.map((item) => {
                  if (item?.user_id === talentContent?.user_id) {
                    return {
                      ...item,
                      primary_personality_id:
                        personalitiesData?.primary_personality_id,
                      secondary_personality_id:
                        personalitiesData?.secondary_personality_id,
                      grit_score: personalitiesData?.grit_score,
                    };
                  }
                  return item;
                })
              );
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.SUCCESS,
                  msg: "Personality Added Successfully",
                })
              );
              handleClose();
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.ERROR,
                  msg: "Something went wrong! please relaod the window",
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const rangeHandler = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newPersonalitiesData = {
      ...personalitiesData,
      [name]: value,
    };

    setErrors(errors?.filter((item) => item?.key !== name));
    setPersonalitiesData(newPersonalitiesData);
  };

  const handleChange = (event, value, id) => {
    const {
      target: { name },
    } = event;
    setButtonArr(
      buttonArr?.map((item) =>
        item.id === id
          ? { ...item, activeLabel: name }
          : {
              ...item,
              activeLabel: item.activeLabel === name ? "" : item.activeLabel,
            }
      )
    );

    const newPersonalitiesData = {
      ...personalitiesData,
      [name]: Number(id),
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setPersonalitiesData(newPersonalitiesData);
  };

  return (
    <Dialog
      open={show}
      hideButton={false}
      maxWidth="xs"
      fullWidth={true}
      showFooter={false}
      scroll="body"
      zIndex='99999999999999999999'
    >
      <DialogTitle onClose={handleClose}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
      </DialogTitle>

      <Box
        sx={{
          height: "fit-content",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 3,
            padding: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            Edit Personality
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Please match and select a personality profile below:
          </Typography>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "50%",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  height: "230px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={challanger}
                />
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={character}
                />
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={contemplator1}
                />
                <StyledBox
                  className="homeImages"
                  alt="Home Image"
                  src={contemplator}
                />
              </Box>

              <Box
                sx={{
                  height: "230px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Challenger
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Character
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Contemplator
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    height: "50px",
                    width: "fit-content",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Collaborator
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                height: "230px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              {buttonArr?.map((item, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                      height: "45px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                      name="primary_personality_id"
                      variant="contained"
                      color={
                        item.activeLabel === "primary_personality_id"
                          ? "lightGreenButton300"
                          : "grayButton200"
                      }
                      mr="8px"
                      onClick={(event) => {
                        handleChange(event, item.name, item.id);
                      }}
                      sx={{
                        border: errors?.find(
                          (error) => error?.key === "primary_personality_id"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: "100px !important",
                        height: "20px",
                        fontWeight: 300,
                        letterSpacing: "0.75px",
                        boxShadow: 0,
                        borderRadius: "5px",
                        color: "white",
                        padding: "0 8px",
                        fontSize: "10px !important",
                      }}
                    >
                      {item?.label}
                    </Button>
                    <Button
                      name="secondary_personality_id"
                      variant="contained"
                      color={
                        item?.activeLabel === "secondary_personality_id"
                          ? "orangeButton"
                          : "grayButton200"
                      }
                      mr="8px"
                      onClick={(event) => {
                        handleChange(event, item?.name, item?.id);
                      }}
                      sx={{
                        border: errors?.find(
                          (error) => error?.key === "secondary_personality_id"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: "100px !important",
                        height: "20px",
                        fontWeight: 300,
                        letterSpacing: "0.75px",
                        boxShadow: 0,
                        borderRadius: "5px",
                        color: "white",
                        padding: "0 8px",
                        fontSize: "10px !important",
                      }}
                    >
                      {item?.label1}
                    </Button>
                  </Box>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
              Enter grit score below:
            </Typography>
            <Slider
              name="grit_score"
              aria-label="Custom marks"
              color="ideaGritScoreSlider"
              value={personalitiesData?.grit_score}
              getAriaValueText={textValue}
              step={1}
              onChange={(event) => rangeHandler(event)}
              valueLabelDisplay="auto"
              valueLabelFormat={textValue}
              marks={CULTURE_MARKS}
              sx={{
                width: "88%",
                ml: 2,
                "& .MuiSlider-rail": {
                  backgroundColor: theme.palette.eyeview100.main,
                  height: "10px",
                  border: errors?.find((error) => error?.key === "grit_score")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                },
                "& .MuiSlider-track": {
                  height: "10px",
                },
                "& .MuiSlider-thumb": {
                  borderRadius: "15%",
                },
              }}
            />
          </Box>
        </Box>
        <Grid
          container
          alignItems="center"
          overflow={"hidden"}
          sx={{
            width: "100%",
            height: 51,
            mt: 2,
          }}
        >
          <Button
            variant="contained"
            id="broad"
            sx={{
              borderRadius: 0,
              width: "100%",
              height: "100%",
              fontSize: "14px",
            }}
            color="redButton100"
            onClick={addPersonality}
          >
            Update
          </Button>
        </Grid>
      </Box>
    </Dialog>
  );
}
