import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import hireTalent from "../assets/HomePage/crayon_hire_talent.gif";
import recruit from "../assets/HomePage/crayon_recruit.gif";
import findWork from "../assets/HomePage/crayon_find_work.gif";
import earnCash from "../assets/HomePage/crayon_earn_cash.gif";
import jobLinkIcon from "../assets/HomePage/JobLinkIcon.svg";
import MainPageGif from "../assets/HomePage/crayon_home_banner.gif";
import { Link, useNavigate } from "react-router-dom";
import useViewport from "../components/common/ViewPort";
import flip from "../assets/NewFlipIcon.svg";
import VideoIcon from "../assets/HomePage/VideoIcon.svg";

export default function HomeNew({
  setComponentToFlip,
  setIsRotate,
  isRotate,
  mobileView,
  setMobileView,
  setisHorizontalFlipped,
}) {
  const { width } = useViewport();
  const navigate = useNavigate();

  const theme = useTheme();
  const [calcPaddingX, setCalcPaddingX] = useState(
    (window.innerHeight * 150) / 1078
  );
  const [calcPaddingY, setCalcPaddingY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [buttonSizeX, setButtonSizeX] = useState(
    (window.innerHeight * 150) / 1078
  );
  const [buttonSizeY, setButtonSizeY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [middleButtonX, setMiddleButtonX] = useState(
    (window.innerHeight * 75) / 1078
  );

  const [middleButtonY, setMiddleButtonY] = useState(
    (window.innerWidth * 150) / 1920
  );

  const [paddingLeftSide, setPaddingLeftSide] = useState(
    (window.innerWidth * 150) / 1920
  );
  const [paddingRightSide, setPaddingRightSide] = useState(
    (window.innerWidth * 150) / 1920
  );

  const getNewWidth = () => {
    setCalcPaddingX((window.innerHeight * 150) / 1078);
    setCalcPaddingY((window.innerWidth * 150) / 1920);
    setButtonSizeX((window.innerHeight * 150) / 1078);
    setButtonSizeY((window.innerWidth * 150) / 1920);
    setPaddingLeftSide((window.innerWidth * 150) / 1920);
    setPaddingRightSide((window.innerWidth * 150) / 1920);
    setMiddleButtonX((window.innerHeight * 75) / 1078);
    setMiddleButtonY((window.innerWidth * 150) / 1920);
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);
  return mobileView ? (
    <>
      <Grid
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          // padding: `${calcPaddingX}px ${calcPaddingY}px`,
          padding: "55px 30px",
        }}
        height={{
          xs: `calc(100vh - 60px)`,
          sm: `calc(100vh - 60px )`,
          md: `calc(100vh - 90px)`,
          mdl: `calc(100vh - 90px)`,
          lg: `calc(100vh - 80px)`,
          mlg: `calc(100vh - 80px)`,
          xl: `calc(100vh - 100px)`,
          xxl: "calc(100vh - 120px)",
        }}
      >
        <Box
          sx={{
            background: "white",
            width: "100%",
            position: "relative",
            height: "100%",
            borderRadius: "25px",
            boxShadow: `0px 5px 10px ${theme.palette.boxShadow.main} !important`,
          }}
        >
          {/* <Button
            variant="contained"
            color="deleteVideoButton"
            sx={{
              width: "20px",
              height: "100px",
              padding: 0,
              zIndex: "999",
              position: "absolute",
              right: 0,
              top: `calc(50% - 50px)`,
              minWidth: "20px",
              fontSize: "20px",
              borderRadius: "10px 0 0 10px",
            }}
            onClick={() => {
              setComponentToFlip(1);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            <Box
              component={"img"}
              src={flip}
              sx={{
                height: "20px",
                width: "15px",
              }}
            />
          </Button> */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    backgroundColor: theme.homepage.hireCandidateNew.main,
                  },
                  backgroundColor: theme.homepage.hireCandidateNew.main,
                  height: `125px`,
                  width: `125px`,
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 25px 0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  textWrap: "nowrap",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={hireTalent}
                    // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                    // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                    heihgt="50px"
                    width="50px"
                  />
                }
                onClick={() => {
                  setComponentToFlip(1);
                  setIsRotate(!isRotate);
                  setisHorizontalFlipped((prev) => !prev);
                }}
              >
                hire talent
              </Button>
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    backgroundColor: theme.homepage.findWorkNew.main,
                  },
                  backgroundColor: theme.homepage.findWorkNew.main,
                  height: `45px`,
                  width: `125px`,
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "0px 0px 10px 10px",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "6px 10px",
                  position: "absolute",
                  top: "0%",
                  left: "50%",
                  transform: "translate(-50%, 0%)",
                  textWrap: "nowrap",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={jobLinkIcon}
                    height="20px"
                    width="22px"
                  />
                  // <Box
                  //   component={"img"}
                  //   alt="talent"
                  //   src={recruit}
                  //   // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                  //   // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                  //   heihgt="50px"
                  //   width="50px"
                  // />
                }
                onClick={() => navigate("/jobs")}
              >
                view jobs
              </Button>
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    backgroundColor: theme.homepage.findWorkNew.main,
                  },
                  backgroundColor: theme.homepage.findWorkNew.main,
                  height: `125px`,
                  width: `125px`,
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "0px 25px 0px 25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  top: 0,
                  right: "0",
                  textWrap: "nowrap",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={findWork}
                    heihgt="50px"
                    width="50px"
                  />
                }
                onClick={() => {
                  setComponentToFlip(2);
                  setIsRotate(!isRotate);
                  setisHorizontalFlipped((prev) => !prev);
                }}
              >
                find work
              </Button>
            </Box>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "50%",
                  // width: { mdl: "70%", lg: "40%", mlg: "50%" },
                  height: "100%",
                  paddingRight: "50px",
                  boxSizing: "border-box",
                }}
              >
                <Box
                  component={"img"}
                  alt="image"
                  display={width < 1024 ? "none" : "block"}
                  src={MainPageGif}
                  marginTop={"30px"}
                  height={{ md24: "50%", lg: "52%", lg1440: "76%" }}
                  // {{
                  //   sm: "85%",
                  //   md: "90%",
                  //   mdl: "88%",
                  //   lg: "92%",
                  //   mlg: "95%",
                  //   xl: "76%",
                  // }}
                  // width="113%"
                  // {{
                  //   sm: "80%",
                  //   md: "100%",
                  //   mdl: "95%",
                  //   lg: "95%",
                  //   mlg: "90%",
                  //   xl: "113%",
                  // }}
                />
              </Box>
              <Box
                sx={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingLeft: `50px`,
                  boxSizing: "border-box",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      sm: "20px",
                      md: "30px",
                      mdl: "32px",
                      mddl: "35px",
                      lg: "37px",
                      lgm: "40px",
                      mlg: "48px",
                      xl: "48px",
                    },
                    fontWeight: "bold",
                    // lineHeight: "66px",
                  }}
                >
                  We connect smiles
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      sm: "20px",
                      md: "30px",
                      mdl: "32px",
                      mddl: "35px",
                      lg: "37px",
                      lgm: "40px",
                      mlg: "48px",
                      xl: "48px",
                    },
                    fontWeight: "bold",
                    // lineHeight: "66px",
                  }}
                >
                  by matching
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "22px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        sm: "20px",
                        md: "30px",
                        mdl: "32px",
                        mddl: "35px",
                        lg: "37px",
                        lgm: "40px",
                        mlg: "48px",
                        xl: "48px",
                      },
                      fontWeight: "bold",
                      textWrap: "nowrap",
                      // lineHeight: "66px",
                    }}
                  >
                    business with talent.
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    //  {
                    //   sm: "12px",
                    //   md: "13px",
                    //   mdl: "14px",
                    //   mddl: "15px",
                    //   lg: "16px",
                    //   lgm: "17px",
                    //   mlg: "23px",
                    //   xl: "24px",
                    // },
                    fontWeight: theme.typography.Bold,
                    // textWrap: "nowrap",
                    maxWidth: "100%",
                    marginBottom: { lg: "15px", lgm: "25" },
                  }}
                >
                  You don’t have the time, nor the money to fail at hiring.{" "}
                  <br /> Hire right. First time.
                </Typography>
                {/* <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom:{lg:"15px" ,lgm:"25px"},
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Your choice of hiring platform is an extension of your
                  employer brand. Attract, manage and communicate with the
                  talent marketplace, differently.
                </Typography>
                <Button
                  variant="contained"
                  color="addQuestionButton"
                  sx={{
                    borderRadius: "10px",
                    width: "100px",
                    height: "45px",
                    padding: "7px 3px",
                    fontSize: "14px",
                  }}
                  onClick={() => {
                    navigate("/solutions");
                  }}
                >
                  find out how
                </Button> */}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    backgroundColor: theme.homepage.earnCashNew.main,
                  },
                  backgroundColor: theme.homepage.earnCashNew.main,
                  height: `125px`,
                  width: `125px`,
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "25px 0px 25px 0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  right: 0,
                  bottom: "0",
                  zIndex: "999",
                  textWrap: "nowrap",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={earnCash}
                    // height={{ xs: 30, md: 35, mdl: 40, lg: 45, mlg: 50 }}
                    // width={{ xs: 21, md: 26, mdl: 31, lg: 36, mlg: 41 }}
                    heihgt="50px"
                    width="50px"
                  />
                }
                onClick={() => {
                  setComponentToFlip(4);
                  setIsRotate(!isRotate);
                  setisHorizontalFlipped((prev) => !prev);
                }}
              >
                earn cash
              </Button>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                  width: "100%",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                {/* <Box
                  onClick={() => navigate("/jobs")}
                  sx={{
                    backgroundColor: theme.palette.peachPayment.main,
                    height: "50px",
                    width: "50px",
                    borderRadius: "10px 10px 0 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    component={"img"}
                    alt="talent"
                    src={jobLinkIcon}
                    height="30px"
                    width="30px"
                  />
                </Box> */}
                <Link
                  to={`https://crayondemo.co.za/`}
                  style={{
                    textDecoration: "none",
                    color: theme.palette.black,
                  }}
                  target="_blank"
                >
                  {/* <Box
                    sx={{
                      backgroundColor: theme.palette.peachPayment.main,
                      height: "45px",
                      width: "125px",
                      borderRadius: "10px 10px 0 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      component={"img"}
                      alt="talent"
                      src={VideoIcon}
                      height="30px"
                      width="30px"
                    />
                  </Box> */}
                  <Button
                    variant="contained"
                    sx={{
                      ":hover": {
                        backgroundColor: theme.palette.tymeColor.main,
                      },
                      backgroundColor: theme.palette.tymeColor.main,
                      height: `45px`,
                      width: `125px`,
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                      borderRadius: "10px 10px 0px 0px",
                      display: "flex",
                      padding: "6px 10px",
                      // flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                      // position: "absolute",
                      // bottom: 0,
                      textWrap: "nowrap",
                      "& .MuiButton-startIcon": {
                        margin: "0px",
                      },
                    }}
                    startIcon={
                      <Box
                        component={"img"}
                        alt="talent"
                        src={VideoIcon}
                        height="20px"
                        width="22px"
                      />
                    }
                    onClick={() => {
                      // setComponentToFlip(4);
                      // setIsRotate(!isRotate);
                      // setisHorizontalFlipped((prev) => !prev);
                    }}
                  >
                    learn more
                  </Button>
                </Link>
              </Box>
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    backgroundColor: theme.homepage.bookDemo.main,
                  },
                  backgroundColor: theme.homepage.bookDemo.main,
                  height: `125px`,
                  width: `125px`,
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "0px 25px 0px 25px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: 0,
                  bottom: "0",
                  textWrap: "nowrap",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={recruit}
                    heihgt="50px"
                    width="50px"
                  />
                }
                onClick={() => {
                  setComponentToFlip(3);
                  setIsRotate(!isRotate);
                  setisHorizontalFlipped((prev) => !prev);
                }}
              >
                recruit
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </>
  ) : (
    <Box sx={{ paddingTop: "55px" }}>
      <Box
        boxShadow={`0px 5px 10px ${theme.palette.boxShadow.main} !important`}
        sx={{
          borderRadius: "25px",
          display: "flex",
          flexDirection: "column",
          height: { xs: "633px", tablet: "85vh", mdl: "85vh" },
          minWidth: "340px",
          background: "white",
          margin: "0px 10px",
          // marginTop: "55px",
          position: "relative",
        }}
      >
        {/* <Button
          variant="contained"
          color="deleteVideoButton"
          sx={{
            width: "20px",
            height: "100px",
            padding: 0,
            zIndex: "999",
            position: "absolute",
            right: 0,
            top: `calc(50% - 50px)`,
            minWidth: "20px",
            fontSize: "20px",
            borderRadius: "10px 0 0 10px",
          }}
          onClick={() => {
            setComponentToFlip(1);
            setisHorizontalFlipped((prev) => !prev);
          }}
        >
          <Box
            component={"img"}
            src={flip}
            sx={{
              height: "20px",
              width: "15px",
            }}
          />
        </Button> */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.hireCandidateNew.main,
              },
              backgroundColor: theme.homepage.hireCandidateNew.main,
              height: `100px`,
              width: `100px`,
              fontSize: theme?.typography?.fontSize?.base,
              borderRadius: "25px 0px 25px 0px",
              display: "flex",
              flexDirection: "column",
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              justifyContent: "center",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={hireTalent}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(1);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            hire talent
          </Button>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.findWorkNew.main,
              },
              backgroundColor: theme.homepage.findWorkNew.main,
              height: `45px`,
              width: `100px`,
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              borderRadius: "0px 0px 10px 10px",
              alignItems: "center",
              display: "flex",
              justifyContent: "space-around",
              padding: "6px 10px",
              position: "absolute",
              top: "0%",
              left: "50%",
              transform: "translate(-50%, 0%)",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={jobLinkIcon}
                height="20px"
                width="22px"
              />
            }
            onClick={() => navigate("/jobs")}
          >
            jobs
          </Button>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.findWorkNew.main,
              },
              backgroundColor: theme.homepage.findWorkNew.main,
              height: `100px`,
              width: `100px`,
              display: "flex",
              borderRadius: "0px 25px 0px 25px",
              flexDirection: "column",
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              justifyContent: "center",
              zIndex: "999",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={findWork}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(2);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            find work
          </Button>
        </Box>
        <Box sx={{ flex: "1" }}>
          <Box
            sx={{
              flex: "1",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              component={"img"}
              alt="image"
              src={MainPageGif}
              // sx={{ objectFit: "contain", width: "320px" }}
              sx={{
                objectFit: "contain",
                display: { xs: "block", md24: "none" },
                width: { xs: "229px", tablet: "365px", md24: "641px" },
                marginBottom: { xs: "" },
              }}
            ></Box>
            <Box
              component={"img"}
              alt="image"
              src={MainPageGif}
              // sx={{ objectFit: "contain", width: "320px" }}
              sx={{
                display: { xs: "none", md24: "block" },
                objectFit: "contain",
                width: { xs: "229px", tablet: "365px", md24: "630px" },
                marginBottom: { xs: "" },
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              textAlign: "right",
              fontSize: { xs: "24px", tablet: "40px" },
              lineHeight: { tablet: "55.5px" },
              color: "#000000",
              fontWeight: 600,
              paddingRight: "15px",
              marginBottom: "5px",
            }}
          >
            <Box>We connect smiles</Box>
            <Box>by matching</Box>
            <Box>business with talent.</Box>
          </Box>
          <Box
            sx={{
              textAlign: "right",
              fontSize: "14px",
              // lineHeight: "24px",
              color: "#000000",
              fontWeight: 600,
              paddingRight: "15px",
              marginBottom: "5px",
            }}
          >
            You don’t have the time, nor the money to fail at hiring. <br />{" "}
            Hire right. First time.
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            // marginTop: "20px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.bookDemo.main,
              },
              backgroundColor: theme.homepage.bookDemo.main,
              height: `100px`,
              width: `100px`,
              borderRadius: "0px 25px 0px 25px",
              display: "flex",
              flexDirection: "column",
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              justifyContent: "center",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={recruit}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(3);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            recruit
          </Button>
          <Box sx={{ display: "flex" }}>
            {/* <Box
              onClick={() => navigate("/jobs")}
              sx={{
                backgroundColor: theme.palette.peachPayment.main,
                height: "50px",
                width: "50px",
                borderRadius: "10px 10px 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "10px",
                cursor: "pointer",
              }}
            >
              <Box
                component={"img"}
                alt="talent"
                src={jobLinkIcon}
                height="30px"
                width="30px"
              />
            </Box> */}
            <Link
              to={`https://crayondemo.co.za/`}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
              target="_blank"
            >
              {/* <Box
                sx={{
                  backgroundColor: theme.palette.peachPayment.main,
                  height: "50px",
                  width: "50px",
                  borderRadius: "10px 10px 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  alt="talent"
                  src={VideoIcon}
                  height="30px"
                  width="30px"
                />
              </Box> */}
              <Button
                variant="contained"
                sx={{
                  ":hover": {
                    backgroundColor: theme.palette.tymeColor.main,
                  },
                  backgroundColor: theme.palette.tymeColor.main,
                  height: `45px`,
                  width: `100px`,
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                  borderRadius: "10px 10px 0px 0px",
                  display: "flex",
                  padding: "6px 10px",
                  alignItems: "center",
                  justifyContent: "space-around",
                  textWrap: "nowrap",
                  "& .MuiButton-startIcon": {
                    margin: "0px",
                  },
                }}
                startIcon={
                  <Box
                    component={"img"}
                    alt="talent"
                    src={VideoIcon}
                    height="20px"
                    width="22px"
                  />
                }
                onClick={() => {}}
              >
                demo
              </Button>
            </Link>
          </Box>
          <Button
            variant="contained"
            sx={{
              ":hover": {
                backgroundColor: theme.homepage.earnCashNew.main,
              },
              backgroundColor: theme.homepage.earnCashNew.main,
              height: `100px`,
              width: `100px`,
              borderRadius: "25px 0px 25px 0px",

              display: "flex",
              flexDirection: "column",
              fontSize: theme?.typography?.fontSize?.base,
              fontWeight: theme.typography.Bold,
              alignItems: "center",
              justifyContent: "center",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                margin: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                alt="talent"
                src={earnCash}
                height={{ xs: 40 }}
                width={{ xs: 40 }}
              />
            }
            onClick={() => {
              setComponentToFlip(4);
              setIsRotate(!isRotate);
              setisHorizontalFlipped((prev) => !prev);
            }}
          >
            earn cash
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
