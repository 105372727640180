import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import SmallButton from "../../common/SmallButton";
import flip from "../../../assets/NewFlipIcon.svg";

export default function CompanyCultureBackCard({
  compInfo,
  setisHorizontalFlipped,
}) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          height: "225px",
          // width: { xs: "360px", lg: "750px", xl: "750px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
          flex: "1",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="boxTitleButton"
            sx={{
              height: "45px",
              minWidth: "125px",
              maxWidth: "125px",
              fontSize: "14px",
              padding: "0px",
             
              borderRadius: "25px 0px 10px 0px",
              cursor: "default",
              ":hover": {
                boxShadow: "none !important",
              },
            }}
          >
            my personality
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            marginTop: "8px",
          }}
        >
          <Box
            sx={{
              width: `calc(100% - 44px)`,
              marginLeft: "22px",
            }}
          >
            <Box sx={{ marginBottom: "11px" }}>
              <Typography
                sx={{
                  paddingBottom: "4.5px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Your company culture
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  flexWrap: "wrap",
                  // padding: "0 15px 15px 15px",
                }}
              >
                {compInfo?.culture_ids?.map((item) => {
                  return (
                    <SmallButton
                      fontWeight={theme.typography.Bold}
                      minWidth="10px"
                      textColor={theme?.palette?.base?.main}
                      height={25}
                      color="traits"
                      borderRadius="5px"
                      label={item?.label}
                      fontSize={"12px"}
                    ></SmallButton>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        // color="redButton100"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </>
  );
}
