import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      xs360: 360,
      xs390: 390,
      sm: 600,
      tb: 768,
      tablet: 768,
      tb769:769,
      md: 900,
      md24: 1024,
      mdl: 1050,
      mddl: 1200,
      odesk: 1240,
      lg: 1366,
      lg1440: 1440,
      lgmm: 1500,
      lgm: 1600,
      mlg: 1785,
      xl: 1920,
      xl1920: 1921,
      xll: 2000,
      xxl: 2559,
      xxxl: 3840,
    },
  },
  palette: {
    // mode: 'light',
    referenceBtn: {
      main: "#1CB955",
      contrastText: "#FFFFFF",
    },
    uploadJobspec: {
      main: "#EC5228",
      contarstText: "#FFFFFF",
    },
    base: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
    jobAndBioButton: "#47D281",
    borderColor: "#C9CAD8",
    DefaultToggleColor: "#F2F4FB",
    RecruitToggleButton: "#009AE0",
    DirectToggleButton: { main: "#38A8AE", contrastText: "#ffffff" },
    greenButton: {
      main: "#2FA84F",
      contrastText: "#FFFFFF",
    },
    buttonColor: {
      main: "#FFF",
      contrastText: "#000",
    },
    lightGrey: {
      main: "#F2F4FB",
      contrastText: "#ffffff",
    },
    darkGrey: {
      main: "#C9CAD8",
      contrastText: "#ffffff",
    },
    checkers: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    discovery: {
      main: "#B98B5F",
      contrastText: "#ffffff",
    },
    oros: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    safair: {
      main: "#E4047C",
      contrastText: "#ffffff",
    },
    springboks: {
      main: "#2C5F35",
      contrastText: "#ffffff",
    },
    takealot: {
      main: "#0B79BF",
      contrastText: "#ffffff",
    },
    outsurance: {
      main: "#7A378D",
      contrastText: "#ffffff",
    },
    newSubmit: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    springBoks: {
      main: "#2C5F35",
      contrastText: "#ffffff",
    },
    yoco: {
      main: "#00A9E0",
      contrastText: "#ffffff",
    },
    nandos: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    newFlipColor:{
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    precium: {
      main: "#5932D9",
      contrastText: "#ffffff",
    },
    peachPayment: {
      main: "#EC5228",
      contrastText: "#ffffff",
    },
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    tymeColor: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    lightGreyColor: {
      main: "#F2F4FB",
      contrastText: "#FFF",
    },
    greenColor: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    interview: {
      main: "#CCF381",
      contrastText: "#FFF",
    },
    jobInfo: {
      main: "#EB5228",
      contrastText: "#FFFFFF",
    },
    getCoins: {
      main: "#B38E66",
      contrastText: "#FFF",
    },
    companySizeButton: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    recordVideoButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    uploadVideoButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    deleteVideoButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    displayCompanyButton: {
      contrastText: "#000",
      main: "#FFF",
    },
    uploadPhotoButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    employerButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    recruiterButton: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    recentButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    filterButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    activeButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    industriesButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    addQuestionButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    disableButton: {
      main: "#F0F0F0",
      contrastText: "#ffffff",
    },
    checkColor: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    followAlready: {
      main: "#E4047C",
      contrastText: "#ffffff",
    },
    salaryRange: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    experience: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    noticePeriod: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    basicColor: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    promoterButton: {
      main: "#E4047C",
      contrastText: "#FFFFFF",
    },
    referredButton: {
      main: "#E4047C",
      contrastText: "#FFFFFF",
    },
    betaVersion: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    manageBaseButton: {
      main: "#FF9900",
      contrastText: "#FFF",
    },
    boxBorderColor: {
      main: "#E0E0E0BF",
      contrastText: "#ffffff",
    },
    applyButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    viewMoreButton: {
      main: "#FFC425",
      contrastText: "#ffffff",
    },
    searchIconColor: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    liteButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    talentButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    submitButton: {
      main: "#C9CAD8",
      contrastText: "#FFF",
    },
    searchButtons: {
      main: "#0A66C2",
      contrastText: "#ffffff",
    },
    greenSwitch: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    directButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    recruitButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    ideaGritScoreSlider: {
      main: "#A05DC1",
      contrastText: "#FFF",
    },
    salarySlider: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    experienceSlider: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    sizeTypeButton: {
      main: "#0C486B",
      contrastText: "#FFFFFF",
    },
    industiresButton: {
      main: "#0077C2",
      contrastText: "#FFFFFF",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    greyPlayButton: {
      main: "#F2F4FB",
      contrastText: "#FFF",
    },
    stepperColor: {
      main: "#60CA58",
      contrastText: "#FFFFFF",
    },
    boxShadow: {
      main: "#00000029",
      contrastText: "#FFFFFF",
    },
    assesmentColor: {
      main: "#A05DC1",
      contrastText: "#FFFFFF",
    },
    adminCheckColor: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },

    dateButton: {
      main: "#FAFAFA",
      contrastText: "#FFFFFF",
    },
    tealColor: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    cvColor: {
      main: "#E0E0E080",
      contrastText: "#FFFFFF",
      light: "#f1e5ff",
    },
    redButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
      light: "#F44336",
    },
    redButton100: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    pinkButton: {
      main: "#F5576C",
      contrastText: "#FFFFFF",
    },
    orangeButton: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
      light: "#ff9800",
    },
    userID: {
      main: "#C9CAD8",
      contrastText: "#FFFFFF",
      light: "#ff9800",
    },
    buttonText: {
      main: "#707070",
      contrastText: "#FFFFFF",
    },
    blueButton100: {
      main: "#52CBDC",
      contrastText: "#FFFFFF",
    },
    blueButton200: {
      main: "#59B4D1",
      contrastText: "#FFFFFF",
    },
    blueButton300: {
      main: "#4382C3",
      contrastText: "#FFFFFF",
      light: "#1976d2",
    },
    blueButton400: {
      main: "#367BF5",
      contrastText: "#FFFFFF",
      light: "#1976d2",
    },
    blueButton500: {
      main: "#2980B9",
      contrastText: "#FFFFFF",
    },
    blueButton600: {
      main: "#8ACDEB",
      contrastText: "#FFFFFF",
    },
    jobTypeButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    workSetupButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    blueButton700: {
      main: "#0A66C2",
      contrastText: "#FFFFFF",
    },
    blueButton800: {
      main: "#228CCE",
      contrastText: "#FFFFFF",
    },
    yellowButton100: {
      main: "#F8B318",
      contrastText: "#FFFFFF",
      light: "#FBC02D",
    },
    yellowButton200: {
      main: "#F38E18",
      contrastText: "#FFFFFF",
    },
    yellowButton300: {
      main: "#F3AA18",
      contrastText: "#FFFFFF",
    },
    yellowButton400: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    grayButtonEmployer: {
      main: "#F2F4FB",
      contrastText: "#FFFFFF",
      light: "#78909c",
    },
    grayButton: {
      main: "#747474",
      contrastText: "#FFFFFF",
      light: "#78909c",
    },

    grayButton100: {
      main: "#efeeee",
      light: "#000000",
    },
    grayButton200: {
      main: "#E5E5E5", 
      contrastText: "#000000",
    },
    searchIcon: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    filterIcon: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    findBiz: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    hireCandidate: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    hireWork: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    closeButton: {
      main: "#C9CAD8",
      contrastText: "#ffffff",
    },
    grayButton300: {
      main: "#D4DADD",
      contrastText: "#FFFFFF",
    },
    grayButton400: {
      main: "#BFBEBE",
      contrastText: "#FFFFFF",
    },
    grayButton500: {
      main: "#F5F5F5",
      contrastText: "#FFFFFF",
    },
    grayButton600: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    greenButton: {
      main: "#2FA84F",
      contrastText: "#FFFFFF",
    },
    greenButton200: {
      main: "#18CA09",
      contrastText: "#FFFFFF",
    },
    greenButton300: {
      main: "#1DB954",
      contrastText: "#FFFFFF",
    },
    lightGreenButton100: {
      main: "#B6C773",
      contrastText: "#FFFFFF",
    },
    lightGreenButton200: {
      main: "#87A707",
      contrastText: "#FFFFFF",
    },
    lightGreenButton300: {
      main: "#61CA58",
      contrastText: "#FFFFFF",
    },
    lightGreenButton400: {
      main: "#7ddd0d",
      contrastText: "#FFFFFF",
    },
    progressBar: {
      main: "#47D281",
      contrastText: "#FFFFFF",
    },
    lightBlueJobButton300: {
      main: "#0A66C2",
      contrastText: "#FFFFFF",
    },
    whiteButton: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
    purpleButton: {
      main: "#993C7D",
      contrastText: "#FFFFFF",
    },
    purpleButton100: {
      main: "#A05DC1",
      contrastText: "#ffffff",
    },
    purpleButton200: {
      main: "#811650",
      contrastText: "#FFFFFF",
    },
    purpleButton300: {
      main: "#F05DD8",
      contrastText: "#FFFFFF",
    },
    pinkButton100: {
      main: "#F05DD8",
      contrastText: "#FFFFFF",
    },
    hired: {
      main: "#429867",
      contrastText: "#ffffff",
    },
    pendingButton: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    likeThisButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    pinkButton200: {
      main: "#EC449B",
      // main: "#DD3EB8",
      contrastText: "#FFFFFF",
    },
    pinkButton300: {
      main: "#E4047C",
      contrastText: "#FFFFFF",
    },
    brownButton: {
      main: "#C19C47",
      contrastText: "#FFFFFF",
    },
    black100: {
      main: "#000000",
      contrastText: "#FFFFFF",
    },
    black200: {
      main: "#000000de",
      contrastText: "#FFFFFF",
    },
    recruit100: {
      main: "#C19C47",
      contrastText: "#FFFFFF",
    },
    eyeview: {
      main: "#1DB954",
      contrastText: "#FFFFFF",
    },
    eyeview100: {
      main: "#EBECF3",
      contrastText: "#FFFFFF",
    },
    QandA: {
      main: "#F09248",
      contrastText: "#FFFFFF",
    },
    quicklinks: {
      main: "#228CCE",
      contrastText: "#FFFFFF",
    },
    orangeButton100: {
      main: "#F09248",
      contrastText: "#FFFFFF",
    },
    leftArrowButton: {
      main: "#FF6037",
      contrastText: "#FFFFFF",
    },
    skillsButton: {
      main: "#A05DC1",
      contrastText: "#FFF",
    },
    grayButton700: {
      main: "#DCDEEB",
      contrastText: "#FFF",
    },
    toolsButton: {
      main: "#6B3FA0",
      contrastText: "#FFF",
    },
    traitsButton: {
      main: "#482683",
      contrastText: "#FFF",
    },
    topBar: {
      main: "#F2F4FB",
      contrastText: "#FFFFFF",
    },
    redButton200: {
      main: "#FF6037",
      contrastText: "#FFFFFF",
    },
    blueButton900: {
      main: "#0F2143",
      contrastText: "#FFFFFF",
    },
    blueButton1000: {
      main: "#023E8A",
      contrastText: "#FFFFFF",
    },
    pausedStatus: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    pendingStatus: {
      main: "#ffcb00",
    },
    activeStatus: {
      main: "#7DDD0D",
    },
    rejectedStatus: {
      main: "#CE0A24",
    },
    closedStatus: {
      main: "#CE0A24",
    },
    shortlist: {
      main: "#EB5228",
    },
    expiredStatus: {
      main: "#CE0A24",
    },
    yellowColor: "#DC9B53",
    white: "#FFFFFF",
    black: "#000000",
    mainBackground: "#EBECF3",
    menuBackground: "#F7F9FA",
    hoverBlue: "#E6EFFF",
    lightText: "#5E6366",
    lightGray: "#D3D3D3",
    grayRejct: "#efeeee",
    grayBackground: "#F0F0F0",
    SliderBackground: "#F2F4FB",
    playButtonBackground: "#F2F4FB",
    grayBorder: "#E0E0E0",
    grayBorder100: "#C9CAD8",
    placeholder: "#C6CACC",
    grayIcon: "#545974",
    picNameColor: "#069697",
    chart: {
      red: "#EC5D60",
      green: "#B6C773",
      yellow: "#F09248",
      blue: "#80CEFF",
      green200: "#18CA09",
      grit_score1: "#A05DC1",
      grit_score: "#5932D9",
      grit_score_new: "#5932D9",
      Applied: "#FFC425",
      Shortlisted: "#FF9602",
      ShortlistedNew: "#FF9602",
      AppliedNew: "#FFC425",
      employerShortListed: "#EB5228",
      interview: "#CCF381",
      Interviewed: "#E49F18",
      gray: "#F2F4FB",
      darkGray: "#c9cad8",
      black: "#000000",
      match: "#7DDD0D",
      assesmentColor: "#a05dc1",
      jobPosting: "#38A8AE",
      activeJob: "#47D281",
      application: "#FFCB00",
      shortlisting: "#FF9900",
      matched: "#009AE0",
      weakMatch: "#CE0A24",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      follower: "#E4047C",
      hired: "#429867",
      activeJobNew: "#0B79BF",
      InterviewedNew: "#EC5228",
      hiredNew: "#2C5F35",
    },
  },

  jobCard: {
    jobType: {
      recruit: {
        main: "#00A9E0",
        contrastText: "#FFFFFF",
      },
      direct: {
        main: "#38A8AE",
        contrastText: "#FFFFFF",
      },
    },
    jobStatus: {
      Review: {
        main: "#FFCB00",
        contrastText: "#FFFFFF",
      },
      Considering: {
        main: "#FF9900",
        contrastText: "#FFFFFF",
      },
      Shortlist: {
        main: "#EB5228",
        contrastText: "#FFFFFF",
      },
      Interview: {
        main: "#CCF381",
        contrastText: "#FFFFFF",
      },
      Assesment: {
        main: "#9DD64C",
        contrastText: "#FFFFFF",
      },
      Offer: {
        main: "#1CB955",
        contrastText: "#FFFFFF",
      },
      Hired: {
        main: "#429867",
        contrastText: "#FFFFFF",
      },
      Incomplete: {
        main: "#C9CAD8",
        contrastText: "#FFFFFF",
      },
      Matched: {
        main: "#009AE0",
        contrastText: "#FFFFFF",
      },
      Rejected: {
        main: "#CE0A24",
        contrastText: "#FFFFFF",
      },
    },
    type: {
      main: "#0F2143",
    },
    industries: {
      main: "#023E8A",
    },
  },

  manageTalent: {
    manageReview: {
      main: "#0B79BF",
      contrastText: "#FFFFFF",
    },

    manageConsidering: {
      main: "#FFC425",
      contrastText: "#FFFFFF",
    },

    manageShortlist: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },

    manageInterview: {
      main: "#EC5228",
      contrastText: "#FFFFFF",
    },

    manageAssesment: {
      main: "#5932D9",
      contrastText: "#FFFFFF",
    },

    manageOffer: {
      main: "#7A378D",
      contrastText: "#FFFFFF",
    },

    manageHired: {
      main: "#2C5F35",
      contrastText: "#FFFFFF",
    },

    manageIncomplete: {
      main: "#C9CAD8",
      contrastText: "#FFFFFF",
    },

    manageMatched: {
      main: "#00A9E0",
      contrastText: "#FFFFFF",
    },

    manageRejected: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },

    leftArrowButton: {
      main: "#FF6037",
      contrastText: "#FFFFFF",
    },

    profileBorder: {
      main: "#C9CAD8",
      contrastText: "#FFFFFF",
    },
  },

  status: {
    pending: {
      main: "#F8B318",
    },
    ilikethis: {
      main: "#FF934A",
    },
    ilovethis: {
      main: "#B6C773",
    },
    notforme: {
      main: "#F05D61",
    },
    active: {
      main: "#B6C773",
    },
    paused: {
      main: "#FF934A",
    },
    closed: {
      main: "#F05D61",
    },
    rejected: {
      main: "#F05D61",
    },
  },

  homepage: {
    footerButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    hireCandidateNew: {
      main: "#FFC425",
      contrastText: "#FFFFFF",
    },
    findWorkNew: {
      main: "#019AE0",
      contrastText: "#FFFFFF",
    },
    earnCashNew: {
      main: "#20B2AA",
      contrastText: "#FFFFFF",
    },
    hireCandidate: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    recruit: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    findWork: {
      main: "#0C486B",
      contrastText: "#FFFFFF",
    },
    earnCash: {
      main: "#E4047C",
      contrastText: "#FFFFFF",
    },
    demo: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    bookDemo: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    viewJobs: {
      main: "#0C486B",
      contrastText: "#FFFFFF",
    },
    homeIcon: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    homeIconNew: {
      main: "#5932D9",
      contrastText: "#FFFFFF",
    },
    learnMoreYellow: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    learnMore: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    searchBox: {
      main: "#FFFAE6",
      contrastText: "#FFCB00",
    },
    searchBoxRecruit: {
      main: "#FFFAE6",
      contrastText: "#FF9900",
    },
    searchBoxRecruitNew: {
      main: "#FAE7E5",
      contrastText: "#FF9900",
    },
    searchBoxRecruitNewInput: {
      main: " #CE1602",
      contrastText: "#FF9900",
    },

    searchBoxText: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    findWorkSearchBox: {
      main: "#E5ECEF",
      contrastText: "#FFCB00",
    },
    findWorkSearchBoxNew: {
      main: "#E4F4FC",
      contrastText: "#FFCB00",
    },
    findWorkSearchBoxNewColor: {
      main: "#009AE0",
      contrastText: "#009AE0",
    },
    findWorkSearchBoxText: {
      main: "#0B4668",
      contrastText: "#FFFFFF",
    },
    earnCashBox: {
      main: "#FCE6F2",
      contrastText: "#FFCB00",
    },
    earnCashBoxNew: {
      main: "#EBF6F7",
      contrastText: "#FFCB00",
    },
    earnCashBoxNewInput: {
      main: "#38A8AE",
      contrastText: "#FFCB00",
    },
    earnCashBoxText: {
      main: "#E3047C",
      contrastText: "#FFFFFF",
    },
    greyButton: {
      main: "#C9CAD8",
      contrastText: "#FFFFFF",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 700,
          borderRadius: 25,
          height: "40px",
          boxShadow: "none",
          letterSpacing: "0.75px",
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10,
        },
      },
    },
  },
  typography: {
    fontFamily: ["Nunito", "serif"].join(","),
    ExtraLight: 200,
    Light: 300,
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    ExtraBold: 800,
    Black: 900,
    fontSize: {
      xs: "12px",
      sm: "14px",
      base: "16px",
      lg: "18px",
      xl: "20px",
      xl2: "24px",
      xl3: "30px",
      xl4: "36px",
      xl5: "48px",
    },
  },
});

const homePageTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    hireCandidate: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    recruit: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    findWork: {
      main: "#0C486B",
      contrastText: "#FFFFFF",
    },
    earnCash: {
      main: "#E4047C",
      contrastText: "#FFFFFF",
    },
    demo: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    bookDemo: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    viewJobs: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    learnMore: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    searchBox: {
      main: "#FFFAE6",
      contrastText: "#FFCB00",
    },
    searchBoxRecruit: {
      main: "#FFFAE6",
      contrastText: "#FF9900",
    },
    searchBoxText: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    findWorkSearchBox: {
      main: "#E5ECEF",
      contrastText: "#FFCB00",
    },
    findWorkSearchBoxText: {
      main: "#0B4668",
      contrastText: "#FFFFFF",
    },
    earnCashBox: {
      main: "#FCE6F2",
      contrastText: "#FFCB00",
    },
    earnCashBoxText: {
      main: "#E3047C",
      contrastText: "#FFFFFF",
    },
  },
});

const topBarTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    searchIconColor: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    lightGrey: {
      main: "#F2F4FB",
      contrastText: "#FFFFFF",
    },
    findWork: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },

    findTalent: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },

    topBarLiteButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    topBarCandidateButton: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
  },
});

const topBarChallengerTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    topBarLiteButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    topBarCandidateButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
  },
});

const topBarCharacterTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    topBarLiteButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    topBarCandidateButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
  },
});

const topBarContemplatorTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    topBarLiteButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    topBarCandidateButton: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
  },
});

const topBarCollaboratorTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    topBarLiteButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    topBarCandidateButton: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
  },
});

const loginPagetheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    backgroundColor: "#f2f4fb",
    errorMessage: "#CE0A24",
    borderColor: "#C9CAD8",
    rememberMeswitch: "#47D281",
    forgotPasswordButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    loginButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
  },
});

const signupPagetheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    backgroundColor: "#f2f4fb",
    errorMessage: "#CE0A24",
    candidateInitialTextColor: "#C9CAD8",
    candidateButton: {
      main: "#0C486B",
      contrastText: "#FFFFFF",
    },
    topBarCandidateButtonLightBlue: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    topBarEmployerButton: {
      main: "#FFC425",
      contrastText: "#ffffff",
    },
    employerButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    recruiterButton: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    topBarRecruiterButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    promoterButton: {
      main: "#E4047C",
      contrastText: "#FFFFFF",
    },
    topBarPromoterButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    borderColor: "#C9CAD8",
    forgotPasswordButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    signupButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
  },
});

const forgotPasswordPagetheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    backgroundColor: "#f2f4fb",
    errorMessage: "#CE0A24",
    borderColor: "#C9CAD8",
    rememberMeswitch: "#47D281",
    backButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    resetButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
  },
});

const jobListingPageTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    hireCandidate: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    RecruitButton: {
      main: "#00A9E0",
      contrastText: "#FFF",
    },
    DirectButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    jobAnnounceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    jobStatus: {
      Review: {
        main: "#FFCB00",
        contrastText: "#FFFFFF",
      },
      Considering: {
        main: "#19747E",
        contrastText: "#FFFFFF",
      },
      Shortlist: {
        main: "#EB5228",
        contrastText: "#FFFFFF",
      },
      Interview: {
        main: "#CCF381",
        contrastText: "#FFFFFF",
      },
      Assesment: {
        main: "#66B2B2",
        contrastText: "#FFFFFF",
      },
      Offer: {
        main: "#1CB955",
        contrastText: "#FFFFFF",
      },
      Hired: {
        main: "#48D1CC",
        contrastText: "#FFFFFF",
      },
      Incomplete: {
        main: "#C9CAD8",
        contrastText: "#FFFFFF",
      },
      Matched: {
        main: "#228CCE",
        contrastText: "#FFFFFF",
      },
      Rejected: {
        main: "#E41E26",
        contrastText: "#FFFFFF",
      },
    },
    jobTypeButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    workSetupButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    skillsButton: {
      main: "#00A9E0",
      contrastText: "#FFF",
    },
    toolsButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    traitsButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    chart: {
      gray: "#F2F4FB",
      darkGray: "#c9cad8",
      Applied: "#FFC425",

      Shortlisted: "#FF9602",
      ShortlistedNew: "#FF9602",
      Interviewed: "#EC5228",
      InterviewedNew: "#EC5228",
      grit_score: "#5932D9",
      grit_score_new: "#5932D9",
      AppliedNew: "#FFC425",
      employerShortListed: "#EB5228",
      interview: "#CCF381",
      jobPosting: "#38A8AE",
      jobPostingNew: "#00A9E0",
      activeJobs: "#47D281",
      shortListings: "#EE4E34",
      strongMatch: "#47D281",
      contractDuration: "#000000",
      averageMatch: "#FF9900",
      weakMatch: "#CE0A24",
      activeJobNew: "#0B79BF",
      
      hiredNew: "#2C5F35",
      matched: "#009AE0",
    },
    matchButton: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    viewMoreButton: {
      main: "#FFC425",
      contrastText: "#ffffff",
    },
    nandos: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    newFlipColor:{
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    applyButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    flipButton: {
      main: "#CE0A24",
      // main: '#38A8AE',
      contrastText: "#ffffff",
    },
    companyTypeButton: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    companySizeButton: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    companyFollowButton: {
      main: "#E4047C",
      contrastText: "#ffffff",
    },
    betaVersion: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
  },
});

const jobListingPageChallengerTheme = createTheme({
  ...jobListingPageTheme,
  palette: {
    ...jobListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
  },
});

const jobListingPageCharacterTheme = createTheme({
  ...jobListingPageTheme,
  palette: {
    ...jobListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
  },
});

const jobListingPageContemplatorTheme = createTheme({
  ...jobListingPageTheme,
  palette: {
    ...jobListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
  },
});

const jobListingPageCollaboratorTheme = createTheme({
  ...jobListingPageTheme,
  palette: {
    ...jobListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
  },
});

const jobDetailsPageTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    RecruitButton: {
      main: "#00A9E0",
      contrastText: "#FFF",
    },
    DirectButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    jobAnnounceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    jobStatus: {
      Review: {
        main: "#FFCB00",
        contrastText: "#FFFFFF",
      },
      Considering: {
        main: "#19747E",
        contrastText: "#FFFFFF",
      },
      Shortlist: {
        main: "#EB5228",
        contrastText: "#FFFFFF",
      },
      Interview: {
        main: "#CCF381",
        contrastText: "#FFFFFF",
      },
      Assesment: {
        main: "#66B2B2",
        contrastText: "#FFFFFF",
      },
      Offer: {
        main: "#1CB955",
        contrastText: "#FFFFFF",
      },
      Hired: {
        main: "#48D1CC",
        contrastText: "#FFFFFF",
      },
      Incomplete: {
        main: "#C9CAD8",
        contrastText: "#FFFFFF",
      },
      Matched: {
        main: "#228CCE",
        contrastText: "#FFFFFF",
      },
      Rejected: {
        main: "#E41E26",
        contrastText: "#FFFFFF",
      },
    },
    jobTypeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    workSetupButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    traitsButton: {
      main: "#A05DC1",
      contrastText: "#FFF",
    },
    toolsButton: {
      main: "#6B3FA0",
      contrastText: "#FFF",
    },
    skillsButton: {
      main: "#482683",
      contrastText: "#FFF",
    },
    chart: {
      gray: "#F2F4FB",
      darkGray: "#c9cad8",
      Applied: "#FFC425",
      Shortlisted: "#FF9602",
      ShortlistedNew: "#FF9602",
      Interviewed: "#EC5228",
      grit_score: "#5932D9",
      
      grit_score_new: "#5932D9",
      AppliedNew: "#FFC425",
      employerShortListed: "#EB5228",
      interview: "#CCF381",
      contractDuration: "#009AE0",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      weakMatch: "#CE0A24",
    },

    boxBackground: "#F2F4FB",
    titleBox: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    matchTitleBox: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    matchButton: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    applyButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    viewJobButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    followButton: {
      main: "#E4047C",
      contrastText: "#ffffff",
    },
    viewAllJobsButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    companyTypeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    companySizeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
  },
});

const talentListingPageTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    hireCandidate: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    likeThisButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    recentButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    recentButtonNew: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    activeButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    activeButtonNew: {
      main: "#0B79BF",
      contrastText: "#FFFFFF",
    },
    inDemandButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    inDemandButtonNew: {
      main: "#FF9900",
      contrastText: "#FFFFFF",
    },
    talentAnnounceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    jobTypeButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    workSetupButton: {
      main: "#0C486B",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    skillsButton: {
      main: "#A05DC1",
      contrastText: "#FFF",
    },
    toolsButton: {
      main: "#6B3FA0",
      contrastText: "#FFF",
    },
    traitsButton: {
      main: "#482683",
      contrastText: "#FFF",
    },
    betaVersion: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    viewMoreButton: {
      main: "#FFC425",
      contrastText: "#ffffff",
    },
    applyButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    chart: {
      ...theme.palette.chart,
      gray: "#F2F4FB",
      darkGray: "#c9cad8",
      Applied: "#FFC425",
      Shortlisted: "#FF9602",
      ShortlistedNew: "#FF9602",
      grit_score_new: "#5932D9",


      Interviewed: "#EC5228",
      grit_score: "#5932D9",
      contractDuration: "#000000",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      weakMatch: "#CE0A24",
    },
    matchButton: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    viewCVButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    shortlistButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    flipButton: {
      main: "#CE0A24",
      // main: '#38A8AE',
      contrastText: "#ffffff",
    },
  },
});

const talentListingPageChallengerTheme = createTheme({
  ...talentListingPageTheme,
  palette: {
    ...talentListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
  },
});

const talentListingPageCharacterTheme = createTheme({
  ...talentListingPageTheme,
  palette: {
    ...talentListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
  },
});

const talentListingPageContemplatorTheme = createTheme({
  ...talentListingPageTheme,
  palette: {
    ...talentListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
  },
});

const talentListingPageCollaboratorTheme = createTheme({
  ...talentListingPageTheme,
  palette: {
    ...talentListingPageTheme.palette,
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
  },
});

const candidateMyJobsPageTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
  },
});

const candidateMyCVDefaultTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    purpleButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    orangeButton: {
      main: "#EC5228",
      contrastText: "#FFF",
    },
    personalDetail: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    skillsAndTools: {
      main: "#FF9900",
      contrastText: "#FFF",
    },
    personality: {
      main: "#A05DC1",
      contrastText: "#FFF",
    },
    workhistory: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    reference: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    classicCVColor: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    basicColor: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    buttonColor: {
      main: "#FFF",
      contrastText: "#000",
    },
    RecruitButton: {
      main: "#00A9E0",
      contrastText: "#FFF",
    },
    DirectButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    uploadmyCV: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    myVideoButton: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    jobVideoButton: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    scrapeLinkedin: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    salaryRange: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    experience: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    noticePeriod: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    nextButton: {
      main: "#0C486B",
      contrastText: "#ffffff",
    },
    lockItIn: {
      main: "#C9CAD8",
      contrastText: "#ffffff",
    },
    greenSwitch: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    cvPageBackground: {
      main: "#F2F4FB",
      contrastText: "#ffffff",
    },
    traits: {
      main: "#A05DC1",
      contrastText: "#ffffff",
    },
    uploadPhoto: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    takePhoto: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    companySearch: {
      main: "#C9CAD8",
      contrastText: "#ffffff",
    },
    checkColor: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    followAlready: {
      main: "#E4047C",
      contrastText: "#ffffff",
    },
    followingButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    hired: {
      main: "#429867",
      contrastText: "#ffffff",
    },
    viewMoreButton: {
      main: "#FFC425",
      contrastText: "#ffffff",
    },
    applyButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    videoButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    uploadVideoButton: {
      main: "#019AE0",
      contrastText: "#ffffff",
    },
    deleteVideoButton: {
      main: "#C9CAD8",
      contrastText: "#000",
    },
    betaVersion: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    chart: {
      ...theme.palette.chart,
      grit_score: "#5932D9",
      AppliedUltra: "#0B79BF",
      grit_score_new: "#5932D9",
      Interviewed: "#EC5228",
      Shortlisted: "#FF9602",

    },
  },
});

const candidateChallengerTheme = createTheme({
  ...candidateMyCVDefaultTheme,
  palette: {
    ...candidateMyCVDefaultTheme.palette,
    basicColor: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    nextButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
  },
});

const candidateCharacterTheme = createTheme({
  ...candidateMyCVDefaultTheme,
  palette: {
    ...candidateMyCVDefaultTheme.palette,
    basicColor: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    nextButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
  },
});

const candidateContemplatorTheme = createTheme({
  ...candidateMyCVDefaultTheme,
  palette: {
    ...candidateMyCVDefaultTheme.palette,
    basicColor: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    nextButton: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
  },
});

const candidateCollaboratorTheme = createTheme({
  ...candidateMyCVDefaultTheme,
  palette: {
    ...candidateMyCVDefaultTheme.palette,
    basicColor: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    nextButton: {
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
  },
});

const ApplyJobsPopupTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    letsgoButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    nextQuestionButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    loginButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    signupButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    laterButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    updateLaterButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    uploadCvButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    updateCVButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    rollCameraButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    uploadVideoButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    deleteVideoButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    updateAnswer: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    lightGrey: {
      main: "#F2F4FB",
      contrastText: "#ffffff",
    },
    darkGrey: {
      main: "#C9CAD8",
      contrastText: "#ffffff",
    },
    grayButton600: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    mainBackground: "#EBECF3",
    peachPayment: {
      main: "#EC5228",
      contrastText: "#ffffff",
    },
    videoButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    jobVideoButton: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    yoco: {
      main: "#00A9E0",
      contrastText: "#ffffff",
    },
    nandos: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    }
  },
  typography: {
    fontFamily: ["Nunito", "serif"].join(","),
    ExtraLight: 200,
    Light: 300,
    Regular: 400,
    Medium: 500,
    SemiBold: 600,
    Bold: 700,
    ExtraBold: 800,
    Black: 900,
    fontSize: {
      xs: "12px",
      sm: "14px",
      base: "16px",
      lg: "18px",
      xl: "20px",
      xl2: "24px",
      xl3: "30px",
      xl4: "36px",
      xl5: "48px",
    },
  },
});

const PostAjobTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    checkers: {
      main: "#38A8AE",
      contarstText: "#FFFFFF",
    },
    uploadJobspec: {
      main: "#EC5228",
      contarstText: "#FFFFFF",
    },
    parseJobspec: {
      main: "#5932D9",
      contarstText: "#FFFFFF",
    },
    grayButton400: {
      main: "#BFBEBE",
      contrastText: "#FFFFFF",
    },
    progressButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    grayButton100: {
      main: "#efeeee",
      light: "#000000",
    },
    lightGreenButton300: {
      main: "#61CA58",
      contrastText: "#FFFFFF",
    },
    greenButton: {
      main: "#2FA84F",
      contrastText: "#FFFFFF",
    },
    lightGreenButton400: {
      main: "#7ddd0d",
      contrastText: "#FFFFFF",
    },
    salaryRange: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    filterButtons: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    pageButtons: {
      main: "#FFFFFF",
      contrastText: "#000000",
    },
    activePageButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    activeStepButtons: {
      main: "#38A8AE",
      contrastText: "#000000",
      contrastCircle: "#FFFFFF",
      iconText: "#000000",
      circleColor: "#C9CAD8",
    },
    stepButtonColor: {
      main: "#C9CAD8",
      contrastText: "#FFFFFF",
    },
    stepButtons: {
      main: "#FFFFFF",
      contrastText: "#000000",
      iconText: "#000000",
    },
    crayonRecrutitButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    PointsTypoColor: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    RecruitCompleteButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    DirectCompleteButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    displayCompanyButton: {
      contrastText: "#000",
      main: "#FFF",
    },
    borderColor: "#C9CAD8",
    DefaultToggleColor: "#F2F4FB",
    RecruitToggleButton: "#009AE0",
    DirectToggleButton: "#38A8AE",
    LearnMoreButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    CloseButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    FindDreamTeamButton: {
      main: "#009AE0",
      contrastText: "#FFFFFF",
    },
    TopUpCoinButton: {
      main: "#B38E66",
      contrastText: "#FFFFFF",
    },

    crayonDirectButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    LetsGoButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },

    SliderBackground: "#F2F4FB",
    experienceSlider: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    salarySlider: {
      main: "#009AE0",
      contrastText: "#FFF",
    },

    // The details page
    scrapeSpecButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    jobAndBioButton: "#47D281",
    saveAndExitButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    tymeColor: {
      main: "#FFC425",
      contrastText: "#000000",
    },
    cultureAddButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    detailButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },

    // culture add
    ideaGritScoreSlider: {
      main: "#A05DC1",
      contrastText: "#FFF",
    },
    ideaGritScoreSliderNew: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    addQuestionButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    recordVideoButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    postJobButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    rollCameraButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
    uploadVideoButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },
    deleteVideoButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },
  },
});

const employerDefaulTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    baseColor: "#FFCB00",
    lightGray: "#D3D3D3",

    accessListButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    RecruitButton: {
      main: "#00A9E0",
      contrastText: "#FFF",
    },
    traits: {
      main: "#5932D9",
      contrastText: "#ffffff",
    },
    tealColor: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    DirectButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    announceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    trackButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    buttonColor: {
      main: "#FFF",
      contrastText: "#000",
    },
    myVideoButton: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    jobVideoButton: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    videoButton: {
      main: "#CE0A24",
      contrastText: "#ffffff",
    },
    uploadVideoButton: {
      main: "#019AE0",
      contrastText: "#ffffff",
    },
    deleteVideoButton: {
      main: "#C9CAD8",
      contrastText: "#000",
    },
    baseColorButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    tymeColor: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    boxTitleButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    uploadPhotoButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    takePhotoButton: {
      main: "#EB5228",
      contrastText: "#FFF",
    },
    assessmentButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    deleteButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    myInfoButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    resetPasswordButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },

    DobTextColor: "#FFCB00",
    lockItinButton: {
      main: "#C9CAD8",
      contrastText: "#000",
    },
    addACompanyButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    companyInfoButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },

    viewProfileButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    deleteProfileButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    myTalentButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    inviteButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    boostProfileButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    progressButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },

    manageLeftButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },

    manageBaseButton: {
      main: "#FF9900",
      contrastText: "#FFF",
    },

    chart: {
      ...theme.palette.chart,
      grit_score: "#5932D9",
      Applied: "#FFC425",
      Shortlisted: "#FF9602",
      ShortlistedNew: "#FF9602",
      Interviewed: "#EC5228",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      weakMatch: "#CE0A24",
      coins: "#B38E66",
      grit_score_new: "#5932D9",
    },
  },
});

const adminUsersTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    liteButton: {
      main: "#FFCB00",
      contrastText: "#FFF",
    },
    borderColor: {
      main: "#E0E0E0BF",
      contrastText: "#FFFFFF",
    },
    talentButton: {
      main: "#CE0A24",
      contrastText: "#FFF",
    },
    postCommentButton: {
      main: "#38A8AE",
      contrastText: "#FFFFFF",
    },
    sizeTypeButton: {
      main: "#0C486B",
      contrastText: "#FFFFFF",
    },

    industiresButton: {
      main: "#0077C2",
      contrastText: "#FFFFFF",
    },

    planButton: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },

    poolButton: {
      main: "#FFCB00",
      contrastText: "#FFFFFF",
    },

    jobButton: {
      main: "#DCDEEB",
      contrastText: "#FFFFFF",
    },

    allJobs: {
      main: "#CE0A24",
      contrastText: "#FFFFFF",
    },

    planButton1: {
      main: "#B38E66",
      contrastText: "#FFFFFF",
    },
    chart: {
      ...theme.palette.chart,
      jobpostings: "#38A8AE",
    },
  },
});

const promoterTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    basicColor: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    jobAnnounceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    uploadPhoto: {
      main: "#47D281",
      contrastText: "#ffffff",
    },
    takePhoto: {
      main: "#FF9900",
      contrastText: "#ffffff",
    },
    followingButton: {
      main: "#FFCB00",
      contrastText: "#ffffff",
    },
    chart: {
      ...theme.palette.chart,
      Interviewed: "#EC5228",
      Applied: "#FFC425",
      Shortlisted: "#FF9602",
      ShortlistedNew: "#FF9602",
      grit_score: "#5932D9",
      strongMatch: "#47D281",
      averageMatch: "#FF9900",
      weakMatch: "#CE0A24",
    },
  },
});

export { adminUsersTheme };
export { loginPagetheme };
export { signupPagetheme };
export { forgotPasswordPagetheme };
export { ApplyJobsPopupTheme };
export { jobListingPageTheme };
export { jobListingPageCharacterTheme };
export { jobListingPageChallengerTheme };
export { jobListingPageCollaboratorTheme };
export { jobListingPageContemplatorTheme };
export { jobDetailsPageTheme };
export { talentListingPageTheme };
export { talentListingPageCharacterTheme };
export { talentListingPageChallengerTheme };
export { talentListingPageCollaboratorTheme };
export { talentListingPageContemplatorTheme };
export { candidateMyJobsPageTheme };
export { topBarTheme };
export { topBarCharacterTheme };
export { topBarChallengerTheme };
export { topBarCollaboratorTheme };
export { topBarContemplatorTheme };
export { candidateMyCVDefaultTheme };
export { candidateChallengerTheme };
export { candidateCharacterTheme };
export { candidateCollaboratorTheme };
export { candidateContemplatorTheme };
export { PostAjobTheme };
export { employerDefaulTheme };
export { homePageTheme };
export { promoterTheme };
export default theme;
