import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import locale from "../../i18n/locale";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputBox from "../common/InputBox";
import SwipeableButton from "../common/SwipeableButton";
import { PASSWORD_REGEX } from "../../utils/Constants";
import { USER_ROLES } from "../../utils/Constants";
import { signup } from "../../redux/login/loginSlice";
import "./login.css";
import * as Yup from "yup";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  emailRegex,
  getContactDetail,
  getDecodedToken,
  getLocalStorage,
  getToken,
  setLocalStorage,
  validateSchema,
} from "../../utils/Common";
import { handleSignState } from "../../redux/signUp/action";
import { InputBase, ThemeProvider } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Dialog from "@mui/material/Dialog";
import CandidateIcon from "../../assets/HomePage/FindPageIcon.svg";
import EmployerIcon from "../../assets/HomePage/HireTalentPageIcon.svg";
import RecruitIcon from "../../assets/HomePage/RecruitPageIcon.svg";
import PromoterIcon from "../../assets/HomePage/EarnPageIcon.svg";
import loginIcon from "../../assets/Characters/CrayBotIcons/LoginIcon.svg";

import ForgotPassword from "./ForgotPassword";
import "react-phone-number-input/style.css";
import PhoneInput, { formatPhoneNumberIntl } from "react-phone-number-input";
import { isValidPhoneNumber } from "libphonenumber-js/max";
import { signupPagetheme } from "../../utils/Theme";
import Cookies from "js-cookie";
import { CheckCircle } from "@mui/icons-material";
import { setLoggedIn } from "../../redux/guest/jobsSlice";
import CloseIcon from "../common/CloseIcon";

export default function Signup({
  onDialogClose,
  toggleForm,
  openFunc,
  handleOpenSignUp,
  closeFunc,
  isVideoUploaded,
  setSelectedLabel = () => {},
  uploadedFileName,
  jobApplyUserType
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  let initialCountryCode = "";
  const FORMDATA = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    contact: "",
    roleId: 3,
  };
  const [userType, setUserType] = useState(jobApplyUserType ? jobApplyUserType : "candidate");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [activeTab, setActiveTab] = useState(pathname.slice(1));
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [signupTouched, setSignupTouched] = useState(false);
  const [countryCode, setCountryCode] = useState(initialCountryCode);
  const [formData, setFormData] = useState(FORMDATA);
  const [errors, setErrors] = useState([]);
  const [fieldTouched, setFieldTouched] = useState([]);
  const navigate = useNavigate();
  let decodedToken = getDecodedToken(getToken());

  const checkStrength = (value) => {
    if (value) {
      let strength = 0;
      if (value === "") {
        return null;
      }
      if (value.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        strength += 1;
      }
      if (value.match(/([0-9])/)) {
        strength += 1;
      }
      if (value.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
        strength += 1;
      }
      if (value.length > 7) {
        strength += 1;
      }
      if (strength <= 2) {
        return (
          <Typography
            sx={{
              color: "errorMessage",
              fontSize: "14px",
              alignItems: "center",
              display: "flex",
              gap: "5px",
              marginRight: "5px",
              justifyContent: "end",
            }}
          >
            {" "}
            Weak
          </Typography>
        ); // Pass the validation
      }
      if (strength === 3) {
        return (
          <Typography
            sx={{
              color: "orange",
              fontSize: "14px",
              alignItems: "center",
              display: "flex",
              gap: "5px",
              marginRight: "5px",
              justifyContent: "end",
            }}
          >
            {" "}
            Medium
          </Typography>
        );
      }
      if (strength === 4) {
        // Return the error message if strength is not equal to 3
        return (
          <Typography
            sx={{
              color: "#38A8AE",
              fontSize: "14px",
              alignItems: "center",
              display: "flex",
              gap: "5px",
              marginRight: "5px",
              justifyContent: "end",
            }}
          >
            Strong
          </Typography>
        );
      }
      return null;
    }
  };
  const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("required"),
    lastName: Yup.string().required("required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address") // Use custom regex for email validation
      .required("Email is Required."),
    // password: Yup.string().required("Password is required"),
    password: Yup.string()
      .matches(
        PASSWORD_REGEX,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character"
      )
      .required("Password required"),
    contact: Yup.string()
      .required("Contact is required")
      .test("is-valid-number", "Invalid contact", function (value) {
        let isValid = isValidPhoneNumber(value);
        return isValid;
      }),
  });
  const handleFormSubmit = () => {
    let data = { ...formData };
    data["email"] = data?.email.trim();
    validateSchema(validationSchema, data)
      .then(() => {
        const contactInfo = getContactDetail(
          formatPhoneNumberIntl(formData?.contact)
        );
        const formBody = {
          first_name: formData?.firstName,
          last_name: formData?.lastName,
          email: formData?.email,
          password: formData?.password,
          contact: contactInfo?.[1],
          country_code: contactInfo?.[0],
          country_name: regionNames?.of(countryCode),
          remember_token: 1,
          is_verified: 0,
          referrer_id: 1,
          terms: 1,
          role_id: selectedUserId?.role_id,
          fileName:
            decodedToken?.data?.role_id === undefined
              ? (getLocalStorage("fileName") || uploadedFileName)
              : "",
          job_id: JSON.parse(getLocalStorage("ShareToken"))?.["id"]
            ? JSON.parse(getLocalStorage("ShareToken"))?.["id"]
            : decodedToken?.data?.role_id === undefined
            ? getLocalStorage("job_id")
            : "",
          jobs_user_id:
            decodedToken?.data?.role_id === undefined
              ? getLocalStorage("jobs_user_id")
              : "",
          referral_code: JSON.parse(getLocalStorage("ShareToken"))?.[
            "shareToken"
          ],
        };
        if (userType === "promoter") {
          formBody["promoter"] = true;
        }
        if (Cookies.get("favoriteTalent")) {
          formBody["favoriteTalent"] = Cookies.get("favoriteTalent");
        }
        if (Cookies.get("favoriteCompany")) {
          formBody["favoriteCompany"] = Cookies.get("favoriteCompany");
        }
        if (Cookies.get("favoriteJobs")) {
          formBody["favoriteJobs"] = Cookies.get("favoriteJobs");
        }
        if (selectedUserId.role_id === 4 || selectedUserId.role_id === 6) {
          delete formBody["favoriteJobs"];
          delete formBody["favoriteCompany"];
        }
        if (selectedUserId.role_id === 3) {
          delete formBody["favoriteTalent"];
        }
        if (isVideoUploaded) {
          formBody["job_cam_flag"] = true;
        }
        const { payload } = dispatch(signup(formBody)).then((payload) => {
          if (payload?.payload?.status === "success") {
            setSelectedLabel();
            localStorage.setItem("temp", "temp");
            setLocalStorage("token", payload?.payload?.token);
            localStorage.removeItem("fileName");
            localStorage.removeItem("job_id");
            localStorage.removeItem("jobs_user_id");
            dispatch(handleSignState());
            dispatch(setLoggedIn(true));
            // dispatch(
            //   setAlert({
            //     show: true,
            //     type: ALERT_TYPE.SUCCESS,
            //     msg: "User registered successfully!",
            //   })
            // );
            if (onDialogClose) {
              onDialogClose();
            }
            setFormData({});
            setUserType("");
          }
          if (payload?.payload?.status === "error") {
            if (payload?.payload?.message?.emailNotExist === true) {
              setErrors([
                {
                  message:
                    "This email address is already in use. Please try signing up with a different email.",
                  key: "dublicateEmail",
                },
              ]);
            }
            // dispatch(
            //   setAlert({
            //     show: true,
            //     type: ALERT_TYPE?.ERROR,
            //     msg: payload?.payload?.message?.message,
            //   })
            // );
          }
        });
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };
  useEffect(() => {
    console.log(errors, "errors");
  }, [errors]);
  const handleChnage = (e, key) => {
    setFormData((prev) => ({ ...prev, [key]: e }));
    if (!PASSWORD_REGEX?.test(e) && key === "password") {
      setErrors((prev) => [
        ...prev,
        { message: "password not matching", key: "password" },
      ]);
      return;
    } else {
      setErrors(errors?.filter((item) => item?.key !== key));
    }
  };
  const handlePrivacy = () => {
    onDialogClose();
    navigate("/privacyPolicy");
  };
  const handleService = () => {
    onDialogClose();
    navigate("/termsOfService");
  };

  useEffect(() => {
    if (pathname?.slice(1) !== activeTab) {
      setActiveTab(pathname?.slice(1));
    }
  }, [activeTab, pathname]);
  useEffect(() => {
    if (openFunc) {
      getUserCountryFromGeolocation();
    }
  }, [openFunc]);

  const onHandleButtonToggle = (event, type) => {
    setUserType(type);
    setSelectedUserId(USER_ROLES?.filter((role) => role?.name === type)[0]);
    //ROLE_ID
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleForgotPassword = () => {
    setOpenForgotPassword(true);
  };
  const handleCloseForgotPassword = () => {
    setOpenForgotPassword(false);
  };
  const handleOpenSignUpFromforgotPassword = () => {
    handleOpenSignUp(true);
    setOpenForgotPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit();
    setSignupTouched(true);
  };
  function getUserCountryFromGeolocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            const response = await fetch(
              `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
            );

            if (response.ok) {
              const data = await response.json();
              // console.log(data.address.country_code, "COUNTRY");
              const country = data?.address
                ? data?.address.country_code
                : "unknown";
              setCountryCode(country?.toUpperCase());
              initialCountryCode = country;
            } else {
              throw new Error("Failed to fetch location information");
            }
          } catch (error) {
            console.error(error);
          }
        },
        (error) => {
          console.error("Geolocation error:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported in this browser.");
    }
  }

  return (
    <ThemeProvider theme={signupPagetheme}>
      {!openForgotPassword && (
        <Dialog
          open={openFunc}
          onDialogClose={closeFunc}
          dialogWidth="xs"
          showFooter={false}
          scroll="body"
          // title={i18n["login.login"]}
          padding={0}
          sx={{
            zIndex: '99999999999999999999',
            margin: "0px",
            "& .MuiPaper-root": {
              borderRadius: "25px",
              // maxWidth: "450px",
              // width: "100%",
              backgroundColor: "backgroundColor",
              margin: "0px",
            },
            "& .MuiDialog-paperScrollBody": {
              width: "100%",
              maxWidth: { xs: "97%", tablet: "450px" },
            },
            "& .MuiPaper-root-MuiDialog-paper": {
              width: "100%",
              maxWidth: "100%",
            },
          }}
        >
          {/* <DialogTitle onClose={closeFunc} sx={{ padding: "0px" }}>
            <IconButton
              aria-label="close"
              onClick={() => {
                closeFunc();
                setFormData({});
                setUserType("");
                setSignupTouched(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              disableRipple="true"
            >
              <Box
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "#000000",
                  border: 2,
                  fontSize: "18px",
                  borderRadius: "5px",
                }}
              >
                X
              </Box>
            </IconButton>
          </DialogTitle> */}
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              closeFunc();
              setFormData({});
              setUserType("");
              setSignupTouched(false);
            }}
          >
            <CloseIcon />
          </Box>

          <form name="signupform" id="signupForm" onSubmit={handleSubmit}>
            <Box
              sx={{
                marginTop: "21px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "auto",
                width: "100%",
                paddingBottom: "0px",
              }}
            >
              <Box
                src={
                  userType === ""
                    ? loginIcon
                    : userType === "candidate"
                    ? CandidateIcon
                    : userType === "recruiter"
                    ? RecruitIcon
                    : userType === "employer"
                    ? EmployerIcon
                    : userType === "promoter"
                    ? PromoterIcon
                    : ""
                }
                component="img"
                sx={{ width: userType === "" ? 169 : 74, height: 125 }}
              ></Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  letterSpacing: "0.4px",
                  lineHeight: "27px",
                  marginTop: "20px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                Hi, let's get you started!
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  marginTop: "20px",
                  fontWeight: theme.typography.Bold,
                  letterSpacing: "0.32px",
                  marginBottom: "20px",
                }}
              >
                Pick your fighter…
              </Typography>
              {Object.keys(errors)?.length !== 0 && signupTouched ? (
                errors?.find((error) => error.key === "dublicateEmail") ? (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      letterSpacing: "0.28px",
                      fontWeight: theme.typography.Regular,
                      color: "errorMessage",
                      marginBottom: "20px",
                      width: "90%",
                      textAlign: "center",
                    }}
                  >
                    This email address is already in use. Please try signing up
                    with a different email.
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      letterSpacing: "0.28px",
                      fontWeight: theme.typography.Regular,
                      color: "errorMessage",
                      marginBottom: "20px",
                    }}
                  >
                    Oops! Something wasn’t right.
                  </Typography>
                )
              ) : (
                <Typography
                  sx={{
                    fontSize: "14px",
                    letterSpacing: "0.28px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Select a profile type below and complete to join.
                </Typography>
              )}

              <Box
                sx={{
                  // width: "374px",
                  boxSizing: "border-box",
                  width: "100%",
                  paddingX: "30px",
                  display: "flex",
                  flexDirection: "column",
                  mt: "20px",
                  gap: "20px",
                }}
              >
                <Box>
                  <SwipeableButton
                    selectedUser={jobApplyUserType ? "candidate" : userType}
                    onButtonToggle={onHandleButtonToggle}
                    setSignupTouched={setSignupTouched}
                    jobApplyUserType={jobApplyUserType}
                  />
                </Box>
                <Box sx={{ display: "flex", width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <InputBox
                      id="firstName"
                      value={formData?.firstName}
                      onChange={(e) => {
                        handleChnage(e.target.value, "firstName");
                      }}
                      onBlur={(e) => {
                        setFieldTouched([...fieldTouched, "firstName"]);
                      }}
                      placeholder={i18n["login.firstName"]}
                      sx={{
                        mr: 1,
                        width: "90% !important",
                        height: "40px",
                        borderRadius: "10px !important",
                        background: "white !important",
                        border: errors?.find(
                          (error) => error.key === "firstName"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid borderColor",
                      }}
                      style={{ flex: "1" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <InputBox
                      id="lastName"
                      value={formData?.lastName}
                      onChange={(e) => {
                        handleChnage(e.target.value, "lastName");
                      }}
                      onBlur={(e) => {
                        setFieldTouched([...fieldTouched, "lastName"]);
                      }}
                      placeholder={i18n["login.lastName"]}
                      sx={{
                        width: "90% !important",
                        height: "40px",
                        borderRadius: "10px !important",
                        background: "white !important",
                        border: errors?.find(
                          (error) => error.key === "lastName"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid borderColor",
                      }}
                      style={{ flex: "1" }}
                    />
                  </Box>
                </Box>

                <Box>
                  <Paper
                    sx={{
                      display: "flex",
                      height: "40px",
                      width: "100% !important",
                      boxShadow: "none",
                      borderRadius: "10px !important",
                      background: "white !important",
                      border:
                        errors?.find((error) => error?.key === "email") ||
                        errors?.find((error) => error?.key === "dublicateEmail")
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid borderColor",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, mr: 2, width: "100%" }}
                      id="email"
                      value={formData?.email}
                      onChange={(e) => {
                        handleChnage(e.target.value, "email");
                      }}
                      onBlur={(e) => {
                        setFieldTouched([...fieldTouched, "email"]);
                      }}
                      placeholder={i18n["login.emailAddrees"]}
                      style={{
                        width: "100%",
                      }}
                    />
                  </Paper>
                </Box>
                <Box>
                  <Paper
                    sx={{
                      display: "flex",
                      height: "40px",
                      width: "100% !important",
                      borderRadius: "10px !important",
                      background: "white !important",
                      boxShadow: "none",
                      border: errors?.find((error) => error?.key === "password")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid borderColor",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 2, mr: 2, width: "100%" }}
                      id="password"
                      value={formData?.password}
                      onChange={(e) => {
                        handleChnage(e.target.value, "password");
                      }}
                      onBlur={(e) => {
                        setFieldTouched([...fieldTouched, "password"]);
                      }}
                      placeholder={i18n["login.password"]}
                      type={showPassword ? "text" : "password"}
                    />
                    {checkStrength(formData?.password)}
                    <IconButton
                      sx={{ py: 0 }}
                      color=""
                      aria-label="reset password"
                      component="button"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </Paper>
                  {errors?.find((error) => error?.key === "password") &&
                    formData?.password !== "" && (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: theme.typography.Medium,
                          color: "red",
                          mt: "4px",
                          textAlign: "center",
                        }}
                      >
                        {i18n["alert.passwordmustcontainSignup"]}
                      </Typography>
                    )}
                </Box>
                <Box>
                  <Paper
                    sx={{
                      display: "flex",
                      height: "40px",
                      width: "100% !important",
                      borderRadius: "10px !important",
                      background: "white !important",
                      boxShadow: "none",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: errors?.find((error) => error.key === "contact")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid borderColor",
                    }}
                  >
                    <PhoneInput
                      international
                      id="contact"
                      placeholder="Enter phone number"
                      countryCallingCodeEditable={false}
                      defaultCountry={countryCode}
                      value={formData?.contact}
                      onChange={(value) => {
                        handleChnage(value, "contact");
                      }}
                      onBlur={(e) => {
                        setFieldTouched([...fieldTouched, "Contact"]);
                      }}
                      focusInputOnCountrySelection={true}
                      onCountryChange={(value) => {
                        setCountryCode(value);
                      }}
                      style={{
                        font: "inherit",
                        padding: "10px",
                      }}
                    />
                    {isValidPhoneNumber(formData?.contact || "") && (
                      <Typography
                        sx={{
                          color: theme.palette.tealColor.main,
                          fontSize: "14px",
                          alignItems: "center",
                          display: "flex",
                          gap: "5px",
                          marginRight: "5px",
                          justifyContent: "end",
                        }}
                      >
                        Correct <CheckCircle fontSize="small" />
                      </Typography>
                    )}
                  </Paper>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: theme.typography.Medium,
                    }}
                  >
                    Dammit, I meant to
                  </Typography>
                  <Link style={{ textDecoration: "none" }}>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                      onClick={toggleForm}
                    >
                      login
                    </Typography>
                  </Link>
                </Box>
                <Box
                  sx={{
                    width: { xs360: "86%", xs390: "79", tablet: "74%" },
                    margin: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: theme.typography.Regular,
                      textAlign: "center",
                    }}
                  >
                    {i18n["login.s1"]}
                    <br />
                    {/* <Link
                      href={PrivacyPolicy}
                      target="_blank"
                      sx={{ textDecoration: "none" }}
                    >
                      
                    </Link> */}
                    <span
                      style={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      // onClick={openPDFPrivacy}
                      onClick={() => {
                        handlePrivacy();
                      }}
                    >
                      {i18n["login.s4"]}
                    </span>

                    {i18n["login.s3"]}
                    <span
                      style={{
                        fontWeight: theme.typography.Bold,
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      // onClick={openPDFService}
                      onClick={() => {
                        handleService();
                      }}
                    >
                      {i18n["login.s2"]}
                    </span>
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  mt: "20px",
                  justifyContent: "space-between",
                }}
              >
                {/* <Button
                  sx={{
                    width: "50%",
                    height: "60px",
                    borderRadius: 0,
                    padding: 3,
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                  variant="contained"
                  color="forgotPasswordButton"
                  onClick={handleForgotPassword}
                >
                  forgot password?
                </Button> */}
                <Button
                  sx={{
                    width: "100%",
                    height: "60px",
                    borderRadius: 0,
                    padding: 3,
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                  variant="contained"
                  color="signupButton"
                  type="submit"
                >
                  sign me up, Scotty!
                </Button>
              </Box>
            </Box>
          </form>
        </Dialog>
      )}
      <ForgotPassword
        openFunc={openForgotPassword}
        closeFunc={handleCloseForgotPassword}
        openPrevDialog={handleOpenSignUpFromforgotPassword}
        handleToggleFuntion={toggleForm}
        closeSignupModal={closeFunc}
      />
    </ThemeProvider>
  );
}
