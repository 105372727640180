import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import locale from "../../../i18n/locale";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ALERT_TYPE,
  CV_STEPS,
  ERROR_MSG,
  WORK,
} from "../../../utils/Constants";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { getCVWorkLife } from "../../../redux/candidate/myCVNew";
import { setAlert, setLoading } from "../../../redux/configSlice";
import {
  addWorkData,
  setCVName,
  setCVUrl,
  uploadCv,
} from "../../../redux/candidate/myCvSlice";
import { useSelector } from "react-redux";
import { getTitles } from "../../../redux/employer/postJobSlice";
import {
  getCandidateCompany,
  updateInitialCandidateCompanies,
  // getCompanies,
} from "../../../redux/employer/empProfileSlice";
import { useTheme } from "@emotion/react";
import TextEditor from "../../common/postJobsSection/TextEditor";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import previousIcon from "../../../assets/Black_Left_Previous - Copy.svg";
import CustomSelect from "./CustomSelect";
import { debounce, validateSchema } from "../../../utils/Common";
import { ToggleSwitch } from "../../../utils/CommonComponent";
import * as Yup from "yup";
import _ from "lodash";
import uploadCVIcon from "../../../assets/MyCV/UploadCv.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/GrabLinkedinIcon.svg";
import { Link } from "react-router-dom";

export default function WorkLifeNew({
  changeStep,
  handleComplete,
  step,
  initialWorkData,
  setInitialWorkData,
  workData,
  setWorkData,
}) {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const WORD_LIMIT = 50;
  const theme = useTheme();
  const currentDate = new Date();
  const hiddenFileInput = useRef(null);
  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const [errors, setErrors] = useState([]);
  const [noWorkExp, setNoWorkExp] = useState(false);
  const [wordLimitExceed, setWordLimitExceed] = useState(false);
  const [isLoading, setIsLoading] = useState({});
  const disableEndDate = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [inputBoxWidth, setInputBoxWidth] = useState(
    (window.innerWidth * 672) / 1920
  );
  const [boxGap, setBoxGap] = useState((window.innerWidth * 120) / 1920);
  const { titles, candidateCompany } = useSelector((state) => state.myProfile);

  const { cVName, cVUrl } = useSelector((state) => state.myCv);
  const workExperienceSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    title: Yup.string().required("Job title is required"),
    start_date: Yup.date()
      .nullable()
      .required("Start date is required")
      .max(Yup.ref("end_date"), "Start date must be before end date"),
  });

  const formSchema = Yup.object().shape({
    workData: Yup.array().when("noCheck", {
      is: true,
      then: (schema) => schema.of(workExperienceSchema),
      otherwise: (schema) => schema.optional(),
    }),
    noCheck: Yup.bool(),
  });

  const calculateDuration = (index) => {
    const end =
      workData?.[index]?.end_date === "Invalid Date"
        ? null
        : workData?.[index]?.end_date
        ? new Date(workData?.[index]?.end_date)
        : null;

    const start =
      workData?.[index]?.start_date === "Invalid Date"
        ? null
        : workData?.[index]?.start_date
        ? new Date(workData?.[index]?.start_date)
        : null;
    return start === null && end === null
      ? ""
      : start === null
      ? ""
      : calculateDaysBetween(start, end);
  };

  const calculateDaysBetween = (startDate, endDate) => {
    endDate = endDate ? new Date(endDate) : new Date();
    startDate = new Date(startDate);
    const timeDifference = endDate - startDate;
    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
    const months = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24 * 365)) /
        (1000 * 60 * 60 * 24 * 30)
    );
    const days = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
    );

    if (years > 0) {
      return `${years} ${years === 1 ? "year" : "years"} ${
        months > 0 ? `${months} ${months === 1 ? "month" : "months"}` : ""
      }`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "month" : "months"} ${
        days > 0 ? `${days} ${days === 1 ? "day" : "days"}` : ""
      }`;
    } else {
      return `${days} ${days === 1 ? "day" : "days"}`;
    }
  };

  const getAllData = async (para) => {
    try {
      switch (para) {
        case "titles":
          await dispatch(getTitles({ setState: setIsLoading, type: "title" }));
          return;
        case "companies":
          await dispatch(
            getCandidateCompany({
              setState: setIsLoading,
              type: "company_name",
            })
          );
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const handleSaveButton = async (step) => {
    try {
      const data = workData.map((item, index) => {
        return {
          ...item,
          start_date:
            item?.start_date === "" || item?.start_date === null
              ? null
              : new Date(item?.start_date),
          end_date:
            item?.end_date === "" || item?.end_date === null
              ? index > 0
                ? new Date(workData[index - 1]?.start_date)
                : new Date()
              : new Date(item?.end_date),
          company_name: item?.company_name?.label ?? item?.company_name,
          title: item?.title?.label ?? item?.title,
        };
      });
      validateSchema(formSchema, { workData: data, noCheck: !noWorkExp })
        .then(() => {
          dispatch(addWorkData(noWorkExp ? [] : data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.SUCCESS,
                  msg: "Work data added successfully!",
                })
              );
              changeStep(step, "component");
              handleComplete(1);
              setErrors([]);
            } else if (payload?.payload?.status === "error") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.ERROR,
                  msg: "Something went wrong",
                })
              );
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE.ERROR,
                  msg: "Fill the work life",
                })
              );
            }
          });
        })
        .catch((error) => {
          setErrors(error);
        });
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getCVWorkLifeData = async () => {
    const { payload } = await dispatch(getCVWorkLife());
    if (payload?.status === "success") {
      setNoWorkExp(payload?.work_experience);
      if (payload?.data?.length) {
        const processedWorkData = payload.data.map((item) => {
          if (item?.end_date === null) {
            item.currently_employed_here = 1;
          }
          if (item?.company_name) {
            item.company_name = item?.company_id
              ? { value: item.company_id, label: item.company_name }
              : { value: item.company_name, label: item.company_name };
          }
          if (item?.title) {
            item.title = item?.title_id
              ? { value: item.title_id, label: item.title }
              : { value: item.title, label: item.title };
          }
          return item;
        });
        setWorkData(processedWorkData);
        setInitialWorkData(processedWorkData);
      } else {
        setWorkData([WORK]);
        setInitialWorkData([WORK]);
      }
    } else if (payload?.status === "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Fill the work life details",
        })
      );
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: payload?.data,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const addWork = () => {
    setWorkData((prevState) => {
      if (prevState.length > 0) {
        const lastWork = prevState[prevState.length - 1];
        const newWork = { ...WORK, end_date: lastWork.start_date };
        return [...prevState, newWork];
      }
      return [...prevState, WORK];
    });
  };
  const removeWork = (event, index) => {
    if (workData.length > 1) {
      const newWorkData = workData?.filter((data, i) => i + 1 !== index);
      setWorkData(newWorkData);
    }
  };
  const handleChange = (event, index, type, id) => {
    const newWorkData = JSON.parse(JSON.stringify(workData));
    if (event && event?.target) {
      if (event?.target?.value.split(" ").length <= WORD_LIMIT) {
        setWordLimitExceed(false);
        newWorkData[index][event?.target?.id] = event?.target?.value;
      } else {
        setWordLimitExceed(true);
      }
    } else {
      if (event === "" || event === null) {
        newWorkData[index][type] = null;
      } else {
        newWorkData[index][type] = dayjs(event).format("YYYY-MM-DD");
        if (
          type === "start_date" &&
          index < newWorkData?.length - 1 &&
          typeof newWorkData[index + 1] === "object" &&
          !newWorkData[index + 1]?.end_date
        ) {
          // Added check for existing end_date

          newWorkData[index + 1].end_date = dayjs(
            newWorkData[index]?.start_date
          ).format("YYYY-MM-DD");
        }
      }
      setWorkData(newWorkData);
    }
    let obj = { ...errors };
    if (event && type === "start_date") {
      delete obj[`workData[${index}].${type}`];
    }
    setErrors({ ...obj });
  };

  const handleInputChange = (inputText, type, index) => {
    const newWorkData = JSON.parse(JSON.stringify(workData));
    if (inputText?.split(" ")?.length <= WORD_LIMIT) {
      setWordLimitExceed(false);
      newWorkData[index][type] = inputText;
      setWorkData(newWorkData);
    } else {
      setWordLimitExceed(true);
    }
  };

  const handleNoExp = (event) => {
    setErrors([]);
    setNoWorkExp(event?.target?.checked);
  };

  const handleSwitch = (event, index) => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let currentDate = `${year}-${month}-${day}`;

    const updatedWorkData = [...workData];

    updatedWorkData[index] = {
      ...updatedWorkData?.[index],
      [event?.target?.id]: event?.target?.checked ? 1 : 0,
      end_date: currentDate,
    };

    disableEndDate.current = event?.target?.checked;
    setWorkData(updatedWorkData);
  };

  const getNewWidth = () => {
    setInputBoxWidth((window.innerWidth * 672) / 1920);
    setBoxGap((window.innerWidth * 120) / 1920);
  };
  const onCreateOption = (data, inputId, index) => {
    const updatedWorkData = [...workData];

    if (data) {
      updatedWorkData[index] = {
        ...updatedWorkData[index],
        [inputId]: { label: data, value: data },
      };
      setWorkData(updatedWorkData);
    }
    let obj = { ...errors };
    if (data) {
      delete obj[`workData[${index}].${inputId}`];
    }
    setErrors({ ...obj });
  };
  const updateDebounceFunc = async (newValue, type) => {
    let params = {
      title: newValue || "",
      limit: 200,
    };
    if (newValue) {
      try {
        if (type === "company_name") {
          dispatch(
            getCandidateCompany({ ...params, setState: setIsLoading, type })
          );
        }
        if (type === "title") {
          dispatch(getTitles({ ...params, setState: setIsLoading, type }));
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    }
  };
  const debounceFunction = _.debounce(updateDebounceFunc, 750);

  const handleOnSelectInputChange = (newValue, inputId) => {
    debounceFunction(newValue, inputId);
  };

  const handleOnSelectChange = (ind, newValue, val, index) => {
    if (newValue === null) {
      dispatch(updateInitialCandidateCompanies());
    }
    const updatedWorkData = [...workData];
    updatedWorkData[ind] = {
      ...updatedWorkData[ind],
      [index]: newValue,
    };
    setWorkData(updatedWorkData);
    let obj = { ...errors };
    if (newValue?.value || newValue?.label) {
      delete obj[`workData[${ind}].${index}`];
    }
    setErrors({ ...obj });
  };

  const onMenuOpen = () => {
    if (candidateCompany?.length < 1) {
      getAllData("companies");
    }
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleFileClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleClick = (e) => {
    e.preventDefault();
      const newWindow = window.open("", "_blank");
      newWindow.document.write(`
        <html>
        <head>
          <title>Crayon Jobs - ${cVName}</title>
        </head>
          <body style="margin: 0;">
            <iframe src='https://view.officeapps.live.com/op/embed.aspx?src=${cVUrl}' width='100%' height='100%' frameborder='0'></iframe>
          </body>
        </html>
      `);
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append("cv", event?.target?.files?.[0]);
    try {
      const { payload } = await dispatch(uploadCv(formData));
      if (payload?.status === "success") {
        dispatch(setCVName(event?.target?.files?.[0]?.name));
        dispatch(setCVUrl(payload?.cv_url));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.SUCCESS,
            msg: "CV uploaded Successfully!",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, []);

  useEffect(() => {
    getCVWorkLifeData();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md24: "row" },
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="yoco"
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                minHeight: "45px",
                minWidth: { xs: "", md24: "150px" },
                padding: { xs: "6px 16px", md24: "6px 16px" },
                borderRadius: "25px 0 10px 0",
                textWrap: "nowrap",
                boxShadow: "none",
                "& .MuiButton-startIcon": {
                  marginLeft: "0px",
                },
              }}
            >
              {i18n["myCV.worklife"]}
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: "none", md24: "flex" },
              alignItems: "center",
              ml: 1,
            }}
          >
            <ToggleSwitch
              onChange={handleNoExp}
              checked={noWorkExp}
              color="greenSwitch"
            />
            <Typography
              sx={{
                fontWeight: theme.typography.Regular,
              }}
            >
              {i18n["myCV.noWorkExp"]}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ textAlign: "right" }}>
          <input
            accept={fileAccept}
            ref={hiddenFileInput}
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            required
          />
          <Button
            variant="contained"
            color={cVName !== "No file chosen" ? "checkColor" : "peachPayment"}
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 10px"},
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "0px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={uploadCVIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {cVName !== "No file chosen" ? "CV uploaded" : "upload my CV"}
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Bold,
              minHeight: "45px",
              minWidth: { xs: "", md24: "150px" },
              // borderRadius:{xs:"0px",md24:"0 0 0 25px"},
              padding: { xs: "5px", md24: "0px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            grab LinkedIn
          </Button>
          <Link
              to={cVName?.endsWith(".pdf") ? cVUrl : "#"}
              onClick={cVName?.endsWith(".pdf") ? () => {} : handleClick}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
              }}
              target="_blank"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: theme?.typography?.Regular,
                  ml: 1,
                  mt: "4px",
                }}
              >
                {cVName}
              </Typography>
            </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "flex", md24: "none" },
          alignItems: "center",
          ml: 1,
        }}
      >
        <ToggleSwitch
          onChange={handleNoExp}
          checked={noWorkExp}
          color="greenSwitch"
        />
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: theme.typography.Regular,
          }}
        >
          {i18n["myCV.noWorkExp"]}
        </Typography>
      </Box>
      <Box sx={{ padding: "0px 32px 0px 32px !important" }}>
        {workData?.length >= 0 &&
          workData?.map((work, index) => (
            <Box key={index}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  mb: 3,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index !== 0 && (
                    <IconButton
                      aria-label="edit"
                      color="redButton"
                      sx={{
                        padding: "0 !important",
                        marginRight: "4px",
                      }}
                      onClick={(event) => removeWork(event, index + 1)}
                    >
                      <RemoveCircleIcon />
                    </IconButton>
                  )}
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme.typography.Bold,
                      flex: 1,
                    }}
                  >
                    {index === 0
                      ? i18n["myCV.latestJobLabel"]
                      : i18n["myCV.previousJobLabel"]}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontWeight: theme.typography.Medium,
                      pl: 1,
                      pr: 1,
                    }}
                  >
                    {index === 0 ? i18n["myCV.mostRecent"] : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", md24: "row" },
                  mb: 3,
                  gap: { xs: `20px`, xl: "120px" },
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <InputLabel
                    htmlFor="company_name"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                    }}
                  >
                    {i18n["myCV.companyNameLabelReq"]}
                  </InputLabel>
                  <CustomSelect
                    isDisabled={noWorkExp}
                    index={index}
                    variant="createSelect"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    onCreateOption={onCreateOption}
                    placeholder={"Select or add the name of the company"}
                    inputId="company_name"
                    name="company_name"
                    value={
                      work?.company_name?.label ? work?.company_name : null
                    }
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuClose}
                    option={candidateCompany?.map((item) => ({
                      value: item?.candidate_company_id,
                      label: item?.name,
                    }))}
                    isLoading={isLoading["company_name"]}
                    customStyle={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,

                        border: errors?.[`workData[${index}].company_name`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.[`workData[${index}].company_name`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <InputLabel
                    htmlFor="title"
                    sx={{
                      color: "black",
                      paddingBottom: "2px",
                      fontSize: "14px",
                      fontWeight: theme.typography.Bold,
                      width: { xs: "100%", xl: `${inputBoxWidth}px` },
                    }}
                  >
                    {i18n["myCV.jobTitleLableReq"]}
                  </InputLabel>

                  <CustomSelect
                    isDisabled={noWorkExp}
                    index={index}
                    variant="createSelect"
                    onInputChange={handleOnSelectInputChange}
                    onChange={handleOnSelectChange}
                    onCreateOption={onCreateOption}
                    placeholder={"What is/was your job title?"}
                    inputId="title"
                    name="title"
                    value={work?.title?.label ? work?.title : null}
                    onMenuOpen={() => {
                      if (titles.length < 1) {
                        getAllData("titles");
                      }
                      setIsMenuOpen(false);
                    }}
                    onMenuClose={onMenuClose}
                    option={titles?.map((item) => ({
                      value: item?.job_title_id,
                      label: item?.title,
                    }))}
                    isLoading={isLoading["title"]}
                    customStyle={{
                      menu: (provided) => ({
                        ...provided,
                        zIndex: 9999,
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => {
                        return {
                          ...styles,
                          color: isSelected ? "#fff" : "black",
                          ":active": {
                            ...styles[":active"],
                          },
                        };
                      },
                      menuList: (provided) => ({
                        ...provided,
                        "&::-webkit-scrollbar": {
                          width: "6px",
                          height: "6px",
                          borderRadius: "2.5px",
                          backgroundColor: "#efefef",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          borderRadius: "2.5px",
                        },
                      }),
                      control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: "none",
                        borderRadius: "10px",
                        height: `40px`,

                        border: errors?.[`workData[${index}].title`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        "&:hover": errors?.[`workData[${index}].title`]
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",

                        width: `100%`,
                      }),
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md24: "row" },
                  mb: 3,
                  width: { xs: "100%", xl: "60%" },
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md24: "" },
                  }}
                >
                  <Box sx={{ width: { xs: "42%", xl: "215px" } }}>
                    <InputLabel
                      htmlFor="startdate"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {i18n["myCV.startDateReq"]}
                    </InputLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en-gb"
                    >
                      <DatePicker
                        maxDate={work?.end_date || currentDate}
                        disabled={noWorkExp}
                        value={work?.start_date}
                        onChange={(newValue) =>
                          handleChange(newValue, index, "start_date")
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              width: "95%",
                              "& .MuiInputBase-root": {
                                height: "40px",
                                width: "100%",
                                fontSize: "14px",
                                borderRadius: "10px",
                                border: errors?.[
                                  `workData[${index}].start_date`
                                ]
                                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                                  : "1px solid #E0E0E0",
                                ":hover": {
                                  border: errors?.[
                                    `workData[${index}].start_date`
                                  ]
                                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                                    : "1px solid #E0E0E0",
                                },
                              },

                              "& fieldset": {
                                border: "none",
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginTop: "21px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: theme.typography.Regular,
                        textWrap: "nowrap",
                      }}
                    >
                      {"I still work here"}
                    </Typography>
                    <ToggleSwitch
                      disabled={noWorkExp}
                      id="currently_employed_here"
                      checked={!!Number(work?.currently_employed_here)}
                      onChange={(event) => handleSwitch(event, index)}
                      color="greenSwitch"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md24: "" },
                  }}
                >
                  <Box sx={{ width: { xs: "42%", xl: "215px" } }}>
                    <InputLabel
                      htmlFor="enddate"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      {i18n["myCV.endDate"]}
                    </InputLabel>
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      locale="en-gb"
                    >
                      <DatePicker
                        disabled={
                          noWorkExp || !!Number(work?.currently_employed_here)
                        }
                        value={work?.end_date}
                        minDate={work?.start_date}
                        onChange={(newValue) => {
                          handleChange(newValue, index, "end_date");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: "41px",
                                borderRadius: "10px",
                                fontSize: "14px",
                              },
                              "& fieldset": {
                                borderColor: "#C9CAD8 !important",
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ width: { xs: "42%", xl: "215px" } }}>
                    <InputLabel
                      htmlFor="enddate"
                      sx={{
                        color: "black",
                        paddingBottom: "2px",
                        fontSize: "14px",
                        fontWeight: theme.typography.Bold,
                      }}
                    >
                      Total Duration
                    </InputLabel>
                    <TextField
                      disabled={noWorkExp}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "41px",
                          borderRadius: "10px",
                          fontSize: "14px",
                        },
                        "& fieldset": {
                          borderColor: "#C9CAD8 !important",
                        },
                      }}
                      value={calculateDuration(index)}
                      placeholder="00 years, 00 months"
                    />
                  </Box>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <InputLabel
                  htmlFor="clients_worked_on_awards"
                  sx={{
                    color: "black",
                    paddingBottom: "2px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  Description incl. clients, awards, accolades
                </InputLabel>
                <TextEditor
                  disabled={noWorkExp}
                  value={work?.clients_worked_on_awards}
                  border="1px solid #C9CAD8"
                  borderRadius="10px"
                  editorIndex={index}
                  type="clients_worked_on_awards"
                  onInputCHange={handleInputChange}
                  placeholder="Add any details you’d like to highlight"
                  width={{ xs: "97.5%", xl: 1443 }}
                  minHeight={80}
                  padding="10px"
                />
                {wordLimitExceed && (
                  <Typography color={"red !important"}>
                    Word limit {WORD_LIMIT}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
      </Box>
      <Button
        disabled={noWorkExp}
        variant="contained"
        color="nandos"
        onClick={addWork}
        sx={{
          borderRadius: "0px 10px 10px 0px",
          width: "150px",
          height: "45px",
        }}
      >
        {i18n["myCV.workBottonText"]}
      </Button>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          pt: 5,
        }}
      >
        <Button
          sx={{
            boxShadow: 0,
            padding: "0px",
            fontSize: "14px",
            width: "150px",
            height: "60px",
            borderRadius: "10px 0px 0px 0px",
            color: "black",
          }}
          startIcon={
            <Box
              component={"img"}
              src={previousIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          variant="contained"
          color="lockItIn"
          onClick={() => {
            handleSaveButton(0);
          }}
        >
          {"the basics"}
        </Button>
        <Button
          onClick={() => handleSaveButton(2)}
          sx={{
            boxShadow: 0,
            padding: "0px",
            fontSize: "14px",
            width: "150px",
            height: "60px",
            borderRadius: "0px 10px 0px 0px",
          }}
          endIcon={
            <Box
              component={"img"}
              src={nextIcon}
              sx={{
                height: "25px",
                width: "25px",
              }}
            />
          }
          variant="contained"
          color="nandos"
        >
          study life
        </Button>
      </Box>
    </>
  );
}
