import React, { useCallback, useEffect, useState } from "react";
import CommonLoader from "../../../common/CommonLoader";
import {
  Box,
  Button,
  InputLabel,
  Modal,
  Slider,
  Typography,
  useTheme,
} from "@mui/material";
import { setAlert, setLoading } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  BASIC_RANGEMARKS,
  BASIC_RANGEMARKS2,
  BASIC_RANGEMARKS_SLIDER,
  ERROR_MSG,
  EXPERIENCE,
  NOTICEPERIOD,
  emailRegex,
  isValidLinkedInUrl,
} from "../../../../utils/Constants";
import {
  getAllTalentJobs,
  getTalentFullDetails,
  updateTalentDetail,
} from "../../../../redux/admin/jobsSlice";
import { useDispatch } from "react-redux";
import locale from "../../../../i18n/locale";
import InputBox from "../../../common/InputBox";
import CustomSelect from "../../../candidate/myCV/CustomSelect";
import {
  debounce,
  formatNumber,
  genarateLabelValue,
  genarateLabelValueForFreelance,
  getContactDetail,
  getUserCountryFromGeolocation,
  textValue,
  validateSchema,
} from "../../../../utils/Common";
import {
  getCurrency,
  getIndustries,
  getQualifications,
  getSkills,
  getTitles,
} from "../../../../redux/candidate/myCvSlice";
import { useSelector } from "react-redux";
import SelectMenu from "../../../common/SelectMenu";
import {
  getCountries,
  getRoleTypes,
  getTools,
  getTown,
  getWorkSetup,
} from "../../../../redux/employer/postJobSlice";
import EditTalentProfile from "./EditTalentProfile";
import EditBasicInfo from "./EditBasicInfo";
import {
  getLanguage,
  getNationality,
  getSchool,
  getInstitute,
  getQualification,
  getTypeQualificationValue,
  getAssociation,
  addStudyData,
} from "../../../../redux/candidate/myCvSlice";
import EditWorkLife from "./EditWorkLife";
import { getCandidateCompany } from "../../../../redux/employer/empProfileSlice";
import EditStudyLife from "./EditStudyLife";
import EditReferences from "./EditReferences";
import * as Yup from "yup";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import { dateConverter } from "../../../../utils/DateTime";
import Loader from "../../../common/Loader";

export default function EditTalent({
  show,
  handleClose,
  editTalent,
  getJobList,
  setAllTalent,
  allTalent,
  pinTalentFlag,
  talentContent
}) {
  const isLoading = useSelector((state) => state.config.loading);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    height: { sm: "480px", lgmm: "650px", lgm: "750px", xl: "800px" },
    bgcolor: "background.paper",
    borderRadius: "10px 10px 0 0",
    boxShadow: "none !important",
  };

  const workExperienceSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    title: Yup.string().required("Job title is required"),
    start_date: Yup.date().required("Start date is required").nullable(),
  });

  const schoolSchema = Yup.object().shape({
    school_id: Yup.string().required("School name is required"),
  });
  const associationSchema = Yup.object().shape({
    association_id: Yup.string().required("School name is required"),
  });

  const qualificationSchema = Yup.object().shape({
    institution_id: Yup.string().required("Institution is required"),
    year_ended: Yup.string().required("Year of completion is required"),
    qualification_id: Yup.string().required("Qualification is required"),
    qualificationtype_id: Yup.string().required(
      "Type of qualification is required"
    ),
  });

  const referenceSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    company_name: Yup.string().required("Company name is required"),
    title: Yup.string().required("Title is required"),
    // contact: Yup.string()
    //   .required("Contact is required")
    //   .test("is-valid-number", "Invalid contact", function (value) {
    //     let isValid = isValidPhoneNumber(value);
    //     return isValid;
    //   }),
    contact: Yup.string().required("Contact is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email format"),
  });

  const candidateInfoSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is Required."),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .matches(emailRegex, "Invalid email address") // Use custom regex for email validation
      .required("Email is Required."),
    dob: Yup.string().required("dob is Required."),
    contact_no: Yup.string().required("Contact No. is Required."),
    country_id: Yup.number().required("country_id is Required."),
    town_id: Yup.number().required("town_id is Required."),
    linkedin_profile_link: Yup.string()
      .test(
        "isValidLinkedIn",
        "Invalid LinkedIn URL",
        (value) => !value || isValidLinkedInUrl(value)
      )
      .required("Hyperlink is Required."),
    nationality_ids: Yup.array()
      .of(Yup.number())
      .required("Nationality is Required.")
      .min(1, "At least one nationality is required."),
    language_ids: Yup.array()
      .of(Yup.number())
      .required("Language is Required.")
      .min(1, "At least one language is required."),
    my_bio: Yup.string().required("My bio is required"),
    skinz: Yup.mixed().required("Skinz is Required."),

    current_job_title: Yup.string().required("Current job title is required"),
    dream_job_title: Yup.string().required("Dream job title is required"),
    employment_type: Yup.string().required("Employment type is required"),
    work_setup: Yup.string().required("Work setup is required"),
    industries_id: Yup.array()
      .of(Yup.number())
      .required("Industries is Required.")
      .min(1, "At least one industries is required.")
      .max(5, "No more than 5 industries"),
    tags_id: Yup.array()
      .of(Yup.number())
      .required("Industries is Required.")
      .min(3, "At least three skills are required")
      .max(10, "No more than 10 skills"),
    tools_id: Yup.array()
      .of(Yup.number())
      .required("Industries is Required.")
      .min(3, "At least three tools are required")
      .max(10, "No more than 10 tools"),
    qualification_level: Yup.string().required(
      "Qualification level is required"
    ),
    currency_id: Yup.string().required("Currency is required"),
    salary: Yup.array().of(Yup.number().required("Salary range is required")),
    experience_id: Yup.number()
      .min(0, "Invalid experience")
      .max(20, "Invalid experience")
      .required("Experience is required"),
    notice_period_id: Yup.number()
      .min(0, "Invalid notice period")
      .max(7, "Invalid notice period")
      .required("Notice period is required"),
    employHistory: Yup.array()
      .of(workExperienceSchema)
      .required("Work experience is required"),
    qualificationdata: Yup.array()
      .of(qualificationSchema)
      .required("Qualification is required"),
    schooldata: Yup.array().of(schoolSchema).required("School is required"),
    associationdata: Yup.array()
      .of(associationSchema)
      .required("Association is required"),
    referencedata: Yup.array()
      .of(referenceSchema)
      .required("Reference is required"),
    gender: Yup.string()
      .when("gender_flag", {
        is: false,
        then: (schema) => schema.required("Gender is Required."),
        otherwise: (schema) => schema.optional(),
      })
      .nullable()
      .notRequired(),
  });

  const {
    titles,
    currency,
    qualifications,
    industries,
    language,
    association,
    nationality,
    skills,
    salary,
    initialIndustries,
    initialSkills,
    school,
    institution,
    qualification,
    typeOfQualification,
  } = useSelector((state) => state?.myCv);
  const { tools, roleTypes, workSetup, initialTools, countries, town } =
    useSelector((state) => state?.postJobs);

  const { candidateCompany } = useSelector((state) => state.myProfile);

  const dispatch = useDispatch();
  const i18n = locale.en;
  const theme = useTheme();
  // const [isLoading, setIsLoading] = useState(false);
  const [salaryRange, setSalaryRange] = useState(BASIC_RANGEMARKS_SLIDER);
  const [initialLoading, setInitialLoading] = useState(true);
  const [talentDetails, setTalentDetails] = useState();
  const [errors, setErrors] = useState([]);
  const [characterCount, setCharacterCount] = useState();
  const [count, setCount] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [rangeValue, setRangeValue] = useState([]);
  const [contactNumber, setContactNumber] = useState();
  const [workLife, setWorkLife] = useState([]);
  const [studyLife, setStudyLife] = useState([]);
  const [initialCountryCode, setInitialCountryCode] = useState("");
  const [interval, setInterval] = useState();
  const [singleSalaryValue, setSingleSalaryValue] = useState(0);

  const getAllData = async (para, countryID) => {
    try {
      switch (para) {
        case "currency":
          await dispatch(getCurrency());
          return;
        case "titles":
          await dispatch(getTitles());
          return;
        case "industry":
          await dispatch(getIndustries());
          return;
        case "companies":
          await dispatch(getCandidateCompany());
          return;
        case "skills":
          await dispatch(getSkills());
          return;
        case "tools":
          await dispatch(getTools());
          return;
        case "roleType":
          await dispatch(getRoleTypes());
          return;
        case "workSetup":
          await dispatch(getWorkSetup());
          return;
        case "qualifications":
          await dispatch(getQualifications());
          return;
        case "nationality":
          await dispatch(getNationality());
          return;
        case "language":
          await dispatch(getLanguage());
          return;
        case "school":
          await dispatch(getSchool());
          return;
        case "institution":
          await dispatch(getInstitute());
          return;
        case "qualification":
          await dispatch(getQualification());
          return;
        case "association":
          await dispatch(getAssociation());
          return;
        case "typeQualification":
          await dispatch(getTypeQualificationValue());
          return;
        case "country":
          await dispatch(getCountries());
          return;
        case "town":
          await dispatch(getTown(countryID));
          return;
        default:
          return;
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getInitialData = async (basic) => {
    // setInitialLoading(true);
    dispatch(setLoading(true));
    // if (basic?.current_job_title_id !== "") {
    //   await getAllData("titles");
    // }
    // if (basic?.industry_id?.length > 0) {
    //   await getAllData("industry");
    // }
    // if (basic?.tags?.length > 0) {
    //   await getAllData("skills");
    // }
    // if (basic?.tools?.length > 0) {
    //   await getAllData("tools");
    // }
    if (basic?.currency_id !== "") {
      await getAllData("currency");
    }
    if (basic?.country_id !== null) {
      getAllData("country");
    }
    if (basic?.qualification_level !== "") {
      await getAllData("qualifications");
    }

    if (basic?.employment_type !== "") {
      await getAllData("roleType");
    }
    if (basic?.work_setup !== "") {
      await getAllData("workSetup");
    }
    // if (basic?.nationality_ids?.length !== 0) {
    //   getAllData("nationality");
    // }
    // if (basic?.language_ids?.length !== 0) {
    //   getAllData("language");
    // }
    if (basic?.qualificationdata?.length !== 0) {
      // getAllData("institution");
      // getAllData("qualification");
      getAllData("typeQualification");
    }
    // if (basic?.schooldata?.length !== 0) {
    //   getAllData("school");
    // }
    // if (basic?.associationdata?.length !== 0) {
    //   getAllData("association");
    // }

    // if (basic?.town_id !== null) {
    //   getAllData("town", basic?.country_id);
    // }
    // setInitialLoading(false);
    dispatch(setLoading(false));
  };

  const getInitialSalary = (basicData) => {
    const currencySalary = currency?.find(
      (item) => item?.currency_id === basicData?.currency_id
    );
    let range;
    let salaryInterval;
    if (basicData?.employment_type === "freelance") {
      let { rangeArray, interval } = genarateLabelValueForFreelance(
        currencySalary ? currencySalary : { max_rate: 2050 }
      );
      range = rangeArray;
      salaryInterval = interval;
      setInterval(interval);
      setRangeValue([range?.length / 10, range?.length / 2]);
      setSingleSalaryValue(range?.length / 2);
    } else {
      range = genarateLabelValue(
        currencySalary ? currencySalary : { max_salary: 205000 }
      );
    }
    setSalaryRange(range);

    if (basicData?.employment_type === "freelance") {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 100,
              basicData?.salary?.[1] / 100,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 100);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / salaryInterval,
              basicData?.salary?.[1] / salaryInterval,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / salaryInterval);
      }
    } else {
      if (basicData?.currency_id === 1) {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 10000,
              basicData?.salary?.[1] / 10000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 10000);
      } else {
        basicData?.salary?.[0] > 0
          ? setRangeValue([
              basicData?.salary?.[0] / 1000,
              basicData?.salary?.[1] / 1000,
            ])
          : setSingleSalaryValue(basicData?.salary?.[1] / 1000);
      }
    }
  };

  const getTalentDetail = async () => {
    // setInitialLoading(true);
    dispatch(setLoading(true));

    if (editTalent) {
      try {
        const { payload } = await dispatch(getTalentFullDetails(editTalent));
        if (payload?.status === "success") {
          const basic = {
            ...payload?.data,
            gender_flag: payload?.data?.gender_flag,
            // gender_flag: true,
          };
          if (basic?.experience_end) {
            basic.experience = basic?.experience_end;
          }

          if (basic?.town_id) {
            basic.towns = {
              value: basic?.town_id,
              label: basic?.townName,
            };
          }

          if (basic?.title && basic?.current_job_title_id) {
            basic.current_job_title_id = {
              value: basic?.current_job_title_id,
              label: basic?.title,
            };
          }

          if (basic?.dreamjob_title && basic?.dreamjob_title) {
            basic.dream_job_title_id = {
              value: basic?.dream_job_title_id,
              label: basic?.dreamjob_title,
            };
          }
          if (basic?.salary?.length !== 0) {
            basic.salary = [basic?.salary?.[0], basic?.salary?.[1]];
          }
          if (basic?.salary?.length === 0) {
            basic.currency_id = 1;
            basic.salary = [0, 100000];
          }
          if (basic?.highest_qualification_id) {
            basic.qualification_level = basic?.highest_qualification_id;
          }
          if (basic?.nationality?.length !== 0) {
            basic.nationality_ids = basic?.nationality?.map((item) => ({
              value: item?.nationality_id,
              label: item?.name,
            }));
          }
          if (basic?.language?.length !== 0) {
            basic.language_ids = basic?.language?.map((item) => ({
              value: item?.language_id,
              label: item?.name,
            }));
          }
          if (basic?.skill?.length !== 0) {
            basic.skill_ids = basic?.skill?.map((item) => ({
              value: item?.tag_id,
              label: item?.name,
            }));
          }
          if (basic?.industry?.length !== 0) {
            basic.industries_ids = basic?.industry?.map((item) => ({
              value: item?.industry_id,
              label: item?.name,
            }));
          }
          if (basic?.tool?.length !== 0) {
            basic.tool_ids = basic?.tool?.map((item) => ({
              value: item?.tool_id,
              label: item?.name,
            }));
          }
          if (basic?.country_name && basic?.country_id) {
            basic.country = basic?.country_name;
          }
          // setRangeValue([
          //   payload?.data?.employment_type === "freelance"
          //     ? basic?.salary?.[0] / 5
          //     : basic?.salary?.[0] / 2000,
          //   payload?.data?.employment_type === "freelance"
          //     ? basic?.salary?.[1] / 5
          //     : basic?.salary?.[1] / 2000,
          // ]);

          setContactNumber(payload?.data?.contact_no);
          setCharacterCount(payload?.data?.my_bio?.slice(0, 500)?.length);
          setTalentDetails(basic);
          getInitialData(payload?.data);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.error"],
            })
          );
        }
        // setInitialLoading(false);
        // dispatch(setLoading(false));
      } catch (error) {
        // setInitialLoading(false);
        // dispatch(setLoading(false));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.error"],
          })
        );
      }
    } else {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "User id is not there",
        })
      );
    }
  };

  const editTalentDetail = async () => {
    let {
      current_job_title,
      dream_job_title,
      experience,
      notice_period_id,
      qualification_level,
      employment_type,
      work_setup,
      salary,
      currency_id,
      nationality_ids,
      language_ids,
      first_name,
      last_name,
      contact_no,
      gender,
      email,
      dob,
      country_id,
      town_id,
      my_bio,
      skinz,
      own_transport,
      own_equipment,
      internet_access,
      linkedin_profile_link,
      tags_id,
      tools_id,
      industries_id,
      employHistory,
      qualificationdata,
      schooldata,
      associationdata,
      referencedata,
      relocate,
      seeking_job,
    } = talentDetails;

    let testErrorState = {
      first_name: talentDetails?.first_name,
      last_name: talentDetails?.last_name,
      contact_no: talentDetails?.contact_no,
      email: talentDetails?.email,
      dob: talentDetails?.dob,
      country_id: talentDetails?.country_id,
      town_id: talentDetails?.towns?.value,
      my_bio: talentDetails?.my_bio,
      skinz: talentDetails?.skinz,
      linkedin_profile_link: talentDetails?.linkedin_profile_link,
      nationality_ids: talentDetails?.nationality_ids?.map((item) => {
        return item?.value;
      }),
      language_ids: talentDetails?.language_ids?.map((item) => {
        return item?.value;
      }),
      current_job_title: talentDetails?.current_job_title_id?.label, // Title
      dream_job_title: talentDetails?.dream_job_title_id?.label,
      notice_period_id: talentDetails?.notice_period_id,
      qualification_level: talentDetails?.qualification_level,
      employment_type: talentDetails?.employment_type, // Title
      work_setup: talentDetails?.work_setup,
      salary: talentDetails?.salary,
      currency_id: talentDetails?.currency_id,
      experience_id: talentDetails?.experience,
      tags_id: talentDetails?.skill_ids?.map((item) => {
        return item?.value;
      }),
      tools_id: talentDetails?.tool_ids?.map((item) => {
        return item?.value;
      }),
      industries_id: talentDetails?.industries_ids?.map((item) => {
        return item?.value;
      }),
      employHistory: talentDetails?.worklife,
      qualificationdata: talentDetails?.qualificationdata,
      schooldata: talentDetails?.schooldata,
      associationdata: talentDetails?.associationdata,
      referencedata: talentDetails?.reference,
      gender: talentDetails?.gender,
      gender_flag: talentDetails?.gender_flag,
    };
    validateSchema(candidateInfoSchema, testErrorState)
      .then(() => {
        try {
          const contactInfo = getContactDetail(
            formatPhoneNumberIntl(talentDetails?.contact_no)
          );
          let data = {
            talent_id: editTalent,
            current_job_title: talentDetails?.current_job_title_id?.label, // Title
            dream_job_title: talentDetails?.dream_job_title_id?.label, // Title
            experience: [0, talentDetails?.experience],
            notice_period_id: talentDetails?.notice_period_id,
            qualification_level: talentDetails?.qualification_level,
            employment_type: talentDetails?.employment_type,
            work_setup: talentDetails?.work_setup,
            salary: talentDetails?.salary,
            currency_id: talentDetails?.currency_id,
            // ...talentDetails,
            nationality_ids: talentDetails?.nationality_ids?.map((item) => {
              return item?.value;
            }),
            language_ids: talentDetails?.language_ids?.map((item) => {
              return item?.value;
            }),
            first_name: talentDetails?.first_name,
            last_name: talentDetails?.last_name,
            // contact_no: talentDetails?.contact_no?.startsWith("+")
            //   ? talentDetails?.contact_no
            //   : `+${talentDetails?.contact_no}`,
            contact_no:
              contactInfo?.[0] !== ""
                ? contactInfo?.[1]
                : talentDetails?.contact_no,
            country_code:
              contactInfo?.[0] !== ""
                ? contactInfo?.[0]
                : talentDetails?.country_code,
            gender: talentDetails?.gender,
            email: talentDetails?.email,
            dob: talentDetails?.dob,
            country_id: talentDetails?.country_id,
            town_id: talentDetails?.towns?.value,
            my_bio: talentDetails?.my_bio,
            skinz: talentDetails?.skinz,
            own_transport: talentDetails?.own_transport,
            own_equipment: talentDetails?.own_equipment,
            internet_access: talentDetails?.internet_access,
            linkedin_profile_link: talentDetails?.linkedin_profile_link,
            tags_id: talentDetails?.skill_ids?.map((item) => {
              return item?.value;
            }),
            tools_id: talentDetails?.tool_ids?.map((item) => {
              return item?.value;
            }),
            industries_id: talentDetails?.industries_ids?.map((item) => {
              return item?.value;
            }),
            employHistory: talentDetails?.worklife,
            qualificationdata: talentDetails?.qualificationdata,
            schooldata: talentDetails?.schooldata,
            associationdata: talentDetails?.associationdata,
            referencedata: talentDetails?.reference?.map((item) => {
              const contactInfo = getContactDetail(
                formatPhoneNumberIntl(
                  item?.contact?.startsWith("+")
                    ? item?.contact
                    : `${item?.country_code}${item?.contact}`
                )
              );
              return {
                ...item,
                contact: contactInfo?.[1],
                country_code: contactInfo?.[0],
              };
            }),
            relocate: 1,
            seeking_job: 1,
          };
          dispatch(updateTalentDetail(data)).then((payload) => {
            if (payload?.payload?.status === "success") {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.SUCCESS,
                  msg: i18n["alertMessage.Talentdataupdatedsuccessfully"],
                })
              );
              handleClose();
              setAllTalent([]);
              getJobList(0);
            } else {
              dispatch(
                setAlert({
                  show: true,
                  type: ALERT_TYPE?.ERROR,
                  msg: ERROR_MSG,
                })
              );
            }
          });
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: i18n["alertMessage.error"],
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handleInputChange = (event) => {
    if (event.target.id === "contact_no") {
      const newtalentDetails = {
        ...talentDetails,
        [event?.target?.id]: event?.target?.value,
      };
      setTalentDetails(newtalentDetails);
    } else if (
      event?.target?.value?.slice(0, 500)?.length <= 500 &&
      event?.target?.id === "my_bio"
    ) {
      const newtalentDetails = {
        ...talentDetails,
        [event?.target?.id]: event?.target?.value?.slice(0, 500),
      };
      setCharacterCount(event?.target?.value?.slice(0, 500)?.length);
      setTalentDetails(newtalentDetails);
    } else if (
      event?.target?.value?.length > 500 &&
      event?.target?.id === "my_bio"
    ) {
      setCount(event?.target?.value?.length);
    } else {
   
      const trimmedValue = event?.target?.value?.trim();
      const newtalentDetails = {
        ...talentDetails,
        [event?.target?.id]: trimmedValue,
      };
      setTalentDetails(newtalentDetails);
    }

    const filteredErrors = errors?.filter(
      (item) => item?.key !== event.target.id
    );
    setErrors(filteredErrors);
  };

  useEffect(() => {
    getTalentDetail();
  }, []);

  useEffect(() => {
    getInitialSalary(talentDetails);
  }, [currency]);

  useEffect(() => {
    getUserCountryFromGeolocation()
      .then((countryCode) => {
        setInitialCountryCode(countryCode);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error fetching country code",
          })
        );
      });
  }, []);

  return (
    !isLoading && (
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ height: "100%", overflow: "auto" }}>
            <EditTalentProfile
              talentDetails={talentDetails}
              cvLink={talentContent?.cv_link}
              errors={errors}
              handleInputChange={handleInputChange}
              setTalentDetails={setTalentDetails}
              setErrors={setErrors}
              setContactNumber={setContactNumber}
              contactNumber={contactNumber}
              language={language}
              nationality={nationality}
              characterCount={characterCount}
              count={count}
              countries={countries}
              town={town}
              getAllData={getAllData}
              setAllTalent={setAllTalent}
              allTalent={allTalent}
              pinTalentFlag={pinTalentFlag}
            />
            <EditBasicInfo
              talentDetails={talentDetails}
              setTalentDetails={setTalentDetails}
              setErrors={setErrors}
              getAllData={getAllData}
              errors={errors}
              setRangeValue={setRangeValue}
              rangeValue={rangeValue}
              industries={industries}
              initialIndustries={initialIndustries}
              skills={skills}
              initialSkills={initialSkills}
              tools={tools}
              initialTools={initialTools}
              setSalaryRange={setSalaryRange}
              salaryRange={salaryRange}
              setSingleSalaryValue={setSingleSalaryValue}
              singleSalaryValue={singleSalaryValue}
            />
            <EditWorkLife
              talentDetails={talentDetails}
              workLife={workLife}
              setWorkLife={setWorkLife}
              errors={errors}
              setErrors={setErrors}
              companies={candidateCompany}
              titles={titles}
              getAllData={getAllData}
              setTalentDetails={setTalentDetails}
            />
            <EditStudyLife
              errors={errors}
              setErrors={setErrors}
              talentDetails={talentDetails}
              studyLife={studyLife}
              setStudyLife={setStudyLife}
              setTalentDetails={setTalentDetails}
              institution={institution}
              qualification={qualification}
              school={school}
              association={association}
              typeOfQualification={typeOfQualification}
              getAllData={getAllData}
            />

            <EditReferences
              talentDetails={talentDetails}
              companies={candidateCompany}
              titles={titles}
              errors={errors}
              setErrors={setErrors}
              getAllData={getAllData}
              setTalentDetails={setTalentDetails}
              initialCountryCode={initialCountryCode}
            />
          </Box>
          <Box>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "50%",
                borderRadius: "0 0 0 10px",
                height: "60px",
                background: "lightgray",
                color: "black",
                padding: 3,
                ":hover": {
                  background: "lightgray",
                },
              }}
              variant="contained"
              onClick={handleClose}
            >
              cancel
            </Button>
            <Button
              sx={{
                boxShadow: 0,
                fontSize: "14px",
                width: "50%",
                borderRadius: "0 0 10px 0",
                height: "60px",
                padding: 3,
              }}
              variant="contained"
              color="redButton100"
              onClick={editTalentDetail}
            >
              edit
            </Button>
          </Box>
        </Box>
      </Modal>
    )
  );
}
