import { useEffect, useRef, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import { ALERT_TYPE } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { favouriteCompany } from "../../../redux/guest/talentSlice";
import {
  Button,
  Card,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";

import logo from "../../../assets/Crayon_Favicon.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import playButtonIcon from "../../../assets/Padding Excluded/Black_reactive_job copy.svg";
import favouriteGrey from "../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../assets/Crayon Favourite Icon_Red.svg";
import linkedUsers from "../../../assets/HomePage/Black_Linked_User_Databases.svg";
import redLocation from "../../../assets/Red_Location.svg";
import FollowIcon from "../../../assets/Padding Excluded/Black_Company Followers.svg";
import flip from "../../../assets/NewFlipIcon.svg";
import NewSlider from "../../common/NewSlider";

import SmallButton from "../../common/SmallButton";
import { convertDatetimeAgo } from "../../../utils/DateTime";
import { getCompanyJobs } from "../../../redux/guest/whoshiring";
import { Link } from "react-router-dom";
import {
  followCompany,
  unFollow,
} from "../../../redux/candidate/myProfileSlice";
import Slider2 from "../../common/Slider2";
import {
  getDecodedToken,
  getToken,
  setCookie,
  splitString,
} from "../../../utils/Common";
import TalentSVGButton from "../../common/TalentSVGButton";
import pinIcon from "../../../assets/Icons/pinIcon.svg";

export default function HiringCardBack({
  job,
  setisFlipped,
  isHorizontalFlipped,
  handleReviewVideoDialog,
  getCompanyHiringList,
  favouriteFilter,
  filterCompany,
  jobslength,
  currentPageJobsCount,
  indexNumber,
  page,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isStar, setIsStarSelected] = useState(
    job?.favouriteflag
      ? job?.favouriteflag
      : document.cookie
          ?.match(/\d+/g)
          ?.map((item) => parseInt(item, 10))
          ?.includes(job?.company_id)
  );
  const loaderRef = useRef(null);
  const [isCompanyFollowed, setIsCompanyFollowed] = useState(job?.followflag);
  const [lastKey, setLastKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [companyJobsCalled, setCompanyJobsCalled] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [companyJobs, setCompanyJobs] = useState([]);

  let decodedToken = getDecodedToken(getToken());

  const handleFavourite = (event, companyid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteCompany", companyid, 30);
  };
  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 3) {
      await dispatch(favouriteCompany({ company_id: job?.company_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as candidate to add this job as favourite",
        })
      );
    }
  };

  const followComp = async (compID, isCompanyFollowed) => {
    try {
      const data = {
        company_id: compID,
      };
      const { payload } = !isCompanyFollowed
        ? await dispatch(followCompany(data))
        : await dispatch(unFollow(data));
      if (
        payload?.data?.status === "success" ||
        payload?.status === "success"
      ) {
        !isCompanyFollowed
          ? setIsCompanyFollowed(true)
          : setIsCompanyFollowed(false);
        getCompanyHiringList();
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: !isCompanyFollowed
              ? "Company followed successfully"
              : "Company unfollowed successfully",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error?.message,
        })
      );
    }
  };

  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 174 + 90;
    } else if (screenWidth < 900) {
      // sm
      return 174 + 90;
    } else if (screenWidth < 1366) {
      // md
      return 174 + 90;
    } else if (screenWidth < 1920) {
      // lg
      return 174 + 90;
    } else if (screenWidth < 2559) {
      // xl
      return 174 + 100;
    } else {
      // xxl or greater
      return 174 + 100;
    }
  };

  useEffect(() => {
    isHorizontalFlipped &&
      companyJobs?.length === 0 &&
      getCompanyJobsList("", job?.company_id);
  }, [isHorizontalFlipped]);

  const getCompanyJobsList = async (lastKey, companyID) => {
    setLoading(true);
    let data = {
      lastKey: lastKey,
      company_id: companyID,
      job_status_id: filterCompany?.[0] !== 1111 ? filterCompany : 2,
      appliedJobs: favouriteFilter?.appliedJobs,
    };
    const { payload } = await dispatch(getCompanyJobs(data));
    if (payload?.status === "success") {
      let state = payload?.data?.length > 0 ? true : false;
      setHasMoreData(state);
      setLastKey(payload?.pageNumber + 1);
      setCompanyJobs((prevJobs) => [...prevJobs, ...payload?.data]);
      setLoading(false);
      setCompanyJobsCalled(true);
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          // entries[0].isIntersecting &&
          // hasMoreData
          companyJobs?.length >= 12 &&
          entries?.[0]?.isIntersecting &&
          hasMoreData
        ) {
          getCompanyJobsList(lastKey, job?.company_id);
        }
      },
      { threshold: 0.5 }
    ); // Adjust threshold as needed

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => observer.disconnect();
  }, [loaderRef, lastKey]);
  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
      className="cardboxdetail"
    >
      <Grid sx={{ padding: "15px 8px 0px 15px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "15px",
          }}
        >
          <Link
            to={`/jobs/company/${job?.company_id}`}
            target={"_blank"}
            style={{
              width: "50%",
              textDecoration: "none",
              color: theme.palette.black,
            }}
          >
            <Box
              component={"img"}
              src={job?.profile_url !== "No URL" ? job?.profile_url : logo}
              sx={{
                height: "60px",
                width: "60px",
                borderRadius: "10px",
              }}
            />
          </Link>
          <Box>
            <Button
              color={"precium"}
              variant="contained"
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                maxWidth: "70px",
                borderRadius: "0 0 0 10px",
                position: "absolute",
                right: 120,
                top: 0,
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                boxShadow: 0,
                cursor: "default",
                color: theme.jobCard.jobType.recruit.contrastText,
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              {job?.company_type ? job?.company_type : "-"}
            </Button>
            <Button
              color={"peachPayment"}
              variant="contained"
              sx={{
                height: "auto",
                minWidth: "60px",
                minHeight: "45px",
                borderRadius: "0 0 0 0px",
                position: "absolute",
                right: 60,
                top: 0,
                padding: 0,
                fontSize: 12,
                fontWeight: theme.typography.Bold,
                boxShadow: 0,
                cursor: "default",
                color: theme.jobCard.jobType.recruit.contrastText,
                ":hover": {
                  boxShadow: 0,
                },
              }}
            >
              {job?.company_size ? job?.company_size : "-"}
            </Button>
            <Button
              color="playButton"
              variant="contained"
              sx={{
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 10,
                height: "auto",
                minWidth: "60px !important",
                minHeight: "45px",
                borderRadius: "0px 25px 0px 0px",
                cursor: job?.video_url === "No URL" ? "default" : "pointer",
                background:
                  job?.video_url === "No URL" &&
                  theme.palette.grayButton600.main,
                padding: 0,
                ":hover": {
                  boxShadow: "none",
                },
              }}
              onClick={() =>
                job?.video_url === "No URL" ? "" : handleReviewVideoDialog(true)
              }
            >
              <Box
                component={"img"}
                src={
                  // job?.video_url === "No URL"
                  //   ? playButtonIcon
                  //   : playButtonIconWhite
                  playButtonIconWhite
                }
                sx={{
                  height: "16px",
                  width: "20px",
                }}
              />
            </Button>
          </Box>

          <Typography
            sx={{
              position: "absolute",
              top: "47px",
              right: "73px",
              fontSize: "12px",
              fontWeight: theme.typography.Regular,
              // paddingRight: "8px",
              color: theme.palette.grayButton600.main,
            }}
          >
            {`joined ${convertDatetimeAgo(job?.created_at)} ago`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            maxWidth: "100%",
          }}
        >
          {job?.pin_company && (
            // <TalentSVGButton
            //   color={"white"}
            //   source={pinIcon}
            //   outerHeight={20}
            //   height={20}
            //   width={20}
            //   padding={"0px !important"}
            //   startIconMargin={"0px !important"}
            // />
            <Box
              component="img"
              sx={{
                height: 20,
                width: 20,
                maxHeight: { xs: 20 },
                maxWidth: { xs: 20 },
                cursor: "pointer",
              }}
              alt="pinIcon"
              src={pinIcon}
            />
          )}
          <Tooltip disableInteractive title={job?.name} placement="top-start">
            <Link
              to={`/jobs/company/${job?.company_id}`}
              target={"_blank"}
              style={{
                maxWidth: `calc(100% - 20px)`,
                textDecoration: "none",
                color: theme.palette.black,
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  minHeight: "27px",
                  wordBreak: "break-word",
                }}
                noWrap={true}
              >
                {job?.name}
              </Typography>
            </Link>
          </Tooltip>
          {page === "footer" ? (
            ""
          ) : (
            <Box
              component={"img"}
              src={isStar ? favouriteRed : favouriteGrey}
              sx={{
                height: "20px",
                width: "20px",
              }}
              onClick={(event) =>
                decodedToken === undefined
                  ? handleFavourite(event, job?.company_id)
                  : handleStar(job?.company_id)
              }
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            marginBottom: "10px",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: 16,
              width: 16,
              maxHeight: { xs: 16 },
              maxWidth: { xs: 16 },
              marginLeft: "-6px !important",
            }}
            alt="location"
            src={redLocation}
          />
          <Typography
            sx={{ fontSize: "14px", fontWeight: theme.typography.Bold }}
          >
            {job?.town ? `${job?.town}, ` : "-"}
            {job?.country ? `${job?.country}` : "-"}
          </Typography>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            gap: "5px",
            marginBottom: "9px",
            minHeight: "25px",
          }}
        >
          {job?.company_type && (
            <SmallButton
              color={"companySizeType"}
              fontSize={12}
              height={25}
              value={job?.company_type}
              label={job?.company_type}
              cursor={"default"}
            />
          )}
          {job?.company_size && (
            <SmallButton
              color={"companySizeType"}
              fontSize={12}
              height={25}
              value={job?.company_size}
              label={job?.company_size}
              cursor={"default"}
            />
          )}
        </Box> */}
        <Box
          sx={{
            // display: "flex",
            // gap: "5px",
            // marginBottom: "15px",
            // minHeight: "25px",

            // display: "flex",
            // flexDirection: "column",
            // gap: "10px",
            // height: "25px",
            display: "flex",
            // gap: "5px",
            marginBottom: "10px",
            minHeight: "25px",
            flexDirection: "column",
            gap: "10px",
            height: "25px",
            marginRight: "15px",
          }}
        >
          {/* {job?.Industries &&
          job?.Industries?.split(",").map((item) => (
            <SmallButton
              color={"industriesButton"}
              fontSize={12}
              height={25}
              value={item}
              label={item}
            />
          ))} */}
          {/* <Slider2
          items={splitString(job?.Industries) || []}
          color={'industriesButton'}
          hideTagsAfter={3}
        /> */}
          {isHorizontalFlipped && (
            <NewSlider
              items={
                splitString(job?.Industries)?.sort(
                  (a, b) => a.length - b.length
                ) || []
              }
              color={"recordVideoButton"}
              hideTagsAfter={3}
              height="25px"
              job={job}
              index={indexNumber}
              jobslength={jobslength}
              currentPageJobsCount={currentPageJobsCount}
              isBackSide
              isHiringCard
              companyJobsCalled={companyJobsCalled}
              id={job?.company_id}
            />
          )}
        </Box>
        <Box
          sx={{
            // marginBottom: "15px",
            // display: "flex",
            // flexDirection: "column",
            // gap: "10px",
            // height: "25px",
            display: "flex",
            // gap: "5px",
            marginBottom: "10px",
            minHeight: "25px",
            flexDirection: "column",
            gap: "10px",
            height: "25px",
            marginRight: "15px",
          }}
        >
          <NewSlider
            items={
              splitString(job?.Company_Culture)?.sort(
                (a, b) => a.length - b.length
              ) || []
            }
            color={"precium"}
            hideTagsAfter={3}
            height="25px"
            job={job}
            index={indexNumber}
            jobslength={jobslength}
            currentPageJobsCount={currentPageJobsCount}
            id={job?.company_id}
          />
        </Box>
        <Box
          sx={{
            marginRight: "17px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            maxHeight: page === "footer" ? "244px" : "264px",
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "white",
              width: "5px",
              boxShadow: "0px 3px 3px #00000029",
              borderRadius: "3px",
              height: "20px",
            },
            "&::-webkit-scrollbar": {
              width: "5px",
              backgroundColor: "#EFEEEE",
              display:
                companyJobs?.length < 4
                  ? "none !important"
                  : "unset !important",
            },
          }}
          height={calculateHeight()}
          id="companyJobsList"
        >
          <Box>
            {companyJobs?.length > 0 ? (
              companyJobs?.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "9px",
                    marginBottom: "10px",
                  }}
                >
                  <Box
                    component={"img"}
                    src={
                      job?.profile_url !== "No URL" ? job?.profile_url : logo
                    }
                    sx={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "10px",
                    }}
                  />
                  <Tooltip
                    disableInteractive
                    title={item?.title}
                    placement="top-start"
                  >
                    <Link
                      to={
                        item?.post_incognito
                          ? `/jobs/${item?.town?.country?.name?.replace(
                              /\s|\//g,
                              ""
                            )}/${item?.town?.name?.replace(
                              /\s|\//g,
                              ""
                            )}/${item?.title?.replace(/\s|\//g, "")}/${
                              item?.job_id
                            }`
                          : `/jobs/${item?.town?.country?.name?.replace(
                              /\s|\//g,
                              ""
                            )}/${item?.town?.name?.replace(
                              /\s|\//g,
                              ""
                            )}/${job?.name?.replace(
                              /\s|\//g,
                              ""
                            )}/${item?.title?.replace(/\s|\//g, "")}/${
                              item?.job_id
                            }`
                      }
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.black,
                      }}
                    >
                      <Typography
                        sx={{
                          maxWidth: "194px",
                          fontSize: "14px",
                          fontWeight: theme.typography.Regular,
                          wordBreak: "break-word",
                        }}
                        noWrap={true}
                      >
                        {item?.title}
                      </Typography>
                    </Link>
                  </Tooltip>
                </Box>
              ))
            ) : (
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {!loading && <Typography>No jobs </Typography>}
              </Box>
            )}
            <div ref={loaderRef} style={{ textAlign: "center" }}>
              {loading && <CircularProgress color="inherit" size={20} />}
            </div>
          </Box>
        </Box>

        <Box sx={{ display: "flex", marginLeft: "-15px", marginRight: "-8px" }}>
          <Link
            to={`/jobs/company/${job?.company_id}`}
            target={"_blank"}
            style={{
              width: "50%",
              textDecoration: "none",
              color: theme.palette.black,
            }}
          >
            <Button
              variant="contained"
              color="viewJobs"
              sx={{
                borderRadius: "0 0 0 25px",
                fontSize: "16px",
                padding: "19px 31px 19px 49px",
                fontWeight: theme.typography.Bold,
                height: "60px",
                width: "100%",
                textWrap: "nowrap",
                //   "& .MuiButton-startIcon": {
                //     margin: "0px",
                //   },
              }}
              startIcon={
                <Box
                  component={"img"}
                  src={linkedUsers}
                  sx={{
                    height: "30px",
                    width: "28px",
                  }}
                />
              }
            >
              view jobs
            </Button>
          </Link>
          <Button
            variant="contained"
            color={isCompanyFollowed ? "yoco" : "nandos"}
            sx={{
              borderRadius: "0 0 25px 0px",
              fontSize: "16px",
              padding: "19px 31px 19px 49px",
              fontWeight: theme.typography.Bold,
              width: "50%",
              height: "60px",
              textWrap: "nowrap",
              //   "& .MuiButton-startIcon": {
              //     margin: "0px",
              //   },
            }}
            onClick={() => followComp(job?.company_id, isCompanyFollowed)}
            startIcon={
              <Box
                component={"img"}
                sx={{
                  height: 20,
                  width: 20,
                }}
                src={FollowIcon}
              />
            }
          >
            {isCompanyFollowed ? "following" : "follow"}
          </Button>
        </Box>
      </Grid>
      <Button
        variant="contained"
        // color="nandos"
        // color="#38A8AE"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>
    </Card>
  );
}
