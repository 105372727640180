import { useState } from "react";
import { useTheme } from "@mui/material/styles";
import profile from "../../../../assets/user_logo.svg";
import flip from "../../../../assets/NewFlipIcon.svg";
import job_exp from "../../../../assets/Padding Included/Green_Duration.svg";
import BlueCurrency from "../../../../assets/Blue_Salary.svg";
import redLocation from "../../../../assets/Red_Location.svg";
import calendar from "../../../../assets/Padding Included/Yellow_Calendar.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SmallButton from "../../../common/SmallButton";
import TextWrapper from "../../../common/TextWrapper";
import { convertDatetimeAgo } from "../../../../utils/DateTime";
import { useDispatch } from "react-redux";
import { favouriteJob } from "../../../../redux/guest/talentSlice";
import { Link } from "react-router-dom";
import { formatCurrencyWithCommas } from "../../../../utils/Currency";

import matchMeIcon from "../../../../assets/Padding Excluded/Black_Match_me.svg";
import viewCVIcon from "../../../../assets/Padding Excluded/Black_Lock.svg";
import viewCVIconUnlock from "../../../../assets/Padding Excluded/Black_Unlock.svg";
import applyIcon from "../../../../assets/Padding Excluded/Black_Follower.svg";
import playButtonIconWhite from "../../../../assets/Padding Excluded/Black_reactive_job.svg";

import favouriteGrey from "../../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../../assets/Crayon Favourite Icon_Red.svg";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE } from "../../../../utils/Constants";
import locale from "../../../../i18n/locale";
import { truncate } from "lodash";

import MUIRadialChart from "../../../common/MUIRadialChart";
import { Card } from "@mui/material";
import TalentMatch from "../TalentMatch";
import RunMatch from "../RunMatch";
import ShortlistBox from "../ShortlistBox";
import {
  calculateMUIHeight,
  getDecodedToken,
  getToken,
  setCookie,
  splitString,
} from "../../../../utils/Common";
import SubscriptionPopModal from "../SubscriptionPopModal";
import * as Flag from "country-flag-icons/react/3x2";
import TalentSVGButton from "../../../common/TalentSVGButton";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import JobsSlider from "../../jobs/JobsSlider";

export default function TalentCard({
  index,
  job,
  selectedJobID,
  setSelectedJobID,
  setisFlipped,
  handleReviewVideoDialog,
  indexNumber,
  jobslength,
  currentPageJobsCount,
  setUnlockCV,
  setUnlockVideo,
  setNoCoins,
  setSelected,
  setGetCoins,
  setOpenNotLoggedIn,
  setOpenNotLoggedInMatched,
  setSubHeadingModal,
}) {
  const i18n = locale.en;
  const label1 = "applications";
  const label2 = "shortlisting";
  const label3 = "interviews";
  const dispatch = useDispatch();
  const theme = useTheme();
  const CountryFlag = Flag[job?.country_code ? job?.country_code : ""];

  const [isStar, setIsStarSelected] = useState(
    job?.favourite_talent
      ? job?.favourite_talent
      : document.cookie
          ?.match(/\d+/g)
          ?.map((item) => parseInt(item, 10))
          ?.includes(job?.user_id)
  );
  const [matchMeButton, setMatchButton] = useState(false);
  const [runMatch, setRunMatch] = useState(false);
  const [shortlist, setShortlist] = useState(false);
  const [selectedJob, setSelectedJob] = useState([]);
  const jobIndustries = splitString(job?.UserIndustries);
  const [isSubscriptionModal, setIsSubscriptionModal] = useState(false);
  let decodedToken = getDecodedToken(getToken());

  const handleFavourite = (event, talentid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteTalent", talentid, 30);
  };

  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 4) {
      await dispatch(favouriteJob({ reqid: job?.user_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as employer to add this talent as favourite",
        })
      );
    }
  };

  const handleViewCV = () => {
    window.open(`/candidate-cv/${job?.user_id}`, { target: "_blank" });
  };

  const handleMatchMeButton = () => {
    if (decodedToken === undefined || decodedToken?.data?.role_id === 3) {
      setSubHeadingModal(
        "talent can only be matched against your current active roles"
      );
      setOpenNotLoggedInMatched(true);
    } else {
      setMatchButton((prevState) => !prevState);
    }
  };

  const handleShortlistButton = () => {
    if (decodedToken === undefined || decodedToken?.data?.role_id === 3) {
      setSubHeadingModal(
        "shortlisting can only be done against your current active roles"
      );
      setOpenNotLoggedInMatched(true);
    } else {
      setShortlist(true);
    }
  };

  const handleRunMatchButton = () => {
    if (decodedToken === undefined) {
    } else {
      setRunMatch((prevState) => !prevState);
      setMatchButton((prevState) => !prevState);
    }
  };
  const handleShortlist = () => {
    if (decodedToken === undefined) {
    } else {
      setRunMatch(false);
      setMatchButton(false);
      setShortlist(false);
    }
  };
  const handleTitle = () => {
    if (decodedToken) {
      setSelected(job);
      setGetCoins("unlockcv");
    } else if (!decodedToken) {
      setOpenNotLoggedIn(true);
    }
  };
  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
    >
      {!matchMeButton && !runMatch && !shortlist && (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            sx={{
              borderRadius: "25px 25px 0 0",
              flexWrap: "nowrap",
            }}
          >
            {job?.profile_url !== "NO URL" ? (
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  ml: "15px",
                  mt: "15px",
                  borderRadius: "10px",
                }}
                alt="profile"
                src={job?.profile_url}
              />
            ) : (
              <Box
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  ml: "15px",
                  mt: "15px",
                  borderRadius: "10px",
                }}
                alt="profile"
                src={profile}
              />
            )}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <Button
                    color={
                      job?.firstactivity === "recent"
                        ? "recentButton"
                        : "activeButtonNew"
                    }
                    variant="contained"
                    sx={{
                      height: "auto",
                      minWidth: "60px",
                      minHeight: "45px",
                      borderRadius: "0 0 0 10px",
                      padding: 0,
                      fontSize: 12,
                      fontWeight: theme.typography.Bold,
                      boxShadow: 0,
                      cursor: "default",
                      color: theme.jobCard.jobType.recruit.contrastText,
                      ":hover": {
                        boxShadow: 0,
                      },
                    }}
                  >
                    {job?.firstactivity || "-"}
                  </Button>

                  {job?.secondactivity && (
                    <Button
                      variant="contained"
                      color="inDemandButtonNew"
                      sx={{
                        height: "auto",
                        minWidth: "60px",
                        minHeight: "45px",
                        borderRadius: 0,
                        padding: 0,
                        fontSize: 12,
                        fontWeight: theme.typography.Bold,
                        cursor: "default",
                        boxShadow: 0,
                        ":hover": {
                          boxShadow: 0,
                        },
                      }}
                    >
                      {job?.secondactivity || "-"}
                    </Button>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Button
                    color="playButton"
                    variant="contained"
                    onClick={() => {
                      if (
                        (job?.is_videoUnlock && decodedToken) ||
                        (decodedToken?.data?.talent_plan &&
                          job?.cam_link !== null)
                      ) {
                        handleReviewVideoDialog(true);
                      } else if (job?.cam_link !== null && decodedToken) {
                        setSelected(job);
                        setGetCoins("unlockvideo");
                      } else if (!decodedToken) {
                        setOpenNotLoggedIn(true);
                      }
                    }}
                    sx={{
                      minWidth: "60px",
                      width: "60px",
                      height: 45,
                      background:
                        job?.cam_link === null &&
                        theme.palette.grayButton600.main,
                      borderRadius: "0 25px 0 0",
                      padding: 0,
                      ":hover": {
                        boxShadow: "none",
                      },
                      cursor: job?.cam_link !== null ? "pointer" : "default",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={playButtonIconWhite}
                      sx={{
                        height: "14px",
                        width: "14px",
                      }}
                    />
                  </Button>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  fontWeight: theme.typography.Regular,
                  letterSpacing: "0.75px",
                  height: "16px",
                  paddingTop: "3px",
                  color: theme.palette.grayButton600.main,
                }}
              >
                joined {convertDatetimeAgo(job?.user_created_at)} ago
              </Typography>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              m: "15px 20px 15px 15px",
            }}
          >
            {job?.is_cvUnlock || decodedToken?.data?.talent_plan ? (
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  width: "100%",
                  cursor: "default",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
                noWrap
              >
                {job?.pin_talent && (
                  <TalentSVGButton
                    color={"white"}
                    source={pinIcon}
                    outerHeight={20}
                    height={20}
                    width={20}
                    padding={"0px !important"}
                    startIconMargin={"0px !important"}
                  />
                )}
                <Link
                  to={`/candidate-cv/${job?.user_id}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: theme.palette.black200.main,
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    cursor: "pointer",
                    maxWidth: `calc(100% - 95px)`,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {job?.first_name}
                </Link>
                {job?.country_code && (
                  <CountryFlag
                    title="United States"
                    className="..."
                    style={{ width: 22, height: 20 }}
                  />
                )}
                <Box
                  component={"img"}
                  src={isStar ? favouriteRed : favouriteGrey}
                  sx={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    decodedToken === undefined
                      ? handleFavourite(event, job?.user_id)
                      : handleStar(job?.user_id)
                  }
                />
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: theme.typography.Bold,
                  width: "100%",
                  cursor: "default",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
                onClick={handleTitle}
                noWrap
              >
                {job?.pin_talent && (
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      maxHeight: { xs: 20 },
                      maxWidth: { xs: 20 },
                      cursor: "pointer",
                    }}
                    alt="pinIcon"
                    src={pinIcon}
                  />
                )}
                <span
                  style={{
                    fontSize: "20px",
                    fontWeight: theme.typography.Bold,
                    cursor: "pointer",
                    maxWidth: job?.pin_talent
                      ? `calc(100% - 95px)`
                      : `calc(100% - 65px)`,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  {job?.first_name}
                </span>
                {job?.country_code && (
                  <CountryFlag
                    title="United States"
                    className="..."
                    style={{ width: 22, height: 20 }}
                  />
                )}
                <Box
                  component={"img"}
                  src={isStar ? favouriteRed : favouriteGrey}
                  sx={{
                    height: "20px",
                    width: "20px",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    decodedToken === undefined
                      ? handleFavourite(event, job?.user_id)
                      : handleStar(job?.user_id)
                  }
                />
              </Typography>
            )}
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: theme.typography.Bold,
                width: "100%",
              }}
              noWrap
            >
              {job?.jobTitle}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="currency"
                  src={BlueCurrency}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.salaryMin === 0 ? (
                    <>
                      {job?.currencySymbol}
                      {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                      {job?.employment_type === "freelance"
                        ? "per hour"
                        : "per month"}
                    </>
                  ) : job?.currencySymbol ||
                    job?.salaryMin ||
                    job?.salaryMax ? (
                    <>
                      {job?.currencySymbol}
                      {formatCurrencyWithCommas(job?.salaryMin)} to{" "}
                      {job?.currencySymbol}
                      {formatCurrencyWithCommas(job?.salaryMax)}{" "}
                      {job?.employment_type === "freelance"
                        ? "per hour"
                        : "per month"}
                    </>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="location"
                  src={redLocation}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.town_name || "-"}
                  {job?.country_name && `, ${job?.country_name} `}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 15,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 15 },
                  }}
                  alt="job_exp"
                  src={job_exp}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.experienceYear ? `${job?.experienceYear} years` : "-"}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "10px",
                  height: "14px",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: 16,
                    width: 14,
                    maxHeight: { xs: 16 },
                    maxWidth: { xs: 14 },
                  }}
                  alt="calendar"
                  src={calendar}
                />
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 14,
                    letterSpacing: "0.25px",
                    lineHeight: 1,
                  }}
                >
                  {job?.noticePeriod_description
                    ? job?.noticePeriod_description?.replace("calendar", "")
                    : "-"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "calc(100% - 20px)",
            }}
          >
            <Grid
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
              padding={"0 0 0 15px "}
              gap={"15px"}
              minHeight="135px"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  height: "60px",
                }}
              >
                <Box>
                  {job?.employment_type && (
                    <SmallButton
                      fontSize={12}
                      color={"nandos"}
                      height={25}
                      value={job?.type}
                      label={truncate(job?.employment_type, { length: 12 })}
                      mr="4px"
                      cursor="default"
                    />
                  )}
                  {job?.work_setup && (
                    <SmallButton
                      fontSize={12}
                      color={"nandos"}
                      height={25}
                      value={job?.work_setup}
                      label={truncate(job?.work_setup, { length: 12 })}
                      mr="4px"
                      cursor="default"
                    />
                  )}
                </Box>
                <JobsSlider
                  items={
                    splitString(job?.UserIndustries)?.sort(
                      (a, b) => a?.length - b?.length
                    ) || []
                  }
                  color={"tymeColor"}
                  hideTagsAfter={3}
                  height="25px"
                  job={job}
                  index={indexNumber}
                  jobslength={jobslength}
                  id={job?.user_id}
                  currentPageJobsCount={currentPageJobsCount}
                />
              </Box>

              <TextWrapper
                size={12}
                color={theme.palette.black100}
                letterSpacing="0.25px"
                height="56px"
                marginBottom={"0px !important"}
              >
                {job?.my_bio}
              </TextWrapper>
            </Grid>
            <Box sx={{ display: "flex" }}></Box>
          </Box>

          <Grid
            container
            padding="15px"
            justifyContent="space-around"
            flexWrap={"nowrap"}
          >
            <MUIRadialChart
              value={job?.TotalUserCount}
              chartName={label1}
              max={500}
              size={calculateMUIHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"activeJobNew"}
            />
            <MUIRadialChart
              value={job?.totalusershorlisted}
              chartName={label2}
              max={100}
              size={calculateMUIHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"ShortlistedNew"}
            />
            <MUIRadialChart
              value={job?.totaluserinterviewed}
              chartName={label3}
              max={50}
              size={calculateMUIHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"InterviewedNew"}
            />
          </Grid>
          <Grid
            container
            flexWrap={"nowrap"}
            alignItems="center"
            overflow={"hidden"}
            sx={{
              width: "100%",
              borderRadius: "0 0 25px 25px",
              height: 60,
            }}
          >
            <Button
              variant="contained"
              onClick={handleMatchMeButton}
              sx={{
                borderRadius: 0,
                width: "32.5%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                boxShadow: "none !important",
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                gap: 1,
              }}
              color="matchButton"
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "26px",
                  }}
                  src={matchMeIcon}
                />
              }
            >
              match
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                fontSize: "14px",
                fontWeight: "bold",
                whiteSpace: "nowrap",
                width: "35%",
                height: "100%",
                padding: "0px !important",
                boxShadow: "none !important",
              }}
              color="tymeColor"
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: 20,
                    width: 20,
                  }}
                  src={
                    job?.is_cvUnlock || decodedToken?.data?.talent_plan
                      ? viewCVIconUnlock
                      : viewCVIcon
                  }
                />
              }
              onClick={() => {
                if (job?.is_cvUnlock || decodedToken?.data?.talent_plan) {
                  handleViewCV();
                } else if (decodedToken) {
                  setSelected(job);
                  setGetCoins("unlockcv");
                } else if (!decodedToken) {
                  setOpenNotLoggedIn(true);
                }
              }}
            >
              view CV
            </Button>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33.5%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                boxShadow: "none !important",
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                gap: 1,
              }}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                  src={applyIcon}
                />
              }
              color="nandos"
              onClick={handleShortlistButton}
            >
              {i18n["talentCard.shortlist"]}
            </Button>
          </Grid>
          <Button
            variant="contained"
            // color="nandos"
            // color="#38A8AE"
            color="newFlipColor"
            sx={{
              width: "20px",
              height: "100px",
              padding: 0,
              position: "absolute",
              right: 0,
              top: `calc(50% - 50px)`,
              minWidth: "20px",
              fontSize: "20px",
              borderRadius: "10px 0 0 10px",
            }}
            onClick={() => setisFlipped(true)}
          >
            <Box
              component={"img"}
              src={flip}
              sx={{
                height: "20px",
                width: "15px",
              }}
            />
          </Button>
        </>
      )}
      {matchMeButton && !runMatch && !shortlist && (
        <TalentMatch
          job={job}
          closeFunc={handleMatchMeButton}
          setRunMatch={setRunMatch}
          setSelectedJob={setSelectedJob}
          setSelectedJobID={setSelectedJobID}
          selectedJobID={selectedJobID}
        />
      )}
      {runMatch && (
        <RunMatch
          job={job}
          closeFunc={handleRunMatchButton}
          setMatchButton={setMatchButton}
          setRunMatch={setRunMatch}
          setShortlist={setShortlist}
          selectedJobID={selectedJobID}
        />
      )}
      {shortlist && (
        <ShortlistBox
          job={job}
          closeFunc={handleShortlist}
          setMatchButton={setMatchButton}
          setRunMatch={setRunMatch}
          setShortlist={setShortlist}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          handleMatchMeButton={handleMatchMeButton}
          selectedJobID={selectedJobID}
          setSelectedJobID={setSelectedJobID}
        />
      )}
      {
        <SubscriptionPopModal
          handleOpen={isSubscriptionModal}
          handleClose={() => setIsSubscriptionModal(false)}
          handleSubscriptionOk={() => setIsSubscriptionModal(false)}
        />
      }
    </Card>
  );
}
