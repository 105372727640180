import React from "react";
import { Box, Button, Typography, useTheme } from "@mui/material";
import blueHalfSmile from "../../../../assets/Characters/Yellow_Star.svg";
import crayonMagician from "../../../../assets/Characters/Crayon_Magician.svg";
import CustomDialog from "../../../common/CustomDialog";
import locale from "../../../../i18n/locale";

export default function PostJobPopUp({
  show,
  handleClose,
  setPostJobButton,
  handlePostJob,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  return (
    <CustomDialog
      show={show}
      hideButton={false}
      dialogWidth="xs"
      showFooter={false}
      onDialogClose={handleClose}
      padding={0}
    >
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // gap: 3,
        }}
      >
        <Box
          component={"img"}
          src={crayonMagician}
          sx={{ width: 170, height: 100, paddingTop: 3, margin: "auto" }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            paddingX: 3,
            width: "90%",
            marginBottom: "15px",
          }}
        >
          {i18n["postAJob.letthemagicbegin"]}
        </Typography>
        <Box
          sx={{
            mb: "25px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: theme.typography.Bold,
              paddingX: 3,
              width: "70%",
              marginBottom: "15px",
            }}
          >
            {i18n["postAJob.yourjobhasbeenposted"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.REgular,
              paddingX: 3,
              width: "65%",
            }}
          >
            The Crayon team will review and activate it shortly. If any of the
            details require clarification, we’ll be in touch.
          </Typography>
          {/* <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Bold,
              paddingX: 3,
              width: "250px",
            }}
          >
            {i18n["postAJob.activateIt"]}
          </Typography> */}
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          color="submitButton"
          sx={{
            borderRadius: "0 0 0 25px",
            width: "50%",
            height: "57px",
            color: "black",
          }}
          onClick={() => {
            handlePostJob("preview");
          }}
        >
          preview job
        </Button>
        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "50%",
            height: "57px",
          }}
          color="talentButton"
          onClick={() => {
            handlePostJob("jobs");
          }}
        >
         unlock now
        </Button>
      </Box>
    </CustomDialog>
  );
}
