import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import companyLogo from "../../../assets/user_logo.svg";
import flip from "../../../assets/NewFlipIcon.svg";
import playButtonIconWhite from "../../../assets/Padding Excluded/Black_reactive_job.svg";
import moreDownArrow from "../../../assets/Black_Down_Open - Copy.svg";
import moreUpArrow from "../../../assets/Black_Up_Close - Copy.svg";
import Slider2 from "../Slider2";

export default function CompanyInfoBackCard({
  companyDetail,
  setisHorizontalFlipped,
  setVideoLink,
  setOpenReviewVideoDialog,
}) {
  const theme = useTheme();
  const culture = companyDetail?.employer_cultures?.map(
    (item) => item?.company_culture?.name
  );
  const typographyRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [viewMoreExpanded, setViewMoreExpanded] = useState(false);

  const handleViewMore = () => {
    setViewMoreExpanded((prevState) => !prevState);
  };

  useEffect(() => {
    const typographyElement = typographyRef?.current;
    if (typographyElement) {
      const fullHeight = typographyElement?.scrollHeight;
      const lineHeight = 20;
      const clampedHeight = lineHeight * 4;
      if (fullHeight > clampedHeight) {
        setIsOverflowing(true);
      }
    }
  }, [companyDetail?.description]);

  return (
    <>
      <Box
        sx={{
          height: { xs: "406px", tablet: "225px", lg: "280px", mlg: "225px" },
          background: "#ffff",
          boxShadow: "0px 5px 10px #00000029",
          borderRadius: "25px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            height: "100%",
            flexDirection:{xs:"column",tablet:"row"},
            alignItems:{xs:"baseline",tablet:"center"},
            paddingTop:{xs:"45px",tablet:"0px"}
          }}
        >
          <Box
            component={"img"}
            src={
              companyDetail?.companyURL !== "NO URL"
                ? companyDetail?.companyURL
                : companyLogo
            }
            sx={{
              //   height: { xs: "96px", xl: "96px" },
              //   width: { xs: "96px", xl: "96px" },
              //   minWidth: { xs: "96px", xl: "96px" },
              height: { xs: "175px", tablet: "175px" },
              width: { xs: "175px", tablet: "175px" },
              borderRadius: "10px",
              margin: "24px 14px 25px 21px",
              marginTop:{xs:"15px",tablet:"24px"},
              marginBottom:{xs:"0px",tablet:"25px",mlg:"25px"},
              marginLeft:{xs:"10px",xs390:"21px"}
            }}
          />
          <Box
            sx={{
              width: { xs: `90%`,lg:"56%", lg1440: `calc(100% - 44px)` },
              boxSizing: "border-box",
              marginTop: { xs: "5px", tablet: "15px" },
            }}
          >
            <Box sx={{paddingLeft:"5px"}}>
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
            >
              About
            </Typography>
            <Typography
              ref={typographyRef}
              sx={{
                fontSize: theme.typography.fontSize.xs,
                fontWeight: theme.typography.Regular,
                overflow: viewMoreExpanded ? "scroll" : "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
                width: `calc(100% - 44px)`,
                height: "auto",
                lineHeight: "20px",
              }}
            >
            {companyDetail?.description}
            </Typography>
            {isOverflowing && (
              <Box sx={{ width: `calc(100% - 44px)` }}>
                <Button
                  sx={{
                    color: "black",
                    padding: 0,
                    fontSize: "12px !important",
                    fontWeight: theme.typography.Bold,
                    ".MuiButton-startIcon": {
                      marginRight: "0px !important",
                      marginLeft: "-18px !important",
                    },
                    height: {xs:"15px",tablet:"30px"},
                  }}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: 30,
                        width: 30,
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={viewMoreExpanded ? moreUpArrow : moreDownArrow}
                    />
                  }
                  onClick={handleViewMore}
                >
                  {viewMoreExpanded ? "less" : "more"}
                </Button>
              </Box>
            )}
            </Box>
            <Box sx={{ width: "100%", gap: "5px", display: "flex" }}>
            {culture?.length > 0 && (
                <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  // marginBottom: "20px",
                  marginTop:{xs:"0px", lg:"10px"},
                  width: {xs:"320px", xs390: "340px", md24: "420px", lg:"240px",lgm:"440px" },
                }}
                >
                  
                  <Slider2
                    items={culture || []}
                    color={"precium"}
                    hideTagsAfter={2}
                    slidertype={"company"}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            color="precium"
            variant="contained"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: "0 0 0 10px",
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            {companyDetail?.company_type ? companyDetail?.company_type : "-"}
          </Button>
          <Button
            color="peachPayment"
            variant="contained"
            sx={{
              fontSize: theme.typography.fontSize.xs,
              fontWeight: theme.typography.Bold,
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              borderRadius: 0,
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
          {companyDetail?.company_size ? companyDetail?.company_size : "-"}
          </Button>
          <Button
            color="playButton"
            variant="contained"
            onClick={() => {
              if (companyDetail?.companyVideoURL !== "NO URL"|| !companyDetail?.video_link) {
                setVideoLink(companyDetail?.companyVideoURL|| !companyDetail?.video_link);
                setOpenReviewVideoDialog(true);
              }
            }}
            sx={{
              height: "auto",
              minWidth: "60px",
              minHeight: "45px",
              background:
                companyDetail?.companyVideoURL === "NO URL" &&  !companyDetail?.video_link  && theme.palette.grayButton600.main,
              borderRadius: 0,
              borderTopRightRadius: "25px",
              cursor: (companyDetail?.companyVideoURL === "NO URL"  &&  !companyDetail?.video_link)? "default" : "pointer",
              padding: 0,
              ":hover": {
                boxShadow: "none",
              },
            }}
          >
            <Box
              component={"img"}
              src={
                // profile?.cam_url !== "NO URL"
                //   ? playButtonIconWhite
                //   : playButtonIcon
                playButtonIconWhite
              }
              sx={{
                height: "16px",
                width: "20px",
              }}
            />
          </Button>
        </Box>
      </Box>
      <Button
        variant="contained"
        // color="redButton100"
        color="newFlipColor"
        sx={{
          width: "20px",
          height: "100px",
          padding: 0,
          position: "absolute",
          right: 0,
          top: `calc(50% - 50px)`,
          minWidth: "20px",
          fontSize: "20px",
          borderRadius: "10px 0 0 10px",
        }}
        onClick={() => setisHorizontalFlipped(false)}
      >
        <Box
          component={"img"}
          src={flip}
          sx={{
            height: "20px",
            width: "15px",
          }}
        />
      </Button>

    </>
  );
}
