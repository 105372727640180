import { useCallback, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import AllTalentCard from "../../common/TalentsCard";
import { ALERT_TYPE, ERROR_MSG, USER_ROLES } from "../../../utils/Constants";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getpooldetails, getTalentsData } from "../../../redux/admin/jobsSlice";
import { setAlert } from "../../../redux/configSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button, InputBase, Paper, Popover } from "@mui/material";
import IconSection from "./IconSection";
import SmallButton from "../../common/SmallButton";
import { useRef } from "react";
import LinkedToJob from "./DialogBox/LinkedToJob";
import AddFreshTalent from "./DialogBox/AddFreshTalent";
import CompanyLogo from "../../../assets/Crayon_Favicon.svg";
import { debounce, getDecodedToken, getToken } from "../../../utils/Common";
import JobsLinked from "./DialogBox/JobsLinked";
import AddToJob from "./DialogBox/AddToJob";
import SubscriptionPopModal from "../../guest/talent/SubscriptionPopModal";

export default function TalentPoolInfo() {
  const theme = useTheme();
  const decodedToken = getDecodedToken(getToken());
  const navigate = useNavigate();
  const { id } = useParams();
  const par = useLocation();
  const [allJobs, setAllJobs] = useState([]);
  const dispatch = useDispatch();
  const [lastKey, setLastKey] = useState(0);
  const { poolId } = useParams();
  const [linkJobButton, setLinkJobButton] = useState(false);
  const linkedJob = useRef(null);
  const [anchorElLinkedJob, setAnchorElLinkedJob] = useState(null);
  const [anchorElJobLinked, setAnchorElJobLinked] = useState(null);
  const [anchorElFreshTalent, setAnchorElFreshTalent] = useState(null);
  const [totalPoolUsers, setTotalPoolUsers] = useState(0);
  const [selectedJob, setSelectedJob] = useState([]);
  const [poolInfo, setPoolInfo] = useState();
  const [linkedJobCount, setLinkedJobCount] = useState();
  const [allTalents, setAllTalent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [isShowWarningModal, setIsShowWarningModal] = useState(false);

  const [shortlistedCandidate, setShortlistedCandidate] = useState(0);
  //   decodedToken?.data?.role_id ===
  //   USER_ROLES?.[6]?.role_id &&
  // row?.owner === false
  //   ? true
  //   : false
  const getJobList = async (lastkeyy, query, linkJobList) => {
    const data = {
      linkjobid: linkJobList,
    };
    const { payload } = await dispatch(
      getTalentsData({
        payload: data,
        lastKey: lastkeyy,
        query: query,
        pool_id: linkJobList?.length > 0 ? "" : poolId,
        candidateID: id ? id : "",
      })
    );

    if (payload?.status === "success") {
      setShortlistedCandidate(payload?.talentCount);
      if (payload?.data?.length <= 0 && payload?.pageNumber === 0) {
        setAllJobs([]);
      } else {
        if (payload?.pageNumber === 0) {
          setAllJobs(
            payload?.data?.map((item) => ({
              currencyname: item?.currencyname,
              candidate_user_id: item?.candidate_user_id,
              first_name: item?.first_name,
              last_name: item?.last_name,
              job_title: item?.jobTitle,
              dob: item?.dob,
              town_name: item?.town_name,
              country_name: item?.country_name,
              currency_symbol: item?.currencySymbol,
              salary_min: item?.salaryMin,
              salary_max: item?.salaryMax,
              experience: item?.experienceYearEnd,
              notice_period: item?.noticePeriod_description,
              email: item?.email,
              total_user_count: item?.TotalUserCount,
              total_user_shortlist: item?.totalusershorlisted,
              total_user_interview: item?.totaluserinterviewed,
              employement_type: item?.employment_type,
              work_setup: item?.work_setup,
              created_at: item?.created_at,
              maxInterviewStageName: item?.maxInterviewStageName,
              gender: item?.gender,
              ...item,
            }))
          );
          setLastKey(payload?.pageNumber + 1);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setAllJobs((prevState) => [
            ...prevState,
            ...payload?.data?.map((item) => ({
              currencyname: item?.currencyname,
              candidate_user_id: item?.candidate_user_id,
              first_name: item?.first_name,
              last_name: item?.last_name,
              job_title: item?.jobTitle,
              dob: item?.dob,
              town_name: item?.town_name,
              country_name: item?.country_name,
              currency_symbol: item?.currencySymbol,
              salary_min: item?.salaryMin,
              salary_max: item?.salaryMax,
              experience: item?.experienceYearEnd,
              notice_period: item?.noticePeriod_description,
              email: item?.email,
              total_user_count: item?.TotalUserCount,
              total_user_shortlist: item?.totalusershorlisted,
              total_user_interview: item?.totaluserinterviewed,
              employement_type: item?.employment_type,
              work_setup: item?.work_setup,
              created_at: item?.created_at,
              maxInterviewStageName: item?.maxInterviewStageName,
              gender: item?.gender,
              ...item,
            })),
          ]);
        }
        // setShowText(false);
      }
    } else if (payload?.status === "error") {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const getPoolDetails = async () => {
    const { payload } = await dispatch(getpooldetails({ poolId }));
    if (payload?.status === "success") {
      setPoolInfo(payload?.data);
      setLinkedJobCount(payload?.linkjobcount);
      setTotalPoolUsers(payload?.poolUserCount);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  const handleFreshTalent = () => {
    // setLinkJobButton((prevState) => !prevState);
    // setAnchorElFreshTalent(linkedJob.current);
    navigate("/admin/search/build-search", {
      state: { talentpool_id: poolId, talentName: poolInfo?.title },
    });
  };

  const handleCloseFreshTalent = () => {
    setLinkJobButton((prevState) => !prevState);
    setAnchorElFreshTalent(null);
  };

  const handleLinkedToJob = () => {
    setAllJobs([]);
    setShortlistedCandidate(0);
    setLinkJobButton((prevState) => !prevState);
    setAnchorElLinkedJob(linkedJob?.current);
  };

  const handleCloseLinkedToJob = async () => {
    setSelectedJob([]);
    setLinkJobButton((prevState) => !prevState);
    setAnchorElLinkedJob(null);
    await getJobList(0, "", []);
  };
  const handleCancel = () => {
    setAnchorElLinkedJob(null);
  };
  const handleLinkedJob = () => {
    setShortlistedCandidate(0);
    setLinkJobButton((prevState) => !prevState);
    setAnchorElJobLinked(linkedJob?.current);
  };

  const handleCloseLinkedJob = () => {
    setLinkJobButton((prevState) => !prevState);
    setAnchorElJobLinked(null);
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          await getJobList(0, newValue?.target?.value, []);
          setSearchTitle(newValue?.target?.value);
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      1000, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setAllTalent, setIsLoading]
  );

  const handleInputSearch = async (event) => {
    debouncedHandleOnTextSearch(event);
  };

  useEffect(() => {
    getJobList(0, "", []);
    setAllJobs([]);
    getPoolDetails();
  }, [poolId]);

  return (
    <Box>
      <Typography
        sx={{
          fontSize: "36px",
          fontWeight: 700,
          textAlign: "center",
        }}
      >
        {par?.pathname
          ?.split("/")
          ?.filter((item) => item !== "")
          ?.includes("client-pool")
          ? "Client Pools"
          : "Talents Pools"}
      </Typography>

      <Box
        flexDirection={"column"}
        sx={{
          display: { xs: "none", md: "flex" },
          marginTop: "30px",
          background: "white",
          borderRadius: "25px",
          padding: "0px 0px 0px 30px",
          height: "240px",
        }}
        boxShadow={1}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Box sx={{ width: 160 }}></Box>
          <Box
            marginTop={"26px"}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconSection />
            {linkJobButton && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 1,
                  paddingY: 1,
                }}
              >
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: theme?.typography?.Bold,
                    textAlign: "center",
                  }}
                >
                  View or add talent and a job to your pool.
                </Typography>
                <SmallButton
                  color="userID"
                  label={`${poolInfo?.title} (${totalPoolUsers})`}
                  paddingX={2}
                  fontSize={12}
                  fontWeight={700}
                  textColor="#000"
                  borderRadius="6px"
                  width="fit-content"
                ></SmallButton>
                <SmallButton
                  color="userID"
                  label={`Selected shortlisted candidate (${shortlistedCandidate})`}
                  paddingX={2}
                  fontSize={12}
                  fontWeight={700}
                  textColor="#000"
                  borderRadius="6px"
                  width="fit-content"
                ></SmallButton>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              ref={linkedJob}
              variant="contained"
              color="adminCheckColor"
              // disabled={ decodedToken?.data?.role_id ===
              //   USER_ROLES?.[6]?.role_id &&
              // poolInfo?.owner === false
              //   ? true
              //   : false}
              sx={{
                borderRadius: "0 25px 0 0",
                justifyContent: "start",
              }}
              onClick={() => {
                if (
                  !(
                    decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id &&
                    poolInfo?.owner === false
                  )
                ) {
                  handleFreshTalent();
                } else {
                  setIsShowWarningModal(true);
                }
              }}
            >
              add fresh talent
            </Button>
            <Popover
              id="dropdown"
              open={Boolean(anchorElFreshTalent)}
              anchorEl={anchorElFreshTalent}
              onClose={handleCloseFreshTalent}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                borderRadius: "0px !important",

                "& .MuiPopover-paper": {
                  width: "360px",
                  height: {
                    sm: "440px",
                    lg: "440px",
                    lgm: "500px",
                    mlg: "550px",
                    xl: "572px",
                  },
                  borderRadius: "25px !important",
                },
              }}
            >
              <AddFreshTalent
                handleCloseFreshTalent={handleCloseFreshTalent}
                getJobList={getJobList}
                setAllJobs={setAllJobs}
              />
            </Popover>
            <Button
              variant="contained"
              color="yellowButton400"
              sx={{
                borderRadius: 0,
                justifyContent: "start",
              }}
              // disabled={
              //   decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id &&
              //   poolInfo?.owner === false
              //     ? true
              //     : false
              // }
              onClick={() => {
                if (
                  !(
                    decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id &&
                    poolInfo?.owner === false
                  )
                ) {
                  handleLinkedToJob();
                } else {
                  setIsShowWarningModal(true);
                }
              }}
            >
              link a job
            </Button>
            <Popover
              id="dropdown"
              open={Boolean(anchorElLinkedJob)}
              anchorEl={anchorElLinkedJob}
              onClose={handleCloseLinkedToJob}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                borderRadius: "0px !important",
                "& .MuiPaper-root-MuiPopover-paper": {
                  minWidth: "18% !important",
                  borderRadius: "20px !important",
                  mt: 2,
                },
                "& .MuiPopover-paper": {
                  borderRadius: "25px !important",
                  width: "360px",
                },
                // "& .MuiPopover-paper": {
                //   width: "360px",
                //   height: {
                //     sm: "440px",
                //     lg: "440px",
                //     lgm: "500px",
                //     mlg: "550px",
                //     xl: "572px",
                //   },
                //   borderRadius: "25px !important",
                // },
              }}
            >
              <LinkedToJob
                setShortlistedCandidate={setShortlistedCandidate}
                handleCloseLinkedToJob={handleCloseLinkedToJob}
                getJobList={getJobList}
                setAllJobs={setAllJobs}
                setLinkedJobCount={setLinkedJobCount}
                linkedJobCount={linkedJobCount}
                open={"linkedtojob"}
                handleCancel={handleCancel}
                selectedJob={selectedJob}
                setSelectedJob={setSelectedJob}
                setAnchorElLinkedJob={setAnchorElLinkedJob}
                setLinkJobButton={setLinkJobButton}
                getPoolDetails={getPoolDetails}
              />
            </Popover>
            <Button
              variant="contained"
              color="tealColor"
              sx={{
                borderRadius: "0 0 0 25px",
                justifyContent: "start",
              }}
              // disabled={
              //   decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id &&
              //   poolInfo?.owner === false
              //     ? true
              //     : false
              // }
              onClick={() => {
                if (
                  !(
                    decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id &&
                    poolInfo?.owner === false
                  )
                ) {
                  handleLinkedJob();
                } else {
                  setIsShowWarningModal(true);
                }
              }}
            >
              linked jobs ({linkedJobCount})
            </Button>
            <Popover
              id="dropdown"
              open={Boolean(anchorElJobLinked)}
              anchorEl={anchorElJobLinked}
              onClose={handleCloseLinkedJob}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              sx={{
                borderRadius: "0px !important",

                "& .MuiPopover-paper": {
                  width: "360px",
                  height: {
                    sm: "440px",
                    lg: "440px",
                    lgm: "500px",
                    mlg: "550px",
                    xl: "572px",
                  },
                  borderRadius: "25px !important",
                },
              }}
            >
              <JobsLinked
                handleCloseLinkedToJob={handleCloseLinkedJob}
                getJobList={getJobList}
                setAllJobs={setAllJobs}
                setLinkedJobCount={setLinkedJobCount}
                linkedJobCount={linkedJobCount}
                open={"linkedjob"}
                setSelectedJob={setSelectedJob}
                setAnchorElJobLinked={setAnchorElJobLinked}
                getPoolDetails={getPoolDetails}
                setLinkJobButton={setLinkJobButton}
              />
            </Popover>
          </Box>
        </Box>
        {!linkJobButton && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderColor: theme?.palette?.grayBorder,
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                top: "-60px",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Box
                  component="img"
                  className="profileAvatar"
                  alt="crayon logo"
                  src={
                    poolInfo?.logo !== null &&
                    poolInfo?.profile_url !== "No URL"
                      ? poolInfo?.profile_url
                      : CompanyLogo
                  }
                  sx={{
                    height: 75,
                    width: 75,
                    borderRadius: "10px",
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "20px",
                    fontWeight: theme?.typography?.Bold,
                  }}
                >
                  {poolInfo?.title}
                  <SmallButton
                    color="userID"
                    label={totalPoolUsers}
                    paddingX={2}
                    fontSize={10}
                    fontWeight={700}
                    textColor="#000"
                    borderRadius="6px"
                    width="fit-content"
                  />
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: theme?.typography?.Regular,
                }}
              >
                View talent within this pool, move talent to other pools and
                link pool to a job
              </Typography>
            </Box>
            <Box
              sx={{
                width: "44.5%",
                marginRight: "30px",
              }}
            >
              <Paper
                elevation={3}
                sx={{
                  display: "flex",
                  borderRadius: "10px",
                  height: "40px",
                  boxShadow: "none",
                  border: `1px solid ${theme?.palette?.grayBorder}`,
                }}
              >
                <InputBase
                  sx={{ ml: 2, mr: 2, width: "100%" }}
                  id="Pool_name"
                  placeholder={"search within pool"}
                  onChange={handleInputSearch}
                />
              </Paper>
            </Box>
          </Box>
        )}
      </Box>
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        scrollableTarget={"jobList"}
        dataLength={allJobs?.length}
        next={() => getJobList(lastKey, searchTitle, [])}
        hasMore={true}
        scrollThreshold={"10px"}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <Box
          sx={{
            mt: "57px",
            paddingBottom: '35px !important'
          }}
        >
          {allJobs?.length > 0 ? (
            allJobs?.map((talent, index) => (
              <AllTalentCard
                key={index}
                talentContent={talent}
                telId={talent?.candidate_user_id}
                allTalent={allJobs}
                setAllTalent={setAllJobs}
                poolName={poolInfo?.title}
                setTotalPoolUsers={setTotalPoolUsers}
                buttonDisabled={
                  decodedToken?.data?.role_id === USER_ROLES?.[6]?.role_id &&
                  poolInfo?.owner === false
                    ? true
                    : false
                }
                setIsShowWarningModal={setIsShowWarningModal}
              />
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                mt: 4,
                color: "black",
              }}
            >
              {!Boolean(anchorElLinkedJob)
                ? "No talent in this pool"
                : "You haven't selected any job. To shortlist a candidate, please select a job."}
            </Box>
          )}
        </Box>
      </InfiniteScroll>
      {isShowWarningModal && (
        <SubscriptionPopModal
          handleOpen={isShowWarningModal}
          handleClose={() => setIsShowWarningModal(false)}
          handleSubscriptionOk={() => setIsShowWarningModal(false)}
          modalHeading={"Permission Denied"}
          modalContent={"You don’t have permission to access this"}
        />
      )}
    </Box>
  );
}
