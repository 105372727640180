import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
  InputBase,
  Paper,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import crayonLogo from "../../../../assets/Crayon_Favicon.svg";
import { useDispatch } from "react-redux";
import { debounce } from "../../../../utils/Common";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";

export default function PostAs({
  setPostJobData,
  setSelectedCompany,
  selectedCompany,
  handleCloseRecruit,
  postJobData,
  companyList,
  companyListLoading,
  getCompanyList,
  lastKey,
  setSearchText,
  searchText,
  setCompanyList,
  defaultCompany,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          const response = await getCompanyList(0, newValue?.target?.value);

          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setCompanyList(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setCompanyList, setIsLoading]
  );

  const handleSearch = async (event) => {
    setCompanyList([]);
    setSearchText(event?.target?.value);
    debouncedHandleOnTextSearch(event);
  };

  return (
    <Box
      sx={{
        boxShadow: "0px 3px 8px #00000029",
        width: "300px",
        borderRadius: "25px",
        height: "586px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
          background: "green",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleCloseRecruit()}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          //   color: (theme) => theme.palette.grey[500],
        }}
        disableRipple={true}
      >
        <Box
          sx={{
            width: "20px",
            height: "20px",
            color: "#000000",
            border: 2,
            fontSize: "18px",
            borderRadius: "5px",
          }}
        >
          X
        </Box>
      </IconButton>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: theme.typography.Medium,
          }}
        >
          Post as
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Paper
            elevation={0}
            component="form"
            sx={{
              padding: "0px 10px",
              border: "1px solid #C9CAD8",
            }}
            height={"40px"}
            width={"308px"}
          >
            <InputBase
              sx={{
                fontWeight: theme.typography.Regular,
                width: "308px",
              }}
              placeholder={"Search for a job"}
              value={searchText}
              onChange={(event) => {
                handleSearch(event);
              }}
            />
          </Paper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "420px",
              "::-webkit-scrollbar": {
                height: "5px",
                background: "#DCDEEB",
                display: "unset !important",
              },
            }}
            id="companyList"
          >
            <InfiniteScroll
              style={{
                overflowX: "hidden",
                scrollbarWidth: "thin",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingBottom: '35px !important'
              }}
              dataLength={companyList?.length || 0}
              next={() => getCompanyList(lastKey, searchText)}
              hasMore={true}
              // loader={
              //   <Box
              //     style={{
              //       margin: "15px 0 0 0",
              //       display: "flex",
              //       justifyContent: "center",
              //       alignItems: "center",
              //     }}
              //   >
              //     <CircularProgress color="inherit" size={20} />
              //   </Box>
              // }
              scrollableTarget="companyList"
              scrollThreshold={"50px"}
            >
              {companyList?.length > 0
                ? companyList
                    ?.filter((item) => !item?.default)
                    ?.map((company) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={
                            company?.profile_url !== "No URL"
                              ? company?.profile_url
                              : crayonLogo
                          }
                          sx={{
                            height: "46px",
                            width: "46px",
                            borderRadius: "10px",
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: theme.typography.Bold,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPostJobData({
                              ...postJobData,
                              company_id: company?.company_id,
                            });
                            setSelectedCompany(company?.company_id);
                            handleCloseRecruit();
                          }}
                        >
                          {company?.name}
                        </Typography>
                        {company?.company_id === selectedCompany && (
                          <CheckCircle
                            sx={{
                              fontSize: 27,
                              cursor: "pointer",
                            }}
                            color="checkColor"
                          />
                        )}
                      </Box>
                    ))
                : companyListLoading && (
                    <Box
                      style={{
                        margin: "15px 0 0 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress color="inherit" size={20} />
                    </Box>
                  )}
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="closeButton"
        sx={{
          color: "black",
          width: "100%",
          borderRadius: "0 0 10px 10px",
        }}
        onClick={handleCloseRecruit}
      >
        Close
      </Button>
    </Box>
  );
}
