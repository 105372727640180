import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import locale from "../../../i18n/locale";
import InputBox from "./InputBox";
import {
  cancelApproval,
  clearCompany,
  createCompInfo,
  getCompanies,
  getCompanyDetail,
  getCompanySize,
  getCompanyType,
  setProfileSteps,
} from "../../../redux/employer/empProfileSlice";
import { setAlert } from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  websiteUrlRegex,
} from "../../../utils/Constants";
import { setLoading } from "../../../redux/configSlice";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";
import SelectMenu from "../../common/SelectMenu";
import { useTheme } from "@emotion/react";
import { Button } from "@mui/material";
import CustomSelect from "../../candidate/myCV/CustomSelect";
import {
  getCompanyCulture,
  getIndustries,
} from "../../../redux/candidate/myCvSlice";
import { debounce, setLocalStorage, validateSchema } from "../../../utils/Common";
import * as Yup from "yup";
import CustomDialog from "../CustomDialog";
import NotApproved from "./NotApproved";
import { getCountry, getTown } from "../../../redux/employer/postJobSlice";
import ExisitingModalSelectModal from "./ExisitingModalSelectModal";

export default function Info({
  handleCompanyInfoData,
  profile2,
  errors,
  setErrors,
  companies,
  industries,
  setCompanyDetail,
  companyID,
  setEditable,
  editable,
  companyurl,
  handleNextStep,
  setEmployerData,
  getAllEmpData,
  setShowMyCompany,
  getSecondaryCompanies,
  employerData,
  country,
  town,
  setCompInfo,
  isApproved,
  setIsApproved,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState(profile2);
  const [companyNameValue, setCompanyNameValue] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [isApproved, setIsApproved] = useState(false);
  const token = localStorage?.getItem("token");
  const [companyInfoCharLength, setCompanyInfoCharLength] = useState(0);
  const [isSecondaryCompany, setIsSecondaryCompany] = useState();
  const [isOpenExistingCompModal, setIsOpenExisitingCompModal] =
    useState(false);
  const { initialIndustries, companyCultures, initialCompanyCulture } =
    useSelector((state) => state?.myCv);
  let decodedToken;
  if (token) {
    decodedToken = jwt_decode(token);
  }
  const { companyType, companySize } = useSelector((state) => state?.myProfile);
  const companyInfoSchema = Yup.object().shape({
    company_name: Yup.object().shape({
      value: Yup.mixed().required("Industry value is Required."),
      label: Yup.string().required("Industry label is Required."),
    }),
    // hyperlink: Yup.string().required("Hyperlink is Required."),
    hyperlink: Yup.string()
      .matches(websiteUrlRegex, "Invalid URL format")
      .required("Hyperlink is Required."),
    company_type: Yup.string().required("Company Type is Required"),
    company_size: Yup.string().required("Company Size is Required"),
    industry_ids: Yup.array()
      .min(1, "At least one industry is required.")
      .of(
        Yup.object().shape({
          value: Yup.number().required("Industry value is Required."),
          label: Yup.string().required("Industry label is Required."),
        })
      )
      .min(1, "At least one industry is required.")
      .required("Industry is Required."),
    notes: Yup.string().required("Description is Required"),
    country_id: Yup.object()
      .shape({
        value: Yup.number().required("country_id value is Required."),
        label: Yup.string().required("country_id label is Required."),
      })
      .nullable(true)
      .required("country_id is Required."),
    town_id: Yup.object()
      .shape({
        value: Yup.number().required("town_id value is Required."),
        label: Yup.string().required("town_id label is Required."),
      })
      .nullable(true)
      .required("town_id is Required."),
    culture_ids: Yup.array()
      .min(5, "At least five company culture is required.")
      .of(
        Yup.object().shape({
          value: Yup.number().required("Culture value is Required."),
          label: Yup.string().required("Culture label is Required."),
        })
      )
      .min(5, "At least five company culture is required.")
      .required("Culture is Required."),
  });

  const getAllData = async () => {
    try {
      dispatch(setLoading(true));
      await Promise.all([
        dispatch(getCompanyType()),
        dispatch(getCompanySize()),
      ]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const  websiteRegex =/^(https?:\/\/www\.|https?:\/\/|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+.*$/;
  const saveCompInfo = async (changeNext, buttonname, type) => {
    let link = profileData?.hyperlink;
    if (!websiteRegex.test(link)) {
      setErrors([{key:"hyperlink",message:"comapny website url is wrong"}])
      return; 
    }
    let {
      company_name,
      company_size,
      company_type,
      hyperlink,
      industry_ids,
      notes,
      culture_ids,
      country_id,
      town_id,
    } = profileData;
    let testErrorState = {
      company_name,
      company_size,
      company_type,
      hyperlink,
      industry_ids,
      notes,
      culture_ids,
      country_id,
      town_id,
    };
    if (profileData?.company_id !== "") {
      try {
        let data = {
          ...profileData,
          country_id: profileData?.country_id?.value,
          town_id: profileData?.town_id?.value,
          companyURL: companyurl,
          logo: profile2?.logo,
        };
        if (data?.company_name && editable === true) {
          data["company_name"] = profileData?.company_name?.label;
        } else {
          data["company_name"] = profileData?.company_name?.value;
        }
        if (data?.company_id) {
          delete data["company_id"];
        }
        if (data?.industry_ids) {
          data["industry_ids"] = profileData?.industry_ids?.map(
            (item) => item?.value
          );
        }
        if (data?.culture_ids) {
          data["culture_ids"] = profileData?.culture_ids?.map(
            (item) => item?.value
          );
        }
        if (profileData?.company_name) {
          setProfileData((pre) => ({
            ...pre,
            company_name: companyNameValue?.["company_name"],
          }));
        }
        if (profileData?.industry_ids) {
          setProfileData((pre) => ({
            ...pre,
            industry_ids: companyNameValue?.["industry_ids"],
          }));
        }
        if (type === "requestTransfer") {
          data["claim_ownership"] = true;
        } else if (type === "joinCompany") {
          data["claim_ownership"] = false;
        }
        const { payload } = await dispatch(createCompInfo(data));
        if (payload?.status === "success") {
          payload?.newtoken !== null && setLocalStorage("token", payload?.newtoken);
          getAllEmpData();
          if (setShowMyCompany) {
            if (buttonname === "addcompany") {
              setShowMyCompany(true);
              handleNextStep(3);
            } else {
              setShowMyCompany(false);
            }
          }
          changeNext
            ? handleNextStep(4)
            : buttonname === "addcompany"
            ? handleNextStep(3): buttonname === "myPlan"?handleNextStep(4)
            : handleNextStep(0);
        } else if (payload?.status === "error") {
          setErrors(payload?.message);
        } else {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: payload?.message,
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } else {
      validateSchema(companyInfoSchema, testErrorState)
        .then(() => {
          try {
            let data = {
              ...profileData,
              country_id: profileData?.country_id?.value,
              town_id: profileData?.town_id?.value,
              companyURL: companyurl,
              logo: profile2?.logo,
            };
            if (data?.company_name && editable === true) {
              data["company_name"] = profileData?.company_name?.label;
            } else {
              data["company_name"] = profileData?.company_name?.value;
            }
            if (data?.company_id) {
              delete data["company_id"];
            }
            if (data?.industry_ids) {
              data["industry_ids"] = profileData?.industry_ids?.map(
                (item) => item?.value
              );
            }
            if (data?.culture_ids) {
              data["culture_ids"] = profileData?.culture_ids?.map(
                (item) => item?.value
              );
            }
            if (profileData?.company_name) {
              setProfileData((pre) => ({
                ...pre,
                company_name: companyNameValue?.["company_name"],
              }));
            }
            if (profileData?.industry_ids) {
              setProfileData((pre) => ({
                ...pre,
                industry_ids: companyNameValue?.["industry_ids"],
              }));
            }
            dispatch(createCompInfo(data)).then((payload) => {
              if (payload?.payload?.status === "success") {
                getAllEmpData();

                if (setShowMyCompany) {
                  if (buttonname === "addcompany") {
                    setShowMyCompany(true);
                    handleNextStep(3);
                  } if(buttonname === "myPlan"){
                    setShowMyCompany(true);
                    dispatch(setProfileSteps(4));
                  }
                   else {
                    setShowMyCompany(false);
                  }
                }
                changeNext
                  ? handleNextStep(4)
                  : buttonname === "addcompany"
                  ? handleNextStep(3):buttonname === "myPlan"?handleNextStep(4)
                  : handleNextStep(0);
              } else if (payload?.payload?.status === "error") {
                // setErrors(payload?.payload?.message);
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: "Error",
                  })
                );
                setErrors([]);
              } else {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: payload?.payload?.message,
                  })
                );
              }
            });
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: i18n["alertMessage.error"],
              })
            );
          }
        })
        .catch((error) => {
          setErrors(
            Object.keys(error)?.map((item) => ({
              message: error[item],
              key: item,
            }))
          );
        });
    }
  };

  const getCompanyData = async (companyID) => {
    try {
      const data = {
        company_id: companyID ? companyID : "",
      };
      const { payload } = await dispatch(getCompanyDetail(data));
      if (payload?.status === "success") {
        setErrors([]);
        setEditable(payload?.data?.editflag);
        setCompanyDetail(payload?.data);
        setCompInfo({ ...profile2, created_at: payload?.data?.created_at });
        setEmployerData((prev) => ({
          ...prev,
          company_name: payload?.data?.name,
        }));
        // setIsApproved(payload?.data?.company_approved);
        setProfileData({
          company_name: {
            label: payload?.data?.name,
            value: payload?.data?.company_id,
          },
          hyperlink: payload?.data?.website,
          notes: payload?.data?.description,
          industry_ids: payload?.data?.employer_industries?.map((item) => ({
            value: item?.industry_id,
            label: item?.industry?.name,
          })),
          company_type: payload?.data?.company_type,
          company_size: payload?.data?.company_size,
          company_id: payload?.data?.company_id,
          companyURL:
            payload?.data?.companyURL === "NO URL"
              ? []
              : payload?.data?.companyURL,
          logo: payload?.data?.logo,
          country_id: {
            value: payload?.data?.country_id,
            label: payload?.data?.country_name,
          },
          town_id: {
            value: payload?.data?.town_id,
            label: payload?.data?.town_name,
          },
          culture_ids: payload?.data?.employer_cultures?.map((item) => ({
            value: item?.company_culture_id,
            label: item?.company_culture?.name,
          })),
          employer_cultures:payload?.data?.employer_cultures,
          created_at: payload?.data?.created_at,
        });
        setCompanyInfoCharLength(payload?.data?.description?.length);
        setCompanyNameValue({
          company_name: {
            label: payload?.data?.name,
            value: payload?.data?.company_id,
          },
          hyperlink: payload?.data?.website,
          notes: payload?.data?.description,
          industry_ids: payload?.data?.employer_industries?.map((item) => ({
            value: item?.industry_id,
            label: item?.industry?.name,
          })),
          company_type: payload?.data?.company_type,
          company_size: payload?.data?.company_size,
          company_id: payload?.data?.company_id,
          companyURL:
            payload?.data?.companyURL === "NO URL"
              ? []
              : payload?.data?.companyURL,
          logo: payload?.data?.logo,
        });
        handleCompanyInfoData({
          company_name: {
            label: payload?.data?.name,
            value: payload?.data?.company_id,
          },
          hyperlink: payload?.data?.website,
          notes: payload?.data?.description,
          industry_ids: payload?.data?.employer_industries?.map((item) => ({
            value: item?.industry_id,
            label: item?.industry?.name,
          })),
          company_type: payload?.data?.company_type,
          company_size: payload?.data?.company_size,
          company_id: payload?.data?.company_id,
          companyURL:
            payload?.data?.companyURL === "NO URL"
              ? []
              : payload?.data?.companyURL,
          logo: payload?.data?.logo,
        });
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: "Something went wrong",
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "Something went wrong",
        })
      );
    }
  };
  const handleCancelRequest = async () => {
    const payload = {
      request_id: employerData?.request_id,
    };
    try {
      const response = await dispatch(cancelApproval(payload));
      if (response?.payload?.status === "success") {
        setProfileData({
          company_name: "",
          hyperlink: "",
          notes: "",
          industry_ids: [],
          company_type: "",
          company_size: "",
          company_id: "",
          companyURL: [],
          logo: "",
          culture_ids: [],
          country_id: { value: "", label: "" },
          town_id: { value: "", label: "" },
        });
        setEmployerData({
          ...employerData,
          company_name: "",
        });
        setCompInfo({
          ...profile2,
          companyURL: [],
          logo: "",
          created_at: "",
        });
        setCompanyDetail({
          TotalJobCount: "",
          ActiveJobCount: "",
          TotalUserCount: "",
          TotalUserShortlisted: "",
          TotalUserInterviewed: "",
          TotalUserHired: "",
        });
        // getCompanyData(companyID)
        setCompanyInfoCharLength(0);
        setIsApproved(false);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: i18n["alertMessage.error"],
        })
      );
    }
  };
  const getIndValue = () => {
    if (profileData?.industry_ids?.length === 0) {
      return "";
    }
    return profileData?.industry_ids?.map(
      (id) => industries?.find((industry) => industry?.industry_id === id) || id
    );
  };
  const getCultureValue = () => {
    if (profileData?.culture_ids?.length === 0) {
      return "";
    }
    return profileData?.culture_ids?.map(
      (id) =>
        companyCultures?.find(
          (culture) => culture?.company_culture_id === id
        ) || id
    );
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (value.length <= 2000) {
      id === "notes" && setCompanyInfoCharLength(value?.length);
      const newProfileData = {
        ...profileData,
        [event.target.id]: event?.target?.value,
        companyURL: companyurl,
        logo: profile2?.logo,
      };
      setErrors(errors?.filter((item) => item?.key !== event?.target?.id));
      setProfileData(newProfileData);
      handleCompanyInfoData(newProfileData);
    } else {
    }
  };

  const handleCompanySize = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    const newProfileData = {
      ...profileData,
      [name]: companySize?.find((role) => role?.id == value)?.name || "",
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setProfileData({
      ...newProfileData,
      companyURL: companyurl,
      logo: profile2?.logo,
    });
  };
  const handleCompanyType = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newProfileData = {
      ...profileData,
      [name]: companyType?.find((role) => role?.id == value)?.name || "",
    };

    setErrors(errors?.filter((item) => item?.key !== name));
    setProfileData({
      ...newProfileData,
      companyURL: companyurl,
      logo: profile2?.logo,
    });
  };
  const onCreateOption = async (data, inputId, index) => {
    const updatedWorkData = { ...profileData };
    setErrors([]);
    if (data) {
      updatedWorkData[index] = {
        ...updatedWorkData[index],
        [inputId]: { label: data, value: data },
      };
      setCompanyNameValue(updatedWorkData);
      setEmployerData((prev) => ({
        ...prev,
        [inputId]: data,
      }));

      setProfileData(updatedWorkData);
      if (typeof data === "string") {
        setEditable(true);
        setProfileData({
          company_name: { label: data, value: data },
          hyperlink: "",
          notes: "",
          industry_ids: [],
          company_type: "",
          company_size: "",
          company_id: "",
          companyURL: [],
          logo: "",
          country_id: { value: "", label: "" },
          town_id: { value: "", label: "" },
          culture_ids: [],
        });
        setCompanyDetail({
          TotalJobCount: "",
          ActiveJobCount: "",
          TotalUserCount: "",
          TotalUserShortlisted: "",
          TotalUserInterviewed: "",
          TotalUserHired: "",
        });
        setCompanyInfoCharLength(0);
      }
    }
  };

  const handleOnSelectInputChange = (newValue, inputId) => {
    debouncedHandleOnSelectInputChange(
      newValue,
      inputId,
      profileData?.country_id?.value
    );
  };

  const debouncedHandleOnSelectInputChange = useCallback(
    debounce(
      async (newValue, type, compID) => {
        let params = {
          title: newValue || "",
          limit: 5,
        };
        if (newValue) {
          try {
            if (type === "company_name") {
              dispatch(getCompanies(params));
            }
            if (type === "industry_ids") {
              dispatch(getIndustries(params));
            }
            if (type === "culture_ids") {
              dispatch(getCompanyCulture(params));
            }
            if (type === "country_id") {
              dispatch(getCountry(params));
            }
            if (type === "town_id") {
              dispatch(
                getTown({
                  title: newValue || "",
                  id: compID,
                  limit: 200,
                })
              );
            }
          } catch (error) {
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: error,
              })
            );
          }
        }
      },
      750,
      setIsLoading
    ),
    []
  );
  const onMenuOpen = () => {
    setIsMenuOpen(true);
  };
  const onMenuClose = () => {
    setIsMenuOpen(false);
  };

  const onMenuCloseTown = async () => {
    setIsMenuOpen(true);
  };

  const onMenuOpenTown = async () => {
    if (town?.length === 0) {
      try {
        const { payload } = await dispatch(
          getTown(profileData?.country_id?.value || "")
        );
      } catch (error) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    }
    setIsMenuOpen(true);
  };

  const handleSubmit = (type) => {
    saveCompInfo(false, "", type);
  };

  const handleOnSelectChange = async (ind, newValue, val, index) => {
    const newProfileData = {
      ...profileData,
      companyURL: companyurl,
      logo: profile2?.logo,
    };
    if (index === "company_name") {
      setErrors([]);
      newProfileData[index] = newValue;
      setEmployerData((prev) => ({
        ...prev,
        [index]: newValue?.label,
      }));
      if (newValue) {
        let comp = companies?.filter(
          (item) => item?.company_id === newValue?.value
        );
        if (comp[0] && comp[0]?.secondary_company === true) {
          setIsSecondaryCompany(true);
          setIsOpenExisitingCompModal(true);
        } else if (
          comp[0] &&
          (comp[0]?.secondary_company === null ||
            comp[0]?.secondary_company === false)
        ) {
          setIsSecondaryCompany(false);
          setIsOpenExisitingCompModal(true);
        }
      }
      setProfileData(newProfileData);
      setEditable(false);
      if (newValue === null) {
        setProfileData({
          company_name: newValue?.inputValue,
          hyperlink: "",
          notes: "",
          industry_ids: [],
          company_type: "",
          company_size: "",
          company_id: "",
          companyURL: [],
          logo: "",
          culture_ids: [],
          country_id: { value: "", label: "" },
          town_id: { value: "", label: "" },
        });
        setCompInfo({
          ...profile2,
          companyURL: [],
          logo: "",
          created_at: "",
        });
        setCompanyDetail({
          TotalJobCount: "",
          ActiveJobCount: "",
          TotalUserCount: "",
          TotalUserShortlisted: "",
          TotalUserInterviewed: "",
          TotalUserHired: "",
        });
        setCompanyInfoCharLength(0);
      }
      setEmployerData((prev) => ({
        ...prev,
        [index]: newValue?.inputValue,
      }));
      setCompanyNameValue(newProfileData);
      handleCompanyInfoData(newProfileData);
      newValue !== null && (await getCompanyData(newValue?.value));
    }
    if (ind === "industry_ids") {
      setErrors(errors?.filter((item) => item?.key !== ind));
      newProfileData[ind] = newValue;
      setEmployerData((prev) => ({
        ...prev,
        [ind]: newValue?.label,
      }));
      setCompanyNameValue(newProfileData);
      setProfileData(newProfileData);
      handleCompanyInfoData(newProfileData);
    }
    if (index === "country_id") {
      dispatch(getTown(newValue?.value));
      setErrors(errors?.filter((item) => item?.key !== index));
      newProfileData[index] = newValue;
      setEmployerData((prev) => ({
        ...prev,
        [index]: newValue?.label,
      }));
      setCompanyNameValue(newProfileData);
      setProfileData(newProfileData);
      handleCompanyInfoData(newProfileData);
    }
    if (index === "town_id") {
      setErrors(errors?.filter((item) => item?.key !== index));
      newProfileData[index] = newValue;
      setEmployerData((prev) => ({
        ...prev,
        [index]: newValue?.label,
      }));
      setCompanyNameValue(newProfileData);
      setProfileData(newProfileData);
      handleCompanyInfoData(newProfileData);
    }
    if (ind === "culture_ids") {
      setErrors(errors?.filter((item) => item?.key !== ind));
      newProfileData[ind] = newValue;
      setEmployerData((prev) => ({
        ...prev,
        [ind]: newValue?.label,
      }));
      setCompanyNameValue(newProfileData);
      setProfileData(newProfileData);
      handleCompanyInfoData(newProfileData);
    }
  };
  useEffect(() => {
    if (getSecondaryCompanies) {
      getSecondaryCompanies();
    }
    dispatch(clearCompany());
    getAllData();
  }, []);
  useEffect(() => {
    handleCompanyInfoData({
      ...profileData,
      // companyURL: companyurl, // image is not getting cleared due to this
      // logo: profile2?.logo, // image is not getting cleared due to this
    });
  }, [profileData]);

  useEffect(() => {
    if (companyID) {
      getCompanyData(companyID);
    }
  }, [companyID]);

  return (
    <>
      {isApproved && (
        <CustomDialog
          show={true}
          hideButton={false}
          dialogWidth="xs"
          showFooter={false}
          padding={0}
        >
          <NotApproved handleCancelRequest={handleCancelRequest} />
        </CustomDialog>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.companyNameReq"]}
            </Typography>
            <CustomSelect
              variant="createSelect"
              onInputChange={handleOnSelectInputChange}
              onChange={handleOnSelectChange}
              onCreateOption={onCreateOption}
              placeholder={i18n["empMyProfile.companyNamePlace"]}
              inputId="company_name"
              name="company_name"
              value={
                profileData?.company_name?.label &&
                profileData?.company_name?.value
                  ? {
                      label: profileData?.company_name?.label
                        ? profileData?.company_name?.label
                        : "",
                      value: profileData?.company_name?.value
                        ? profileData?.company_name?.value
                        : "",
                    }
                  : null
              }
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              option={companies?.map((item) => ({
                value: item?.company_id,
                label: item?.name,
              }))}
              isLoading={isLoading}
              customStyle={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                menuList: (provided) => ({
                  ...provided,
                  "&::-webkit-scrollbar": {
                    width: "6px",
                    height: "6px",
                    borderRadius: "2.5px",
                    backgroundColor: "#efefef",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    borderRadius: "2.5px",
                  },
                }),
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  boxShadow: "none",
                  borderRadius: "10px",
                  height: `40px`,
                  border: errors?.find(
                    (error) =>
                      error?.key === "company_name.label" ||
                      error?.key === "company_name"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                  "&:hover": errors?.find(
                    (error) =>
                      error?.key === "company_name.label" ||
                      error?.key === "company_name"
                  )
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
                }),
              }}
            />
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                tablet: "47%",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              },
            }}
          >
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.companyWebsiteReq"]}
            </Typography>
            <InputBox
              readOnly={!editable}
              placeholder={i18n["empMyProfile.companyWebsitePlace"]}
              value={profileData?.hyperlink ? profileData?.hyperlink : ""}
              id="hyperlink"
              onChange={handleInputChange}
              sx={{
                // alignItems: "flex-start",
                border:
                  // !profileData?.hyperlink &&
                  errors?.find((error) => error?.key === "hyperlink")
                    ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                    : "1px solid #E0E0E0",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: { xs: "column", tablet: "column" },
              gap: { xs: "20px", tablet: "0px" },
              width: { xs: "100%", tablet: "47%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", tablet: "row" },
                justifyContent: { tablet: "space-between" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", tablet: "47%" },
                  marginBottom: "20px",
                }}
              >
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.companyTypeReq"]}
                </Typography>
                <SelectMenu
                  clear={true}
                  readOnly={!editable}
                  name="company_type"
                  value={profileData?.company_type}
                  onHandleChange={handleCompanyType}
                  options={companyType}
                  borderRadius="10px"
                  sx={{ width: "100%" }}
                  placeholder={"Select the company type"}
                  border={
                    // !profileData?.company_type &&
                    errors?.find((error) => error?.key === "company_type")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0"
                  }
                />
              </Box>
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.companySizeReq"]}
                </Typography>
                <SelectMenu
                  clear={true}
                  readOnly={!editable}
                  name="company_size"
                  value={profileData?.company_size}
                  onHandleChange={handleCompanySize}
                  options={companySize}
                  borderRadius="10px"
                  sx={{ width: "100%" }}
                  border={
                    // !profileData?.company_size &&
                    errors?.find((error) => error?.key === "company_size")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0"
                  }
                  placeholder={"Select the size of company"}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", tablet: "row" },
                justifyContent: { tablet: "space-between" },
              }}
            >
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.companyCountry"]}
                </Typography>
                <CustomSelect
                  isDisabled={!editable}
                  onMenuOpen={() => {}}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select the country of the company"}
                  inputId="country_id"
                  name="country_id"
                  value={profileData?.country_id}
                  // isLoading={isLoading}
                  filterOption={"filter"}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: errors?.find(
                        (error) => error.key === "country_id.value"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error.key === "country_id.value"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      // width: `100%`,
                    }),
                  }}
                  option={country?.map((item) => ({
                    value: item?.country_id,
                    label: item?.name,
                  }))}
                />
              </Box>
              <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
                <Typography
                  sx={{
                    marginBottom: "4px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  {i18n["empMyProfile.companyCityTown"]}
                </Typography>
                <CustomSelect
                  isDisabled={
                    !editable ? true : profileData?.country_id ? false : true
                  }
                  onMenuClose={onMenuCloseTown}
                  onMenuOpen={onMenuOpenTown}
                  variant="default"
                  onInputChange={handleOnSelectInputChange}
                  onChange={handleOnSelectChange}
                  placeholder={"Select the city/town"}
                  inputId="town_id"
                  name="town_id"
                  value={profileData?.town_id}
                  isLoading={isLoading["town_id"]}
                  customStyle={{
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      "&::-webkit-scrollbar": {
                        width: "6px",
                        height: "6px",
                        borderRadius: "2.5px",
                        backgroundColor: "#efefef",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        borderRadius: "2.5px",
                      },
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => {
                      return {
                        ...styles,
                        color: isSelected ? "#fff" : "black",
                        ":active": {
                          ...styles[":active"],
                        },
                      };
                    },
                    control: (baseStyles) => ({
                      ...baseStyles,
                      boxShadow: "none",
                      borderRadius: "10px",
                      border: errors?.find(
                        (error) => error.key === "town_id.value"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                      "&:hover": errors?.find(
                        (error) => error.key === "town_id.value"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    }),
                  }}
                  option={town?.map((item) => ({
                    value: item?.town_id,
                    label: item?.name,
                  }))}
                />
              </Box>
            </Box>
          </Box>

          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  marginBottom: "4px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Bold,
                }}
              >
                {i18n["empMyProfile.companyDescriptionReq"]}(
                {companyInfoCharLength}/2000)
              </Typography>
              <InputBox
                multiline={true}
                sx={{
                  width: "100%",
                  height: "120px",
                  borderRadius: "10px",
                  alignItems: "flex-start",
                  border:
                    (profileData?.notes === null ||
                      profileData?.notes === "" ||
                      profileData?.notes?.length > 2000) &&
                    errors?.find((error) => error?.key === "notes")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                }}
                placeholder={i18n["empMyProfile.companyDescriptionPlace"]}
                value={profileData?.notes ? profileData?.notes : ""}
                id="notes"
                onChange={handleInputChange}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        >
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.companyIndustryReq"]}
            </Typography>
            <CustomSelect
              isDisabled={!editable}
              inputId={"industry_ids"}
              name={"industry_ids"}
              variant={"multiSelect"}
              closeMenuOnSelect={false}
              isMulti={true}
              multiSelectOptions={industries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
              placeholder={i18n["myCV.preferredIndustries"]}
              onChange={(e, val) => handleOnSelectChange(e, val)}
              onInputChange={handleOnSelectInputChange}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border:
                    // getIndValue() === "" &&
                    errors?.find((error) => error?.key === "industry_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    // getIndValue() === "" &&
                    errors?.find((error) => error?.key === "industry_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              multiOnFocus={() => {
                // if (industries?.length === 0) {
                //   dispatch(getCompanies());
                dispatch(getIndustries());
                // }
              }}
              value={getIndValue()}
              limitTags={5}
              initialOptions={initialIndustries?.map((item) => ({
                value: item?.industry_id,
                label: item?.name,
              }))}
            />
          </Box>
          <Box sx={{ width: { xs: "100%", tablet: "47%" } }}>
            <Typography
              sx={{
                marginBottom: "4px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["empMyProfile.companyCulture"]}
            </Typography>
            <CustomSelect
              isDisabled={!editable}
              inputId={"culture_ids"}
              name={"culture_ids"}
              variant={"multiSelect"}
              closeMenuOnSelect={true}
              isMulti={true}
              multiSelectOptions={companyCultures?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
              placeholder={
                "Select 5 adjectives that best describe your company culture"
              }
              onChange={(e, val) => handleOnSelectChange(e, val)}
              onInputChange={handleOnSelectInputChange}
              customStyle={{
                option: (
                  styles,
                  { data, isDisabled, isFocused, isSelected }
                ) => {
                  return {
                    ...styles,
                    color: isSelected ? "#fff" : "black",
                    ":active": {
                      ...styles[":active"],
                    },
                  };
                },
                control: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: "10px",
                  minHeight: `40px`,
                  border:
                    // getIndValue() === "" &&
                    errors?.find((error) => error?.key === "culture_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  "&:hover":
                    // getIndValue() === "" &&
                    errors?.find((error) => error?.key === "culture_ids")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0",
                  width: `100%`,
                }),
              }}
              multiOnFocus={() => {
                if (companyCultures?.length === 0) {
                  dispatch(getCompanyCulture({ limit: "1000" }));
                }
              }}
              value={getCultureValue()}
              limitTags={5}
              initialOptions={initialCompanyCulture?.map((item) => ({
                value: item?.company_culture_id,
                label: item?.name,
              }))}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: { xs: "20px", tablet: "0px" },
            flexDirection: { xs: "column", tablet: "row" },
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: "30px",
        }}
      >
        <Button
          onClick={() => {
            saveCompInfo(false);
          }}
          variant="contained"
          color="lockItinButton"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: "25px 0 0 0",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          lock it in
        </Button>
        <Button
          variant="contained"
          color="addACompanyButton"
          sx={{
            borderRadius: "0",
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
          }}
          onClick={() => {
            saveCompInfo(false, "addcompany");
          }}
        >
          add a company
        </Button>
        <Button
          onClick={() => {
            // navigate()
            saveCompInfo(false, "myPlan");
            // dispatch(setProfileSteps(4));
          }}
          variant="contained"
          color="companyInfoButton"
          sx={{
            width: "150px",
            height: "60px",
            fontSize: "15px",
            fontWeight: theme.typography.Bold,
            borderRadius: "0 25px 0 0 ",
            ":hover": {
              boxShadow: "none",
            },
          }}
        >
          my plan
        </Button>
      </Box>
      {isOpenExistingCompModal && (
        <ExisitingModalSelectModal
          open={isOpenExistingCompModal}
          setOpen={setIsOpenExisitingCompModal}
          isSecondaryCompany={isSecondaryCompany}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}
