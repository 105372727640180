// import flatten from 'flat'  TODO: install flat if require nested objects in en.js
import { flatten } from "flat";
const en = {
  recruiter: {
    gritScore: "Grit Score",
  },

  linkitUp: {
    close: "close",
    jobdoesnothaveanexternallink:
      "This job does not have an external application link.",
    yourapplicationissafewith: "Your application is safe with us!",
    visitexternallink: "visit external link",
    applicationviaCrayon:
      "Your application via Crayon will still be logged and takes precedence over any subsequent external application (for purposes of Crayon Recruit and Crayon Bounty).",
    jobhasanexternal:
      "This job has an external application link where you can also apply via the job poster’s site or ATS.",
    yourapplicationissafe: "Your application is safe with us, but…",
    linkitup: "Link it up!",
    enteredit:
      "Enter/edit an external application link you’d like to redirect candidates to post their application.",
    applicationsviaCrayon:
      "Applications via Crayon will still be logged and take precedence over any subsequent internal application (for purposes of Crayon Recruit and Crayon Bounty).",
  },

  coinHistory: {
    theassociateduser: "Select the associated user",
    selecttheitem: "Select the item",
    thejobassociated: "Select the job associated with the item",
  },

  voucher: {
    entervouchername: "Enter voucher name",
    numberofcoins: "Select the number of coins",
    singlemultipleuse: "Select single or multiple use",
    numberofdaysvalid: "Select number of days valid",
    claimexpirydate: "Select claim expiry date",
  },

  myplanPopup: {
    addcoins: "Do you want to add",
    chargeemployer: "Do you want to charge",
    employercoinbalance: "coins balance",
    confirmpurchase: "confirm purchase",
    purchaseSummary: "Purchase summary",
    youcanalso:
      "You can also pay-as-you-grow starting from 10 coins per job unlock.",
    coinsmayexpire:
      "Voucher coins may expire depending on the nature of the voucher given.",
    claim: "claim",
    thevouchercode: "Enter the voucher code",
    claimavoucher: "Claim a voucher",
    cannotberefunded: "Purchased coins cannot be refunded, but do not expire.",
    cointopup: "Select your coin top-up",
    confirmcoinspend: "Confirm coin spend",
    youcurrentlyhave: "You currently have",
    thisjobpostwilluse: "This job post will use",
    thisjobrunmatchwilluse:"This job run match will use",
    jobpurchase: "This job post will require that you purchase",
    jobrunmatchpurchase:"This job run match will require that you purchase",
    thisCVunlockwillrequire:
      "This CV unlock will require that you purchase at least",
    pleaseconfirm: "Please confirm that you are happy to proceed?",
    happyletsdothis: "happy, let’s do this",
    nowyourecoiningit: "Now you’re coining it!",
    crayonadminwillreview:
      "Crayon admin will review your purchase and update your account accordingly.",
    thisCVunlockwilluse: "This CV unlock will use",
    thisvideounlockwilluse: "This video unlock will use",
    thisjobpostisfree: "This job post is free, but…",
    itwillcost: "It will cost",
    tounlock: "to unlock.",
    youcanreview:
      "You can review the incoming, matched and shortlisted traffic on the job and elect to unlock it at any time.",
    okgotit: "ok, got it",
    edit: "edit",
    theCrayonRecruitsuccessfee: "The Crayon Recruit success fee will be",
    candidateCTC: "of the candidate’s annualised CTC.",
    thejobwillbeunlocked:
      "The job will be unlocked at the time of the first presentation of candidates.",
  },

  myplan: {
    reviewyourplanoptions: "Review your plan options",
    newbieskickoffwithCrayonLite: "Newbies kick-off with Crayon Lite,",
    realpower: "but the real value and power",
    unlocked: "is unlocked with Crayon Talent.",
    reviewoptions: "review options",
    mycoins: "My coins:",
    crayonlite: "Crayon Lite",
    crayontalent: "Crayon Talent",
    kickoffoffering: "Kick-off offering",
    litetext1:
      "This is our standard pay-as-you-grow base package minus the value-add of Crayon Talent. You are able to post and manage jobs, add additional users and invite your team members to promote and share jobs via Crayon Bounty.",
    startingfrom10coins: "Starting from 10 coins (R950) per job posting",
    litetext2: "You currently have",
    litetext3: "active user",
    litetext4: "assigned to your company account.",
    litetext5: "You currently have",
    litetext6: "coins",
    litetext7: "in your Crayon wallet.",
    talenttext1:
      "Crayon Talent unlocks the full power of the platform! You’ll gain access to our global pool of talent with enhanced search features, talent pools, and reduced hiring fees!",
    talenttext2: "Based on your company’s talent pool",
    talenttext3: "(applicants and followers) of",
    talenttext4: "you would",
    talenttext5: "upgrade onto our",
    talenttext6: "Starter",
    talenttext7: "pricing package.",
    startingfrom: "Starting from R2,950 per month",
    addremoveusers: "add/remove users",
    unlockCrayonTalent: "unlock Crayon Talent",
    getcoins: "get coins",
    whatsinthebox: "What’s in the box?",
    benefitsofCrayonTalent: "Benefits of Crayon Talent",
    poweroffering: "Power offering (LOCKED)",
    viewpricing: "view pricing",
    crayoncoins: "Crayon coins",
    mycoinbalance: "My coin balance:",
    mycoinhistory: "My coin history:",
  },

  alert: {
    passwordsdonotmatch:
      "The entered passwords do not match. Please re-enter to confirm.",
    passwordmustcontain:
      "Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character",
    passwordmustcontainSignup:
      "Your first dog’s name must contain at least 8 characters, one uppercase, one lowercase, one number and one special character.",

    update: "update",
    alert: "alerts",
    alertsetting: "alert setting",
    alertsettings: "alert settings",
    setting: "settings",
    readAll: "read all",
    buildnewalert: "build new alert",
    save: "save",
    back: "back",
    togglecustomalerts: "Toggle all alerts on/off",
    customalerts: "Custom alert 1",
    toggleallalerts: "Toggle all alerts on/off",
    togglenewjobalerts: "Toggle new job alerts on/off",
    togglechangedjobstagealerts: "Toggle changed job stage alerts on/off",
    adminMatchPer: "Minimum match %:",
    selectyourmatch:
      "Select your minimum match % to receive a new job alert (default is 25%):",
    toqualifyfornewjob:
      "To qualify for new job alerts, your profile must be deemed ‘active’ - i.e. logged in within the past 3 months.",
    spoileralert: "Spoiler alert…",
    youllreceiveemailsalerts: "…you’ll receive emails",
    basedonyourjobalertsettings: "based on your job alert settings.",
    newjobswhereyourmatch: "New jobs where your match % exceeds",
    newjobswhereyourmatch1: "your selected level, will trigger an",
    newjobswhereyourmatch2: "alert email. You can also build",
    newjobswhereyourmatch3: "your own custom alerts.",
    emailsreceived: "Emails received as system alerts are not",
    emailsreceived1: "marketing and cannot be unsubscribed from.",
    emailsreceived2: "Rather hop onto your dashboard",
    emailsreceived3: "and toggle off alerts.",
  },

  promoter: {
    topCornorText: "share to earn",
    headingText: "Crayon Bounty",
    headingText2: "Share to",
    shareToInfo: "Select the platform you’d like to share this job to:",
    craybotInfo:
      "Crayon Bounty allows you to leverage your social network and share this opportunity with friends and colleagues. If they sign-up and land a job, ANY job, not just this one, you earn the assigned bounty on that job!",
    text1:
      "The amount below depends on the level offered by the employer, or by Crayon if worked as a Crayon Recruit role.",
    text2: "The more you share, the higher your chances of earning the bounty!",
    text3:
      "Craybot thinks you’re a strong match for this role! Apply while it’s still available.",
    share: "share",
    back: "back",
    referafriend: "Refer a friend",
    jobsharereferrals: "job share referrals",
    referraljobstatus: "referral job status",
  },

  popupBoxText: {
    postIncognito:
      "This employer is a bit shy and has chosen to go incognito with this job posting. All shall be revealed if you are shortlisted for the job.",
    historyLogs: "History Logs",
    createandadd: "create and link",
    cancel: "cancel",
    addtojob: "add to job",
    addtopool: "update linked pools",
    linkedJob: "Linked job",
    linkedJobList: "Below is the list of all linked job for this pool",
    linkAJob:
      "Link a job from a job you have already posted and only shortlisted candidate will be added into pool",
  },

  alertMessage: {
    alreadyverified: "You are already verified",
    logoUploaded: "Logo uploaded successfully",
    verify:
      "Your account is yet not verified. Please check your email inbox and if you didn't found verification email then you can",
    emailVerify: "Email verify successfully",
    passwordreset: "Password reset successfully",
    newPassword: "New password created successfully",
    emailsent: "Email sent successfully",
    companydelete: "Company deleted successfully",
    userstatuschange: "User status changed successfully",
    candidatesubmittedsuccessfully: "Candidate submitted successfully",
    uploadCV: "Upload CV",
    Jobaddedtopoolsuccessfully: "Job added to pool successfully",
    Talentaddedtopoolsuccessfully: "Talent added to pool successfully",
    Joblinkedsuccessfully: "Job linked successfully",
    Loginasemployertoaccess: "Login as employer to access",
    error: "ERROR",
    Companydataupdatedsuccessfully: "Company data updated successfully",
    Employerdataupdatedsuccessfully: "Employer data updated successfully",
    Jobpostedsuccessfully: "Job posted successfully",
    Jobdataupdatedsuccessfully: "Job data updated successfully",
    Talentdataupdatedsuccessfully: "Talent data updated successfully",
    Companyfollowed: "Company followed",
    Companyunfollowed: "Company unfollowed",
    Videosizeshouldbelessthan100MB: "Video size should be less than 100MB",
    CompanylogouploadedSuccessfully: "Company logo uploaded Successfully!",
    Imageformatnotsupported: "Image format not supported",
    Permissionchangedsuccessfully: "Permission changed successfully",
    Jobaccessremovedsuccessfully: "Job access removed successfully",
    Memberremoved: "Member removed",
    CultureDataaddedsuccessfully: "Culture Data added successfully!",
    Fillthepreviousstepsfirst: "Fill the previous steps first",
    PrimaryandShadowPersonalityshouldnotbesimilar:
      "Primary and Shadow Personality should not be similar",
    Youcantaddmorethan5traits: "You can't add more than 5 traits!!",
    VideouploadedSuccessfully: "Video uploaded Successfully!",
    FailedtouploadVideo: "Failed to upload Video",
    SpecuploadedSuccessfully: "Spec uploaded Successfully!",
    ProfilepicuploadedSuccessfully: "Profile pic uploaded Successfully!",
    subScription:
      "You've clicked on a premium feature! Currently, you don't have the necessary subscription to access this. Please reach out to your administrator to upgrade your subscription and unlock this talent-enhancing feature.",
    subScriptionHeading: "🔒 Upgrade Required for Access",
  },

  login: {
    login: "Login",
    resetPassword: "reset password",
    findWork: "find work",
    findTalent: "find talent",
    talent: "talent",
    title1: " sourcing and management",
    done: "done",
    fast: " fast,",
    title2: " affordably, efficiently",
    viewPlans: "view plans",
    signUp: "Sign up",
    firstName: "first name",
    lastName: "last name",
    emailAddrees: "email address",
    password: "password",
    newPassword: "newPassword",
    contact: "contact number",
    createNewPassword: "Create new password",
    // signUpLabel:'By signing up, I agree to be bound by Crayon’s Terms of Service and Privacy Policy.',
    s1: "By signing up, I agree to be bound by Crayon’s  ",
    s2: "  Terms of Service ",
    s3: " and ",
    s4: "  Privacy Policy",
    letsGo: "let's Go",
    cancel: "cancel",
    // candidate: 'candidate'
  },
  topBar: {
    login: "login",
    join: "join Crayon",
    logout: "logout",
    talent: "talent",
    lite: "lite",
    upgrade: "upgrade",
    myCrayon: "my crayon",
  },
  searchBar: {
    findBiz: "find a biz",
    search: "search",
    filters: "filters",
    letsGo: "let's go!",
    jobTitle: "job title(s)",
    location: "location(s)",
    skills: "skills",
    region: "region(s)",
    experience: "experience",
    salary: "salary",
    and: "and",
    or: "or",
    searchPlaceholder: "Begin your search here…",
    findWork: "find work",
    findTalent: "find talent",
  },
  jobs: {
    searchPlaceholder: "Begin your search for work here...",
    noData: "No Jobs Found.",
  },
  talent: {
    searchPlaceholder: "Begin your search for talent here...",
    noData: "No Talent Data Found",
  },
  myJobs: {
    searchPlaceholder: "search my jobs...",
  },
  jobCard: {
    thisisa: "This is a Crayon",
    job: "job",
    recruit: "recruit",
    lite: "lite jobs",
    matchnow: "match",
    review: "review",
    tech: "tech",
    fullTime: "full-time",
    remote: "remote",
    apply: "apply",
    matchMe: "match me",
    earn$: "earn $",
    challengers: "challengers",
    contemplators: "contemplators",
    detailed: "detailed",
    marketing: "marketing",
    operations: "operations",
    manage: "manage",
  },
  talentCard: {
    shortlist: "shortlist",
    active: "active",
    inDemand: "in demand",
    tech: "tech",
    fullTime: "full-time",
    remote: "remote",
    matchMe: "match me",
    earn$: "earn $",
    challengers: "challengers",
    contemplators: "contemplators",
    detailed: "detailed",
  },
  dialog: {
    close: "close",
  },
  submitCandidate: {
    uploadCandidateCV: "Upload candidate CV",
    submitnewcandidate: "Submit new candidate",
    searchMyTalent: "search My Talent",
    addacandidate: "Add a candidate:",
    firstname: "first name",
    lastname: "last name",
    email: "email address",
    contact: "contact number",
    text: "This candidate is aware of the submission and consents to you putting them forward for this role (they will be notified and prompted to complete their Crayon profile)",
  },
  manageJob: {
    itmighttakesometime:
      "It might take sometime to refresh all the percentage as we are running our algorithm in background.",
    trackTalent: "track talent",
    title: "Lead Developer",
    recruit: "recruit",
    review: "review",
    tech: "tech",
    fullTime: "full-time",
    talentText: "Talent",
    remote: "remote",
    manage: "manage",
    edit: "edit",
    close: "close",
    challengers: "challengers",
    contemplators: "contemplators",
    detailed: "detailed",
    talentBtn: "talent",
    editJob: "edit job",
    pauseJob: "pause job",
    closeJob: "close job",
    duplicateJob: "duplicate job",
    reactivateJob: "reactivate job",
  },
  teamMenu: {
    label: "team",
    title: "Who has the rights…",
  },
  draggableCard: {
    videoApp: "video app",
    qAnda: "Q&A",
    cv: "CV",
    crayonButton: "crayon cam",
    fullCV: "full CV",
    portfolio: "portfolio",
    crayonInterview: "crayon interview",
    history: "history",
    requestInterview: "request interview",
    chat: "chat",
    challenger: "challenger",
    collaborator: "collaborator",
    matchColumnQA:
      "These candidates are matched by our system and therefore no answers available.",
  },
  buildSearch: {
    title: "Build a search",
    and: "and",
    update: "update",
    approved: "approved",
    clearAll: "clear all",
    jobPlacements: "Search or add job title",
    industryPlacement: "Select or add the preferred industry",
    companyPlacement: "Select or add the preferred company",
    skillPlacement: "Select or add the required skills",
    toolsPlacement: "Select or add the required tools",
    qualificationPlacement: "Select or add the required qualification",
    institutionsPlacement: "Select or add the required institutions",
    associationsPlacement: "Select or add the required associations",
    schoolsPlacements: "Select or add the required school",
    townPlaceHolder: "Select or add the required towns",
    nationalityPlaceholder: "Select or add the required nationalities",
    languagePlaceholder: "Select or add the required languages",
  },
  jobTitles: {
    title: "Job titles",
    broad: "broad",
    currentAndPast: "current & past",
    approved: "approved",
  },
  activeJobs: {
    title: "Active jobs",
    interview: "interview",
    copyLink: "copy link",
    viewSpec: "view spec",
    recruit: "recruit",
    interviews: "interviews",
    crayonComfort: "crayon comfort",
    active: "active",
    lite: "lite",
    update: "update",
    industries: "Industries",
    tools: "Tools",
    qualifications: "Qualification(s)",
    institutions: "Institution(s)",
    associations: "Association(s)",
    jobQuestions: "Questions & Answers",
    question1: "Question:",
    question2: "Question 2:",
    question3: "Question 3:",
    manageTalent: "manage talent",
  },
  pendingJobs: {
    selectorsearch:
      "Select or search for an existing pool(s) you’d like to link this job to, or create a new pool. Only shortlisted candidates will be linked. You can also delink this job from existing pools; candidates already within that pool will remain.",
    title: "Pending Jobs",
    paused: "Paused Jobs",
    closed: "Closed Jobs",
    activeJob: "Active Jobs",
    approveJob: "approve job",
    viewspec: "View Spec",
    copylink: "Copy Link",
    uploadedspec: "Uploaded Spec",
    bookbriefing: "Book a briefing",
    managebtn: "manage talent",
    jobquestions: "Questions & Answers",
    answer: "Answer",
    viewbriefing: "View Briefing",
    crayonVitae: "Crayon Vitae",
    resume: "Resume",
    portfolio: "Portfolio",
    active: "active",
    history: "history",
    chat: "chat",
    match: "match",
    addToPool: "add to pool",
    addToJob: "add to job",
    tags: "Tags",
    industries: "Industries",
    tools: "Tools",
    nationalities: "Nationality(ies)",
    qualifications: "Qualifications",
    highestQualification: "Highest Qualification",
    institutions: "Institutions",
    associations: "Associations",
    languages: "Languages",
    nationality: "Nationality",
    school: "School",
    workHistory: "Work History",
    alerts: "Alerts",
    type: "Type(s)",
    region: "Region(s)",
    currency: "Currency(ies)",
    industry: "Industry(ies)",
    salary: "Salary",
    experience: "Experience",
    comments: "comments",
    noData: "No Comments",
    personality: "Personality",
    challenger: "Challenger",
    collaborator: "Collaborator",
    detailed: "detailed",
    adaptable: "adaptable",
    organised: "organised",
    proactive: "proactive",
    thrivesOnStress: "thrives on stress",
  },
  allTalent: {
    addPersonality: "Add Personality",
    title: "All talent",
    cv: "CV",
    crayonVitae: "Crayon Vitae",
    resume: "Resume",
    portfolio: "Portfolio",
    active: "active",
    history: "history",
    chat: "chat",
    match: "match",
    addToPool: "add to pool",
    addToJob: "add to job",
    tags: "Tags",
    industries: "Industries",
    tools: "Tools",
    qualifications: "Qualifications",
    highestQualification: "Highest Qualification",
    institutions: "Institutions",
    associations: "Associations",
    languages: "Languages",
    nationality: "Nationality",
    school: "School",
    workHistory: "Work History",
    alerts: "Alerts",
    type: "Type(s)",
    region: "Region(s)",
    currency: "Currency(ies)",
    industry: "Industry(ies)",
    salary: "Salary",
    experience: "Experience",
    comments: "comments",
    personality: "Personality",
    challenger: "Challenger",
    collaborator: "Collaborator",
    detailed: "detailed",
    adaptable: "adaptable",
    organised: "organised",
    proactive: "proactive",
    thrivesOnStress: "thrives on stress",
  },

  myJobsCandidate: {
    shortlist: "shortlist",
    recruit: "recruit",
    review: "review",
    tech: "tech",
    fullTime: "full-time",
    remote: "remote",
    matchMe: "match me",
    earn$: "earn $",
    challengers: "challengers",
    contemplators: "contemplators",
    detailed: "detailed",
    inOrOut: "in or out: pending",
    status: "status: active",
  },
  myCV: {
    gritscoreInfo:
      "* The Grit Score is an indicative measure of an individual’s tenacity and determination to persevere in the face of adversity and overcome obstacles towards achieving long-term goals. There is no good or bad score as both a high and low value offer unique perspectives.",
    references: "References",
    professionalAssociations: "Professional Associations",
    qualifications: "Qualifications",
    experience: "Experience",
    summary: "Summary",
    gritscore: "Grit Score:",
    traits: "Traits:",
    shadowpersonality: "Shadow personality:",
    primarypersonality: "Primary personality:",
    personality: "Personality",
    salary: "Salary:",
    workculture: "Work culture:",
    worksetup: "Work setup:",
    industries: "Industry(ies):",
    preferences: "Preferences",
    language: "Language(s)",
    nationality: "Nationality(ies)",
    tools: "Tools",
    noticePeriod1: "Notice Period:",
    highestEducation: "Highest Education:",
    internet_access: "Internet access",
    own_equipment: "Own equipment",
    driving_license: "Driver's license",
    own_transport: "Own transport",
    thebasics: "the basics",
    worklife: "work life",
    studylife: "study life",
    reference: "reference",
    curriculamVitae: "CURRICULUM VITAE",
    traditionalCVs:
      "Traditional CVs are so 2000s, but upload your current one anyway. We’ll help you build a new Crayon Vitae where CrayBot can then use this data to match you to your dream job.",
    accreditation: "Accreditation",
    workJobTitle: "Enter your job title",
    crayonVitae: "Crayon Vitae",
    uploadCV: "upload CV",
    scrapeCV: "scrape CV",
    currentJobTitle: "Enter your current job title",
    dreamNextJobTitle: "Enter your ideal next job title",
    preferredIndustries:
      'Enter your preferred industry and press "Tab" to add more',
    skills: 'Enter your skill(s) and press "Tab" to add more',
    yearsWorkExperience: "years work experience",
    1: "notice period",
    highestQualificationLevel: "Select",
    preferredWorkType: "Select",
    preferredSalary: "Select",
    portfolioLink: "Enter a link to your profile",
    companyName: "Select or add the name of the company",
    jobTitle: "what is/was your job title?",
    clientsAwardsAccolades: "clients, awards, accolades",
    startDate: "Start date",
    startDateReq: "Start date*",
    endDate: "End date",
    companyNameLabel: "Company Name",
    companyNameLabelReq: "Company Name*",
    stillCompletingLabel: "Still Completing?",
    institutionPlaceholder: "Select or add the name of the institution",
    qualificationPlaceholder: "Select or add the name of the qualification",
    typeOfqualificationPlaceholder: "Select the type of qualification",
    jobTitleLable: "Job Title",
    jobTitleLableReq: "Job Title*",
    descriptionJobLabel: "Description Incl. clients, awards, accolades",
    descriptionJobLabelOpt:
      "Description Incl. clients, awards, accolades (Optional)",
    latestEducation: "Latest / Current Education",
    previousEducation: "Previous Education",
    basic: "The basics",
    workLife: "Work life",
    studyLife: "Study life",
    preview: "Preview",
    institutionLabel: "Institution",
    institutionLabelReq: "Institution*",
    yearOfCompletionLabel: "Year of Completion",
    yearOfCompletionLabelOpt: "Year of Completion (Optional)",
    yearOfCompletionLabelReq: "Year of Completion*",
    qualificationLabel: "Qualification",
    qualificationLabelReq: "Qualification*",
    typeOfQualificationLabel: "Type of qualification",
    typeOfQualificationLabelReq: "Type of qualification*",
    gradeLabelOpt: "Grade (Optional)",
    gradePlaceholder: "Indicate your aggregate academic results",
    documentLabelOpt: "Document",
    documentPlaceholder: "Upload proof of qualification/transcripts",
    upload: "upload document",
    latestJobLabel: "Latest / Current Job",
    previousJobLabel: "Previous Job",
    mostRecent: "(most recent)",
    clientsAwards: "Add any details you would like to hightlight",
    startDate1: "start date",
    endDate1: "end date",
    school: "school",
    year: "year",
    institution: "institution",
    qualification: "qualification",
    dateCompleted: "date completed",
    professionalDesignation: "professional designation(s)",
    addPortfolio: "+ Upload File",
    quickSave: "save",
    save: "save",
    preferredCurrency: "Select",
    noWorkExp: "no work experience",
    noStudyExp: "no study experience",
    currentJobTitleLabel: "Current Job Title",
    currentJobTitleLabelReq: "Current Job Title*",
    dreamNextJobLabel: "Dream Next Job Title",
    dreamNextJobLabelReq: "Dream Next Job Title",
    industriesLabel: "Industry(ies)",
    industriesLabelReq: "Industry(ies)*",
    skillsLabel: "Skills",
    skillsLabelReq: "Skills*",
    yearsOfExperienceLabel: "How many years of work experience do you have?*",
    noticePeriodLabel: "What's your notice period*",
    qualificationLevelLabel: "Highest qualification Level",
    qualificationLevelLabelReq: "Highest qualification Level*",
    preferedWorkTypeLabel: "Preferred Work Type",
    preferedWorkTypeLabelReq: "Preferred Work Type*",
    preferedWorkSetupReq: "Preferred Work Setup*",
    preferedCurrencyLabel: "Preferred Currency",
    preferedCurrencyLabelReq: "Preferred Currency*",
    requiredSalaryRangeLabel: "Required Salary Range (Monthly rate)",
    requiredSalaryRangeLabel2: "Required Rate Range (Hourly rate)",
    portfolioLabel: "Portfolio",
    workBottonText: "+ experience",
    previousStudyBottonText: "+ study experience",
    highSchoolLabel: "High School / A-Levels",
    schoolName: "School",
    schoolNameReq: "School*",
    schoolPlaceholder: "Select or add the name of the school",
    professionalAssociationLabel:
      "Professional Association(s) / Accreditation(s)",
    professionalAssociationLabelOpt:
      "Professional Association(s) / Accreditation(s) (Optional)",
    associationLabel: "Association",
    associationPlaceholder: "Select or add the name of the association",
    inProgressSwitchLabel: "In Progress?",
    referencePersonNameAndSurnameReq: "Reference Person Name and Surname*",
    contactNumber: "Contact Number*",
  },
  postAJob: {
    saveUpto66:
      "Save up to 66% vs. traditional recruiters. Post a Crayon Recruit job and our team of Talent Partners will go to work on your behalf, scouting the global Crayon database, reviewing incoming applications and headhunting only the best talent in the market. You can request recorded screening interviews and save time on those wasted first meetings.",
    successFee: "Success fees from 5% of annualised CTC.",
    timeismoney: "Time is money. Crayon Recruit saves you both.",
    findingtalent: "Finding talent takes talent!",
    optCandidate:
      "You can opt to have candidates complete a self-recorded video application and can request Crayon to conduct recorded screening interviews. Post as your company, a group company, or one of your clients, or go incognito if you’re feeling a bit shy.",
    makeyourlifeeasier:
      "Crayon Direct will make your life easier, but as our DIY solution, you’ll be flying solo using the platform tools to post a job, filter and assess incoming applicants. Auto-match and move talent through the process via our bespoke applicant tracking system. You’ve got this! But if you get stuck along the way, we’re here to help.",
    coinsperjobunlock: "Free to post. From 10 coins per job unlock.",
    donthirefools: "We give you the tools, so you don’t hire fools.",
    notyourfirstrodeo: "This is not your first rodeo!",
    optCandidate1:
      "You can opt to have candidates complete a self-recorded video application. Post as your company, a group company, or one of your clients, or go incognito if you’re feeling a bit shy.",
    hireonmyown: "I’m ready to hire on my own!",
    yourjobhasbeenposted: "Your job has been posted!",
    crayonTeam: "The Crayon team will review",
    activateIt: "and activate it shortly.",
    letthemagicbegin: "Let the talent sourc(er)ing begin!",
    whatthismeanstome: "R-E-SPEC-T…",
    findout: "find out what this means to me!",
    attachyouroriginalspec: "Attach your original spec (optional)",
    additionalinfo:
      "We’ll use this in case there is additional info we need to add, or to fully populate the job post on your behalf where required.",
    wouldyouliketoattach: "Would you like to attach your original job spec in",
    inCase: "case there is any additional info or",
    requirement: "requirements we should be aware of?",
    title: "Design your next employee",
    saveHours: " Save hours and outsource, or take control and go it alone.",
    workSetupRequirementsOpt: "Work setup requirements*",
    theBasics: "The basics",
    theDetails: "The details",
    next: "Next",
    theCulture: "The culture",
    preferredCurrency: "Select the preferred currency",
    jobTitle: "Select or add the required job title",
    roleType: "Is this a permanent contract or freelance job",
    contemplatorsact_DurationLabel: "Contract Duration",
    contemplatorsact_DurationLabelOpt: "Contract Duration (Optional)",
    associated_industriesLabel: "Associated Industry(ies)",
    associated_industriesLabelReq: "Associated Industry(ies)*",
    displaySalary: "Display salary",
    countryPlaceHolder: "Which country",
    preferredAssociationsLabel: "Preferred Association(s) (Optional)",
    preferredAssociationsLabelReq: "Preferred Association(s)*",
    preferredStartDateReq: "Preferred start date*",
    salaryNegotiable: "Salary negotiable",
    workSetup: "work setup",
    languagesLabel: "Language(s)",
    languagesLabelOpt: "Language(s) (Optional)",
    languagesLabelReq: "Language(s)*",
    languagesPlaceholder: "Select any language(s) required for the job",
    country: "country",
    town: "town",
    skills: "Enter any required skill(s) relating to the job",
    tools: 'Enter any required tool(s) and press "Tab" to add more',
    requiredQualificationLevel:
      "Enter the minimum required qualification level",
    preferredQualification: "Enter any required qualification(s)",
    yearsWorkExperience: "years work experience",
    uploadSpec: "upload spec",
    scrapeSpec: "scrape spec",
    howTheyWillRole: "how they’ll role (role summary)",
    whatTheyWillDo: "what they’ll do (role responsibilities)",
    whatTheyWillNeed: "what they’ll need (role requirements)",
    whenTheyWillBegin: "When they’ll begin (preferred start date)",
    screeningQuestionPara:
      "You can add up to 5 screening questions (The candidate will be requested to answer this these questions as part of their application)",
    cultureAdd: "Culture add",
    preferredDominantPersonality: "preferred dominant personality",
    preferredShadowPersonality: "preferred shadow personality",
    preferredTraits: "preferred traits",
    screeningQuestions: "Screening questions",
    screeningQuestionsReq: "Screening questions*",
    question: "question #",
    quickSave: "quick save",
    save: "Add Spec",
    saveToSpecs: "Save to my specs",
    jobTitleLabel: "Job Title",
    jobTitleLabelReq: "Job Title*",
    roleTypeLabel: "Role Type",
    roleTypeLabelReq: "Role Type*",
    currencyIdLabel: "Currency",
    currencyIdLabelReq: "Currency*",
    indicatedSalaryReq: "Indicated salary/rate (monthly/hourly)*",
    salaryRangeLable: "Salary Range (Monthly rate)",
    salaryRangeLable2: "Rate Range (Hourly rate)",
    countryIdLabel: "Country",
    townIdLabel: "Town",
    townPlaceHolder: "Select the town where the office is based",
    workSetupPlaceholder: "Where will they be required qualification level",
    workSetupLable: "Work Setup",
    workSetupLableReq: "Work Setup*",
    countryReq: "Country*",
    cityTown: "City/Town*",
    toolsLable: "Tools",
    toolsLableReq: "Tools*",
    companyCultureOpt: "Company Culture (Optional)",
    skillsLabel: "Skills",
    skillsLabelReq: "Skills*",
    requiredQualificationLable: "Required Qualification Level",
    requiredQualificationLableReq: "Required Qualification Level*",
    preferredQualificationLabel: "Preferred Qualification(s)",
    preferredQualificationLabelOpt: "Preferred Qualification(s) (Optional)",
    yearsWorkExperienceLabel: "Years Work Experience",
    primaryLabel: "Preffered Primary Personality",
    primaryLabelReq: "Preffered Primary Personality*",
    shadowLabel: "Preffered Shadow Personality",
    shadowLabelReq: "Preffered Shadow Personality*",
    traitsLabel: "Preffered Traits",
    traitsLabelReq: "Preffered Traits*",
    gritScoreLabel: "Ideal Grit Score*",
    addButton: "+ add a question",
    videoLabel: "Application video",
    videoParaLabel: "Require the candidate to complete a video",
    VideoParaNote:
      "(The application process will request the candidate to record a video specific to this role)",
    jobTypeLabel: "Job Type",
    recruitParaLabel:
      "The crayon recruit team will facilate the process on your behalf and present only the most suitable options!",
    cryonRecruitLabel: "Crayon Recruit",
    cryonLiteLabel: "Crayon Lite",
    nationalityOpt: "Nationality(ies) (Optional)",
    nationalityReq: "Nationality(ies)*",
    liteParaLabel:
      "You will be in control of the process from start to finish via your employer dashboard!",
    primary_personality: "Select your ideal primary personality",
    shadow_personality: "Select your ideal shadow/secondary personality",
    traits: "Select 5 preferred personality traits",
    videoDescription:
      "Is there anything specific you’d like the candidates to cover in their video applications?",
    employervideo: "Employer video",
    brief:
      "Will you be adding a briefing video that will be visible to applicants (max 1 minute)?",
    visible:
      "Do you want this video to be visible to only shortlisted candidates?",
    saveandexit: "save and exit",
    recordvideo: "record video",
    postjob: "post job",
    starperformance: "A star performance!",
    deleteupload: "delete or upload your video now.",
    deletebutton: "delete",
    uploadvideo: " upload video",
    jobbio: "Job bio (this will show on the job card)",
    jobsummary: "How they’ll role (job summary)*",
    jobsummary1: "Use this for job bio",
    rolesummarytooltip: "Role summary and bio will be same if turned on",
    roleresponsibility: "What they’ll do (role responsibilities)*",
    rolerequirement: "What they’ll need (role requirements)*",
    thebasics: "the basics",
    thedetails: "the details",
    cultureadd: "culture add",
    crayonBounty: "Crayon Bounty",
    parsejobspec: "parse job spec",
    uploadjobspec: "upload job spec",
    crayonBountyText:
      "Crayon Bounty is a first-of-its-kind incentivisation offering whereby your internal team, external candidates and promoters, as well as our network of recruiter Talent Partners share and work the role on your behalf to source talent and monetise their social networks. You can set the same incentive level across the different users, however, we would recommend a higher amount for recruiters as they are proactively presenting candidates into your job management dashboard.",
    crayonBountyText1:
      "If you hire someone referred via the Bounty system, Crayon will collect the indicated amount from you and pay over the reward to the promoter, candidate or Talent Partner (nett of an admin fee).",
    crayonBountyText2: "Use one singe Bounty amount",
    teamBountyamount: "Select your internal team Bounty amount",
    externalBountyamount: "Select your external Bounty amount",
    partnerBountyamount: "Select your recruiter Talent Partner amount",
    suggestsInternal: "(CrayBot suggests:",
    suggestsInternal1: "R5,000",
    suggestsInternal2: ")",
    suggestsInternal3: "R2,500",
    suggestsInternal4: "R10,000",
    noBountyonthis: "No Bounty on this one, thanks",
    crayBotwouldbeshocked: "(CrayBot would be shocked!)",
  },
  myProfile: {
    deleteProfileText1:
      "I confirm that I wish to delete my account and be immediately logged out.",
    deleteProfileText:
      "We’d be sad to see you go, but we accept that not everything is for life. We hope you enjoyed your time with CrayBot and maybe we’ll see you again soon!",
    settingText:
      "Let’s look under the bonnet. Here you can tweak your profile, take a break from the job search or say goodbye to Crayon, if you really really want to.",
    YourWorkSetup: "Your Work Setup",
    yourCrayonSkinz: "Select your Crayon Skinz",
    PreferredCompanyCulture: "Preffered Company Culture",
    PreferredCompanySize: "Preferred Company Size",
    PreferredCompanyType: "Preferred Company Type",
    Profileinfo: "Profile Info",
    Thebasic: "The Basic",
    Worklife: "Work Life",
    Studylife: "Study Life",
    Reference: "Reference",
    yourSetupOpt: "Your setup (Optional)",
    title: "build my profile",
    nameLabel: "First Name",
    nameLabelRequired: "First Name*",
    surnameLabel: "Surname",
    surnameLabelRequired: "Surname*",
    emailLabel: "Email",
    emailLabelRequired: "Email*",
    myBio: "My Bio",
    myBioRequired: "My Bio*",
    myBioPlace: "Tell us about yourself (max 500 characters)",
    genderLabel: "Gender",
    genderLabelOpt: "Gender",
    contactLabel: "Phone Number",
    contactLabelReq: "Phone Number*",
    birthLabel: "Birth Date",
    birthLabelRequired: "Birth Date*",
    countryRequired: "Country*",
    countryLabel: "Region",
    townLabel: "City/Town",
    townLabelRequired: "City/Town*",
    nationalityLabel: "Nationality(ies)",
    nationalityLabelRequired: "Nationality(ies)*",
    languageLabel: "Language(s)",
    languageLabelRequired: "Language(s)*",
    linkedInLabel: "LinkedIn Profile",
    linkedInLabelRequired: "LinkedIn Profile*",
    passwordChangeLabel: "Change Password",
    passwordChangeLabelOpt: "Change Password",
    idPassportLabelOpt: "ID/Passport Number",
    enteryourIDnumber: "Enter your ID or primary passport number",
    disability: "Are you living with a substantially limiting disability?",
    specifyimpairment: "Please specify the category of your impairment",
    providedetails: "If you do have a criminal record, please provide details",
    criminalrecord: "I confirm that I do not have a criminal record",
    uploadId: "upload ID/passport",
    uploadportfolio: "upload portfolio",
    portfolioLabelOpt: "Portfolio",
    addlink: "Add a link to your portfolio/Github and/or attach a document",
    name: "Enter your first name",
    surname: "Enter your surname",
    email: "Enter your email",
    password: "Enter your current password",
    contactNumber: "Enter your contact number",
    country: "Enter your country",
    city: "city/town",
    linkedIn: "Enter your linked In profile link",
    yearsWorkExperience: "years work experience",
    noticePeriod: "notice period",
    highestQualificationLevel: "highest qualification level",
    preferredWorkType: "preferred work type",
    preferredSalary: "preferred salary/rate",
    portfolioLink: "portfolio link",
    resetPassword: "Reset password",
    willingToRelocate: "willing to relocate?",
    save: "save",
    uploadPhoto: "Upload a photo",
    take: "Take a photo",
    nationality: 'Enter your nationality and press "Tab" to add more',
    language: `Enter your language and press "Tab" to add more`,
    crayonSkinz: "crayon skinz",
    seekingWork: "seeking work",
    hideMyProfile: "hide my profile",
    hideMyAge: "hide my age",
    hideMyVideo: "hide my video",
    moveAndScale: "move and scale",
    upload: "upload",
    cancel: "cancel",
    hide_profile: "Hide my profile",
    hide_age: "Hide my age",
    hide_video: "Hide my video",
    myInfo: "my info",
    companyInfo: "company info",
    myPlan: "my plan",
    billing: "billing",
  },
  empMyCompanies: {
    default: "Default",
    dateAdded: "Date added: ",
    about: "About",
    contact: "Contact",
    edit: "edit",
    viewJobs: "view jobs",
    delete: "delete",
    companyDescription: "Company Description",
    contactPerson: "Contact Person",
    contactPersonEmail: "Contact Person Email",
    contactPersonPhone: "Contact Person Phone Number",
    correct: "Correct",
    uploadLogo: "Upload logo",
  },
  empMyProfile: {
    mybadge: "my badges",
    myinfo: "my info",
    myTalent: "my talent",
    inviteMates: "invite mates",
    boostProfile: "boost profile",
    previewPage: "preview page",
    assessment: "assessment",
    deleteProfile: "delete profile",
    addACompany: "add a company",
    reset: "Reset",
    dateJoined: "Date joined:",
    profileCompletion: "Profile Completion",
    uploadlogo: "upload logo",
    uploadAlogo: "upload photo",
    takePhoto: "take photo",
    updateProfile: "Update Profile",
    townPlace: "Select",
    cancelProfile: "Cancel Updation",
    firstName: "First Name",
    firstNameReq: "First Name*",
    firstNamePlace: "Enter your first name",
    surname: "Surname",
    surnameReq: "Surname*",
    surnamePlace: "Enter your Surname",
    email: "Email",
    emailReq: "Email*",
    emailPlace: "Enter your email",
    changePassword: "Change Password",
    changePasswordOpt: "Change Password (Optional)",
    changePasswordPlace: "Enter your current password",
    resetPassword: "Reset Password",
    phoneNumber: "Phone Number",
    phoneNumberReq: "Phone Number*",
    phoneNumberPlace: "Enter your contact number",
    birthDate: "Birth date",
    birthDateReq: "Birth date*",
    countryReq: "Country*",
    birthDatePlace: "dd/mm/yyyy",
    region: "Region",
    linkedinProfile: "LinkedIn Profile",
    linkedinProfileOpt: "LinkedIn Profile (Optional)",
    calendarLinkOpt: "Calendar link (Optional)",
    gender: "Gender",
    male: "Male",
    female: "Female",
    country: "Country",
    select: "select",
    cityTown: "City/Town",
    cityTownReq: "City/Town*",
    crayonSkinz: "Crayon Skinz",
    save: "Save",
    companyInfo: "Company info",
    myInfo: "My info",
    cancel: "cancel",
    companyName: "Company Name",
    companyNameReq: "Company Name*",
    companyNamePlace: "Enter your company name",
    companyWebsite: "Company Website",
    companyWebsiteReq: "Company Website*",
    companyTypeReq: "Company Type*",
    companySizeReq: "Company Size*",
    companyWebsitePlace: "Enter your company website address",
    companyTypePlace: "Select the company type",
    companySizePlace: "Select the size of company",
    companyIndustry: "Company Industry(ies)",
    companyIndustryReq: "Company Industry(ies)*",
    companyCityTown: "Company City/Town",
    companyCountry: "Company Country",
    companyCulture: "Company Culture",
    companyIndustryPlace:
      "Select the industry(ies) associated with your company",
    companyDescription: "Company Description",
    companyDescriptionReq: "Company Description*",
    companyDescriptionPlace: "Add a short description of your company",
    moveAndScale: "move and scale",
    upload: "upload",
  },
  empMyCam: {
    posted: "posted",
    whatsJobStatus: "The clock is ticking…",
    whoCanAccess: "Who has the rights…",
    whoCanAccessText:
      "Below is a list of your team members that can view, edit and manage this job. Selected external recruiters can submit candidates to this job.",
    viewVideos: "view videos",
    letsKeepItReel: "Let’s keep it reels!",
    letsKeepItReelText:
      "You can include a video specific to each job. Here you will get to highlight the key requirements of the job, what sort of candidate should apply and any nuances not captured within the job spec.",
    letsKeepItReelText1:
      "Make sure to keep it relevant to the particular job and rather highlight your overall business via the company video.",
    showcaseYourBiz: "Showcase your biz!",
    showcaseYourBizText:
      "Give us your company elevator pitch! Record or upload a short intro video, highlighting the nature of your business, what makes it great to work at, growth plans, company culture, etc.",
    showcaseYourBizText1:
      "Adding your company video increases your chances of attracting top talent and is often the first thing looked at by candidates!",
    leftBoxText: "Lights, camera, action!",
    leftBoxText1: "It’s selfie time. Push record and let us get to know you!",
    companyVideo: "company video",
    starOfTheShow: "You’re the star of the show!",
    oscarGoesTo: "And the Oscar goes to…",
    addVideoText:
      "Give us your company elevator pitch! Record or upload a short intro video, highlighting the nature of your business, what makes it great to work at, company culture, etc.",
    addVideoText1:
      "Adding your company video increases your chances of attracting top talent and is often the first thing looked at by candidates!",
    recordvideo: "record video",
    uploadvideo: "upload video",
    publishVideoText:
      "Your video has been successfully recorded. Hollywood awaits!",
    publishVideoText1: "Review, re-record or upload a new video below.",
    jobVideoBriefBoxText:
      "…to post a job! You’ll first need to post a job before being able to record a job video.",
    jobVideoBriefBoxText1:
      "Once you’ve done that, the job card(s) will appear here instead of CrayBot and you will be able to add a recording per job.",
    briefBoxText:
      " You can include a video specific to each job. Here you will get to highlight the key requirements of the job, what sort of candidate should apply and any nuances not captured within the job spec.",
    briefBoxText1:
      "Make sure to keep it relevant to the particular job and rather highlight your overall business via the company video.",
    counterDialogVideo: "video",
    getReady: "Get ready…",
    recordingStart: "Recording starts after the countdown!",
    cancelRecording: "cancel recording",
    sureDelete: "Sure you want to delete?",
    confirmDelete: "Please confirm that you want to delete the selected",
    clickToConfirm: "Click to confirm",
    cancel: "cancel",
    continue: "continue",
    crayonCam: "Crayon Cam",
    staring: "Staring:",
    theBrief: "the brief",
    delete: "delete",
    recordNewVideo: "record new video",
    uploadNewVideo: "upload new video",
    video: "video",
    recordingInProgress: "Recording in progress…",
    stopRecording: "Stop Recording",
    stopRecordingText:
      "Stop recording below once you’re done or if you want to start again. Max recording length = 1 minute.",
    jobvideos: "job videos",
    jobvideo: "job video",
    oneJob: "You had one job…",
    rollCamera: "Roll cameras…",
    rollCamera1:
      "This is your chance to attract top talent with a brief summary video of the job.",
    jobVideoText:
      "…to post a job! You’ll first need to post a job before being able to record a job video.",
    jobVideoText1:
      "Once you’ve done that, the job card(s) will appear here instead of CrayBot and you will be able to add a recording per job.",
    postajob: "post a job",
    nailedIt: "You nailed it!",
    completeVideo: "complete video",
    Delete: "Delete",
    updateVideo: "update video",
    successfullyRecorded:
      "Your job video has been successfully recorded. Hollywood awaits!",
    rerecord: "Review, re-record or upload a new video below.",
  },
  empMyTeam: {
    email: "Email",
    addNewUser: "Add a new user",
    addTeamMember: "Add a team member, recruiter or promoter to your team",
    permissionType: "Permission Type",
    company: "Company",
    firstName: "First Name",
    surname: "Surname",
    phoneNumber: "Phone Number",
    tempPassword: "Create Temporary Password",
    cancel: "Cancel",
    sendInvite: "Send Invite",
    inviteSent: "invite sent!",
    joinViaEmail:
      "the new admin or member will receive an invite to join via email.",
    teamStatus: "Back To Team Status",
    addUser: "Add another user",
    jobAccess: "This user has access to the jobs below",
    back: "Back",
    toggleAllJobs: "Toggle all jobs",
    managePermission:
      "Add and manage your team's and recruiter account permissions",
    addNewMember: "add new member",
    downloadCsv: "download CSV",
  },
  empMyJobs: {
    assignedJobs: "assigned jobs",
    myclient: "my client",
    selectcompanies: "Select the company(ies) you want to filter by:",
    profilenotcomplete: "It doesn't look like you've completed",
    profilenotcomplete2: "your profile yet.",
    profilenotcomplete3: "Let's complete profile first!",
    myinfoButton: "myInfo",
    hirelikeaboss: "Hire like a boss!",
    hirelikeaboss2:
      "This is where you can manage the status of your posted jobs.",
    Eachnewjobis: "Each new job is valid for",
    validfor30days: "30 days.",
    extendJob:
      "At the end of the 30-day period, you'll need to extend the job at the cost of",
    coins: "10 coins",
    extensionisvalid: "The extension is valid for a further",
    days: "30 days",
    totaldaysremaining: "total days remaining",
    totalcreditavaliable: "total credit(s) avaliable",
    topupcoins: "top-up coins",
  },
  profileCompletion: {
    validapplication:
      "Applications are valid once yout profile line turns green.",
    remember: "Remember,",
    moreyoucomplete: "the more you complete, the stronger you can compete!",
    myinfo: "my info",
    companyinfo: "company info",
    myplan: " my plan",
    billinginfo: "billing info",
  },
  myTeam: {
    confirm: "Confirm",
    wanttodelete: "Sure you want to delete?",
  },
  adminJobDetail: {
    skills: "Skills",
    jobDescription: "Job Description",
  },
  matchMeBox: {
    avgMatch:
      "CrayBot thinks you’re an average match for this role! Apply if you feel you fit the criteria.",
    strongMatch:
      "CrayBot thinks you’re a strong match for this role! Apply while it’s still available.",
    weakMatch:
      "CrayBot thinks you’re a weak match for this role! This doesn’t mean you can’t apply, but please double-check the job requirements.",
    beta: "find my match",
    matchPer: "My match %",
    talentMatchPer: "Talent match %",
    craybotInfo:
      "CrayBot, our in-house matching guy, uses the information you’ve provided to match your skillset and experience against the requirements of the role.",
    remember: "Remember, the more you complete, the stronger you can compete!",
    viewMore: "view more",
    apply: "apply",
    back: "back",
    shortlist: "shortlist",
    shortlistToJob: "Shortlist to a job",
    selectJob: "Select a job you’d like to shortlist this candidate to:",
    match: "match",
    matchToJob: "Match to a job",
    selectJobEval:
      "Select a job you’d like to evaluate this candidate against:",
    matchAll: "match all jobs",
    runMatch: "run match",
  },
  adminUsers: {
    totalPools: "Total pools",
    totalSearches: "Total searches",
    companiestext:
      "Below is a list of sub-companies or clients linked to this company account.",
    promotertext:
      "Below is a list of Promoters linked to this company account.",
    recruitertext:
      "Below is a list of Recruiters linked to this company account.",
    addcoins: "add coins",
    teammembertext:
      "Below is a list of Team Members and their associated rights linked to this company account.",
    createandadd: "create and add",
    updatepools: "update pools",
    type: "Type",
    industries: "Industries",
    culture: "Cultue",
    companyDescription: "Company Bio",
    plan: "Plan",
    user: "Users",
    coins: "Coins",
    billingInfo: "Billing Info",
    vatNumber: "VAT number:",
    financePerson: "Finance contact person:",
    financeContact: "Finance contact email:",
    creditCard: "Credit card:",
    talentPooltext:
      "Select or search for an existing pool(s) you’d like to explore. You can also remove an existing pool or create a new pool.",
    coinHistoryText:
      "Select or search for an active job you’d like to evaluate this candidate against. You can elect to match against multiple or all active jobs.",
  },
};
export default flatten(en);
