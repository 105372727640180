import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import close from "../../assets/Padding Included/Black_Close.svg";
import notSignedUp from "../../assets/CrayBotIcons/Crayon Craybot Notification-09.svg";
import userProfile from "../../assets/Padding Excluded/Black_Man_Happy.svg";
import ComapnyLogo from "../../assets/Padding Excluded/Crayon_Direct_No_Padding.svg";
import { useTheme } from "@emotion/react";
import Login from "../login/login";
import Signup from "../login/signup";
import {
  ALERT_TYPE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER,
  AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER,
  PUBLIC_TAB_ITEMS,
} from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setAlert } from "../../redux/configSlice";
import ResendMailBox from "../login/ResendMailBox";
import { getDecodedToken } from "../../utils/Common";
import {
  setActiveTab,
  setIsAdmin,
  topBarCurrentTab,
} from "../../redux/login/loginSlice";
import ForgotPasswordConfirmationModal from "../login/ForgotPasswordConfirmationModal";

export default function NotLoggedIn({ onClose, setOpenNotLoggedIn }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  let { pathname } = useLocation();
  const navigate = useNavigate();
  const [uploadedFileName, setUploadedFileName] = useState(null);
  // const [isAdmin, setIsAdmin] = useState(false);
  const [currentTabs, setcurrentTabs] = useState(PUBLIC_TAB_ITEMS);
  // const [activeTab, setActiveTab] = useState(pathname.slice(1));
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("token"))
  );
  const [resendMailBox, setResendMailBox] = useState(false);
  const [email, setEmail] = useState("");
  const [isForgotPassword, setIsForgotPassowrd] = useState(false);

  const onHandleClose = () => {
    setOpenLoginDialog(false);
    setShowSignup(false);
  };

  // const onHandleLogin = async (payload) => {
  //   setShowLogin(false);

  //   // const user = payload?.data?.role_id;

  //   const user = parseInt(getDecodedToken(payload?.token)?.data?.role_id);
  //   localStorage.setItem("token", payload?.token);
  //   onHandleClose();

  //   const jwt = localStorage?.getItem("token");
  //   const parts = jwt?.split(".");
  //   if (parts?.length !== 3) {
  //     throw new Error("Invalid JWT");
  //   }
  //   const encodedPayload = parts[1];
  //   const decodedPayload = atob(encodedPayload);
  //   const payloadData = JSON.parse(decodedPayload);
  //   const profileCompletion = payloadData?.data?.profile_percent_complete;
  //   const isNavigate = payloadData?.data?.employerProfileFlag;
  //   let tabs;
  //   if (user === 1) {
  //     setIsAdmin(true);
  //     navigate("/admin/dashboard", { replace: true });
  //   } else if (user === 4) {
  //     if (profileCompletion === 100) {
  //       tabs = AUTHORIZED_TAB_ITEMS_EMPLOYER;
  //       navigate("/employer/my-jobs", { replace: true });
  //       setActiveTab("/employer/my-jobs");
  //     }else if(isNavigate){
  //       navigate("/employer/my-jobs", { replace: true });
  //       setActiveTab("/employer/my-jobs");
  //     } else {
  //       tabs = AUTHORIZED_TAB_ITEMS_EMPLOYER;
  //       navigate("/employer/my-profile", { replace: true });
  //       setActiveTab("/employer/my-profile");
  //     }
  //   } else {
  //     if (profileCompletion === 0) {
  //       tabs = AUTHORIZED_TAB_ITEMS_CANDIDATE;
  //       navigate("/candidate/my-jobs", { replace: true });
  //       setActiveTab("/candidate/my-jobs");
  //     } else {
  //       tabs = AUTHORIZED_TAB_ITEMS_CANDIDATE;
  //       navigate("/candidate/my-jobs", { replace: true });
  //       setActiveTab("/candidate/my-jobs");
  //     }
  //   }

  //   setcurrentTabs(tabs);
  //   setIsLoggedIn(true);
  //   // dispatch(
  //   //   setAlert({
  //   //     show: true,
  //   //     type: ALERT_TYPE?.SUCCESS,
  //   //     msg: "Login successful",
  //   //   })
  //   // );
  // };
  const onHandleLogin = async (payload) => {
    setShowLogin(false);
    const token = payload?.token;
    const decodedToken = getDecodedToken(token);
    const user = parseInt(decodedToken?.data?.role_id);
    const isPromoter = parseInt(decodedToken?.data?.promoter);
    const profileCompletion = decodedToken?.data?.profile_percent_complete;
    const isNavigate = decodedToken?.data?.employerProfileFlag;

    localStorage.setItem("token", token);
    onHandleClose();

    let tabs;
    if ([1, 2, 8].includes(user)) {
      dispatch(setIsAdmin(true));
      navigate("/admin/adminJobs/active-jobs", { replace: true });
    } else if (user === 4) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_EMPLOYER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/employer/my-jobs", { replace: true });
        dispatch(setActiveTab("/employer/my-jobs"));
      } else {
        navigate("/employer/my-profile", { replace: true });
        dispatch(setActiveTab("/employer/my-profile"));
      }
    } else if (user === 5) {
      tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
      navigate("/promoter/shared-jobs", { replace: true });
      dispatch(setActiveTab("/promoter/shared-jobs"));
    } else if (user === 6) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_RECRUITER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/recruiter/my-jobs", { replace: true });
        dispatch(setActiveTab("/recruiter/my-jobs"));
      } else {
        navigate("/recruiter/my-profile", { replace: true });
        dispatch(setActiveTab("/recruiter/my-profile"));
      }
    } else {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
        : AUTHORIZED_TAB_ITEMS_CANDIDATE;
      navigate("/candidate/my-jobs", { replace: true });
      dispatch(setActiveTab("/candidate/my-jobs"));
    }

    dispatch(topBarCurrentTab(tabs));
    setIsLoggedIn(true);
  };
  const toggleForm = () => {
    setShowLogin((prevState) => !prevState);
    setShowSignup((prevState) => !prevState);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };
  const handleCloseSignup = () => {
    setShowSignup(false);
  };

  const closeForgotPassword = () => {
    setShowSignup(true);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "468px",
        width: "100%",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
          ":hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <Box
          component={"img"}
          src={close}
          sx={{
            border: 2,
            borderRadius: "5px",
            height: 20,
            width: 20,
          }}
        />
      </IconButton>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          component={"img"}
          src={notSignedUp}
          sx={{
            height: "150px",
            width: "150px",
          }}
        />

        <Typography
          sx={{
            height: "68px",
            width: "270px",
            fontSize: "25px",
            fontWeight: theme?.typography?.Bold,
            textAlign: "center",
            margin: "18.83px 0",
          }}
        >
          You haven’t signed up as a candidate
        </Typography>
        <Typography
          sx={{
            height: "69px",
            width: "398px",
            fontSize: "17px",
            fontWeight: theme?.typography?.Bold,
            textAlign: "center",
            marginBottom: "18.83px",
          }}
        >
          CrayBot can only match you once you’ve joined us, added your info and
          completed ‘the basics’ section of your Crayon vitae.
        </Typography>
        <Typography
          sx={{
            height: "69px",
            width: "342px",
            fontSize: "18px",
            fontWeight: theme?.typography?.Regular,
            textAlign: "center",
          }}
        >
          If you already have a profile with Crayon, what are you waiting for,
          login!
        </Typography>
      </Box>

      <Grid
        container
        // padding="0 8px 8px 8px"
        alignItems="center"
        overflow={"hidden"}
        sx={{
          width: "100%",
          borderRadius: "0 0 25px 25px",
          height: "60px",
        }}
      >
        <Button
          variant="contained"
          sx={{
            borderRadius: 0,
            fontSize: "14px",
            fontWeight: "bold",
            whiteSpace: "nowrap",
            height: "100%",
            width: "50%",
            justifyContent: "center",
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
              justifyContent: "end",
              width: "26px",
            },
            padding: "0px !important",
            boxShadow: "none !important",
          }}
          onClick={() => {
            setShowLogin(true);
            onClose();
          }}
          color="grayButton600"
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: 25,
                width: 25,
                padding: 0,
              }}
              src={userProfile}
            />
          }
        >
          login
        </Button>

        <Button
          variant="contained"
          sx={{
            borderRadius: "0 0 25px 0",
            width: "50%",
            height: "100%",
            fontSize: "14px",
            fontWeight: "bold",
            ":hover": {
              boxShadow: 0,
            },
            ".MuiButton-startIcon": {
              marginRight: "0px !important",
              marginLeft: 0,
            },
            padding: "0px !important",
            boxShadow: "none !important",
            gap: 1,
          }}
          onClick={() => {
            setShowSignup(true);
            onClose();
          }}
          color="applyButton"
          //   onClick={applyFunction}
          startIcon={
            <Box
              component={"img"}
              sx={{
                height: 25,
                width: 25,
              }}
              src={ComapnyLogo}
            />
          }
        >
          join Crayon
        </Button>
      </Grid>

      <Login
        handleLogin={onHandleLogin}
        openFunc={showLogin}
        toggleForm={toggleForm}
        closeFunc={handleCloseLogin}
        handleOpenLogin={setShowLogin}
        handleOpenSignUp={closeForgotPassword}
        handleForgotPassword={closeForgotPassword}
        setResendMailBox={setResendMailBox}
        setEmail={setEmail}
        uploadedFileName={uploadedFileName}
        handleConfirmPasswordChange={() => setIsForgotPassowrd(true)}
      />

      <ResendMailBox
        setResendMailBox={setResendMailBox}
        email={email}
        open={resendMailBox}
        handleClose={() => setResendMailBox(false)}
      />

      <Signup
        onDialogClose={onHandleClose}
        toggleForm={toggleForm}
        openFunc={showSignup}
        closeFunc={handleCloseSignup}
        handleOpenSignUp={setShowSignup}
        uploadedFileName={uploadedFileName}
      />
      {isForgotPassword && (
        <ForgotPasswordConfirmationModal
          openFunc={isForgotPassword}
          closeFunc={() => setIsForgotPassowrd(false)}
        />
      )}
    </Box>
  );
}
