import React, { useEffect, useRef, useState } from "react";
import {
  getAllQuestionsWithoutLogin,
  postAnswers,
  postAnswersWithoutLogin,
} from "../../../redux/guest/getQuestions";
import { useDispatch } from "react-redux";
import {
  ALERT_TYPE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE,
  AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER,
  AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER,
} from "../../../utils/Constants";
import {
  Box,
  Button,
  Modal,
  ModalRoot,
  TextField,
  ThemeProvider,
  Typography,
  useTheme,
} from "@mui/material";
import locale from "../../../i18n/locale";
import { useLocation, useNavigate } from "react-router-dom";
import { setAlert } from "../../../redux/configSlice";
import CustomDialog from "../../common/CustomDialog";
import {
  uploadCv,
  uploadCvWithoutLogin,
} from "../../../redux/candidate/myCvSlice";
import jwt_decode from "jwt-decode";
import {
  getDecodedToken,
  getToken,
  setLocalStorage,
} from "../../../utils/Common";
import Signup from "../../login/signup";
import Login from "../../login/login";
import redTriangleSmile from "../../../assets/Characters/Red_Triangle_Smiling.svg";
import CVCharacterIcon from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-05.svg";
import CrayonUploadCV from "../../../assets/Characters/CrayBotIcons/Crayon_UploadCv.svg";
import CrayonSelfie from "../../../assets/Characters/CrayBotIcons/Crayon_selfie.svg";
import CrayonLogin from "../../../assets/TopBarIcons/crayon_login.svg";
import CrayonJoin from "../../../assets/TopBarIcons/crayon_join.svg";
import UpdateCVCharacterIcon from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-06.svg";
import SelfieUploadIcon from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-09.svg";
import FinalStepIcon from "../../../assets/Characters/CrayBotIcons/Crayon Craybot Notification-04.svg";
import moreJobsIcon from "../../../assets/HomePage/Crayon_Direct.svg";
import blueSmileFace from "../../../assets/Characters/Blue_Half_Circle_Smile.svg";
import redSkateboarder from "../../../assets/Characters/Red_Skateboarder.svg";
import yellowChilled from "../../../assets/Characters/Yellow_Chilled.svg";
import redDiamondClose from "../../../assets/Characters/Red_Diamond_Close_Eyes.svg";
import greenTriangleThinking from "../../../assets/Characters/Green_Triangle_Thinking.svg";
import userProfile from "../../../assets/Padding Excluded/Black_Man_Happy.svg";
import ComapnyLogo from "../../../assets/Padding Excluded/Crayon_Direct_No_Padding.svg";
import cameraIcon from "../../../assets/Padding Excluded/WhiteRecord.svg";
import { ApplyJobsPopupTheme } from "../../../utils/Theme";
import RecordingInProgress from "./RecordingInProgress";
import {
  uploadJobCamVideo,
  uploadJobCamVideoWithOutLogin,
} from "../../../redux/candidate/candidateJobs";
import { appliedFlagUpdate } from "../../../redux/guest/jobsSlice";
import ResendMailBox from "../../login/ResendMailBox";
import CloseIcon from "../../common/CloseIcon";

import oneImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_1.svg";
import twoImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_2.svg";
import threeImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_3.svg";
import fourImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_4.svg";
import fiveImage from "../../../assets/CrayBotIcons/Crayon Craybot QA Character_5.svg";
import useViewport from "../../common/ViewPort";
import {
  setActiveTab,
  setIsAdmin,
  topBarCurrentTab,
} from "../../../redux/login/loginSlice";
import ForgotPasswordConfirmationModal from "../../login/ForgotPasswordConfirmationModal";
import JobVideoUpload from "../../candidate/myCam/JobVideUpload";

const questionImages = [oneImage, twoImage, threeImage, fourImage, fiveImage];
export default function ApplyJobs({
  jobs,
  jobId,
  setopenApplyJobDialog,
  setChangedStatus,
  setIsApplied,
  jobVideoRequired,
  openApplyJobDialog,
  setActiveStep
}) {

  // const fileAccept = "application/pdf, application/doc, application/docx";
  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  const hiddenFileInput = useRef(null);
  const { width } = useViewport();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    // pyb: 4,
    boxShadow: "none !important",
    // border: "1px solid black !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };
  const i18n = locale.en;
  const dispatch = useDispatch();
  const theme = useTheme();
  const history = useNavigate();
  const navigate = useNavigate();
  const [answers, setAnswers] = useState([]);
  const [cvName, setCvName] = useState("No file chosen");
  const [nextButton, setNextButton] = useState(false);
  const [openQandADialog, setopenQandADialog] = useState(false);
  const [openCVDialog, setopenCVDialog] = useState(false);
  const [openLoggedInCVDialog, setOpenLoggedInCVDialog] = useState(false);
  const [openProfilePicDialog, setOpenProfilePicDialog] = useState(false);
  const [openSignUpLogin, setOpenSignUpLogin] = useState(false);
  const [openSignUpDialog, setOpenSignUpDialog] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [userID, setuserID] = useState();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questions, setQuestions] = useState([]);
  const [openVideoRecording, setOpenVideoRecording] = useState(false);
  const [cvAvaliable, setCvAvaliable] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [recorderVideoData, setRecordedVideoData] = useState(null);
  const [isVideoUploaded, setIsVideoUploaded] = useState(false);
  const [resendMailBox, setResendMailBox] = useState(false);
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(openApplyJobDialog);
  const [isForgotPassword, setIsForgotPassowrd] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  let decodedToken = getDecodedToken(getToken());

  // new video upload functionality:
  const location = useLocation();
  const [openRecordDialog, setOpenRecordDialog] = useState(false);
  const [steps, setSteps] = useState();
  const VideoRef = useRef(null);

  const handleOpenRecordDialog = () => {
    setSteps(1);
    setOpenRecordDialog((prevState) => !prevState);
  };
  const handleVideoUploaded = (uploadedVideoLink) => {
    setVideoLink(uploadedVideoLink);
    VideoRef?.current?.load();
  };

  const onHandleClose = () => {
    setopenCVDialog(false);
  };
  const onHandleLoggedInCvClose = () => {
    setOpenLoggedInCVDialog(false);
  };
  const onHandleCloseSingUpLogin = () => {
    setOpenSignUpLogin(false);
    setopenApplyJobDialog(false);
  };

  const handleAnswersUpload = async () => {
    const { payload } = await dispatch(
      postAnswersWithoutLogin({
        data: answers,
        job_id: questions?.at(0)?.job_id,
      })
    );
    if (payload?.status === "sccess") {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.SUCCESS,
      //     msg: "Applied Successfully",
      //   })
      // );
    }
  };
  const handleLogin = () => {
    setopenCVDialog(false);
    setOpenLoggedInCVDialog(false);
    setOpenProfilePicDialog(false);
    setOpenSignUpLogin(false);
    setOpenLoginDialog(true);
  };

  const handleSignup = () => {
    setOpenSignUpLogin(false);
    setOpenSignUpDialog(true);
  };

  const onHandleLogin = async (payload) => {
    const token = payload?.token;
    const decodedToken = getDecodedToken(token);
    const user = parseInt(decodedToken?.data?.role_id);
    const isPromoter = parseInt(decodedToken?.data?.promoter);
    const profileCompletion = decodedToken?.data?.profile_percent_complete;
    const isNavigate = decodedToken?.data?.employerProfileFlag;

    localStorage.setItem("token", token);
    onHandleClose();

    let tabs;
    if ([1, 2, 8].includes(user)) {
      dispatch(setIsAdmin(true));
      navigate("/admin/adminJobs/active-jobs", { replace: true });
    } else if (user === 4) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_EMPLOYER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/employer/my-jobs", { replace: true });
        dispatch(setActiveTab("/employer/my-jobs"));
      } else {
        navigate("/employer/my-profile", { replace: true });
        dispatch(setActiveTab("/employer/my-profile"));
      }
    } else if (user === 5) {
      tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
      navigate("/promoter/shared-jobs", { replace: true });
      dispatch(setActiveTab("/promoter/shared-jobs"));
    } else if (user === 6) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_RECRUITER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/recruiter/my-jobs", { replace: true });
        dispatch(setActiveTab("/recruiter/my-jobs"));
      } else {
        navigate("/recruiter/my-profile", { replace: true });
        dispatch(setActiveTab("/recruiter/my-profile"));
      }
    } else {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
        : AUTHORIZED_TAB_ITEMS_CANDIDATE;
      navigate("/candidate/my-jobs", { replace: true });
      dispatch(setActiveTab("/candidate/my-jobs"));
    }

    dispatch(topBarCurrentTab(tabs));
  };

  const onHandleCloseLogin = () => {
    setOpenLoginDialog(false);
  };

  const onHandleCloseSignUp = () => {
    setOpenSignUpDialog(false);
  };
  const uploadVideo = async (file) => {
    const formData = new FormData();
    if (decodedToken !== undefined) {
      formData.append("jobcam", file);
      formData.append("job_id", jobId);
    } else {
      formData.append("tempvideo", file);
      formData.append("jobs_user_id", userID);
    }
    try {
      const { payload } = await dispatch(
        decodedToken !== undefined
          ? uploadJobCamVideo(formData)
          : uploadJobCamVideoWithOutLogin(formData)
      );
      if (payload?.status === "success") {
        if (decodedToken === undefined) {
          setIsVideoUploaded(true);
        }
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: "Video uploaded Successfully!",
        //   })
        // );
        setOpenSignUpLogin(true);
        setOpenReviewVideoDialog(false);
      } else {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.ERROR,
        //     msg: "Failed to upload Video",
        //   })
        // );
      }
    } catch (error) {
      // dispatch(setAlert({ show: true }));
    }
  };

  const createFileFromBlob = () => {
    const myFile = new File([recorderVideoData], "videoFile.webm", {
      type: recorderVideoData?.type,
    });
    uploadVideo(myFile);
    // decodedToken?.data?.role_id === 3
    //   ? uploadVideo(myFile)
    //   : dispatch(
    //       setAlert({
    //         show: true,
    //         type: ALERT_TYPE?.ERROR,
    //         msg: "Login as candidate to upload Video",
    //       })
    //     );
  };

  const onHandleVideoRecording = () => {
    setOpenVideoRecording(false);
  };

  const handleVideoData = (data) => {
    setOpenVideoRecording(false);
    setOpenReviewVideoDialog(true);
    setRecordedVideoData(data);
    const link = URL.createObjectURL(data);
    setVideoLink(link);
  };

  const handleUploadProfilePicLater = () => {
    setOpenProfilePicDialog(false);
    setOpenSignUpLogin(true);
  };

  const handleUploadCVLater = () => {
    setopenCVDialog(false);
    setOpenProfilePicDialog(jobVideoRequired ? true : false);
    setOpenSignUpLogin(jobVideoRequired ? false : true);
  };

  const handleLoggedInUploadCVLater = () => {
    setOpenLoggedInCVDialog(false);
    setOpenProfilePicDialog(jobVideoRequired ? true : false);
    setOpenSignUpLogin(jobVideoRequired ? false : true);
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    formData.append(
      decodedToken?.data?.role_id === undefined ? "tempcv" : "cv",
      event?.target?.files?.[0]
    );
    formData.append("jobs_user_id", userID);
    try {
      const { payload } = await dispatch(
        decodedToken?.data?.role_id === 3
          ? uploadCv(formData)
          : uploadCvWithoutLogin(formData)
      );
      if (payload?.status === "success") {
        if (setUploadedFileName) {
          setUploadedFileName(payload?.fileName);
        }
        decodedToken?.data?.role_id === 3
          ? setOpenLoggedInCVDialog(false)
          : setopenCVDialog(false);
        setOpenProfilePicDialog(jobVideoRequired ? true : false);
        setOpenSignUpLogin(jobVideoRequired ? false : true);
        // setOpenSignUpLogin(true);
        setCvName(event?.target?.files?.[0]?.name);
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: "CV uploaded Successfully!",
        //   })
        // );
      } else {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.ERROR,
        //     msg: payload?.message,
        //   })
        // );
      }
    } catch (error) {
      // dispatch(setAlert({ show: true }));
    }
  };

  const handleFileClick = () => {
    hiddenFileInput.current.click();
  };

  const handleApply = async () => {
    setopenQandADialog(false);
    try {
      const { payload } = await dispatch(
        decodedToken?.data?.role_id === 3
          ? postAnswers({
              data: answers,
              job_id: questions?.at(0)?.job_id || jobId,
            })
          : postAnswersWithoutLogin({
              data: answers,
              job_id: questions?.at(0)?.job_id || jobId,
            })
      );
      dispatch(appliedFlagUpdate({ jobId: jobId, applidflag: true }));
      if (payload?.status === "sccess") {
        if (decodedToken?.data?.role_id === 3) {
          setCvAvaliable(payload?.data?.isUserCvUploaded);
          setOpenLoggedInCVDialog(true);
          // setopenApplyJobDialog(false);
        } else {
          setuserID(payload?.jobs_user_id);
          setopenCVDialog(true);
        }
        setQuestions([]);
        !decodedToken && setLocalStorage("job_id", payload?.job_id);
        !decodedToken && setLocalStorage("jobs_user_id", payload?.jobs_user_id);
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.SUCCESS,
        //     msg: "Applied Successfully",
        //   })
        // );
        if (setChangedStatus) {
          setChangedStatus("i like this");
        }
        if (setIsApplied) {
          setIsApplied(true);
        }
      } else {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.ERROR,
        //     msg: payload?.message,
        //   })
        // );
      }
    } catch (error) {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "",
      //   })
      // );
    }
  };

  const nextQuestion = () => {
    setNextButton(false);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // Submit or perform any necessary action here
      // For example, you can reset the dialog state to show the first question again
      setCurrentQuestionIndex(0);
      setAnswers([]);
      // setopenQandADialog(false);
      handleApply();
      // setopenCVDialog(true);
    }
  };

  const handleChange = (event, id) => {
    setNextButton(event.target.value.trim() !== "");
    if (answers?.find((item) => item?.question_id === id)) {
      setAnswers(
        answers?.map((item) =>
          item?.question_id === id
            ? { ...item, answer: event.target.value }
            : item
        )
      );
    } else {
      setAnswers([...answers, { question_id: id, answer: event.target.value }]);
    }
  };

  const handleQuestions = () => {
    setAnswers([]);
    setNextButton(false);
    setopenQandADialog(false);
    setCurrentQuestionIndex(0);
    setopenApplyJobDialog(false);
    setOpen(false);
  };

  const getquestionswithoutlogin = async () => {
    const { payload } = await dispatch(getAllQuestionsWithoutLogin(jobId));
    if (payload?.status === "success") {
      if (payload?.data?.length === 0) {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.ERROR,
        //     msg: "NO Questions avaliable",
        //   })
        // );
        handleApply();
      } else {
        setQuestions(payload?.data);
      }
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: payload?.message,
        })
      );
    }
  };

  const handleQandAContinue = () => {
    getquestionswithoutlogin();
    // setopenApplyJobDialog(false);
    setOpen(false);
    setopenQandADialog(true);
  };

  const toggleForm = () => {
    setOpenLoginDialog((prevState) => !prevState);
    setOpenSignUpDialog((prevState) => !prevState);
  };

  return (
    <ThemeProvider theme={ApplyJobsPopupTheme}>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setopenApplyJobDialog(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              // gap: 3,
              width: "100%",
            }}
          >
            <CloseIcon
              top="15px"
              right="15px"
              onClick={() => setopenApplyJobDialog(false)}
            />
            <Box
              sx={{
                padding: { xs: "0 10px", tablet: "0 30px" },
              }}
            >
              <Box
                component={"img"}
                src={redTriangleSmile}
                sx={{ width: 136, height: 125, marginTop: "20px" }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  lineHeight: "27px",
                  marginBottom: "20px",
                }}
              >
                Let's get you started
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.base,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "20px",
                  // paddingX: 3,
                }}
              >
                We’ll kick-off with a quick Q&A session,
                <br /> followed by some admin.
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                  marginBottom: "20px",
                  // paddingX: 3,
                }}
              >
                {width < 768 ? (
                  <>
                    {`If you’re new here: apply > Q&A > sign-up >`}
                    <br /> {`complete profile = valid application!`}
                    <br />{" "}
                    {`If you’re already with us (with a complete profile):`}
                    <br /> {`apply > Q&A = valid application!`}
                  </>
                ) : (
                  <>
                    {`If you’re new here: apply > Q&A > sign-up > complete`}
                    <br /> {`profile = valid application!`}
                    <br />{" "}
                    {`If you’re already with us (with a complete profile): apply > `}
                    <br /> {`Q&A = valid application!`}
                  </>
                )}
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="letsgoButton"
              sx={{
                borderRadius: "0 0 25px 25px",
                height: "60px",
              }}
              onClick={handleQandAContinue}
            >
              let's go
            </Button>
          </Box>
        </Box>
      </Modal>
      {questions?.map((question, index) => {
        const isCurrentQuestion = index === currentQuestionIndex;
        return (
          <Modal
            key={question.question_id}
            open={openQandADialog && isCurrentQuestion}
            onClose={() => {
              handleQuestions();
              setopenApplyJobDialog(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <CloseIcon
                top="15px"
                right="15px"
                onClick={() => {
                  handleQuestions();
                  setopenApplyJobDialog(false);
                }}
              />
              <Box
                sx={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: { xs: "0 10px", tablet: "0 30px" },
                }}
              >
                <Box
                  component={"img"}
                  src={questionImages[index]}
                  sx={{ height: 125, marginTop: "20px" }}
                />
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.xl,
                    fontWeight: theme.typography.Bold,
                    marginTop: "20px",
                    marginBottom: "20px",
                    lineHeight: "27px",
                    // paddingX: 3,
                  }}
                >
                  A quick Q&A session
                </Typography>
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.base,
                    fontWeight: theme.typography.Bold,
                    marginBottom: "3px",
                    // paddingX: 3,
                  }}
                >
                  Question {currentQuestionIndex + 1}
                </Typography>
                <Box
                  sx={{
                    marginBottom: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: theme.typography.fontSize.sm,
                      fontWeight: theme.typography.Regular,
                      marginBottom: "20px",
                      // paddingX: 3,
                      // mb: 3,
                    }}
                  >
                    {question?.question}
                  </Typography>
                  <TextField
                    value={
                      answers.find(
                        (item) => item?.question_id === question?.question_id
                      )?.answer || ""
                    }
                    id="answer"
                    type="text"
                    rows={3}
                    multiline
                    onChange={(event) =>
                      handleChange(event, question?.question_id)
                    }
                    placeholder={"Enter your answer here..."}
                    sx={{
                      height: "100px !important",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        background: "#fff !important",
                        borderRadius: "10px",
                      },
                    }}
                  />
                </Box>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 0 }}>
                <Button
                  disabled={!nextButton}
                  sx={{
                    boxShadow: 0,
                    fontSize: "12px",
                    width: "100%",
                    height: "60px",
                    borderRadius: "0 0 25px 25px",
                  }}
                  variant="contained"
                  color="nextQuestionButton"
                  onClick={nextQuestion}
                >
                  {/* {i18n["jobCard.apply"]} */}
                  thank you, next
                </Button>
              </Box>
            </Box>
          </Modal>
        );
      })}

      <Modal
        open={openCVDialog}
        onClose={() => {
          onHandleClose();
          setopenApplyJobDialog(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            top="15px"
            right="15px"
            onClick={() => {
              handleQuestions();
              setopenApplyJobDialog(false);
            }}
          />
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                padding: "0 13px 0 14px",
              }}
            >
              <Box
                component={"img"}
                src={CrayonUploadCV}
                sx={{
                  height: 125,
                  marginTop: "20px",
                }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "30px",
                  lineHeight: "27px",
                }}
              >
                Please upload your CV
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.base,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "30px",
                }}
              >
                We’re big on data but if all else fails,
                <br /> we’ll need to review your CV.
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                  marginBottom: "30px",
                }}
              >
                If you’re already on the Crayon platform, and we <br /> have
                your CV, you can simply login to continue.
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "30px",
                }}
              >
                Note: If you exit now, <br /> your application will be
                discarded.
              </Typography>
            </Box>
            <Box
              sx={{
                height: "60px",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="loginButton"
                sx={{
                  borderRadius: "0 0 0 25px",
                  width: `33.333%`,
                  height: "100%",
                  padding: "0px",
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: {
                        sx: "20px",
                        md: "20px",
                        xl: "25px",
                        xxl: "30px",
                      },
                      cursor: "pointer",
                    }}
                    alt="crayon logo"
                    src={CrayonLogin}
                  />
                }
                onClick={handleLogin}
              >
                login
              </Button>
              <Button
                variant="contained"
                color="laterButton"
                sx={{
                  borderRadius: 0,
                  width: `33.33%`,
                  height: "100%",
                  padding: "0px",
                }}
                onClick={handleUploadCVLater}
              >
                I'll do it later
              </Button>
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                required
              />

              <Button
                onClick={handleFileClick}
                variant="contained"
                color="uploadCvButton"
                sx={{
                  borderRadius: "0 0 25px 0",
                  width: `33.33%`,
                  height: "100%",
                  padding: "0px",
                }}
              >
                {i18n["myCV.uploadCV"]}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openLoggedInCVDialog}
        onClose={() => {
          onHandleLoggedInCvClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            top="20px"
            right="20px"
            onClick={() => {
              onHandleLoggedInCvClose();
              setopenApplyJobDialog(false);
            }}
          />
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                padding: "0 13px 0 14px",
              }}
            >
              <Box
                component={"img"}
                src={cvAvaliable ? UpdateCVCharacterIcon : CVCharacterIcon}
                sx={{
                  height: 125,
                  marginTop: "25px",
                  marginBottom: "12px",
                }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "30px",
                  lineHeight: "27px",
                }}
              >
                {cvAvaliable
                  ? "We have your CV, but…"
                  : "Please upload your CV"}
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "20px",
                }}
              >
                {decodedToken?.data?.role_id === undefined ? (
                  <>
                    We’re big on data but if all else fails, <br />
                    we’ll need to review your CV.
                  </>
                ) : decodedToken?.data?.role_id === 3 && cvAvaliable ? (
                  <>
                    …is it up to date? If you have a more <br />
                    recent version, you can upload it now <br />
                    or later via your Crayon dashboard.
                  </>
                ) : (
                  <>
                    We ain’t seeing no CV. We’re big on <br />
                    data but if all else fails, we’ll need to <br /> review your
                    CV.
                  </>
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                  marginBottom: "70px",
                }}
              >
                {decodedToken?.data?.role_id === undefined ? (
                  <>
                    If you’re already on the Crayon platform, and we <br /> have
                    your CV, you can simply login to continue.
                  </>
                ) : decodedToken?.data?.role_id === 3 && cvAvaliable ? (
                  <>
                    If we already have your latest CV, <br />
                    you can simply continue.
                  </>
                ) : (
                  <>
                    You can also do this later via your Crayon <br />
                    dashboard, but why wait.
                  </>
                )}
              </Typography>
            </Box>
            <Box
              sx={{
                height: "60px",
                width: "101%",
                display: "flex",
                flexDirection: cvAvaliable ? "row" : "row-reverse",
              }}
            >
              <input
                accept={fileAccept}
                ref={hiddenFileInput}
                type="file"
                onChange={handleFileChange}
                style={{ display: "none" }}
                required
              />

              <Button
                onClick={handleFileClick}
                variant="contained"
                color={cvAvaliable ? "laterButton" : "updateLaterButton"}
                sx={{
                  borderRadius: cvAvaliable ? "0 0 0 25px" : "0 0 25px 0",
                  width: `50%`,
                  height: "100%",
                }}
              >
                {i18n["myCV.uploadCV"]}
              </Button>
              <Button
                variant="contained"
                color={cvAvaliable ? "uploadCvButton" : "updateCVButton"}
                sx={{
                  borderRadius: cvAvaliable ? "0 0 25px 0" : "0 0 0 25px",
                  width: `50%`,
                  height: "100%",
                }}
                onClick={handleLoggedInUploadCVLater}
              >
                {cvAvaliable ? "skip it, next" : "I’ll do it later"}
              </Button>
              {/* <StyledButton
              sx={{ opacity: 0.5, mt: 1 }}
              variant="contained"
              color="redButton100"
            >
              {i18n["myCV.scrapeCV"]}
            </StyledButton> */}
            </Box>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openProfilePicDialog}
        onClose={() => {
          setOpenProfilePicDialog(false);
          setopenApplyJobDialog(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            top="15px"
            right="12px"
            onClick={() => {
              setOpenProfilePicDialog(false);
              setopenApplyJobDialog(false);
            }}
          />
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              // gap: 3,
            }}
          >
            <Box
              sx={{
                padding: "0 13px 0 14px",
              }}
            >
              <Box
                component={"img"}
                src={CrayonSelfie}
                sx={{
                  height: 125,
                  marginTop: "20px",
                }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  marginBottom: "30px",
                  marginTop: "12px",
                  lineHeight: "27px",
                }}
              >
                Selfie, please.
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.base,
                  fontWeight: theme.typography.Bold,
                  marginBottom:
                    decodedToken?.data?.role_id === 3 ? "30px" : "30px",
                }}
              >
                {decodedToken?.data?.role_id === 3 ? (
                  <>
                    This role requires a quick video
                    <br />
                    application.
                  </>
                ) : (
                  <>
                    This role requires a quick video
                    <br />
                    application. You can do this now,
                    <br />
                    or later via your dashboard.
                  </>
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                  marginBottom: "30px",
                }}
              >
                {decodedToken?.data?.role_id === 3 ? (
                  <>
                    You can do this now, or later via your dashboard,
                    <br />
                    but there’s no time like the present!
                  </>
                ) : (
                  <>
                    If you’re already on the Crayon platform,
                    <br />
                    you can simply login to continue.
                  </>
                )}
              </Typography>
              {decodedToken?.data?.role_id === undefined && (
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    marginBottom: "30px",
                  }}
                >
                  Note: if you exit now,
                  <br /> your application will be discarded.
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                height: "60px",
                width: "100%",
                padding: "auto 0 !important",
              }}
            >
              {decodedToken?.data?.role_id === undefined && (
                <Button
                  variant="contained"
                  color="loginButton"
                  sx={{
                    borderRadius: "0 0 0 25px",
                    width: `33.3%`,
                    height: "100%",
                    padding: "0px",
                  }}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: {
                          sx: "20px",
                          md: "20px",
                          xl: "22px",
                          xxl: "24px",
                        },
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={CrayonLogin}
                    />
                  }
                  onClick={handleLogin}
                >
                  login
                </Button>
              )}
              <Button
                variant="contained"
                color="laterButton"
                sx={{
                  borderRadius:
                    decodedToken?.data?.role_id === undefined
                      ? 0
                      : "0 0 0 25px",
                  width:
                    decodedToken?.data?.role_id === undefined ? `33.3%` : "50%",
                  height: "100%",
                  padding: "0px",
                }}
                onClick={handleUploadProfilePicLater}
              >
                I'll do it later
              </Button>
              <Button
                variant="contained"
                color="rollCameraButton"
                sx={{
                  borderRadius: "0 0 25px 0",
                  width:
                    decodedToken?.data?.role_id === undefined ? `33.3%` : "50%",
                  padding: "0px",
                  height: "100%",
                  textWrap: "noWrap",
                }}
                startIcon={
                  <Box
                    component="img"
                    sx={{
                      height: {
                        sx: "20px",
                        md: "16px",
                        xl: "20px",
                        xxl: "22px",
                      },
                      cursor: "pointer",
                    }}
                    alt="crayon logo"
                    src={cameraIcon}
                  />
                }
                onClick={() => {
                  setOpenProfilePicDialog(false);
                  // setOpenVideoRecording(true);
                  handleOpenRecordDialog();
                }}
              >
                roll cameras
                {/* {i18n["myCV.uploadCV"]} */}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {openRecordDialog && (
        <JobVideoUpload
          setOpenProfilePicDialog={setOpenProfilePicDialog}
          show={openRecordDialog}
          closeFunc={handleOpenRecordDialog}
          setOpenRecordDialog={setOpenRecordDialog}
          reloadVideo={handleVideoUploaded}
          job_id={location?.state ? location?.state : jobId}
          allVideoJobs={jobs}
          // setAllJobList={setAllJobList}
          job={jobs.find((j) => j?.job_id === jobId)}
          setSteps={setSteps}
          steps={steps}
          setActiveStep={setActiveStep}
          userId={userID}
          setIsVideoUploaded={setIsVideoUploaded}
          setOpenSignUpLogin={setOpenSignUpLogin}
          setOpenReviewVideoDialog={setOpenReviewVideoDialog}
        />
      )}

      <CustomDialog
        show={openVideoRecording}
        hideButton={false}
        dialogWidth="xs"
        showFooter={false}
        // title={i18n["login.signUp"]}
        isApplyJob
        padding={0}
      >
        {openVideoRecording && (
          <RecordingInProgress
            onRecordingStop={handleVideoData}
            onCloseCamera={onHandleVideoRecording}
          />
        )}
      </CustomDialog>
      <CustomDialog
        show={openReviewVideoDialog}
        hideButton={false}
        onDialogClose={() => {
          setVideoLink("");
          setRecordedVideoData(null);
          setOpenReviewVideoDialog(false);
        }}
        dialogWidth="xs"
        showFooter={false}
        // title={i18n["login.signUp"]}
        isApplyJob
        padding={0}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <video
            preload="auto"
            autoPlay={false}
            poster=""
            style={{
              width: "80%",
              height: "auto",
              borderRadius: "10px",
            }}
            src={videoLink}
            type="video/mp4"
            controls
          ></video>
          <Box>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "20px",
                fontWeight: 700,
              }}
            >
              A star performance!
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: 700,
                marginBottom: 3,
              }}
            >
              delete or upload your video now.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              color="deleteVideoButton"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "60px",
              }}
              onClick={() => {
                setVideoLink("");
                setRecordedVideoData(null);
                setOpenReviewVideoDialog(false);
                setOpenProfilePicDialog(true);
              }}
            >
              delete
            </Button>
            <Button
              variant="contained"
              color="uploadVideoButton"
              sx={{
                borderRadius: 0,
                width: "50%",
                height: "60px",
              }}
              onClick={createFileFromBlob}
            >
              upload video
            </Button>
          </Box>
        </Box>
      </CustomDialog>

      <Modal
        open={openSignUpLogin}
        onClose={() => {
          onHandleCloseSingUpLogin();
          setopenApplyJobDialog(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseIcon
            top="15px"
            right="15px"
            onClick={() => {
              handleQuestions();
              setopenApplyJobDialog(false);
            }}
          />
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                padding: "0 13px 0 14px",
              }}
            >
              <Box
                component={"img"}
                src={FinalStepIcon}
                sx={{
                  height: 125,
                  marginTop: "20px",
                }}
              />
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  marginBottom:
                    decodedToken?.data?.role_id === undefined ? "30px" : "30px",
                }}
              >
                {decodedToken?.data?.role_id === undefined ? (
                  "Great start, you nailed it!"
                ) : (
                  <>
                    Great application,
                    <br /> you nailed it!
                  </>
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.base,
                  fontWeight: theme.typography.Bold,
                  marginBottom:
                    decodedToken?.data?.role_id === 3 ? "30px" : "30px",
                }}
              >
                {decodedToken?.data?.role_id === 3 ? (
                  <>
                    You know the drill by now. Make sure <br /> your profile is
                    complete and up to date… <br />
                    the more you complete, the stronger you <br />
                    can compete!
                  </>
                ) : (
                  <>
                    Step one, done! Create your profile and <br /> complete the
                    required steps for your <br /> application to fully qualify.
                  </>
                )}
              </Typography>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.sm,
                  fontWeight: theme.typography.Regular,
                  marginBottom: "30px",
                }}
              >
                {decodedToken?.data?.role_id === 3 ? (
                  <>
                    Visit the 'my jobs' section of your Crayon <br /> dashboard
                    to track the status of your application.
                  </>
                ) : (
                  <>
                    If you’re already on the Crayon platform,
                    <br /> you can simply login to continue.
                  </>
                )}
              </Typography>
              {decodedToken?.data?.role_id === undefined && (
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Bold,
                    marginBottom: "30px",
                  }}
                >
                  Note: If you exit now,<br></br> your application will be
                  discarded.
                </Typography>
              )}
            </Box>
            {decodedToken?.data?.role_id === 3 ? (
              <Box
                sx={{
                  height: "60px",
                }}
              >
                <Button
                  variant="contained"
                  color="viewMoreButton"
                  sx={{
                    borderRadius: "0 0 0 25px",
                    width: "50%",
                    height: "100%",
                  }}
                  onClick={() => {
                    onHandleCloseSingUpLogin();
                  }}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: {
                          sx: "20px",
                          md: "20px",
                          xl: "26px",
                          xxl: "30px",
                        },
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={
                        decodedToken?.data?.role_id === 3
                          ? moreJobsIcon
                          : userProfile
                      }
                    />
                  }
                >
                  view more jobs
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "0 0 25px 0",
                    height: "100%",
                    width: "50%",
                  }}
                  color="redButton"
                  onClick={() => {
                    navigate("/candidate/my-jobs");
                    setopenApplyJobDialog(false);
                  }}
                >
                  {/* {i18n["topBar.join"]} */}
                  go to my jobs
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  height: "60px",
                }}
              >
                <Button
                  variant="contained"
                  color="loginButton"
                  sx={{
                    borderRadius: "0 0 0 25px",
                    width: "50%",
                    height: "100%",
                  }}
                  onClick={handleLogin}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: {
                          sx: "20px",
                          md: "20px",
                          xl: "26px",
                          xxl: "26px",
                        },
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={CrayonLogin}
                    />
                  }
                >
                  {i18n["topBar.login"]}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: "0 0 25px 0",
                    height: "100%",
                    width: "50%",
                  }}
                  color="signupButton"
                  onClick={handleSignup}
                  startIcon={
                    <Box
                      component="img"
                      sx={{
                        height: {
                          sx: "20px",
                          md: "20px",
                          xl: "26px",
                          xxl: "30px",
                        },
                        // marginRight: 1,
                        cursor: "pointer",
                      }}
                      alt="crayon logo"
                      src={CrayonJoin}
                    />
                  }
                >
                  {/* {i18n["topBar.join"]} */}
                  Join Crayon
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>

      <Login
        handleLogin={onHandleLogin}
        openFunc={openLoginDialog}
        toggleForm={toggleForm}
        handleOpenLogin={handleLogin}
        closeFunc={onHandleCloseLogin}
        isVideoUploaded={isVideoUploaded}
        setResendMailBox={setResendMailBox}
        uploadedFileName={uploadedFileName}
        setEmail={setEmail}
        handleConfirmPasswordChange={() => setIsForgotPassowrd(true)}
      />
      <ResendMailBox
        setResendMailBox={setResendMailBox}
        email={email}
        open={resendMailBox}
        handleClose={() => setResendMailBox(false)}
      />
      <Signup
        onDialogClose={onHandleCloseSignUp}
        openFunc={openSignUpDialog}
        toggleForm={toggleForm}
        handleOpenSignUp={handleSignup}
        closeFunc={onHandleCloseSignUp}
        isVideoUploaded={isVideoUploaded}
        uploadedFileName={uploadedFileName}
        jobApplyUserType="candidate"
      />
      {isForgotPassword && (
        <ForgotPasswordConfirmationModal
          openFunc={isForgotPassword}
          closeFunc={() => setIsForgotPassowrd(false)}
        />
      )}
    </ThemeProvider>
  );
}
