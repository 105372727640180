import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import InputBox from "../../../common/InputBox";
import {
  ALERT_TYPE,
  NUMBERS_REGEX,
  VOUCHER_INFO,
  VOUCHER_USE,
} from "../../../../utils/Constants";
import locale from "../../../../i18n/locale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  addNewVoucher,
  updateNewVoucher,
} from "../../../../redux/admin/maintenance";
import { setAlert } from "../../../../redux/configSlice";
import SelectMenu from "../../../common/SelectMenu";
import { convertDateToISO, getDateFromToday } from "../../../../utils/DateTime";
import * as Yup from "yup";
import { validateSchema } from "../../../../utils/Common";

export default function AddVoucher({
  addVoucher,
  handleClose,
  getVouchersInfo,
  edit,
  setEdit,
  editVoucherData,
}) {
  const voucherInfoSchema = Yup.object().shape({
    voucher_name: Yup.string().required("Text Input Field is Required."),
    coins: Yup.number().positive("Numeric Field 1 must be a positive number."),
    duration: Yup.number().positive(
      "Numeric Field 2 must be a positive number."
    ),
    use: Yup.number().positive("Numeric Field 3 must be a positive number."),
    expiry_date: Yup.date().min(
      new Date(),
      "Expiry date must be in the future."
    ), // Minimum date is today
  });
  const theme = useTheme();
  const dispatch = useDispatch();
  const i18n = locale.en;
  const currentDate = new Date();
  const [voucherInfo, setVoucherInfo] = useState(
    edit ? editVoucherData : VOUCHER_INFO
  );
  const [errors, setErrors] = useState([]);

  const handleInputChange = (event) => {
    const { id, value, name } = event.target;
    if (id === "voucher_name") {
      const trimmedValue = value.replace(/\s+/g, ""); // Trim the input value to remove white spaces
      setVoucherInfo({ ...voucherInfo, [id]: trimmedValue?.toUpperCase() });
    } else {
      setVoucherInfo({ ...voucherInfo, [id]: value?.toUpperCase() });
    }
    setErrors(errors?.filter((item) => item?.key !== id));
  };

  const handleDateChange = (newValue) => {
    let formatedDate =
      dayjs(newValue)?.format("YYYY-MM-DD") === "Invalid Date"
        ? ""
        : dayjs(newValue)?.format("YYYY-MM-DD");
    const newProfileData = {
      ...voucherInfo,
      expiry_date: formatedDate,
    };
    const filteredErrors = errors?.filter((item) => item?.key != "expiry_date");
    setErrors(filteredErrors);
    setVoucherInfo(newProfileData);
  };

  const handleCoinChange = (event) => {
    const { value, id } = event.target;
    if (NUMBERS_REGEX.test(value)) {
      let updatedInfo = { ...voucherInfo, [id]: value };
      setVoucherInfo(updatedInfo);
      setErrors(errors?.filter((item) => item?.key !== id));
    } else if (value === "") {
      // If the value doesn't match the regex, clear the input field
      setVoucherInfo({ ...voucherInfo, [id]: "" });
    }
  };

  const handleSave = async () => {
    let { voucher_name, use, coins, duration, expiry_date } = voucherInfo;
    let testErrorState = {
      voucher_name,
      use,
      coins,
      duration,
      expiry_date,
    };
    validateSchema(voucherInfoSchema, testErrorState)
      .then(() => {
        try {
          let data;
          if (edit) {
            data = {
              voucher_id: voucherInfo?.voucher_id,
              name: voucherInfo?.voucher_name,
              coins: Number(voucherInfo?.coins),
              duration: Number(voucherInfo?.duration),
              expiry_date: convertDateToISO(voucherInfo?.expiry_date),
              multiple_use: voucherInfo?.use === 1 ? false : true,
            };
          } else {
            data = {
              name: voucherInfo?.voucher_name,
              coins: Number(voucherInfo?.coins),
              duration: Number(voucherInfo?.duration),
              expiry_date: convertDateToISO(voucherInfo?.expiry_date),
              multiple_use: voucherInfo?.use === 1 ? false : true,
            };
          }
          dispatch(edit ? updateNewVoucher(data) : addNewVoucher(data)).then(
            (payload) => {
              if (payload?.payload?.status === "success") {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.SUCCESS,
                    msg: edit
                      ? "Voucher updated successfully"
                      : "Voucher added successfully",
                  })
                );
                getVouchersInfo(0);
                handleClose();
                setEdit(false);
              } else {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: payload?.message,
                  })
                );
              }
            }
          );
        } catch (error) {
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: error,
            })
          );
        }
      })
      .catch((error) => {
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handleVoucherUse = async (event) => {
    const {
      target: { value },
      target: { name },
      target: { id },
    } = event;

    const temp = VOUCHER_USE.find((item) => item.id === value);
    setVoucherInfo({ ...voucherInfo, use: temp?.id });
    setErrors(errors?.filter((item) => item?.key !== "use"));
  };

  return (
    <Dialog
      open={addVoucher}
      fullWidth={true}
      //   maxWidth={"xs"}
      scroll="body"
      padding={0}
      zIndex='99999999999999999999'
      sx={{
        // zIndex: 100000,
        "& .MuiPaper-root": {
          borderRadius: "25px",
          width: "547px",
        },
      }}
    >
      <DialogTitle>
        <Button
          variant="contained"
          color="yellowButton400"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 0 25px 0",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          new voucher
        </Button>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Box
            sx={{
              width: "20px",
              height: "20px",
              color: "#000000",
              border: 2,
              fontSize: "18px",
              borderRadius: "5px",
            }}
          >
            X
          </Box>
        </IconButton>
      </DialogTitle>
      <Box
        mt="27px"
        padding="0 25px"
        sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            sx={{ fontSize: "15px", fontWeight: theme.typography.Regular }}
          >
            Voucher Name
          </Typography>
          <InputBox
            id="voucher_name"
            value={voucherInfo?.voucher_name}
            onChange={(e) => handleInputChange(e)}
            placeholder={i18n["voucher.entervouchername"]}
            sx={{
              border: errors?.find((error) => error?.key == "voucher_name")
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
              width: "500px !important",
              borderRadius: "10px !important",
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: "50px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "225px",
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: theme.typography.Regular }}
            >
              Coins
            </Typography>
            <InputBox
              id="coins"
              value={voucherInfo?.coins}
              onChange={handleCoinChange}
              placeholder={i18n["voucher.numberofcoins"]}
              sx={{
                border: errors?.find((error) => error?.key == "coins")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
                width: "100% !important",
                borderRadius: "10px !important",
                "& .MuiInputBase-root": {
                  margin: "0 10px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "225px",
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: theme.typography.Regular }}
            >
              Use
            </Typography>

            <SelectMenu
              clear={true}
              name="use"
              value={voucherInfo?.use}
              onHandleChange={handleVoucherUse}
              options={VOUCHER_USE}
              placeholder={"Job stage"}
              borderRadius="10px !important"
              width={"225px !important"}
              border={
                errors?.find((error) => error?.key == "use")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0"
              }
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "50px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "225px",
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: theme.typography.Regular }}
            >
              Coins Duration
            </Typography>
            <InputBox
              id="duration"
              value={voucherInfo?.duration}
              onChange={handleCoinChange}
              placeholder={i18n["voucher.numberofdaysvalid"]}
              sx={{
                border: errors?.find((error) => error?.key == "duration")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
                width: "100% !important",
                borderRadius: "10px !important",
                "& .MuiInputBase-root": {
                  margin: "0 10px",
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              width: "225px",
            }}
          >
            <Typography
              sx={{ fontSize: "15px", fontWeight: theme.typography.Regular }}
            >
              Claim Expiry Date
            </Typography>
            {/* <InputBox
              id="coins"
              value={
                voucherInfo?.duration !== ""
                  ? getDateFromToday(voucherInfo?.duration)
                  : ""
              }
              // value={getDateFromToday(voucherInfo?.duration)}
              // onChange={handleInputChange}
              placeholder={i18n["voucher.claimexpirydate"]}
              sx={{
                border: errors?.find((error) => error?.key == "expiry_date")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
                width: "100% !important",
                borderRadius: "10px !important",
                "& .MuiInputBase-root": {
                  margin: "0 10px",
                },
              }}
            />*/}
            <LocalizationProvider dateAdapter={AdapterDayjs} locale="en-gb">
              <DatePicker
                name="dob"
                value={voucherInfo?.expiry_date}
                onChange={handleDateChange}
                minDate={currentDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "Select claim expiry date",
                    }}
                    sx={{
                      width: "100%",
                      // "& .MuiButtonBase-root-MuiIconButton-root": {
                      //   padding: "0px !important",
                      // },
                      "& .MuiInputBase-root": {
                        "& .MuiIconButton-root": {
                          padding: "0px !important",
                        },
                        height: "40px",
                        width: "100%",
                        borderRadius: "10px",
                        border: errors?.find(
                          (error) => error?.key === "expiry_date"
                        )
                          ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                          : "1px solid #E0E0E0",
                        ":hover": {
                          border: errors?.find(
                            (error) => error?.key === "expiry_date"
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                        },
                      },
                      "& fieldset": {
                        border: "none",
                      },
                      "& .MuiIconButton-root": {
                        color: theme.palette.DobTextColor, // Change this to the desired color
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </Box>
      <Box sx={{ width: "100%", textAlign: "center", mt: "18px" }}>
        <Button
          variant="contained"
          color="submitButton"
          sx={{
            width: "150px",
            height: "45px",
            borderRadius: "25px 25px 0 0",
            color: "black",
          }}
          onClick={handleSave}
        >
          save
        </Button>
      </Box>
    </Dialog>
  );
}
