import { useEffect, useState } from "react";
import TalentFront from "./frontSide/TalentFront";
import TalentBack from "./backSide/TalentBack";

import ReactCardFlip from "react-card-flip";
import ReviewVideoDialog from "../../common/ReviewVideoDialog";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE } from "../../../utils/Constants";
import CoinSpendBox from "../../common/CoinSpendBox";
import { getMyCamVideo } from "../../../redux/candidate/candidateJobs";
import locale from "../../../i18n/locale";
import {
  getJobCoins,
  unlockCVandVideo,
} from "../../../redux/employer/empProfileSlice";
import { useSelector } from "react-redux";
import {
  getNotificationCount,
  setCoinsCount,
  setNotificationCount,
} from "../../../redux/candidate/alerts";
import { setTalentList } from "../../../redux/guest/talentSlice";
import CustomDialog from "../../common/CustomDialog";
import NotLoggedInEmployer from "../../common/NotLoggedInEmployer";
import CompanyInfoNotCompleted from "../../common/CompanyInfoNotCompleted";
import NotLoggedInEmployerMatched from "../../common/NotLoggedInEmployerMatched";

export default function TalentCard({
  index,
  job,
  jobslength,
  currentPageJobsCount,
  allTalent,
}) {
  const i18n = locale.en;
  const [isHorizontalFlipped, setisHorizontalFlipped] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [unlockCV, setUnlockCV] = useState(false);
  const [unlockVideo, setUnlockVideo] = useState(false);
  const [noCoins, setNoCoins] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [selectedJobID, setSelectedJobID] = useState([]);
  const [openNotLoggedIn, setOpenNotLoggedIn] = useState(false);
  const [openNotLoggedInMatched, setOpenNotLoggedInMatched] = useState(false);
  const [subHeadingModal, setSubHeadingModal] = useState(
    "talent can only be matched against your current active roles"
  );
  const handleCloseDialog = () => {
    setVideoLink("");
    setOpenReviewVideoDialog(false);
  };
  const dispatch = useDispatch();

  const handleUnlockCV = () => {
    // setUnlockCV(false);
    // setConfirm(true);
    unlockAJob();
  };

  const handleUnlockVideo = () => {
    // setUnlockVideo(false);
    // setConfirm(true);
    unlockAJob();
  };

  const handleConfirmButtonClick = () => {
    setConfirm(false);
    setUnlockCV(false);
    setUnlockVideo(false);
  };
  const handleNoCoinsButtonClick = async() => {
    // setNoCoins(false);
    let data;
      if (unlockCV) {
        data = {
          unlock_amount: coinsInfo?.unlock_amount,
          candidate_id: selectedJob?.user_id,
          transaction_name_id: coinsInfo?.transaction_name_id,
          employer_unlock: true,
          cvUnlock: unlockCV,
          cost:coinsInfo?.ZARCOST,

        };
      } else if (unlockVideo) {
        data = {
          unlock_amount: coinsInfo?.unlock_amount,
          candidate_id: selectedJob?.user_id,
          transaction_name_id: coinsInfo?.transaction_name_id,
          employer_unlock: true,
          videoUnlock: unlockVideo,
          cost:coinsInfo?.ZARCOST,
        };
      }
      const { payload } = await dispatch(unlockCVandVideo(data));
      if(payload?.status === "success"){
        setNoCoins(false)
        window.open(`/payment/${payload?.payment_transaction_id}`, "_blank");
      }
    // setConfirm(true);
  };

  const [selectedJob, setSelectedJob] = useState();
  const [coinsInfo, setCoinsInfo] = useState();
  const [getCoins, setGetCoins] = useState("");
  const { company_id } = useSelector((state) => state?.configalert);
  const getCount = async () => {
    try {
      const { payload } = await dispatch(getNotificationCount());
      if (payload?.status === "success") {
        dispatch(setNotificationCount(payload?.count));
        dispatch(setCoinsCount(payload?.coinsCounter));
      }
    } catch (error) {}
  };

  const unlockAJob = async () => {
    try {
      let data;
      if (unlockCV) {
        data = {
          unlock_amount: coinsInfo?.unlock_amount,
          candidate_id: selectedJob?.user_id,
          transaction_name_id: coinsInfo?.transaction_name_id,
          employer_unlock: true,
          cvUnlock: unlockCV,
          cost:coinsInfo?.ZARCOST,
          company_id:company_id
        };
      } else if (unlockVideo) {
        data = {
          unlock_amount: coinsInfo?.unlock_amount,
          candidate_id: selectedJob?.user_id,
          transaction_name_id: coinsInfo?.transaction_name_id,
          employer_unlock: true,
          videoUnlock: unlockVideo,
          cost:coinsInfo?.ZARCOST,
          company_id:company_id
        };
      }
      const { payload } = await dispatch(unlockCVandVideo(data));
      if (payload?.status === "success") {
        if (coinsInfo?.coins <coinsInfo?.unlock_amount) {
          setNoCoins(false);
          window.open(`/payment/${payload?.payment_transaction_id}`, "_blank");
        } else {
          if (unlockCV) {
            setUnlockCV(false);
            dispatch(
              setTalentList(
                allTalent?.map((item) =>
                  item?.user_id === selectedJob?.user_id
                    ? { ...item, is_cvUnlock: coinsInfo?.coins <coinsInfo?.unlock_amount?false:true }
                    : item
                )
              )
            );
          }
          if (unlockVideo) {
            setUnlockVideo(false);
            dispatch(
              setTalentList(
                allTalent?.map((item) =>
                  item?.user_id === selectedJob?.user_id
                    ? { ...item, is_videoUnlock: coinsInfo?.coins <coinsInfo?.unlock_amount?false:true }
                    : item
                )
              )
            );
          }
        }
        getCount();
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const [showNoInfoBox, setShowNoInfoBox] = useState(false);

  const getCoinBalance = async () => {
    if (!company_id) {
      setShowNoInfoBox(true);
      return;
    }
    try {
      const { payload } = await dispatch(
        getJobCoins({
          company_id: company_id,
          cvUnlock: getCoins === "unlockcv" && true,
          videoUnlock: getCoins === "unlockvideo" && true,
        })
      );
      if (payload?.status === "success") {
        setCoinsInfo(payload?.data);
        if (payload?.data?.coins === 0) {
          // setCoinSpend(false);
          // setUnlockCV(false);
          // setUnlockVideo(false);
          // setNoCoins(true);
          if (getCoins === "unlockcv") {
            setUnlockCV(true);
          } else if (getCoins === "unlockvideo") {
            setUnlockVideo(true);
          }
        } else {
          setNoCoins(false);
          if (getCoins === "unlockcv") {
            setUnlockCV(true);
          } else if (getCoins === "unlockvideo") {
            setUnlockVideo(true);
          }
          // setCoinSpend(true);
        }
        setGetCoins("");
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (getCoins !== "") {
      getCoinBalance();
    }
  }, [getCoins]);

  useEffect(() => {
    const fetchVideoLink = async () => {
      const { payload } = await dispatch(
        getMyCamVideo({ user_id: job?.user_id })
      );
      if (payload?.status === "success") {
        setVideoLink(payload?.data?.cam_url);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "No video",
          })
        );
      }
    };

    if (openReviewVideoDialog) {
      fetchVideoLink();
    }
  }, [dispatch, job?.job_id, openReviewVideoDialog]);

  return (
    <>
      <ReactCardFlip
        isFlipped={isHorizontalFlipped}
        flipDirection={"horizontal"}
        flipSpeedBackToFront="0.5"
        flipSpeedFrontToBack="0.5"
        key={job?.user_id}
      >
        <TalentFront
          index={index}
          job={job}
          selectedJobID={selectedJobID}
          setSelectedJobID={setSelectedJobID}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          jobslength={jobslength}
          indexNumber={index}
          currentPageJobsCount={currentPageJobsCount}
          setUnlockCV={setUnlockCV}
          setUnlockVideo={setUnlockVideo}
          setNoCoins={setNoCoins}
          setSelected={setSelectedJob}
          setGetCoins={setGetCoins}
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          setOpenNotLoggedInMatched={setOpenNotLoggedInMatched}
          setSubHeadingModal={setSubHeadingModal}
        />
        <TalentBack
          index={index}
          selectedJobID={selectedJobID}
          setSelectedJobID={setSelectedJobID}
          talent={job}
          setisFlipped={setisHorizontalFlipped}
          handleReviewVideoDialog={setOpenReviewVideoDialog}
          jobslength={jobslength}
          indexNumber={index}
          isHorizontalFlipped={isHorizontalFlipped}
          currentPageJobsCount={currentPageJobsCount}
          setUnlockCV={setUnlockCV}
          setUnlockVideo={setUnlockVideo}
          setNoCoins={setNoCoins}
          setSelected={setSelectedJob}
          setGetCoins={setGetCoins}
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          setOpenNotLoggedInMatched={setOpenNotLoggedInMatched}
          setSubHeadingModal={setSubHeadingModal}
          allTalent={allTalent}
        />
      </ReactCardFlip>

      {unlockCV && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coin.`}
          text2Width={"236px"}
          text3={i18n["myplanPopup.thisCVunlockwilluse"]}
          text3Width={"270px"}
          text3Height={"72px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => setUnlockCV(false)}
          show={unlockCV}
          buttonClick={handleUnlockCV}
          gap={"18px"}
        />
      )}

      {unlockVideo && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coin.`}
          text2Width={"236px"}
          text3={i18n["myplanPopup.thisvideounlockwilluse"]}
          text3Width={"270px"}
          text3Height={"72px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={i18n["myplanPopup.happyletsdothis"]}
          handleClose={() => setUnlockVideo(false)}
          show={unlockVideo}
          buttonClick={handleUnlockVideo}
          gap={"18px"}
        />
      )}

      {noCoins && (
        <CoinSpendBox
          text1={i18n["myplanPopup.confirmcoinspend"]}
          text1Width={"186px"}
          text2={i18n["myplanPopup.youcurrentlyhave"]}
          text2Width={"224px"}
          text3={i18n["myplanPopup.thisCVunlockwillrequire"]}
          text3Width={"298px"}
          text4={i18n["myplanPopup.pleaseconfirm"]}
          text4Width={"220px"}
          buttonText={"happy, let’s do this"}
          coinsBalance={`${coinsInfo?.coins} coins.`}
          coinsUse={`${coinsInfo?.unlock_amount} coin.`}
          handleClose={() => setNoCoins(false)}
          show={noCoins}
          buttonClick={handleNoCoinsButtonClick}
          gap={"15px"}
        />
      )}

      {confirm && (
        <CoinSpendBox
          text1={i18n["myplanPopup.nowyourecoiningit"]}
          text1Width={"208px"}
          text3={i18n["myplanPopup.crayonadminwillreview"]}
          text3Width={"252px"}
          text3Height={"72px"}
          buttonText={"got it, thanks"}
          handleClose={() => setConfirm(false)}
          show={confirm}
          buttonClick={handleConfirmButtonClick}
          gap={"20px"}
        />
      )}
      {openReviewVideoDialog && (
        <ReviewVideoDialog
          videoLink={videoLink}
          open={openReviewVideoDialog}
          setOpen={handleCloseDialog}
        />
      )}
      <CustomDialog
        show={openNotLoggedIn}
        hideButton={false}
        onDialogClose={() => setOpenNotLoggedIn(false)}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
      >
        <NotLoggedInEmployer
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          onClose={() => setOpenNotLoggedIn(false)}
        />
      </CustomDialog>
      <CustomDialog
        show={openNotLoggedInMatched}
        hideButton={false}
        onDialogClose={() => setOpenNotLoggedInMatched(false)}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
      >
        <NotLoggedInEmployerMatched
          setOpenNotLoggedIn={setOpenNotLoggedInMatched}
          onClose={() => setOpenNotLoggedInMatched(false)}
          subHeading={subHeadingModal}
        />
      </CustomDialog>
      <CustomDialog
        show={showNoInfoBox}
        onDialogClose={() => {
          setGetCoins("");
          setShowNoInfoBox(false);
        }}
        hideButton={false}
        dialogWidth="xs"
        showFooter={false}
        padding={0}
      >
        <CompanyInfoNotCompleted onClose={() => setShowNoInfoBox(false)}/>
      </CustomDialog>
    </>
  );
}
