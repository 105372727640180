import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import job_logo from "../../../../assets/Crayon_Favicon.svg";
import post_incognito from "../../../../assets/Incognito.svg";
import flip from "../../../../assets/NewFlipIcon.svg";
import job_volume from "../../../../assets/Padding Excluded/Crayon Icon_Promote.svg";
import job_exp from "../../../../assets/Padding Included/Green_Duration.svg";
import BlueCurrency from "../../../../assets/Blue_Salary.svg";
import redLocation from "../../../../assets/Red_Location.svg";
import calendar from "../../../../assets/Padding Included/Yellow_Calendar.svg";
import playButtonIconWhite from "../../../../assets/Padding Excluded/Black_reactive_job.svg";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ALERT_TYPE } from "../../../../utils/Constants";
import Tooltip from "@mui/material/Tooltip";
import SmallButton from "../../../common/SmallButton";
import {
  convertDatetimeAgo,
  dateConverterMonth,
} from "../../../../utils/DateTime";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setAlert } from "../../../../redux/configSlice";
import { favouriteJob } from "../../../../redux/guest/talentSlice";
import { formatCurrencyWithCommas } from "../../../../utils/Currency";
import matchMeIcon from "../../../../assets/Padding Excluded/Black_Match_me.svg";
import viewIcon from "../../../../assets/Padding Excluded/White_View.svg";
import applyIcon from "../../../../assets/Padding Excluded/Black_Follower.svg";
import BlackapplyIcon from "../../../../assets/Padding Excluded/White_Follower.svg";

import { truncate } from "lodash";
import MUIRadialChart from "../../../common/MUIRadialChart";
import favouriteGrey from "../../../../assets/Crayon Favourite Icon_Grey.svg";
import favouriteRed from "../../../../assets/Crayon Favourite Icon_Red.svg";
import { Card } from "@mui/material";
import TextWrapper from "../../../common/TextWrapper";
import MatchDialogBox from "../../../common/MatchDialogBox";
import {
  getDecodedToken,
  getToken,
  renderColor,
  setCookie,
  splitString,
} from "../../../../utils/Common";
import { getMatchData } from "../../../../redux/guest/jobsSlice";
import JobPromoteCard from "../../../promoter/JobPromoteCard";
import JobsSlider from "../JobsSlider";
import { LightTooltip } from "../../../../utils/CommonComponent";
import locale from "../../../../i18n/locale";
import pinIcon from "../../../../assets/Icons/pinIcon.svg";
import * as Flag from "country-flag-icons/react/3x2";
const Slider2 = React.lazy(() => import("../../../common/Slider2"));

const JobCardFront = ({
  index,
  job,
  setJobId,
  setopenApplyJobDialog,
  setisFlipped,
  setOpenNotLoggedIn,
  handleReviewVideoDialog,
  onClose,
  setSelectedCard,
  indexNumber,
  jobslength,
  currentPageJobsCount,
  setShowLogin,
  setJobVideoRequired,
  mobileText,
}) => {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  const CountryFlag = Flag[job?.country_code];
  const [isStar, setIsStarSelected] = useState(
    job?.favoriteJob
      ? job?.favoriteJob
      : document.cookie
          ?.match(/\d+/g)
          ?.map((item) => parseInt(item, 10))
          ?.includes(job?.job_id)
  );
  const [matchMeButton, setMatchButton] = useState(false);
  const [jobPromote, setJobPromote] = useState(false);
  const jobIndustries = splitString(job?.JobIndustries);
  const [match, setMatch] = useState();
  const label1 = "applied";
  const label2 = "shortlisted";
  const label3 = "interviewed";
  const [isApplied, setIsApplied] = useState(false);
  const calculateHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      // xs
      return 100;
    } else if (screenWidth < 900) {
      // sm
      return 100;
    } else if (screenWidth < 1366) {
      // md
      return 100;
    } else if (screenWidth < 1920) {
      // lg
      return 100;
    } else if (screenWidth < 2559) {
      // xl
      return 100;
    } else {
      // xxl or greater
      return 100;
    }
  };
  const decodedToken = getDecodedToken(getToken());

  const getDescriptionText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText;
  };

  const handleStar = async () => {
    if (decodedToken?.data?.role_id === 3) {
      await dispatch(favouriteJob({ reqid: job?.job_id }));
      setIsStarSelected(!isStar);
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Login as candidate to add this job as favourite",
        })
      );
    }
  };

  const handleFavourite = (event, jobid) => {
    setIsStarSelected(!isStar);
    setCookie("favoriteJobs", jobid, 30);
  };

  const handleClick = (card) => {
    setSelectedCard(card);
    if (
      decodedToken?.data?.role_id === undefined ||
      decodedToken?.data?.role_id === 3
    ) {
      if (card?.applidflag === true) {
        // dispatch(
        //   setAlert({
        //     show: true,
        //     type: ALERT_TYPE?.INFO,
        //     msg: "You have already applied for this job",
        //   })
        // );
      } else {
        setopenApplyJobDialog((prev) => !prev);
        setJobId(job?.job_id);
        setJobVideoRequired(job?.is_video_require);
      }
    } else {
      // dispatch(
      //   setAlert({
      //     show: true,
      //     type: ALERT_TYPE?.ERROR,
      //     msg: "Login as candidate to apply for this job",
      //   })
      // );
    }
  };

  const getMatch = async () => {
    try {
      const data = {
        job_id: job?.job_id,
        user_id: decodedToken?.data?.user_id,
      };
      const { payload } = await dispatch(getMatchData(data));
      if (payload?.status === "success") {
        setMatch(payload?.data);
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: error,
        })
      );
    }
  };

  const handleMatchMeButton = () => {
    if (decodedToken === undefined) {
      setOpenNotLoggedIn(true);
    } else {
      getMatch();
      setMatchButton((prevState) => !prevState);
    }
  };
  useEffect(() => {
    setIsApplied(job?.applidflag);
  }, [job]);
  return (
    <Card
      sx={{
        borderRadius: "25px",
        position: "relative",
        boxShadow: "0px 5px 10px #00000029",
      }}
      className="cardboxdetail"
    >
      {!matchMeButton && !jobPromote && (
        <>
          <Grid
            container
            justifyContent="space-between"
            alignItems="start"
            sx={{
              borderRadius: "25px 25px 0 0",
              flexWrap: "nowrap",
              height: "81px",
            }}
          >
            {job?.post_incognito && !job?.shortlistedflag ? (
              <Link
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                  cursor: "default",
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "60px",
                    width: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    ml: "13px",
                    mt: "11px",
                    mb: "10px",
                    borderRadius: "10px",
                  }}
                  alt="job_logo"
                  src={post_incognito}
                />
                <Tooltip disableInteractive>
                  <LightTooltip
                    title={i18n["popupBoxText.postIncognito"]}
                    placement="right"
                  >
                    <Typography
                      sx={{
                        mt: "10px",
                        cursor: "pointer",
                        height: "17px",
                        width: "17px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        lineHeight: 0.1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: theme.typography.Bold,
                        boxShadow: "0px 2px 4px #00000029",
                        border: 1,
                        position: "absolute",
                        top: "0px",
                        left: "75px",
                      }}
                    >
                      i
                    </Typography>
                  </LightTooltip>
                </Tooltip>
              </Link>
            ) : (
              <Link
                to={`/jobs/company/${job?.company_id}`}
                target={"_blank"}
                style={{
                  textDecoration: "none",
                  color: theme.palette.black,
                }}
              >
                <Box
                  component="img"
                  sx={{
                    height: "60px",
                    width: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    ml: "13px",
                    mt: "11px",
                    mb: "10px",
                    borderRadius: "10px",
                  }}
                  alt="job_logo"
                  src={
                    job?.profile_url !== "No URL" ? job?.profile_url : job_logo
                  }
                />
              </Link>
            )}
            <Box>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",
                }}
              >
                <Button
                  color={
                    job?.job_type?.includes("Recruit")
                      ? "RecruitButton"
                      : "DirectButton"
                  }
                  variant="contained"
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: "0 0 0 10px",
                    padding: 0,
                    fontSize: 12,
                    fontWeight: "700",
                    cursor: "default",
                    ":hover": {
                      boxShadow: 0,
                    },
                  }}
                >
                  {job?.job_type}
                </Button>
                <Button
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    borderRadius: 0,
                    padding: 0,
                    fontSize: 12,
                    fontWeight: "700",
                    background:
                      theme.manageTalent[renderColor(job?.stage_name)]?.main,
                    color: "white",
                    cursor: "default",
                    ":hover": {
                      background:
                        theme.manageTalent[renderColor(job?.stage_name)]?.main,
                    },
                  }}
                >
                  {job?.stage_name || "-"}
                </Button>
                <Button
                  color="jobAnnounceButton"
                  variant="contained"
                  sx={{
                    height: "45px",
                    width: "60px",
                    minWidth: "60px",
                    maxHeight: { xs: "60px" },
                    maxWidth: { xs: "60px" },
                    borderRadius: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ":hover": {
                      boxShadow: 0,
                    },
                  }}
                  onClick={() => setJobPromote(true)}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 30,
                      width: 30,
                      maxHeight: { xs: 30 },
                      maxWidth: { xs: 30 },
                    }}
                    alt="job_volume"
                    src={job_volume}
                  />
                </Button>
                <Button
                  color="playButton"
                  variant="contained"
                  onClick={() =>
                    (job?.job_video || job?.job_video_link) &&
                    handleReviewVideoDialog(true)
                  }
                  sx={{
                    height: "auto",
                    minWidth: "60px",
                    minHeight: "45px",
                    background:
                      job?.job_video === null &&
                      !job?.job_video_link &&
                      theme.palette.grayButton600.main,
                    borderRadius: 0,
                    borderTopRightRadius: "25px",
                    cursor: job?.job_video === null ? "default" : "pointer",
                    padding: 0,
                    ":hover": {
                      boxShadow: "none",
                    },
                  }}
                >
                  <Box
                    component={"img"}
                    src={playButtonIconWhite}
                    sx={{
                      height: "16px",
                      width: "20px",
                    }}
                  />
                </Button>
              </Box>

              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xs,
                  fontWeight: theme.typography.Regular,
                  letterSpacing: "0.75px",
                  height: "16px",
                  paddingTop: "3px",
                  color: theme.palette.grayButton600.main,
                }}
              >
                posted {convertDatetimeAgo(job?.created_at)} ago
              </Typography>
            </Box>
          </Grid>
          <Box
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <Grid
              paddingTop={0}
              marginLeft={"13px"}
              marginRight={"20px"}
              paddingBottom={0}
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "flex-end",
                  maxWidth: "100%",
                  height: "27px",
                  marginBottom: "6px",
                }}
              >
                {job?.pin_job && (
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      maxHeight: { xs: 20 },
                      maxWidth: { xs: 20 },
                    }}
                    alt="pinIcon"
                    src={pinIcon}
                  />
                )}
                <Tooltip
                  disableInteractive
                  arrow
                  title={job?.title}
                  placement="top"
                >
                  <Link
                    to={
                      job?.post_incognito
                        ? `/jobs/${job?.country_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                        : `/jobs/${job?.country_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.town_name?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.CompanyName?.replace(
                            /\s|\//g,
                            ""
                          )}/${job?.title?.replace(/\s|\//g, "")}/${
                            job?.job_id
                          }`
                    }
                    target={"_blank"}
                    style={{
                      textDecoration: "none",
                      color: theme.palette.black,
                      maxWidth: job?.pin_job
                        ? `calc(100% - 95px)`
                        : `calc(100% - 65px)`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: theme.typography.Bold,
                        fontSize: theme.typography.fontSize.xl,
                        overflow: "hidden",
                        lineHeight: 1,
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {job?.title || "-"}
                    </Typography>
                  </Link>
                </Tooltip>
                {job?.country_code && (
                  <CountryFlag
                    title="United States"
                    className="..."
                    style={{ width: 22, height: 20 }}
                  />
                )}
                <Box
                  component={"img"}
                  src={isStar ? favouriteRed : favouriteGrey}
                  sx={{
                    height: "21px",
                    width: "21px",
                    cursor: "pointer",
                  }}
                  onClick={(event) =>
                    decodedToken === undefined
                      ? handleFavourite(event, job?.job_id)
                      : handleStar(job?.job_id)
                  }
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "19px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="currency"
                    src={BlueCurrency}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.salary_min === 0 ? (
                      <>
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_max)}
                        {job?.type === "freelance" ? " per hour" : " per month"}
                      </>
                    ) : job?.currency_symbol ||
                      job?.salary_min ||
                      job?.salary_max ? (
                      <>
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_min)} to{" "}
                        {job?.currency_symbol}
                        {formatCurrencyWithCommas(job?.salary_max)}{" "}
                        {job?.type === "freelance" ? "per hour" : "per month"}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "19px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="location"
                    src={redLocation}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.town_name || "-"}
                    {job?.country_name && `, ${job?.country_name} `}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "19px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 15,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 15 },
                    }}
                    alt="job_exp"
                    src={job_exp}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.experience_year_end || job?.experience_year_start ? (
                      <>
                        {job?.experience_year_start} to{" "}
                        {job?.experience_year_end} years
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                    gap: "10px",
                    height: "19px",
                  }}
                >
                  <Box
                    component="img"
                    sx={{
                      height: 16,
                      width: 14,
                      maxHeight: { xs: 16 },
                      maxWidth: { xs: 14 },
                    }}
                    alt="calendar"
                    src={calendar}
                  />
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: 14,
                      letterSpacing: "0.25px",
                      lineHeight: 1,
                    }}
                  >
                    {job?.job_start_date
                      ? dateConverterMonth(job?.job_start_date)
                      : "-"}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "6px",
                  height: "56px",
                  marginBottom: "10px",
                  marginTop: "15px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                  }}
                >
                  {job?.type && (
                    <SmallButton
                      color={"jobTypeButton"}
                      fontSize={12}
                      height={25}
                      value={job?.type}
                      label={truncate(job?.type, { length: 12 })}
                      cursor={"default"}
                    />
                  )}
                  {job?.work_setup && (
                    <SmallButton
                      color={"workSetupButton"}
                      fontSize={12}
                      height={25}
                      value={job?.work_setup}
                      label={truncate(job?.work_setup, { length: 12 })}
                      cursor={"default"}
                    />
                  )}
                </Box>
                <JobsSlider
                  items={
                    jobIndustries?.sort((a, b) => a.length - b.length) || []
                  }
                  color={"industriesButton"}
                  hideTagsAfter={3}
                  height="25px"
                  job={job}
                  index={indexNumber}
                  jobslength={jobslength}
                  id={job?.job_id}
                  currentPageJobsCount={currentPageJobsCount}
                  mobileText={mobileText}
                />
              </Box>
              <TextWrapper
                height={"59px"}
                size={12}
                color={theme.palette.black100}
                letterSpacing="0.25px"
                lineHeight={"20px"}
                gutterBottom={false}
              >
                {job?.job_bio
                  ? getDescriptionText(job?.job_bio)
                  : getDescriptionText(job?.description)}
              </TextWrapper>
            </Grid>
          </Box>
          <Box
            display={"flex"}
            spacing={"15px"}
            padding="10px 18px 13px 13px"
            justifyContent="space-between"
            height={"100px"}
            flexWrap={"nowrap"}
          >
            <MUIRadialChart
              value={job?.TotalUserCount}
              chartName={label1}
              max={500}
              size={calculateHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"Applied"}
            />
            <MUIRadialChart
              value={job?.totalusershorlisted}
              chartName={label2}
              max={100}
              size={calculateHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"Shortlisted"}
            />
            <MUIRadialChart
              value={job?.totaluserinterviewed}
              chartName={label3}
              max={50}
              size={calculateHeight()}
              countFontSize={16}
              labelFontSize={12}
              color={"Interviewed"}
            />
          </Box>
          <Grid
            container
            flexWrap={"nowrap"}
            alignItems="center"
            overflow={"hidden"}
            sx={{
              width: "100%",
              borderRadius: "0 0 25px 25px",
              height: 60,
            }}
          >
            <Button
              variant="contained"
              sx={{
                borderRadius: 0,
                width: "32.5%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                boxShadow: "none !important",
                gap: 1,
              }}
              color="yoco"
              onClick={handleMatchMeButton}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "26px",
                  }}
                  src={matchMeIcon}
                />
              }
            >
              match
            </Button>

            <Link
              to={
                job?.post_incognito
                  ? `/jobs/${job?.country_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.town_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
                  : `/jobs/${job?.country_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.town_name?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.CompanyName?.replace(
                      /\s|\//g,
                      ""
                    )}/${job?.title?.replace(/\s|\//g, "")}/${job?.job_id}`
              }
              target={"_blank"}
              style={{
                textDecoration: "none",
                color: theme.palette.black,
                width: "35%",
                height: "100%",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  borderRadius: 0,
                  fontSize: "14px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  height: "100%",
                  width: "100%",
                  justifyContent: "center",
                  ".MuiButton-startIcon": {
                    marginRight: "0px !important",
                    marginLeft: 0,
                    justifyContent: "end",
                    width: "26px",
                  },
                  padding: "0px !important",
                  boxShadow: "none !important",
                }}
                color="tymeColor"
                startIcon={
                  <Box
                    component={"img"}
                    sx={{
                      height: 17,
                      width: 28,
                      padding: 0,
                      paddingRight: "5px",
                    }}
                    src={viewIcon}
                  />
                }
              >
                view more
              </Button>
            </Link>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0 0 25px 0",
                width: "33%",
                height: "100%",
                fontSize: "14px",
                fontWeight: "bold",
                ":hover": {
                  boxShadow: 0,
                },
                ".MuiButton-startIcon": {
                  marginRight: "0px !important",
                  marginLeft: 0,
                },
                padding: "0px !important",
                boxShadow: "none !important",
                gap: 1,
              }}
              color={job?.applidflag === true ? "newSubmit" :  "applyButton"}
              onClick={() => {
                handleClick(job);
              }}
              startIcon={
                <Box
                  component={"img"}
                  sx={{
                    height: "20px",
                    width: "24px",
                  }}
                  src={job?.applidflag === true ? BlackapplyIcon : applyIcon}
                />
              }
            >
              {job?.applidflag === true ? "applied" : "apply"}
            </Button>
          </Grid>
          <Button
            variant="contained"
            // color="nandos"
            // color="#38A8AE"
            color="newFlipColor"
            sx={{
              width: "20px",
              height: "100px",
              padding: 0,
              position: "absolute",
              right: 0,
              top: `calc(50% - 50px)`,
              minWidth: "20px",
              fontSize: "20px",
              borderRadius: "10px 0 0 10px",
            }}
            onClick={() => setisFlipped(true)}
          >
            <Box
              component={"img"}
              src={flip}
              sx={{
                height: "20px",
                width: "15px",
              }}
            />
          </Button>
        </>
      )}

      {matchMeButton && (
        <MatchDialogBox
          closeFunc={handleMatchMeButton}
          job={job}
          applyFunction={handleClick}
          setMatchButton={setMatchButton}
          match={match}
          setMatch={setMatch}
        />
      )}

      {jobPromote && (
        <JobPromoteCard
          setJobPromote={setJobPromote}
          jobID={job?.job_id}
          job={job}
          setShowLogin={setShowLogin}
          height={"auto"}
        />
      )}
    </Card>
  );
};

export default JobCardFront;
