import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  Popover,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import locale from "../../../../i18n/locale";
import crayonRecruitIcon from "../../../../assets/Characters/CrayBotIcons/Crayon Direct Recruit_1.svg";
import { setOpenCoinBox } from "../../../../redux/candidate/alerts";
import { useDispatch } from "react-redux";
import CrayonRecruitExpanded from "./CrayonRecruitExpanded";
import { LightTooltip, ToggleSwitch } from "../../../../utils/CommonComponent";
import { CheckCircle } from "@mui/icons-material";
import crayonLogo from "../../../../assets/Crayon_Favicon.svg";
import upArrow from "../../../../assets/Black_Up_Close - Copy.svg";

export default function CrayonRecruitBox({
  crayonRecruitExpand,
  setCrayonDirectExpand,
  findMyDreamBtn,
  handleCrayonRectuitExpand,
  setRecruitButton,
  recruitButton,
  isRecruitVideoNeeded,
  setisRecruitVideoNeeded,
  isInterviewNeeded,
  setIsInterviewNeeded,
  isRecruitPostPublicNeeded,
  setisRecruitPostPublicNeeded,
  isSelectedRecruiter,
  handleClickRecruit,
  selectedCompanyRecruit,
  companyList,
  idRecruit,
  openRecruit,
  anchorElRecruit,
  handleCloseRecruit,
  setBasicData,
  basicData,
  setSelectedCompanyDirect,
  setSelectedCompanyRecruit,
  handleDynamicTheme,
  setIsSelectedRecruiter,
  companyListLoading,
  setDirectButton,
}) {
  const theme = useTheme();
  const i18n = locale.en;
  const dispatch = useDispatch();
  return (
    <>
      <Typography
        sx={{
          fontSize: theme.typography.fontSize.xl,
          fontWeight: theme.typography.Bold,
          lineHeight: "25px",
          marginTop: "5px",
          marginBottom: "25px",
        }}
      >
       You guys take it from here!
      </Typography>
      <Box
        sx={{
          width: "170px",
          height: "125px",
          display: "flex",
          position: "relative",
          //   marginBottom: "50px",
          marginBottom: "10px",
        }}
        component={"img"}
        src={crayonRecruitIcon}
      />

      <Typography
        sx={{
          fontSize: theme.typography.fontSize.sm,
          fontWeight: theme.typography.Regular,
          marginBottom: recruitButton ? "15px" : "30px",
          width: { xs: "350px", tablet: "440px" },
          textAlign: "center", // Center align the text
        }}
      >
        <span
          style={{
            display: "block",
            marginBottom: recruitButton ? "15px" : "30px",
          }}
        >
          {i18n["postAJob.findingtalent"]}
        </span>
        <span style={{ display: "block" }}>
          {i18n["postAJob.optCandidate"]}
        </span>
      </Typography>

      {recruitButton && (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: recruitButton ? "5px" : "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                width: "40%",
                textAlign: "end",
              }}
            >
              we don’t need videos
            </Typography>
            <ToggleSwitch
              color="recruitButton"
              checked={isRecruitVideoNeeded}
              onClick={(event) => {
                setisRecruitVideoNeeded((prevState) => !prevState);
                // setBasicData({
                //   ...basicData,
                //   is_video_require: !basicData?.is_video_require,
                // });
              }}
            />
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                width: "40%",
                textAlign: "start",
              }}
            >
              include video applications
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: recruitButton ? "5px" : "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                width: "40%",
                textAlign: "end",
              }}
            >
              we don’t need interviews
            </Typography>
            <ToggleSwitch
              color="recruitButton"
              checked={isInterviewNeeded}
              onClick={(event) => {
                setIsInterviewNeeded((prevState) => !prevState);
                // setBasicData({
                //   ...basicData,
                //   is_interview_require: !basicData?.is_interview_require,
                // });
              }}
            />

            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                width: "40%",
                textAlign: "start",
              }}
            >
              include recorded interviews
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: recruitButton ? "5px" : "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                width: "40%",
                textAlign: "end",
              }}
            >
              public post
            </Typography>

            <ToggleSwitch
              color="recruitButton"
              checked={isRecruitPostPublicNeeded}
              onClick={() => {
                setisRecruitPostPublicNeeded((prevState) => !prevState);
                // setBasicData({
                //   ...basicData,
                //   post_incognito: !basicData?.post_incognito,
                // });
              }}
            />
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                width: "40%",
                display: "flex",
                gap: "10px",
                textAlign: "start",
              }}
            >
              post incognito
              <Tooltip disableInteractive>
                <LightTooltip
                  title={"Post your job in public mode or incognito mode"}
                  placement="right-end"
                >
                  <Typography
                    sx={{
                      height: "17px",
                      width: "17px",
                      borderRadius: "5px",
                      fontSize: "12px",
                      lineHeight: 0.1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontWeight: theme.typography.Bold,
                      boxShadow: "0px 2px 4px #00000029",
                      border: 1,
                    }}
                  >
                    i
                  </Typography>
                </LightTooltip>
              </Tooltip>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              marginBottom: recruitButton ? "10px" : "25px",
            }}
          >
            <Typography
              sx={{
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
              }}
            >
              post as
            </Typography>
            <FormControl
              sx={{
                width: { xl: "36.4%" },
              }}
            >
              <Button
                color="displayCompanyButton"
                sx={{
                  border: "1px solid",
                  boxShadow: "none",
                  ":hover": {
                    boxShadow: "none",
                  },
                  borderColor: isSelectedRecruiter ? "red" : "borderColor",
                  borderRadius: "10px",
                  fontSize: "14px",
                  fontWeight: theme.typography.Regular,
                  padding: "10px 10px 11px 19px",
                }}
                variant="contained"
                onClick={handleClickRecruit}
              >
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                    textWrap: "noWrap",
                  }}
                >
                  {selectedCompanyRecruit
                    ? `${
                        companyList?.find((item) => {
                          return item?.company_id === selectedCompanyRecruit;
                        })?.name
                      }`
                    : "Select the displayed company"}
                </Typography>
                <Box
                  component={"img"}
                  src={upArrow}
                  sx={{
                    height: "25px",
                    width: "25px",
                  }}
                />
              </Button>
              <Popover
                id={idRecruit}
                open={openRecruit}
                anchorEl={anchorElRecruit}
                onClose={handleCloseRecruit}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "25px",
                  },
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0px 3px 8px #00000029",
                    width: "300px",
                    borderRadius: "25px",
                    height: "586px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
                      background: "green",
                    },
                  }}
                >
                  <IconButton
                    aria-label="close"
                    onClick={() => handleCloseRecruit()}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                    disableRipple={true}
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        color: "#000000",
                        border: 2,
                        fontSize: "18px",
                        borderRadius: "5px",
                      }}
                    >
                      X
                    </Box>
                  </IconButton>
                  {companyList?.length > 0 ? (
                    <Box
                      sx={{
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "15px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: theme.typography.Medium,
                        }}
                      >
                        Post as
                      </Typography>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          My company (default):
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Box
                            component={"img"}
                            src={
                              companyList?.filter((item) => item?.default)?.[0]
                                ?.profile_url !== "No URL"
                                ? companyList?.filter(
                                    (item) => item?.default
                                  )?.[0]?.profile_url
                                : crayonLogo
                            }
                            sx={{
                              height: "46px",
                              width: "46px",
                              borderRadius: "10px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: theme.typography.Bold,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setBasicData({
                                ...basicData,
                                job_type: "Recruit",
                              });
                              setSelectedCompanyRecruit(
                                companyList?.filter(
                                  (item) => item?.default
                                )?.[0]?.company_id
                              );
                              setSelectedCompanyDirect(undefined);
                              handleDynamicTheme("Recruit");
                              handleCloseRecruit();
                            }}
                          >
                            {
                              companyList?.filter((item) => item?.default)?.[0]
                                ?.name
                            }
                          </Typography>
                          {companyList?.filter((item) => item?.default)?.[0]
                            ?.company_id === selectedCompanyRecruit && (
                            <CheckCircle
                              sx={{
                                fontSize: 27,
                                cursor: "pointer",
                              }}
                              color="checkColor"
                            />
                          )}
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: theme.typography.Medium,
                          }}
                        >
                          Or select another added company or client:
                        </Typography>
                        {companyList
                          ?.filter((item) => !item?.default)
                          ?.map((company) => (
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <Box
                                component={"img"}
                                src={
                                  company?.profile_url !== "No URL"
                                    ? company?.profile_url
                                    : crayonLogo
                                }
                                sx={{
                                  height: "46px",
                                  width: "46px",
                                  borderRadius: "10px",
                                }}
                              />
                              <Typography
                                sx={{
                                  fontSize: "15px",
                                  fontWeight: theme.typography.Bold,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setBasicData({
                                    ...basicData,
                                    job_type: "Recruit",
                                  });
                                  setIsSelectedRecruiter(false);
                                  setSelectedCompanyDirect(undefined);
                                  setSelectedCompanyRecruit(
                                    company?.company_id
                                  );
                                  handleDynamicTheme("Recruit");
                                  handleCloseRecruit();
                                }}
                              >
                                {company?.name}
                              </Typography>
                              {company?.company_id ===
                                selectedCompanyRecruit && (
                                <CheckCircle
                                  sx={{
                                    fontSize: 27,
                                    cursor: "pointer",
                                  }}
                                  color="checkColor"
                                />
                              )}
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  ) : companyListLoading ? (
                    <Box
                      style={{
                        margin: "15px 0 0 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress color="inherit" size={20} />
                    </Box>
                  ) : (
                    <Box
                      style={{
                        margin: "15px 0 0 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Typography>No Company is added</Typography>
                    </Box>
                  )}
                  <Button
                    variant="contained"
                    color="CloseButton"
                    sx={{
                      width: "100%",
                      borderRadius: "0 0 10px 10px",
                    }}
                    onClick={handleCloseRecruit}
                  >
                    Close
                  </Button>
                </Box>
              </Popover>
            </FormControl>
          </Box>
        </>
      )}

      {crayonRecruitExpand && <CrayonRecruitExpanded />}

      <Box
        sx={{
          background: theme.palette.lightGrey.main,
          width: "100%",
          borderRadius: "0 0 25px 25px",
        }}
      >
        <Box sx={{ margin: "25px 0", textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.xl,
              fontWeight: theme.typography.Bold,
              lineHeight: "36px",
            }}
          >
            {i18n["postAJob.timeismoney"]}
          </Typography>

          {!recruitButton && (
            <>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "8px 0",
                }}
              >
                <Typography
                  sx={{
                    fontSize: theme.typography.fontSize.sm,
                    fontWeight: theme.typography.Regular,
                    lineHeight: "19px",
                    width: { xs: "350px", tablet: "440px" },
                  }}
                >
                  {i18n["postAJob.saveUpto66"]}
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: theme.typography.fontSize.xl,
                  fontWeight: theme.typography.Bold,
                  lineHeight: "27px",
                }}
              >
                {i18n["postAJob.successFee"]}
              </Typography>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: crayonRecruitExpand && "#F2F4FB",
            borderRadius: "0 0 25px 25px",
          }}
        >
          {crayonRecruitExpand ? (
            <Button
              variant="contained"
              color="discovery"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "10px 0 0 0",
                letterSpacing: "0.28px",
                boxShadow: "none",
                ":hover": {
                  boxShadow: "none",
                },
              }}
              onClick={() => dispatch(setOpenCoinBox(true))}
            >
              add coins
            </Button>
          ) : (
            <Button
              variant="contained"
              color="tymeColor"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "10px 0 0 0",
                letterSpacing: "0.28px",
                ":hover": {
                  boxShadow: 0,
                },
                color: "white",
              }}
              onClick={handleCrayonRectuitExpand}
            >
              tell me more
            </Button>
          )}

          {recruitButton ? (
            <Button
              variant="contained"
              color="yoco"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "0 10px 0 0 ",
                letterSpacing: "0.28px",
                padding: 0,
                ":hover": {
                  boxShadow: 0,
                },
              }}
              onClick={() => {
                findMyDreamBtn();
              }}
            >
              begin the search
            </Button>
          ) : (
            <Button
              variant="contained"
              color="darkGrey"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                borderRadius: "0 10px 0 0 ",
                letterSpacing: "0.28px",
                color: "black",
                padding: 0,
                ":hover": {
                  boxShadow: 0,
                },
              }}
              onClick={() => {
                setRecruitButton(true);
                setDirectButton(false);
                setCrayonDirectExpand(false);
              }}
            >
              go with Recruit
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
}
