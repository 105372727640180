import {
  Box,
  Button,
  IconButton,
  InputLabel,
  Slider,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { BlueSwitch, LightTooltip } from "../../../../utils/CommonComponent";
import SelectMenu from "../../../common/SelectMenu";
import locale from "../../../../i18n/locale";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/configSlice";
import {
  ALERT_TYPE,
  CULTURE_MARKS,
  SCREEN_QUESTIONS,
} from "../../../../utils/Constants";
import AutoComplete from "../../../common/AutoComplete";
import InputBox from "../../../common/InputBox";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

export default function EditCultureDetail({
  jobDetail,
  setJobDetail,
  personalities,
  traits,
  errors,
  setErrors,
  isRecruitVideoNeeded,
  isDirectVideoNeeded,
  setisRecruitVideoNeeded,
  setisDirectVideoNeeded,
}) {
  const i18n = locale.en;
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    if (
      jobDetail?.primary_personality === value ||
      jobDetail?.shadow_personality === value
    ) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Primary and Shadow Personality should not be similar",
        })
      );
      return;
    }

    const newCultureData = {
      ...jobDetail,
      [name]: value,
    };
    setErrors(errors.filter((item) => item.key !== name));
    setJobDetail(newCultureData);
  };

  const textValue = (value) => {
    return value;
  };

  const rangeHandler = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newCultureData = {
      ...jobDetail,
      [name]: value,
    };

    const filteredErrors = errors?.filter((item) => item.key != name);
    setErrors(filteredErrors);
    setJobDetail(newCultureData);
  };

  const handleMultipleAutoComplete = ({ event, newValue, id }) => {
    if (newValue.length <= 5) {
      let newCultureData = {
        ...jobDetail,
        [id]: newValue.map((val) => val?.inputValue || val?.trait_id || val),
      };
      setJobDetail(newCultureData);
    } else {
      setErrors(errors.filter((item) => item.key !== id));
      newValue.splice(5, 1);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: "You can't add more than 5 traits!!",
        })
      );
    }
  };

  const getTraitsValue = () => {
    if (jobDetail?.traits?.length === 0) {
      return [];
    }

    return jobDetail?.traits?.map(
      (id) => traits?.find((trait) => trait.id === id) || id
    );
  };

  const removeQuestion = (event, index) => {
    if (jobDetail?.job_questions?.length >= 1) {
      const newquestions = jobDetail?.job_questions?.filter(
        (data, i) => i + 1 !== index
      );
      const newCultureData = {
        ...jobDetail,
        job_questions: newquestions,
      };
      setJobDetail(newCultureData);
    }
  };

  const addQuestion = () => {
    if (jobDetail?.job_questions?.length >= 5) {
      return;
    } else {
      const newCultureData = {
        ...jobDetail,
        job_questions: jobDetail?.job_questions !== null ? [...jobDetail?.job_questions, SCREEN_QUESTIONS] : [SCREEN_QUESTIONS],
      };

      const filteredErrors = errors?.filter(
        (item) => item?.key !== "job_questions"
      );
      setErrors(filteredErrors);
      setJobDetail(newCultureData);
    }
  };

  const handleQuestionChange = (event, index) => {
    const {
      target: { value },
      target: { id },
    } = event;

    let newCultureData = { ...jobDetail };
    newCultureData.job_questions[index] = {
      ...newCultureData.job_questions[index],
      [id]: value,
      // job_id: Number(jobDetail?.job_id),
    };
    setErrors(
      errors?.filter(
        (item) =>
          item?.key !== `job_questions[${index}].question_id` &&
          item?.key !== `job_questions[${index}].question`
      )
    );
    setJobDetail(newCultureData);
  };

  const handleVideoApplicationBrief = (event) => {
    setJobDetail({
      ...jobDetail,
      video_description: event?.target?.value,
    });
  };

  return (
    <>
      <Box
        sx={{
          p: 4,
          pb: 0,
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="tags"
              sx={{
                color: "black",
                paddingLeft: "10px",
                paddingBottom: "8px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                display: "flex",
                gap: 1,
              }}
            >
              {i18n["postAJob.primaryLabelReq"]}
            </InputLabel>
            <SelectMenu
              name="primary_personality"
              borderRadius="10px"
              value={jobDetail?.primary_personality}
              onHandleChange={handleChange}
              options={personalities}
              sx={{ width: "95%" }}
              border={
                // !cultureData?.jobDetails?.primary_personality &&
                errors?.find((error) => error.key === "primary_personality")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0"
              }
              placeholder={"Select your ideal primary personality"}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              htmlFor="tags"
              sx={{
                color: "black",
                paddingLeft: "10px",
                paddingBottom: "8px",
                fontSize: "14px",
                fontWeight: theme.typography.Bold,
                display: "flex",
                gap: 1,
              }}
            >
              {i18n["postAJob.shadowLabelReq"]}
            </InputLabel>
            <SelectMenu
              name="shadow_personality"
              borderRadius="10px"
              value={jobDetail?.shadow_personality}
              onHandleChange={handleChange}
              options={personalities}
              sx={{ width: "95%" }}
              border={
                // !cultureData?.jobDetails?.shadow_personality &&
                errors?.find((error) => error.key === "shadow_personality")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0"
              }
              placeholder={"Select your ideal shadow/secondary personality"}
            />
          </Box>
        </Box>

        <Box sx={{ width: "100%" }}>
          <InputLabel
            htmlFor="tags"
            sx={{
              color: "black",
              paddingLeft: "10px",
              paddingBottom: "8px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              display: "flex",
              gap: 1,
            }}
          >
            {i18n["postAJob.gritScoreLabel"]}
          </InputLabel>

          <Slider
            name="grit_score"
            aria-label="Custom marks"
            color="ideaGritScoreSlider"
            value={jobDetail?.grit_score}
            getAriaValueText={textValue}
            step={1}
            onChange={(event) => rangeHandler(event)}
            valueLabelDisplay="auto"
            valueLabelFormat={textValue}
            marks={CULTURE_MARKS}
            sx={{
              width: "88%",
              ml: 2,
              "& .MuiSlider-rail": {
                backgroundColor: theme.palette.eyeview100.main,
                height: "10px",
                border: errors?.find((error) => error.key === "grit_score")
                  ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                  : "1px solid #E0E0E0",
              },
              "& .MuiSlider-track": {
                height: "10px",
              },
              "& .MuiSlider-thumb": {
                borderRadius: "15%",
              },
            }}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <InputLabel
            htmlFor="tags"
            sx={{
              color: "black",
              paddingLeft: "10px",
              paddingBottom: "8px",
              fontSize: "14px",
              fontWeight: theme.typography.Bold,
              display: "flex",
              gap: 1,
            }}
          >
            {i18n["postAJob.traitsLabelReq"]}
          </InputLabel>

          <AutoComplete
            multiple={true}
            id="traits"
            name="traits"
            value={getTraitsValue()}
            onChange={handleMultipleAutoComplete}
            sx={{
              width: "95%",
              // display: "inline-table",
              display:"flex",
              fontSize:"14px",
              alignItems:"center",
              height:"auto",
              borderRadius: "10px",
              border: errors?.find((error) => error.key === "traits")
                ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                : "1px solid #E0E0E0",
            }}
            placeholder={"Select 5 preferred personality traits"}
            data={traits}
            limitTags={5}
            disableCloseOnSelect={true}
          ></AutoComplete>
        </Box>

        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Bold,

              mb: 2,
            }}
          >
            {i18n["postAJob.screeningQuestionsReq"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
              mb: 2,
            }}
          >
            {i18n["postAJob.screeningQuestionPara"]}
          </Typography>
          {jobDetail?.job_questions?.length > 0 &&
            jobDetail?.job_questions?.map((question, index) =>
              index < 5 ? (
                <Box key={index} sx={{ display: "flex" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 3,
                      width: "90%",
                    }}
                  >
                    <InputBox
                      id="question"
                      sx={{
                        width: "97%",
                        borderRadius: "10px",
                        border:
                          // question.question === "" &&
                          errors?.find(
                            (error) =>
                              error?.key === `job_questions[${index}].question_id` ||
                              error?.key === `job_questions[${index}].question`
                          )
                            ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                            : "1px solid #E0E0E0",
                      }}
                      value={question?.question}
                      onChange={(event) => handleQuestionChange(event, index)}
                      placeholder={i18n["postAJob.question"] + `0${index + 1}`}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mb: 3,
                    }}
                  >
                    {index >= 1 ? (
                      <IconButton
                        aria-label="edit"
                        color="addQuestionButton"
                        sx={{
                          padding: "0 !important",
                        }}
                        onClick={(event) => removeQuestion(event, index + 1)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
              ) : (
                ""
              )
            )}
        </Box>
      </Box>
      <Box
        sx={{
          paddingBottom: "32px",
        }}
      >
        <Button
          variant="contained"
          component="label"
          onClick={addQuestion}
          color="addQuestionButton"
          sx={{
            height: "40px",
            width: "230px",
            boxShadow: "none",
            ":hover": {
              boxShadow: "none",
            },
            borderRadius: "0 10px 10px 0",
            fontSize: "15px",
            transform: "translateX(-24px)",
            fontWeight: theme.typography.Bold,
          }}
        >
          {i18n["postAJob.addButton"]}
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 4,
          pt: 0,
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            mb: "22px",
            height: "24px",
          }}
        >
          {i18n["postAJob.videoLabel"]}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            height: "20px",
            mb: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            {i18n["postAJob.videoParaLabel"]}
            {i18n["postAJob.VideoParaNote"]}
          </Typography>
          <BlueSwitch
            // checked={jobDetail?.is_video_require}
            checked={
              jobDetail?.job_type === "Recruit"
                ? isRecruitVideoNeeded
                : isDirectVideoNeeded
            }
            onClick={(event) => {
              setJobDetail({
                ...jobDetail,
                is_video_require: event?.target?.checked,
              });
              jobDetail?.job_type === "Recruit"
                ? setisRecruitVideoNeeded(event?.target?.checked)
                : setisDirectVideoNeeded(event?.target?.checked);
            }}
          />
        </Box>
        <TextField
          value={jobDetail?.video_description}
          onChange={handleVideoApplicationBrief}
          placeholder="Is there anything specific you’d like the candidates to cover in their video applications?"
          multiline
          rows={2}
          sx={{
            width: "100%",
            height: "80px",
            borderColor: theme.palette.borderColor,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderRadius: "10px",
                borderColor: theme.palette.borderColor,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.borderColor,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.borderColor,
              },
            },
          }}
        ></TextField>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 4,
          pt: 0,
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            fontWeight: theme.typography.Bold,
            mb: "22px",
            height: "24px",
          }}
        >
          Employer video
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            height: "20px",
            mb: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            Will you be adding a briefing video that will be visible to
            applicants (max 1 minute)?
          </Typography>
          <BlueSwitch
            checked={jobDetail?.briffing_video_flag}
            onClick={(event) => {
              setJobDetail({
                ...jobDetail,
                briffing_video_flag: event?.target?.checked,
              });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "10px",
            height: "20px",
            mb: "16px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: theme.typography.Regular,
            }}
          >
            Do you want this video to be visible to only shortlisted candidates?
          </Typography>
          <BlueSwitch
            checked={jobDetail?.video_for_shortlisted_flag}
            onClick={(event) => {
              setJobDetail({
                ...jobDetail,
                video_for_shortlisted_flag: event?.target?.checked,
              });
            }}
          />
        </Box>
      </Box>
    </>
  );
}
