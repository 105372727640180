import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { postApi, getApi } from "../../utils/Apis";
import { setLoading } from "../configSlice";

const initialState = {
  town: [],
  currentStep: 1,
  cvSteps: 0,
  profileProgressDataValues: {},
  currentStepPromoter:false,
  topBarCompanyLogoClicked:false,
  footerCompanyData:[]
};

export const getProfile = createAsyncThunk(
  "getProfile",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/profile/get", true);
    return data;
  }
);

export const getCandidateBadge = createAsyncThunk(
  "getCandidateBadge",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/profile/candidatebadges", true);
    return data;
  }
);

export const getProfileProgressData = createAsyncThunk(
  "getProfileProgressData",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/profile/profileCompletionDetails", true);
    return data;
  }
);

export const getCountry = createAsyncThunk(
  "getCountry",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/regions", true);
    return data;
  }
);

export const getNationality = createAsyncThunk(
  "getNationality",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/nationalities", true);
    return data;
  }
);

export const getTowns = createAsyncThunk(
  "getTowns",
  async (payload, { dispatch }) => {
    let url;
    if (payload?.title) {
      url = `/towns?country_id=${payload.id}&title=${payload.title}`;
    } else if (payload?.searchTitle) {
      url = `/towns?title=${payload.searchTitle}&limit=${payload?.limit}`; // Assuming you want to use payload.title here
    } else if (payload.id) {
      url = `/towns?country_id=${payload.id}`; // Assuming you want to use payload.id here
    } else {
      url = `/towns?title=${payload}`;
    }
    const { data } = await getApi(
      url,
      // payload?.title
      //   ? `/towns?country_id=${payload?.id}&title=${payload?.title}`
      //   : `/towns?country_id=${payload}`,
      true
    );
    return data;
  }
);

export const getLanguage = createAsyncThunk(
  "getLanguage",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/languages", true);
    return data;
  }
);
export const updateActiveStatus = createAsyncThunk(
  "updateActiveStatus",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const {data} = await postApi("/profile/activestatusupdate", payload, true);
    dispatch(setLoading(false));
    return data;
  }
)
export const updatehideitems = createAsyncThunk(
  "updatehideitems",
  async (payload, {dispatch}) => {
    dispatch(setLoading(true));
    const {data} = await postApi("/candidate/edithideitems", payload, true);
    dispatch(setLoading(false));
    return data;
  }
)
export const createProfile = createAsyncThunk(
  "createProfile",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const data = await postApi("/profile/create", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi("/reset/password", payload, true);
    dispatch(setLoading(false));
    return data;
  }
);

export const changeToCandidate = createAsyncThunk(
  "changeToCandidate",
  async (payload, { dispatch }) => {
    const { data } = await getApi("/promoter/switchtocandidate", true);
    return data;
  }
);

export const uploadProfilePic = createAsyncThunk(
  "uploadProfilePic",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/profilepic",
      payload,
      true,
      "multipart/form-data"
    ); //"multipart/form-data"
    dispatch(setLoading(false));
    return data;
  }
);

export const uploadCompanyLogo = createAsyncThunk(
  "uploadCompanyLogo",
  async (payload, { dispatch }) => {
    dispatch(setLoading(true));
    const { data } = await postApi(
      "/upload/companylogo",
      payload,
      true,
      "multipart/form-data"
    ); //"multipart/form-data"
    dispatch(setLoading(false));
    return data;
  }
);

export const getCompanyTeamMember = createAsyncThunk(
  "getCompanyTeamMember",
  async ({ lastKey = "", company_id, roleTypeID }, { dispatch }) => {
    const { data } = await getApi(
      "/admin/companyteammembers?lastKey=" +
        lastKey +
        "&company_id=" +
        company_id +
        "&roleTypeId=" +
        roleTypeID,
      true
    );
    return data;
  }
);

export const getCompanyRecruiter = createAsyncThunk(
  "getCompanyRecruiter",
  async ({ lastKey, company_id }, { dispatch }) => {
    const { data } = await getApi(
      "/admin/companyrecruiters?lastKey=" +
        lastKey +
        "&company_id=" +
        company_id,
      true
    );
    return data;
  }
);

export const followCompany = createAsyncThunk(
  "followCompany",
  async (payload, { dispatch }) => {
    // dispatch(setLoading(true));
    const data = await postApi("/admin/followcompany", payload, true);
    // dispatch(setLoading(false));
    return data;
  }
);
export const unFollow = createAsyncThunk(
  "unFollow",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      `/candidate/unfollowcompany?company_id=${payload?.company_id}`,
      true
    );
    return data;
  }
);

export const unFollowCompany = createAsyncThunk(
  "unFollowCompany",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      "/candidate/unfollowcompany?company_id=" + payload,
      true
    );
    return data;
  }
);

export const getFollowCompany = createAsyncThunk(
  "getFollowCompany",
  async (payload, { dispatch }) => {
    const { data } = await getApi(
      "/admin/getfollowedcompany?lastKey=" + payload.lastKey,
      payload,
      true
    );
    return data;
  }
);

// export const setCurrentStep = createAction("setCurrentStep");

export const myProfileSlice = createSlice({
  name: "myProfile",
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setFooterCompanyData: (state, action) => {
      state.footerCompanyData = action.payload;
    },
    setCurrentStepPromoter: (state, action) => {
      state.currentStepPromoter = action.payload;
    },
    setCvSteps: (state, action) => {
      state.cvSteps = action.payload;
    },
    companyLogoClicked:(state, action)=>{
      state.topBarCompanyLogoClicked = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getTowns.fulfilled, (state, action) => {
      state.town = action?.payload?.data?.map((curr) => {
        return {
          ...curr,
          id: curr?.town_id,
          name: curr?.name,
        };
      });
    });
    builder.addCase(getProfileProgressData.fulfilled, (state, action) => {
      state.profileProgressDataValues = action?.payload?.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentStep, setCvSteps,setCurrentStepPromoter,companyLogoClicked,setFooterCompanyData } = myProfileSlice.actions;

export default myProfileSlice.reducer;
