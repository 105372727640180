import { Button, Paper, Typography, useTheme } from "@mui/material";
import { USER_TYPES } from "../../utils/Constants";

export default function SwipeableButton({
  selectedUser,
  onButtonToggle,
  setSignupTouched,
  jobApplyUserType,
  error,
}) {
  const theme = useTheme();

  const selectedColor = (selectedUser) => {
    switch (selectedUser) {
      case "candidate":
        return "topBarCandidateButtonLightBlue";
      case "employer":
        return "topBarEmployerButton";
      case "recruiter":
        return "topBarRecruiterButton";
      case "promoter":
        return "topBarPromoterButton";
      default:
        break; 
    }
  };

  const ButtonComponent = ({ selectedUser, nextUser, disabled }) => {
    return (
      <Button
        sx={{
          width: 100,
          height: "100%",
          boxShadow: 0,
          borderRadius: "10px !important",
        }}
        variant="contained"
        color={selectedColor(selectedUser)}
        onClick={(e) => !disabled && onButtonToggle(e, nextUser)} 
        disabled={disabled}
      >
        {selectedUser}
      </Button>
    );
  };

  const TypographyComponent = ({ user, children, disabled, ...otherStyles }) => {
    return (
      <Typography
        onClick={(e) => {
          if (!disabled) {
            onButtonToggle(e, user);
          }
        }}
        sx={{
          fontWeight: 300,
          fontSize: "14px",
          color: error ? "errorMessage" : theme?.palette?.lightGray,
          cursor: disabled ? "not-allowed" : "pointer", 
          width: 97,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "10px !important",
          textAlign: "center",
          opacity: disabled ? 0.5 : 1,
          ...otherStyles,
          
        }}
        variant="subtitle1"
      >
        {children}
      </Typography>
    );
  };

  return (
    <Paper
      sx={{
        display: "flex",
        borderRadius: "10px !important",
        height: "40px",
        alignItems: "center",
        justifyContent: "space-between",
        overflowX: "auto",
        whiteSpace: "nowrap",
        background: "#fff !important",
        boxShadow: "none",
        border: `1px solid`,
        borderColor: "borderColor",
        // backgroundColor: 
        //   isTogglingDisabled
        //     ? theme.palette.topBarCandidateButtonLightBlue 
        //     : undefined,
      }}
      style={{
        maxWidth: "99%",
      }}
    >
      {USER_TYPES.map((userType, index) => (
        selectedUser === userType ? (
          <ButtonComponent
            key={index}
            selectedUser={userType}
            nextUser={userType}
            disabled={jobApplyUserType ? userType !== jobApplyUserType : false} 
          />
        ) : (
          <TypographyComponent
            key={index}
            user={userType}
            disabled={jobApplyUserType ? userType !== jobApplyUserType : false} 
            color={selectedUser === undefined && "redButton100"}
            background={selectedUser === "" && theme?.palette?.candidateInitialTextColor}
            borderRadius={selectedUser === "" && "10px"}
          >
            {userType}
          </TypographyComponent>
        )
      ))}
    </Paper>
  );
}
