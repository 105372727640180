import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  calculateDynamicHeight,
  getDecodedToken,
  getToken,
  useQuery,
} from "../../../utils/Common";
import { Box, Button, Grid, Paper } from "@mui/material";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import ButtonPanel from "../../common/ButtonPanel";
import useViewport from "../../common/ViewPort";
import {
  SOLUTION_LIST,
  SOLUTION_TYPE,
  USER_TYPE,
} from "../../../utils/Constants";
import { useNavigate } from "react-router-dom";
import NoRecord from "../../common/NoRecord";
import CommonLoader from "../../common/CommonLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import SolutionCards from "./SolutionCards";
import { getHiringList } from "../../../redux/guest/whoshiring";
import { useDispatch } from "react-redux";
import {
  getSolitionList,
  getSolutionData,
  updateAllSolution,
} from "../../../redux/admin/maintenance";
import { useSelector } from "react-redux";
import Loader from "../../common/Loader";
import { setLoading } from "../../../redux/configSlice";
import {
  setCardsPadding,
  setFilterExpanded,
} from "../../../redux/guest/jobsSlice";
import { setSideFilter } from "../../../redux/login/loginSlice";

export default function Solutions({ handleJobsListingDynamicTheme }) {
  const gridRef = useRef();
  const selectedUserType = useRef();
  const solutionArr = useSelector((state) => state?.maintenance?.solutionList);
  const solutionType = useRef();
  const navigate = useNavigate();
  const { width } = useViewport();
  const query = useQuery();
  const dispatch = useDispatch();

  let decodedToken = getDecodedToken(getToken());
  const currentPageJobsCount = useSelector(
    (state) => state?.maintenance?.currentPageJobsCount
  );
  const solution = useSelector((state) => state?.maintenance?.solution);
  const showBox = useSelector((state) => state?.maintenance?.showBox);
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(25);
  const [itemsFetched, setItemsFetched] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  // const [solutionList, setSolutionList] = useState([]);
  // const [showBox, setShowBox] = useState(false);
  const [lastKey, setLastKey] = useState("");
  const [userTypeFilter, setUserTypeFilter] = useState();
  const [solutionTypeFilter, setSolutionTypeFilter] = useState();
  const { isSideFilter } = useSelector((state) => state.login);

  const handleFilterSelection = (paramType, filterName) => {
    if (filterName[0] === "user type" || filterName[0] === "solution types") {
      filterName[0] === "user type" && (selectedUserType.current = []);
      filterName[0] === "solution types" && (solutionType.current = []);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType); // Remove the parameter from the query string
      navigate(`/solutions?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const getSolutionList = async (lastkeyy, usertype, solutiontype) => {
    dispatch(setLoading(true));
    // lastkeyy === "" && setIsDataLoading(true);
    try {
      const { payload } = await dispatch(
        getSolutionData({
          lastKey: lastkeyy,
          user_types: usertype?.[0] === 1111 ? "" : usertype,
          solution_types:
            solutiontype?.[0] === "solution types" || solutiontype?.[0] === 1111
              ? ""
              : solutiontype,
        })
      );

      if (payload?.status === "success") {
        if (payload?.data?.length <= 0) {
          // setShowBox(true);
        } else {
          // setShowBox(false);
          setLastKey(payload?.pageNumber + 1);
          setItemsFetched(true);
        }
      }
      // setIsDataLoading(false);
      dispatch(setLoading(false));
    } catch (error) {
      // setIsDataLoading(false);
      dispatch(setLoading(false));
    }
  };

  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }

  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };

  const onChangeFilter = (selectedFilter) => {
    let userType = [];
    selectedFilter?.map((type) => {
      let selectedUser = USER_TYPE?.find((user) => user?.id === type);
      userType?.push(selectedUser?.name);
    });
    selectedUserType.current = selectedFilter;
    handleFilterSelection("usertype", userType);
    // setSolutionList([]);
    dispatch(updateAllSolution());
    setUserTypeFilter(selectedFilter);
    getSolutionList(0, selectedFilter, solutionTypeFilter);
  };

  const onChangeFilterJobType = (selectedFilter) => {
    let solutionsType = [];
    selectedFilter?.map((type) => {
      let selectedSolutionType = solutionArr?.find((sol) => sol?.id === type);
      solutionsType?.push(selectedSolutionType?.name);
    });
    solutionType.current = selectedFilter;
    handleFilterSelection("solutiontype", solutionsType);
    dispatch(updateAllSolution());
    setSolutionTypeFilter(solutionsType);
    getSolutionList(0, userTypeFilter, solutionsType);
  };

  const getSolution = async () => {
    try {
      let response = await dispatch(getSolitionList());
      if (response?.payload?.status === "success") {
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    dispatch(updateAllSolution());
    let users = [];
    let sol = [];
    let solID = [];
    query
      .get("usertype")
      ?.split(",")
      ?.map((type) => {
        let selectedUsers = USER_TYPE?.find((user) => user?.name === type);
        users?.push(selectedUsers?.id);
      });
    selectedUserType.current = users;
    query
      .get("solutiontype")
      ?.split(",")
      ?.map((type) => {
        let selectedSolution = solutionArr?.find((user) => user?.name === type);
        sol?.push(selectedSolution?.name);
        solID?.push(selectedSolution?.id);
      });
    solutionType.current = solID;
    if (solutionArr?.length > 0) {
      getSolutionList(0, users, sol);
    }
  }, [solutionArr]);

  useLayoutEffect(() => {
    solutionArr?.length === 0 && getSolution();
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, isSideFilter]);

  useEffect(() => {
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleJobsListingDynamicTheme(decodedToken?.data?.personality);
    }
  }, [decodedToken]);

  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true))
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false))
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position:"relative"

      }}
    >
       {width < 768 && width !==0 && isSideFilter && 
      <Box sx={{position:"absolute", top:"0", left:"0", height:"100%", width:"100%", background:"rgba(0,0,0, .2)"}}></Box>
      }
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
          zIndex: "30",
        }}
      >
             <Box sx={{ minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight:"45px",
             }}></Box>
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {leftExpanded ? <LeftArrow /> : <RightArrow />}
        </Button> */}
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Box
              sx={{
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedUserType?.current}
                panelData={USER_TYPE}
                side="left"
                color="filterButtons"
                onChangeFilter={onChangeFilter}
              />
            </Box>
            <style>
              {`.filterSec::-webkit-scrollbar {
                    display: none; /* Set the background color of the scrollbar */
                  }`}
            </style>
          </Box>
        )}
      </Box>

      {/* {isDataLoading ? (
        <Loader />
      ) : ( */}
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          // zIndex:isSideFilter && -1,
          zIndex:width < 768 && isSideFilter && -1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          height={calculateDynamicHeight()}
          key={`${userTypeFilter} + ${solutionTypeFilter}`}
          dataLength={solution?.length} //This is important field to render the next data
          next={() => getSolutionList(lastKey)}
          scrollThreshold={"200px"}
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Grid
            container
            gap={width <= 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width <= 768 && "center"}
            sx={{
              position: "relative",
              padding:
                width <= 768
                  ? "10px 10px"
                  : `0 ${newPadding}px ${newPadding}px ${newPadding}px`,
              top: width <= 768 ? "35px" : "55px",
            }}
          >
            {solution?.length > 0
              ? solution?.map((sol, index) => (
                  <Box
                    sx={{
                      width: newCardWidth,
                    }}
                  >
                    <SolutionCards
                      solution={sol}
                      index={index}
                      currentPageJobsCount={currentPageJobsCount}
                      solutionlength={solution?.length}
                      mobileText={width > 1024 ? false : true}
                    />
                  </Box>
                ))
              : showBox && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NoRecord />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                      display:none;
              }
              
              .infinite-scroll-component__outerdiv{
                width:100%
              }
                     `}
          </style>
        </InfiniteScroll>
      </Box>
      {/* )} */}

      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          // width: rightExpand && "150px",
          right: "0",
          // width: leftExpanded
          //   ? { xs: "150px", mdl: "123px", mlg: "150px", xl: "150px" }
          //   : "30px",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
        }}
      >
          <Box sx={{ minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight:"45px",
             }}></Box>
        {/* <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {rightExpand ? <RightArrow /> : <LeftArrow />}
        </Button> */}
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                // maxWidth: `${width < 768 && "150px"}`,
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={solutionType?.current}
                topMargin={true}
                panelData={solutionArr}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobType}
              />
            </Paper>
          </Box>
        )}
      </Box>
    </Box>
  );
}
