import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import locale from "../../../i18n/locale";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Typography from "@mui/material/Typography";
import InputBox from "../../common/InputBox";
import Slider from "@mui/material/Slider";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import IconButton from "@mui/material/IconButton";
import {
  addCultureData,
  getCultureData,
  getPersonalities,
  getTraits,
  uploadPostJobCultureVideo,
  uploadEmployerJobSpecData,
} from "../../../redux/employer/postJobSlice";
import {
  ALERT_TYPE,
  ERROR_MSG,
  SCREEN_QUESTIONS,
  CULTURE,
  CULTURE_MARKS,
} from "../../../utils/Constants";
import { setAlert, setLoading } from "../../../redux/configSlice";
import SelectMenu from "../../common/SelectMenu";
import AutoComplete from "../../common/AutoComplete";
import { cloneDeep } from "lodash";
import { InputLabel, TextField, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@emotion/react";
import CustomDialog from "../../common/CustomDialog";
import RecordingInProgress from "./dialog/RecordingInProgress";
import { useRef } from "react";
import { BlueSwitch, LightTooltip } from "../../../utils/CommonComponent";
import {
  getDecodedToken,
  getToken,
  validateSchema,
} from "../../../utils/Common";
import specIcon from "../../../assets/Padding Excluded/White_scrapeSpec.svg";
import scrapeLinkedinIcon from "../../../assets/MyCV/Black_View.svg";
import nextIcon from "../../../assets/Black_Right_Next.svg";
import previousIcon from "../../../assets/Black_Left_Previous.svg";
import * as Yup from "yup";
import useViewport from "../ViewPort";
import SavaAndExit from "./dialog/SavaAndExit";

export default function CultureAdd({
  specName,
  setspecName,
  changeStep,
  // handleOpenSaveAndExitDialog,
  setActiveStep,
  jobTypeDetails,
  handleComplete,
  isEditPostjob,
  setSelectedPage,
}) {
  const i18n = locale.en;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { width } = useViewport();
  let decodedToken = getDecodedToken(getToken());
  const [openSaveAndExitDialog, setOpenSaveAndExitDialog] = useState(false);

  const [cultureData, setCultureData] = useState({ ...CULTURE });
  const [errors, setErrors] = useState([]);
  const history = useNavigate();
  const { jobId } = useParams();
  const VideoRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const [selectedCount, setSelectedCount] = useState(0);
  const [openVideoRecording, setOpenVideoRecording] = useState(false);
  const [openReviewVideoDialog, setOpenReviewVideoDialog] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  const [recorderVideoData, setRecordedVideoData] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [disabledButton, setDisabledButton] = useState(true);

  const { personalities, traits } = useSelector((state) => state?.postJobs);

  const cultureAddInfoSchema = Yup.object().shape({
    primary_personality: Yup.string()
      .nullable()
      .required("Primary Personality is Required."),
    shadow_personality: Yup.string()
      .nullable()
      .required("Shadow Personality is Required."),
    grit_score: Yup.number().nullable().required("Grit Score is Required."),
    screen_questions: Yup.lazy((value) => {
      if (Array.isArray(value) && value.length > 0) {
        return Yup.array()
          .of(
            Yup.object().shape({
              question: Yup.string().required("Question is Required."),
            })
          )
          .max(5, "At least five question is required.")
          .required("Screen Questions are Required.");
      }
      return Yup.array().required("At least one screen question is required.");
    }),
    traits: Yup.array()
      .min(5, "At least five trait is required.")
      .required("Traits are Required."),
  });

  const getAllCultureData = async () => {
    try {
      dispatch(setLoading(true));
      const { payload } = await dispatch(getCultureData(jobId));
      if (payload?.status === "success") {
        const basic = payload?.data;
        let updatedScreenData =
          basic?.screen_questions?.length === 0
            ? [
                {
                  question_id: null,
                  question: "",
                },
              ]
            : basic?.screen_questions;

        setCultureData({ ...basic, screen_questions: updatedScreenData });
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getAllData = async () => {
    try {
      dispatch(setLoading(true));
      await Promise.all([dispatch(getPersonalities()), dispatch(getTraits())]);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const saveCulture = async (btntype) => {
    let {
      jobDetails: { primary_personality, shadow_personality, grit_score },
      screen_questions,
      traits,
    } = cultureData;

    let testErrorState = {
      primary_personality: primary_personality,
      shadow_personality: shadow_personality,
      grit_score: grit_score,
      screen_questions,
      traits,
    };
    validateSchema(cultureAddInfoSchema, testErrorState)
      .then(() => {
        try {
          if (jobId !== undefined) {
            const culturePayload = {
              job_id: jobId,
              job_spec: cultureData?.job_spec
                ? cultureData?.job_spec
                : specName !== "No file chosen"
                ? specName
                : null,
              primary_personality: cultureData?.jobDetails?.primary_personality,
              shadow_personality: cultureData?.jobDetails?.shadow_personality,
              grit_score: cultureData?.jobDetails?.grit_score
                ? cultureData?.jobDetails?.grit_score
                : 0,
              // screen_questions: cultureData?.screen_questions?.filter(
              //   (item) => item.job_id !== ""
              // ),
              screen_questions: cultureData?.screen_questions,
              traits: [...cultureData?.traits],
              is_video_require: cultureData?.jobDetails?.is_video_require,
              candidate_video_complete_flag:
                cultureData?.jobDetails?.candidate_video_complete_flag === null
                  ? false
                  : cultureData?.jobDetails?.candidate_video_complete_flag,
              video_description:
                cultureData?.jobDetails?.video_description === null
                  ? ""
                  : cultureData?.jobDetails?.video_description,
              briffing_video_flag: cultureData?.jobDetails?.briffing_video_flag
                ? cultureData?.jobDetails?.briffing_video_flag
                : false,
              video_for_shortlisted_flag:
                cultureData?.jobDetails?.video_for_shortlisted_flag === null
                  ? false
                  : cultureData?.jobDetails?.video_for_shortlisted_flag,
            };
            dispatch(addCultureData(culturePayload)).then((payload) => {
              if (payload?.payload?.status === "success") {
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.SUCCESS,
                    msg: i18n["alertMessage.CultureDataaddedsuccessfully"],
                  })
                );
                setErrors([]);
                handleComplete(3);
                if (btntype === "detail") {
                  changeStep(2);
                } else if (btntype === "saveToDraft") {
                  setActiveStep(6);
                  setOpenSaveAndExitDialog(false);
                  setSelectedPage(3);
                } else {
                  changeStep(4);
                }
                // setTimeout(() => {
                //   navigate(navigateUrl);
                // }, [500]);
              } else if (payload?.payload?.status === "error") {
                setErrors(payload?.payload?.errors);
              } else {
                setOpenSaveAndExitDialog(false);
                dispatch(
                  setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: payload?.payload?.message,
                  })
                );
              }
            });
          } else {
            setOpenSaveAndExitDialog(false);
            dispatch(
              setAlert({
                show: true,
                type: ALERT_TYPE?.ERROR,
                msg: i18n["alertMessage.Fillthepreviousstepsfirst"],
              })
            );
          }
        } catch (error) {
          setOpenSaveAndExitDialog(false);
          dispatch(
            setAlert({
              show: true,
              type: ALERT_TYPE?.ERROR,
              msg: ERROR_MSG,
            })
          );
        }
      })
      .catch((error) => {
        setOpenSaveAndExitDialog(false);
        setErrors(
          Object.keys(error)?.map((item) => ({
            message: error[item],
            key: item,
          }))
        );
      });
  };

  const handleChange = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;
    if (
      cultureData?.jobDetails?.primary_personality == value ||
      cultureData?.jobDetails?.shadow_personality == value
    ) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: i18n[
            "alertMessage.PrimaryandShadowPersonalityshouldnotbesimilar"
          ],
        })
      );
      return;
    }

    const newCultureData = {
      ...cultureData,
      jobDetails: {
        ...cultureData?.jobDetails,
        [name]: value,
      },
    };
    setErrors(errors?.filter((item) => item?.key !== name));
    setCultureData(newCultureData);
  };

  const textValue = (value) => {
    return value;
  };

  const handleMultipleAutoComplete = ({ event, newValue, id }) => {
    if (newValue?.length <= 5) {
      let newCultureData = {
        ...cultureData,
        [id]: newValue?.map((val) => val?.inputValue || val?.trait_id || val),
      };
      setSelectedCount(newValue?.length);
      setCultureData(newCultureData);
    } else {
      setErrors(errors?.filter((item) => item?.key !== id));
      newValue?.splice(5, 1);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE.ERROR,
          msg: i18n["alertMessage.Youcantaddmorethan5traits"],
        })
      );
    }
  };

  const getTraitsValue = () => {
    if (cultureData?.traits?.length === 0) {
      return [];
    }

    return cultureData?.traits?.map(
      (id) => traits?.find((trait) => trait?.id === id) || id
    );
  };

  const rangeHandler = (event) => {
    const {
      target: { value },
      target: { name },
    } = event;

    const newCultureData = {
      ...cultureData,
      jobDetails: {
        ...cultureData?.jobDetails,
        [name]: value,
      },
    };

    const filteredErrors = errors?.filter((item) => item?.key != name);
    setErrors(filteredErrors);
    setCultureData(newCultureData);
  };

  const handleQuestionChange = (event, index) => {
    const {
      target: { value },
      target: { id },
    } = event;

    let newCultureData = cloneDeep(cultureData);
    newCultureData.screen_questions[index] = {
      ...newCultureData.screen_questions[index],
      [id]: value,
      // question_id: Number(jobId),
    };
    setErrors(
      errors?.filter(
        (item) =>
          item?.key !== `screen_questions[${index}].question_id` &&
          item?.key !== `screen_questions[${index}].question`
      )
    );
    setCultureData(newCultureData);
  };

  const addQuestion = () => {
    if (cultureData?.screen_questions.length >= 5) {
      return;
    } else {
      const newCultureData = {
        ...cultureData,
        screen_questions: [...cultureData?.screen_questions, SCREEN_QUESTIONS],
      };

      const filteredErrors = errors?.filter(
        (item) => item?.key !== "screen_questions"
      );
      setErrors(filteredErrors);
      setCultureData(newCultureData);
    }
  };
  const removeQuestion = (event, index) => {
    if (cultureData?.screen_questions?.length >= 1) {
      const newquestions = cultureData?.screen_questions?.filter(
        (data, i) => i + 1 !== index
      );
      const newCultureData = {
        ...cultureData,
        screen_questions: newquestions,
      };
      setCultureData(newCultureData);
    }
  };

  const onHandleVideoRecording = () => {
    setOpenVideoRecording(false);
  };

  const handleVideoData = (data) => {
    setOpenVideoRecording(false);
    setOpenReviewVideoDialog(true);
    setRecordedVideoData(data);
    const link = URL.createObjectURL(data);
    setVideoLink(link);
  };

  const uploadVideo = async (file) => {
    const formData = new FormData();
    formData?.append("jobvideo", file);
    formData?.append("job_id", jobId);
    try {
      const { payload } = await dispatch(uploadPostJobCultureVideo(formData));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.VideouploadedSuccessfully"],
          })
        );
        setOpenReviewVideoDialog(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: i18n["alertMessage.FailedtouploadVideo"],
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
  };
  const createFileFromBlob = () => {
    const myFile = new File([recorderVideoData], "videoFile.webm", {
      type: recorderVideoData?.type,
    });
    uploadVideo(myFile);
  };

  const handleVideoApplicationBrief = (event) => {
    setCultureData({
      ...cultureData,
      jobDetails: {
        ...cultureData?.jobDetails,
        video_description: event?.target?.value,
      },
    });
  };

  const fileAccept =
    "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  const handleFileClick = async (event) => {
    hiddenFileInput?.current?.click();
  };

  const handleFileChange = async (event) => {
    const formData = new FormData();
    const file = event?.target?.files?.[0];
    formData.append("jobspecemployer", file);
    jobId !== undefined && formData.append("job_id", jobId );
    try {
      const { payload } = await dispatch(uploadEmployerJobSpecData(formData));
      if (payload?.status === "success") {
        // setspecName(file?.name);
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.SpecuploadedSuccessfully"],
          })
        );
        setspecName(payload?.data?.job_spec);
        !jobId && history(`/employer/post-a-job/${payload?.data?.job_id}`);
        // setOpenSpecBox(false);
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
      }
    } catch (error) {
      dispatch(setAlert({ show: true }));
    }
    // setOpenSpecBox(false);
  };

  const handleOpenSaveAndExitDialog = () => {
    setOpenSaveAndExitDialog((prevState) => !prevState);
  };
  const handleModalSaveAndExit = () => {
    saveCulture("saveToDraft");
  };
  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    jobId !== undefined && getAllCultureData();
  }, [jobId]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Button
            variant="contained"
            color="checkers"
            sx={{
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px 16px", md24: "6px 16px" },
              borderRadius: "25px 0 10px 0",
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
              color: "white",
            }}
          >
            {i18n["postAJob.cultureadd"]}
          </Button>
        </Box>
        <Box sx={{ display: { xs: "block", tablet: "none" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              borderRadius: "0 0 0 10px",
              padding: { xs: "6px", md24: "6px 16px" },
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            // onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            upload spec
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            parse spec
          </Button>
        </Box>
        <input
          accept={fileAccept}
          ref={hiddenFileInput}
          type="file"
          onChange={handleFileChange}
          style={{ display: "none" }}
          required
        />

        <Box sx={{ display: { xs: "none", tablet: "block" } }}>
          <Button
            variant="contained"
            color="uploadJobspec"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 0 0 10px",
              textWrap: "nowrap",
              boxShadow: "none",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            onClick={handleFileClick}
            startIcon={
              <Box
                component={"img"}
                src={specIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "26px",
                }}
              />
            }
          >
            {i18n["postAJob.uploadjobspec"]}
          </Button>
          <Button
            variant="contained"
            color="precium"
            sx={{
              color: "white",
              minHeight: "45px",
              minWidth: { xs: "", md24: "175px" },
              padding: { xs: "6px", md24: "6px 16px" },
              borderRadius: "0 25px 0 0",
              textWrap: "nowrap",
              "& .MuiButton-startIcon": {
                marginLeft: "0px",
              },
            }}
            startIcon={
              <Box
                component={"img"}
                src={scrapeLinkedinIcon}
                sx={{
                  display: { xs: "none", tablet: "block" },
                  height: "20px",
                  width: "20px",
                }}
              />
            }
          >
            {i18n["postAJob.parsejobspec"]}
          </Button>
          <Typography sx={{ fontSize: "12px" }}>{specName}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "24px 41px 0 41px",
        }}
      >
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", lg: "row" },
                mb: { xs: "10px", lg: 3 },
              }}
            >
              <Box
                sx={{ width: "100%", marginBottom: { xs: "10px", lg: "0px" } }}
              >
                <InputLabel
                  htmlFor="tags"
                  sx={{
                    color: "black",
                    paddingLeft: "10px",
                    paddingBottom: "8px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {i18n["postAJob.primaryLabelReq"]}
                  <Tooltip disableInteractive>
                    <LightTooltip
                      title={"Preffered Primary personality"}
                      placement="right"
                    >
                      <Typography
                        sx={{
                          height: "15px",
                          width: "15px",
                          borderRadius: "50%",
                          fontSize: "10px",
                          // lineHeight: 0.1,
                          /* text-align: center; */
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: theme.typography.Bold,
                          boxShadow: "0px 2px 4px #00000029",
                          border: 1,
                        }}
                      >
                        i
                      </Typography>
                    </LightTooltip>
                  </Tooltip>
                </InputLabel>
                <SelectMenu
                  name="primary_personality"
                  borderRadius="10px"
                  value={cultureData?.jobDetails?.primary_personality}
                  onHandleChange={handleChange}
                  options={personalities}
                  sx={{ width: { xs: "100%", lg: "95%" } }}
                  border={
                    // !cultureData?.jobDetails?.primary_personality &&
                    errors?.find(
                      (error) => error?.key === "primary_personality"
                    )
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0"
                  }
                  placeholder={"Select your ideal primary personality"}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <InputLabel
                  htmlFor="tags"
                  sx={{
                    color: "black",
                    paddingLeft: "10px",
                    paddingBottom: "8px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {i18n["postAJob.shadowLabelReq"]}
                  <Tooltip disableInteractive>
                    <LightTooltip
                      title={"Preffered Shadow personality"}
                      placement="right"
                    >
                      <Typography
                        sx={{
                          height: "15px",
                          width: "15px",
                          borderRadius: "50%",
                          fontSize: "10px",
                          lineHeight: 0.1,
                          /* text-align: center; */
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: theme.typography.Bold,
                          boxShadow: "0px 2px 4px #00000029",
                          border: 1,
                        }}
                      >
                        i
                      </Typography>
                    </LightTooltip>
                  </Tooltip>
                </InputLabel>
                <SelectMenu
                  name="shadow_personality"
                  borderRadius="10px"
                  value={cultureData?.jobDetails?.shadow_personality}
                  onHandleChange={handleChange}
                  options={personalities}
                  sx={{ width: { xs: "100%", lg: "95%" } }}
                  border={
                    // !cultureData?.jobDetails?.shadow_personality &&
                    errors?.find((error) => error?.key === "shadow_personality")
                      ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                      : "1px solid #E0E0E0"
                  }
                  placeholder={"Select your ideal shadow/secondary personality"}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { xs: "column", lg: "row" },
                mb: { xs: "10px", lg: 3 },
              }}
            >
              <Box
                sx={{ width: "100%", marginBottom: { xs: "10px", lg: "0px" } }}
              >
                <InputLabel
                  htmlFor="tags"
                  sx={{
                    color: "black",
                    paddingLeft: "10px",
                    paddingBottom: "8px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {i18n["postAJob.traitsLabelReq"]}
                  <Tooltip disableInteractive>
                    <LightTooltip title={"preffered traits"} placement="right">
                      <Typography
                        sx={{
                          height: "15px",
                          width: "15px",
                          borderRadius: "50%",
                          fontSize: "10px",
                          lineHeight: 0.1,
                          /* text-align: center; */
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: theme.typography.Bold,
                          boxShadow: "0px 2px 4px #00000029",
                          border: 1,
                        }}
                      >
                        i
                      </Typography>
                    </LightTooltip>
                  </Tooltip>
                </InputLabel>

                <AutoComplete
                  multiple={true}
                  id="traits"
                  name="traits"
                  value={getTraitsValue()}
                  onChange={handleMultipleAutoComplete}
                  sx={{
                    width: { xs: "100%", lg: "95%" },
                    // display: "inline-table",
                    fontSize: "14px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "10px",
                    height: "auto",
                    border:
                      cultureData?.traits?.length < 5 &&
                      errors?.find((error) => error?.key === "traits")
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                  }}
                  placeholder={"Select 5 preferred personality traits"}
                  data={traits}
                  limitTags={5}
                  disableCloseOnSelect={true}
                ></AutoComplete>
              </Box>
              <Box sx={{ width: "100%" }}>
                <InputLabel
                  htmlFor="tags"
                  sx={{
                    color: "black",
                    paddingLeft: "10px",
                    paddingBottom: "8px",
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {i18n["postAJob.gritScoreLabel"]}
                  <Tooltip disableInteractive>
                    <LightTooltip title={"Ideal grit score"} placement="right">
                      <Typography
                        sx={{
                          height: "15px",
                          width: "15px",
                          borderRadius: "50%",
                          fontSize: "10px",
                          lineHeight: 0.1,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontWeight: theme.typography.Bold,
                          boxShadow: "0px 2px 4px #00000029",
                          border: 1,
                        }}
                      >
                        i
                      </Typography>
                    </LightTooltip>
                  </Tooltip>
                </InputLabel>

                <Slider
                  name="grit_score"
                  aria-label="Custom marks"
                  color="ideaGritScoreSliderNew"
                  value={cultureData?.jobDetails?.grit_score}
                  getAriaValueText={textValue}
                  step={1}
                  onChange={(event) => rangeHandler(event)}
                  valueLabelDisplay="auto"
                  valueLabelFormat={textValue}
                  marks={CULTURE_MARKS}
                  sx={{
                    width: { xs: "95%", lg: "88%" },
                    ml: 2,
                    "& .MuiSlider-rail": {
                      backgroundColor: theme.palette.eyeview100.main,
                      height: "10px",
                      border: errors?.find(
                        (error) => error?.key === "grit_score"
                      )
                        ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                        : "1px solid #E0E0E0",
                    },
                    "& .MuiSlider-track": {
                      height: "10px",
                    },
                    "& .MuiSlider-thumb": {
                      borderRadius: "15%",
                    },
                  }}
                />
              </Box>
            </Box>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: theme.typography.Bold,

                mb: 2,
              }}
            >
              {i18n["postAJob.screeningQuestionsReq"]}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: theme.typography.Regular,
                mb: 2,
              }}
            >
              {i18n["postAJob.screeningQuestionPara"]}
            </Typography>
            {cultureData?.screen_questions?.length > 0 &&
              cultureData?.screen_questions?.map((question, index) =>
                index < 5 ? (
                  <Box key={index} sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 3,
                        width: "90%",
                      }}
                    >
                      <InputBox
                        id="question"
                        sx={{
                          width: "97%",
                          borderRadius: "10px",
                          border:
                            // question.question === "" &&
                            errors?.find(
                              (error) =>
                                error?.key ===
                                  `screen_questions[${index}].question_id` ||
                                error?.key ===
                                  `screen_questions[${index}].question`
                            )
                              ? `1px solid ${theme.jobCard.jobStatus.Rejected.main}`
                              : "1px solid #E0E0E0",
                        }}
                        value={question?.question}
                        onChange={(event) => handleQuestionChange(event, index)}
                        placeholder={
                          i18n["postAJob.question"] + `0${index + 1}`
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 3,
                      }}
                    >
                      {index >= 1 ? (
                        <IconButton
                          aria-label="edit"
                          color="addQuestionButton"
                          sx={{
                            padding: "0 !important",
                          }}
                          onClick={(event) => removeQuestion(event, index + 1)}
                        >
                          <RemoveCircleIcon />
                        </IconButton>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                ) : (
                  ""
                )
              )}

            <Button
              variant="contained"
              component="label"
              onClick={addQuestion}
              color="addQuestionButton"
              sx={{
                height: "40px",
                width: "230px",
                boxShadow: "none",
                ":hover": {
                  boxShadow: "none",
                },
                borderRadius: "0 10px 10px 0",
                fontSize: "15px",
                transform: "translateX(-41px)",
                fontWeight: theme.typography.Bold,
              }}
            >
              {i18n["postAJob.addButton"]}
            </Button>
            <Box sx={{ display: "flex", flexDirection: "column", mt: "30px" }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: theme.typography.Bold,
                  mb: "22px",
                  height: "24px",
                }}
              >
                {i18n["postAJob.videoLabel"]}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  // height: "20px",
                  // mb: "16px",
                  marginBottom: "20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  {i18n["postAJob.videoParaLabel"]}
                  {i18n["postAJob.VideoParaNote"]}
                </Typography>
                <BlueSwitch
                  checked={cultureData?.jobDetails?.is_video_require}
                  onClick={(event) => {
                    setCultureData({
                      ...cultureData,
                      jobDetails: {
                        ...cultureData?.jobDetails,
                        is_video_require: event?.target?.checked,
                      },
                    });
                  }}
                />
              </Box>
              <TextField
                value={cultureData?.jobDetails?.video_description}
                onChange={handleVideoApplicationBrief}
                placeholder="Is there anything specific you’d like the candidates to cover in their video applications?"
                multiline
                rows={2}
                sx={{
                  width: "100%",
                  height: "80px",
                  fontSize: "14px",
                  borderColor: theme.palette.borderColor,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderRadius: "10px",
                      borderColor: theme.palette.borderColor,
                    },
                    "&:hover fieldset": {
                      borderColor: theme.palette.borderColor,
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: theme.palette.borderColor,
                    },
                  },
                }}
              ></TextField>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: "30px",
                maxWidth: "604px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: theme.typography.Bold,
                  mb: "22px",
                  // height: "24px",
                }}
              >
                Employer video
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  // height: "20px",
                  mb: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Will you be adding a briefing video that will be visible to
                  applicants (max 1 minute)?
                </Typography>
                <BlueSwitch
                  checked={cultureData?.jobDetails?.briffing_video_flag}
                  onClick={(event) => {
                    setCultureData({
                      ...cultureData,
                      jobDetails: {
                        ...cultureData?.jobDetails,
                        briffing_video_flag: event?.target?.checked,
                      },
                    });
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                  height: "20px",
                  mb: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Regular,
                  }}
                >
                  Do you want this video to be visible to only shortlisted
                  candidates?
                </Typography>
                <BlueSwitch
                  checked={cultureData?.jobDetails?.video_for_shortlisted_flag}
                  onClick={(event) => {
                    setCultureData({
                      ...cultureData,
                      jobDetails: {
                        ...cultureData?.jobDetails,
                        video_for_shortlisted_flag: event?.target?.checked,
                      },
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: "38px",
            }}
          >
            <Button
              variant="contained"
              color="tymeColor"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "15px",
                fontWeight: theme.typography.Bold,
                borderRadius: "25px 0 0 0",
                color: "white",
              }}
              onClick={() => saveCulture("detail")}
              startIcon={
                <Box
                  component={"img"}
                  src={previousIcon}
                  sx={{
                    height: "25px",
                    width: "25px",
                  }}
                />
              }
              // onClick={handleOpenSaveAndExitDialog}
            >
              the details
            </Button>
            <Button
              variant="contained"
              color="saveAndExitButton"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: theme.typography.fontSize.sm,
                fontWeight: theme.typography.Bold,
                borderRadius: "0",
              }}
              onClick={handleOpenSaveAndExitDialog}
            >
              save to drafts
            </Button>
            <Button
              variant="contained"
              color="precium"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "15px",
                fontWeight: theme.typography.Bold,
                borderRadius: 0,
              }}
              disabled={!cultureData?.jobDetails?.briffing_video_flag}
              onClick={() => setOpenVideoRecording(true)}
            >
              record video
            </Button>
            <Button
              variant="contained"
              color="detailButton"
              sx={{
                width: "150px",
                height: "60px",
                fontSize: "15px",
                fontWeight: theme.typography.Bold,
                borderRadius: "0 25px  0 0",
              }}
              endIcon={
                <Box
                  component={"img"}
                  src={nextIcon}
                  sx={{
                    height: "25px",
                    width: "25px",
                  }}
                />
              }
              onClick={() => saveCulture("")}
            >
              bounty
            </Button>
          </Box>
        </Box>
        <CustomDialog
          show={openVideoRecording}
          hideButton={false}
          dialogWidth="xs"
          showFooter={false}
          // title={i18n["login.signUp"]}
          isApplyJob
          padding={0}
        >
          {openVideoRecording && (
            <RecordingInProgress
              onRecordingStop={handleVideoData}
              onCloseCamera={onHandleVideoRecording}
            />
          )}
        </CustomDialog>
        <CustomDialog
          show={openReviewVideoDialog}
          hideButton={false}
          onDialogClose={() => {
            setVideoLink("");
            setRecordedVideoData(null);
            setOpenReviewVideoDialog(false);
          }}
          dialogWidth="xs"
          showFooter={false}
          isApplyJob
          padding={0}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <video
              autoPlay={false}
              loops
              preload="auto"
              poster=""
              style={{
                width: "80%",
                height: "auto",
                borderRadius: "10px",
                cursor: disabledButton ? "wait" : "pointer",
              }}
              src={videoLink}
              type="video/mp4"
              controls={false}
              onClick={() => {
                if (!disabledButton) {
                  if (VideoRef?.current?.paused) {
                    VideoRef?.current?.play();
                    setIsPlaying(true);
                  } else {
                    VideoRef?.current?.pause();
                    setIsPlaying(false);
                  }
                }
              }}
              onLoadedData={() => {
                setDisabledButton(false);
              }}
              onEnded={() => setIsPlaying(false)}
            ></video>
            <Box>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: 700,
                }}
              >
                A star performance!
              </Typography>
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: "14px",
                  fontWeight: 700,
                  marginBottom: 3,
                }}
              >
                delete or upload your video now.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                color="deleteVideoButton"
                sx={{
                  borderRadius: 0,
                  width: "50%",
                  height: "60px",
                }}
                onClick={() => {
                  setVideoLink("");
                  setRecordedVideoData(null);
                  setOpenReviewVideoDialog(false);
                }}
              >
                delete
              </Button>
              <Button
                variant="contained"
                color="uploadVideoButton"
                sx={{
                  borderRadius: 0,
                  width: "50%",
                  height: "60px",
                }}
                onClick={createFileFromBlob}
              >
                upload video
              </Button>
            </Box>
          </Box>
        </CustomDialog>
      </Box>
      {openSaveAndExitDialog && (
        <SavaAndExit
          show={openSaveAndExitDialog}
          handleOpen={handleOpenSaveAndExitDialog}
          handleModalSaveAndExit={handleModalSaveAndExit}
        />
      )}
    </>
  );
}
