import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import JobCard from "./JobCardNew";
import ButtonPanel from "../../common/ButtonPanel";
import {
  JOBS_RIGHT_STAGES_BUTTON_GROUP,
  ALERT_TYPE,
  JOBS_RIGHT_COMPANIES_BUTTON,
  BASIC_SEARCH,
  ERROR_MSG,
  AUTHORIZED_TAB_ITEMS_EMPLOYER,
  AUTHORIZED_TAB_ITEMS_CANDIDATE,
  AUTHORIZED_TAB_ITEMS_PROMOTER,
  AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER,
  AUTHORIZED_TAB_ITEMS_RECRUITER,
  AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER,
} from "../../../utils/Constants";
import {
  getFilteredJobs,
  setCardsPadding,
  setFilterExpanded,
  setLoggedIn,
  updateAllJobs,
} from "../../../redux/guest/jobsSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { getAllIndustries, setLoading } from "../../../redux/configSlice";
import { setAlert } from "../../../redux/configSlice";
import { getAllJobRoleType } from "../../../redux/jobRole";
import { getAllStages } from "../../../redux/stages";
import { getAllTypes } from "../../../redux/allTypes";
import CustomDialog from "../../common/CustomDialog";
import ApplyJobs from "./ApplyJobs";
import { Button, Paper } from "@mui/material";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import CompanyInfoCard from "../../common/CompanyInfoCard";
import { getDecodedToken, getToken, useQuery } from "../../../utils/Common";
import AllCompaniesFIlter from "./AllCompaniesFIlter";
import NoRecord from "../../common/NoRecord";
import NotLoggedIn from "../../common/NotLoggedIn";
import { getCompanyDetailNew } from "../../../redux/employer/empProfileSlice";
import ResetPassword from "../../login/ResetPassword";
import Login from "../../login/login";
import {
  profileVerify,
  setActiveTab,
  setIsAdmin,
  setSideFilter,
  topBarCurrentTab,
} from "../../../redux/login/loginSlice";
import locale from "../../../i18n/locale";
import AccountVerified from "../../common/AccountVerified";
import ResendMailBox from "../../login/ResendMailBox";
import useViewport from "../../common/ViewPort";
import CreateNewPassword from "../../login/CreateNewPassword";
import ForgotPasswordConfirmationModal from "../../login/ForgotPasswordConfirmationModal";

export default function Jobs({ handleJobsListingDynamicTheme }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "auto",
    width: { xs: "360px", tablet: "450px" },
    bgcolor: "background.paper",
    borderRadius: "25px",
    boxShadow: "none !important",
    ".&focus-visible": {
      outlineColor: "none",
    },
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const i18n = locale.en;
  const { companyid } = useParams();
  const { width } = useViewport();
  const query = useQuery();
  const selectedIndustries = useRef([]);
  const selectedJobTypes = useRef([]);
  const selectedAllStages = useRef([]);
  const selectedAllPost = useRef([]);
  const selectedAllTypes = useRef([]);
  const currentPageJobsCount = useSelector(
    (state) => state?.configjobslice?.currentPageJobsCount
  );
  const filterExpanded = useSelector(
    (state) => state?.configjobslice?.filterExpanded
  );
  const { isSideFilter } = useSelector((state) => state.login);
  const jobs = useSelector((state) => state?.configjobslice?.allJobs);
  const showBox = useSelector((state) => state?.configjobslice?.showBox);
  const allIndustries = useSelector((state) => state?.config?.industries);
  const allJobTypes = useSelector((state) => state?.jobtype?.jobRoleType);
  const allStages = useSelector((state) => state?.configstages?.stages);
  const allTypes = useSelector((state) => state?.configAllTypes?.types);

  const [filters, setFilters] = useState([allIndustries?.[0]?.id]);
  const [filtersJobType, setFiltersJobType] = useState([allJobTypes?.[0]?.id]);
  const [filtersJobStage, setFiltersJobStage] = useState([allStages?.[0]?.id]);
  const [filtersType, setFiltersType] = useState([allTypes?.[0]?.id]);
  const [lastKey, setLastKey] = useState("");
  const [searchedJobs, setSearchedJobs] = useState("");
  const [openApplyJobDialog, setopenApplyJobDialog] = useState(false);
  const [openNotLoggedIn, setOpenNotLoggedIn] = useState(false);
  const [resetPassword, setResetPasssword] = useState(false);
  const [newToken, setNewToken] = useState();
  const [createPassword, setCreatePasssword] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [basicData, setBasicData] = useState(BASIC_SEARCH);
  const [rightExpand, setRightExpand] = useState(true);
  const [leftExpanded, setLeftExpand] = useState(true);
  const [selectedJobId, setSelectedJobId] = useState(0);
  const [jobVideoRequired, setJobVideoRequired] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const [favouriteFilter, setFavouriteFilter] = useState({
    recent: "",
    favourite: "",
    appliedJobs: "",
  });
  const [isForgotPassword, setIsForgotPassowrd] = useState(false);

  const [itemsFetched, setItemsFetched] = useState(false);
  const gridRef = useRef();

  const [companySize, setCompanySize] = useState("");
  const [companyType, setCompanyType] = useState("");
  const token = localStorage?.getItem("token");
  let decodedToken = getDecodedToken(getToken());
  const [newCardWidth, setNewCardWidth] = useState(340);
  const [newPadding, setNewPadding] = useState(15);
  const [companyDetail, setCompanyDetail] = useState([]);
  const [companyFollowed, setCompanyFollowed] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    Boolean(localStorage.getItem("token"))
  );
  const [resendMailBox, setResendMailBox] = useState(false);
  const [email, setEmail] = useState("");
  const [activeStep, setActiveStep] = useState(1);
  function calculateCardWidthAndPadding(totalWidth) {
    let cardWidth = 340;
    let padding = 15;
    let numCards = Math.floor(totalWidth / (cardWidth + padding));
    let remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    while (remainingSpace > 0) {
      if (remainingSpace < cardWidth + padding) {
        if (padding < 30) {
          padding++;
          remainingSpace =
            totalWidth - (numCards * cardWidth + padding * (numCards + 1));
        } else {
          break;
        }
      } else {
        numCards++;
      }
    }

    cardWidth = (totalWidth - padding * (numCards + 1)) / numCards;
    if (cardWidth > 380) {
      cardWidth = 380;
    }
    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    padding = padding + remainingSpace / (numCards + 1);

    remainingSpace =
      totalWidth - (numCards * cardWidth + padding * (numCards + 1));

    setNewCardWidth(cardWidth - 1);
    setNewPadding(padding);
    dispatch(setCardsPadding(padding));
    return { numCards, cardWidth, padding, remainingSpace };
  }

  const getNewWidth = () => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
  };
  useLayoutEffect(() => {
    if (gridRef?.current) {
      calculateCardWidthAndPadding(gridRef?.current?.clientWidth);
    }
    window.addEventListener("resize", getNewWidth);
    return () => {
      window.removeEventListener("resize", getNewWidth);
    };
  }, [itemsFetched, isSideFilter]);

  const onHandleClose = () => {
    setopenApplyJobDialog(false);
  };

  const onClose = () => {
    setOpenNotLoggedIn(false);
  };

  const getTypes = async () => {
    dispatch(getAllTypes());
  };
  const getIndustries = async () => {
    dispatch(getAllIndustries());
  };
  const getJobTypes = async () => {
    dispatch(getAllJobRoleType());
  };
  const getStages = async () => {
    dispatch(getAllStages());
  };

  const getCompanyData = async () => {
    try {
      dispatch(setLoading({ value: true, apiName: "getCompanyData" }));
      const data = {
        company_id: Number(companyid),
      };
      const { payload } = await dispatch(getCompanyDetailNew(data));
      if (payload?.status === "success") {
        setCompanyFollowed(payload?.data?.followflag);
        setCompanyDetail(payload?.data);
        dispatch(setLoading({ value: false, apiName: "getCompanyData" }));
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: payload?.message,
          })
        );
        dispatch(setLoading({ value: false, apiName: "getCompanyData" }));
      }
    } catch (error) {
      dispatch(setLoading({ value: false, apiName: "getCompanyData" }));
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };
  const getProfileVerify = async () => {
    try {
      const { payload } = await dispatch(
        profileVerify({ token: query.get("token") })
      );
      if (payload?.status === "success") {
        if (query.get("createPasswordFlag")) {
          setCreatePasssword(true);
        } else {
          if (localStorage.getItem("token")) {
            localStorage.setItem("token", payload?.token);
          } else {
            setShowLogin(true);
          }
        }
        navigate("/jobs");
        dispatch(setLoggedIn(false));
      } else if (payload?.status === "error" && payload?.data?.isverifyflag) {
        setShowLogin(true);
        navigate("/jobs");
        dispatch(setLoggedIn(false));
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: i18n["alertMessage.alreadyverified"],
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: ERROR_MSG,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  const getJobList = async (
    selectedFilters = [],
    jobtype = [],
    jobstage = [],
    personalityType = [],
    lastkeyy = 0,
    job_title = "",
    filteralltype = favouriteFilter
  ) => {
    dispatch(setLoading({ value: true, apiName: "getJobList" }));

    let data = {
      selectedFilters:
        selectedFilters?.[0] !== 1111 ? selectedFilters?.toString() : "",
      lastKey: lastkeyy?.toString(),
      jobtype: jobtype?.[0] !== 1111 ? jobtype?.toString() : "",
      jobstage: jobstage?.[0] !== 1111 ? jobstage?.toString() : "",
      personalityType:
        personalityType?.[0] !== 1111 ? personalityType?.toString() : "",
      favourites: filteralltype?.favourite || "",
      recentjob: filteralltype?.recent || "",
      appliedjob: filteralltype?.appliedJobs || "",
      company_size:
        query.get("companySize") !== null
          ? decodeURIComponent(query.get("companySize"))
          : companySize?.toString(),
      company_type:
        query.get("companyType") !== null
          ? decodeURIComponent(query.get("companyType"))
          : companyType?.toString(),
      ...basicData,
      job_title: query.get("job_title")
        ? query.get("job_title")
        : basicData?.job_title,
      query: query.get("query") ? query.get("query") : "",

      town_id: query.get("location")
        ? query.get("location")
        : query.size < 1
        ? ""
        : basicData?.town_id,
      region_id: query.get("region_id")
        ? query.get("region_id")
        : query.size < 1
        ? ""
        : basicData?.region_id,
      tag_id: query.get("tag_id")
        ? query.get("tag_id")
        : query.size < 1
        ? ""
        : basicData?.tag_id,
      tool_id: query.get("tool_id")
        ? query.get("tool_id")
        : query.size < 1
        ? ""
        : basicData?.tool_id,
      company_id:
        query.get("company_id") || companyid
          ? query.get("company_id") || Number(companyid)
          : query.size < 1
          ? ""
          : basicData?.company_id,
      currency_id: query.get("currency_id")
        ? query.get("currency_id")
        : query.size < 1
        ? ""
        : basicData?.currency_id,
      highest_qualification_id: query.get("highest_qualification_id")
        ? query.get("highest_qualification_id")
        : query.size < 1
        ? ""
        : basicData?.highest_qualification_id,
      salary: query.get("salary")
        ? query.get("salary")
        : query.size < 1
        ? ""
        : basicData?.salary,
      experience: query.get("experience")
        ? query.get("experience")
        : query.size < 1
        ? ""
        : basicData?.experience,
      decodedToken: decodedToken,
    };

    if (location?.state?.industry_id) {
      data = {
        ...data,
        selectedFilters: location?.state?.industry_id,
      }
    }

    const { payload } = await dispatch(getFilteredJobs(data));

    if (payload?.status === "success") {
      if (payload?.data?.length <= 0) {
      } else {
        setLastKey(payload?.pageNumber + 1);
        setItemsFetched(true);
      }
      dispatch(setLoading({ value: false, apiName: "getJobList" }));
    }
  };

  const setNewWidthandPadding = () => {
    if (gridRef?.current) {
    }
  };

  const onChangeFilter = (selectedFilter) => {
    setIsFilterApplied(true);
    let industry = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allIndustries?.find(
        (jobtype) => jobtype?.id === type
      );
      industry?.push(selectedJobType?.name);
    });
    handleFilterSelection("filter", industry);
    dispatch(updateAllJobs());
    setLastKey("");
    setFilters(selectedFilter);
    getJobList(
      selectedFilter,
      filtersJobType,
      filtersJobStage,
      filtersType,
      "",
      searchedJobs,
      favouriteFilter
    );
  };
  const onChangeFilterJobType = (selectedFilter) => {
    setIsFilterApplied(true);
    let jobs = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allJobTypes?.find(
        (jobtype) => jobtype?.id === type
      );
      jobs?.push(selectedJobType?.name?.replace("Crayon", "")?.trim());
    });
    dispatch(updateAllJobs());
    setLastKey("");
    setFiltersJobType(jobs);
    handleFilterSelection("jobType", jobs);
    getJobList(
      filters,
      jobs,
      filtersJobStage,
      filtersType,
      "",
      searchedJobs,
      favouriteFilter
    );
  };
  const onChangeFilterJobStage = (selectedFilter) => {
    setIsFilterApplied(true);
    let stage = [];
    selectedFilter?.map((type) => {
      let selectedJobType = allStages?.find(
        (stagetype) => stagetype?.id === type
      );
      stage?.push(selectedJobType?.name);
    });
    handleFilterSelection("stage", stage);
    dispatch(updateAllJobs());
    setLastKey("");
    setFiltersJobStage(selectedFilter);
    getJobList(
      filters,
      filtersJobType,
      selectedFilter,
      filtersType,
      "",
      searchedJobs,
      favouriteFilter
    );
  };
  const onChangeFilterType = (selectedFilter) => {
    setIsFilterApplied(true);
    let selectedtypes = [];
    selectedFilter?.map((types) => {
      let selectedJobType = allTypes?.find((type) => type?.id === types);
      selectedtypes?.push(selectedJobType?.name);
    });
    handleFilterSelection("Type", selectedtypes);
    dispatch(updateAllJobs());
    setLastKey("");
    setFiltersType(selectedFilter);
    getJobList(
      filters,
      filtersJobType,
      filtersJobStage,
      selectedFilter,
      "",
      searchedJobs,
      favouriteFilter
    );
  };

  const onChangefavourite = (selectedFilter) => {
    setIsFilterApplied(true);
    if (
      (decodedToken && decodedToken?.data?.role_id !== 4) ||
      selectedFilter?.includes(2) ||
      selectedFilter?.includes(1111)
    ) {
      let posts = [];
      selectedFilter?.map((types) => {
        let selectedJobType = JOBS_RIGHT_STAGES_BUTTON_GROUP?.find(
          (type) => type?.id === types
        );
        posts?.push(selectedJobType?.name);
      });
      handleFilterSelection(
        "activity",
        selectedFilter?.includes(1111) ? ["all activity"] : posts
      );
      dispatch(updateAllJobs());
      setLastKey("");
      const allTypeFilter = {
        recent: selectedFilter?.includes(2) ? true : "",
        favourite: selectedFilter?.includes(3) ? true : "",
        appliedJobs: selectedFilter?.includes(4) ? true : "",
      };
      setFavouriteFilter(allTypeFilter);
      getJobList(
        filters,
        filtersJobType,
        filtersJobStage,
        filtersType,
        "",
        searchedJobs,
        allTypeFilter
      );
    } else {
      handleFilterSelection("activity", ["all activity"]);
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg:
            decodedToken?.data?.role_id !== 4
              ? "Login first to apply this filter"
              : "Login as candidate to apply this filter",
        })
      );
    }
  };
  const calculateDynamicHeight = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 600) {
      return `calc(100vh - 95px)`;
    } else if (screenWidth < 900) {
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1366) {
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 1920) {
      return `calc(100vh - 80px)`;
    } else if (screenWidth < 2559) {
      return `calc(100vh - 100px)`;
    } else {
      return `calc(100vh - 120px)`;
    }
  };

  const handleDropDownFilters = (selectedFilter) => {
    setIsFilterApplied(true);
    if (selectedFilter === 1) {
      setCompanySize("");
      setCompanyType("");
    }
  };

  useEffect(() => {
    setBasicData({
      job_title: query.get("job_title")
        ? query.get("job_title")
        : basicData?.job_title,
      town_id: query.get("location")
        ? query
            .get("location")
            .split(",")
            .map((item) => Number(item))
        : basicData?.town_id,
      region_id: query.get("region_id")
        ? query
            .get("region_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.region_id,
      tag_id: query.get("tag_id")
        ? query
            .get("tag_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.tag_id,
      tool_id: query.get("tool_id")
        ? query
            .get("tool_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.tool_id,
      company_id:
        query.get("company_id") || companyid
          ? query
              ?.get("company_id")
              ?.split(",")
              ?.map((item) => Number(item)) || Number(companyid)
          : basicData?.company_id,
      currency_id: query.get("currency_id")
        ? query
            .get("currency_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.currency_id,
      highest_qualification_id: query.get("highest_qualification_id")
        ? query
            .get("highest_qualification_id")
            .split(",")
            .map((item) => Number(item))
        : basicData?.highest_qualification_id,
      salary: query.get("salary")
        ? query
            .get("salary")
            .split(",")
            .map((item) => Number(item))
        : basicData?.salary,
      experience: query.get("experience")
        ? query
            .get("experience")
            .split(",")
            .map((item) => Number(item))
        : basicData?.experience,
    });
  }, []);

  const handleFilterSelection = (paramType, filterName) => {
    if (
      filterName[0] === "all job types" ||
      filterName[0] === "all industries" ||
      filterName[0] === "all stages" ||
      filterName[0] === "all activity" ||
      filterName[0] === "all types"
    ) {
      filterName[0] === "all industries" && (selectedIndustries.current = []);
      filterName[0] === "all job types" && (selectedJobTypes.current = []);
      filterName[0] === "all stages" && (selectedAllStages.current = []);
      filterName[0] === "all activity" && (selectedAllPost.current = []);
      filterName[0] === "all types" && (selectedAllTypes.current = []);
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.delete(paramType);
      navigate(`/jobs?${queryParams.toString()}`);
      return;
    } else {
      const updatedFilters = selectedFilters?.includes(filterName)
        ? selectedFilters?.filter((filter) => filter !== filterName)
        : [filterName];
      const queryParams = new URLSearchParams(window.location.search);
      queryParams.set(paramType, updatedFilters?.join(","));
      navigate(`${window.location.pathname}?${queryParams.toString()}`);
      setSelectedFilters(updatedFilters);
    }
  };

  const onHandleLogin = async (payload) => {
    setShowLogin(false);
    const token = payload?.token;
    const decodedToken = getDecodedToken(token);
    const user = parseInt(decodedToken?.data?.role_id);
    const isPromoter = parseInt(decodedToken?.data?.promoter);
    const profileCompletion = decodedToken?.data?.profile_percent_complete;
    const isNavigate = decodedToken?.data?.employerProfileFlag;

    localStorage.setItem("token", token);
    onHandleClose();

    let tabs;
    if ([1, 2, 8].includes(user)) {
      dispatch(setIsAdmin(true));
      navigate("/admin/adminJobs/active-jobs", { replace: true });
    } else if (user === 4) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_EMPLOYER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_EMPLOYER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/employer/my-jobs", { replace: true });
        dispatch(setActiveTab("/employer/my-jobs"));
      } else {
        navigate("/employer/my-profile", { replace: true });
        dispatch(setActiveTab("/employer/my-profile"));
      }
    } else if (user === 5) {
      tabs = AUTHORIZED_TAB_ITEMS_PROMOTER;
      navigate("/promoter/shared-jobs", { replace: true });
      dispatch(setActiveTab("/promoter/shared-jobs"));
    } else if (user === 6) {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_RECRUITER_PROMOTER
        : AUTHORIZED_TAB_ITEMS_RECRUITER;
      if (profileCompletion === 100 || isNavigate) {
        navigate("/recruiter/my-jobs", { replace: true });
        dispatch(setActiveTab("/recruiter/my-jobs"));
      } else {
        navigate("/recruiter/my-profile", { replace: true });
        dispatch(setActiveTab("/recruiter/my-profile"));
      }
    } else {
      tabs = isPromoter
        ? AUTHORIZED_TAB_ITEMS_CANDIDATE_PROMOTER
        : AUTHORIZED_TAB_ITEMS_CANDIDATE;
      navigate("/candidate/my-jobs", { replace: true });
      dispatch(setActiveTab("/candidate/my-jobs"));
    }

    dispatch(topBarCurrentTab(tabs));
    setIsLoggedIn(true);
  };

  const toggleForm = () => {
    setShowLogin((prevState) => !prevState);
    setShowSignup((prevState) => !prevState);
  };

  const handleCloseLogin = () => {
    setShowLogin(false);
  };

  const closeForgotPassword = () => {
    setShowSignup(true);
  };

  useEffect(() => {
    setNewWidthandPadding();
  }, []);

  useEffect(() => {
    if (decodedToken?.data?.role_id === 3 || decodedToken === undefined) {
      handleJobsListingDynamicTheme(decodedToken?.data?.personality);
    }
  }, [decodedToken]);

  useEffect(() => {
    const fetchData = async () => {
      if (companyid) {
        await getCompanyData();
      }

      let industry = [];
      let stages = [];
      let types = [];
      const allTypeFilter = {
        recent: query.get("activity")?.includes("recent") ? true : "",
        favourite: query.get("activity")?.includes("favourite") ? true : "",
        appliedJobs: query.get("activity")?.includes("applied jobs")
          ? true
          : "",
      };

      await getIndustries();
      await getJobTypes();
      await getStages();
      await getTypes();

      dispatch(updateAllJobs());
      if (JSON.parse(localStorage.getItem("detailsFilterIndustry"))) {
        await getJobList(
          JSON.parse(localStorage.getItem("detailsFilterIndustry")),
          query.get("jobType") !== null &&
            query.get("jobType") !== "all job types"
            ? query.get("jobType")
            : [allJobTypes?.[0]?.id],
          stages,
          types,
          "",
          searchedJobs,
          allTypeFilter
        );
        localStorage.clear("detailsFilterIndustry");
      } else if (JSON.parse(localStorage.getItem("detailsFilterType"))) {
        await getJobList(
          industry,
          query.get("jobType") !== null &&
            query.get("jobType") !== "all job types"
            ? query.get("jobType")
            : [allJobTypes?.[0]?.id],
          stages,
          JSON.parse(localStorage.getItem("detailsFilterType")),
          "",
          searchedJobs,
          allTypeFilter
        );
        localStorage.clear("detailsFilterIndustry");
      } else if (JSON.parse(localStorage.getItem("detailsFilterStage"))) {
        await getJobList(
          industry,
          query.get("jobType") !== null &&
            query.get("jobType") !== "all job types"
            ? query.get("jobType")
            : [allJobTypes?.[0]?.id],
          JSON.parse(localStorage.getItem("detailsFilterStage")),
          types,
          "",
          searchedJobs,
          allTypeFilter
        );
        localStorage.clear("detailsFilterIndustry");
      } else {
        await getJobList(
          industry,
          query.get("jobType") !== null &&
            query.get("jobType") !== "all job types"
            ? query.get("jobType")
            : [allJobTypes?.[0]?.id],
          stages,
          types,
          "",
          searchedJobs,
          allTypeFilter
        );
      }
    };

    fetchData();
  }, [token, location?.pathname, companyid]);

  useEffect(() => {
    let industry = [];
    let jobtypes = [];
    let stages = [];
    let post = [];
    let types = [];

    query
      .get("filter")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allIndustries?.find(
          (jobtype) => jobtype?.name === type
        );
        industry?.push(selectedJobType?.id);
      });
    selectedIndustries.current = industry;

    query
      .get("jobType")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allJobTypes?.find(
          (jobtype) => jobtype?.name === type || jobtype?.name.includes(type)
        );
        jobtypes?.push(selectedJobType?.id);
      });
    selectedJobTypes.current = jobtypes;

    query
      .get("stage")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allStages?.find(
          (jobtype) => jobtype?.name === type
        );
        stages?.push(selectedJobType?.id);
      });
    selectedAllStages.current = stages;

    query
      .get("Type")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = allTypes?.find(
          (jobtype) => jobtype?.name === type
        );
        types?.push(selectedJobType?.id);
      });
    selectedAllTypes.current = types;

    query
      .get("activity")
      ?.split(",")
      ?.map((type) => {
        let selectedJobType = JOBS_RIGHT_STAGES_BUTTON_GROUP?.find(
          (jobtype) => jobtype?.name === type
        );
        post?.push(selectedJobType?.id);
      });
    selectedAllPost.current = post;

    const allTypeFilter = {
      recent: query.get("activity")?.includes("recent") ? true : "",
      favourite: query.get("activity")?.includes("favourite") ? true : "",
      appliedJobs: query.get("activity")?.includes("applied jobs") ? true : "",
    };

    setFilters(industry);
    setFiltersJobType(jobtypes);
    setFiltersJobStage(stages);
    setFavouriteFilter(allTypeFilter);
    setFiltersType(types);

    setBasicData(BASIC_SEARCH);
    if (
      allIndustries?.length > 0 &&
      allJobTypes?.length > 0 &&
      allStages?.length > 0 &&
      allTypes?.length > 0
    ) {
      if (isFilterApplied) {
        dispatch(updateAllJobs());
        getJobList(
          industry,
          query.get("jobType") !== null &&
            query.get("jobType") !== "all job types"
            ? query.get("jobType")
            : [allJobTypes?.[0]?.id],
          stages,
          types,
          "",
          searchedJobs,
          allTypeFilter
        );
      }
    }
  }, [
    allIndustries,
    allJobTypes,
    allStages,
    allTypes,
    query.get("companySize"),
    query.get("companyType"),
  ]);

  useEffect(() => {
    if (location?.pathname?.includes("profileverify")) {
      // localStorage?.clear();
      setNewToken(query?.get("token"));
      getProfileVerify();
    }
    if (location?.pathname?.includes("resetpassword")) {
      setResetPasssword(true);
    }
  }, [location]);

  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true));
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false));
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);

  if (width === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        position: "relative",
      }}
    >
      {width < 768 && width !== 0 && isSideFilter && (
        <Box
        onClick={() => dispatch(setSideFilter(false))}
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            background: "rgba(0,0,0, .2)",
          }}
        ></Box>
      )}
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          position: `${width < 768 ? "absolute" : "relative"}`,
          width: isSideFilter ? "125" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
          zIndex: "30",
        }}
      >
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {/* <Button
          variant="contained"
          sx={{
            position: "static",
            borderRadius: "0 10px 10px 0",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <LeftArrow /> : <RightArrow />}
        </Button> */}
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px)",
              },
              width: `${width < 768 && "135px"}`,
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              overflowX: "hidden",
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="filterSec"
          >
            <Box
              sx={{
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedIndustries?.current}
                panelData={allIndustries}
                side="left"
                color="filterButtons"
                onChangeFilter={onChangeFilter}
                type="common"
              />
            </Box>
            {/* <Button
              variant="contained"
              sx={{
                position: "static",
                borderRadius: "0 10px 10px 0",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="filterButtons"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpand((prevState) => !prevState);
                dispatch(setFilterExpanded(!filterExpanded));
              }}
            >
              {isSideFilter ? <LeftArrow /> : <RightArrow />}
            </Button> */}
            <style>
              {`.filterSec::-webkit-scrollbar {
                      display: none; /* Set the background color of the scrollbar */
                    }`}
            </style>
          </Box>
        )}
      </Box>

      {/* {!isLoading && ( */}
      <Box
        item
        sx={{
          display: "flex",
          flexGrow: 1,
          // zIndex:isSideFilter && -1,
          zIndex: width < 768 && isSideFilter && -1,
          alignItems: "center",
          flexDirection: "column",
          width: `calc(100vw - 255px)`,
          position: "sticky",
        }}
        ref={gridRef}
        className="grigBox"
      >
        <InfiniteScroll
          height={calculateDynamicHeight()}
          key={`${filters} + ${filtersJobType} + ${filtersJobStage} + ${filtersType}+${searchedJobs} +${favouriteFilter}`}
          dataLength={jobs?.length}
          marginBottom='20px !important'
          next={() =>
            getJobList(
              filters,
              filtersJobType,
              filtersJobStage,
              filtersType,
              lastKey,
              searchedJobs,
              favouriteFilter
            )
          }
          scrollThreshold={"200px"}
          hasMore={true}
          style={{
            overflowX: "hidden",
          }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
          <Box
            sx={{
              margin: "auto",
              height: "auto",
              flexDirection: "column",
              alignItems: { xs: "center", xl: "flex-start" },
              display: companyid ? "flex" : "none",
              width: "100%",
              paddingLeft: "30px",
              paddingRight: "30px",
              marginTop: width <= 768 ? "45px" : "55px",
              boxSizing: "border-box",
            }}
          >
            {companyid && (
              <CompanyInfoCard
                companyid={Number(companyid)}
                companyDetail={companyDetail}
                companyFollowed={companyFollowed}
                setCompanyFollowed={setCompanyFollowed}
                leftExpanded={isSideFilter}
                newCardWidth={newCardWidth}
                newPadding={newPadding}
              />
            )}
          </Box>

          <Grid
            container
            gap={width <= 768 ? "10px" : `${newPadding}px`}
            columns={{
              xs: 1,
              md: 1,
              lg: 3,
              mlg: 3.5,
              xl: 4,
              xxl: 6,
              xxxl: 8,
            }}
            justifyContent={width <= 768 && "center"}
            sx={{
              position: "relative",
              padding: width <= 768 ? "10px 10px" : `0 ${newPadding}px`,
              top: companyid ? "20px" : width <= 768 ? "35px" : "55px",
              paddingBottom: '35px !important'
            }}
          >
            {jobs?.length > 0
              ? jobs?.map((job, index) => (
                  <Box
                    sx={{
                      width: newCardWidth,
                    }}
                  >
                    <JobCard
                      job={job}
                      setJobId={setSelectedJobId}
                      setJobVideoRequired={setJobVideoRequired}
                      onHandleClose={onClose}
                      setopenApplyJobDialog={setopenApplyJobDialog}
                      onClose={onHandleClose}
                      setOpenNotLoggedIn={setOpenNotLoggedIn}
                      setSelectedCard={setSelectedCard}
                      jobslength={jobs?.length}
                      index={index}
                      currentPageJobsCount={currentPageJobsCount}
                      mobileText={width > 1024 ? false : true}
                    />
                  </Box>
                ))
              : showBox && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <NoRecord />
                  </Box>
                )}
          </Grid>
          <style>
            {`.infinite-scroll-component::-webkit-scrollbar {
                      display:none;
              }
              
              .infinite-scroll-component__outerdiv{
                width:100%
              }
                     `}
          </style>
        </InfiniteScroll>
      </Box>
      {/* )} */}
      <Box
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: `${width >= 768 && "10px"}`,
          direction: "rtl",
          position: `${width < 768 ? "absolute" : "relative"}`,
          zIndex: "30",
          right: "0",
          width: isSideFilter ? "125px" : "30px",
          minWidth: isSideFilter ? "125px" : "30px",
        }}
      >
        <Box
          sx={{
            minWidth: "30px",
            width: "30px",
            height: "45px",
            minHeight: "45px",
          }}
        ></Box>
        {/* <Button
          variant="contained"
          sx={{
            position: "sticky",
            top: 0,
            borderRadius: "10px 0 0 10px",
            minWidth: "30px",
            width: "30px",
            height: "45px",
          }}
          color="filterButtons"
          onClick={() => {
            setLeftExpand((prevState) => !prevState);
            setRightExpand((prevState) => !prevState);
            dispatch(setFilterExpanded(!filterExpanded));
          }}
        >
          {isSideFilter ? <RightArrow /> : <LeftArrow />}
        </Button> */}
        {isSideFilter && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: `${width < 768 && "135px"}`,
              minWidth: "125px",
              paddingTop: `${width < 768 && "10px"}`,
              background: `${width > 0 && width < 768 && "#EBECF3"}`,
              boxShadow: `${
                width > 0 && width < 768 && "0px 5px 10px #00000029"
              }`,
              opacity: `${width < 768 && "1"}`,
              borderRadius: `${width < 768 && "10px"}`,
              height: {
                xs: `calc(100vh - 95px)`,
                sm: `calc(100vh - 120px )`,
                md: `calc(100vh - 120px)`,
                lg: `calc(100vh - 135px)`,
                xl: `calc(100vh - 155px)`,
                xxl: "calc(100vh - 175px )",
              },
              overflowY: isSideFilter ? "scroll" : "unset",
            }}
            className="rightfilterSec"
          >
            <style>
              {`.rightfilterSec::-webkit-scrollbar {
                      display:none;
                    }
                    `}
            </style>
            <Paper
              sx={{
                background: "transparent",
                boxShadow: 0,
                gap: "10px",
                display: "flex",
                flexDirection: "column",
                maxWidth: "125px",
              }}
            >
              <ButtonPanel
                selected={selectedJobTypes.current}
                topMargin={true}
                panelData={allJobTypes}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobType}
              />
              <AllCompaniesFIlter
                panelData={JOBS_RIGHT_COMPANIES_BUTTON}
                setCompanySize={setCompanySize}
                setCompanyType={setCompanyType}
                companySize={companySize}
                companyType={companyType}
                onChangeFilter={handleDropDownFilters}
                side="right"
                color="filterButtons"
              />
              <ButtonPanel
                selected={selectedAllStages.current}
                panelData={allStages}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterJobStage}
              />
              <ButtonPanel
                selected={selectedAllPost.current}
                panelData={JOBS_RIGHT_STAGES_BUTTON_GROUP}
                onChangeFilter={onChangefavourite}
                side="right"
                color="filterButtons"
              />
              <ButtonPanel
                selected={selectedAllTypes.current}
                panelData={allTypes}
                side="right"
                color="filterButtons"
                onChangeFilter={onChangeFilterType}
              />
            </Paper>
            {/* <Button
              variant="contained"
              sx={{
                position: "sticky",
                top: 0,
                borderRadius: "10px 0 0 10px",
                minWidth: "30px",
                width: "30px",
                height: "45px",
              }}
              color="filterButtons"
              onClick={() => {
                setLeftExpand((prevState) => !prevState);
                setRightExpand((prevState) => !prevState);
                dispatch(setFilterExpanded(!filterExpanded));
              }}
            >
              {isSideFilter ? <RightArrow /> : <LeftArrow />}
            </Button> */}
          </Box>
        )}
      </Box>

      {openApplyJobDialog && (
        <ApplyJobs
          jobs={jobs}
          jobId={selectedJobId}
          setopenApplyJobDialog={setopenApplyJobDialog}
          jobVideoRequired={jobVideoRequired}
          openApplyJobDialog={openApplyJobDialog}
          setActiveStep={setActiveStep}
        />
      )}
      <CustomDialog
        show={openNotLoggedIn}
        hideButton={false}
        onDialogClose={onClose}
        dialogWidth="xs"
        padding={0}
        showFooter={false}
      >
        <NotLoggedIn
          jobId={selectedJobId}
          setOpenNotLoggedIn={setOpenNotLoggedIn}
          onClose={onClose}
        />
      </CustomDialog>

      <CreateNewPassword
        open={createPassword}
        close={() => setCreatePasssword(false)}
        setOpenLogin={setShowLogin}
        onHandleLogin={onHandleLogin}
        newToken={newToken}
      />
      <ResetPassword
        open={resetPassword}
        close={() => setResetPasssword(false)}
        setOpenLogin={setShowLogin}
      />

      <AccountVerified
        openEdit={openVerify}
        handleClose={() => setOpenVerify(false)}
        setShowLogin={setShowLogin}
      />

      <Login
        handleLogin={onHandleLogin}
        openFunc={showLogin}
        toggleForm={toggleForm}
        closeFunc={handleCloseLogin}
        handleOpenLogin={setShowLogin}
        handleOpenSignUp={closeForgotPassword}
        handleForgotPassword={closeForgotPassword}
        setResendMailBox={setResendMailBox}
        setEmail={setEmail}
        handleConfirmPasswordChange={() => setIsForgotPassowrd(true)}
      />
      <ResendMailBox
        setResendMailBox={setResendMailBox}
        email={email}
        open={resendMailBox}
        handleClose={() => setResendMailBox(false)}
      />
      {isForgotPassword && (
        <ForgotPasswordConfirmationModal
          openFunc={isForgotPassword}
          closeFunc={() => setIsForgotPassowrd(false)}
        />
      )}
    </Box>
  );
}
