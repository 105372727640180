import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import referralsIcon from "../../../../assets/Padding Excluded/TrackIcon.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import profile from "../../../../assets/user_logo.svg";

export default function ReferralsBox({
  text,
  referralsData = [],
  isLoading = false,
  count,
}) {
  const theme = useTheme();
  return (
    <>
      <Button
        variant="contained"
        color="pinkButton300"
        sx={{
          width: "150px",
          height: "45px",
          borderRadius: "25px 0 25px 0",
          padding: "6px !important",
          boxShadow: "none",
        }}
        startIcon={
          <Box
            component={"img"}
            src={referralsIcon}
            sx={{ width: 20, height: 20 }}
          />
        }
      >
        referrals ({count})
      </Button>
      <Box
        sx={{
          padding: "16px !important",
        }}
      >
        <Typography sx={{ fontSize: "11px", fontWeight: 700 }}>
          {text}
        </Typography>
        <Box
          id="talentList"
          sx={{ overflow: "hidden", height: "350px", mt: 1 }}
        >
          <InfiniteScroll
            style={{
              height: "100%",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              paddingBottom: '35px !important'
            }}
            dataLength={referralsData?.length}
            hasMore={true}
            scrollableTarget="talentList"
            loader={
              isLoading && (
                <Box
                  style={{
                    margin: "15px 0 0 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="inherit" size={20} />
                </Box>
              )
            }
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {referralsData?.length > 0 ? (
              referralsData?.map((item, index) => {
                return (
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <Box
                      component="img"
                      className="profileAvatar"
                      alt="crayon logo"
                      src={
                        item?.profile_url !== "NO URL"
                          ? item?.profile_url
                          : profile
                      }
                      sx={{
                        mr: 1,
                        height: "50px !important",
                        width: "50px !important",
                        borderRadius: "5px",
                      }}
                    />
                    <Link
                      to={`/admin/admin-talent/all-talent/candidate-cv/${item?.user_id}`}
                      target={"_blank"}
                      style={{
                        textDecoration: "none",
                        color: theme.palette.black,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            width: "100px",
                            whiteSpace: "nowrap", // Prevents text from wrapping
                            overflow: "hidden", // Hides any overflowing content
                            textOverflow: "ellipsis",
                          }}
                        >
                          {`${item?.first_name} ${item?.last_name}`}
                        </Typography>

                        <Typography
                          sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            width: "170px",
                          }}
                        >
                          {item?.jobTitle || "-"}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                );
              })
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: theme.typography.Bold,
                  }}
                >
                  No Data
                </Typography>
              </Box>
            )}
            <style>
              {`.infinite-scroll-component::-webkit-scrollbar {
                          width: 7px !important;
                          background-color: #F5F5F5; /* Set the background color of the scrollbar */
                        }

                        .infinite-scroll-component__outerdiv {
                          height:100%
                        }

                        .infinite-scroll-component::-webkit-scrollbar-thumb {
                          background-color: #888c; /* Set the color of the scrollbar thumb */
                        }`}
            </style>
          </InfiniteScroll>
        </Box>
      </Box>
    </>
  );
}
