import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { adminJobsFilter } from "../../../redux/admin/jobsSlice";
import { setAlert } from "../../../redux/configSlice";
import {
  ALERT_TYPE,
  ALL_JOBS_STAGE_ARRAY,
  JOBPAGE,
  JOB_STATUS,
} from "../../../utils/Constants";
import InfiniteScroll from "react-infinite-scroll-component";
import JobCard from "./JobsCardComponent";
import Filters from "./Filters";
import _ from "lodash";
import locale from "../../../i18n/locale";
import RunMatchBox from "../../common/myJobsSectionEmployerAndRecruiter/RunMatchBox";

const BASIC = {
  lastKey: "",
  status_id: "",
  job_stage: "",
  job_title: "",
  job_type: "",
  team_member_user_id: "",
  company_id: "",
};

export default function AllJobs({ page }) {
  const i18n = locale.en;
  const navigate = useNavigate();
  const { compname } = useParams();
  const { pathname } = useLocation();
  const [allJobs, setAllJobs] = useState([]);
  const dispatch = useDispatch();
  const [lastKey, setLastKey] = useState("");
  const [totalJob, setTotalJob] = useState(0);
  const [basicData, setBasicData] = useState(BASIC);
  const [showMsg, setShowMsg] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [filtersValue, setFiltersValue] = useState({
    quickSearch: "",
    jobStage: "",
    jobType: "",
    companyName: "",
    jobStatus: "",
  });
  // const [openFilter, setOpenFilter] = useState(false);
  const temp = JOBPAGE?.find((item) => item?.filters?.value === page);
  const pageInfoRef = useRef(
    temp?.filters?.title === "Incognito Jobs" ? true : ""
  );
  const [stageArray, setStageArray] = useState(ALL_JOBS_STAGE_ARRAY);
  const formStateRef = useRef();
  const [jobLockedUnlocked, setJobLockedUnlocked] = useState(false);
  const [jobTypeArray, setJobTypeArray] = useState([
    {
      id: 1,
      name: "Recruit",
    },
    {
      id: 2,
      name: "Direct",
    },
  ]);

  const [jobStatus, setJobStatus] = useState(JOB_STATUS);

  const onHandleManageTalent = (activeJobId) => {
    navigate(`${pathname}/${activeJobId}`);
  };

  const getJobList = async (lastkeyy, newBasicData) => {
    const data = {
      ...newBasicData,
      lastKey: lastkeyy,
      status_id:
        temp !== undefined
          ? temp?.status_id
          : newBasicData?.status_id || newBasicData?.jobStatus,
      job_type:
        (temp !== undefined && temp?.filters?.type === "Direct") ||
        (temp !== undefined && temp?.filters?.type === "Recruit")
          ? temp?.filters?.type
          : newBasicData?.jobType,
      post_incognito: pageInfoRef?.current,
      company_id: newBasicData?.companyName ? newBasicData?.companyName : "",
      job_stage: newBasicData?.jobStage ? newBasicData?.jobStage : "",
      job_title: newBasicData?.quickSearch ? newBasicData?.quickSearch : "",
      // job_type:"Recruit",
      // lastKey:"",
      // status_id:"",
      team_member_user_id: newBasicData?.teamMember
        ? newBasicData?.teamMember
        : "",
    };
    const { payload } = await dispatch(adminJobsFilter(data));
    if (payload?.status === "success") {
      setTotalJob(payload?.talentCount);
      if (payload?.data?.length <= 0 && payload?.pageNumber === 0) {
        setAllJobs([]);
        setShowMsg(true);
      } else {
        if (payload?.pageNumber === 0) {
          setLastKey(payload?.pageNumber + 1);
          setAllJobs(payload?.data);
        } else {
          setLastKey(payload?.pageNumber + 1);
          setAllJobs((prevState) => [...prevState, ...payload?.data]);
          // setAllJobs(payload?.data);
        }
      }
    } else if (payload === undefined) {
      return;
    } else {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Something went wrong! please relaod the window",
        })
      );
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    if (!compname) {
      getJobList(lastKey, basicData);
    }
  }, []);

  return (
    <>
      <Filters
        title={temp !== undefined ? temp?.filters?.title : "All Jobs"}
        total={totalJob}
        // handleJobRoleChange={handleJobRoleChange}
        value={temp !== undefined ? temp?.filters?.value : ""}
        // handleJobType={handleJobType}
        stageArray={stageArray}
        jobTypeArray={jobTypeArray}
        // handleInputSearch={handleInputSearch}
        // handleTeamMember={handleTeamMember}
        jobStatus={jobStatus}
        // handleCompany={handleCompany}
        // handleJobStatus={handleJobStatus}
        filtersValue={filtersValue}
        setFiltersValue={setFiltersValue}
        // setOpenFilter={setOpenFilter}
        // openFilter={openFilter}
        getJobList={getJobList}
        basicData={basicData}
        setBasicData={setBasicData}
        setAllJobs={setAllJobs}
        formStateRef={formStateRef}
      />

      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={allJobs.length}
        next={() =>
          getJobList(
            lastKey,
            JSON.parse(
              sessionStorage.getItem(
                `${temp !== undefined ? temp?.filters?.title : "All Jobs"}`
              )
            )
          )
        }
        scrollThreshold={"100px"}
        scrollableTarget={"jobList"}
        hasMore={allJobs.length < totalJob ? true : false}
        // loader={<Box>loading....</Box>}
      >
        <Box sx={{ mt: "260px", mb: "16px", paddingBottom: '35px !important' }}>
          {showSuccess && (
            <RunMatchBox
              handleClose={() => setShowSuccess(false)}
              openEdit={showSuccess}
              isTalentLocked={true}
            />
          )}
          {allJobs?.length > 0
            ? allJobs?.map((job, index) => (
                <JobCard
                  key={index}
                  setShowSuccess={setShowSuccess}
                  setJobLockedUnlocked={setJobLockedUnlocked}
                  jobLockedUnlocked={jobLockedUnlocked}
                  index={job.job_id}
                  jobContentData={job}
                  onManageTalent={onHandleManageTalent}
                  getJobList={getJobList}
                  setAllJobs={setAllJobs}
                  setJobsLastKey={setLastKey}
                  basicData={basicData}
                  page={page}
                  setTotalJob={setTotalJob}
                  allJobs={allJobs}
                />
              ))
            : showMsg && (
                <Box
                  sx={{
                    width: "100%",
                    textAlign: "center",
                    mt: 4,
                    color: "black",
                  }}
                >
                  No Jobs
                </Box>
              )}
        </Box>
        <style>
          {`.infinite-scroll-component__outerdiv{
              width:100%
            }`}
        </style>
      </InfiniteScroll>
    </>
  );
}
