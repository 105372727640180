import { createTheme } from "@mui/material/styles";

import { employerDefaulTheme } from "../Theme";

const employerMyJobsTheme = createTheme({
  ...employerDefaulTheme,
  palette: {
    ...employerDefaulTheme.palette,
    jobTypeButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    workSetupButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    matchButton: {
      main: "#009AE0",
      contrastText: "#ffffff",
    },
    playButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    greyPlayButton: {
      main: "#F2F4FB",
      contrastText: "#FFF",
    },
    accessListButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    trackButton: {
      main: "#F2F4FB",
      contrastText: "#000",
    },
    RecruitButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    DirectButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    announceButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    industriesButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    manageButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    talentButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    flipButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    newFlipColor:{
      main: "#38A8AE",
      contrastText: "#ffffff",
    },
    editJobButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    pauseJobButton: {
      main: "#FF9900",
      contrastText: "#FFF",
    },
    closeJobButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    duplicateJobButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    viewJobButton: {
      main: "#FFC425",
      contrastText: "#FFF",
    },
    copyJobButton: {
      main: "#E4047C",
      contrastText: "#FFF",
    },
    reactiveJobButton: {
      main: "#47D281",
      contrastText: "#FFF",
    },
    skillsButton: {
      main: "#5932D9",
      contrastText: "#FFF",
    },
    toolsButton: {
      main: "#38A8AE",
      contrastText: "#FFF",
    },
    traitsButton: {
      main: "#009AE0",
      contrastText: "#FFF",
    },
    addEditMemberButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    backButton: {
      main: "#C9CAD8",
      contrastText: "#000000",
    },
    topUpCoinButton: {
      main: "#B38E66",
      contrastText: "#FFF",
    },
    extendJobButton: {
      main: "#D1011B",
      contrastText: "#FFF",
    },
    AddJobButton: {
      main: "#F2F4FB",
      contrastText: "black",
    },
    chart: {
      ...employerDefaulTheme.palette.chart,
      lessThan10: "#D1011B",
      between10to20: "#FF9900",
      moreThan20: "#47D281",
    },
  },
});

export { employerMyJobsTheme };
