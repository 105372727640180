import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import pricingTabIcon from "../../../../assets/TopBarIcons/PricingTabIcon.svg";
import locale from "../../../../i18n/locale";

export default function ReviewPlan({ show, handleReview }) {
  const theme = useTheme();
  const i18n = locale.en;
  return (
    <Dialog
      open={show}
      hideButton={false}
      maxWidth="xs"
      showFooter={false}
      zIndex='99999999999999999999'
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: "25px !important",
        },
      }}
    >
      <Box
        sx={{
          width: "360px",
          minHeight: "300px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-evenly",
          //   gap: gap,
        }}
      >
        <Box
          component={"img"}
          src={pricingTabIcon}
          sx={{ height: 75, width: 96 }}
        />

        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: theme.typography.Bold,
            width: "242px",
            textAlign: "center",
          }}
        >
          {i18n["myplan.reviewyourplanoptions"]}
        </Typography>
        <Box>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              width: "288px",
              textAlign: "center",
            }}
          >
            {i18n["myplan.newbieskickoffwithCrayonLite"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              width: "288px",
              textAlign: "center",
            }}
          >
            {i18n["myplan.realpower"]}
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: theme.typography.Regular,
              width: "288px",
              textAlign: "center",
            }}
          >
            {i18n["myplan.unlocked"]}
          </Typography>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="talentButton"
        sx={{ width: "100%", height: "45px", borderRadius: "0 0 25px 25px" }}
        onClick={handleReview}
      >
        {i18n["myplan.reviewoptions"]}
      </Button>
    </Dialog>
  );
}
