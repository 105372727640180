import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setCurrentStep } from "../../../redux/candidate/myProfileSlice";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  useTheme,
} from "@mui/material";
import useViewport from "../../common/ViewPort";
import { getDecodedToken, getToken } from "../../../utils/Common";
import LeftArrow from "../../common/LeftArrow";
import RightArrow from "../../common/RightArrow";
import LeftSideProfilePanel from "../myProfile/LeftSideProfilePanel";
import {
  CANDIDATE_MY_PROFILE_LEFT,
  assesmentSteps,
} from "../../../utils/Constants";
import { useSelector } from "react-redux";
import GoodStart from "./GoodStart.js";
import HalfWay from "./Halfway.js";
import KeepItUp from "./KeepItUp.js";
import TheEnd from "./TheEnd.js";
import AlmostThere from "./AlmostThere.js";
import { setSideFilter } from "../../../redux/login/loginSlice.js";

const CandidateAssesment = ({ handleCandidateDynamicTheme }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useViewport();
  const decodedToken = getDecodedToken(getToken());
  const theme = useTheme();
  const currentStep = useSelector((state) => state.myProfileSli.currentStep);
  const [step, setStep] = useState(0);
  const [presentStepCount, setPresentStepCount] = useState();
  const { isSideFilter } = useSelector((state) => state.login);

  const [leftExpanded, setLeftExpand] = useState(true);
  const [rightExpand, setRightExpand] = useState(true);
  useEffect(() => {
    dispatch(setCurrentStep(4));
    handleCandidateDynamicTheme(decodedToken?.data?.personality);
    // getProgressData();
    // completedStep();
  }, []);
  const handleLeftSide = (step) => {
    dispatch(setCurrentStep(step));
    if (step === 1) {
      navigate("/candidate/my-profile");
    } else if (step === 2) {
      navigate("/candidate/my-profile");
      // setExpanded(true);
    } else if (step === 3) {
      navigate("/candidate/my-cv");
    } else if (step === 4) {
      navigate("/candidate/assessment");
      // window.open(
      //   "https://docs.google.com/forms/d/e/1FAIpQLScKTA3IfBUw8xvdP03-hMFX1UObzO_pPcd5p61OsKAf3whbrg/viewform"
      // );
    } else if (step === 5) {
      navigate("/candidate/my-cam");
    }
  };
  const handleStep = (value, comp) => {
    setStep(value);
  };
  const handleChangeStep = (value, comp) => {
    handleStep(value, comp);
  };
  const handleGoodStartSaveBtn = () => {};
  useEffect(() => {
    if (width > 1024) {
      dispatch(setSideFilter(true));
      // setLeftExpand(true);
      // setRightExpand(true);
    } else {
      dispatch(setSideFilter(false));
      // setLeftExpand(false);
      // setRightExpand(false);
    }
  }, [width]);
  return (
    <>
      <Grid
        container
        spacing={0}
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        flexWrap={"nowrap"}
        gap={"30px"}
        position={"relative"}
      >
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            width: isSideFilter ? "150px" : "30px",
            zIndex: "30",
            position: `${width < 768 ? "absolute" : "relative"}`,
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              boxShadow: "0px 5px 10px #00000029 !important",
              position: "static",
              borderRadius: "0 10px 10px 0",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              backgroundColor: `${theme.palette.filterButtons.main}`,
              "&:hover": {
                backgroundColor: `${theme.palette.filterButtons.main}`,
              },
            }}
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpand((prevState) => !prevState);
            }}
          >
            {isSideFilter ? <LeftArrow /> : <RightArrow />}
          </Button> */}
          <Box
            sx={{
              minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight: "45px",
            }}
          ></Box>
          {isSideFilter && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                // height: {
                //   xs: `calc(100vh - 95px)`,
                //   sm: `calc(100vh - 120px )`,
                //   md: `calc(100vh - 120px)`,
                //   lg: `calc(100vh - 135px)`,
                //   xl: `calc(100vh - 155px)`,
                //   xxl: "calc(100vh - 175px)",
                // },
                width: `${width < 768 && "160px"}`,
                paddingTop: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
                overflowY: isSideFilter ? "scroll" : "unset",
              }}
              className="filterSec"
            >
              <Paper
                sx={{
                  background: "transparent",
                  marginRight: "1px",
                  boxShadow: 0,
                  maxWidth: `${width < 768 && "150px"}`,
                }}
              >
                <LeftSideProfilePanel
                  panelData={CANDIDATE_MY_PROFILE_LEFT}
                  side="left"
                  onChangeFilter={handleLeftSide}
                  selectedButton={currentStep}
                  color="filterButtons"
                />
              </Paper>
            </Box>
          )}
        </Grid>
        {width < 768 && width !== 0 && isSideFilter && (
          <Box
          onClick={() => dispatch(setSideFilter(false))}
            sx={{
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0, .2)",
            }}
          ></Box>
        )}
        <Grid
          container
          width={{ xs: "100%", tb24: `calc(100vw - 300px)` }}
          justifyContent={"center"}
          gap={"10px"}
          alignContent={"flex-start"}
          flexGrow={1}
          sx={{
            // zIndex: isSideFilter && -1,
            zIndex:width < 768 && isSideFilter && -1,
            height: {
              xs: `calc(100vh - 70px)`,
              sm: `calc(100vh - 70px )`,
              md: `calc(100vh - 70px)`,
              lg: `calc(100vh - 80px)`,
              xl: `calc(100vh - 100px)`,
              xxl: "calc(100vh - 120px)",
            },
            // height:"100vh"
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              alignItems: "flex-start",
              position: "relative",
              height: "auto",
              flexDirection: "column",
              padding: { xs: "5px", md24: "0px" },
              marginTop: { xs: "40px", tablet: "55px" },
            }}
            width={"100%"}
          >
            <Box
              width="100%"
              sx={{
                cursor: "auto !important",
                boxShadow: "0px 5px 10px #00000029 !important",
                borderRadius: "25px",
                marginBottom: step !== 4 && "110px !important",
                position: "relative",
                background: "#ffff",
              }}
            >
              {step === 0 && (
                <GoodStart
                  handleSaveButton={handleGoodStartSaveBtn}
                  handleChangeStep={handleChangeStep}
                />
              )}
              {step === 1 && <KeepItUp handleChangeStep={handleChangeStep} />}
              {step === 2 && <HalfWay handleChangeStep={handleChangeStep} />}
              {step === 3 && (
                <AlmostThere handleChangeStep={handleChangeStep} />
              )}
              {step === 4 && <TheEnd handleChangeStep={handleChangeStep} />}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: `${width >= 768 && "10px"}`,
            direction: "rtl",
            position: `${width < 768 ? "absolute" : "relative"}`,
            zIndex: "30",
            width: isSideFilter ? "150px" : "30px",
            right: "0",
          }}
        >
          {/* <Button
            variant="contained"
            sx={{
              position: "sticky",
              top: 0,
              boxShadow: "0px 5px 10px #00000029 !important",
              borderRadius: "10px 0 0 10px",
              minWidth: "30px",
              width: "30px",
              height: "45px",
              backgroundColor: `${theme.palette.filterButtons.main}`,
              "&:hover": {
                backgroundColor: `${theme.palette.filterButtons.main}`,
              },
            }}
            onClick={() => {
              setLeftExpand((prevState) => !prevState);
              setRightExpand((prevState) => !prevState);
            }}
          >
            {isSideFilter ? <RightArrow /> : <LeftArrow />}
          </Button> */}
          <Box
            sx={{
              minWidth: "30px",
              width: "30px",
              height: "45px",
              minHeight: "45px",
            }}
          ></Box>
          {isSideFilter && (
            <Box
              className="rightfilterSec"
              sx={{
                display: "flex",
                flexDirection: "column",
                // width: "141px",
                width: `${width < 768 && "125px"}`,
                minWidth: "125px",
                paddingTop: `${width < 768 && "10px"}`,
                paddingLeft: `${width < 768 && "10px"}`,
                paddingBottom: `${width < 768 && "10px"}`,
                background: `${width > 0 && width < 768 && "#EBECF3"}`,
                boxShadow: `${
                  width > 0 && width < 768 && "0px 5px 10px #00000029"
                }`,
                opacity: `${width < 768 && "1"}`,
                borderRadius: `${width < 768 && "10px"}`,
              }}
            >
              <Stepper
                nonLinear
                activeStep={step}
                orientation="vertical"
                sx={{
                  "& .MuiStepConnector-root": {
                    marginLeft: "20px",
                  },
                  "& .MuiStepConnector-line": {
                    borderColor: theme.palette.precium.main,
                    minHeight: "40px",
                    margin: "5px 0px 6px 0px",
                  },
                }}
              >
                {assesmentSteps?.map((item, index) => (
                  <Step
                    key={item.label}
                    // completed={completed[index]}
                    sx={{
                      "& .Mui-active": {
                        color:
                          theme.palette.precium.contrastText + " !important",
                        fill: theme.palette.precium.contrastText,
                      },
                      "& .MuiStepIcon-root": {
                        color: step !== index && theme.palette.precium.main,
                        width: "25px !important",
                        height: "25px !important",
                        fontSize: "12px",
                      },
                      "& .MuiStepIcon-text": {
                        fill:
                          step === index
                            ? theme.palette.precium.main
                            : theme.palette.base.main,
                        fontSize: "12px",
                      },
                      "& .MuiStepLabel-labelContainer": {
                        color: theme.palette.base.contrastText,
                      },
                      "& .MuiStepLabel-label": {
                        fontSize: "14px",
                        fontWeight:
                          step === index
                            ? theme.typography.Bold
                            : theme.typography.Regular,
                      },
                      "& .MuiStepLabel-label.Mui-active": {
                        color: theme.palette.precium.contrastText,
                      },
                      "& .MuiStepLabel-label.Mui-completed": {
                        color: theme.palette.precium.main,
                      },
                    }}
                  >
                    <StepLabel
                      onClick={() => {
                        setPresentStepCount(index);
                        handleStep(index);
                      }}
                      color="precium"
                      style={{
                        border: `2px solid ${item.borderColor}`,
                        borderRadius: "10px 0 0 10px",
                        cursor: "pointer",
                        height: "40px",
                        padding: "0 10px",
                        backgroundColor:
                          step === index
                            ? theme.palette.precium.main
                            : theme.palette.base.main,
                        // boxShadow: "0px 5px 10px #00000029",
                        flexDirection: "row-reverse",
                      }}
                    >
                      {item.label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default CandidateAssesment;
