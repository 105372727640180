import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "../../../common/CloseIcon";
import { ToggleSwitch } from "../../../../utils/CommonComponent";
import { useDispatch } from "react-redux";
import { updatehideitems } from "../../../../redux/candidate/myProfileSlice";
import { setAlert } from "../../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../../utils/Constants";

export default function HideItems({
  onClose,
  settingsRef,
  setSettingButton,
  profile,
  setProfile,
}) {

  const theme = useTheme();
  const dispatch = useDispatch();
  const [hideProfile, setHideProfile] = useState(profile?.hide_profile);
  const [hideAge, setHideAge] = useState(profile?.hide_age);
  const [hideProfilePicture, setHideProfilePicture] = useState(profile?.hide_profile_pic);
  const [hideVideo, setHideVideo] = useState(profile?.hide_video);

  const handleStatusToggleChange = async (key, value) => {
    try {
      const data = {
        [key]: value,
      };
      const { payload } = await dispatch(updatehideitems(data));
      if (
        payload?.data?.status === "success" ||
        payload?.status === "success"
      ) {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Status Changed",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Something went wrong,please try again later!",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: ERROR_MSG,
        })
      );
    }
  };

  return (
    <Box sx={{ width: "300px", height: "auto", background: "#FFFFFF" }}>
      <Box>
        <Button
          variant="contained"
          color="checkers"
          sx={{
            width: "150px",
            height: "45px",
            boxShadow: "none !important",
            borderRadius: "25px 0 10px 0",
            padding: "0px",
          }}
        >
          hide items
        </Button>
        <Box onClick={onClose}>
          <CloseIcon />
        </Box>
      </Box>

      <Box
        sx={{
          padding: "10px 10px 14px 16px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your profile
          </Typography>

          <ToggleSwitch
            color="greenSwitch"
            notCheckedColor="redButton"
            // checkedColor={"greenSwitch"}
            checked={hideProfile}
            onChange={(event) => {
              setHideProfile(!hideProfile);
              setProfile({ ...profile, hideProfile: event?.target?.checked });
              handleStatusToggleChange("hide_profile", event?.target?.checked);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your age
          </Typography>

          <ToggleSwitch
            // color="greenSwitch"
            color="greenSwitch"
            notCheckedColor="redButton"
            // checkedColor={"greenSwitch"}
            // notCheckedColor={"redButton"}
            checked={hideAge}
            onChange={(event) => {
              setHideAge(!hideAge);
              setProfile({ ...profile, hideAge: event?.target?.checked });
              handleStatusToggleChange("hide_age", event?.target?.checked);
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your profile picture
          </Typography>

          <ToggleSwitch
            // color="greenSwitch"
            color="greenSwitch"
            notCheckedColor="redButton"
            // checkedColor={"greenSwitch"}
            // notCheckedColor={"redButton"}
            checked={hideProfilePicture}
            onChange={(event) => {
              setHideProfilePicture(!hideProfilePicture);
              setProfile({
                ...profile,
                hideProfilePicture: event?.target?.checked,
              });
              handleStatusToggleChange(
                "hide_profile_pic",
                event?.target?.checked
              );
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: theme.typography.fontSize.sm,
              fontWeight: theme.typography.Regular,
            }}
          >
            Hide your video
          </Typography>

          <ToggleSwitch
            // color="greenSwitch"
            color="greenSwitch"
            notCheckedColor="redButton"
            // checkedColor={"greenSwitch"}
            // notCheckedColor={"redButton"}
            checked={hideVideo}
            onChange={(event) => {
              setHideVideo(!hideVideo);
              setProfile({ ...profile, hideVideo: event?.target?.checked });
              handleStatusToggleChange("hide_video", event?.target?.checked);
            }}
          />
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="darkGrey"
          sx={{
            width: "100%",
            height: "60px",
            borderRadius: "0 0 25px 25px",
            color: "black",
          }}
          onClick={() => {
            setSettingButton(settingsRef?.current);
            onClose();
          }}
        >
          back to settings
        </Button>
      </Box>
    </Box>
  );
}
