import { CheckCircle } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  Tooltip,
  Paper,
  InputBase,
  IconButton,
  Button,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import userLogo from "../../../assets/user_logo.svg";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/configSlice";
import { ALERT_TYPE, ERROR_MSG } from "../../../utils/Constants";
import { adminAssignJob, getAdmin } from "../../../redux/admin/userAdminSlice";
import { debounce } from "../../../utils/Common";

export default function AssignJobBox({
  handleClose,
  setJobContent,
  jobContent,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState();
  const [lastKey, setLastKey] = useState();

  const [teamMember, setTeamMember] = useState([]);
  const [teamListLoading, setTeamListLoading] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getTeamMemberList = async (lastkeyy, title) => {
    setTeamListLoading(true);
    try {
      let response = await dispatch(
        getAdmin({ role_type_id: "", lastKey: lastkeyy, title: title })
      );
      if (response?.payload?.status === "success") {
        setTeamMember((prev) => [...prev, ...response?.payload?.data]);
        setLastKey(response?.payload?.pageNumber + 1);
      }
      setTeamListLoading(false);
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const handleAssignJob = async (userId) => {
    try {
      let data = {
        job_id: jobContent?.job_id,
        admin_id: userId,
      };
      const { payload } = await dispatch(adminAssignJob(data));
      if (payload?.status === "success") {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.SUCCESS,
            msg: "Job assigned successfully",
          })
        );
      } else {
        dispatch(
          setAlert({
            show: true,
            type: ALERT_TYPE?.ERROR,
            msg: "Error",
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          show: true,
          type: ALERT_TYPE?.ERROR,
          msg: "Error",
        })
      );
    }
  };

  const debouncedHandleOnTextSearch = useCallback(
    debounce(
      async (newValue) => {
        if (!newValue) return; // Early return
        try {
          const response = await getTeamMemberList(0, newValue?.target?.value);

          if (response && response?.payload) {
            const { payload } = response;
            dispatch(
              payload?.status === "success"
                ? setTeamMember(payload?.data)
                : setAlert({
                    show: true,
                    type: ALERT_TYPE?.ERROR,
                    msg: ERROR_MSG,
                  })
            );
          }
        } catch (error) {
          dispatch(
            setAlert({ show: true, type: ALERT_TYPE?.ERROR, msg: ERROR_MSG })
          );
        }
      },
      500, // Adjust delay as needed
      setIsLoading
    ),
    [dispatch, setTeamMember, setIsLoading]
  );

  const handleSearch = async (event) => {
    setTeamMember([]);
    setSearchText(event?.target?.value);
    debouncedHandleOnTextSearch(event);
  };

  useEffect(() => {
    getTeamMemberList(0);
  }, []);

  return (
    <Box
      sx={{
        boxShadow: "0px 3px 8px #00000029",
        width: "300px",
        borderRadius: "25px",
        height: "586px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "& .css-ll95b0-MuiPaper-root-MuiPopover-paper": {
          background: "green",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={() => handleClose()}
        sx={{
          position: "absolute",
          right: 8,
          //   color: (theme) => theme.palette.grey[500],
        }}
        disableRipple={true}
      >
        <Box
          sx={{
            width: "20px",
            height: "20px",
            color: "#000000",
            border: 2,
            fontSize: "18px",
            borderRadius: "5px",
          }}
        >
          X
        </Box>
      </IconButton>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          marginTop: "26px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: theme.typography.Bold,
          }}
          gutterBottom
        >
          Below is the list of team members that can view, edit or manage this
          pool
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Paper
            elevation={0}
            component="form"
            sx={{
              padding: "0px 10px",
              border: "1px solid #C9CAD8",
            }}
            height={"40px"}
            width={"308px"}
          >
            <InputBase
              sx={{
                fontWeight: theme.typography.Regular,
                width: "308px",
              }}
              placeholder={"Search for a job"}
              value={searchText}
              onChange={(event) => {
                handleSearch(event);
              }}
            />
          </Paper>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "400px",
              "::-webkit-scrollbar": {
                height: "5px",
                background: "#DCDEEB",
                display: "unset !important",
              },
            }}
            id="teamMember"
          >
            <InfiniteScroll
              style={{
                overflowX: "hidden",
                scrollbarWidth: "thin",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingBottom: '35px !important'
              }}
              dataLength={teamMember?.length || 0}
              next={() => getTeamMemberList(lastKey, searchText)}
              hasMore={true}
              // loader={
              //   <Box
              //     style={{
              //       margin: "15px 0 0 0",
              //       display: "flex",
              //       justifyContent: "center",
              //       alignItems: "center",
              //     }}
              //   >
              //     <CircularProgress color="inherit" size={20} />
              //   </Box>
              // }
              scrollableTarget="teamMember"
              scrollThreshold={"50px"}
            >
              {teamMember?.length > 0
                ? teamMember
                    ?.filter((item) => !item?.default)
                    ?.map((member) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Box
                          component={"img"}
                          src={
                            member?.profile_url !== "NO URL"
                              ? member?.profile_url
                              : userLogo
                          }
                          sx={{
                            height: "46px",
                            width: "46px",
                            borderRadius: "10px",
                          }}
                        />
                        <Tooltip
                          disableInteractive
                          title={`${member?.user?.first_name} ${
                            member?.user?.last_name
                          } ${
                            member?.admin === true
                              ? "(admin)"
                              : member?.superadmin === true
                              ? "(super admin)"
                              : member?.talent_partner === true
                              ? "(talent partner)"
                              : ""
                          }`}
                          placement="top-end"
                        >
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontWeight: theme.typography.Bold,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setJobContent({
                                ...jobContent,
                                assigned_first_name: member?.user?.first_name,
                                assigned_last_name: member?.user?.last_name,
                                profile_url: member?.profile_url,
                              });
                              setSelectedTeamMember(member?.user_id);
                              handleAssignJob(member?.user_id);
                              // handleClose();
                            }}
                            noWrap
                          >
                            {`${member?.user?.first_name} ${
                              member?.user?.last_name
                            } ${
                              member?.admin === true
                                ? "(admin)"
                                : member?.superadmin === true
                                ? "(super admin)"
                                : member?.talent_partner === true
                                ? "(talent partner)"
                                : ""
                            }`}
                          </Typography>
                        </Tooltip>
                        {member?.user_id === selectedTeamMember && (
                          <CheckCircle
                            sx={{
                              fontSize: 27,
                              cursor: "pointer",
                            }}
                            color="checkColor"
                          />
                        )}
                      </Box>
                    ))
                : teamListLoading && (
                    <Box
                      style={{
                        margin: "15px 0 0 0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress color="inherit" size={20} />
                    </Box>
                  )}
            </InfiniteScroll>
          </Box>
        </Box>
      </Box>
      <Button
        variant="contained"
        color="closeButton"
        sx={{
          color: "black",
          width: "100%",
          borderRadius: "0 0 10px 10px",
        }}
        onClick={handleClose}
      >
        Close
      </Button>
    </Box>
  );
}
